//Component Added by Macdonald
//--------------------------------------------------------------------
import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import { Link } from "react-router-dom";
import classnames from "classnames";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import $ from "jquery";
import axios from "axios";
import { useState, useEffect } from 'react'
import {
    BASE_URL,
    token,
    storage_type
  } from "../../../../config";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Donut', 452, 25.0, 51, 4.9),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Honeycomb', 408, 3.2, 87, 6.5),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Jelly Bean', 375, 0.0, 94, 0.0),
  createData('KitKat', 518, 26.0, 65, 7.0),
  createData('Lollipop', 392, 0.2, 98, 0.0),
  createData('Marshmallow', 318, 0, 81, 2.0),
  createData('Nougat', 360, 19.0, 9, 37.0),
  createData('Oreo', 437, 18.0, 63, 4.0),
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Depot Name' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Depot Address' },
  { id: 'DateCreated', numeric: true, disablePadding: false, label: 'Date Created' },
  { id: 'DateUpdated', numeric: true, disablePadding: false, label: 'Date Assigned' },
//   { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Assigned Depots
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

export default function EnhancedTable() {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [staffs, setStaff] = useState([]);
  const [depots, setDepot] = useState([]);   
  const [staff_id,setStaffId] = useState(null);
  const [deletedRows, setDeletedRows] = useState([]);

  useEffect(() => {    
    const GetStaffs = async () => {  
      axios.get(`${BASE_URL}/staffs`,
      {method:'GET',
        headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`},
      }, 
      ).then(response => {
        if(response.data.payload.staffs.length !== 0){
          console.log("Selected Depots: ", response.data.payload.staffs);
          setStaff(response.data.payload.staffs);
        }
      });  
    }  
    GetStaffs();   
    //console.log(staffs);  
}, []); 

const handleStaffChange = e => {
    setStaffId(e.target.value);
    setDepot([]);
    
    const staffId = e.target.value;
    const GetDepots = async () => { 
        axios.get(`${BASE_URL}/staff-depots?id=${staffId}`,
            {method:'GET',
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storage_type.getItem(token)}`},
            }, 
            ).then(response => {
            if(response.data.payload.staffDepots.length !== 0){
                console.log("Selected Depots: ", response.data.payload.staffDepots[0].depots);
                setDepot(response.data.payload.staffDepots[0].depots);
            }
        });
    }
    GetDepots();
};

const handleSubmit = e => {
    e.preventDefault();
    //const { deallocateDepot } = this.props;
    const creds = {
      depotsLeft: selected,
      userId: staff_id
    };
    console.log("Selected dpots for removal: ", creds);
    const deallocateDepots = async (creds) => { 
        //axios.delete( `${BASE_URL}/depots/removedepots?depots=${creds.depotsLeft}&userID=${creds.UserId}`,
        axios.delete(`${BASE_URL}/depots/removedepots/?creds=` + JSON.stringify(selected)+`&userID=${staff_id}`,
            {method:'DELETE',
            headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${storage_type.getItem(token)}`},
            }, 
            ).then(response => {
                if(response.data.payload.staffDepots.length !== 0){
                    console.log("Selected Depots: ", response.data.payload.staffDepots[0].depots);
                    setDepot(response.data.payload.staffDepots[0].depots);
                }
        });

    };
    deallocateDepots();
    
}

const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = depots.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, depots.length - page * rowsPerPage);
  //const { spinner, status, message } = this.props;
  return (
    <div className="content-wrapper">
        <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>PREFERENCES</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Deallocate Depot</li>
                            </ol>
                        </div>
                    </div>
                </div>
        </section>
        {/* Main content */}
        <section className="content">
            {/* Default box */}
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title font-weight-bold ">Deallocate Depot</h3>
                        <div className="card-tools"></div>
                    </div>
                    <div className="card-body">
                        {/* {spinner ? <Spinner position="right" /> : null}
                        {status && message ? (
                            <Message status={status} message={message} />
                        ) : null} */}
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <label>
                                    Staffs <span className="text-danger">*</span>
                                </label>
                                <div className="form-group col-md-6">
                                    <select
                                        className={classnames({
                                            //"is-invalid": this.hasErrorFor("user_id"),
                                            "form-control selected_state": true
                                        })}
                                        placeholder="Select"
                                        onChange={handleStaffChange}
                                        id="staff_id"
                                        value={staff_id}
                                    >
                                    <option value="">--Select--</option>
                                        {staffs &&
                                        staffs.map((staff, index) => (
                                            <option key={index} value={staff.id}>
                                                {staff.first_name} {staff.last_name}{" "}
                                                {staff.other_name}
                                            </option>
                                        ))}
                                    </select>
                                    {/* {this.renderErrorFor("user_id")} */}
                                </div>
                            </div>
                            <div className={classes.root}>
                                <Paper className={classes.paper}>
                                    <EnhancedTableToolbar numSelected={selected.length} />
                                    <TableContainer>
                                    <Table
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                        aria-label="enhanced table"
                                    >
                                        <EnhancedTableHead
                                        classes={classes}
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={depots.length}
                                        />
                                        <TableBody>
                                        {stableSort(depots, getComparator(order, orderBy))
                                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                            .map((depot, index) => {
                                            const isItemSelected = isSelected(depot.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;

                                            return (
                                                <TableRow
                                                hover
                                                onClick={(event) => handleClick(event, depot.id)}
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={depot.id}
                                                selected={isItemSelected}
                                                >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                    checked={isItemSelected}
                                                    inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                </TableCell>
                                                <TableCell component="th" id={labelId} scope="row" padding="none">
                                                    {depot.name}
                                                </TableCell>
                                                <TableCell align="left">{depot.address}</TableCell>
                                                <TableCell align="right">{depot.created_at}</TableCell>
                                                <TableCell align="right">{depot.updated_at}</TableCell>
                                                {/* <TableCell align="right">{row.protein}</TableCell> */}
                                                </TableRow>
                                            );
                                            })}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                                            <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                        </TableBody>
                                    </Table>
                                    </TableContainer>
                                    <TablePagination
                                    rowsPerPageOptions={[5, 10, 25]}
                                    component="div"
                                    count={depots.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onChangePage={handleChangePage}
                                    onChangeRowsPerPage={handleChangeRowsPerPage}
                                    />
                                </Paper>
                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={handleChangeDense} />}
                                    label="Dense padding"
                                />
                                <div style={{float:'right'}}>
                                    <button
                                        type="submit"
                                        className="btn btn-warning btn-sm text-uppercase font-weight-bold  mx-auto"
                                        // disabled={spinner ? true : false}
                                        >
                                        Deallocate
                                    </button>{" "}
                                    <Link
                                        to={"/preferences/depots"}
                                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-1"
                                        >
                                        View Depot
                                    </Link>
                                </div>
                                </div>
                            </form>
                        </div>
                    {/* /.card-body */}     
                </div>
                {/* /.card */}
            </section>
    </div>
  );
}
//----------------------------------END----------------------------------------------