import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateUser,
  resetUsersState,
  getOneMember,
} from "../../../store/actions/usersActions";
import { getAllCamps } from "../../../store/actions/preferencesActions";
import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../store/actions/authActions";
import MiniSpinner from "../../helpers/MiniSpinner";
import $ from "jquery";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
// 10.10.1.227

class DetailsGideons extends Component {
  state = {};
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "UPDATE_MEMBER" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    const id = this.props.match.params.id;
    this.props.getOneMember(id).then((res) => {
      if (res.status === "success") {
        const new_state1 = res.payload.member;
        const new_state2 = res.payload.member.profile;
        this.setState({ ...new_state2, ...new_state1 }, () => {
          this.props.getAllCamps();
        });
      }
    });
    this.initDependency();
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFileChange = (e) => {
    // alert("here");
    // console.log(e.target.files[0]);

    this.setState({
      image: e.target.files[0],
    });
  };
  handleClick = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  // getMemberStatus = (status) => {
  //   if (status === "GOOD") {
  //     return "GOOD STANDING";
  //   } else if (status === "TRANS") {
  //     return "TRANSFERED";
  //   } else if (status === "DELI") {
  //     return "DELIQUENT";
  //   } else if (status === "DROP") {
  //     return "DROPPED";
  //   } else if (status === "ACTI") {
  //     return "ACTIVE";
  //   } else if (status === "EXIT") {
  //     return "EXIT";
  //   }
  //   return "UNKNOWN";
  // };
  testPrint = (e) => {
    e.preventDefault();
    html2canvas(document.getElementById("content")).then(function (canvas) {
      var img = canvas.toDataURL("image/jpeg");
      var doc = new jsPDF("p", "mm", "a4");
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();
      doc.addImage(img, "JPEG", 1, 2, width, height);
      doc.save("gideon_detail.pdf");
    });
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const previous_date = $("#previous_date").val();
    const birth_date = $("#birth_date").val();
    const passed_on = $("#passed_on").val();
    const accepted_date = $("#accepted_date").val();
    const endorse_date = $("#endorse_date").val();
    // const last_payment_date = $("#last_payment_date").val();
    this.setState(
      {
        birth_date,
        passed_on,
        previous_date,
        accepted_date,
        endorse_date,
        // last_payment_date
      },
      () => {
        if (window.confirm("Are you sure want to update this member record?")) {
          const { updateUser } = this.props;
          updateUser(this.state).then((res) => {
            // console.log(res);
            if (res.status === "error") {
              $(".opp").each(function () {
                $(this).removeClass("collapsed-card");
                const item = $(this).find(".fa-plus");
                if (item) {
                  item.removeClass("fa-plus").addClass("fa-minus");
                }
              });
            }
          });
        }
      }
    );
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.camps !== prevState.camps) {
      return {
        camps: nextProps.camps,
      };
    }
    return null;
  }
  dateConv = (date) => {
    if (date === null || date === "") {
      return null;
    }
    return new Date(date).toDateString();
  };
  render() {
    let my_camp = "--";
    let married = "--";
    let alive = "--";
    if (this.state.camps) {
      my_camp = this.state.camps.find((camp) => {
        // eslint-disable-next-line
        return camp.id == this.state.camp_id;
      });
    }
    // console.log("hetre", my_camp);
    let my_camp2 = my_camp;
    if (
      my_camp2 &&
      my_camp2.area &&
      my_camp2.area.region &&
      my_camp2.area.region.state
    ) {
      my_camp2 = `${my_camp.name} -- ${my_camp.area.name} -- ${my_camp.area.region.name} --${my_camp.area.region.state.name}`;
    }

    if (this.state.married) {
      if (this.state.married === "1") {
        married = "Yes";
      } else {
        married = "No";
      }
    }
    if (this.state.alive) {
      if (this.state.alive === "1") {
        alive = "Yes";
      } else {
        alive = "No";
      }
    }
    const { spinner, status, message, preference_spinner } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Membership</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Details Gideon Record
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content" id="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Details Gideon Record
              </h3>
              <div className="card-tools">
                {/* <button
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold mr-1"
                  onClick={this.testPrint}
                >
                  {" "}
                  Download PDF
                </button> */}
                {hasPermission({
                  mod: "Preferences",
                  action: "VIEW_MEMBER",
                }) ? (
                  <Link to="/membership/gideons">
                    <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                      <i className="fas fa-long-arrow-alt-left mr-1"></i>
                      BACK
                    </button>
                  </Link>
                ) : null}
              </div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="card-body">
                {spinner ? <Spinner position="right" /> : null}
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}
                {/* start SECTION I: PERSONAL INFORMATION */}

                <div className="card opp">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">PERSONAL INFORMATION</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <strong> Title :</strong> {this.state.title || "--"}
                      </div>
                      <div className="col-md-4">
                        <strong> First Name :</strong>{" "}
                        {this.state.first_name || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Last Name : </strong>{" "}
                        {this.state.last_name || "--"}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Other Name : </strong>{" "}
                        {this.state.other_name || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Mailing Address : </strong>{" "}
                        {this.state.mailing_address || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> City : </strong> {this.state.city || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> State : </strong> {this.state.state || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Camp : </strong>{" "}
                        {preference_spinner ? (
                          <MiniSpinner />
                        ) : my_camp2 ? (
                          my_camp2
                        ) : (
                          "--"
                        )}
                      </div>
                      <div className=" col-md-4">
                        <strong> Role(s) : </strong>{" "}
                        {this.state.roles &&
                          this.state.roles.map((role, index) => (
                            <span
                              className="badge badge-primary mr-2"
                              key={index}
                            >
                              {role.name}
                            </span>
                          ))}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Status : </strong> {this.state.status || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Phone : </strong> {this.state.phone || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Phone2 : </strong> {this.state.phone_2 || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Email : </strong> {this.state.email || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Birth Date : </strong>{" "}
                        {this.dateConv(this.state.birth_date) || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Married : </strong> {married || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Spouse Name : </strong>{" "}
                        {this.state.aux
                          ? `${this.state.aux.first_name} ${this.state.aux.last_name}`
                          : "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Is member alive? : </strong>{" "}
                        {this.state.alive === 0 ? "No" : "Yes"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Passed on Date : </strong>{" "}
                        {this.dateConv(this.state.passed_on) || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Member ID : </strong>{" "}
                        {this.state.login_id || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Spouse ID : </strong>{" "}
                        {this.state.aux ? this.state.aux.login_id : "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Due Date : </strong>{" "}
                        {this.dateConv(this.state.expiry_date) || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Recruitment Method : </strong>{" "}
                        {this.state.recruitment || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Renewal type : </strong>{" "}
                        <span className="text-uppercase">
                          {this.state.membership_type || "--"}
                        </span>{" "}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>

                {/* end SECTION I: PERSONAL INFORMATION */}

                {/* start SECTION III: BUSINESS OCCUPATIONS */}
                <div className="card  opp">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">BUSINESS OCCUPATIONS</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className=" col-md-6">
                        <strong>
                          The name of my business, or that in which I am
                          involved is :{" "}
                        </strong>{" "}
                        {this.state.business_name || "--"}
                      </div>
                      <div className=" col-md-6">
                        <strong>
                          {" "}
                          The nature of my business, or that in which I am
                          involved is :{" "}
                        </strong>{" "}
                        {this.state.business_description || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Physical address : </strong>{" "}
                        {this.state.business_address || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> City: </strong>{" "}
                        {this.state.business_city || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> State : </strong>{" "}
                        {this.state.business_state || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Website: </strong>{" "}
                        {this.state.business_website || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> My title is: </strong>{" "}
                        {this.state.business_title || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Zip Code : </strong>{" "}
                        {this.state.business_zip_code || "--"}
                      </div>
                    </div>
                    {/* <div className="row mt-3">              
                      <div className=" col-md-6">
                        <strong>  Be generally accepted and recognized as a
                                  businessman in the sense that this term is
                                  normally used? </strong> {this.state.business_conduct  || "--"}
                      </div>
                      <div className=" col-md-6">
                        <strong>  My title is: </strong> {this.state.business_title  || "--"}
                      </div>
                   
                    </div> */}
                  </div>

                  {/* /.card-body */}
                </div>
                {/* end SECTION III: BUSINESS OCCUPATIONS*/}

                {/* start SECTION IV: PROFESSIONAL OR INSTITUTIONAL OCCUPATIONS */}
                <div className="card  opp">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">
                      PROFESSIONAL OR INSTITUTIONAL OCCUPATIONS
                    </h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> My profession is: </strong>{" "}
                        {this.state.profession || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> My title is: </strong>{" "}
                        {this.state.profession_title || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong>
                          {" "}
                          Do you have a related four-year degree or above?:{" "}
                        </strong>{" "}
                        {this.state.profession_degree || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong>
                          {" "}
                          Do you have a related four-year degree or above?:{" "}
                        </strong>{" "}
                        {this.state.profession_degree || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Degree name: </strong>{" "}
                        {this.state.profession_degree_name || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Institution: </strong>{" "}
                        {this.state.profession_institution || "--"}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className=" col-md-12">
                        <strong>
                          {" "}
                          In lieu of a four-year degree, please describe the
                          experience that qualifies you into your profession:{" "}
                        </strong>{" "}
                        {this.state.profession_description || "--"}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
                {/* end SECTION IV: PROFESSIONAL OR INSTITUTIONAL OCCUPATIONS*/}

                {/* start SECTION V: DECLARATION */}
                <div className="card opp">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">DECLARATION</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className=" col-md-6">
                        <strong> Date: </strong>{" "}
                        {this.dateConv(this.state.accepted_date) || "--"}
                      </div>
                      <div className=" col-md-6">
                        <strong> Payment Type : </strong>{" "}
                        {this.state.pay_type || "--"}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
                {/* end SECTION V: DECLARATION*/}

                {/* start SECTION VI: SPONSORSHIP */}
                <div className="card  opp ">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">SPONSORSHIP</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className=" col-md-6">
                        <strong>
                          {" "}
                          Source of application for Gideon membership:{" "}
                        </strong>{" "}
                        {this.state.application_source || "--"}
                      </div>
                      <div className=" col-md-6">
                        <strong> Spiritual Reasons : </strong>{" "}
                        {this.state.sponsor_spiritual_reasons || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Occupation: </strong>{" "}
                        {this.state.sponsor_occupation || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Membership Card # : </strong>{" "}
                        {this.state.sponsor_spiritual_reasons || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Membership Card # : </strong>{" "}
                        {this.state.sponsor_card || "--"}
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* end SECTION VI: SPONSORSHIP*/}

                {/* start SECTION VII: OTHER INFOMATION */}
                <div className="card  opp ">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">OTHER INFOMATION</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong>Endorsement Name: </strong>{" "}
                        {this.state.endorsement_name || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong>Endorsement Position : </strong>{" "}
                        {this.state.endorsement_position || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Pastor's Comments : </strong>{" "}
                        {this.state.pastors_comments || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong>Endorsement Date: </strong>{" "}
                        {this.dateConv(this.state.endorse_date) || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> By (Headquaters Staff Initials) : </strong>{" "}
                        {this.state.endorse_by || "--"}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
                {/* end SECTION VII: OTHER INFOMATION*/}

                {hasPermission({
                  mod: "Preferences",
                  action: "VIEW_MEMBER",
                }) ? (
                  <Link to="/membership/gideons">
                    <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                      {/* <i className="fas fa-long-arrow-alt-left mr-1"></i> */}
                      View Gideons
                    </button>
                  </Link>
                ) : null}
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    preference_spinner: state.preferences.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    camps: state.preferences.camps,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (creds) => updateUser(dispatch, creds),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    getAllCamps: () => dispatch(getAllCamps()),
    getOneMember: (id) => getOneMember(dispatch, id),
    resetUsersState: () => resetUsersState(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailsGideons);
