import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  getAllStates,
  createRegion
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import $ from "jquery";

class CreateRegions extends Component {
  state = {
    name: "",
    state_id: "",
    states: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_REGION" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      this.props.getAllStates();
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.states !== prevState.states) {
      return {
        states: nextProps.states
      };
    }
    return null;
  }

  handleSelectArea = e => {
    this.setState({ state_id: e.target.value });
  };
  handleSubmit = e => {
    e.preventDefault();
    const { createRegion } = this.props;
    const creds = {
      name: this.state.name,
      state_id: this.state.state_id
    };
    createRegion(creds).then(res => {
      if (res.status === "success") {
        this.setState({
          name: "",
          state_id: ""
        });
        $(".selected_state").val("");
      }
    });
  };
  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Create Region</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">Create Region</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("name"),
                        "form-control": true
                      })}
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("name")}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      States <span className="text-danger">*</span>
                    </label>
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("state_id"),
                        "form-control selected_state": true
                      })}
                      placeholder="Select a State"
                      onChange={this.handleSelectArea}
                      id="state_id"
                      defaultValue={this.state.state_id}
                    >
                      <option value="">--Select a State--</option>
                      {this.state.states &&
                        this.state.states.map((state, index) => (
                          <option key={index} value={state.id}>
                            {state.name}
                          </option>
                        ))}
                    </select>
                    {this.renderErrorFor("state_id")}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Add
                </button>
                {hasPermission({
                  mod: "Preferences",
                  action: "VIEW_REGION"
                }) ? (
                  <Link
                    to="/preferences/regions"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  >
                    View Regions
                  </Link>
                ) : null}
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    states: state.preferences.states,
    errors: state.preferences.errors
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllStates: () => dispatch(getAllStates()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    createRegion: creds => createRegion(dispatch, creds)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRegions);
