import { BASE_URL, token, storage_type } from "../../config";
import axios from "axios";

export const resetReportState = (dispatch) => {
  dispatch({ type: "CLEAR_REPORT_ERRORS" });
};

export const getAccountReport = (id, from, to, area, region, state) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/camps/funds?camp=${id}&from=${from}&to=${to}&area=${area}&region=${region}&state=${state}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_ACCOUNT_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getSummaryStateReport = (from, to) => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/membership/all?from=${from}&to=${to}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_SUMMARY_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_STATE_SUMMARY_REPORT", resMessage });
      });

    // }
  };
};
export const getSummaryStateReportScrip = (from, to) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/scriptures/all?from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_SUMMARY_REPORT_SCRIPTURE", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_STATE_SUMMARY_REPORT", resMessage });
      });

    // }
  };
};
export const getSummaryStateReportFund = (from, to) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/funds/all?from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_SUMMARY_REPORT_FUNDS", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_STATE_SUMMARY_REPORT", resMessage });
      });

    // }
  };
};
export const getCampScriptureReport = (id, from, to, area, region, state) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/camps/scriptures?camp=${id}&from=${from}&to=${to}&area=${area}&region=${region}&state=${state}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_CAMP_SCRIPTURE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};

/**---------------MODIFICATION BY MACDONALD------------------
 * 
 * @param {*} from 
 * @param {*} to 
 * 
 * DATE: mua/202101193
 * ---------------------------------------------------------------
 */
export const getMemberAllCampReport = (from, to) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/camps/allmembership?from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_MEM_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
/*---------------END FUNCTION MODIFICATION BY MACDONALD----------------------*/


export const getMemberReport = (id, from, to) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/camps/membership?camp=${id}&from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_MEM_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};

export const getCamp503Report = (id, from) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/camps/monthly-remittance?camp=${id}&from=${from}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_CAMP_503_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getMonthlyPerm = (id, from) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/camps/monthly-performance?camp=${id}&from=${from}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_CAMP_MONTHLY_PERFORMANCE", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getAreAccountReport = (area, from, to) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/areas/funds?area=${area}&from=${from}&to=${to}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_AREA_ACCOUNT_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const areaCampDetails = (area, type, from, to) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/areas/funds?area=${area}&camp=${true}&type=${type}&from=${from}&to=${to}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_AREA_CAMP_ACCOUNT_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const scrpCampdetails = (area, bible, from, to) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/areas/scriptures?area=${area}&camp=${true}&bible=${bible}&from=${from}&to=${to}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_AREA_CAMP_SCRIPT_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getAreaMemberReport = (from, to, area) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/areas/membership?from=${from}&to=${to}&area=${area}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_AREA_MEMBER_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getAreaScriptureReport = (from, to, area, camp) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/areas/scriptures?from=${from}&to=${to}&area=${area}&camp=${camp}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_AREA_SCRIPTURE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};

export const getRegionAccountReport = (region, from, to) => {
  // console.log(region);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/regions/funds?region=${region}&from=${from}&to=${to}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_REGION_ACCOUNT_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getRegionMemberReport = (from, to, region) => {
  // console.log(from, to, region, area, camp);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/regions/membership?from=${from}&to=${to}&region=${region}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_REGION_MEMBER_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const regionAreaMember = (from, to, region, area) => {
  // console.log(from, to, region, area, camp);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/regions/membership?from=${from}&to=${to}&region=${region}&area=${area}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_REGION_AREA_MEMBER_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getRegionScriptureReport = (from, to, region, area, camp) => {
  console.log(from, to, region, area, camp);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/regions/scriptures?from=${from}&to=${to}&region=${region}&area=${area}&camp=${camp}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_REGION_SCRIPTURE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const scrpRegionAreadetails = (region, bible, from, to) => {
  // console.log(from, to, region, area, camp);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/regions/scriptures?from=${from}&to=${to}&region=${region}&area=${true}&bible=${bible}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_REGION_AREA_SCRIPTURE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const scrpRegionAreaCampdetails = (region, area, bible, from, to) => {
  // console.log(from, to, region, area, camp);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/regions/scriptures?from=${from}&to=${to}&region=${region}&area=${area}&bible=${bible}&camp=${true}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_REGION_CAMP_SCRIPTURE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getStateDistributionReport = (from, to) => {
  // alert(region);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/distribution?from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_DISTRIBUTION_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getStateAccountReport = (state, from, to) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/funds?state=${state}&from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_ACCOUNT_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const regionDetails = (state, type, from, to) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/funds?state=${state}&region=${true}&type=${type}&from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_ACCOUNT_REGION_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const stateAreaDetails = (state, type, from, to) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/funds?state=${state}&area=${true}&type=${type}&from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_ACCOUNT_AREA_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const regionAreaDetails = (region, type, from, to) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/regions/funds?region=${region}&area=${true}&type=${type}&from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_REGION_ACCOUNT_AREA_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const regionCampDetails = (region, type, from, to) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/regions/funds?region=${region}&camp=${true}&type=${type}&from=${from}&to=${to}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_REGION_ACCOUNT_CAMP_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const stateCampDetails = (state, type, from, to) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/funds?state=${state}&camp=${true}&type=${type}&from=${from}&to=${to}`,

      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_ACCOUNT_CAMP_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getStateMemberReport = (from, to, state) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/membership?from=${from}&to=${to}&state=${state}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_MEMBER_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const stateRegionMember = (from, to, state, region) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/membership?from=${from}&to=${to}&state=${state}&region=${region}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_REGION_MEMBER_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const stateAreaMember = (from, to, state, area) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/membership?from=${from}&to=${to}&state=${state}&area=${area}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_AREA_MEMBER_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getStateScriptureReport = (
  from,
  to,
  state,
  region,
  area,
  camp
) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/scriptures?from=${from}&to=${to}&state=${state}&region=${region}&area=${area}&camp=${camp}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_SCRIPTURE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const scrpStateRegiondetails = (state, bible, from, to) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/scriptures?from=${from}&to=${to}&bible=${bible}&state=${state}&region=${true}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_REGION_SCRIPTURE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const scrpStateArea = (state, region, bible, from, to) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/scriptures?from=${from}&to=${to}&bible=${bible}&state=${state}&region=${region}&area=${true}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_AREA_SCRIPTURE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const scrpStateCamp = (state, region, area, bible, from, to) => {
  // alert(state);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/scriptures?from=${from}&to=${to}&bible=${bible}&state=${state}&region=${region}&area=${area}&camp=${true}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_CAMP_SCRIPTURE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_ACCOUNT_REPORT", resMessage });
      });

    // }
  };
};
export const getPerformanceReport = (id, from, to, area, region, state) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/camps/performance?camp=${id}&from=${from}&to=${to}&area=${area}&region=${region}&state=${state}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_PERFORMANCE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_PERFORMANCE_REPORT", resMessage });
      });

    // }
  };
};
export const getAreaPerformanceReport = (from, to, area) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/areas/performance?from=${from}&to=${to}&area=${area}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_AREA_PERFORMANCE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_PERFORMANCE_REPORT", resMessage });
      });

    // }
  };
};
export const getRegionPerformanceReport = (from, to, region) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/regions/performance?from=${from}&to=${to}&region=${region}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_REGION_PERFORMANCE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_PERFORMANCE_REPORT", resMessage });
      });

    // }
  };
};
export const getStatePerformanceReport = (from, to, state) => {
  // alert(area);
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_REPORT_ERRORS" });
    dispatch({ type: "LOAD_REPORT_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/reports/states/performance?from=${from}&to=${to}&state=${state}`,
      // url: `${BASE_URL}/reports/camps/funds?camp=${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "GET_STATE_PERFORMANCE_REPORT", resMessage });
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_REPORT_SPINNER" });
        dispatch({ type: "ERRORS_GET_PERFORMANCE_REPORT", resMessage });
      });

    // }
  };
};
// export const getAccountReport = () =>{

// }
