import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllHotelsAll,
  deleteCampAction,
  resetPreferencesState,
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";

class ViewConHotels extends Component {
  state = {
    hotels: null,
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllHotelsAll } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_HOTEL" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllHotelsAll();
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hotels !== prevState.hotels) {
      return {
        hotels: nextProps.hotels,
      };
    }
    return null;
  }
  deleteCamp = (e, id) => {
    const { deleteCampAction } = this.props;
    e.preventDefault();
    if (window.confirm("Are you sure you want to delete this camp?")) {
      deleteCampAction(id);
    }
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">View Camps</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                View Convention Hotels
              </h3>
              <div className="card-tools">
                <Link to="/preferences/convention-hotels/create">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle mr-1"></i>
                    ADD NEW CONVENTION HOTEL
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <table
                    id="camp_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Hotel</th>
                        <th>Address</th>
                        <th>Telephone</th>
                        <th>Remarks</th>
                        <th>Rooms</th>
                        <th>Status</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.hotels &&
                        this.state.hotels.map((hotel, index) => {
                          return (
                            <tr key={index}>
                              <td>{hotel.name}</td>
                              <td>{hotel.address}</td>
                              <td>{hotel.tel}</td>
                              <td>{hotel.remarks}</td>
                              <td>
                                {hotel.rooms.length &&
                                  hotel.rooms.map((room, index) => (
                                    <div key={index} className="my-1">
                                      <Link
                                        to={`/preferences/hotels/rooms-type/edit?hotel_id=${hotel.id}&hotel_name=${hotel.name}&name=${room.name}&price=${room.price}&available=${room.available}&type_id=${room.id}`}
                                      >
                                        <span className="badge badge-primary  mx-1 p-2 text-uppercase">
                                          type: {room.name}
                                        </span>
                                        <span className="badge badge-primary  mx-1 p-2 text-uppercase">
                                          Price: &#8358;{" "}
                                          {this.moneyConv(room.price)}
                                        </span>
                                        <span className="badge badge-primary  mx-1 p-2 text-uppercase">
                                          Available: {room.available}
                                        </span>
                                      </Link>
                                    </div>
                                  ))}
                              </td>
                              <td>{hotel.status}</td>

                              <td>
                                <span
                                  style={{
                                    overflow: "visible",
                                    position: "relative",
                                    width: "110px",
                                  }}
                                >
                                  <Link
                                    to={`/preferences/convention-hotels/edit?id=${hotel.id}&name=${hotel.name}&address=${hotel.address}&tel=${hotel.tel}&remarks=${hotel.remarks}&status=${hotel.status}`}
                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                    title="Edit Hotel"
                                  >
                                    <i className="fa fa-edit"></i>
                                  </Link>

                                  {/* <Link
                                    to="#!"
                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                    title="Close Camp"
                                    onClick={(e) => {
                                      this.deleteCamp(e, camp.id);
                                    }}
                                  >
                                    <i className="fa fa-trash"></i>
                                  </Link> */}

                                  <Link
                                    to={`/preferences/hotels/rooms?id=${hotel.id}&name=${hotel.name}`}
                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                    title="Add Room Types"
                                  >
                                    <i className="fa fa-plus"></i>
                                  </Link>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    hotels: state.preferences.hotels,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllHotelsAll: () => dispatch(getAllHotelsAll()),
    resetPreferencesState: () => resetPreferencesState(dispatch),
    deleteCampAction: (id) => dispatch(deleteCampAction(id)),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewConHotels);
