import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../../hooks";
import {
  useGetSystemRoles,
  useGetSystemCamps,
} from "../../../../store/hookactions/notificationsActionhooks";
import MiniSpinner from "../../../helpers/MiniSpinner";
import Message from "../../../helpers/Message";
import Spinner from "../../../helpers/Spinner";
import $ from "jquery";
import {
  submitAreaMessage,
  resetNotificationState,
} from "../../../../store/actions/notificationActions";
import { useDispatch } from "react-redux";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import { storage_type, user_auth_details, dehash } from "./../../../../config";

function SendAreaMessages(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [enabled, setEnabled] = useState(true);
  const [cars_Info, setCarInfo] = useState({});
  const [filtered_roles, setFilterRoles] = useState([]);
  const [filtered_camps, setFilterCamps] = useState([]);
  const [title, setTitle] = useState("");
  const [all_chk_rol, setAllRoleChk] = useState(false);
  const [all_chk_camp, setAllCampChk] = useState(false);

  useGetSystemRoles(enabled, setEnabled);
  useGetSystemCamps(enabled, setEnabled);
  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const status = useShallowEqualSelector((state) => state.notifications.status);
  const errors = useShallowEqualSelector((state) => state.notifications.errors);
  const roles = useShallowEqualSelector((state) => state.notifications.roles);
  const camps = useShallowEqualSelector((state) => state.preferences.camps);

  // console.log(errors);
  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );
  const spinner_preferences = useShallowEqualSelector(
    (state) => state.preferences.spinner
  );

  function handleSubmit(e) {
    e.preventDefault();
    const send_date = $("#birth_date").val();
    const compose_message = $("#compose_message").val();
    let role_ids = Array.from($("#role_id").val());
    role_ids = role_ids.map((item) => parseInt(item, 10));
    let camp_ids = Array.from($("#camp_id").val());
    camp_ids = camp_ids.map((item) => parseInt(item, 10));
    const to_area = cars_Info.area.id;
    const creds = {
      title,
      role_ids,
      to_camp: camp_ids,
      compose_message,
      send_date,
      to_area: [to_area],
      type: "area",
    };
    submitAreaMessage(dispatch, creds).then((res) => {
      if (res?.status === "success") {
        $("#role_id").val([]);
        $("#camp_id").val([]);
        $("#birth_date").val("");
        // $("#compose_message").s("reset");
        setTitle("");
        initDependency();
      }
    });
  }
  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        // initDependency();
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  function handleCheckRole() {
    setAllRoleChk((prev) => {
      if (!prev) {
        checkAllRoles();
      } else {
        removeAllRoles();
      }
      return !prev;
    });
  }
  function handleCheckCamp() {
    setAllCampChk((prev) => {
      if (!prev) {
        checkAllCamps();
      } else {
        removeAllCamps();
      }
      return !prev;
    });
  }

  function checkAllRoles() {
    const sel_role = [];
    filtered_roles.forEach((role) => {
      sel_role.push(role.id);
    });
    // console.log(sel_role);
    $("#role_id").val([...sel_role]);
    initDependency();
  }
  function removeAllRoles() {
    $("#role_id").val([]);
    initDependency();
  }
  function removeAllCamps() {
    $("#camp_id").val([]);
    initDependency();
  }
  function checkAllCamps() {
    const sel_camp = [];
    filtered_camps.forEach((camp) => {
      sel_camp.push(camp.id);
    });
    // console.log(sel_camp);
    $("#camp_id").val([...sel_camp]);
    initDependency();
  }

  useLayoutEffect(() => {
    initDependency();
  }, []);

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Notification",
        action: "SEND_NOTIFICATION_TO_AREA",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      setCarInfo(user?.camp);
      //   console.log(user);
    }

    return () => {
      resetNotificationState(dispatch);
    };
  }, [dispatch, history]);

  useEffect(() => {
    const PATTERN = "Area";
    const PATTERN1 = "Camp";
    const filtered = roles?.filter(function (str) {
      return str.name.includes(PATTERN) || str.name.includes(PATTERN1);
    });
    setFilterRoles(filtered);
  }, [roles]);
  useEffect(() => {
    const area_name = cars_Info?.area?.name;
    const filtered = camps?.filter((camp) => {
      return camp?.area?.name === area_name;
    });
    setFilterCamps(filtered);
  }, [camps, cars_Info]);
  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>NOTIFICATIONS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Send Area Message</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              Send Area Message{" "}
              <span className="lead">
                Area: <i>{cars_Info?.area?.name}</i>
              </span>
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="send_date">
                    Send Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames({
                      "is-invalid": hasErrorFor("send_date"),
                      "form-control": true,
                    })}
                    id="birth_date"
                    name="name"
                    readOnly
                    placeholder="Select date"
                  />
                  {renderErrorFor("send_date")}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="title">
                    Title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames({
                      "is-invalid": hasErrorFor("title"),
                      "form-control": true,
                    })}
                    id="title"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {renderErrorFor("title")}
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 ">
                  <div className="row">
                    <div
                      className={classnames({
                        "col-11": spinner === true,
                        "col-12": spinner === false,
                      })}
                    >
                      <label>
                        Select Role <span className="text-danger">*</span>
                      </label>
                      <select
                        className={classnames({
                          "form-control selected_role": true,
                          memberselect2: true,
                        })}
                        placeholder="Select a Role"
                        id="role_id"
                        multiple="multiple"
                      >
                        <option value="">--Select a role--</option>
                        {filtered_roles &&
                          filtered_roles.map((role, index) => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                      </select>

                      {renderErrorFor("role_ids")}
                    </div>
                    {spinner && (
                      <div className="col-1 pt-4">
                        <MiniSpinner />
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group col-md-6 pt-4">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name="all_role"
                      id="all_role"
                      defaultChecked={all_chk_rol}
                      onChange={handleCheckRole}
                    />
                    <label htmlFor="all_role" className="custom-control-label">
                      All Role
                    </label>
                  </div>
                  <div>
                    <i className="fa fa-info-circle text-info mr-1"></i>
                    Check to send to all roles.
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 ">
                  <div className="row">
                    <div
                      className={classnames({
                        "col-11": spinner_preferences === true,
                        "col-12": spinner_preferences === false,
                      })}
                    >
                      <label>
                        Select Camp <span className="text-danger">*</span>
                      </label>
                      <select
                        className={classnames({
                          "is-invalid": hasErrorFor("camp_ids"),
                          "form-control selected_camp": true,
                          memberselect22: true,
                        })}
                        placeholder="Select camp"
                        id="camp_id"
                        multiple="multiple"
                      >
                        <option value="">--Select camp--</option>
                        {filtered_camps &&
                          filtered_camps.map((camp, index) => (
                            <option key={camp.id} value={camp.id}>
                              {camp.name} -- {camp.area.name} --{" "}
                              {camp.area.region.name} --{" "}
                              {camp.area.region.state.name}
                            </option>
                          ))}
                      </select>

                      {renderErrorFor("camp_ids")}
                    </div>
                    {spinner && (
                      <div className="col-1 pt-4">
                        <MiniSpinner />
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group col-md-6 pt-4">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name="all_camps"
                      id="all_camps"
                      defaultChecked={all_chk_camp}
                      onChange={handleCheckCamp}
                    />
                    <label htmlFor="all_camps" className="custom-control-label">
                      All Camps
                    </label>
                  </div>
                  <div>
                    <i className="fa fa-info-circle text-info mr-1"></i>
                    Check to send to all camps.
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-outline card-info">
                    <div className="card-header">
                      <h3 className="card-title">Compose message</h3>
                      {/* tools box */}
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool btn-sm"
                          data-card-widget="collapse"
                          data-toggle="tooltip"
                          title="Collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                      </div>
                      {/* /. tools */}
                    </div>
                    {/* /.card-header */}
                    <div className="card-body pad">
                      <div className="mb-3">
                        <textarea
                          className="textarea "
                          name="compose_message"
                          id="compose_message"
                          placeholder="Place some text here"
                          style={{
                            width: "100%",
                            height: 400,
                            fontSize: 14,
                            lineHeight: 18,
                            border: "1px solid #dddddd",
                            padding: 10,
                          }}
                        />
                        {renderErrorFor("compose_message")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                disabled={spinner ? true : false}
              >
                Send
                {spinner && <MiniSpinner />}
              </button>
            </form>
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default SendAreaMessages;
