import React from "react";
import { Link } from "react-router-dom";

function DelistedGidTable({ gideons, setRemarkPropertiesGid, dateConv }) {
  return (
    <div key="uniqueKey">
      <table
        id="example"
        className="table table-hover table-bordered table-striped"
        style={{
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th>S/N</th>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Other Name</th>
            <th>Member Id</th>
            <th>Spouse Id</th>
            <th>Spouse Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Recruitment Method</th>
            <th>Renewal Type</th>
            <th>Camp</th>
            <th>Area</th>
            <th>Region</th>
            <th>State</th>

            <th>Status</th>
            <th>Due Date</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {gideons &&
            gideons.map((gideon, index) => {
              return (
                <tr key={gideon.id}>
                  <td>{index + 1}</td>

                  <td>{gideon.last_name}</td>
                  <td>{gideon.first_name}</td>
                  <td>{gideon.other_name || "--"}</td>
                  <td>{gideon.login_id || "--"}</td>
                  <td>{gideon.aux ? gideon.aux.login_id : "--"}</td>
                  <td>
                    {" "}
                    {gideon.aux
                      ? `${gideon.aux.first_name} ${gideon.aux.last_name}`
                      : "--"}
                  </td>

                  {/* <td>{gideon.phone.trim() || "--"}</td>
                <td>{gideon.email || "--"}</td>
                <td>{gideon.camp?.name || "--"}</td>
                <td>{gideon.camp.area?.name || "--"}</td>
                <td>{gideon.camp.area.region?.name || "--"}</td>
                <td>
                  {gideon.camp.area.region.state?.name || "--"}
                </td> */}
                  <td>{gideon.phone ? gideon.phone.trim() : "--"}</td>
                  <td>{gideon.email || "--"}</td>
                  <td>{gideon.recruitment || "--"}</td>
                  <td>
                    {" "}
                    <span className="text-uppercase">
                      {gideon.membership_type || "--"}
                    </span>{" "}
                  </td>
                  {/*--------modifications by Macdonald-------------*/}
                  <td>{gideon.camp ? gideon.camp.name : "--"}</td>
                  <td>{gideon.camp ? gideon.camp.area.name : "--"}</td>
                  <td>{gideon.camp ? gideon.camp.area.region.name : "--"}</td>
                  <td>
                    {gideon.camp ? gideon.camp.area.region.state.name : "--"}
                  </td>
                  {/*--------------End Modifications------------------ */}
                  <td> {gideon.status} </td>
                  <td> {dateConv(gideon.expiry_date)} </td>

                  <td>
                    <div
                      style={{
                        overflow: "visible",
                        position: "relative",
                      }}
                    >
                      <center>
                        <Link
                          onClick={() =>
                            setRemarkPropertiesGid(
                              gideon.id,
                              gideon.first_name + " " + gideon.last_name
                            )
                          }
                          to="#"
                          className="btn btn-sm btn-danger btn-icon mr-1"
                          data-toggle="modal"
                          data-target="#RemarkGidModal"
                        >
                          <i className="fas fa-trash-alt text-white" />
                        </Link>
                      </center>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(DelistedGidTable);
