import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateUser,
  resetUsersState,
  getOneMember
} from "../../../store/actions/usersActions";
import { getAllCamps } from "../../../store/actions/preferencesActions";
import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../store/actions/authActions";

import $ from "jquery";
import * as jsPDF from "jspdf";
import * as html2canvas from "html2canvas";
// 10.10.1.227

class DetailsFriends extends Component {
  state = {};
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_FRIENDS" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    const id = this.props.match.params.id;
    this.props.getOneMember(id).then(res => {
      if (res.status === "success") {
        const new_state1 = res.payload.member;
        const new_state2 = res.payload.member.profile;
        this.setState({ ...new_state2, ...new_state1 });
      }
    });
    this.initDependency();
    this.props.getAllCamps();
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleFileChange = e => {
    // alert("here");
    // console.log(e.target.files[0]);

    this.setState({
      image: e.target.files[0]
    });
  };
  handleClick = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  testPrint = e => {
    e.preventDefault();
    html2canvas(document.getElementById("content")).then(function(canvas) {
      var img = canvas.toDataURL("image/jpeg");
      var doc = new jsPDF("p", "mm", "a4");
      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();
      doc.addImage(img, "JPEG", 1, 2, width, height);
      doc.save("gideon_detail.pdf");
    });
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    const previous_date = $("#previous_date").val();
    const birth_date = $("#birth_date").val();
    const passed_on = $("#passed_on").val();
    const accepted_date = $("#accepted_date").val();
    const endorse_date = $("#endorse_date").val();
    // const last_payment_date = $("#last_payment_date").val();
    this.setState(
      {
        birth_date,
        passed_on,
        previous_date,
        accepted_date,
        endorse_date
        // last_payment_date
      },
      () => {
        if (window.confirm("Are you sure want to update this member record?")) {
          const { updateUser } = this.props;
          updateUser(this.state).then(res => {
            // console.log(res);
            if (res.status === "error") {
              $(".opp").each(function() {
                $(this).removeClass("collapsed-card");
                const item = $(this).find(".fa-plus");
                if (item) {
                  item.removeClass("fa-plus").addClass("fa-minus");
                }
              });
            }
          });
        }
      }
    );
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.camps !== prevState.camps) {
      return {
        camps: nextProps.camps
      };
    }
    return null;
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Membership</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Details Staff Record
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content" id="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Details Staff Record
              </h3>
              <div className="card-tools">
                {/* <button
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold mr-1"
                  onClick={this.testPrint}
                >
                  {" "}
                  Download PDF
                </button> */}
                {hasPermission({
                  mod: "Preferences",
                  action: "VIEW_FRIENDS"
                }) ? (
                  <Link to="/membership/friends">
                    <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                      <i className="fas fa-long-arrow-alt-left mr-1"></i>
                      BACK
                    </button>
                  </Link>
                ) : null}
              </div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="card-body">
                {spinner ? <Spinner position="right" /> : null}
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}
                {/* start SECTION I: PERSONAL INFORMATION */}

                <div className="card opp">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">PERSONAL INFORMATION</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <strong> Title :</strong> {this.state.title || "--"}
                      </div>
                      <div className="col-md-4">
                        <strong> First Name :</strong>{" "}
                        {this.state.first_name || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Last Name : </strong>{" "}
                        {this.state.last_name || "--"}
                      </div>
                    </div>

                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Other Name : </strong>{" "}
                        {this.state.other_name || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Mailing Address : </strong>{" "}
                        {this.state.mailing_address || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> City : </strong> {this.state.city || "--"}
                      </div>
                    </div>

                    <div className="row mt-3">
                      {/* <div className=" col-md-4">
                                                <strong> Status : </strong>{" "}
                                                {(this.state.status === "ACTI"
                                                    ? "ACTIVE"
                                                    : this.state.status) || "--"}
                                            </div> */}
                      <div className=" col-md-4">
                        <strong> Phone : </strong> {this.state.phone || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Phone2 : </strong> {this.state.phone_2 || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> Email : </strong> {this.state.email || "--"}
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className=" col-md-4">
                        <strong> Birth Date : </strong>{" "}
                        {this.state.birth_date || "--"}
                      </div>
                      <div className=" col-md-4">
                        <strong> State : </strong> {this.state.state || "--"}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>

                {/* end SECTION I: PERSONAL INFORMATION */}

                {hasPermission({
                  mod: "Preferences",
                  action: "VIEW_FRIENDS"
                }) ? (
                  <Link to="/membership/friends">
                    <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                      {/* <i className="fas fa-long-arrow-alt-left mr-1"></i> */}
                      View Friends
                    </button>
                  </Link>
                ) : null}
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.users.spinner,
    preference_spinner: state.preferences.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    camps: state.preferences.camps
  };
};
const mapDispatchToProps = dispatch => {
  return {
    updateUser: creds => updateUser(dispatch, creds),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    getAllCamps: () => dispatch(getAllCamps()),
    getOneMember: id => getOneMember(dispatch, id),
    resetUsersState: () => resetUsersState(dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailsFriends);
