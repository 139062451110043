import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateNewActivityCon,
  resetUsersState,
} from "../../../../store/actions/usersActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import classnames from "classnames";
import $ from "jquery";
import MiniSpinner from "../../../helpers/MiniSpinner";
import {
  getAllSettings2,
  getAllStates,
  getOneCampActivityCon,
} from "./../../../../store/actions/preferencesActions";

class UpdateActivityCon extends Component {
  state = {
    creds: {},
    states: null,
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_CONVENTION" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    this.initDependency();
    this.props.getAllStates();
    this.props.getAllSettings2().then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.settings }, () => {
          this.setState({
            creds: {
              ...this.state.creds,
              gideon_fee: this.state.gideon_convention_fee,
              aux_fee: this.state.aux_convention_fee,
              both_fee: this.state.both_convention_fee,
            },
          });
        });
      }
    });
    const id = this.props.match.params.id;
    this.props.getOneCampActivityCon(id).then((res) => {
      if (res.status === "success") {
        this.setState({
          creds: { ...this.state.creds, ...res.payload.convention },
        });
        $("#start_date").val(res.payload.convention.start_date);
        $("#end_date").val(res.payload.convention.end_date);
      }
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.mem_state_area !== prevState.mem_state_area ||
      nextProps.s_campFromArea !== prevState.s_campFromArea ||
      nextProps.mem_state_region !== prevState.mem_state_region ||
      nextProps.states !== prevState.states ||
      nextProps.mem_state !== prevState.mem_state
    ) {
      return {
        s_campFromArea: nextProps.s_campFromArea,
        mem_state_area: nextProps.mem_state_area,
        mem_state_region: nextProps.mem_state_region,
        states: nextProps.states,
        mem_state: nextProps.mem_state,
      };
    }
    return null;
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    const { target } = e;
    this.setState(
      {
        creds: { ...this.state.creds, [e.target.id]: e.target.value },
      },
      () => {
        if (target.id === "type") {
          if (target.value === "national" || target.value === "international") {
            this.setState({ creds: { ...this.state.creds, state_id: "" } });
          }
        }
        if (target.id === "type" && target.value === "national") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.national_theme,
              number: this.state.national_number,
              aux_fee: this.state.aux_national_convention_fee,
              gideon_fee: this.state.gideon_national_convention_fee,
              both_fee: this.state.both_national_convention_fee,
            },
          });
        }
        if (target.id === "type" && target.value === "international") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.international_theme,
              number: this.state.international_number,
              aux_fee: this.state.aux_international_convention_fee,
              gideon_fee: this.state.gideon_international_convention_fee,
              both_fee: this.state.both_international_convention_fee,
            },
          });
        }
        if (target.id === "type" && target.value === "state") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.state_theme,
              number: this.state.state_number,
              aux_fee: this.state.aux_state_convention_fee,
              gideon_fee: this.state.gideon_state_convention_fee,
              both_fee: this.state.both_state_convention_fee,
            },
          });
        }
        if (target.id === "type" && target.value === "") {
          // alert("here");
          this.setState({
            creds: {
              ...this.state.creds,
              theme: "",
              number: "",
              aux_fee: "",
              gideon_fee: "",
              both_fee: "",
            },
          });
        }
      }
    );

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const start_date = $("#start_date").val();
    const end_date = $("#end_date").val();
    const creds = { ...this.state.creds };
    if (!creds.state_id) {
      delete creds.state_id;
    }
    this.setState({ creds: { ...creds, start_date, end_date } }, () => {
      this.props.updateNewActivityCon(this.state.creds).then((res) => {
        if (res.status === "success") {
          // const creds = {};
          // this.setState({ creds });
          // $("#start_date").val("");
          // $("#end_date").val("");
        }
      });
    });
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  render() {
    const {
      spinner,
      message,
      status,
      spinner1,
      message1,
      status1,
    } = this.props;
    if (
      (!spinner && status !== "error") ||
      (!spinner1 && status1 !== "error")
    ) {
      this.initDependency();
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFRENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Update Convention</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Update Convention</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {message1 && status1 ? (
                <Message message={message1} status={status1} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              {spinner1 ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label>
                        Type <span className="text-danger">*</span>
                      </label>
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("type"),
                          "form-control ": true,
                        })}
                        placeholder="Select"
                        onChange={this.handleChange}
                        id="type"
                        value={this.state.creds.type || ""}
                      >
                        <option value="">--Select--</option>
                        <option value="state">State</option>
                        <option value="national">National</option>
                        <option value="international">International</option>
                      </select>
                      {this.renderErrorFor("type")}
                    </div>
                    {this.state.creds.type === "state" ? (
                      <div className=" col-sm-6">
                        <label>State(TGIN)</label>
                        <div className="d-flex">
                          <select
                            className={classnames({
                              "is-invalid": this.hasErrorFor("state_id"),
                              "form-control selected_area": true,
                            })}
                            onChange={this.handleChange}
                            id="state_id"
                            value={this.state.creds.state_id || ""}
                          >
                            <option value="">--Select a State--</option>
                            {this.state.states &&
                              this.state.states.map((state, index) => (
                                <option key={index} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                          </select>
                          {/* {preference_spinner ? <MiniSpinner /> : null} */}
                        </div>
                        {this.renderErrorFor("state_id")}
                      </div>
                    ) : null}

                    <div className="form-group col-md-6">
                      <label htmlFor="theme">
                        Convention Theme <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("theme"),
                          "form-control": true,
                        })}
                        id="theme"
                        name="theme"
                        value={this.state.creds.theme || ""}
                        onChange={this.handleChange}
                        readOnly
                      />
                      {this.renderErrorFor("theme")}
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="number">
                        Convention Number <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("number"),
                          "form-control": true,
                        })}
                        id="number"
                        name="number"
                        value={this.state.creds.number || ""}
                        onChange={this.handleChange}
                        readOnly
                      />
                      {this.renderErrorFor("number")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="gideon_fee">
                        Gideon Convention Fee{" "}
                        <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("gideon_fee"),
                          "form-control": true,
                        })}
                        id="gideon_fee"
                        name="gideon_fee"
                        value={this.state.creds.gideon_fee || ""}
                        onChange={this.handleChange}
                        readOnly
                      />
                      {this.renderErrorFor("gideon_fee")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="aux_fee">
                        Auxilliary Convention Fee{" "}
                        <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("aux_fee"),
                          "form-control": true,
                        })}
                        id="aux_fee"
                        name="aux_fee"
                        value={this.state.creds.aux_fee || ""}
                        onChange={this.handleChange}
                        readOnly
                      />
                      {this.renderErrorFor("aux_fee")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="both_fee">
                        Couples Convention Fee{" "}
                        <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("both_fee"),
                          "form-control": true,
                        })}
                        id="both_fee"
                        name="both_fee"
                        value={this.state.creds.both_fee || ""}
                        onChange={this.handleChange}
                        readOnly
                      />
                      {this.renderErrorFor("both_fee")}
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="start_date">
                        State Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("start_date"),
                          "form-control": true,
                        })}
                        id="start_date"
                        name="start_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("start_date")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="end_date">
                        End Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("end_date"),
                          "form-control": true,
                        })}
                        id="end_date"
                        name="end_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("end_date")}
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="address">
                        Address <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("address"),
                          "form-control": true,
                        })}
                        id="address"
                        name="address"
                        value={this.state.creds.address || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("address")}
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="admin_fund">
                        Admin Fund <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("admin_fund"),
                          "form-control": true,
                        })}
                        id="admin_fund"
                        name="admin_fund"
                        value={this.state.creds.admin_fund || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("admin_fund")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="scripture_fund">
                        Scripture Fund <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("scripture_fund"),
                          "form-control": true,
                        })}
                        id="scripture_fund"
                        name="scripture_fund"
                        value={this.state.creds.scripture_fund || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("scripture_fund")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="city">
                        City <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("city"),
                          "form-control": true,
                        })}
                        id="city"
                        name="city"
                        value={this.state.creds.city || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("city")}
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="state">
                          State <span className="text-danger">*</span>
                        </label>
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("state"),
                            "form-control": true,
                          })}
                          id="state"
                          name="state"
                          value={this.state.creds.state || ""}
                          onChange={this.handleChange}
                        >
                          <option value="">- Select -</option>
                          <option value="Abuja FCT">Abuja FCT</option>
                          <option value="Abia">Abia</option>
                          <option value="Adamawa">Adamawa</option>
                          <option value="Akwa Ibom">Akwa Ibom</option>
                          <option value="Anambra">Anambra</option>
                          <option value="Bauchi">Bauchi</option>
                          <option value="Bayelsa">Bayelsa</option>
                          <option value="Benue">Benue</option>
                          <option value="Borno">Borno</option>
                          <option value="Cross River">Cross River</option>
                          <option value="Delta">Delta</option>
                          <option value="Ebonyi">Ebonyi</option>
                          <option value="Edo">Edo</option>
                          <option value="Ekiti">Ekiti</option>
                          <option value="Enugu">Enugu</option>
                          <option value="Gombe">Gombe</option>
                          <option value="Imo">Imo</option>
                          <option value="Jigawa">Jigawa</option>
                          <option value="Kaduna">Kaduna</option>
                          <option value="Kano">Kano</option>
                          <option value="Katsina">Katsina</option>
                          <option value="Kebbi">Kebbi</option>
                          <option value="Kogi">Kogi</option>
                          <option value="Kwara">Kwara</option>
                          <option value="Lagos">Lagos</option>
                          <option value="Nassarawa">Nassarawa</option>
                          <option value="Niger">Niger</option>
                          <option value="Ogun">Ogun</option>
                          <option value="Ondo">Ondo</option>
                          <option value="Osun">Osun</option>
                          <option value="Oyo">Oyo</option>
                          <option value="Plateau">Plateau</option>
                          <option value="Rivers">Rivers</option>
                          <option value="Sokoto">Sokoto</option>
                          <option value="Taraba">Taraba</option>
                          <option value="Yobe">Yobe</option>
                          <option value="Zamfara">Zamfara</option>
                        </select>
                        {this.renderErrorFor("state")}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="country">
                        Country <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("country"),
                          "form-control": true,
                        })}
                        id="country"
                        name="country"
                        value={this.state.creds.country || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("country")}
                    </div>

                    {/* <div className="form-group col-md-6"> */}
                    {/* <label htmlFor="location">
                        No of prospects<span className="text-danger">*</span>
                      </label> */}
                    {/* <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("location"),
                          "form-control": true,
                        })}
                        id="location"
                        name="location"
                        value={this.state.location}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("location")}
                    </div> */}
                    {/* <div className="form-group col-md-6">
                      <label htmlFor="funding">
                        No of Sign-Up<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="funding"
                        name="funding"
                        value={this.state.funding}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("funding")}
                    </div> */}
                    {/* <div className="form-group col-md-6">
                      <label htmlFor="speaker">
                        Amount Collected<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="speaker"
                        name="speaker"
                        value={this.state.speaker}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("speaker")}
                    </div> */}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold mr-1"
                    disabled={spinner || spinner1 ? true : false}
                  >
                    Update
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>

                  <Link
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                    to="/preferences/conventions/pending"
                  >
                    View Pending Activities
                  </Link>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    states1: state.preferences.states,
    spinner1: state.preferences.spinner,
    message1: state.preferences.message,
    status1: state.preferences.status,
    errors1: state.preferences.errors,
    states: state.preferences.states,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    updateNewActivityCon: (creds) => updateNewActivityCon(dispatch, creds),
    resetUsersState: () => resetUsersState(dispatch),
    getAllSettings2: () => getAllSettings2(dispatch),
    getAllStates: () => dispatch(getAllStates()),
    getOneCampActivityCon: (id) => getOneCampActivityCon(dispatch, id),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateActivityCon);
