import React, { Component } from "react";
import { connect } from "react-redux";
import {
  resetPreferencesState,
  assignMemberRole2,
  oneRole
} from "./../../../../store/actions/preferencesActions";
import Message from "./../../../helpers/Message";
import Spinner from "./../../../helpers/Spinner";
import { Link } from "react-router-dom";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import MiniSpinner from "../../../helpers/MiniSpinner";

class DetailsRolePermissions extends Component {
  state = {
    name: null,
    members: [],
    permissions: {}
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_ROLE" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    const id = this.props.match.params.id;
    // get the role from route param
    this.props.oneRole(id).then(res => {
      if (res.status === "success") {
        const arry_perms = {};
        const permissions = res.payload.role.permissions;
        // console.log(res);
        permissions.forEach(permission => {
          arry_perms[permission.for] = [];
        });
        Object.keys(arry_perms).forEach(key => {
          permissions.forEach(permission => {
            if (key === permission.for) {
              arry_perms[key].push(permission.name);
            }
          });
        });
        // console.log(res.payload.role);
        this.setState({
          members: res.payload.role.users,
          name: res.payload.role.name,
          permissions: arry_perms
        });
      }
    });
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  getMemberStatus = status => {
    if (status === "GOOD") {
      return "GOOD STANDING";
    } else if (status === "TRANS") {
      return "TRANSFERED";
    } else if (status === "DELI") {
      return "DELIQUENT";
    } else if (status === "DROP") {
      return "DROPPED";
    } else if (status === "ACTI") {
      return "ACTIVE";
    } else if (status === "EXIT") {
      return "EXIT";
    }
    return "UNKNOWN";
  };
  getUserType = type => {
    if (type === "A") {
      return "AUXILLARY";
    } else if (type === "G") {
      return "GIDEON";
    } else if (type === "S") {
      return "STAFF";
    }
    return "UNKNOWN";
  };
  handlecheckbox = e => {
    const is_checked = e.target.checked;
    const allmembers = document.querySelectorAll(".rm_member");
    if (allmembers) {
      allmembers.forEach(member => (member.checked = is_checked));
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    const allmembers = Array.from(document.querySelectorAll(".rm_member"));
    const newArry = allmembers.filter(member => member.checked);
    if (newArry.length) {
      if (
        window.confirm("Are you sure you want to remove the selected user(s)?")
      ) {
        const arryIdRemoved = newArry.map(item => item.value);
        // const arryIdInRole = this.state.members.map(item => item.id);

        const creds = {
          roleId: this.props.match.params.id,
          users: arryIdRemoved
        };
        this.props.assignMemberRole2(creds).then(res => {
          if (res.status === "success") {
            // console.log(res);
            this.setState({ members: res.payload.role.users });
          }
        });
      }
    }
    return;
  };

  removeFromArray = (original, remove) => {
    return original.filter(value => !remove.includes(value));
  };
  
  render() {
    const { spinner, status, message } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
           <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.name}</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                <span className="text-capitalize ">{this.state.name}</span>{" "}
                Details
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}

              {spinner ? (
                <Spinner position="right" />
              ) : (
                <div>
                  <div className="col-12">
                    <h5>
                      {" "}
                      Members Details <small>({this.state.name})</small>
                    </h5>
                  </div>
                  <form onSubmit={this.handleSubmit}>
                    <table
                      id="detail_table"
                      className="table table-hover table-bordered table-striped"
                      style={{
                        width: "100%"
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Member Type</th>
                          <th>Login ID</th>
                          <th>Status</th>
                          <th>Camp</th>
                          <th>Area</th>
                          <th>Region</th>
                          <th>State</th>
                          <th className="d-flex">
                            Remove
                            <div className="form-check ml-1">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                onClick={this.handlecheckbox}
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.members &&
                          this.state.members.map((member, index) => (
                            <tr key={index}>
                              <td>
                                {member.title} {member.first_name}{" "}
                                {member.last_name}
                              </td>
                              <td>{member.email}</td>
                              <td>{this.getUserType(member.type)}</td>
                              <td>{member.login_id}</td>
                              <td>{this.getMemberStatus(member.status)}</td>
                              <td>{member.camp?.name || "--"}</td>
                              <td>{member.camp?.area?.name || "--"}</td>
                              <td>{member.camp?.area?.region?.name || "--"}</td>
                              <td>
                                {member.camp?.area?.region?.state.name || "--"}
                              </td>
                              <td>
                                <div className="form-check ml-5">
                                  <input
                                    className="form-check-input rm_member"
                                    type="checkbox"
                                    value={member.id}
                                  />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                    <button
                      type="submit"
                      className="btn btn-warning btn-sm text-uppercase font-weight-bold mt-2"
                      disabled={spinner ? true : false}
                    >
                      Remove Member(s)
                      {spinner ? <MiniSpinner color="white" /> : null}
                    </button>
                  </form>

                  <hr className="my-5" />
                  <div className="col-12">
                    <h5>
                      {" "}
                      Permissions Details <small>({this.state.name})</small>
                    </h5>
                  </div>
                  <table
                    id="detail_table1"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%"
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Permissions</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.permissions &&
                        Object.keys(this.state.permissions).map(
                          (key, index) => (
                            <tr key={index}>
                              <td>{key}</td>
                              <td>
                                {this.state.permissions[key].map(
                                  (action, index) => (
                                    <span
                                      key={index}
                                      className="badge badge-primary  mx-1 my-1 p-1 text-uppercase"
                                    >
                                      {action}
                                    </span>
                                  )
                                )}
                              </td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                  <Link
                    to="/preferences/roles-and-permissions"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold mt-2"
                  >
                    Back
                  </Link>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    message: state.preferences.message,
    spinner: state.preferences.spinner,
    errors: state.preferences.errors,
    status: state.preferences.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    resetPreferencesState: () => resetPreferencesState(dispatch),
    oneRole: id => oneRole(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    assignMemberRole2: creds => assignMemberRole2(dispatch, creds)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsRolePermissions);
