import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  getAllBibles,
  createDepotInventory,
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import $ from "jquery";
import { storage_type, user_auth_details, dehash } from "../../../../config";
import axios from "axios";
import {
  BASE_URL,
  token,
} from "../../../../config";

class CreateDepotIventory extends Component {
  state = {
    depot: null, //Added by Macdonald
    stock: "",
    bible_id: "",
    bibles: null,
    comment: "",
    depotId: '' //Added by Macdonald
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_DEPOT_INVENTORY" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      if (storage_type.getItem(user_auth_details)) {
        const user = JSON.parse(
          dehash(`${storage_type.getItem(user_auth_details)}`)
        );
        this.setState({ depot: user.depots, depotId:window.$depotID });
      }
      this.props.getAllBibles();
    }
    
    //---------------------CODE ADDED BY MACDONALD------------------------
    const id = this.props.match.params.id;
    console.log("Depot ID: ", id);
    axios.get(`${BASE_URL}/get-depot4Inventory?id=${id}`,
      {method:'GET',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage_type.getItem(token)}`},
      }, 
      ).then(response => {
        this.setState({  
          depot: response.data.payload.Depot,
          depotId:id
        });  
      });

      //----------------------------------END------------------------------------------


  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.bibles !== prevState.bibles) {
      return {
        bibles: nextProps.bibles,
      };
    }
    return null;
  }

  //   handleSelectArea = e => {
  //     this.setState({ st_id: e.target.value });
  //   };
  handleSubmit = (e) => {
    e.preventDefault();
    const { createDepotInventory } = this.props;
    const creds = {
      bible_id: this.state.bible_id,
      stock: this.state.stock,
      comment: this.state.comment,
      depotId: this.state.depotId //Added by Macdonald
    };
    console.log("Depot ID from Global Variable: ", creds.depotId);
    createDepotInventory(creds).then((res) => {
      if (res.status === "success") {
        this.setState({
          stock: "",
          bible_id: "",
          comment: "",
        });
        $(".selected_state").val("");
      }
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Add Depot Inventory
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Add Depot Inventory{" "}
                {this.state.depot ? `(${this.state.depot[0].name + "-" + this.state.depot[0].address})` : null}
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Stock Items <span className="text-danger">*</span>
                    </label>
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("bible_id"),
                        "form-control selected_state": true,
                      })}
                      placeholder="Select a State"
                      onChange={this.handleChange}
                      id="bible_id"
                      defaultValue={this.state.bible_id}
                    >
                      <option value="">--Select--</option>
                      {this.state.bibles &&
                        this.state.bibles.map((bible, index) => (
                          <option key={index} value={bible.id}>
                            {bible.name} [{" "}
                            {bible.merchandise === 1
                              ? "Cash item"
                              : "Non cash item"}{" "}
                            ]
                          </option>
                        ))}
                    </select>
                    {this.renderErrorFor("bible_id")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="stock">
                      Stock Quantity <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("stock"),
                        "form-control": true,
                      })}
                      id="stock"
                      name="stock"
                      value={this.state.stock}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("stock")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="comment">
                      Comment <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className={classnames({
                        "is-invalid": this.hasErrorFor("comment"),
                        "form-control": true,
                      })}
                      id="comment"
                      name="comment"
                      value={this.state.comment}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("comment")}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Add
                </button>
                <Link
                  to="/preferences/depot-inventory"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                >
                  View Inventory
                </Link>
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    bibles: state.preferences.bibles,
    errors: state.preferences.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllBibles: () => dispatch(getAllBibles()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    createDepotInventory: (creds) => createDepotInventory(dispatch, creds),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateDepotIventory);
