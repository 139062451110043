import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  resetUsersState,
  getDetailReport,
  RejectActivityReport,
} from "../../../../store/actions/usersActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import MiniSpinner from "../../../helpers/MiniSpinner";
// import $ from "jquery";
import uuid from "uuid";

// 10.10.1.227

class DetailAuthorizedActReport extends Component {
  state = {
    camp_inventory: [],
    bibles: [],
    new_gideon: "",
    new_aux: "",
    prospect_gideon: "",
    prospect_aux: "",
    modal_2: false,
    reason: "",
  };
  componentDidMount() {
    const { setPermissionsErrors, getDetailReport } = this.props;
    if (
      !hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD",
      }) ||
      !hasPermission({
        mod: "Personal Management",
        action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    const id = this.props.match.params.id;
    getDetailReport(id).then((res) => {
      if (res.status === "success") {
        // console.log("dfdt", res);
        this.setState(
          {
            details: res.payload.details,
            ...res.payload.report,
          },
          () => {
            const bibles = {};
            this.state.details.forEach((item) => {
              //   let id = uuid.v1();
              let name = item.name;
              let distributed = item.distributed;
              let requested = item.requested;
              let supplied = item.supplied;
              bibles[name] = {
                name: name,
                requested,
                supplied: parseInt(supplied),
                distributed: bibles[name]
                  ? [...bibles[name]["distributed"], parseInt(distributed)]
                  : [parseInt(distributed)],
              };
            });
            const details = this.state.details.sort((a, b) =>
              a.bible_id > b.bible_id ? 1 : b.bible_id > a.bible_id ? -1 : 0
            );

            this.setState({ bibles, details });
          }
        );
      }
    });
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
  };
  handleChangeArea = (e) => {
    const id = e.target.dataset.id;
    // console.log(id);
    const bibles = this.state.bibles;
    // eslint-disable-next-line
    const bible = bibles.find((item) => item.id == id);
    // console.log(id, bible);
    bible.target_area = e.target.value;
    this.setState({ bibles });
  };
  handleChangeDistrubted = (e) => {
    e.preventDefault();
    const id = e.target.dataset.id;
    const bibles = this.state.bibles;
    // eslint-disable-next-line
    const bible = bibles.find((item) => item.id == id);
    // console.log(id, bible);
    bible.distributed = e.target.value;
    this.setState({ bibles });
  };
  handleChangeAddress = (e) => {
    e.preventDefault();
    const id = e.target.dataset.id;
    const bibles = this.state.bibles;
    // eslint-disable-next-line
    const bible = bibles.find((item) => item.id == id);
    // console.log(id, bible);
    bible.address = e.target.value;
    this.setState({ bibles });
  };
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  handleSubmitReject = (e) => {
    e.preventDefault();
    const creds = {
      reason: this.state.reason,
      id: this.props.match.params.id,
    };
    this.props.RejectActivityReport(creds).then((res) => {
      if (res.status === "success") {
        this.setState({
          modal_2: false,
          reason: "",
        });
        setTimeout(() => {
          this.props.history.push("/camp/activity-report/pending");
        }, 1500);
      }
    });
  };
  getStatus = (status) => {
    if (status === "P") {
      return "PENDING";
    } else if (status === "U") {
      return "PENDING";
    } else if (status === "R") {
      return "REJECTED";
    } else if (status === "A") {
      return "AUTHORIZED";
    }
  };
  getBible = (id) => {
    // eslint-disable-next-line
    return this.state.bibles.find((item) => item.id == id);
  };
  addRowTest = (e) => {
    e.preventDefault();
    const bible = this.getBible(e.target.id);
    // console.log(e.target.tagName);
    // const bibles = this.state.bibles;
    if (bible.type === "addition") {
      const bible = this.getBible(e.target.id);
      // console.log(bible);
      const bibles = this.state.bibles;
      const key = uuid.v1();
      bibles.push({
        id: key,
        key: key,
        bible_id: bible.bible_id,
        name: bible.name,
        supplied: bible.supplied,
        distributed: "",
        target_area: "",
        address: "",
        type: "subtration",
      });
      bibles.sort((a, b) =>
        a.bible_id > b.bible_id ? 1 : b.bible_id > a.bible_id ? -1 : 0
      );
      this.setState({ bibles });
    } else if (bible.type === "subtration") {
      const bible = this.getBible(e.target.id);
      // console.log(bible);
      const bibles = this.state.bibles;
      // eslint-disable-next-line
      const rem_bibles = bibles.find((item) => item.id == bible.id);

      rem_bibles.distributed = "";
      rem_bibles.target_area = "";
      rem_bibles.address = "";
      this.setState({ bibles }, () => {
        const new_bibles = bibles.filter((item) => item.id !== bible.id);
        this.setState({ bibles: new_bibles });
      });
    }
  };
  closeModal_2 = (e) => {
    this.setState({ modal_2: false, comment: "" });
    this.props.resetUsersState();
  };
  rejectReport = (e) => {
    e.preventDefault();
    if (window.confirm("Are you sure you reject this report?")) {
      this.setState({ modal_2: true });
    }
  };
  render() {
    const { spinner, status, message } = this.props;

    const table = [];
    if (this.state.bibles) {
      Object.entries(this.state.bibles).forEach(([key, value]) => {
        table.push(
          <tr key={key}>
            <td>{key}</td>
            <td>{value.supplied}</td>
            <td>{value.distributed.reduce((a, b) => a + b, 0)}</td>
            <td>
              {" "}
              {value.supplied - value.distributed.reduce((a, b) => a + b, 0)}
            </td>
          </tr>
        );
      });
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">PERSONAL</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Scripture Requisition Report
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Scripture Requisition Report (Scripture Distribution)
              </h3>
              <div className="card-tools">
                <button
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  onClick={(e) => {
                    this.props.history.goBack();
                  }}
                >
                  <i className="fas fa-arrow-circle-left "></i> Back
                </button>
              </div>
            </div>
            <form id="request-form">
              <div className="card-body">
                {this.state.modal_2 ? (
                  <div
                    //   className="card opp"
                    //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                    style={{
                      position: "fixed",
                      top: "0px",
                      right: "0px",
                      bottom: "0px",
                      left: "0px",
                      zIndex: 9999999,
                      backgroundColor: "rgba(0,0,0,0.5)",
                      // padding: "5%",
                      display: "flex",
                      justifyContent: "center",
                      overflowX: "hidden",

                      alignItems: "center",

                      // transition: "all 10s ease"
                    }}
                  >
                    <div
                      className="card-body"
                      style={{
                        backgroundColor: "#fff",
                        height: "300px",
                        overflowX: "hidden",
                      }}
                    >
                      <form onSubmit={this.handleSubmit}>
                        <h4 className="text-center mt-3">
                          {" "}
                          Enter Rejection Reason
                        </h4>
                        <div className="col-sm-6 offset-sm-3 ">
                          <div className="form-group">
                            <label htmlFor="reason">
                              Reason <span className="text-danger">*</span>{" "}
                            </label>
                            <textarea
                              className={classnames({
                                "is-invalid": this.hasErrorFor("reason"),
                                "form-control": true,
                              })}
                              id="reason"
                              name="reason"
                              rows={2}
                              onChange={this.handleChange}
                              value={this.state.reason}
                            />

                            {this.renderErrorFor("reason")}
                          </div>

                          <button
                            onClick={this.handleSubmitReject}
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                            disabled={spinner ? true : false}
                          >
                            Reject
                            {spinner ? <MiniSpinner color="white" /> : null}
                          </button>
                          <button
                            className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                            disabled={spinner ? true : false}
                            onClick={this.closeModal_2}
                          >
                            Cancel
                          </button>
                        </div>
                      </form>
                    </div>

                    {/* /.card-body */}
                  </div>
                ) : null}
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}

                {spinner ? (
                  <div>
                    <Spinner />
                  </div>
                ) : null}
                <div
                  className="card"
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <div className="card-body ">
                    <div
                      className="row d-flex justify-content-between"
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <div>
                        <p className="font-weight-bold">
                          Activity Status:{" "}
                          <span className="font-italic">
                            {this.state.activity
                              ? this.state.activity.status === "P"
                                ? "PENDING"
                                : "AUTHORIZED"
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Activity Type:{" "}
                          <span>
                            {this.state.activity
                              ? this.state.activity.type
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Activity Description:{" "}
                          <span>
                            {this.state.activity
                              ? this.state.activity.description
                              : null}
                          </span>{" "}
                        </p>
                      </div>
                      <div>
                        <p className="font-weight-bold">
                          Report Status:{" "}
                          <span className="font-italic">
                            {this.state.status
                              ? this.getStatus(this.state.status)
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Authorized By:{" "}
                          <span className="font-italic">
                            {this.state.authorised_by
                              ? `${this.state.authorised_by.first_name} ${
                                  this.state.authorised_by.last_name
                                } ${
                                  this.state.authorised_by.other_name
                                    ? this.state.authorised_by.other_name
                                    : ""
                                }`
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Created Date:{" "}
                          <span className="font-italic">
                            {/* {this.moneyConv(this.state.stock)} */}
                            {this.state.created_at
                              ? this.dateConv(this.state.created_at)
                              : null}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-center font-weight-bold">
                          {" "}
                          Members Participation
                        </p>
                      </div>
                      {/* <div className="col-md-6 mx-auto d-flex"> */}
                      <div className="col-md-4">
                        Gideons:
                        <input
                          type="number"
                          min="0"
                          id="new_gideon"
                          readOnly
                          defaultValue={this.state.new_gideon}
                        />
                      </div>
                      <div className="col-md-4">
                        Auxilliary:
                        <input
                          type="number"
                          min="0"
                          id="new_aux"
                          defaultValue={this.state.new_aux}
                          readOnly
                        />
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-4 ">
                        <p className="text-center font-weight-bold">
                          {" "}
                          Prospects/Observers
                        </p>
                      </div>
                      {/* <div className="col-md-6 mx-auto d-flex"> */}
                      <div className="col-md-4">
                        Gideons:
                        <input
                          type="number"
                          min="0"
                          id="prospect_gideon"
                          readOnly
                          defaultValue={this.state.prospect_gideon}
                        />
                      </div>
                      <div className="col-md-4">
                        Auxilliary:
                        <input
                          type="number"
                          min="0"
                          id="prospect_aux"
                          readOnly
                          defaultValue={this.state.prospect_aux}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <h5>Summarized Report</h5>
                  <table
                    className="table table-hover table-bordered table-striped"
                    id="dynamic_field44"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Scriptures</th>
                        <th>Supplied Quantity</th>
                        <th>Distributed Quantity</th>
                        <th> Expected Returned Quantity</th>
                      </tr>
                    </thead>
                    <tbody id="">{table}</tbody>
                  </table>
                </div>
                <div key="uniqueKey" className="row t45">
                  <h5>Detailed Report</h5>
                  <table
                    className="table table-hover table-bordered table-striped"
                    id="dynamic_field"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Scriptures</th>
                        <th>Supplied Quantity</th>
                        <th>Distributed Quantity</th>
                        <th>Target Area</th>
                        <th>Recipient's Address</th>
                      </tr>
                    </thead>
                    <tbody id="try">
                      {this.state.details &&
                        this.state.details.map((item, index) => {
                          return (
                            <tr key={index} id={`row-${item.bible_id}`}>
                              <td>{item.name}</td>
                              <td>{item.supplied}</td>
                              <td>{item.distributed}</td>
                              <td>{item.target_area}</td>
                              <td>{item.address}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch),
    getDetailReport: (id) => getDetailReport(dispatch, id),
    RejectActivityReport: (creds) => RejectActivityReport(dispatch, creds),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailAuthorizedActReport);
