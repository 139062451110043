import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import { connect } from "react-redux";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import {
  getAllMembers,
  errorCreateKit,
  createKit,
  verifyRemita,
} from "./../../../../store/actions/usersActions";
import classnames from "classnames";

class VerifyRemita extends Component {
  state = {
    transaction_id: "",
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "VERIFY_PAYMENTS" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      //   if (this.props.members.length) {
      //     this.setState({ members: this.props.members });
      //   } else {
      //     getAllMembers();
      //   }
    }
  }

  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.members !== prevState.members) {
      return {
        members: nextProps.members,
      };
    }
    return null;
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.verifyRemita(this.state.transaction_id).then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload });
      }
    });
  };

  handleChange = (e) => {
    // alert("here");
    this.setState({ [e.target.name]: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  covMoney = (amount) => {
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>MEMBERSHIP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Verify Remita Payment
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Verify Remita Payment
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? <Spinner /> : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="transaction_id">
                      Transaction ID / Reference ID{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("transaction_id"),
                        "form-control": true,
                      })}
                      name="transaction_id"
                      id="transaction_id"
                      onChange={this.handleChange}
                      value={this.state.transaction_id}
                    />
                    {this.renderErrorFor("transaction_id")}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold mt-2"
                  disabled={spinner ? true : false}
                >
                  Submit
                </button>
              </form>
              <hr />
              {this.state.amount ? (
                <div key="uniqueKey">
                  <table
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Data</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Amount</td>
                        <td>&#8358; {this.covMoney(this.state.amount)}</td>
                      </tr>
                      <tr>
                        <td>RRR</td>
                        <td> {this.state.RRR}</td>
                      </tr>
                      <tr>
                        <td>Message</td>
                        <td> {this.state.message}</td>
                      </tr>
                      <tr>
                        <td>Order Id</td>
                        <td> {this.state.orderId}</td>
                      </tr>
                      <tr>
                        <td>Payment Date</td>
                        <td> {this.state.paymentDate}</td>
                      </tr>
                      <tr>
                        <td>Status</td>
                        <td> {this.state.status}</td>
                      </tr>
                      <tr>
                        <td>Transaction Time</td>
                        <td> {this.state.transactiontime}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    message: state.users.message,
    spinner: state.users.spinner,
    errors: state.users.errors,
    status: state.users.status,
    members: state.users.members,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllMembers: () => dispatch(getAllMembers()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    errorCreateKit: () => errorCreateKit(dispatch),
    createKit: (creds) => createKit(dispatch, creds),
    verifyRemita: (id) => verifyRemita(dispatch, id),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifyRemita);
