import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../../hooks";
import {
  useGetCampMemberGoalRegion,
  useGetSystemRegions,
} from "../../../../store/hookactions/notificationsActionhooks";
import MiniSpinner from "../../../helpers/MiniSpinner";
import Message from "../../../helpers/Message";
import Spinner from "../../../helpers/Spinner";
import $ from "jquery";
import {
  // submitCampMessage,
  resetNotificationState,
} from "../../../../store/actions/notificationActions";
import { useDispatch } from "react-redux";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
// import { storage_type, user_auth_details, dehash } from "../../../../config";

function ViewSetMemberGoalRegionHQ(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [enabled, setEnabled] = useState(false);
  const [cars_Info, setCarInfo] = useState();
  const [previous_year, setPrevious] = useState("");
  const [current_year, setCurrent] = useState("");
  const [gid_year, setGidYear] = useState(null);
  const [enabled_region, setEnabledRegion] = useState(true);
  //   console.log(cars_Info);

  useGetCampMemberGoalRegion(
    enabled,
    setEnabled,
    cars_Info,
    "region",
    gid_year
  );

  useGetSystemRegions(enabled_region, setEnabledRegion);

  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const status = useShallowEqualSelector((state) => state.notifications.status);
  const errors = useShallowEqualSelector((state) => state.notifications.errors);

  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );

  const region_goals_view_member = useShallowEqualSelector(
    (state) => state.notifications.region_goals_view_member
  );
  const spinner_pref = useShallowEqualSelector(
    (state) => state.preferences.spinner
  );

  const regions = useShallowEqualSelector((state) => state.preferences.regions);

  function handleSubmit(e) {
    e.preventDefault();
    const gideon_year = $("#only_year").val();
    const id = $(".memberselect22").val();

    setCarInfo(id);
    setGidYear(gideon_year);
    setEnabled(true);
  }
  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  useLayoutEffect(() => {
    initDependency();
  }, [region_goals_view_member]);

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Goals",
        action: "VIEW_REGION_GOAL_REPORT_HQ",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();

    return () => {
      resetNotificationState(dispatch);
      dispatch({ type: "CLEAR_USERS_ERRORS" });
    };
  }, [dispatch, history]);

  //   useEffect(() => {
  //     if (Object.keys(cars_Info).length) {
  //       setEnabled(true);
  //     }
  //   }, [cars_Info]);

  useEffect(() => {
    if (region_goals_view_member?.length) {
      const year_previous = region_goals_view_member[0].total?.previous.year;
      const year_current = region_goals_view_member[0].total?.current.year;

      setPrevious(year_previous);
      setCurrent(year_current);

      setEnabled(true);
    }
  }, [region_goals_view_member]);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Goals</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  View Membership Goal HQ
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              View Membership Goal HQ{" "}
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}
            <form onSubmit={handleSubmit}>
              <div className="row  ">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="from_date">Gideon Year</label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": hasErrorFor("gideon_year"),
                        "form-control": true,
                      })}
                      id="only_year"
                      name="gideon_year"
                      readOnly
                      placeholder="Select date"
                    />
                    {renderErrorFor("gideon_year")}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="camp_id">
                      Region <span className="text-danger">*</span>{" "}
                    </label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": hasErrorFor("camp_id"),
                          "form-control": true,
                          memberselect22: true,
                        })}
                        placeholder="Select a Camp"
                        id="camp_id"
                        name="camp_id"
                        style={{
                          width: "100%",
                        }}
                      >
                        <option value="">--Select region--</option>
                        {regions &&
                          regions.map((area, index) => (
                            <option key={area.id} value={area.id}>
                              {area.name}-- {area.state.name}
                            </option>
                          ))}
                      </select>
                      {spinner_pref ? <MiniSpinner /> : null}
                    </div>
                    {renderErrorFor("camp_id")}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  Generate
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
            </form>

            {!spinner && region_goals_view_member.length ? (
              <div key="uniqueKey">
                <table
                  id="example"
                  className="table table-bordered"
                  style={{
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>REGION</th>
                      <th>AREA</th>
                      <th>CAMP</th>
                      <th>CAMP NO.</th>
                      <th>GIDEON</th>
                      <th>GIDEON</th>
                      <th>AUXILIARY</th>
                      <th>AUXILIARY </th>
                      <th>TOTAL</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>({previous_year})₦</th>
                        <th>({current_year})₦</th>
                        <th>({previous_year})₦</th>

                        <th>({current_year})₦</th>
                        <th>({previous_year})₦</th>
                        <th>({current_year})₦</th>
                      </tr>
                    }

                    {region_goals_view_member.map((item, index) => {
                      return (
                        <>
                          <tr key={item.camp_name}>
                            <th>{item.region}</th>
                            <th>{item.area}</th>
                            <th>{item.camp_name}</th>
                            <th>{item.camp_no}</th>
                            <td>{item.gideon.previous.total || "--"}</td>
                            <td>{item.gideon.current.total || "--"}</td>
                            <td>{item.aux.previous.total || "--"}</td>
                            <td>{item.aux.current.total || "--"}</td>

                            <td>{item.total.previous.total || "--"}</td>
                            <td>{item.total.current.total || "--"}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {/* {initDependency()} */}
              </div>
            ) : null}
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default ViewSetMemberGoalRegionHQ;
