import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createReportOneRec,
  getOneCampActivityMet,
  resetUsersState,
} from "./../../../../store/actions/usersActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
// import classnames from "classnames";
import $ from "jquery";
// import MiniSpinner from "../../../helpers/MiniSpinner";

class ViewReportMet extends Component {
  state = {
    type: "",
    activity_date: "",
    location: "",
    target: "",
    description: "",
    funding: "",
    speaker: "",
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Camp Administration",
        action: "VIEW_CAMP_ADMINISTRATION",
      }) ||
      !hasPermission({
        mod: "Camp Administration",
        action: "VIEW_CAMP_ACTIVITY",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    const id = this.props.match.params.id;

    this.props.getOneCampActivityMet(id).then((res) => {
      if (res.status === "success") {
        this.setState({
          type: res.payload.activity.type,
          activity_date: res.payload.activity.activity_date,
          location: res.payload.activity.location,
          created_at: res.payload.activity.created_at,

          gideon: res.payload.activity.gideon,
          auxilliary: res.payload.activity.auxilliary,
          scripture_offering: res.payload.activity.scripture_offering,
          faith_offering: res.payload.activity.faith_offering,
          admin_offering: res.payload.activity.admin_offering,
          //   female: res.payload.activity.report.female,
          //   guest: res.payload.activity.report.guest,
          //   speaker: res.payload.activity.report.speaker,
          //   prospect: res.payload.activity.prospect,
          //   signup: res.payload.activity.report.signup,
          //   date_report: res.payload.activity.report.created_at,
        });
        $("#activity_date").val(res.payload.activity.activity_date);
      }
    });
    this.setState({ id });
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const activity_date = $("#activity_date").val();
    const id = this.props.match.params.id;
    if (window.confirm("Are you sure you want to submit this report?")) {
      this.setState({ activity_date, id }, () => {
        this.props.createReportOneRec(this.state).then((res) => {
          if (res.status === "success") {
            //   this.setState({
            //     type: "",
            //     activity_date: "",
            //     activity_time: "",
            //     location: "",
            //     target: "",
            //     description: "",
            //     funding: "",
            //     speaker: "",
            //   });
            //   $("#activity_date").val("");
          }
        });
      });
    }
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>CAMP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    View Activity Report
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                View Activity Report (Camp Meetings)
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <div>
                <div className="row text-center">
                  <div className="col-12">
                    <p className="lead">Activity Details</p>
                  </div>
                </div>
                <div
                  className="row d-flex justify-content-between"
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <div>
                    <p className="font-weight-bold">
                      Activity Type:{" "}
                      <span className="font-italic">
                        {this.state.type ? this.state.type : null}
                      </span>{" "}
                    </p>
                    {/* <p className="font-weight-bold">
                      Expected Funding:{" "}
                      <span>
                        {" "}
                        &#8358;
                        {this.state.funding
                          ? this.moneyConv(this.state.funding)
                          : 0}
                      </span>{" "}
                    </p> */}
                    <p className="font-weight-bold">
                      Location:{" "}
                      <span>
                        {this.state.location ? this.state.location : null}
                      </span>{" "}
                    </p>
                  </div>
                  <div>
                    {/* <p className="font-weight-bold">
                      Location:{" "}
                      <span className="font-italic">
                        {this.state.location ? this.state.location : null}
                      </span>{" "}
                    </p>
                    <p className="font-weight-bold">
                      Speaker:{" "}
                      <span className="font-italic">
                        {this.state.speaker ? this.state.speaker : null}
                      </span>{" "}
                    </p> */}
                    <p className="font-weight-bold">
                      Created Date:{" "}
                      <span className="font-italic">
                        {/* {this.moneyConv(this.state.stock)} */}
                        {this.state.created_at
                          ? this.dateConv(this.state.created_at)
                          : null}
                      </span>{" "}
                    </p>
                    <p className="font-weight-bold">
                      Scheduled Date:{" "}
                      <span className="font-italic">
                        {/* {this.moneyConv(this.state.stock)} */}
                        {this.state.activity_date
                          ? this.dateConv(this.state.activity_date)
                          : null}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                <hr />
                <div className="row text-center">
                  <div className="col-12">
                    <p className="lead">Report Details</p>
                  </div>
                </div>
                <form>
                  <div className="row justify-content-between">
                    <div>
                      <div>
                        <label htmlFor="amount">
                          Attendance (Gideons) :
                          {this.state.gideon ? this.state.gideon : null}{" "}
                        </label>
                      </div>
                      <div>
                        <label htmlFor="speaker">
                          Attendance (Auxiliary) :
                          {this.state.auxilliary ? this.state.auxilliary : null}{" "}
                        </label>
                      </div>
                      <div>
                        <label htmlFor="amount">
                          Offering (Scripture) : &#8358;{" "}
                          {this.state.scripture_offering
                            ? this.moneyConv(this.state.scripture_offering)
                            : null}{" "}
                        </label>
                      </div>
                    </div>
                    <div>
                      <div>
                        <label htmlFor="amount">
                          Offering (Admin) : &#8358;{" "}
                          {this.state.admin_offering
                            ? this.moneyConv(this.state.admin_offering)
                            : null}{" "}
                        </label>
                      </div>
                      <div>
                        <label htmlFor="amount">
                          Offering (Faith) : &#8358;{" "}
                          {this.state.faith_offering
                            ? this.moneyConv(this.state.faith_offering)
                            : null}{" "}
                        </label>
                      </div>

                      {/* <div>
                        <label htmlFor="prospect">
                          Prospect: {this.state.prospect || null}
                        </label>
                      </div> */}
                    </div>
                  </div>

                  <Link
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                    to="/camp/activities/meetings/completed/report/"
                  >
                    Back
                  </Link>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    createReportOneRec: (creds) => createReportOneRec(dispatch, creds),
    resetUsersState: () => resetUsersState(dispatch),
    getOneCampActivityMet: (id) => getOneCampActivityMet(dispatch, id),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewReportMet);
