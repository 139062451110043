import React from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../../helpers/functions/functions";

function NotificationsAside(props) {
  const { handleActiveLink } = props;
  return (
    <React.Fragment>
      {hasPermission({
        mod: "Notification",
        action: "VIEW_NOTIFICATIONS",
      }) ? (
        <li className="nav-item has-treeview">
          <a href="#!" className="nav-link">
            <i className="nav-icon fas fa-chart-pie" />
            <p>
              NOTIFICATIONS
              <i className="right fas fa-angle-left" />
            </p>
          </a>
          <ul className="nav nav-treeview">
            {hasPermission({
              mod: "Notification",
              action: "SEND_NOTIFICATION_TO_CAMP",
            }) ? (
              <li className="nav-item has-treeview">
                <a href="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>
                    Camp Messages
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/camps/send-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> Send Message </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/camps/sent-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Sent Messages</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/camps/pending-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Pending Messages</p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            {/* change permissions to SEND_NOTIFICATION_TO_CAMP_HQ */}
            {hasPermission({
              mod: "Notification",
              action: "SEND_NOTIFICATION_TO_CAMP_HQ",
            }) ? (
              <li className="nav-item has-treeview">
                <a href="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>
                    Camp Messages HQ
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/camps/send-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> Send Message </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/camps/sent-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Sent Messages</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/camps/pending-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Pending Messages</p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            {/* area */}
            {hasPermission({
              mod: "Notification",
              action: "SEND_NOTIFICATION_TO_AREA",
            }) ? (
              <li className="nav-item has-treeview">
                <a href="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>
                    Area Messages
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/areas/send-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> Send Message </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/areas/sent-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Sent Messages</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/areas/pending-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Pending Messages</p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            {hasPermission({
              mod: "Notification",
              action: "SEND_NOTIFICATION_TO_AREA_HQ",
            }) ? (
              <li className="nav-item has-treeview">
                <a href="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>
                    Area Messages HQ
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/areas/send-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> Send Message </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/areas/sent-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Sent Messages</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/areas/pending-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Pending Messages</p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}



            {/* region */}
            {hasPermission({
              mod: "Notification",
              action: "SEND_NOTIFICATION_TO_REGION",
            }) ? (
              <li className="nav-item has-treeview">
                <a href="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>
                    Region Messages
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/regions/send-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> Send Message </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/regions/sent-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Sent Messages</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/regions/pending-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Pending Messages</p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}

            {hasPermission({
              mod: "Notification",
              action: "SEND_NOTIFICATION_TO_REGION_HQ",
            }) ? (
              <li className="nav-item has-treeview">
                <a href="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>
                    Region Messages HQ
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/regions/send-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> Send Message </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/regions/sent-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Sent Messages</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/regions/pending-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Pending Messages</p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            {/* state */}
            {hasPermission({
              mod: "Notification",
              action: "SEND_NOTIFICATION_TO_STATE",
            }) ? (
              <li className="nav-item has-treeview">
                <a href="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>
                    State Messages
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/states/send-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> Send Message </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/states/sent-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Sent Messages</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/states/pending-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Pending Messages</p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            {hasPermission({
              mod: "Notification",
              action: "SEND_NOTIFICATION_TO_STATE_HQ",
            }) ? (
              <li className="nav-item has-treeview">
                <a href="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>
                    State Messages HQ
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/states/send-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> Send Message </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/states/sent-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Sent Messages</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/states/pending-message/hq"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Pending Messages</p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
            {/* national */}
            {hasPermission({
              mod: "Notification",
              action: "SEND_NOTIFICATION_TO_NATIONAL",
            }) ? (
              <li className="nav-item has-treeview">
                <a href="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>
                    National Messages
                    <i className="right fas fa-angle-left" />
                  </p>
                </a>
                <ul className="nav nav-treeview">
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/national/send-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> Send Message </p>
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/national/sent-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Sent Messages</p>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      onClick={handleActiveLink}
                      to="/notifications/national/pending-message"
                      className="nav-link"
                    >
                      <i className="far fa-dot-circle nav-icon" />
                      <p> View Pending Messages</p>
                    </Link>
                  </li>
                </ul>
              </li>
            ) : null}
          </ul>
        </li>
      ) : null}
    </React.Fragment>
  );
}

export default NotificationsAside;
