import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  createStaff,
  resetUsersState
} from "../../../store/actions/usersActions";

// import Spinner from "../../helpers/Spinner";
import Message from "../../helpers/Message";
import { hasPermission } from "../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../store/actions/authActions";
import MiniSpinner from "../../helpers/MiniSpinner";
import $ from "jquery";
// 10.10.1.227

class CreateStaff extends Component {
  state = {
    title: "",
    first_name: "",
    last_name: "",
    other_name: "",
    mailing_address: "",
    city: "",
    state: "",
    phone: "",
    phone_2: "",
    email: "",
    birth_date: "",
    image: "",
    status: "",
    login_id: ""
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_STAFF" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    this.initDependency();
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleFileChange = e => {
    // alert("here");
    // console.log(e.target.files[0]);

    this.setState({
      image: e.target.files[0]
    });
  };
  handleClick = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  handleSubmit = e => {
    e.preventDefault();

    const birth_date = $("#birth_date").val();

    this.setState(
      {
        birth_date
      },
      () => {
        if (window.confirm("Are you sure want to create this staff record?")) {
          const { createStaff } = this.props;
          createStaff(this.state).then(res => {
            // console.log(res);
            if (res.status === "error") {
              $(".opp").each(function() {
                $(this).removeClass("collapsed-card");
                const item = $(this).find(".fa-plus");
                if (item) {
                  item.removeClass("fa-plus").addClass("fa-minus");
                }
              });
            } else {
              // const all= this.state;
              // Object.entries(all).forEach(([key, value]) => {
              //   this.setState({[key]:""});
              // });

              $("#birth_date").val("");
              $("#image").val("");
              this.setState({
                title: "",
                first_name: "",
                last_name: "",
                other_name: "",
                mailing_address: "",
                city: "",
                state: "",
                camps: "",
                camp_id: "",
                phone: "",
                phone_2: "",
                email: "",
                birth_date: "",
                image: "",
                status: "",
                login_id: ""
              });
            }
          });
        }
      }
    );
  };

  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Membership</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create a Staff Record
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Create a Staff Record
              </h3>
              <div className="card-tools"></div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="card-body">
                {/* {spinner ? <Spinner position="right" /> : null} */}
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}
                {/* start SECTION I: PERSONAL INFORMATION */}

                <div className="card opp">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">PERSONAL INFORMATION</h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label htmlFor="last_name">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("last_name"),
                            "form-control": true
                          })}
                          name="last_name"
                          id="last_name"
                          onChange={this.handleChange}
                          value={this.state.last_name}
                        />
                        {this.renderErrorFor("last_name")}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="first_name">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("first_name"),
                            "form-control": true
                          })}
                          name="first_name"
                          id="first_name"
                          onChange={this.handleChange}
                          value={this.state.first_name}
                        />
                        {this.renderErrorFor("first_name")}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="other_name">Other Name</label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("other_name"),
                            "form-control": true
                          })}
                          name="other_name"
                          id="other_name"
                          value={this.state.other_name}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("other_name")}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="mailing_address">
                        Mailing Address <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("mailing_address"),
                          "form-control": true
                        })}
                        id="mailing_address"
                        name="mailing_address"
                        value={this.state.mailing_address}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("mailing_address")}
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="city">
                            City <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("city"),
                              "form-control": true
                            })}
                            name="city"
                            id="city"
                            value={this.state.city}
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("city")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="state">
                            State <span className="text-danger">*</span>
                          </label>
                          <select
                            className={classnames({
                              "is-invalid": this.hasErrorFor("state"),
                              "form-control": true
                            })}
                            id="state"
                            name="state"
                            value={this.state.state}
                            onChange={this.handleChange}
                          >
                            <option value="">- Select -</option>
                            <option value="Abuja FCT">Abuja FCT</option>
                            <option value="Abia">Abia</option>
                            <option value="Adamawa">Adamawa</option>
                            <option value="Akwa Ibom">Akwa Ibom</option>
                            <option value="Anambra">Anambra</option>
                            <option value="Bauchi">Bauchi</option>
                            <option value="Bayelsa">Bayelsa</option>
                            <option value="Benue">Benue</option>
                            <option value="Borno">Borno</option>
                            <option value="Cross River">Cross River</option>
                            <option value="Delta">Delta</option>
                            <option value="Ebonyi">Ebonyi</option>
                            <option value="Edo">Edo</option>
                            <option value="Ekiti">Ekiti</option>
                            <option value="Enugu">Enugu</option>
                            <option value="Gombe">Gombe</option>
                            <option value="Imo">Imo</option>
                            <option value="Jigawa">Jigawa</option>
                            <option value="Kaduna">Kaduna</option>
                            <option value="Kano">Kano</option>
                            <option value="Katsina">Katsina</option>
                            <option value="Kebbi">Kebbi</option>
                            <option value="Kogi">Kogi</option>
                            <option value="Kwara">Kwara</option>
                            <option value="Lagos">Lagos</option>
                            <option value="Nassarawa">Nassarawa</option>
                            <option value="Niger">Niger</option>
                            <option value="Ogun">Ogun</option>
                            <option value="Ondo">Ondo</option>
                            <option value="Osun">Osun</option>
                            <option value="Oyo">Oyo</option>
                            <option value="Plateau">Plateau</option>
                            <option value="Rivers">Rivers</option>
                            <option value="Sokoto">Sokoto</option>
                            <option value="Taraba">Taraba</option>
                            <option value="Yobe">Yobe</option>
                            <option value="Zamfara">Zamfara</option>
                          </select>
                          {this.renderErrorFor("state")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="title">
                            Title <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder=" Mr/Master/Dr ..."
                            className={classnames({
                              "is-invalid": this.hasErrorFor("title"),
                              "form-control": true
                            })}
                            id="title"
                            name="title"
                            onChange={this.handleChange}
                            value={this.state.title}
                          />
                          {this.renderErrorFor("title")}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="phone">
                            Phone <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("phone"),
                              "form-control": true
                            })}
                            id="phone"
                            name="phone"
                            onChange={this.handleChange}
                            value={this.state.phone}
                          />
                          {this.renderErrorFor("phone")}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="phone_2">Phone2</label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("phone_2"),
                              "form-control": true
                            })}
                            id="phone_2"
                            name="phone_2"
                            onChange={this.handleChange}
                            value={this.state.phone_2}
                          />
                          {this.renderErrorFor("phone_2")}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="email">Email </label>
                        <input
                          type="email"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("email"),
                            "form-control": true
                          })}
                          id="email"
                          name="email"
                          onChange={this.handleChange}
                          value={this.state.email}
                        />
                        {this.renderErrorFor("email")}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="image">Upload member's image</label>
                        <br />

                        <input
                          type="file"
                          id="image"
                          onChange={this.handleFileChange}
                        />

                        {this.renderErrorFor("image")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="birth_date">
                            Birth Date <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("birth_date"),
                              "form-control": true
                            })}
                            id="birth_date"
                            name="birth_date"
                            readOnly
                            placeholder="Select date"
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("birth_date")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="status">
                            status <span className="text-danger">*</span>
                          </label>
                          <select
                            className={classnames({
                              "is-invalid": this.hasErrorFor("status"),
                              "form-control": true
                            })}
                            id="status"
                            name="status"
                            onChange={this.handleChange}
                            value={this.state.status}
                          >
                            <option value="">- Select -</option>
                            <option value="ACTI">ACTIVE</option>
                            <option value="EXIT">EXIT</option>
                          </select>
                          {this.renderErrorFor("status")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="login_id">
                            Login ID <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("login_id"),
                              "form-control": true
                            })}
                            id="login_id"
                            name="login_id"
                            onChange={this.handleChange}
                            value={this.state.login_id}
                          />
                          {this.renderErrorFor("login_id")}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>

                {/* end SECTION I: PERSONAL INFORMATION */}

                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  Create
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createStaff: creds => createStaff(dispatch, creds),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateStaff);
