import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../../hooks";
// import { useGetSystemRoles } from "../../../../store/hookactions/notificationsActionhooks";
import MiniSpinner from "../../../helpers/MiniSpinner";
import Message from "../../../helpers/Message";
import Spinner from "../../../helpers/Spinner";
import {
  resetNotificationState,
  submitScriptureFund,
} from "../../../../store/actions/notificationActions";
import { useDispatch } from "react-redux";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import { storage_type, user_auth_details, dehash } from "../../../../config";

function SetScriptureFund(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [enabled, setEnabled] = useState(true);
  const [cars_Info, setCarInfo] = useState({});
  const [church_receipts, setChurchReceipts] = useState("0.00");
  const [auxiliary, setAuxiliary] = useState("0.00");
  const [gideon_card, setGideonCard] = useState("0.00");
  const [others, setOthers] = useState("0.00");

  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const status = useShallowEqualSelector((state) => state.notifications.status);
  const errors = useShallowEqualSelector((state) => state.notifications.errors);
  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );

  console.log({ message, status, message });

  function handleSubmit(e) {
    e.preventDefault();

    const creds = {
      camp_id: cars_Info?.id,
      type: "scripture_fund_goal",
      church_receipt: church_receipts,
      other_scripture_fund_goals: others,
      auxiliary_receipt: auxiliary,
      gideon_receipt: gideon_card,
    };

    submitScriptureFund(dispatch, creds).then((res) => {
      if (res?.status === "success") {
        setAuxiliary("0.00");
        setOthers("0.00");
        setGideonCard("0.00");
        setChurchReceipts("0.00");
      }
    });
  }
  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  useLayoutEffect(() => {
    initDependency();
  }, []);

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Goals",
        action: "SET_GOAL",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      setCarInfo(user?.camp);
    }

    return () => {
      resetNotificationState(dispatch);
    };
  }, [dispatch, history]);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>GOALS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Set scripture funds </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              Set Scripture Funds{" "}
              <span className="lead">
                Camp: <i>{cars_Info?.name}</i>
              </span>
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="church_receipts">
                    Church receipts <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("church_receipts"),
                      "form-control": true,
                    })}
                    value={church_receipts}
                    id="church_receipts"
                    name="church_receipts"
                    onChange={(e) => setChurchReceipts(e.target.value)}
                  />
                  {renderErrorFor("church_receipts")}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="auxiliary">
                    Auxiliary <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("auxiliary"),
                      "form-control": true,
                    })}
                    id="auxiliary"
                    name="auxiliary"
                    value={auxiliary}
                    onChange={(e) => setAuxiliary(e.target.value)}
                  />
                  {renderErrorFor("auxiliary")}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="gideon_card">
                    Gideon card <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("gideon_card"),
                      "form-control": true,
                    })}
                    id="gideon_card"
                    name="gideon_card"
                    value={gideon_card}
                    onChange={(e) => setGideonCard(e.target.value)}
                  />
                  {renderErrorFor("gideon_card")}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="others">
                    Others <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("others"),
                      "form-control": true,
                    })}
                    id="others"
                    name="others"
                    value={others}
                    onChange={(e) => setOthers(e.target.value)}
                  />
                  {renderErrorFor("others")}
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                disabled={spinner ? true : false}
              >
                Set goal
                {spinner && <MiniSpinner />}
              </button>
            </form>
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default SetScriptureFund;
