import React from "react";
import Moment from "react-moment";
function Notification({ notifications, removeNofication }) {
  return (
    <>
      {notifications.length ? (
        <li className="nav-item dropdown " id="tag-drop2">
          <a className="nav-link" data-toggle="dropdown" href="#!">
            <i className="far fa-bell" />
            <span className="badge badge-danger navbar-badge">
              {notifications?.length}
            </span>
          </a>
          <div
            className="dropdown-menu dropdown-menu-lg dropdown-menu-right "
            style={{ height: "300px", overflowY: "scroll" }}
            id="tag-drop"
          >
            {notifications &&
              notifications.map((notification, index) => (
                <React.Fragment key={notification.id}>
                  <a
                    href="#!"
                    className="dropdown-item my-item"
                    onClick={(e) => {
                      removeNofication(e, notification.id);
                    }}
                  >
                    {/* Message Start */}
                    <div className="media">
                      <div className="media-body">
                        <h3 className="dropdown-item-title my-item">
                          {notification.data.subject}
                          <span className="float-right text-sm text-danger">
                            <i className="fas fa-times " />
                          </span>
                        </h3>
                        <p className="text-sm font-italic">
                          {notification.data.body}
                        </p>
                        <p className="text-sm text-muted">
                          <i className="far fa-clock mr-1" />

                          <Moment format="MMMM Do YYYY, h:mm:ss a">
                            {notification.created_at}
                          </Moment>
                        </p>
                      </div>
                    </div>
                    {/* Message End */}
                  </a>
                  <div className="dropdown-divider" />
                </React.Fragment>
              ))}
          </div>
        </li>
      ) : null}
    </>
  );
}

export default React.memo(Notification);
