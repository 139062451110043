import React from "react";

const Permission = props => {
  const { index, permission, all_permissions, handleCheckChange } = props;
  return (
    <div className="card collapsed-card permission" key={index}>
      <div className="card-header" id={`headingRole${index}`}>
        <h3 className="card-title"> {permission}</h3>
        <div className="card-tools d-flex ">
          <div className="form-check ">
            <input
              className="form-check-input"
              type="checkbox"
              value={`chk-${index}`}
              onChange={handleCheckChange}
            />
          </div>
          <button
            type="button"
            className="btn btn-tool"
            data-card-widget="collapse"
            data-toggle="tooltip"
            title="Collapse"
          >
            <i className="fas fa-plus mt-2" />
          </button>
        </div>
      </div>
      <div className="card-body">
        <div className="row">
          {Object.keys(all_permissions[permission]).map((item, ind) => {
            return (
              <div className="form-group col-md-3 " key={ind}>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className={`chk-${index} perm-role form-check-input`}
                    value={all_permissions[permission][item].id}
                  />
                  <label className="form-check-label">
                    {all_permissions[permission][item].name}
                  </label>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      {/* /.card-body */}
    </div>
  );
};

export default Permission;
