import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createNewActivityCon,
  resetUsersState,
} from "../../../../store/actions/usersActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import classnames from "classnames";
import $ from "jquery";
// import MiniSpinner from "../../../helpers/MiniSpinner";
import {
  getAllSettings2,
  getAllStates,
  getOneCampActivityCon,
  getCampActivityConReg,
  clearPreferenceReport,
} from "../../../../store/actions/preferencesActions";

class BackCon extends Component {
  state = {
    creds: {},
    states: null,
    regist: [],
    report_bck: {},
    show_reg: false,
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_CONVENTION" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    this.initDependency();
    this.props.getAllStates();
    this.props.getAllSettings2().then((ress) => {
      if (ress.status === "success") {
        this.setState({ ...ress.payload.settings }, () => {
          this.setState({
            creds: {
              ...this.state.creds,
              // gideon_fee: this.state.gideon_convention_fee,
              // aux_fee: this.state.aux_convention_fee,
              // both_fee: this.state.both_convention_fee,
            },
          });
        });
      }
    });
    const id = this.props.match.params.id;
    this.props.getOneCampActivityCon(id).then((res) => {
      if (res.status === "success") {
        this.setState({
          creds: { ...this.state.creds, ...res.payload.convention },
        });
        $("#start_date").val(res.payload.convention.start_date);
        $("#end_date").val(res.payload.convention.end_date);
      }
    });
    this.props.getCampActivityConReg(id).then((res) => {
      if (res.status === "success") {
        // console.log(res.payload);
        this.setState(
          {
            regist: res.payload.registrations,
          },
          () => {
            this.initDependency();
          }
        );
      }
    });
  }
  // componentDidUpdate() {
  //   this.initDependency();
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.mem_state_area !== prevState.mem_state_area ||
      nextProps.s_campFromArea !== prevState.s_campFromArea ||
      nextProps.mem_state_region !== prevState.mem_state_region ||
      nextProps.states !== prevState.states ||
      nextProps.mem_state !== prevState.mem_state ||
      nextProps.report_bck !== prevState.report_bck
    ) {
      return {
        s_campFromArea: nextProps.s_campFromArea,
        mem_state_area: nextProps.mem_state_area,
        mem_state_region: nextProps.mem_state_region,
        states: nextProps.states,
        mem_state: nextProps.mem_state,
        report_bck: nextProps.report_bck,
      };
    }
    return null;
  }
  componentWillUnmount() {
    this.props.resetUsersState();
    this.props.clearPreferenceReport();
  }
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  dateConv = (date) => {
    return date ? new Date(date).toDateString() : null;
  };
  handleChange = (e) => {
    const { target } = e;
    this.setState(
      {
        creds: { ...this.state.creds, [e.target.id]: e.target.value },
      },
      () => {
        if (target.id === "type") {
          if (target.value === "national" || target.value === "international") {
            this.setState({ creds: { ...this.state.creds, state_id: "" } });
          }
        }
        if (target.id === "type" && target.value === "national") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.national_theme,
              number: this.state.national_number,
            },
          });
        }
        if (target.id === "type" && target.value === "international") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.international_theme,
              number: this.state.international_number,
            },
          });
        }
        if (target.id === "type" && target.value === "state") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.state_theme,
              number: this.state.state_number,
            },
          });
        }
        if (target.id === "type" && target.value === "") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: "",
              number: "",
            },
          });
        }
      }
    );

    // this.setState({ [e.target.id]: e.target.value });
  };
  //   handleSubmit = (e) => {
  //     e.preventDefault();
  //     const start_date = $("#start_date").val();
  //     const end_date = $("#end_date").val();
  //     this.setState(
  //       { creds: { ...this.state.creds, start_date, end_date } },
  //       () => {
  //         this.props.createNewActivityCon(this.state.creds).then((res) => {
  //           if (res.status === "success") {
  //             const creds = {};
  //             this.setState({ creds });
  //             $("#start_date").val("");
  //             $("#end_date").val("");
  //           }
  //         });
  //       }
  //     );
  //   };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  goBack = (e) => {
    e.preventDefault();
    const lin = window.localStorage.getItem("pa");
    this.props.history.push(lin);
    // setTimeout(() => {
    //   this.props.navigation.goBack(null);
    //   this.props.navigation.goBack(null);
    // }, 50);
  };
  getTypye = (type) => {
    if (type === "A") {
      return "Auxilliary";
    } else if (type === "G") {
      return "Gideon";
    } else if (type === "C") {
      return "Gideon & Auxilliary";
    } else {
      return "Unknown";
    }
  };
  getStateVal = (id) => {
    // eslint-disable-next-line
    const sel_state = this.state.states.find((sat) => sat.id == id);

    if (sel_state) {
      return sel_state.name;
    }
  };
  toggleShowReg = (e) => {
    e.preventDefault();
    if (this.state.show_reg) {
      this.setState({ show_reg: false });
    } else {
      this.setState({ show_reg: true });
    }
  };
  render() {
    const {
      spinner,
      message,
      status,
      spinner1,
      message1,
      status1,
    } = this.props;
    if (!spinner1 && status1 !== "error") {
      this.initDependency();
      // setTimeout(() => {
      // }, 1000);
    }
    // if (this.state.regist.length) {
    //   setTimeout(() => {
    //     this.initDependency();
    //   }, 5000);
    // }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFRENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Details Convention</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Details Convention
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {message1 && status1 ? (
                <Message message={message1} status={status1} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              {spinner1 ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <div>
                <div className="row">
                  <div className="form-group col-md-3">
                    <label htmlFor="type">Type : &nbsp;</label>
                    {this.state.creds.type
                      ? this.state.creds.type.toUpperCase()
                      : ""}
                  </div>
                  {this.state.creds.type === "state" ? (
                    <div className=" col-sm-3">
                      <label>State(TGIN): </label>
                      {this.state.states &&
                        this.getStateVal(this.state.creds.state_id)}
                    </div>
                  ) : null}

                  <div className="form-group col-md-3">
                    <label htmlFor="theme">Convention Theme : &nbsp;</label>
                    {this.state.creds.theme || ""}
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor="number">Convention Number : &nbsp;</label>
                    {this.state.creds.number || ""}
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="gideon_fee">Gideon Convention Fee :</label>
                    &#8358;{" "}
                    {this.state.creds.gideon_fee &&
                      this.moneyConv(this.state.creds.gideon_fee)}
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="aux_fee">
                      Auxilliary Convention Fee : &#8358;
                    </label>
                    {this.state.creds.aux_fee &&
                      this.moneyConv(this.state.creds.aux_fee)}
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="both_fee">
                      Couples Convention Fee : &#8358;{" "}
                    </label>
                    {this.state.creds.both_fee &&
                      this.moneyConv(this.state.creds.both_fee)}
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor="start_date">State Date : </label>
                    {this.state.creds.start_date || ""}
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="end_date">End Date : </label>
                    {this.state.creds.end_date || ""}
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor="address">Address: </label>
                    {this.state.creds.address || " "}
                  </div>

                  <div className="form-group col-md-3">
                    <label htmlFor="admin_fund">Admin Fund : &#8358;</label>
                    {this.state.creds.admin_fund &&
                      this.moneyConv(this.state.creds.admin_fund)}
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="scripture_fund">
                      Scripture Fund : &#8358;
                    </label>
                    {this.state.creds.scripture_fund &&
                      this.moneyConv(this.state.creds.scripture_fund)}
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="city">City : &nbsp;</label>

                    {this.state.creds.city || " "}
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="state">State : &nbsp;</label>
                      {this.state.creds.state || ""}
                    </div>
                  </div>
                  <div className="form-group col-md-3">
                    <label htmlFor="country">Country : &nbsp;</label>
                    {this.state.creds.country || " "}
                  </div>

                  {/* <div className="form-group col-md-3"> */}
                  {/* <label htmlFor="location">
                        No of prospects<span className="text-danger">*</span>
                      </label> */}
                  {/* <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("location"),
                          "form-control": true,
                        })}
                        id="location"
                        name="location"
                        value={this.state.location}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("location")}
                    </div> */}
                  {/* <div className="form-group col-md-3">
                      <label htmlFor="funding">
                        No of Sign-Up<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="funding"
                        name="funding"
                        value={this.state.funding}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("funding")}
                    </div> */}
                  {/* <div className="form-group col-md-3">
                      <label htmlFor="speaker">
                        Amount Collected<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="speaker"
                        name="speaker"
                        value={this.state.speaker}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("speaker")}
                    </div> */}
                </div>

                {this.state.report_bck &&
                Object.keys(this.state.report_bck).length !== 0 ? (
                  <>
                    <hr />
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label htmlFor="facility_adequate">
                          Were facilities adequate?
                        </label>
                        {this.state.report_bck.facility_adequate &&
                          this.state.report_bck.facility_adequate}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="facility_adequate_explain">
                          if No, explain
                        </label>
                        {this.state.report_bck.facility_adequate_explain &&
                          this.state.report_bck.facility_adequate_explain}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="actual_admin_offering">
                          Actual Admin Fund : &#8358;
                        </label>
                        {this.state.report_bck.actual_admin_offering &&
                          this.moneyConv(
                            this.state.report_bck.actual_admin_offering
                          )}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="actual_scripture_offering">
                          Actual Scripture Fund:
                        </label>
                        &#8358;{" "}
                        {this.state.report_bck.actual_scripture_offering &&
                          this.moneyConv(
                            this.state.report_bck.actual_scripture_offering
                          )}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="friday_banquet">Friday Banquet:</label>
                        &#8358;{" "}
                        {this.state.report_bck.friday_banquet &&
                          this.moneyConv(this.state.report_bck.friday_banquet)}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="president_breakfast">
                          President Breakfast :
                        </label>
                        &#8358;{" "}
                        {this.state.report_bck.president_breakfast &&
                          this.moneyConv(
                            this.state.report_bck.president_breakfast
                          )}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="auxiliary_luncheon">
                          Auxiliary Luncheon :
                        </label>
                        &#8358;{" "}
                        {this.state.report_bck.auxiliary_luncheon &&
                          this.moneyConv(
                            this.state.report_bck.auxiliary_luncheon
                          )}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="saturday_banquet">
                          Saturday Banquet :
                        </label>
                        &#8358;{" "}
                        {this.state.report_bck.saturday_banquet &&
                          this.moneyConv(
                            this.state.report_bck.saturday_banquet
                          )}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="church_receipts">
                          Church Receipts :
                        </label>
                        &#8358;{" "}
                        {this.state.report_bck.church_receipts &&
                          this.moneyConv(this.state.report_bck.church_receipts)}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="total_realized">Total Realized :</label>
                        &#8358;{" "}
                        {this.state.report_bck.total_realized &&
                          this.moneyConv(this.state.report_bck.total_realized)}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="service_taken">
                          {" "}
                          No of service taken{" "}
                        </label>{" "}
                        {this.state.report_bck.service_taken &&
                          this.state.report_bck.service_taken}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="service_booked">
                          {" "}
                          No of Service Booked
                        </label>{" "}
                        {this.state.report_bck.service_booked &&
                          this.state.report_bck.service_booked}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th colSpan="2">Attendance</th>
                              <th>Kick-Off</th>
                              <th>Mid-Morning</th>
                              <th>Afternoon</th>
                              <th>Evening</th>
                              <th>Burden Brearers</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <th>Friday</th>
                              <td>Gideon</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_gideon_kick_off"
                                    ),
                                  })}
                                  id="friday_gideon_kick_off"
                                  name="friday_gideon_kick_off"
                                  value={
                                    this.state.report_bck
                                      .friday_gideon_kick_off || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_gideon_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_gideon_mid_morning"
                                    ),
                                  })}
                                  id="friday_gideon_mid_morning"
                                  name="friday_gideon_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .friday_gideon_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_gideon_mid_morning"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_gideon_afternoon"
                                    ),
                                  })}
                                  id="friday_gideon_afternoon"
                                  name="friday_gideon_afternoon"
                                  value={
                                    this.state.report_bck
                                      .friday_gideon_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_gideon_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_gideon_evening"
                                    ),
                                  })}
                                  id="friday_gideon_evening"
                                  name="friday_gideon_evening"
                                  value={
                                    this.state.report_bck
                                      .friday_gideon_evening || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_gideon_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_gideon_burden_bearers"
                                    ),
                                  })}
                                  id="friday_gideon_burden_bearers"
                                  name="friday_gideon_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .friday_gideon_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_gideon_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Auxilliary</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_aux_kick_off"
                                    ),
                                  })}
                                  id="friday_aux_kick_off"
                                  name="friday_aux_kick_off"
                                  value={
                                    this.state.report_bck.friday_aux_kick_off ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_aux_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_aux_mid_morning"
                                    ),
                                  })}
                                  id="friday_aux_mid_morning"
                                  name="friday_aux_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .friday_aux_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_aux_mid_morning")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_aux_afternoon"
                                    ),
                                  })}
                                  id="friday_aux_afternoon"
                                  name="friday_aux_afternoon"
                                  value={
                                    this.state.report_bck
                                      .friday_aux_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_aux_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_aux_evening"
                                    ),
                                  })}
                                  id="friday_aux_evening"
                                  name="friday_aux_evening"
                                  value={
                                    this.state.report_bck.friday_aux_evening ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_aux_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_aux_burden_bearers"
                                    ),
                                  })}
                                  id="friday_aux_burden_bearers"
                                  name="friday_aux_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .friday_aux_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_aux_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Guest</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_guest_kick_off"
                                    ),
                                  })}
                                  id="friday_guest_kick_off"
                                  name="friday_guest_kick_off"
                                  value={
                                    this.state.report_bck
                                      .friday_guest_kick_off || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_guest_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_guest_mid_morning"
                                    ),
                                  })}
                                  id="friday_guest_mid_morning"
                                  name="friday_guest_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .friday_guest_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_guest_mid_morning"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_guest_afternoon"
                                    ),
                                  })}
                                  id="friday_guest_afternoon"
                                  name="friday_guest_afternoon"
                                  value={
                                    this.state.report_bck
                                      .friday_guest_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_guest_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_guest_evening"
                                    ),
                                  })}
                                  id="friday_guest_evening"
                                  name="friday_guest_evening"
                                  value={
                                    this.state.report_bck
                                      .friday_guest_evening || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_guest_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_guest_burden_bearers"
                                    ),
                                  })}
                                  id="friday_guest_burden_bearers"
                                  name="friday_guest_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .friday_guest_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_guest_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Youth</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_youth_kick_off"
                                    ),
                                  })}
                                  id="friday_youth_kick_off"
                                  name="friday_youth_kick_off"
                                  value={
                                    this.state.report_bck
                                      .friday_youth_kick_off || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_youth_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_youth_mid_morning"
                                    ),
                                  })}
                                  id="friday_youth_mid_morning"
                                  name="friday_youth_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .friday_youth_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_youth_mid_morning"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_youth_afternoon"
                                    ),
                                  })}
                                  id="friday_youth_afternoon"
                                  name="friday_youth_afternoon"
                                  value={
                                    this.state.report_bck
                                      .friday_youth_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_youth_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_youth_evening"
                                    ),
                                  })}
                                  id="friday_youth_evening"
                                  name="friday_youth_evening"
                                  value={
                                    this.state.report_bck
                                      .friday_youth_evening || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_youth_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_youth_burden_bearers"
                                    ),
                                  })}
                                  id="friday_youth_burden_bearers"
                                  name="friday_youth_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .friday_youth_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_youth_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Children</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_children_kick_off"
                                    ),
                                  })}
                                  id="friday_children_kick_off"
                                  name="friday_children_kick_off"
                                  value={
                                    this.state.report_bck
                                      .friday_children_kick_off || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_children_kick_off"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_children_mid_morning"
                                    ),
                                  })}
                                  id="friday_children_mid_morning"
                                  name="friday_children_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .friday_children_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_children_mid_morning"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_children_afternoon"
                                    ),
                                  })}
                                  id="friday_children_afternoon"
                                  name="friday_children_afternoon"
                                  value={
                                    this.state.report_bck
                                      .friday_children_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_children_afternoon"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_children_evening"
                                    ),
                                  })}
                                  id="friday_children_evening"
                                  name="friday_children_evening"
                                  value={
                                    this.state.report_bck
                                      .friday_children_evening || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("friday_children_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "friday_children_burden_bearers"
                                    ),
                                  })}
                                  id="friday_children_burden_bearers"
                                  name="friday_children_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .friday_children_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "friday_children_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Total Head Count (Friday):</th>
                              <th></th>
                              <td>
                                {this.state.report_bck.friday_gideon_kick_off +
                                  this.state.report_bck.friday_aux_kick_off +
                                  this.state.report_bck.friday_guest_kick_off +
                                  this.state.report_bck.friday_youth_kick_off +
                                  this.state.report_bck
                                    .friday_children_kick_off}
                              </td>
                              <td>
                                {this.state.report_bck
                                  .friday_gideon_mid_morning +
                                  this.state.report_bck.friday_aux_mid_morning +
                                  this.state.report_bck
                                    .friday_guest_mid_morning +
                                  this.state.report_bck
                                    .friday_youth_mid_morning +
                                  this.state.report_bck
                                    .friday_children_mid_morning}
                              </td>
                              <td>
                                {this.state.report_bck.friday_gideon_afternoon +
                                  this.state.report_bck.friday_aux_afternoon +
                                  this.state.report_bck.friday_guest_afternoon +
                                  this.state.report_bck.friday_youth_afternoon +
                                  this.state.report_bck
                                    .friday_children_afternoon}
                              </td>
                              <td>
                                {this.state.report_bck.friday_gideon_evening +
                                  this.state.report_bck.friday_aux_evening +
                                  this.state.report_bck.friday_guest_evening +
                                  this.state.report_bck.friday_youth_evening +
                                  this.state.report_bck.friday_children_evening}
                              </td>
                              <td>
                                {this.state.report_bck
                                  .friday_gideon_burden_bearers +
                                  this.state.report_bck
                                    .friday_aux_burden_bearers +
                                  this.state.report_bck
                                    .friday_guest_burden_bearers +
                                  this.state.report_bck
                                    .friday_youth_burden_bearers +
                                  this.state.report_bck
                                    .friday_children_burden_bearers}
                              </td>
                            </tr>
                            <tr>
                              <th>Registered Delegates: </th>
                              <td></td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                            </tr>

                            <tr className="py-5 ">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>

                            <tr>
                              <th>Saturday</th>
                              <td>Gideon</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_gideon_kick_off"
                                    ),
                                  })}
                                  id="satur_gideon_kick_off"
                                  name="satur_gideon_kick_off"
                                  value={
                                    this.state.report_bck
                                      .satur_gideon_kick_off || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_gideon_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_gideon_mid_morning"
                                    ),
                                  })}
                                  id="satur_gideon_mid_morning"
                                  name="satur_gideon_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .satur_gideon_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "satur_gideon_mid_morning"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_gideon_afternoon"
                                    ),
                                  })}
                                  id="satur_gideon_afternoon"
                                  name="satur_gideon_afternoon"
                                  value={
                                    this.state.report_bck
                                      .satur_gideon_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_gideon_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_gideon_evening"
                                    ),
                                  })}
                                  id="satur_gideon_evening"
                                  name="satur_gideon_evening"
                                  value={
                                    this.state.report_bck
                                      .satur_gideon_evening || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_gideon_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_gideon_burden_bearers"
                                    ),
                                  })}
                                  id="satur_gideon_burden_bearers"
                                  name="satur_gideon_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .satur_gideon_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "satur_gideon_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Auxilliary</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_aux_kick_off"
                                    ),
                                  })}
                                  id="satur_aux_kick_off"
                                  name="satur_aux_kick_off"
                                  value={
                                    this.state.report_bck.satur_aux_kick_off ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_aux_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_aux_mid_morning"
                                    ),
                                  })}
                                  id="satur_aux_mid_morning"
                                  name="satur_aux_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .satur_aux_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_aux_mid_morning")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_aux_afternoon"
                                    ),
                                  })}
                                  id="satur_aux_afternoon"
                                  name="satur_aux_afternoon"
                                  value={
                                    this.state.report_bck.satur_aux_afternoon ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_aux_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_aux_evening"
                                    ),
                                  })}
                                  id="satur_aux_evening"
                                  name="satur_aux_evening"
                                  value={
                                    this.state.report_bck.satur_aux_evening ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_aux_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_aux_burden_bearers"
                                    ),
                                  })}
                                  id="satur_aux_burden_bearers"
                                  name="satur_aux_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .satur_aux_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "satur_aux_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Guest</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_kick_off"
                                    ),
                                  })}
                                  id="satur_kick_off"
                                  name="satur_kick_off"
                                  value={
                                    this.state.report_bck.satur_kick_off || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_mid_morning"
                                    ),
                                  })}
                                  id="satur_mid_morning"
                                  name="satur_mid_morning"
                                  value={
                                    this.state.report_bck.satur_mid_morning ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_mid_morning")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_afternoon"
                                    ),
                                  })}
                                  id="satur_afternoon"
                                  name="satur_afternoon"
                                  value={
                                    this.state.report_bck.satur_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_evening"
                                    ),
                                  })}
                                  id="satur_evening"
                                  name="satur_evening"
                                  value={
                                    this.state.report_bck.satur_evening || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_burden_bearers"
                                    ),
                                  })}
                                  id="satur_burden_bearers"
                                  name="satur_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .satur_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_burden_bearers")}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Youth</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_youth_kick_off"
                                    ),
                                  })}
                                  id="satur_youth_kick_off"
                                  name="satur_youth_kick_off"
                                  value={
                                    this.state.report_bck
                                      .satur_youth_kick_off || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_youth_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_youth_mid_morning"
                                    ),
                                  })}
                                  id="satur_youth_mid_morning"
                                  name="satur_youth_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .satur_youth_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_youth_mid_morning")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_youth_afternoon"
                                    ),
                                  })}
                                  id="satur_youth_afternoon"
                                  name="satur_youth_afternoon"
                                  value={
                                    this.state.report_bck
                                      .satur_youth_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_youth_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_youth_evening"
                                    ),
                                  })}
                                  id="satur_youth_evening"
                                  name="satur_youth_evening"
                                  value={
                                    this.state.report_bck.satur_youth_evening ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_youth_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_youth_burden_bearers"
                                    ),
                                  })}
                                  id="satur_youth_burden_bearers"
                                  name="satur_youth_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .satur_youth_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "satur_youth_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Children</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_children_kick_off"
                                    ),
                                  })}
                                  id="satur_children_kick_off"
                                  name="satur_children_kick_off"
                                  value={
                                    this.state.report_bck
                                      .satur_children_kick_off || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_children_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_children_mid_morning"
                                    ),
                                  })}
                                  id="satur_children_mid_morning"
                                  name="satur_children_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .satur_children_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "satur_children_mid_morning"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_children_afternoon"
                                    ),
                                  })}
                                  id="satur_children_afternoon"
                                  name="satur_children_afternoon"
                                  value={
                                    this.state.report_bck
                                      .satur_children_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "satur_children_afternoon"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_children_evening"
                                    ),
                                  })}
                                  id="satur_children_evening"
                                  name="satur_children_evening"
                                  value={
                                    this.state.report_bck
                                      .satur_children_evening || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("satur_children_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "satur_children_burden_bearers"
                                    ),
                                  })}
                                  id="satur_children_burden_bearers"
                                  name="satur_children_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .satur_children_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "satur_children_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Total Head Count (Saturday):</th>
                              <th></th>
                              <td>
                                {this.state.report_bck.satur_gideon_kick_off +
                                  this.state.report_bck.satur_aux_kick_off +
                                  this.state.report_bck.satur_kick_off +
                                  this.state.report_bck.satur_youth_kick_off +
                                  this.state.report_bck.satur_children_kick_off}
                              </td>
                              <td>
                                {this.state.report_bck
                                  .satur_gideon_mid_morning +
                                  this.state.report_bck.satur_aux_mid_morning +
                                  this.state.report_bck.satur_mid_morning +
                                  this.state.report_bck
                                    .satur_youth_mid_morning +
                                  this.state.report_bck
                                    .satur_children_mid_morning}
                              </td>
                              <td>
                                {this.state.report_bck.satur_gideon_afternoon +
                                  this.state.report_bck.satur_aux_afternoon +
                                  this.state.report_bck.satur_afternoon +
                                  this.state.report_bck.satur_youth_afternoon +
                                  this.state.report_bck
                                    .satur_children_afternoon}
                              </td>
                              <td>
                                {this.state.report_bck.satur_gideon_evening +
                                  this.state.report_bck.satur_aux_evening +
                                  this.state.report_bck.satur_evening +
                                  this.state.report_bck.satur_youth_evening +
                                  this.state.report_bck.satur_children_evening}
                              </td>
                              <td>
                                {this.state.report_bck
                                  .satur_gideon_burden_bearers +
                                  this.state.report_bck
                                    .satur_aux_burden_bearers +
                                  this.state.report_bck.satur_burden_bearers +
                                  this.state.report_bck
                                    .satur_youth_burden_bearers +
                                  this.state.report_bck
                                    .satur_children_burden_bearers}
                              </td>
                            </tr>
                            <tr>
                              <th>Registered Delegates: </th>
                              <td></td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                            </tr>
                            <tr className="py-5 ">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>

                            <tr>
                              <th>Sunday</th>
                              <td>Gideon</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_gideon_kick_off"
                                    ),
                                  })}
                                  id="sunday_gideon_kick_off"
                                  name="sunday_gideon_kick_off"
                                  value={
                                    this.state.report_bck
                                      .sunday_gideon_kick_off || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("sunday_gideon_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_gideon_mid_morning"
                                    ),
                                  })}
                                  id="sunday_gideon_mid_morning"
                                  name="sunday_gideon_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .sunday_gideon_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "sunday_gideon_mid_morning"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_gideon_afternoon"
                                    ),
                                  })}
                                  id="sunday_gideon_afternoon"
                                  name="sunday_gideon_afternoon"
                                  value={
                                    this.state.report_bck
                                      .sunday_gideon_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("sunday_gideon_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_gideon_evening"
                                    ),
                                  })}
                                  id="sunday_gideon_evening"
                                  name="sunday_gideon_evening"
                                  value={
                                    this.state.report_bck
                                      .sunday_gideon_evening || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("sunday_gideon_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_gideon_burden_bearers"
                                    ),
                                  })}
                                  id="sunday_gideon_burden_bearers"
                                  name="sunday_gideon_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .sunday_gideon_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "sunday_gideon_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td></td>
                              <td>Auxilliary</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_aux_kick_off"
                                    ),
                                  })}
                                  id="sunday_aux_kick_off"
                                  name="sunday_aux_kick_off"
                                  value={
                                    this.state.report_bck.sunday_aux_kick_off ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("sunday_aux_kick_off")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_aux_mid_morning"
                                    ),
                                  })}
                                  id="sunday_aux_mid_morning"
                                  name="sunday_aux_mid_morning"
                                  value={
                                    this.state.report_bck
                                      .sunday_aux_mid_morning || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("sunday_aux_mid_morning")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_aux_afternoon"
                                    ),
                                  })}
                                  id="sunday_aux_afternoon"
                                  name="sunday_aux_afternoon"
                                  value={
                                    this.state.report_bck
                                      .sunday_aux_afternoon || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("sunday_aux_afternoon")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_aux_evening"
                                    ),
                                  })}
                                  id="sunday_aux_evening"
                                  name="sunday_aux_evening"
                                  value={
                                    this.state.report_bck.sunday_aux_evening ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("sunday_aux_evening")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sunday_aux_burden_bearers"
                                    ),
                                  })}
                                  id="sunday_aux_burden_bearers"
                                  name="sunday_aux_burden_bearers"
                                  value={
                                    this.state.report_bck
                                      .sunday_aux_burden_bearers || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "sunday_aux_burden_bearers"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Total Head Count (Sunday):</th>
                              <th></th>
                              <td>
                                {this.state.report_bck.sunday_gideon_kick_off +
                                  this.state.report_bck.sunday_aux_kick_off}
                              </td>
                              <td>
                                {this.state.report_bck
                                  .sunday_gideon_mid_morning +
                                  this.state.report_bck.sunday_aux_mid_morning}
                              </td>
                              <td>
                                {this.state.report_bck.sunday_gideon_afternoon +
                                  this.state.report_bck.sunday_aux_afternoon}
                              </td>
                              <td>
                                {this.state.report_bck.sunday_gideon_evening +
                                  this.state.report_bck.sunday_aux_evening}
                              </td>
                              <td>
                                {this.state.report_bck
                                  .sunday_gideon_burden_bearers +
                                  this.state.report_bck
                                    .sunday_aux_burden_bearers}
                              </td>
                            </tr>
                            <tr>
                              <th>Registered Delegates: </th>
                              <td></td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                              <td>
                                {" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </td>
                            </tr>
                            {/* <tr>
                              <th colSpan="4">
                                Grand Total Attendance: &nbsp;
                                {this.state.report_bck.sunday_gideon_kick_off +
                                  this.state.report_bck.sunday_aux_kick_off +
                                  this.state.report_bck.sunday_gideon_mid_morning +
                                  this.state.report_bck.sunday_aux_mid_morning +
                                  this.state.report_bck.sunday_gideon_afternoon +
                                  this.state.report_bck.sunday_aux_afternoon +
                                  this.state.report_bck.sunday_gideon_evening +
                                  this.state.report_bck.sunday_aux_evening +
                                  this.state.report_bck
                                    .sunday_gideon_burden_bearers +
                                  this.state.report_bck.sunday_aux_burden_bearers +
                                  this.state.report_bck.satur_gideon_kick_off +
                                  this.state.report_bck.satur_aux_kick_off +
                                  this.state.report_bck.satur_kick_off +
                                  this.state.report_bck.satur_youth_kick_off +
                                  this.state.report_bck.satur_children_kick_off +
                                  this.state.report_bck.satur_gideon_mid_morning +
                                  this.state.report_bck.satur_aux_mid_morning +
                                  this.state.report_bck.satur_mid_morning +
                                  this.state.report_bck.satur_youth_mid_morning +
                                  this.state.report_bck.satur_children_mid_morning +
                                  this.state.report_bck.satur_gideon_afternoon +
                                  this.state.report_bck.satur_aux_afternoon +
                                  this.state.report_bck.satur_afternoon +
                                  this.state.report_bck.satur_youth_afternoon +
                                  this.state.report_bck.satur_children_afternoon +
                                  this.state.report_bck.satur_gideon_evening +
                                  this.state.report_bck.satur_aux_evening +
                                  this.state.report_bck.satur_evening +
                                  this.state.report_bck.satur_youth_evening +
                                  this.state.report_bck.satur_children_evening +
                                  this.state.report_bck
                                    .satur_gideon_burden_bearers +
                                  this.state.report_bck.satur_aux_burden_bearers +
                                  this.state.report_bck.satur_burden_bearers +
                                  this.state.report_bck.satur_youth_burden_bearers +
                                  this.state.report_bck
                                    .satur_children_burden_bearers +
                                  this.state.report_bck.friday_gideon_kick_off +
                                  this.state.report_bck.friday_aux_kick_off +
                                  this.state.report_bck.friday_guest_kick_off +
                                  this.state.report_bck.friday_youth_kick_off +
                                  this.state.report_bck.friday_children_kick_off +
                                  this.state.report_bck.friday_gideon_mid_morning +
                                  this.state.report_bck.friday_aux_mid_morning +
                                  this.state.report_bck.friday_guest_mid_morning +
                                  this.state.report_bck.friday_youth_mid_morning +
                                  this.state.report_bck
                                    .friday_children_mid_morning +
                                  this.state.report_bck.friday_gideon_afternoon +
                                  this.state.report_bck.friday_aux_afternoon +
                                  this.state.report_bck.friday_guest_afternoon +
                                  this.state.report_bck.friday_youth_afternoon +
                                  this.state.report_bck.friday_children_afternoon +
                                  this.state.report_bck.friday_gideon_evening +
                                  this.state.report_bck.friday_aux_evening +
                                  this.state.report_bck.friday_guest_evening +
                                  this.state.report_bck.friday_youth_evening +
                                  this.state.report_bck.friday_children_evening +
                                  this.state.report_bck
                                    .friday_gideon_burden_bearers +
                                  this.state.report_bck.friday_aux_burden_bearers +
                                  this.state.report_bck
                                    .friday_guest_burden_bearers +
                                  this.state.report_bck
                                    .friday_youth_burden_bearers +
                                  this.state.report_bck
                                    .friday_children_burden_bearers}
                              </th>
                              <th colSpan="3">
                                Total Registered: &nbsp;{" "}
                                {this.state.report_bck.registered_aux +
                                  this.state.report_bck.registered_gideon +
                                  this.state.report_bck.registered_children}
                              </th>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="camp_represented">
                          CAMP REPRESENTED at convention (do not include camps
                          from other states){" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_represented"),
                            "form-control": true,
                          })}
                          id="camp_represented"
                          name="camp_represented"
                          value={this.state.report_bck.camp_represented || ""}
                          readOnly
                        />
                        {this.renderErrorFor("camp_represented")}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="total_camps">
                          Total camps in State Association{" "}
                        </label>
                        <input
                          type="number"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("total_camps"),
                            "form-control": true,
                          })}
                          id="total_camps"
                          name="total_camps"
                          value={this.state.report_bck.total_camps || ""}
                          readOnly
                        />
                        {this.renderErrorFor("total_camps")}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <p className="lead">
                          GIDEON OFFICERS elected or appointed for coming year
                        </p>
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Position</th>
                              <th>Name</th>
                              <th>Camp </th>
                              <th>Phone no </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>a. President</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "president_name"
                                    ),
                                  })}
                                  id="president_name"
                                  name="president_name"
                                  value={
                                    this.state.report_bck.gideons
                                      ? this.state.report_bck.gideons
                                          .president_name
                                      : ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("president_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "president_camp_name"
                                    ),
                                  })}
                                  id="president_camp_name"
                                  name="president_camp_name"
                                  value={
                                    this.state.report_bck.gideons
                                      ? this.state.report_bck.gideons
                                          .president_camp_name
                                      : ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("president_camp_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "president_phone"
                                    ),
                                  })}
                                  id="president_phone"
                                  name="president_phone"
                                  value={
                                    this.state.report_bck.gideons
                                      ? this.state.report_bck.gideons
                                          .president_phone
                                      : ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("president_phone")}
                              </td>
                            </tr>
                            <tr>
                              <td>b. Vice President</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "vice_president_name"
                                    ),
                                  })}
                                  id="vice_president_name"
                                  name="vice_president_name"
                                  value={
                                    this.state.report_bck.gideons
                                      ? this.state.report_bck.gideons
                                          .vice_president_name
                                      : ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("vice_president_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "vice_president_camp_name"
                                    ),
                                  })}
                                  id="vice_president_camp_name"
                                  name="vice_president_camp_name"
                                  value={
                                    this.state.report_bck.gideons
                                      ? this.state.report_bck.gideons
                                          .vice_president_camp_name
                                      : ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor(
                                  "vice_president_camp_name"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "vice_president_phone"
                                    ),
                                  })}
                                  id="vice_president_phone"
                                  name="vice_president_phone"
                                  value={
                                    this.state.report_bck.gideons
                                      ? this.state.report_bck.gideons
                                          .vice_president_phone
                                      : ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("vice_president_phone")}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                {this.state.creds.type &&
                                this.state.creds.type.toUpperCase() === "STATE"
                                  ? " c. Secretary"
                                  : " c. Secretary/ Ex director"}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "secretary_name"
                                    ),
                                  })}
                                  id="secretary_name"
                                  name="secretary_name"
                                  value={
                                    this.state.report_bck.gideons
                                      ? this.state.report_bck.gideons
                                          .secretary_name
                                      : ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("secretary_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "secretary_camp_name"
                                    ),
                                  })}
                                  id="secretary_camp_name"
                                  name="secretary_camp_name"
                                  value={
                                    this.state.report_bck.gideons
                                      ? this.state.report_bck.gideons
                                          .secretary_camp_name
                                      : ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("secretary_camp_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "secretary_phone"
                                    ),
                                  })}
                                  id="secretary_phone"
                                  name="secretary_phone"
                                  value={
                                    this.state.report_bck.gideons
                                      ? this.state.report_bck.gideons
                                          .secretary_phone
                                      : ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("secretary_phone")}
                              </td>
                            </tr>
                            <tr>
                              <td>d. Treasurer</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "treasurer_name"
                                    ),
                                  })}
                                  id="treasurer_name"
                                  name="treasurer_name"
                                  value={
                                    this.state.report_bck.gideons
                                      .treasurer_name || ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("treasurer_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "treasurer_camp_name"
                                    ),
                                  })}
                                  id="treasurer_camp_name"
                                  name="treasurer_camp_name"
                                  value={
                                    this.state.report_bck.gideons
                                      .treasurer_camp_name || ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("treasurer_camp_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "treasurer_phone"
                                    ),
                                  })}
                                  id="treasurer_phone"
                                  name="treasurer_phone"
                                  value={
                                    this.state.report_bck.gideons
                                      .treasurer_phone || ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("treasurer_phone")}
                              </td>
                            </tr>
                            <tr>
                              <td>e. Chaplain</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "chaplain_name"
                                    ),
                                  })}
                                  id="chaplain_name"
                                  name="chaplain_name"
                                  value={
                                    this.state.report_bck.gideons
                                      .chaplain_name || ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("chaplain_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "chaplain_camp_name"
                                    ),
                                  })}
                                  id="chaplain_camp_name"
                                  name="chaplain_camp_name"
                                  value={
                                    this.state.report_bck.gideons
                                      .chaplain_camp_name || ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("chaplain_camp_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "chaplain_phone"
                                    ),
                                  })}
                                  id="chaplain_phone"
                                  name="chaplain_phone"
                                  value={
                                    this.state.report_bck.gideons
                                      .chaplain_phone || ""
                                  }
                                  onChange={this.handleChange_7}
                                />
                                {this.renderErrorFor("chaplain_phone")}
                              </td>
                            </tr>

                            {this.state.creds.type &&
                            this.state.creds.type.toUpperCase() === "STATE" ? (
                              <>
                                <tr>
                                  <td>f. Church Ministry Coordinator</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "ministry_coordinator_name"
                                        ),
                                      })}
                                      id="ministry_coordinator_name"
                                      name="ministry_coordinator_name"
                                      value={
                                        this.state.report.gideons
                                          .ministry_coordinator_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "ministry_coordinator_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "ministry_coordinator_camp_name"
                                        ),
                                      })}
                                      id="ministry_coordinator_camp_name"
                                      name="ministry_coordinator_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .ministry_coordinator_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "ministry_coordinator_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "ministry_coordinator_phone"
                                        ),
                                      })}
                                      id="ministry_coordinator_phone"
                                      name="ministry_coordinator_phone"
                                      value={
                                        this.state.report.gideons
                                          .ministry_coordinator_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "ministry_coordinator_phone"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>g. Membership Coordinator</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "membership_coordinator_name"
                                        ),
                                      })}
                                      id="membership_coordinator_name"
                                      name="membership_coordinator_name"
                                      value={
                                        this.state.report.gideons
                                          .membership_coordinator_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "membership_coordinator_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "membership_coordinator_camp_name"
                                        ),
                                      })}
                                      id="membership_coordinator_camp_name"
                                      name="membership_coordinator_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .membership_coordinator_camp_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "membership_coordinator_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "membership_coordinator_phone"
                                        ),
                                      })}
                                      id="membership_coordinator_phone"
                                      name="membership_coordinator_phone"
                                      value={
                                        this.state.report.gideons
                                          .membership_coordinator_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "membership_coordinator_phone"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>h. GideonCard Coordinator</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "gideonCard_coordinator_name"
                                        ),
                                      })}
                                      id="gideonCard_coordinator_name"
                                      name="gideonCard_coordinator_name"
                                      value={
                                        this.state.report.gideons
                                          .gideonCard_coordinator_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "gideonCard_coordinator_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "gideonCard_coordinator_camp_name"
                                        ),
                                      })}
                                      id="gideonCard_coordinator_camp_name"
                                      name="gideonCard_coordinator_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .gideonCard_coordinator_camp_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "gideonCard_coordinator_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "gideonCard_coordinator_phone"
                                        ),
                                      })}
                                      id="gideonCard_coordinator_phone"
                                      name="gideonCard_coordinator_phone"
                                      value={
                                        this.state.report.gideons
                                          .gideonCard_coordinator_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "gideonCard_coordinator_phone"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>i. Scripture Coordinator</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "scripture_coordinator_name"
                                        ),
                                      })}
                                      id="scripture_coordinator_name"
                                      name="scripture_coordinator_name"
                                      value={
                                        this.state.report.gideons
                                          .scripture_coordinator_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "scripture_coordinator_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "scripture_coordinator_camp_name"
                                        ),
                                      })}
                                      id="scripture_coordinator_camp_name"
                                      name="scripture_coordinator_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .scripture_coordinator_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "scripture_coordinator_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "scripture_coordinator_phone"
                                        ),
                                      })}
                                      id="scripture_coordinator_phone"
                                      name="scripture_coordinator_phone"
                                      value={
                                        this.state.report.gideons
                                          .scripture_coordinator_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "scripture_coordinator_phone"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>j. Regional Director 1</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "regional_director1_name"
                                        ),
                                      })}
                                      id="regional_director1_name"
                                      name="regional_director1_name"
                                      value={
                                        this.state.report.gideons
                                          .regional_director1_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "regional_director1_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "regional_director1_camp_name"
                                        ),
                                      })}
                                      id="regional_director1_camp_name"
                                      name="regional_director1_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .regional_director1_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "regional_director1_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "regional_director1_phone"
                                        ),
                                      })}
                                      id="regional_director1_phone"
                                      name="regional_director1_phone"
                                      value={
                                        this.state.report.gideons
                                          .regional_director1_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "regional_director1_phone"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>k. Regional Director 2</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "regional_director2_name"
                                        ),
                                      })}
                                      id="regional_director2_name"
                                      name="regional_director2_name"
                                      value={
                                        this.state.report.gideons
                                          .regional_director2_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "regional_director2_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "regional_director2_camp_name"
                                        ),
                                      })}
                                      id="regional_director2_camp_name"
                                      name="regional_director2_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .regional_director2_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "regional_director2_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "regional_director2_phone"
                                        ),
                                      })}
                                      id="regional_director2_phone"
                                      name="regional_director2_phone"
                                      value={
                                        this.state.report.gideons
                                          .regional_director2_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "regional_director2_phone"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>l. Area Directors</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_directors_name"
                                        ),
                                      })}
                                      id="area_directors_name"
                                      name="area_directors_name"
                                      value={
                                        this.state.report.gideons
                                          .area_directors_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_directors_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_directors_camp_name"
                                        ),
                                      })}
                                      id="area_directors_camp_name"
                                      name="area_directors_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_directors_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "area_directors_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_directors_phone"
                                        ),
                                      })}
                                      id="area_directors_phone"
                                      name="area_directors_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_directors_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor(
                                      "area_directors_phone"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 1</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_1_name"
                                        ),
                                      })}
                                      id="area_1_name"
                                      name="area_1_name"
                                      value={
                                        this.state.report.gideons.area_1_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_1_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_1_camp_name"
                                        ),
                                      })}
                                      id="area_1_camp_name"
                                      name="area_1_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_1_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_1_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_1_phone"
                                        ),
                                      })}
                                      id="area_1_phone"
                                      name="area_1_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_1_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_1_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 2</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_2_name"
                                        ),
                                      })}
                                      id="area_2_name"
                                      name="area_2_name"
                                      value={
                                        this.state.report.gideons.area_2_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_2_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_2_camp_name"
                                        ),
                                      })}
                                      id="area_2_camp_name"
                                      name="area_2_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_2_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_2_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_2_phone"
                                        ),
                                      })}
                                      id="area_2_phone"
                                      name="area_2_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_2_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_2_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 3</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_3_name"
                                        ),
                                      })}
                                      id="area_3_name"
                                      name="area_3_name"
                                      value={
                                        this.state.report.gideons.area_3_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_3_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_3_camp_name"
                                        ),
                                      })}
                                      id="area_3_camp_name"
                                      name="area_3_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_3_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_3_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_3_phone"
                                        ),
                                      })}
                                      id="area_3_phone"
                                      name="area_3_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_3_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_3_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 4</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_4_name"
                                        ),
                                      })}
                                      id="area_4_name"
                                      name="area_4_name"
                                      value={
                                        this.state.report.gideons.area_4_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_4_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_4_camp_name"
                                        ),
                                      })}
                                      id="area_4_camp_name"
                                      name="area_4_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_4_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_4_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_4_phone"
                                        ),
                                      })}
                                      id="area_4_phone"
                                      name="area_4_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_4_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_4_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 5</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_5_name"
                                        ),
                                      })}
                                      id="area_5_name"
                                      name="area_5_name"
                                      value={
                                        this.state.report.gideons.area_5_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_5_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_5_camp_name"
                                        ),
                                      })}
                                      id="area_5_camp_name"
                                      name="area_5_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_5_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_5_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_5_phone"
                                        ),
                                      })}
                                      id="area_5_phone"
                                      name="area_5_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_5_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_5_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 6</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_6_name"
                                        ),
                                      })}
                                      id="area_6_name"
                                      name="area_6_name"
                                      value={
                                        this.state.report.gideons.area_6_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_6_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_6_camp_name"
                                        ),
                                      })}
                                      id="area_6_camp_name"
                                      name="area_6_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_6_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_6_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_6_phone"
                                        ),
                                      })}
                                      id="area_6_phone"
                                      name="area_6_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_6_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_6_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 7</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_7_name"
                                        ),
                                      })}
                                      id="area_7_name"
                                      name="area_7_name"
                                      value={
                                        this.state.report.gideons.area_7_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_7_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_7_camp_name"
                                        ),
                                      })}
                                      id="area_7_camp_name"
                                      name="area_7_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_7_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_7_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_7_phone"
                                        ),
                                      })}
                                      id="area_7_phone"
                                      name="area_7_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_7_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_7_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 8</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_8_name"
                                        ),
                                      })}
                                      id="area_8_name"
                                      name="area_8_name"
                                      value={
                                        this.state.report.gideons.area_8_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_8_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_8_camp_name"
                                        ),
                                      })}
                                      id="area_8_camp_name"
                                      name="area_8_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_8_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_8_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_8_phone"
                                        ),
                                      })}
                                      id="area_8_phone"
                                      name="area_8_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_8_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_8_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 9</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_9_name"
                                        ),
                                      })}
                                      id="area_9_name"
                                      name="area_9_name"
                                      value={
                                        this.state.report.gideons.area_9_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_9_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_9_camp_name"
                                        ),
                                      })}
                                      id="area_9_camp_name"
                                      name="area_9_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_9_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_9_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_9_phone"
                                        ),
                                      })}
                                      id="area_9_phone"
                                      name="area_9_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_9_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_9_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 10</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_10_name"
                                        ),
                                      })}
                                      id="area_10_name"
                                      name="area_10_name"
                                      value={
                                        this.state.report.gideons
                                          .area_10_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_10_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_10_camp_name"
                                        ),
                                      })}
                                      id="area_10_camp_name"
                                      name="area_10_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_10_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_10_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_10_phone"
                                        ),
                                      })}
                                      id="area_10_phone"
                                      name="area_10_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_10_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_10_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 11</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_11_name"
                                        ),
                                      })}
                                      id="area_11_name"
                                      name="area_11_name"
                                      value={
                                        this.state.report.gideons
                                          .area_11_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_11_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_11_camp_name"
                                        ),
                                      })}
                                      id="area_11_camp_name"
                                      name="area_11_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_11_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_11_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_11_phone"
                                        ),
                                      })}
                                      id="area_11_phone"
                                      name="area_11_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_11_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_11_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 12</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_12_name"
                                        ),
                                      })}
                                      id="area_12_name"
                                      name="area_12_name"
                                      value={
                                        this.state.report.gideons
                                          .area_12_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_12_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_12_camp_name"
                                        ),
                                      })}
                                      id="area_12_camp_name"
                                      name="area_12_camp_name"
                                      value={
                                        this.state.report.gideons
                                          .area_12_camp_name || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_12_camp_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_12_phone"
                                        ),
                                      })}
                                      id="area_12_phone"
                                      name="area_12_phone"
                                      value={
                                        this.state.report.gideons
                                          .area_12_phone || ""
                                      }
                                      onChange={this.handleChange_7}
                                    />
                                    {this.renderErrorFor("area_12_phone")}
                                  </td>
                                </tr>
                              </>
                            ) : (
                              <>
                                <tr>
                                  <th colSpan={4}>TRUSTEES AS APPLICABLE</th>
                                </tr>

                                <tr>
                                  <th>Position</th>
                                  <th>Name</th>
                                  <th colSpan={2}>Complete Address</th>
                                </tr>

                                <tr>
                                  <td>
                                    f.{" "}
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_1"
                                        ),
                                      })}
                                      id="trustee_position_1"
                                      name="trustee_position_1"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_position_1 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_position_1")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_name_1"
                                        ),
                                      })}
                                      id="trustee_name_1"
                                      name="trustee_name_1"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_name_1 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_name_1")}
                                  </td>
                                  <td colSpan={2}>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_addrs_1"
                                        ),
                                      })}
                                      id="trustee_addrs_1"
                                      name="trustee_addrs_1"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_addrs_1 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_addrs_1")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    g.{" "}
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_2"
                                        ),
                                      })}
                                      id="trustee_position_2"
                                      name="trustee_position_2"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_position_2 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_position_2")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_2"
                                        ),
                                      })}
                                      id="trustee_name_2"
                                      name="trustee_name_2"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_name_2 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_name_2")}
                                  </td>
                                  <td colSpan={2}>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_addrs_2"
                                        ),
                                      })}
                                      id="trustee_addrs_2"
                                      name="trustee_addrs_2"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_addrs_2 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_addrs_2")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    h.{" "}
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_3"
                                        ),
                                      })}
                                      id="trustee_position_3"
                                      name="trustee_position_3"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_position_3 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_position_3")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_name_3"
                                        ),
                                      })}
                                      id="trustee_name_3"
                                      name="trustee_name_3"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_name_3 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_name_3")}
                                  </td>
                                  <td colSpan={2}>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_addrs_3"
                                        ),
                                      })}
                                      id="trustee_addrs_3"
                                      name="trustee_addrs_3"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_addrs_3 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_addrs_3")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    i.{" "}
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_4"
                                        ),
                                      })}
                                      id="trustee_position_4"
                                      name="trustee_position_4"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_position_4 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_position_4")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_name_4"
                                        ),
                                      })}
                                      id="trustee_name_4"
                                      name="trustee_name_4"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_name_4 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_name_4")}
                                  </td>
                                  <td colSpan={2}>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_addrs_4"
                                        ),
                                      })}
                                      id="trustee_addrs_4"
                                      name="trustee_addrs_4"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_addrs_4 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_addrs_4")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    j.{" "}
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_5"
                                        ),
                                      })}
                                      id="trustee_position_5"
                                      name="trustee_position_5"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_position_5 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_position_5")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_name_5"
                                        ),
                                      })}
                                      id="trustee_name_5"
                                      name="trustee_name_5"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_name_5 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_name_5")}
                                  </td>
                                  <td colSpan={2}>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_addrs_5"
                                        ),
                                      })}
                                      id="trustee_addrs_5"
                                      name="trustee_addrs_5"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_addrs_5 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_addrs_5")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    k.{" "}
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_6"
                                        ),
                                      })}
                                      id="trustee_position_6"
                                      name="trustee_position_6"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_position_6 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_position_6")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_name_6"
                                        ),
                                      })}
                                      id="trustee_name_6"
                                      name="trustee_name_6"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_name_6 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_name_6")}
                                  </td>
                                  <td colSpan={2}>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_addrs_6"
                                        ),
                                      })}
                                      id="trustee_addrs_6"
                                      name="trustee_addrs_6"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_addrs_6 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_addrs_6")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    l.{" "}
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_7"
                                        ),
                                      })}
                                      id="trustee_position_7"
                                      name="trustee_position_7"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_position_7 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_position_7")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_name_7"
                                        ),
                                      })}
                                      id="trustee_name_7"
                                      name="trustee_name_7"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_name_7 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_name_7")}
                                  </td>
                                  <td colSpan={2}>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_addrs_7"
                                        ),
                                      })}
                                      id="trustee_addrs_7"
                                      name="trustee_addrs_7"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_addrs_7 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_addrs_7")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    m.{" "}
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_8"
                                        ),
                                      })}
                                      id="trustee_position_8"
                                      name="trustee_position_8"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_position_8 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_position_8")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_name_8"
                                        ),
                                      })}
                                      id="trustee_name_8"
                                      name="trustee_name_8"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_name_8 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_name_8")}
                                  </td>
                                  <td colSpan={2}>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_addrs_8"
                                        ),
                                      })}
                                      id="trustee_addrs_8"
                                      name="trustee_addrs_8"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_addrs_8 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_addrs_8")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    n.{" "}
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_position_9"
                                        ),
                                      })}
                                      id="trustee_position_9"
                                      name="trustee_position_9"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_position_9 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_position_9")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_name_9"
                                        ),
                                      })}
                                      id="trustee_name_9"
                                      name="trustee_name_9"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_name_9 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_name_9")}
                                  </td>
                                  <td colSpan={2}>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "trustee_addrs_9"
                                        ),
                                      })}
                                      id="trustee_addrs_9"
                                      name="trustee_addrs_9"
                                      value={
                                        this.state.report.trustees
                                          ?.trustee_addrs_9 || ""
                                      }
                                      onChange={this.handleChange_trustees}
                                    />
                                    {this.renderErrorFor("trustee_addrs_9")}
                                  </td>
                                </tr>
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <p className="lead">
                          AUXILIARY OFFICERS elected or appointed for coming
                          year
                        </p>
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Position</th>
                              <th>Name</th>
                              <th>Camp </th>
                              <th>Phone no </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>a. President</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "president_aux_name"
                                    ),
                                  })}
                                  id="president_aux_name"
                                  name="president_aux_name"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .president_aux_name || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("president_aux_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "president_aux_camp_name"
                                    ),
                                  })}
                                  id="president_aux_camp_name"
                                  name="president_aux_camp_name"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .president_aux_camp_name || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("president_aux_camp_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "president_aux_phone"
                                    ),
                                  })}
                                  id="president_aux_phone"
                                  name="president_aux_phone"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .president_aux_phone || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("president_aux_phone")}
                              </td>
                            </tr>
                            <tr>
                              <td>b. Vice President</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "vice_president_aux_name"
                                    ),
                                  })}
                                  id="vice_president_aux_name"
                                  name="vice_president_aux_name"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .vice_president_aux_name || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("vice_president_aux_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "vice_president_aux_camp_name"
                                    ),
                                  })}
                                  id="vice_president_aux_camp_name"
                                  name="vice_president_aux_camp_name"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .vice_president_aux_camp_name || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor(
                                  "vice_president_aux_camp_name"
                                )}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "vice_president_aux_phone"
                                    ),
                                  })}
                                  id="vice_president_aux_phone"
                                  name="vice_president_aux_phone"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .vice_president_aux_phone || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor(
                                  "vice_president_aux_phone"
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>c. Secretary/Treasurer</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "secretary_aux_name"
                                    ),
                                  })}
                                  id="secretary_aux_name"
                                  name="secretary_aux_name"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .secretary_aux_name || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("secretary_aux_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "secretary_aux_camp_name"
                                    ),
                                  })}
                                  id="secretary_aux_camp_name"
                                  name="secretary_aux_camp_name"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .secretary_aux_camp_name || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("secretary_aux_camp_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "secretary_aux_phone"
                                    ),
                                  })}
                                  id="secretary_aux_phone"
                                  name="secretary_aux_phone"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .secretary_aux_phone || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("secretary_aux_phone")}
                              </td>
                            </tr>
                            <tr>
                              <td>d. Chaplain</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "chaplain_aux_name"
                                    ),
                                  })}
                                  id="chaplain_aux_name"
                                  name="chaplain_aux_name"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .chaplain_aux_name || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("chaplain_aux_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "chaplain_aux_camp_name"
                                    ),
                                  })}
                                  id="chaplain_aux_camp_name"
                                  name="chaplain_aux_camp_name"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .chaplain_aux_camp_name || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("chaplain_aux_camp_name")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "chaplain_aux_phone"
                                    ),
                                  })}
                                  id="chaplain_aux_phone"
                                  name="chaplain_aux_phone"
                                  value={
                                    this.state.report_bck.auxiliary
                                      .chaplain_aux_phone || ""
                                  }
                                  onChange={this.handleChange_6}
                                />
                                {this.renderErrorFor("chaplain_aux_phone")}
                              </td>
                            </tr>
                            {this.state.creds.type &&
                            this.state.creds.type.toUpperCase() === "STATE" ? (
                              <>
                                <tr>
                                  <td>e. Scripture Coordinator</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "scripture_coordinator_aux_name"
                                        ),
                                      })}
                                      id="scripture_coordinator_aux_name"
                                      name="scripture_coordinator_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .scripture_coordinator_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "scripture_coordinator_aux_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "scripture_coordinator_aux_camp_name"
                                        ),
                                      })}
                                      id="scripture_coordinator_aux_camp_name"
                                      name="scripture_coordinator_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .scripture_coordinator_aux_camp_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "scripture_coordinator_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "scripture_coordinator_aux_phone"
                                        ),
                                      })}
                                      id="scripture_coordinator_aux_phone"
                                      name="scripture_coordinator_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .scripture_coordinator_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "scripture_coordinator_aux_phone"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td>f. Auxiliary Area Directors</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "auxiliary_area_directors_name"
                                        ),
                                      })}
                                      id="auxiliary_area_directors_name"
                                      name="auxiliary_area_directors_name"
                                      value={
                                        this.state.report.auxiliary
                                          .auxiliary_area_directors_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "auxiliary_area_directors_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "auxiliary_area_directors_camp_name"
                                        ),
                                      })}
                                      id="auxiliary_area_directors_camp_name"
                                      name="auxiliary_area_directors_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .auxiliary_area_directors_camp_name ||
                                        ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "auxiliary_area_directors_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "auxiliary_area_directors_phone"
                                        ),
                                      })}
                                      id="auxiliary_area_directors_phone"
                                      name="auxiliary_area_directors_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .auxiliary_area_directors_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "auxiliary_area_directors_phone"
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Area 1 </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_1_aux_name"
                                        ),
                                      })}
                                      id="area_1_aux_name"
                                      name="area_1_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_1_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_1_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_1_aux_camp_name"
                                        ),
                                      })}
                                      id="area_1_aux_camp_name"
                                      name="area_1_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_1_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_1_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_1_aux_phone"
                                        ),
                                      })}
                                      id="area_1_aux_phone"
                                      name="area_1_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_1_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_1_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 2</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_2_aux_name"
                                        ),
                                      })}
                                      id="area_2_aux_name"
                                      name="area_2_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_2_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_2_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_2_aux_camp_name"
                                        ),
                                      })}
                                      id="area_2_aux_camp_name"
                                      name="area_2_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_2_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_2_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_2_aux_phone"
                                        ),
                                      })}
                                      id="area_2_aux_phone"
                                      name="area_2_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_2_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_2_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 3</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_3_aux_name"
                                        ),
                                      })}
                                      id="area_3_aux_name"
                                      name="area_3_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_3_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_3_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_3_aux_camp_name"
                                        ),
                                      })}
                                      id="area_3_aux_camp_name"
                                      name="area_3_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_3_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_3_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_3_aux_phone"
                                        ),
                                      })}
                                      id="area_3_aux_phone"
                                      name="area_3_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_3_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_3_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 4</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_4_aux_name"
                                        ),
                                      })}
                                      id="area_4_aux_name"
                                      name="area_4_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_4_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_4_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_4_aux_camp_name"
                                        ),
                                      })}
                                      id="area_4_aux_camp_name"
                                      name="area_4_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_4_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_4_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_4_aux_phone"
                                        ),
                                      })}
                                      id="area_4_aux_phone"
                                      name="area_4_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_4_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_4_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 5</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_5_aux_name"
                                        ),
                                      })}
                                      id="area_5_aux_name"
                                      name="area_5_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_5_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_5_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_5_aux_camp_name"
                                        ),
                                      })}
                                      id="area_5_aux_camp_name"
                                      name="area_5_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_5_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_5_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_5_aux_phone"
                                        ),
                                      })}
                                      id="area_5_aux_phone"
                                      name="area_5_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_5_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_5_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 6</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_6_aux_name"
                                        ),
                                      })}
                                      id="area_6_aux_name"
                                      name="area_6_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_6_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_6_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_6_aux_camp_name"
                                        ),
                                      })}
                                      id="area_6_aux_camp_name"
                                      name="area_6_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_6_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_6_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_6_aux_phone"
                                        ),
                                      })}
                                      id="area_6_aux_phone"
                                      name="area_6_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_6_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_6_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 7</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_7_aux_name"
                                        ),
                                      })}
                                      id="area_7_aux_name"
                                      name="area_7_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_7_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_7_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_7_aux_camp_name"
                                        ),
                                      })}
                                      id="area_7_aux_camp_name"
                                      name="area_7_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_7_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_7_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_7_aux_phone"
                                        ),
                                      })}
                                      id="area_7_aux_phone"
                                      name="area_7_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_7_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_7_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 8</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_8_aux_name"
                                        ),
                                      })}
                                      id="area_8_aux_name"
                                      name="area_8_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_8_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_8_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_8_aux_camp_name"
                                        ),
                                      })}
                                      id="area_8_aux_camp_name"
                                      name="area_8_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_8_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_8_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_8_aux_phone"
                                        ),
                                      })}
                                      id="area_8_aux_phone"
                                      name="area_8_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_8_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_8_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 9</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_9_aux_name"
                                        ),
                                      })}
                                      id="area_9_aux_name"
                                      name="area_9_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_9_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_9_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_9_aux_camp_name"
                                        ),
                                      })}
                                      id="area_9_aux_camp_name"
                                      name="area_9_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_9_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_9_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_9_aux_phone"
                                        ),
                                      })}
                                      id="area_9_aux_phone"
                                      name="area_9_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_9_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_9_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 10</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_10_aux_name"
                                        ),
                                      })}
                                      id="area_10_aux_name"
                                      name="area_10_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_10_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_10_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_10_aux_camp_name"
                                        ),
                                      })}
                                      id="area_10_aux_camp_name"
                                      name="area_10_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_10_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_10_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_10_aux_phone"
                                        ),
                                      })}
                                      id="area_10_aux_phone"
                                      name="area_10_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_10_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_10_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 11</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_11_aux_name"
                                        ),
                                      })}
                                      id="area_11_aux_name"
                                      name="area_11_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_11_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_11_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_11_aux_camp_name"
                                        ),
                                      })}
                                      id="area_11_aux_camp_name"
                                      name="area_11_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_11_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_11_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_11_aux_phone"
                                        ),
                                      })}
                                      id="area_11_aux_phone"
                                      name="area_11_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_11_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_11_aux_phone")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>Area 12</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_12_aux_name"
                                        ),
                                      })}
                                      id="area_12_aux_name"
                                      name="area_12_aux_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_12_aux_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_12_aux_name")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_12_aux_camp_name"
                                        ),
                                      })}
                                      id="area_12_aux_camp_name"
                                      name="area_12_aux_camp_name"
                                      value={
                                        this.state.report.auxiliary
                                          .area_12_aux_camp_name || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor(
                                      "area_12_aux_camp_name"
                                    )}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "area_12_aux_phone"
                                        ),
                                      })}
                                      id="area_12_aux_phone"
                                      name="area_12_aux_phone"
                                      value={
                                        this.state.report.auxiliary
                                          .area_12_aux_phone || ""
                                      }
                                      onChange={this.handleChange_6}
                                    />
                                    {this.renderErrorFor("area_12_aux_phone")}
                                  </td>
                                </tr>
                              </>
                            ) : null}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    {this.state.creds.type &&
                    this.state.creds.type.toUpperCase() === "STATE" ? (
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="state_editor">
                            Name and Camp of State Editor (if appointed) (not a
                            member of State Cabinet){" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("state_editor"),
                              "form-control": true,
                            })}
                            id="state_editor"
                            name="state_editor"
                            value={this.state.report.state_editor || ""}
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("state_editor")}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="state_webmaster">
                            Name of State Webmaster (if appointed) (not a member
                            of State Cabinet){" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("state_webmaster"),
                              "form-control": true,
                            })}
                            id="state_webmaster"
                            name="state_webmaster"
                            value={this.state.report.state_webmaster || ""}
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("state_webmaster")}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="state_merchandise">
                            Name and Camp of State Merchandise Officer (not a
                            member of State Cabinet){" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor(
                                "state_merchandise"
                              ),
                              "form-control": true,
                            })}
                            id="state_merchandise"
                            name="state_merchandise"
                            value={this.state.report.state_merchandise || ""}
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("state_merchandise")}
                        </div>
                      </div>
                    ) : null}

                    <div className="row">
                      <div className="col-md-12">
                        <p className="lead">
                          MEETING WITH NEWLY ELECTED CABINET
                        </p>
                        <table className="table table-bordered table-striped">
                          <tbody>
                            <tr>
                              <td>Meeting Date</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.hasErrorFor(
                                      "meeting_date"
                                    ),
                                  })}
                                  // id="meeting_date"
                                  name="meeting_date"
                                  readOnly
                                  value={
                                    this.state.report.meetings.meeting_date ||
                                    ""
                                  }
                                  // onChange={this.handleChange}
                                />
                                {this.renderErrorFor("meeting_date")}
                              </td>
                            </tr>
                            <tr>
                              <td>Meeting time</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.hasErrorFor(
                                      "meeting_time"
                                    ),
                                  })}
                                  id="meeting_time"
                                  name="meeting_time"
                                  value={
                                    this.state.report.meetings.meeting_time ||
                                    ""
                                  }
                                  onChange={this.handleChange_5}
                                />
                                {this.renderErrorFor("meeting_time")}
                              </td>
                            </tr>
                            <tr>
                              <td>Officers present</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.hasErrorFor(
                                      "officers_present"
                                    ),
                                  })}
                                  id="officers_present"
                                  name="officers_present"
                                  value={
                                    this.state.report.meetings
                                      .officers_present || ""
                                  }
                                  onChange={this.handleChange_5}
                                />
                                {this.renderErrorFor("officers_present")}
                              </td>
                            </tr>
                            <tr>
                              <td>Date of next year’s convention </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.hasErrorFor(
                                      "next_convention"
                                    ),
                                  })}
                                  id="next_convention"
                                  name="next_convention"
                                  value={
                                    this.state.report.meetings
                                      .next_convention || ""
                                  }
                                  onChange={this.handleChange_5}
                                />
                                {this.renderErrorFor("next_convention")}
                              </td>
                            </tr>
                            <tr>
                              <td>Place for next year’s convention (City) </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.hasErrorFor(
                                      "next_convention_place"
                                    ),
                                  })}
                                  id="next_convention_place"
                                  name="next_convention_place"
                                  value={
                                    this.state.report.meetings
                                      .next_convention_place || ""
                                  }
                                  onChange={this.handleChange_5}
                                />
                                {this.renderErrorFor("next_convention_place")}
                              </td>
                            </tr>
                            <tr>
                              <td> Hotel/Motel (Proposed)</td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.hasErrorFor(
                                      "hotel_proposed"
                                    ),
                                  })}
                                  id="hotel_proposed"
                                  name="hotel_proposed"
                                  value={
                                    this.state.report.meetings.hotel_proposed ||
                                    ""
                                  }
                                  onChange={this.handleChange_5}
                                />
                                {this.renderErrorFor("hotel_proposed")}
                              </td>
                            </tr>
                            <tr>
                              <td> Address </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.hasErrorFor(
                                      "hotel_address"
                                    ),
                                  })}
                                  id="hotel_address"
                                  name="hotel_address"
                                  value={
                                    this.state.report.meetings.hotel_address ||
                                    ""
                                  }
                                  onChange={this.handleChange_5}
                                />
                                {this.renderErrorFor("hotel_address")}
                              </td>
                            </tr>
                            <tr>
                              <td> City, State and Zip </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.hasErrorFor(
                                      "hotel_city_state_zip"
                                    ),
                                  })}
                                  id="hotel_city_state_zip"
                                  name="hotel_city_state_zip"
                                  value={
                                    this.state.report.meetings
                                      .hotel_city_state_zip || ""
                                  }
                                  onChange={this.handleChange_5}
                                />
                                {this.renderErrorFor("hotel_city_state_zip")}
                              </td>
                            </tr>
                            <tr>
                              <td> Phone number for hotel </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames("form-control", {
                                    "is-invalid": this.hasErrorFor(
                                      "hotel_phone"
                                    ),
                                  })}
                                  id="hotel_phone"
                                  name="hotel_phone"
                                  value={
                                    this.state.report.meetings.hotel_phone || ""
                                  }
                                  onChange={this.handleChange_5}
                                />
                                {this.renderErrorFor("hotel_phone")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      {this.state.creds.type &&
                      this.state.creds.type.toUpperCase() === "STATE" ? (
                        <>
                          <div className="col-md-12">
                            <p className="lead">
                              a. New Member Plan Activity (confirm two in
                              detail, list other place and dates below
                            </p>
                            <p>
                              (1) First New Member Plan to be held following
                              State Convention:{" "}
                            </p>
                            <div className="row">
                              <div className="form-group col-md-3">
                                <label htmlFor="member_plan_date">Date </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "member_plan_date"
                                    ),
                                    "form-control": true,
                                  })}
                                  // id="member_plan_date"
                                  name="member_plan_date"
                                  readOnly
                                  value={
                                    this.state.report.member_plan_date || ""
                                  }
                                  // onChange={this.handleChange}
                                />
                                {this.renderErrorFor("member_plan_date")}
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="member_plan_place">
                                  Place{" "}
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "member_plan_place"
                                    ),
                                    "form-control": true,
                                  })}
                                  id="member_plan_place"
                                  name="member_plan_place"
                                  value={
                                    this.state.report.member_plan_place || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("member_plan_place")}
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="member_gideon_in_charge ">
                                  Gideon in Charge{" "}
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "member_gideon_in_charge"
                                    ),
                                    "form-control": true,
                                  })}
                                  id="member_gideon_in_charge"
                                  name="member_gideon_in_charge"
                                  value={
                                    this.state.report.member_gideon_in_charge ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "member_gideon_in_charge "
                                )}
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="member_moderator ">
                                  Moderator for NMP dinner meeting{" "}
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "member_moderator"
                                    ),
                                    "form-control": true,
                                  })}
                                  id="member_moderator"
                                  name="member_moderator"
                                  value={
                                    this.state.report.member_moderator || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("member_moderator ")}
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="member_training_leader ">
                                  State Assistant Training Leader{" "}
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "member_training_leader"
                                    ),
                                    "form-control": true,
                                  })}
                                  id="member_training_leader"
                                  name="member_training_leader"
                                  value={
                                    this.state.report.member_training_leader ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("member_training_leader ")}
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <p className="lead">
                              b. New Member Plan Activity – continued.
                            </p>
                            <p>
                              (2) Second New Member Plan to be held following
                              State Convention:{" "}
                            </p>
                            <div className="row">
                              <div className="form-group col-md-3">
                                <label htmlFor="second_member_plan_date">
                                  Date{" "}
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "second_member_plan_date"
                                    ),
                                    "form-control": true,
                                  })}
                                  // id="second_member_plan_date"
                                  name="second_member_plan_date"
                                  readOnly
                                  value={
                                    this.state.report.second_member_plan_date ||
                                    ""
                                  }
                                  // onChange={this.handleChange}
                                />
                                {this.renderErrorFor("second_member_plan_date")}
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="second_member_plan_place">
                                  Place{" "}
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "second_member_plan_place"
                                    ),
                                    "form-control": true,
                                  })}
                                  id="second_member_plan_place"
                                  name="second_member_plan_place"
                                  value={
                                    this.state.report
                                      .second_member_plan_place || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "second_member_plan_place"
                                )}
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="second_member_gideon_in_charge ">
                                  Gideon in Charge{" "}
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "second_member_gideon_in_charge"
                                    ),
                                    "form-control": true,
                                  })}
                                  id="second_member_gideon_in_charge"
                                  name="second_member_gideon_in_charge"
                                  value={
                                    this.state.report
                                      .second_member_gideon_in_charge || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "second_member_gideon_in_charge "
                                )}
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="second_member_moderator ">
                                  Moderator for NMP dinner meeting{" "}
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "second_member_moderator"
                                    ),
                                    "form-control": true,
                                  })}
                                  id="second_member_moderator"
                                  name="second_member_moderator"
                                  value={
                                    this.state.report.second_member_moderator ||
                                    ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "second_member_moderator "
                                )}
                              </div>
                              <div className="form-group col-md-3">
                                <label htmlFor="second_member_training_leader ">
                                  State Assistant Training Leader{" "}
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "second_member_training_leader"
                                    ),
                                    "form-control": true,
                                  })}
                                  id="second_member_training_leader"
                                  name="second_member_training_leader"
                                  value={
                                    this.state.report
                                      .second_member_training_leader || ""
                                  }
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor(
                                  "second_member_training_leader "
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-md-12">
                            <p>(3) Other New Member Plans </p>
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th>Region</th>
                                  <th>Place</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "region_mem_1"
                                        ),
                                      })}
                                      id="region_mem_1"
                                      name="region_mem_1"
                                      value={
                                        this.state.report.members
                                          .region_mem_1 || ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("region_mem_1")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "place_mem_1"
                                        ),
                                      })}
                                      id="place_mem_1"
                                      name="place_mem_1"
                                      value={
                                        this.state.report.members.place_mem_1 ||
                                        ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("place_mem_1")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_mem_1"
                                        ),
                                      })}
                                      // id="date_mem_1"
                                      name="date_mem_1"
                                      readOnly
                                      value={
                                        this.state.report.members.date_mem_1 ||
                                        ""
                                      }
                                      // onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("date_mem_1")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "region_mem_2"
                                        ),
                                      })}
                                      id="region_mem_2"
                                      name="region_mem_2"
                                      value={
                                        this.state.report.members
                                          .region_mem_2 || ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("region_mem_2")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "place_mem_2"
                                        ),
                                      })}
                                      id="place_mem_2"
                                      name="place_mem_2"
                                      value={
                                        this.state.report.members.place_mem_2 ||
                                        ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("place_mem_2")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_mem_2"
                                        ),
                                      })}
                                      // id="date_mem_2"
                                      name="date_mem_2"
                                      readOnly
                                      value={
                                        this.state.report.members.date_mem_2 ||
                                        ""
                                      }
                                      // onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("date_mem_2")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "region_mem_3"
                                        ),
                                      })}
                                      id="region_mem_3"
                                      name="region_mem_3"
                                      value={
                                        this.state.report.members
                                          .region_mem_3 || ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("region_mem_3")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "place_mem_3"
                                        ),
                                      })}
                                      id="place_mem_3"
                                      name="place_mem_3"
                                      value={
                                        this.state.report.members.place_mem_3 ||
                                        ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("place_mem_3")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_mem_3"
                                        ),
                                      })}
                                      // id="date_mem_3"
                                      name="date_mem_3"
                                      readOnly
                                      value={
                                        this.state.report.members.date_mem_3 ||
                                        ""
                                      }
                                      // onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("date_mem_3")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "region_mem_4"
                                        ),
                                      })}
                                      id="region_mem_4"
                                      name="region_mem_4"
                                      value={
                                        this.state.report.members
                                          .region_mem_4 || ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("region_mem_4")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "place_mem_4"
                                        ),
                                      })}
                                      id="place_mem_4"
                                      name="place_mem_4"
                                      value={
                                        this.state.report.members.place_mem_4 ||
                                        ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("place_mem_4")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_mem_4"
                                        ),
                                      })}
                                      // id="date_mem_4"
                                      name="date_mem_4"
                                      readOnly
                                      value={
                                        this.state.report.members.date_mem_4 ||
                                        ""
                                      }
                                      // onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("date_mem_4")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "region_mem_5"
                                        ),
                                      })}
                                      id="region_mem_5"
                                      name="region_mem_5"
                                      value={
                                        this.state.report.members
                                          .region_mem_5 || ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("region_mem_5")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "place_mem_5"
                                        ),
                                      })}
                                      id="place_mem_5"
                                      name="place_mem_5"
                                      value={
                                        this.state.report.members.place_mem_5 ||
                                        ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("place_mem_5")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_mem_5"
                                        ),
                                      })}
                                      // id="date_mem_5"
                                      name="date_mem_5"
                                      readOnly
                                      value={
                                        this.state.report.members.date_mem_5 ||
                                        ""
                                      }
                                      // onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("date_mem_5")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "region_mem_6"
                                        ),
                                      })}
                                      id="region_mem_6"
                                      name="region_mem_6"
                                      value={
                                        this.state.report.members
                                          .region_mem_6 || ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("region_mem_6")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "place_mem_6"
                                        ),
                                      })}
                                      id="place_mem_6"
                                      name="place_mem_6"
                                      value={
                                        this.state.report.members.place_mem_6 ||
                                        ""
                                      }
                                      onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("place_mem_6")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_mem_6"
                                        ),
                                      })}
                                      // id="date_mem_6"
                                      name="date_mem_6"
                                      readOnly
                                      value={
                                        this.state.report.members.date_mem_6 ||
                                        ""
                                      }
                                      // onChange={this.handleChange_4}
                                    />
                                    {this.renderErrorFor("date_mem_6")}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>

                          <div className="col-md-12">
                            <p>c. Church Relations Days Scheduled </p>
                            <table className="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th>S/N</th>
                                  <th>Name of Church</th>
                                  <th>Date Scheduled</th>
                                  <th>Camp Assigned</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>1.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_1"
                                        ),
                                      })}
                                      id="name_of_church_1"
                                      name="name_of_church_1"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_1 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_1")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_1"
                                        ),
                                      })}
                                      // id="date_scheduled_1"
                                      name="date_scheduled_1"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_1 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_1")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_1"
                                        ),
                                      })}
                                      id="camp_assigned_1"
                                      name="camp_assigned_1"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_1 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_1")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>2.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_2"
                                        ),
                                      })}
                                      id="name_of_church_2"
                                      name="name_of_church_2"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_2 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_2")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_2"
                                        ),
                                      })}
                                      // id="date_scheduled_2"
                                      name="date_scheduled_2"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_2 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_2")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_2"
                                        ),
                                      })}
                                      id="camp_assigned_2"
                                      name="camp_assigned_2"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_2 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_2")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>3.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_3"
                                        ),
                                      })}
                                      id="name_of_church_3"
                                      name="name_of_church_3"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_3 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_3")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_3"
                                        ),
                                      })}
                                      // id="date_scheduled_3"
                                      name="date_scheduled_3"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_3 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_3")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_3"
                                        ),
                                      })}
                                      id="camp_assigned_3"
                                      name="camp_assigned_3"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_3 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_3")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>4.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_4"
                                        ),
                                      })}
                                      id="name_of_church_4"
                                      name="name_of_church_4"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_4 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_4")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_4"
                                        ),
                                      })}
                                      // id="date_scheduled_4"
                                      name="date_scheduled_4"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_4 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_4")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_4"
                                        ),
                                      })}
                                      id="camp_assigned_4"
                                      name="camp_assigned_4"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_4 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_4")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>5.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_5"
                                        ),
                                      })}
                                      id="name_of_church_5"
                                      name="name_of_church_5"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_5 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_5")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_5"
                                        ),
                                      })}
                                      // id="date_scheduled_5"
                                      name="date_scheduled_5"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_5 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_5")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_5"
                                        ),
                                      })}
                                      id="camp_assigned_5"
                                      name="camp_assigned_5"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_5 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_5")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>6.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_6"
                                        ),
                                      })}
                                      id="name_of_church_6"
                                      name="name_of_church_6"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_6 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_6")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_6"
                                        ),
                                      })}
                                      // id="date_scheduled_6"
                                      name="date_scheduled_6"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_6 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_6")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_6"
                                        ),
                                      })}
                                      id="camp_assigned_6"
                                      name="camp_assigned_6"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_6 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_6")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>7.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_7"
                                        ),
                                      })}
                                      id="name_of_church_7"
                                      name="name_of_church_7"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_7 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_7")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_7"
                                        ),
                                      })}
                                      // id="date_scheduled_7"
                                      name="date_scheduled_7"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_7 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_7")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_7"
                                        ),
                                      })}
                                      id="camp_assigned_7"
                                      name="camp_assigned_7"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_7 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_7")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>8.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_8"
                                        ),
                                      })}
                                      id="name_of_church_8"
                                      name="name_of_church_8"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_8 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_8")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_8"
                                        ),
                                      })}
                                      // id="date_scheduled_8"
                                      name="date_scheduled_8"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_8 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_8")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_8"
                                        ),
                                      })}
                                      id="camp_assigned_8"
                                      name="camp_assigned_8"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_8 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_8")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>9.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_9"
                                        ),
                                      })}
                                      id="name_of_church_9"
                                      name="name_of_church_9"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_9 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_9")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_9"
                                        ),
                                      })}
                                      // id="date_scheduled_9"
                                      name="date_scheduled_9"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_9 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_9")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_9"
                                        ),
                                      })}
                                      id="camp_assigned_9"
                                      name="camp_assigned_9"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_9 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_9")}
                                  </td>
                                </tr>
                                <tr>
                                  <td>10.</td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "name_of_church_10"
                                        ),
                                      })}
                                      id="name_of_church_10"
                                      name="name_of_church_10"
                                      value={
                                        this.state.report.relations
                                          .name_of_church_10 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("name_of_church_10")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "date_scheduled_10"
                                        ),
                                      })}
                                      // id="date_scheduled_10"
                                      name="date_scheduled_10"
                                      value={
                                        this.state.report.relations
                                          .date_scheduled_10 || ""
                                      }
                                      // onChange={this.handleChange_3}
                                      readOnly
                                    />
                                    {this.renderErrorFor("date_scheduled_10")}
                                  </td>
                                  <td>
                                    <input
                                      type="text"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "camp_assigned_10"
                                        ),
                                      })}
                                      id="camp_assigned_10"
                                      name="camp_assigned_10"
                                      value={
                                        this.state.report.relations
                                          .camp_assigned_10 || ""
                                      }
                                      onChange={this.handleChange_3}
                                    />
                                    {this.renderErrorFor("camp_assigned_10")}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="">
                      <div className="form-group col-md-6">
                        <label htmlFor="number_of_camps">
                          d. Number of camps with no Scripture Fund Received to
                          date{" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("number_of_camps"),
                            "form-control": true,
                          })}
                          id="number_of_camps"
                          name="number_of_camps"
                          value={this.state.report_bck.number_of_camps || ""}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("number_of_camps")}
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="date_first_conv">
                          e. Date of first State Cabinet Meeting following State
                          Convention{" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("date_first_conv"),
                            "form-control": true,
                          })}
                          // id="date_first_conv"
                          name="date_first_conv"
                          value={this.state.report_bck.date_first_conv || ""}
                          readOnly
                          // onChange={this.handleChange}
                        />
                        {this.renderErrorFor("date_first_conv")}
                      </div>
                      <div className="col-md-12">
                        <p>
                          f. For other results of this meeting with the newly
                          elected State Cabinet, see my letter to State
                        </p>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="president_dated">
                          President dated{" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("president_dated"),
                            "form-control": true,
                          })}
                          // id="president_dated"
                          name="president_dated"
                          value={this.state.report_bck.president_dated || ""}
                          readOnly
                          // onChange={this.handleChange}
                        />
                        {this.renderErrorFor("president_dated")}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="hotel_place_bible">
                          Hotel Placed Bible{" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("hotel_place_bible"),
                            "form-control": true,
                          })}
                          id="hotel_place_bible"
                          name="hotel_place_bible"
                          value={this.state.report_bck.hotel_place_bible || ""}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("hotel_place_bible")}
                      </div>
                    </div>

                    {this.state.creds.type &&
                    this.state.creds.type.toUpperCase() === "STATE" ? (
                      <div className="row">
                        <div className="form-group col-md-6">
                          <label htmlFor="date_first_conv">
                            e. Date of first State Cabinet Meeting following
                            State Convention{" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("date_first_conv"),
                              "form-control": true,
                            })}
                            // id="date_first_conv"
                            name="date_first_conv"
                            value={this.state.report.date_first_conv || ""}
                            readOnly
                            // onChange={this.handleChange}
                          />
                          {this.renderErrorFor("date_first_conv")}
                        </div>
                        <div className="col-md-12">
                          <p>
                            f. For other results of this meeting with the newly
                            elected State Cabinet, see my letter to State
                          </p>
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="president_dated">
                            President dated{" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("president_dated"),
                              "form-control": true,
                            })}
                            // id="president_dated"
                            name="president_dated"
                            value={this.state.report.president_dated || ""}
                            readOnly
                            // onChange={this.handleChange}
                          />
                          {this.renderErrorFor("president_dated")}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="hotel_place_bible">
                            Hotel Placed Bible{" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor(
                                "hotel_place_bible"
                              ),
                              "form-control": true,
                            })}
                            id="hotel_place_bible"
                            name="hotel_place_bible"
                            value={this.state.report.hotel_place_bible || ""}
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("hotel_place_bible")}
                        </div>
                      </div>
                    ) : null}

                    <div className="row">
                      <div className="col-md-12">
                        <p className="lead">Recommendation</p>
                        <p>
                          <strong>
                            Do you recommend any Gideon appearing on the State
                            Convention Educational Programme to be considered by
                            the National Convention Programme Committee to speak
                            at a future National Convention?
                          </strong>
                        </p>
                        <table className="table table-bordered table-striped">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Subject</th>
                              <th>Camp</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_name_1"
                                    ),
                                  })}
                                  id="recommend_name_1"
                                  name="recommend_name_1"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_name_1 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_name_1")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_subject_1"
                                    ),
                                  })}
                                  id="recommend_subject_1"
                                  name="recommend_subject_1"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_subject_1 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_subject_1")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_camp_1"
                                    ),
                                  })}
                                  id="recommend_camp_1"
                                  name="recommend_camp_1"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_camp_1 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_camp_1")}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_name_2"
                                    ),
                                  })}
                                  id="recommend_name_2"
                                  name="recommend_name_2"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_name_2 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_name_2")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_subject_2"
                                    ),
                                  })}
                                  id="recommend_subject_2"
                                  name="recommend_subject_2"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_subject_2 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_subject_2")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_camp_2"
                                    ),
                                  })}
                                  id="recommend_camp_2"
                                  name="recommend_camp_2"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_camp_2 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_camp_2")}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_name_3"
                                    ),
                                  })}
                                  id="recommend_name_3"
                                  name="recommend_name_3"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_name_3 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_name_3")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_subject_3"
                                    ),
                                  })}
                                  id="recommend_subject_3"
                                  name="recommend_subject_3"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_subject_3 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_subject_3")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_camp_3"
                                    ),
                                  })}
                                  id="recommend_camp_3"
                                  name="recommend_camp_3"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_camp_3 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_camp_3")}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_name_4"
                                    ),
                                  })}
                                  id="recommend_name_4"
                                  name="recommend_name_4"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_name_4 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_name_4")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_subject_4"
                                    ),
                                  })}
                                  id="recommend_subject_4"
                                  name="recommend_subject_4"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_subject_4 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_subject_4")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_camp_4"
                                    ),
                                  })}
                                  id="recommend_camp_4"
                                  name="recommend_camp_4"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_camp_4 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_camp_4")}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_name_5"
                                    ),
                                  })}
                                  id="recommend_name_5"
                                  name="recommend_name_5"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_name_5 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_name_5")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_subject_5"
                                    ),
                                  })}
                                  id="recommend_subject_5"
                                  name="recommend_subject_5"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_subject_5 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_subject_5")}
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "recommend_camp_5"
                                    ),
                                  })}
                                  id="recommend_camp_5"
                                  name="recommend_camp_5"
                                  value={
                                    this.state.report_bck.recommend
                                      .recommend_camp_5 || ""
                                  }
                                  onChange={this.handleChange_1}
                                />
                                {this.renderErrorFor("recommend_camp_5")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row">
                      <div className="form-group col-md-12">
                        <label htmlFor="additional_info">
                          ADDITIONAL INFORMATION noted at the convention.{" "}
                        </label>
                        <textarea
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("additional_info"),
                            "form-control": true,
                          })}
                          id="additional_info"
                          name="additional_info"
                          value={this.state.report_bck.additional_info || ""}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("additional_info")}
                      </div>
                    </div>
                  </>
                ) : null}

                <div className="row mb-1">
                  <div className="col-12">
                    <button
                      className="btn btn-primary"
                      onClick={this.toggleShowReg}
                    >
                      {this.state.show_reg
                        ? `Hide Registeration Details`
                        : `Show Registeration Details`}
                    </button>
                  </div>
                </div>

                {this.state.show_reg && this.state.regist.length ? (
                  <div className="row ">
                    <hr />
                    <div className="col-12">
                      <p className="lead  my-3">Registeration Details</p>
                      <div key="uniqueKey">
                        <table
                          id="fund_table12"
                          className="table table-hover table-bordered table-striped"
                          style={{
                            width: "100%",
                            marginTop: "5px",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>S/N</th>
                              <th>Name</th>
                              <th>Member ID</th>
                              <th>Camp</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Paid Date</th>
                              <th>Amount(&#8358;)</th>
                              <th>Payment For</th>
                              <th>Hotel Reservation</th>
                              <th>Church</th>
                              <th>More Details</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.regist &&
                              this.state.regist.map((item, index) => {
                                return (
                                  <React.Fragment key={index}>
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>
                                        {item.user.first_name} &nbsp;
                                        {item.user.last_name}
                                      </td>
                                      <td>{item.user.login_id}</td>
                                      <td>{item.camp.name}</td>
                                      <td>{item.user.email || ""}</td>
                                      <td>{item.user.phone || ""}</td>
                                      <td>{this.dateConv(item.updated_at)}</td>
                                      <td>{item.amount}</td>
                                      <td>{this.getTypye(item.type)}</td>
                                      <td>{item.reservation}</td>
                                      <td>{item.service}</td>
                                      <td>
                                        <Link
                                          to={`/preferences/activities/convention/more-details/${item.convention_id}?id=${item.id}`}
                                        >
                                          View More
                                        </Link>
                                      </td>
                                    </tr>
                                  </React.Fragment>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {this.initDependency()}
                  </div>
                ) : null}

                <Link
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  to="#!"
                  onClick={this.goBack}
                >
                  Back
                </Link>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    states1: state.preferences.states,
    spinner1: state.preferences.spinner,
    message1: state.preferences.message,
    status1: state.preferences.status,
    errors1: state.preferences.errors,
    states: state.preferences.states,
    report_bck: state.preferences.report_bck,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    createNewActivityCon: (creds) => createNewActivityCon(dispatch, creds),
    resetUsersState: () => resetUsersState(dispatch),
    clearPreferenceReport: () => clearPreferenceReport(dispatch),
    getAllSettings2: () => getAllSettings2(dispatch),
    getAllStates: () => dispatch(getAllStates()),
    getOneCampActivityCon: (id) => getOneCampActivityCon(dispatch, id),
    getCampActivityConReg: (id) => getCampActivityConReg(dispatch, id),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BackCon);
