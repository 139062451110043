//import React, { Component } from 'react'  
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import Table from '@material-ui/core/Table';  
import TableBody from '@material-ui/core/TableBody';  
import TableCell from '@material-ui/core/TableCell';  
import TableContainer from '@material-ui/core/TableContainer';  
import TableHead from '@material-ui/core/TableHead';  
import TableRow from '@material-ui/core/TableRow';  
import Paper from '@material-ui/core/Paper';  
import axios from 'axios';  
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import {
    BASE_URL,
    token,
    storage_type,
    user_auth_details,
    dehash,
    UserIdList,
    depotrowlist
  } from "../../../../config";


export class RemoveAssignedDepot extends Component {
    constructor(props) {  
        super(props)  
        this.state = {  
          staffs: [],
          depots: []  
        }  
    }  
    componentDidMount() {  
        axios.get(`${BASE_URL}/staffs`,
        {method:'GET',
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage_type.getItem(token)}`},
        }, 
        ).then(response => {
          if(response.data.payload.staffs.length !== 0){
            console.log("Selected Depots: ", response.data.payload.staffs);
            this.setState({  
              staffs: response.data.payload.staffs
            });
          }
        });
    } 
      
    handleStaffChange = e => {
        this.setState({ 
          staff_id: e.target.value,
          depots: [] 
        });
        const staffId = e.target.value;
        axios.get(`${BASE_URL}/staff-depots?id=${staffId}`,
            {method:'GET',
              headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${storage_type.getItem(token)}`},
            }, 
            ).then(response => {
              if(response.data.payload.staffDepots.length !== 0){
                console.log("Selected Depots: ", response.data.payload.staffDepots[0].depots);
                this.setState({  
                  depots:response.data.payload.staffDepots[0].depots
                });
                console.log("Depot Row List: ", this.state.depots);
              }
            });
    };

    render() {  
        const { spinner, status, message } = this.props;
        console.log(this.state.staffs);  
        return (  
        <div className="content-wrapper">
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>PREFERENCES</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item">
                                    <Link to="/">Dashboard</Link>
                                </li>
                                <li className="breadcrumb-item active">Deallocate Depot</li>
                            </ol>
                        </div>
                    </div>
                </div>
                {/* /.container-fluid */}
            </section>
            {/* Main content */}
            <section className="content">
            {/* Default box */}
                <div className="card">
                    <div className="card-header">
                        <h3 className="card-title font-weight-bold ">Deallocate Depot</h3>
                        <div className="card-tools"></div>
                    </div>
                    <div className="card-body">
                        {spinner ? <Spinner position="right" /> : null}
                        {status && message ? (
                            <Message status={status} message={message} />
                        ) : null}
                        <form onSubmit={this.handleSubmit}>
                            <div className="row">
                                <label>
                                    Staffs <span className="text-danger">*</span>
                                </label>
                                <div className="form-group col-md-6">
                                    <select
                                        className={classnames({
                                            //"is-invalid": this.hasErrorFor("user_id"),
                                            "form-control selected_state": true
                                        })}
                                        placeholder="Select"
                                        onChange={this.handleStaffChange}
                                        id="staff_id"
                                        value={this.state.staff_id}
                                    >
                                    <option value="">--Select--</option>
                                        {this.state.staffs &&
                                        this.state.staffs.map((staff, index) => (
                                            <option key={index} value={staff.id}>
                                                {staff.first_name} {staff.last_name}{" "}
                                                {staff.other_name}
                                            </option>
                                        ))}
                                    </select>
                                    {/* {this.renderErrorFor("user_id")} */}
                                </div>
                            </div>
                            {/* <table id="mytable"></table>  
                            <div className="App"> <ReactTable data={this.state.data} columns={this.columns} /> </div> */}
                            {/* {depotrowlist.name==='FIRST'? this.EnhancedTable({...this.state.depots}) : this.EnhancedTable({...this.state.selectedDepots})} */}
                            <TableContainer component={Paper}>  
                                <Table stickyHeader  aria-label="sticky table">  
                                    <TableHead>  
                                        <TableRow>  
                                            <TableCell align="left">Depot Name</TableCell>  
                                            <TableCell align="right">Depot Address</TableCell>  
                                            <TableCell align="right">Date Created</TableCell>  
                                            <TableCell align="right">Date Assigned</TableCell>  
                                            {/* <TableCell align="right">ContactNum</TableCell>  
                                            <TableCell align="right">Salary</TableCell>  
                                            <TableCell style={{paddingRight:"60px"}} align="right" >Department</TableCell>   */}
                                        </TableRow>  
                                    </TableHead>  
                                    <TableBody>  
                                        {  
                                            this.state.depots.map((p, index) => {  
                                                return <TableRow key={index}>  
                                                    <TableCell align="left">{p.name}</TableCell>  
                                                    <TableCell align="right">{p.address}</TableCell>  
                                                    <TableCell align="right">{p.created_at}</TableCell>  
                                                    <TableCell align="right">{p.updated_at}</TableCell>  
                                                    {/* <TableCell align="right">{p.Salary}</TableCell>  
                                                    <TableCell style={{paddingRight:"114px"}} align="right">{p.Department}</TableCell>   */}
                                                </TableRow>  
                                            })  
                                        }  
                                    </TableBody>  
                                </Table>  
                            </TableContainer>  
                            <div className="text-center">
                                {/* <Button
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                style={{ textTransform: "none" }}
                                onClick={this.handlePurge}
                                >
                                Purge Records
                                </Button>{" "} */}
                                <button
                                    type="submit"
                                    className="btn btn-warning btn-sm text-uppercase font-weight-bold  mx-auto"
                                    disabled={spinner ? true : false}
                                    >
                                    Deallocate
                                </button>{" "}
                                <Link
                                    to={"/preferences/depots"}
                                    className="btn btn-default btn-sm text-uppercase font-weight-bold ml-1"
                                    >
                                    View Depot
                                </Link>
                            </div>
                        </form>
                    </div>
                    {/* /.card-body */}     
                </div>
                {/* /.card */}
            </section>
        </div>
        );  
      }  
    }  

    export default RemoveAssignedDepot 