import React from "react";
import { Link } from "react-router-dom";

function DelistedAuxTable({ auxilliaries, setRemarkPropertiesGid, dateConv }) {
  return (
    <div key="uniqueKey">
      <table
        id="example"
        className="table table-hover table-bordered table-striped"
        style={{
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th>S/N</th>

            <th>Last Name</th>
            <th>First Name</th>
            <th>Other Name</th>
            <th>Member Id</th>
            <th>Spouse Id</th>
            <th>Spouse Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Recruitment Method</th>
            <th>Renewal Type</th>
            <th>Camp</th>
            <th>Area</th>
            <th>Region</th>
            <th>State</th>

            <th>Status</th>
            <th>Due Date</th>

            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {auxilliaries &&
            auxilliaries.map((auxilliary, index) => {
              return (
                <tr key={auxilliary.id}>
                  <td>{index + 1}</td>

                  <td>{auxilliary.last_name}</td>
                  <td>{auxilliary.first_name}</td>
                  <td>{auxilliary.other_name || "--"}</td>
                  <td>{auxilliary.login_id || "--"}</td>
                  <td>
                    {auxilliary.gideon ? auxilliary.gideon.login_id : "--"}
                  </td>
                  <td>
                    {auxilliary.gideon
                      ? `${auxilliary.gideon.first_name} ${auxilliary.gideon.last_name}`
                      : "--"}
                  </td>
                  <td>
                    {auxilliary.phone != null ? auxilliary.phone.trim() : "--"}
                  </td>
                  <td>{auxilliary.email || "--"}</td>
                  <td>
                    {auxilliary.recruitment != null &&
                    auxilliary.recruitment != "null"
                      ? auxilliary.recruitment.trim()
                      : "--"}
                  </td>
                  <td>
                    {" "}
                    <span className="text-uppercase">
                      {auxilliary.membership_type || "--"}
                    </span>{" "}
                  </td>
                  <td>
                    {auxilliary.camp != null && auxilliary.camp != "null"
                      ? auxilliary.camp.name != null
                        ? auxilliary.camp.name
                        : "--"
                      : "--"}
                  </td>
                  <td>
                    {auxilliary.camp != null && auxilliary.camp != "null"
                      ? auxilliary.camp.area != null
                        ? auxilliary.camp.area.name != null
                          ? auxilliary.camp.area.name
                          : "--"
                        : "--"
                      : "--"}
                  </td>
                  <td>
                    {auxilliary.camp != null && auxilliary.camp != "null"
                      ? auxilliary.camp.area != null
                        ? auxilliary.camp.area.region != null
                          ? auxilliary.camp.area.region.name != null
                            ? auxilliary.camp.area.region.name
                            : "--"
                          : "--"
                        : "--"
                      : "--"}
                  </td>
                  <td>
                    {auxilliary.camp != null && auxilliary.camp != "null"
                      ? auxilliary.camp.area != null
                        ? auxilliary.camp.area.region != null
                          ? auxilliary.camp.area.region.state.name != null
                            ? auxilliary.camp.area.region.state.name
                            : "--"
                          : "--"
                        : "--"
                      : "--"}
                  </td>
                  <td> {auxilliary.status} </td>
                  <td> {dateConv(auxilliary.expiry_date)} </td>

                  <td>
                    <span
                      style={{
                        overflow: "visible",
                        position: "relative",
                      }}
                    >
                      <center>
                        <Link
                          onClick={(e) =>
                            setRemarkPropertiesGid(
                              auxilliary.id,
                              auxilliary.first_name + " " + auxilliary.last_name
                            )
                          }
                          to="#"
                          className="btn btn-sm btn-danger btn-icon"
                          data-toggle="modal"
                          data-target="#RemarkGidModal"
                        >
                          <i className="fas fa-trash-alt" />
                        </Link>
                      </center>
                    </span>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(DelistedAuxTable);
