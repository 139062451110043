import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import {
//   getAllCamps,
//   deleteCampAction
// } from "./../../../store/actions/preferencesActions";
import { getAllDelistedHistory } from "./../../../store/actions/usersActions";
import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../store/actions/authActions";

class ViewDelistedHistory extends Component {
  state = {
    initdep: false,
    delistedhistory: null
  };

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.setState({ initdep: true });
    const { setPermissionsErrors, getAllDelistedHistory } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBER" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllDelistedHistory();
    }
  }

  initDependency = () => {
    if (this.state.initdep) {
      const old_element = document.querySelector(
        "script[src='/js/content.js']"
      );
      const new_element = document.createElement("script");
      new_element.src = `/js/content.js`;
      new_element.async = true;
      if (old_element) {
        old_element.replaceWith(new_element);
      } else {
        document.body.appendChild(new_element);
      }
      this.setState({ initdep: false });
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.delistedhistory !== prevState.delistedhistory) {
      return {
        delistedhistory: nextProps.delistedhistory,
      };
    }
    return null;
  }
  getMemberStatus = (status) => {
    if (status === "GOOD") {
      return "GOOD STANDING";
    } else if (status === "TRANS") {
      return "TRANSFERED";
    } else if (status === "DELI") {
      return "DELIQUENT";
    } else if (status === "DROP") {
      return "DROPPED";
    } else if (status === "ACTI") {
      return "ACTIVE";
    } else if (status === "DELISTED") {
      return "DELISTED";
    } else if (status === "EXIT") {
      return "EXIT";
    }
    return "";
  };
  dateConv = (date) => {
    if (date === null || date === "") {
      return null;
    }
    return new Date(date).toDateString();
  };
  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Membership</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">View Delist History</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">View Delist History</h3>

                <Link
                  to="/membership/delist"
                  style={{'float': 'right'}}
                  className="btn btn-sm btn-danger btn-icon mr-1"
                  title="Back to delisted"
                >
                  <i className="fas fa-arrow-circle-left text-white"></i> &nbsp; Back
                </Link>

            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <table
                    id="example"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th><center>S/N</center></th>
                        <th>Last Name</th>
                        <th>First Name</th>
                        <th>Other Name</th>
                        <th>Member Id</th>
                        <th>Status</th>
                        <th>Prev status</th>

                        <th>Remarks</th>
                        <th>Created at</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.delistedhistory &&
                        this.state.delistedhistory.map((delisthistory, index) => {
                          return (
                            <tr key={index} style={{'background': (delisthistory.status == 'DELISTED') ? '#ff929269' : '#4fff4f4a'}}>
                              <td><center>{index + 1}</center></td>

                              <td>{delisthistory.last_name}</td>
                              <td>{delisthistory.first_name}</td>
                              <td>{delisthistory.other_name || "--"}</td>
                              <td>{delisthistory.login_id || "--"}</td>
                              <td> {delisthistory.status} </td>
                              <td> {delisthistory.prev_status} </td>

                              <td> {delisthistory.remark ?? '--'} </td>
                              <td> {delisthistory.created_at} </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    delistedhistory: state.users.delistedhistory,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllDelistedHistory: () => dispatch(getAllDelistedHistory()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewDelistedHistory);
