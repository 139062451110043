import React, { Component } from "react";
import { connect } from "react-redux";
// import { HashRouter } from "react-router-dom";

export default WrappedComponent => {
  class alreadyAuth extends Component {
    componentDidMount() {
      if (this.props.isAuth) {
        this.props.history.push("/");
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  const mapStateToprops = state => {
    return {
      isAuth: state.auth.isAuth
    };
  };
  return connect(mapStateToprops)(alreadyAuth);
};
