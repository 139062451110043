import React, { Component } from "react";
import { Link } from "react-router-dom";
// import classnames from "classnames";
import { connect } from "react-redux";
import Modal from "./Modal";
import {
  setProducts,
  addToCart,
  modalOpen,
  modalClose
} from "./../../../store/actions/tstoreActions";
import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import { setPermissionsErrors } from "./../../../store/actions/authActions";
import Product from "./Product";
import { storage_type, user_auth_details, dehash } from "./../../../config";
class ProductList extends Component {
  state = {
    products: [],
    modalOpen2: false,
    modalProduct: {}
  };
  // componentDidMount() {

  // }
  // setProducts = () => {
  //   let temproducts = [];
  //   storeProducts.forEach(item => {
  //     const singleItem = { ...item };
  //     temproducts = [...temproducts, singleItem];
  //   });
  //   this.setState({ products: temproducts });
  // };

  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    const user = JSON.parse(
      dehash(`${storage_type.getItem(user_auth_details)}`)
    );

    if (user.type === "S") {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const { setProducts } = this.props;
      setProducts();
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.products !== prevState.products ||
      nextProps.modalProduct !== prevState.modalProduct ||
      nextProps.modalOpen2 !== prevState.modalOpen2
    ) {
      return {
        products: nextProps.products,
        modalProduct: nextProps.modalProduct,
        modalOpen2: nextProps.modalOpen2
      };
    }
    return null;
  }
  addToCart = id => {
    this.props.addToCart(id);
  };
  modalOpen = id => {
    this.props.modalOpen(id);
  };
  modalClose = () => {
    this.props.modalClose();
  };

  render() {
    const { spinner, status, message } = this.props;
    const products = this.state.products;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>STORE</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">My Store</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">My Store</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}

              <React.Fragment>
                <div className="row">
                  {products &&
                    products.map((product, index) => {
                      return (
                        <Product
                          key={index}
                          product={product}
                          addToCart={this.addToCart}
                          modalOpen={this.modalOpen}
                          modalClose={this.modalClose}
                        />
                      );
                    })}
                </div>
                {this.state.modalOpen2 && this.state.modalProduct ? (
                  <Modal
                    modalClose={this.modalClose}
                    modalProduct={this.state.modalProduct}
                  />
                ) : null}
              </React.Fragment>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.tstore.spinner,
    message: state.tstore.message,
    status: state.tstore.status,
    errors: state.tstore.errors,
    products: state.tstore.products,
    modalOpen2: state.tstore.modalOpen2,
    modalProduct: state.tstore.modalProduct
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    setProducts: () => dispatch(setProducts()),
    addToCart: id => dispatch(addToCart(id)),
    modalOpen: id => dispatch(modalOpen(id)),
    modalClose: () => dispatch(modalClose())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
