import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../../hooks";
import MiniSpinner from "../../../helpers/MiniSpinner";
import Message from "../../../helpers/Message";
import Spinner from "../../../helpers/Spinner";
import { resetNotificationState } from "../../../../store/actions/notificationActions";
import { useDispatch, connect } from "react-redux";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import $ from "jquery";

import { useGetUploadedPrayerCalender } from "../../../../store/hookactions/notificationsActionhooks";

function ViewUploadedCalendar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { spinner_pref } = props;
  const [enabled_upload, setEnabledUpload] = useState(false);
  const [gideon_year, setGideonYear] = useState("");
  const [month, setMonth] = useState("");

  useGetUploadedPrayerCalender(
    enabled_upload,
    setEnabledUpload,
    month,
    gideon_year
  );

  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const errors = useShallowEqualSelector((state) => state.notifications.errors);

  const status = useShallowEqualSelector((state) => state.notifications.status);

  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );
  const prayer_calendar = useShallowEqualSelector(
    (state) => state.notifications.prayer_calender
  );

  function initDependency() {
    console.log("dfd");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  useLayoutEffect(() => {
    initDependency();
  }, []);

  useEffect(() => {
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "PRAYER_CALENDAR_UPLOAD" })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();

    return () => {
      resetNotificationState(dispatch);
    };
  }, [dispatch, history]);

  function handleSubmit(e) {
    e.preventDefault();
    const only_months = $("#only_months").val();
    const only_year = $("#only_year").val();
    console.log({ only_months, only_year });
    setGideonYear(only_year);
    setMonth(only_months);
    setEnabledUpload(true);
  }
  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>PREFERENCES</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  View Upload Prayer Calender{" "}
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              View Upload Prayer Calender{" "}
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}

            <form onSubmit={handleSubmit}>
              <div className="row  ">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="only_year">
                      Gideon Year <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": hasErrorFor("only_year"),
                        "form-control": true,
                      })}
                      id="only_year"
                      name="only_year"
                      readOnly
                      placeholder="Select date"
                    />
                    {renderErrorFor("only_year")}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="only_months">
                      Month <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": hasErrorFor("only_months"),
                        "form-control": true,
                      })}
                      id="only_months"
                      name="only_months"
                      readOnly
                      placeholder="Select date"
                    />
                    {renderErrorFor("only_months")}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  View
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
            </form>

            {prayer_calendar.length ? (
              <div key="uniqueKey">
                <table
                  id="fund_table1"
                  className="table table-bordered"
                  style={{
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Category</th>
                      <th>Avatar</th>
                      <th>Location</th>
                      <th>Content</th>
                      <th>Verse</th>
                    </tr>
                  </thead>
                  <tbody>
                    {prayer_calendar.map(({ prayer_calendar }) => {
                      return (
                        <tr key={prayer_calendar.id}>
                          <td>{prayer_calendar.date}</td>
                          <td>{prayer_calendar.category}</td>
                          <td>
                            {" "}
                            <img
                              src={prayer_calendar.avatar}
                              style={{
                                width: "50px",
                                height: "50px",
                                borderRadius: "50%",
                              }}
                            />{" "}
                          </td>
                          <td>{prayer_calendar.location}</td>
                          <td>{prayer_calendar.content}</td>
                          <td>{prayer_calendar.verse}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : null}
          </div>
          {/* {initDependency()} */}

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    spinner_pref: state.preferences.spinner,
    message_pref: state.preferences.message,
    status_pref: state.preferences.status,
    errors_pref: state.preferences.errors,
  };
};

export default connect(mapStateToProps)(ViewUploadedCalendar);
