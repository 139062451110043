import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useQuery } from "react-query";
import { privateRequestGet } from "../../requestMethods";

const useGetSystemRoles = (enabled, setEnabled) => {
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_roles"],
    async () => {
      const result = await privateRequestGet.get(`/roles`);

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "SYSTEM_ROLES_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "SYSTEM_ROLES_ERROR", payload: resMessage });
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetSystemCamps = (enabled, setEnabled) => {
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_camps"],
    async () => {
      const result = await privateRequestGet.get(`/camps`);

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    }
    if (data) {
      dispatch({ type: "SET_ALL_CAMPS", resMessage: data });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "ERRORS_SET_ALL_CAMPS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetSystemAreas = (enabled, setEnabled) => {
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_areas"],
    async () => {
      const result = await privateRequestGet.get(`/areas`);

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    }
    if (data) {
      dispatch({ type: "SET_ALL_AREAS", resMessage: data });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "ERRORS_SET_ALL_AREAS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetSystemRegions = (enabled, setEnabled) => {
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_regions"],
    async () => {
      const result = await privateRequestGet.get(`/regions`);

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    }
    if (data) {
      dispatch({ type: "SET_ALL_REGIONS", resMessage: data });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "ERRORS_SET_ALL_REGIONS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};

const useGetSystemStates = (enabled, setEnabled) => {
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["system_states"],
    async () => {
      const result = await privateRequestGet.get(`/states`);

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    }
    if (data) {
      dispatch({ type: "SET_ALL_STATES", resMessage: data });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "ERRORS_SET_ALL_STATES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampPendingNotifications = (
  enabled,
  setEnabled,
  status,
  start,
  end
) => {
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["camp_notifications", status, start, end],
    async () => {
      const result = await privateRequestGet.get(
        `/notification-messages/view-all-messages/camp/?status=${status}&start=${start}&end=${end}`
      );
      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "CLEAR_NOTIFICATION_ERRORS" });
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({
        type: "PENDING_CAMP_NOTIFICATION_SUCCESS",
        payload: data,
      });
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({
        type: "PENDING_CAMP_NOTIFICATION_ERRORS",
        payload: resMessage,
      });
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampSetGoal = (enabled, setEnabled, from_date, camp_id) => {
  const dispatch = useDispatch();

  const { data, error, refetch, isLoading } = useQuery(
    ["camp_set_goals", from_date, camp_id],
    async () => {
      const result = await privateRequestGet.get(
        `/goals/track-goal/camp?camp_id=${camp_id}&gideon_year=${from_date}`
      );

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "CAMP_GOAL_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "CAMP_GOAL_ERROR", payload: resMessage });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetGoalStatistics = (enabled, setEnabled, from_date, id, level) => {
  const dispatch = useDispatch();

  const { data, error, refetch, isLoading } = useQuery(
    ["camp_set_goals", from_date, id],
    async () => {
      const result = await privateRequestGet.post(
        `/goals/view-goal-performance/chart`,
        {
          gideon_year: from_date,
          level,
          level_points: id,
        }
      );

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GOAL_STATS_SUCCESS", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      console.log(resMessage);
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GOAL_STATS_ERROR", payload: resMessage });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetUploadedPrayerCalender = (enabled, setEnabled, month, year) => {
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_uploaded_prayer_calender", month, year],
    async () => {
      const result = await privateRequestGet.get(
        `/bible/get-all-prayer-calendar?month=${month}&year=${year}`
      );

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      console.log(data);
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_UPLOADED_PRAYER_CALENDER", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_UPLOADED_PRAYER_CALENDER_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetUploadedBibleVerse = (enabled, setEnabled, month, year) => {
  const dispatch = useDispatch();
  const { data, error, refetch, isLoading } = useQuery(
    ["get_uploaded_bible_verse", month, year],
    async () => {
      const result = await privateRequestGet.get(
        `/bible/get-all-bible-verse?month=${month}&year=${year}`
      );

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_UPLOADED_BIBLE_VERSE", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_UPLOADED_BIBLE_VERSE_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampScriptureFund = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-scipture-fund-goal/${type}?gideon_year=${year}`
    : `/goals/view-scipture-fund-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_camp_set_goals_script", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_CAMP_SCRIPTURE_FUND_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampScriptureFundState = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-scipture-fund-goal/${type}?gideon_year=${year}`
    : `/goals/view-scipture-fund-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_camp_set_goals_state", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_STATE",
        payload: data,
      });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_STATE_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampScriptureFundRegion = (enabled, setEnabled, id, type, year) => {
  const dispatch = useDispatch();

  const end_point = year
    ? `/goals/view-scipture-fund-goal/${type}?gideon_year=${year}`
    : `/goals/view-scipture-fund-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_camp_set_goals_region", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_REGION",
        payload: data,
      });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_REGION_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampScriptureFundArea = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();

  const end_point = year
    ? `/goals/view-scipture-fund-goal/${type}?gideon_year=${year}`
    : `/goals/view-scipture-fund-goal/${type}`;

  const { data, error, refetch, isLoading } = useQuery(
    ["view_camp_set_goals_area_script", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_AREA",
        payload: data,
      });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_AREA_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampAdminFundArea = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-admin-fund-goal/${type}?gideon_year=${year}`
    : `/goals/view-admin-fund-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_camp_set_goals_area", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_ADMIN_FUND_GOAL_AREA",
        payload: data,
      });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_ADMIN_FUND_GOAL_AREA_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampAdminFundRegion = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-admin-fund-goal/${type}?gideon_year=${year}`
    : `/goals/view-admin-fund-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_camp_set_goals_region_admin", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_ADMIN_FUND_GOAL_REGION",
        payload: data,
      });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_ADMIN_FUND_GOAL_REGION_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampAdminFundState = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();

  const end_point = year
    ? `/goals/view-admin-fund-goal/${type}?gideon_year=${year}`
    : `/goals/view-admin-fund-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_camp_set_goals_state_Admin", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_ADMIN_FUND_GOAL_STATE",
        payload: data,
      });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_ADMIN_FUND_GOAL_STATE_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};

const useGetCampAdminFund = (enabled, setEnabled, id, type, year = null) => {
  const dispatch = useDispatch();

  const end_point = year
    ? `/goals/view-admin-fund-goal/${type}?gideon_year=${year}`
    : `/goals/view-admin-fund-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_camp_set_goals", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_CAMP_ADMIN_FUND_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_ADMIN_FUND_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampMemberGoal = (enabled, setEnabled, id, type, year = null) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-membership-goal/${type}?gideon_year=${year}`
    : `/goals/view-membership-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_camp_members_goal_memmm", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_CAMP_MEMBER_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_MEMBER_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampDistributionGoal = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-scripture-distribution-goal/${type}?gideon_year=${year}`
    : `/goals/view-scripture-distribution-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["_memmm", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_CAMP_DISTRIBUTION_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_CAMP_DISTRIBUTION_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampMemberGoalArea = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-membership-goal/${type}?gideon_year=${year}`
    : `/goals/view-membership-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_area_members_goal_mem", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_AREA_MEMBER_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_AREA_MEMBER_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampDistributionGoalArea = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-scripture-distribution-goal/${type}?gideon_year=${year}`
    : `/goals/view-scripture-distribution-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_area_members_goal", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_AREA_DISTRIBUTION_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_AREA_DISTRIBUTION_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampMemberGoalRegion = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-membership-goal/${type}?gideon_year=${year}`
    : `/goals/view-membership-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_region_members_goal_mem", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_REGION_MEMBER_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_REGION_MEMBER_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampDistributionGoalRegion = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-scripture-distribution-goal/${type}?gideon_year=${year}`
    : `/goals/view-scripture-distribution-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["_mem", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_REGION_DISTRIBUTION_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_REGION_DISTRIBUTION_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampMemberGoalState = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-membership-goal/${type}?gideon_year=${year}`
    : `/goals/view-membership-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_state_members_goal_mem", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_STATE_MEMBER_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_STATE_MEMBER_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};
const useGetCampDistributionGoalState = (
  enabled,
  setEnabled,
  id,
  type,
  year = null
) => {
  const dispatch = useDispatch();
  const end_point = year
    ? `/goals/view-scripture-distribution-goal/${type}?gideon_year=${year}`
    : `/goals/view-scripture-distribution-goal/${type}`;
  const { data, error, refetch, isLoading } = useQuery(
    ["view_state_members_goal_script", id, type, year],
    async () => {
      const result = await privateRequestGet.post(end_point, {
        levels: type,
        level_points: [id],
      });

      return result.data;
    },
    { enabled: enabled, manual: true, cacheTime: 100 }
  );

  useEffect(() => {
    if (isLoading === true) {
      dispatch({ type: "START_SPINNER_NOTIFICATION" });
    }
    if (data) {
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({ type: "GET_SET_STATE_DISTRIBUTION_GOAL", payload: data });
      setEnabled(false);
    }

    if (error) {
      const resMessage = error.response.data;
      dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
      dispatch({
        type: "GET_SET_STATE_DISTRIBUTION_GOAL_ERROR",
        payload: resMessage,
      });
      setEnabled(false);
    }
  }, [dispatch, isLoading, data, error, setEnabled]);

  return { data, refetch };
};

export {
  useGetSystemRoles,
  useGetSystemCamps,
  useGetSystemAreas,
  useGetSystemRegions,
  useGetSystemStates,
  useGetCampPendingNotifications,
  useGetCampSetGoal,
  useGetUploadedPrayerCalender,
  useGetUploadedBibleVerse,
  useGetGoalStatistics,
  useGetCampScriptureFund,
  useGetCampScriptureFundState,
  useGetCampScriptureFundRegion,
  useGetCampScriptureFundArea,
  useGetCampAdminFund,
  useGetCampAdminFundArea,
  useGetCampAdminFundRegion,
  useGetCampAdminFundState,
  useGetCampMemberGoal,
  useGetCampDistributionGoal,
  useGetCampMemberGoalArea,
  useGetCampDistributionGoalArea,
  useGetCampMemberGoalRegion,
  useGetCampDistributionGoalRegion,
  useGetCampMemberGoalState,
  useGetCampDistributionGoalState,
};
