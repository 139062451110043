import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createNewActivityCon,
  resetUsersState,
  ClearUserReport,
} from "../../../../store/actions/usersActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import classnames from "classnames";
import $ from "jquery";
// import MiniSpinner from "../../../helpers/MiniSpinner";
import {
  getAllSettings2,
  getAllStates,
  getOneCampActivityCon,
} from "../../../../store/actions/preferencesActions";

class ViewDetailsCon extends Component {
  state = {
    creds: {},
    states: null,
    report: {},
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD",
      }) ||
      !hasPermission({
        mod: "Personal Management",
        action: "REGISTER_FOR_CONVENTION",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    this.initDependency();
    this.props.getAllStates();
    this.props.getAllSettings2().then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.settings }, () => {
          this.setState({
            creds: {
              ...this.state.creds,
              // gideon_fee: this.state.gideon_convention_fee,
              // aux_fee: this.state.aux_convention_fee,
              // both_fee: this.state.both_convention_fee,
            },
          });
        });
      }
    });
    const id = this.props.match.params.id;
    this.props.getOneCampActivityCon(id).then((res) => {
      if (res.status === "success") {
        this.setState({
          creds: { ...this.state.creds, ...res.payload.convention },
        });
        $("#start_date").val(res.payload.convention.start_date);
        $("#end_date").val(res.payload.convention.end_date);
      }
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.mem_state_area !== prevState.mem_state_area ||
      nextProps.s_campFromArea !== prevState.s_campFromArea ||
      nextProps.mem_state_region !== prevState.mem_state_region ||
      nextProps.states !== prevState.states ||
      nextProps.mem_state !== prevState.mem_state ||
      nextProps.report !== prevState.report
    ) {
      return {
        s_campFromArea: nextProps.s_campFromArea,
        mem_state_area: nextProps.mem_state_area,
        mem_state_region: nextProps.mem_state_region,
        states: nextProps.states,
        mem_state: nextProps.mem_state,
        report: nextProps.report,
      };
    }
    return null;
  }
  componentWillUnmount() {
    this.props.resetUsersState();
    this.props.ClearUserReport();
  }
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  handleChange = (e) => {
    const { target } = e;
    this.setState(
      {
        creds: { ...this.state.creds, [e.target.id]: e.target.value },
      },
      () => {
        if (target.id === "type") {
          if (target.value === "national" || target.value === "international") {
            this.setState({ creds: { ...this.state.creds, state_id: "" } });
          }
        }
        if (target.id === "type" && target.value === "national") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.national_theme,
              number: this.state.national_number,
            },
          });
        }
        if (target.id === "type" && target.value === "international") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.international_theme,
              number: this.state.international_number,
            },
          });
        }
        if (target.id === "type" && target.value === "state") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.state_theme,
              number: this.state.state_number,
            },
          });
        }
        if (target.id === "type" && target.value === "") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: "",
              number: "",
            },
          });
        }
      }
    );

    // this.setState({ [e.target.id]: e.target.value });
  };
  //   handleSubmit = (e) => {
  //     e.preventDefault();
  //     const start_date = $("#start_date").val();
  //     const end_date = $("#end_date").val();
  //     this.setState(
  //       { creds: { ...this.state.creds, start_date, end_date } },
  //       () => {
  //         this.props.createNewActivityCon(this.state.creds).then((res) => {
  //           if (res.status === "success") {
  //             const creds = {};
  //             this.setState({ creds });
  //             $("#start_date").val("");
  //             $("#end_date").val("");
  //           }
  //         });
  //       }
  //     );
  //   };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  render() {
    const {
      spinner,
      message,
      status,
      spinner1,
      message1,
      status1,
    } = this.props;
    if (
      (!spinner && status !== "error") ||
      (!spinner1 && status1 !== "error")
    ) {
      this.initDependency();
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PERSONAL</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Details Convention</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Details Convention
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {message1 && status1 ? (
                <Message message={message1} status={status1} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              {spinner1 ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-3">
                      <label htmlFor="type">Type : &nbsp;</label>
                      {this.state.creds.type
                        ? this.state.creds.type.toUpperCase()
                        : ""}
                    </div>
                    {this.state.creds.type === "state" ? (
                      <div className=" col-sm-3">
                        <label>State(TGIN)</label>
                        <div className="d-flex">
                          <select
                            className={classnames({
                              "is-invalid": this.hasErrorFor("state_id"),
                              "form-control selected_area": true,
                            })}
                            onChange={this.handleChange}
                            id="state_id"
                            value={this.state.creds.state_id || ""}
                            readOnly
                          >
                            <option value="">--Select a State--</option>
                            {this.state.states &&
                              this.state.states.map((state, index) => (
                                <option key={index} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                          </select>
                          {/* {preference_spinner ? <MiniSpinner /> : null} */}
                        </div>
                        {this.renderErrorFor("state_id")}
                      </div>
                    ) : null}

                    <div className="form-group col-md-3">
                      <label htmlFor="theme">Convention Theme : &nbsp;</label>
                      {this.state.creds.theme || ""}
                    </div>

                    <div className="form-group col-md-3">
                      <label htmlFor="number">Convention Number : &nbsp;</label>
                      {this.state.creds.number || ""}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="gideon_fee">
                        Gideon Convention Fee :
                      </label>
                      &#8358;{" "}
                      {this.state.creds.gideon_fee &&
                        this.moneyConv(this.state.creds.gideon_fee)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="aux_fee">
                        Auxilliary Convention Fee : &#8358;
                      </label>
                      {this.state.creds.aux_fee &&
                        this.moneyConv(this.state.creds.aux_fee)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="both_fee">
                        Couples Convention Fee : &#8358;{" "}
                      </label>
                      {this.state.creds.both_fee &&
                        this.moneyConv(this.state.creds.both_fee)}
                    </div>

                    <div className="form-group col-md-3">
                      <label htmlFor="start_date">State Date : </label>
                      {this.state.creds.start_date || ""}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="end_date">End Date : </label>
                      {this.state.creds.end_date || ""}
                    </div>

                    <div className="form-group col-md-3">
                      <label htmlFor="address">Address: </label>
                      {this.state.creds.address || " "}
                    </div>

                    <div className="form-group col-md-3">
                      <label htmlFor="admin_fund">Admin Fund : &#8358;</label>
                      {this.state.creds.admin_fund &&
                        this.moneyConv(this.state.creds.admin_fund)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="scripture_fund">
                        Scripture Fund : &#8358;
                      </label>
                      {this.state.creds.scripture_fund &&
                        this.moneyConv(this.state.creds.scripture_fund)}
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="city">City : &nbsp;</label>

                      {this.state.creds.city || " "}
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group">
                        <label htmlFor="state">State : &nbsp;</label>
                        {this.state.creds.state || ""}
                      </div>
                    </div>
                    <div className="form-group col-md-3">
                      <label htmlFor="country">Country : &nbsp;</label>
                      {this.state.creds.country || " "}
                    </div>

                    {/* <div className="form-group col-md-3"> */}
                    {/* <label htmlFor="location">
                        No of prospects<span className="text-danger">*</span>
                      </label> */}
                    {/* <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("location"),
                          "form-control": true,
                        })}
                        id="location"
                        name="location"
                        value={this.state.location}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("location")}
                    </div> */}
                    {/* <div className="form-group col-md-3">
                      <label htmlFor="funding">
                        No of Sign-Up<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="funding"
                        name="funding"
                        value={this.state.funding}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("funding")}
                    </div> */}
                    {/* <div className="form-group col-md-3">
                      <label htmlFor="speaker">
                        Amount Collected<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="speaker"
                        name="speaker"
                        value={this.state.speaker}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("speaker")}
                    </div> */}
                  </div>

                  {this.state.report &&
                  Object.keys(this.state.report).length !== 0 ? (
                    <>
                      <hr />
                      <div className="row">
                        {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="type">
                          Type<span className="text-danger">*</span>
                        </label>
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("type"),
                            "form-control": true,
                          })}
                          id="type"
                          name="type"
                          onChange={this.handleChange}
                          value={this.state.report.type || ""}
                        >
                          {this.state.user && this.state.user.type === "G" ? (
                            <option value="G">Gideon</option>
                          ) : (
                            <option value="A">Auxilliary</option>
                          )}
                          <option value="C">Gideon & Auxilliary </option>
                        </select>
                        {this.renderErrorFor("type")}
                      </div>
                    </div> */}
                        <div className="form-group col-md-6">
                          <label htmlFor="actual_admin_offering">
                            Actual Admin Fund : &#8358;
                          </label>
                          {this.state.report.actual_admin_offering &&
                            this.moneyConv(
                              this.state.report.actual_admin_offering
                            )}
                        </div>
                        <div className="form-group col-md-6">
                          <label htmlFor="actual_scripture_offering">
                            Actual Scripture Fund:
                          </label>
                          &#8358;{" "}
                          {this.state.report.actual_scripture_offering &&
                            this.moneyConv(
                              this.state.report.actual_scripture_offering
                            )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <table className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th colSpan="2">Attendance</th>
                                <th>Kick-Off</th>
                                <th>Mid-Morning</th>
                                <th>Afternoon</th>
                                <th>Evening</th>
                                <th>Burden Brearers</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Friday</th>
                                <td>Gideon</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_gideon_kick_off"
                                      ),
                                    })}
                                    id="friday_gideon_kick_off"
                                    name="friday_gideon_kick_off"
                                    value={
                                      this.state.report
                                        .friday_gideon_kick_off || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_gideon_kick_off"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_gideon_mid_morning"
                                      ),
                                    })}
                                    id="friday_gideon_mid_morning"
                                    name="friday_gideon_mid_morning"
                                    value={
                                      this.state.report
                                        .friday_gideon_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_gideon_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_gideon_afternoon"
                                      ),
                                    })}
                                    id="friday_gideon_afternoon"
                                    name="friday_gideon_afternoon"
                                    value={
                                      this.state.report
                                        .friday_gideon_afternoon || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_gideon_afternoon"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_gideon_evening"
                                      ),
                                    })}
                                    id="friday_gideon_evening"
                                    name="friday_gideon_evening"
                                    value={
                                      this.state.report.friday_gideon_evening ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("friday_gideon_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_gideon_burden_bearers"
                                      ),
                                    })}
                                    id="friday_gideon_burden_bearers"
                                    name="friday_gideon_burden_bearers"
                                    value={
                                      this.state.report
                                        .friday_gideon_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_gideon_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Auxilliary</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_aux_kick_off"
                                      ),
                                    })}
                                    id="friday_aux_kick_off"
                                    name="friday_aux_kick_off"
                                    value={
                                      this.state.report.friday_aux_kick_off ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("friday_aux_kick_off")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_aux_mid_morning"
                                      ),
                                    })}
                                    id="friday_aux_mid_morning"
                                    name="friday_aux_mid_morning"
                                    value={
                                      this.state.report
                                        .friday_aux_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_aux_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_aux_afternoon"
                                      ),
                                    })}
                                    id="friday_aux_afternoon"
                                    name="friday_aux_afternoon"
                                    value={
                                      this.state.report.friday_aux_afternoon ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("friday_aux_afternoon")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_aux_evening"
                                      ),
                                    })}
                                    id="friday_aux_evening"
                                    name="friday_aux_evening"
                                    value={
                                      this.state.report.friday_aux_evening || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("friday_aux_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_aux_burden_bearers"
                                      ),
                                    })}
                                    id="friday_aux_burden_bearers"
                                    name="friday_aux_burden_bearers"
                                    value={
                                      this.state.report
                                        .friday_aux_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_aux_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Guest</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_guest_kick_off"
                                      ),
                                    })}
                                    id="friday_guest_kick_off"
                                    name="friday_guest_kick_off"
                                    value={
                                      this.state.report.friday_guest_kick_off ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("friday_guest_kick_off")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_guest_mid_morning"
                                      ),
                                    })}
                                    id="friday_guest_mid_morning"
                                    name="friday_guest_mid_morning"
                                    value={
                                      this.state.report
                                        .friday_guest_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_guest_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_guest_afternoon"
                                      ),
                                    })}
                                    id="friday_guest_afternoon"
                                    name="friday_guest_afternoon"
                                    value={
                                      this.state.report
                                        .friday_guest_afternoon || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_guest_afternoon"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_guest_evening"
                                      ),
                                    })}
                                    id="friday_guest_evening"
                                    name="friday_guest_evening"
                                    value={
                                      this.state.report.friday_guest_evening ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("friday_guest_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_guest_burden_bearers"
                                      ),
                                    })}
                                    id="friday_guest_burden_bearers"
                                    name="friday_guest_burden_bearers"
                                    value={
                                      this.state.report
                                        .friday_guest_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_guest_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Youth</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_youth_kick_off"
                                      ),
                                    })}
                                    id="friday_youth_kick_off"
                                    name="friday_youth_kick_off"
                                    value={
                                      this.state.report.friday_youth_kick_off ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("friday_youth_kick_off")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_youth_mid_morning"
                                      ),
                                    })}
                                    id="friday_youth_mid_morning"
                                    name="friday_youth_mid_morning"
                                    value={
                                      this.state.report
                                        .friday_youth_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_youth_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_youth_afternoon"
                                      ),
                                    })}
                                    id="friday_youth_afternoon"
                                    name="friday_youth_afternoon"
                                    value={
                                      this.state.report
                                        .friday_youth_afternoon || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_youth_afternoon"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_youth_evening"
                                      ),
                                    })}
                                    id="friday_youth_evening"
                                    name="friday_youth_evening"
                                    value={
                                      this.state.report.friday_youth_evening ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("friday_youth_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_youth_burden_bearers"
                                      ),
                                    })}
                                    id="friday_youth_burden_bearers"
                                    name="friday_youth_burden_bearers"
                                    value={
                                      this.state.report
                                        .friday_youth_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_youth_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Children</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_children_kick_off"
                                      ),
                                    })}
                                    id="friday_children_kick_off"
                                    name="friday_children_kick_off"
                                    value={
                                      this.state.report
                                        .friday_children_kick_off || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_children_kick_off"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_children_mid_morning"
                                      ),
                                    })}
                                    id="friday_children_mid_morning"
                                    name="friday_children_mid_morning"
                                    value={
                                      this.state.report
                                        .friday_children_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_children_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_children_afternoon"
                                      ),
                                    })}
                                    id="friday_children_afternoon"
                                    name="friday_children_afternoon"
                                    value={
                                      this.state.report
                                        .friday_children_afternoon || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_children_afternoon"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_children_evening"
                                      ),
                                    })}
                                    id="friday_children_evening"
                                    name="friday_children_evening"
                                    value={
                                      this.state.report
                                        .friday_children_evening || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_children_evening"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "friday_children_burden_bearers"
                                      ),
                                    })}
                                    id="friday_children_burden_bearers"
                                    name="friday_children_burden_bearers"
                                    value={
                                      this.state.report
                                        .friday_children_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "friday_children_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>Total Head Count (Friday):</th>
                                <th></th>
                                <td>
                                  {parseInt(
                                    this.state.report.friday_gideon_kick_off
                                  ) +
                                    parseInt(
                                      this.state.report.friday_aux_kick_off
                                    ) +
                                    parseInt(
                                      this.state.report.friday_guest_kick_off
                                    ) +
                                    parseInt(
                                      this.state.report.friday_youth_kick_off
                                    ) +
                                    parseInt(
                                      this.state.report.friday_children_kick_off
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report.friday_gideon_mid_morning
                                  ) +
                                    parseInt(
                                      this.state.report.friday_aux_mid_morning
                                    ) +
                                    parseInt(
                                      this.state.report.friday_guest_mid_morning
                                    ) +
                                    parseInt(
                                      this.state.report.friday_youth_mid_morning
                                    ) +
                                    parseInt(
                                      this.state.report
                                        .friday_children_mid_morning
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report.friday_gideon_afternoon
                                  ) +
                                    parseInt(
                                      this.state.report.friday_aux_afternoon
                                    ) +
                                    parseInt(
                                      this.state.report.friday_guest_afternoon
                                    ) +
                                    parseInt(
                                      this.state.report.friday_youth_afternoon
                                    ) +
                                    parseInt(
                                      this.state.report
                                        .friday_children_afternoon
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report.friday_gideon_evening
                                  ) +
                                    parseInt(
                                      this.state.report.friday_aux_evening
                                    ) +
                                    parseInt(
                                      this.state.report.friday_guest_evening
                                    ) +
                                    parseInt(
                                      this.state.report.friday_youth_evening
                                    ) +
                                    parseInt(
                                      this.state.report.friday_children_evening
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report
                                      .friday_gideon_burden_bearers
                                  ) +
                                    parseInt(
                                      this.state.report
                                        .friday_aux_burden_bearers
                                    ) +
                                    parseInt(
                                      this.state.report
                                        .friday_guest_burden_bearers
                                    ) +
                                    parseInt(
                                      this.state.report
                                        .friday_youth_burden_bearers
                                    ) +
                                    parseInt(
                                      this.state.report
                                        .friday_children_burden_bearers
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <th>Registered Delegates: </th>
                                <td></td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                              </tr>

                              <tr className="py-5 ">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <th>Saturday</th>
                                <td>Gideon</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_gideon_kick_off"
                                      ),
                                    })}
                                    id="satur_gideon_kick_off"
                                    name="satur_gideon_kick_off"
                                    value={
                                      this.state.report.satur_gideon_kick_off ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_gideon_kick_off")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_gideon_mid_morning"
                                      ),
                                    })}
                                    id="satur_gideon_mid_morning"
                                    name="satur_gideon_mid_morning"
                                    value={
                                      this.state.report
                                        .satur_gideon_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_gideon_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_gideon_afternoon"
                                      ),
                                    })}
                                    id="satur_gideon_afternoon"
                                    name="satur_gideon_afternoon"
                                    value={
                                      this.state.report
                                        .satur_gideon_afternoon || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_gideon_afternoon"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_gideon_evening"
                                      ),
                                    })}
                                    id="satur_gideon_evening"
                                    name="satur_gideon_evening"
                                    value={
                                      this.state.report.satur_gideon_evening ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_gideon_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_gideon_burden_bearers"
                                      ),
                                    })}
                                    id="satur_gideon_burden_bearers"
                                    name="satur_gideon_burden_bearers"
                                    value={
                                      this.state.report
                                        .satur_gideon_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_gideon_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Auxilliary</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_aux_kick_off"
                                      ),
                                    })}
                                    id="satur_aux_kick_off"
                                    name="satur_aux_kick_off"
                                    value={
                                      this.state.report.satur_aux_kick_off || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_aux_kick_off")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_aux_mid_morning"
                                      ),
                                    })}
                                    id="satur_aux_mid_morning"
                                    name="satur_aux_mid_morning"
                                    value={
                                      this.state.report.satur_aux_mid_morning ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_aux_mid_morning")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_aux_afternoon"
                                      ),
                                    })}
                                    id="satur_aux_afternoon"
                                    name="satur_aux_afternoon"
                                    value={
                                      this.state.report.satur_aux_afternoon ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_aux_afternoon")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_aux_evening"
                                      ),
                                    })}
                                    id="satur_aux_evening"
                                    name="satur_aux_evening"
                                    value={
                                      this.state.report.satur_aux_evening || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_aux_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_aux_burden_bearers"
                                      ),
                                    })}
                                    id="satur_aux_burden_bearers"
                                    name="satur_aux_burden_bearers"
                                    value={
                                      this.state.report
                                        .satur_aux_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_aux_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Guest</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_kick_off"
                                      ),
                                    })}
                                    id="satur_kick_off"
                                    name="satur_kick_off"
                                    value={
                                      this.state.report.satur_kick_off || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_kick_off")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_mid_morning"
                                      ),
                                    })}
                                    id="satur_mid_morning"
                                    name="satur_mid_morning"
                                    value={
                                      this.state.report.satur_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_mid_morning")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_afternoon"
                                      ),
                                    })}
                                    id="satur_afternoon"
                                    name="satur_afternoon"
                                    value={
                                      this.state.report.satur_afternoon || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_afternoon")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_evening"
                                      ),
                                    })}
                                    id="satur_evening"
                                    name="satur_evening"
                                    value={
                                      this.state.report.satur_evening || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_burden_bearers"
                                      ),
                                    })}
                                    id="satur_burden_bearers"
                                    name="satur_burden_bearers"
                                    value={
                                      this.state.report.satur_burden_bearers ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_burden_bearers")}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Youth</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_youth_kick_off"
                                      ),
                                    })}
                                    id="satur_youth_kick_off"
                                    name="satur_youth_kick_off"
                                    value={
                                      this.state.report.satur_youth_kick_off ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_youth_kick_off")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_youth_mid_morning"
                                      ),
                                    })}
                                    id="satur_youth_mid_morning"
                                    name="satur_youth_mid_morning"
                                    value={
                                      this.state.report
                                        .satur_youth_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_youth_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_youth_afternoon"
                                      ),
                                    })}
                                    id="satur_youth_afternoon"
                                    name="satur_youth_afternoon"
                                    value={
                                      this.state.report.satur_youth_afternoon ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_youth_afternoon")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_youth_evening"
                                      ),
                                    })}
                                    id="satur_youth_evening"
                                    name="satur_youth_evening"
                                    value={
                                      this.state.report.satur_youth_evening ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("satur_youth_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_youth_burden_bearers"
                                      ),
                                    })}
                                    id="satur_youth_burden_bearers"
                                    name="satur_youth_burden_bearers"
                                    value={
                                      this.state.report
                                        .satur_youth_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_youth_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Children</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_children_kick_off"
                                      ),
                                    })}
                                    id="satur_children_kick_off"
                                    name="satur_children_kick_off"
                                    value={
                                      this.state.report
                                        .satur_children_kick_off || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_children_kick_off"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_children_mid_morning"
                                      ),
                                    })}
                                    id="satur_children_mid_morning"
                                    name="satur_children_mid_morning"
                                    value={
                                      this.state.report
                                        .satur_children_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_children_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_children_afternoon"
                                      ),
                                    })}
                                    id="satur_children_afternoon"
                                    name="satur_children_afternoon"
                                    value={
                                      this.state.report
                                        .satur_children_afternoon || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_children_afternoon"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_children_evening"
                                      ),
                                    })}
                                    id="satur_children_evening"
                                    name="satur_children_evening"
                                    value={
                                      this.state.report
                                        .satur_children_evening || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_children_evening"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "satur_children_burden_bearers"
                                      ),
                                    })}
                                    id="satur_children_burden_bearers"
                                    name="satur_children_burden_bearers"
                                    value={
                                      this.state.report
                                        .satur_children_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "satur_children_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <th>Total Head Count (Saturday):</th>
                                <th></th>
                                <td>
                                  {parseInt(
                                    this.state.report.satur_gideon_kick_off
                                  ) +
                                    parseInt(
                                      this.state.report.satur_aux_kick_off
                                    ) +
                                    parseInt(this.state.report.satur_kick_off) +
                                    parseInt(
                                      this.state.report.satur_youth_kick_off
                                    ) +
                                    parseInt(
                                      this.state.report.satur_children_kick_off
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report.satur_gideon_mid_morning
                                  ) +
                                    parseInt(
                                      this.state.report.satur_aux_mid_morning
                                    ) +
                                    parseInt(
                                      this.state.report.satur_mid_morning
                                    ) +
                                    parseInt(
                                      this.state.report.satur_youth_mid_morning
                                    ) +
                                    parseInt(
                                      this.state.report
                                        .satur_children_mid_morning
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report.satur_gideon_afternoon
                                  ) +
                                    parseInt(
                                      this.state.report.satur_aux_afternoon
                                    ) +
                                    parseInt(
                                      this.state.report.satur_afternoon
                                    ) +
                                    parseInt(
                                      this.state.report.satur_youth_afternoon
                                    ) +
                                    parseInt(
                                      this.state.report.satur_children_afternoon
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report.satur_gideon_evening
                                  ) +
                                    parseInt(
                                      this.state.report.satur_aux_evening
                                    ) +
                                    parseInt(this.state.report.satur_evening) +
                                    parseInt(
                                      this.state.report.satur_youth_evening
                                    ) +
                                    parseInt(
                                      this.state.report.satur_children_evening
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report
                                      .satur_gideon_burden_bearers
                                  ) +
                                    parseInt(
                                      this.state.report.satur_aux_burden_bearers
                                    ) +
                                    parseInt(
                                      this.state.report.satur_burden_bearers
                                    ) +
                                    parseInt(
                                      this.state.report
                                        .satur_youth_burden_bearers
                                    ) +
                                    parseInt(
                                      this.state.report
                                        .satur_children_burden_bearers
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <th>Registered Delegates: </th>
                                <td></td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                              </tr>
                              <tr className="py-5 ">
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr>
                                <th>Sunday</th>
                                <td>Gideon</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_gideon_kick_off"
                                      ),
                                    })}
                                    id="sunday_gideon_kick_off"
                                    name="sunday_gideon_kick_off"
                                    value={
                                      this.state.report
                                        .sunday_gideon_kick_off || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "sunday_gideon_kick_off"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_gideon_mid_morning"
                                      ),
                                    })}
                                    id="sunday_gideon_mid_morning"
                                    name="sunday_gideon_mid_morning"
                                    value={
                                      this.state.report
                                        .sunday_gideon_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "sunday_gideon_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_gideon_afternoon"
                                      ),
                                    })}
                                    id="sunday_gideon_afternoon"
                                    name="sunday_gideon_afternoon"
                                    value={
                                      this.state.report
                                        .sunday_gideon_afternoon || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "sunday_gideon_afternoon"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_gideon_evening"
                                      ),
                                    })}
                                    id="sunday_gideon_evening"
                                    name="sunday_gideon_evening"
                                    value={
                                      this.state.report.sunday_gideon_evening ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("sunday_gideon_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_gideon_burden_bearers"
                                      ),
                                    })}
                                    id="sunday_gideon_burden_bearers"
                                    name="sunday_gideon_burden_bearers"
                                    value={
                                      this.state.report
                                        .sunday_gideon_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "sunday_gideon_burden_bearers"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td></td>
                                <td>Auxilliary</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_aux_kick_off"
                                      ),
                                    })}
                                    id="sunday_aux_kick_off"
                                    name="sunday_aux_kick_off"
                                    value={
                                      this.state.report.sunday_aux_kick_off ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("sunday_aux_kick_off")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_aux_mid_morning"
                                      ),
                                    })}
                                    id="sunday_aux_mid_morning"
                                    name="sunday_aux_mid_morning"
                                    value={
                                      this.state.report
                                        .sunday_aux_mid_morning || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "sunday_aux_mid_morning"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_aux_afternoon"
                                      ),
                                    })}
                                    id="sunday_aux_afternoon"
                                    name="sunday_aux_afternoon"
                                    value={
                                      this.state.report.sunday_aux_afternoon ||
                                      ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("sunday_aux_afternoon")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_aux_evening"
                                      ),
                                    })}
                                    id="sunday_aux_evening"
                                    name="sunday_aux_evening"
                                    value={
                                      this.state.report.sunday_aux_evening || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor("sunday_aux_evening")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "sunday_aux_burden_bearers"
                                      ),
                                    })}
                                    id="sunday_aux_burden_bearers"
                                    name="sunday_aux_burden_bearers"
                                    value={
                                      this.state.report
                                        .sunday_aux_burden_bearers || ""
                                    }
                                    onChange={this.handleChange}
                                  />
                                  {this.renderErrorFor(
                                    "sunday_aux_burden_bearers"
                                  )}
                                </td>
                              </tr>

                              <tr>
                                <th>Total Head Count (Sunday):</th>
                                <th></th>
                                <td>
                                  {parseInt(
                                    this.state.report.sunday_gideon_kick_off
                                  ) +
                                    parseInt(
                                      this.state.report.sunday_aux_kick_off
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report.sunday_gideon_mid_morning
                                  ) +
                                    parseInt(
                                      this.state.report.sunday_aux_mid_morning
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report.sunday_gideon_afternoon
                                  ) +
                                    parseInt(
                                      this.state.report.sunday_aux_afternoon
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report.sunday_gideon_evening
                                  ) +
                                    parseInt(
                                      this.state.report.sunday_aux_evening
                                    )}
                                </td>
                                <td>
                                  {parseInt(
                                    this.state.report
                                      .sunday_gideon_burden_bearers
                                  ) +
                                    parseInt(
                                      this.state.report
                                        .sunday_aux_burden_bearers
                                    )}
                                </td>
                              </tr>
                              <tr>
                                <th>Registered Delegates: </th>
                                <td></td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                                <td>
                                  {" "}
                                  {parseInt(this.state.report.registered_aux) +
                                    parseInt(
                                      this.state.report.registered_gideon
                                    ) +
                                    parseInt(
                                      this.state.report.registered_children
                                    )}
                                </td>
                              </tr>

                              {/* <tr>
                              <th colSpan="4">
                                Grand Total Attendance: &nbsp;
                                {this.state.report.sunday_gideon_kick_off +
                                  this.state.report.sunday_aux_kick_off +
                                  this.state.report.sunday_gideon_mid_morning +
                                  this.state.report.sunday_aux_mid_morning +
                                  this.state.report.sunday_gideon_afternoon +
                                  this.state.report.sunday_aux_afternoon +
                                  this.state.report.sunday_gideon_evening +
                                  this.state.report.sunday_aux_evening +
                                  this.state.report
                                    .sunday_gideon_burden_bearers +
                                  this.state.report.sunday_aux_burden_bearers +
                                  this.state.report.satur_gideon_kick_off +
                                  this.state.report.satur_aux_kick_off +
                                  this.state.report.satur_kick_off +
                                  this.state.report.satur_youth_kick_off +
                                  this.state.report.satur_children_kick_off +
                                  this.state.report.satur_gideon_mid_morning +
                                  this.state.report.satur_aux_mid_morning +
                                  this.state.report.satur_mid_morning +
                                  this.state.report.satur_youth_mid_morning +
                                  this.state.report.satur_children_mid_morning +
                                  this.state.report.satur_gideon_afternoon +
                                  this.state.report.satur_aux_afternoon +
                                  this.state.report.satur_afternoon +
                                  this.state.report.satur_youth_afternoon +
                                  this.state.report.satur_children_afternoon +
                                  this.state.report.satur_gideon_evening +
                                  this.state.report.satur_aux_evening +
                                  this.state.report.satur_evening +
                                  this.state.report.satur_youth_evening +
                                  this.state.report.satur_children_evening +
                                  this.state.report
                                    .satur_gideon_burden_bearers +
                                  this.state.report.satur_aux_burden_bearers +
                                  this.state.report.satur_burden_bearers +
                                  this.state.report.satur_youth_burden_bearers +
                                  this.state.report
                                    .satur_children_burden_bearers +
                                  this.state.report.friday_gideon_kick_off +
                                  this.state.report.friday_aux_kick_off +
                                  this.state.report.friday_guest_kick_off +
                                  this.state.report.friday_youth_kick_off +
                                  this.state.report.friday_children_kick_off +
                                  this.state.report.friday_gideon_mid_morning +
                                  this.state.report.friday_aux_mid_morning +
                                  this.state.report.friday_guest_mid_morning +
                                  this.state.report.friday_youth_mid_morning +
                                  this.state.report
                                    .friday_children_mid_morning +
                                  this.state.report.friday_gideon_afternoon +
                                  this.state.report.friday_aux_afternoon +
                                  this.state.report.friday_guest_afternoon +
                                  this.state.report.friday_youth_afternoon +
                                  this.state.report.friday_children_afternoon +
                                  this.state.report.friday_gideon_evening +
                                  this.state.report.friday_aux_evening +
                                  this.state.report.friday_guest_evening +
                                  this.state.report.friday_youth_evening +
                                  this.state.report.friday_children_evening +
                                  this.state.report
                                    .friday_gideon_burden_bearers +
                                  this.state.report.friday_aux_burden_bearers +
                                  this.state.report
                                    .friday_guest_burden_bearers +
                                  this.state.report
                                    .friday_youth_burden_bearers +
                                  this.state.report
                                    .friday_children_burden_bearers}
                              </th>
                              <th colSpan="3">
                                Total Registered: &nbsp;{" "}
                                {this.state.report.registered_aux +
                                  this.state.report.registered_gideon +
                                  this.state.report.registered_children}
                              </th>
                            </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {/* <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold mr-1"
                    disabled={spinner || spinner1 ? true : false}
                  >
                    Create
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button> */}

                  <Link
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                    to="#!"
                    onClick={this.goBack}
                  >
                    BACK
                  </Link>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    report: state.users.report,
    states1: state.preferences.states,
    spinner1: state.preferences.spinner,
    message1: state.preferences.message,
    status1: state.preferences.status,
    errors1: state.preferences.errors,
    states: state.preferences.states,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    createNewActivityCon: (creds) => createNewActivityCon(dispatch, creds),
    resetUsersState: () => resetUsersState(dispatch),
    ClearUserReport: () => ClearUserReport(dispatch),
    getAllSettings2: () => getAllSettings2(dispatch),
    getAllStates: () => dispatch(getAllStates()),
    getOneCampActivityCon: (id) => getOneCampActivityCon(dispatch, id),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewDetailsCon);
