import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../../helpers/functions/functions";
import { signout } from "../../../../store/actions/authActions";
import { connect } from "react-redux";

class PersonalAside extends Component {
  signout = (e) => {
    e.preventDefault();
    this.props.signout();
  };
  render() {
    const { handleActiveLink } = this.props;
    return (
      <React.Fragment>
        {hasPermission({
          mod: "Personal Management",
          action: "PERSONAL_DASHBOARD",
        }) ||
        hasPermission({
          mod: "Preferences",
          action: "UPDATE_MEMBER_RENEWAL",
        }) ? (
          <li className="nav-item has-treeview">
            <a href="#!" className="nav-link">
              <i className="nav-icon fas fa-chart-pie" />
              <p>
                PERSONAL
                <i className="right fas fa-angle-left" />
              </p>
            </a>

              <ul className="nav nav-treeview">

              {hasPermission({
                mod: "Personal Management",
                action: "PERSONAL_DASHBOARD",
              }) ? (
              <li className="nav-item">
                <Link onClick={handleActiveLink} to="/" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>Dashboard</p>
                </Link>
              </li>) : null}
              {hasPermission({
                mod: "Personal Management",
                action: "PERSONAL_DASHBOARD",
              }) ? (
              <li className="nav-item">
                <Link
                  onClick={handleActiveLink}
                  to="/store"
                  className="nav-link"
                >
                  <i className="fas fa-circle nav-icon" />
                  <p>Store</p>
                </Link>
              </li>) : null}
              {hasPermission({
                mod: "Personal Management",
                action: "PERSONAL_DASHBOARD",
              }) ? (
              <li className="nav-item">
                <Link
                  onClick={handleActiveLink}
                  to="/personal/change-password"
                  className="nav-link"
                >
                  <i className="fas fa-circle nav-icon" />
                  <p>Change Password</p>
                </Link>
              </li>) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "CREATE_RELOCATION_REQUEST",
              }) ? (
                <li className="nav-item">
                  <Link
                    onClick={handleActiveLink}
                    to="/personal/create-relocation-request"
                    className="nav-link"
                  >
                    <i className="fas fa-circle nav-icon" />
                    <p>Request Relocation</p>
                  </Link>
                </li>
              ) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "RECEIVE_MY_KIT",
              }) ? (
                <li className="nav-item">
                  <Link
                    onClick={handleActiveLink}
                    to="/personal/receive-my-kit"
                    className="nav-link"
                  >
                    <i className="fas fa-circle nav-icon" />
                    <p>Receive my Kit</p>
                  </Link>
                </li>
              ) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "PAY_CONTRIBUTIONS",
              }) ||
              hasPermission({
                mod: "Personal Management",
                action: "RENEW_MEMBERSHIP",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_MEMBER_RENEWAL",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Payments
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Personal Management",
                      action: "PAY_CONTRIBUTIONS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/payments/financial-contribution"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Financial Contribution</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_MEMBER_RENEWAL",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/payments/renewal"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Renewal</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Personal Management",
                      action: "PAY_CONTRIBUTIONS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/payments/payment-history"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> My Payment History</p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "VIEW_MY_CAMP_ACTIVITY",
              }) ||
              hasPermission({
                mod: "Personal Management",
                action: "CAMP_ACTIVITY_SCRIPTURE_REQUISITION",
              }) ||
              hasPermission({
                mod: "Personal Management",
                action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Scripture Activities
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Personal Management",
                      action: "VIEW_MY_CAMP_ACTIVITY",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/activities/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Activities</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Personal Management",
                      action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/personal/activities/report"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Report</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/personal/activities/report/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected Activity Report</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/personal/activities/report/authorized"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Authorized Activity Report</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}

                    {hasPermission({
                      mod: "Personal Management",
                      action: "CAMP_ACTIVITY_SCRIPTURE_REQUISITION",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/my-scriptures-request"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> My Scripture Requests </p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "VIEW_MY_CAMP_ACTIVITY",
              }) ||
              hasPermission({
                mod: "Personal Management",
                action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Camp Activities
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Personal Management",
                      action: "VIEW_MY_CAMP_ACTIVITY",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/activities/non-scripture/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Activities</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Personal Management",
                      action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/personal/activities/non-scripture/report"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Report</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "VIEW_MY_CAMP_ACTIVITY",
              }) ||
              hasPermission({
                mod: "Personal Management",
                action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Recruitment Activities
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Personal Management",
                      action: "VIEW_MY_CAMP_ACTIVITY",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/activities/recruitment/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Activities</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Personal Management",
                      action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/personal/activities/recruitment/report"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Report</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {hasPermission({
                mod: "Personal Management",
                action: "VIEW_MY_CAMP_ACTIVITY",
              }) ||
              hasPermission({
                mod: "Personal Management",
                action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Camp Meetings
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Personal Management",
                      action: "VIEW_MY_CAMP_ACTIVITY",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/activities/meetings/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Activities</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Personal Management",
                      action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/personal/activities/meetings/report"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Report</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "VIEW_MY_CAMP_ACTIVITY",
              }) ||
              hasPermission({
                mod: "Personal Management",
                action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Legacy/Bequest
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Personal Management",
                      action: "VIEW_MY_CAMP_ACTIVITY",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/activities/legacy/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Activities</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Personal Management",
                      action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/personal/activities/legacy/report"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Report</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "REGISTER_FOR_CONVENTION",
              }) ? (
                <li className="nav-item has-treeview">
                              {hasPermission({
                mod: "Personal Management",
                action: "PERSONAL_DASHBOARD",
              }) ? (
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Convention
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>) : null}
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Personal Management",
                      action: "VIEW_MY_CAMP_ACTIVITY",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/personal/activities/convention/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Activities</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Personal Management",
                      action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/personal/activities/convention/view-report"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> View Activity Report</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "REQUEST_MERCHANDISE",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Excos Merchandise Request
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/merchandise-request/create"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> New Request</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/merchandise-request/pending"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Pending Request</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/merchandise-request/authorized"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Authorized Request</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/merchandise-request/received"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Received Request</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/merchandise-request/returned"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Returned Request</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Personal Management",
                action: "REQUEST_MERCHANDISE",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Excos Transferred Request
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/merchandise-request/transferred/pending"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Pending Request</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/merchandise-request/transferred/authorized"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Authorized Request</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/merchandise-request/transferred/received"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Received Request</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/merchandise-request/transferred/returned"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Returned Request</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {hasPermission({
                mod: "Personal Management",
                action: "PERSONAL_DASHBOARD",
              }) ? (
              <li className="nav-item">
                <Link onClick={this.signout} to="#!" className="nav-link">
                  <i className="fas fa-circle nav-icon" />
                  <p>Signout</p>
                </Link>
              </li>) : null}
            </ul>
          </li>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    signout: () => dispatch(signout()),
  };
};

export default connect(null, mapDispatchToProps)(PersonalAside);
