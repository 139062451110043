import {
  BASE_URL,
  token,
  storage_type,
  user_auth_details,
  dehash,
} from "../../config";
import axios from "axios";
import { isEmpty } from "underscore";

export const resetPreferencesState = (dispatch) => {
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
};
export const clearPreferenceReport = (dispatch) => {
  dispatch({ type: "CLEAR_PREFRENCES_REPORT" });
};
export const setConvenReport = (dispatch, payload) => {
  // console.log(payload)
  dispatch({ type: "SET_CONVEN_REPORT", payload: payload });
};

export const getAllRoles = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/roles`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_ROLES", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_ROLES", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};

export const updateRole = (creds) => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "PUT",
      url: `${BASE_URL}/roles/${creds.id}`,
      headers: headers,
      data: creds,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "UPDATE_ROLE", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_UPDATE_ROLE", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};

export const createRole = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/roles`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });
  return result;
};

export const deleteRoleAction = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "DELETE",
      url: `${BASE_URL}/roles/${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "DELETE_ROLE", resMessage, id });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        // getAllRoles();
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        dispatch({ type: "ERRORS_DELETE_ROLE", resMessage });
      });
  };
};

export const createCamp = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_CAMPS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_CAMPS", resMessage });
      result = resMessage;
    });
  return result;
};
export const createHotel = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hotels`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_CAMPS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_CAMPS", resMessage });
      result = resMessage;
    });
  return result;
};

export const updateHotel = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/hotels/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_CAMPS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_CAMPS", resMessage });
      result = resMessage;
    });
  return result;
};
export const addHotelRoomType = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hotels/rooms`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_CAMPS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_CAMPS", resMessage });
      result = resMessage;
    });
  return result;
};
export const updateHotelRoomType = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/hotels/rooms/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_CAMPS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_CAMPS", resMessage });
      result = resMessage;
    });
  return result;
};

export const updateCamp = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/camps/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UPDATE_CAMP", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_CAMP", resMessage });
      result = resMessage;
    });
  return result;
};
export const setCampInventory = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camp/inventories/settings`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "SET_CAMP_INVENTORY_LIMIT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_SET_CAMP_INVENTORY_LIMIT", resMessage });
      result = resMessage;
    });
  return result;
};
export const detailAuthorizedTransferRequest = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/transfers/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_GET_SINGLE_TRANSFER_DETAILS", resMessage });
      result = resMessage;
    });
  return result;
};
export const newHqScriptureRequest = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/scripture/requests`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_HQ_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    });
  return result;
};
export const newHqMerchandiseRequest = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/merchandise/requests`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_HQ_MERCHANDISE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_HQ_MERCHANDISE_REQUEST", resMessage });
      result = resMessage;
    });
  return result;
};
export const newDepotScriptureRequest = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depot/scripture/requests`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_DEPOT_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_DEPOT_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    });
  return result;
};
export const verifyDepotCampRequest = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depot/scripture/requests/${id}/hq`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "VERIFY_CAMP_DEPOT_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_VERIFY_CAMP_DEPOT_REQUEST", resMessage });
      result = resMessage;
    });
  return result;
};

export const getPendingCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/scripture/requests/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_PENDING_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingMerchandiseRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_PENDING_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getTransferedCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/scripture/requests/transferred?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_TRANSFERRED_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getTransferedPersonalMerchandiseRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/transferred?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_TRANSFERRED_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingTransferRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/transfers/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_TRANSFER_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingCampDepotRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/hq/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_TRANSFER_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getVerifiedCampDepotRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/hq/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_TRANSFER_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedTransferRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/transfers/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_TRANSFER_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingDepotScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_PENDING_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getPendingDepotMerchandiseRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/pending?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_PENDING_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/scripture/requests/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedPersonalMerchandiseRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedDepotScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getAuthorizedDepotMerchandiseRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/authorised?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getReceivedCampScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/scripture/requests/received?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getReceivedPersonalMerchandiseRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/received?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getReceivedPersonalMerchandiseRequest_Re = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/returned?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getReceivedDepotScriptureRequest = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/received?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getReceivedDepotScriptureRequestTrans = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/received?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getReceivedDepotScriptureRequestTransRe = async (
  dispatch,
  page = "",
  year = "",
  month = "",
  day = ""
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/returned?page=${page}&year=${year}&month=${month}&day=${day}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const detailPendingScriptureCampRequest = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/scripture/requests/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_SINGLE_PENDING_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const detailPendingMerchandiseRequest = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/merchandise/requests/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_SINGLE_PENDING_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const detailPendingMerchandiseRequestTrans = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_SINGLE_PENDING_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const detailPendingScriptureDepotRequest = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_SINGLE_PENDING_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const detailPendingScriptureCampRequest1 = async (dispatch, id) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/scripture/requests/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_SINGLE_PENDING_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const detailPendingMerchandisePersonalRequest1 = async (
  dispatch,
  id
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/merchandise/requests/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_GET_SINGLE_PENDING_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const authDetailPendingScriptureCampRequest = async (
  dispatch,
  creds
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/hq/scripture/requests/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const authDetailPendingMerchandiseRequest = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/hq/merchandise/requests/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const authDetailPendingMerchandiseRequestReturn = async (
  dispatch,
  creds
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depot/merchandise/requests/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const authDetailPendingMerchandiseRequestHqReturn = async (
  dispatch,
  creds
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/merchandise/requests/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const authDetailPendingScriptureCampRequest1 = async (
  dispatch,
  creds
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/depot/scripture/requests/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const authDetailPendingMerchandisePersonalRequest1 = async (
  dispatch,
  creds
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/depot/merchandise/requests/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const recDetailPendingScriptureCampRequest = async (dispatch, creds) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/scripture/requests/${creds.id}`,
    headers: headers,
    data: { bibles: creds.bibles },
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "RECEIVED_CAMP_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_RECEIVED_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const recDetailPendingMerchandiseRequest = async (dispatch, creds) => {
  // alert("here");s
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/merchandise/requests/${creds.id}`,
    headers: headers,
    data: { bibles: creds.bibles },
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "RECEIVED_CAMP_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_RECEIVED_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const recDetailPendingScriptureDepotRequest = async (
  dispatch,
  creds
) => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depot/scripture/requests/${creds.id}`,
    headers: headers,
    data: { bibles: creds.bibles },
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "RECEIVED_CAMP_HQ_SCRIPTURE_REQUEST", resMessage });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({
        type: "ERRORS_RECEIVED_CAMP_HQ_SCRIPTURE_REQUEST",
        resMessage,
      });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const setHQInventory = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/inventories/settings`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "SET_HQ_INVENTORY_LIMIT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_SET_HQ_INVENTORY_LIMIT", resMessage });
      result = resMessage;
    });
  return result;
};
export const getDepotBibles = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depots/${id}/inventories`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "SET_HQ_INVENTORY_LIMIT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_GET_SINGLE_DEPOT_INVENTORY", resMessage });
      result = resMessage;
    });
  return result;
};
export const setDepotInventory = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depot/inventories/settings`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "SET_DEPOT_INVENTORY_LIMIT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_SET_DEPOT_INVENTORY_LIMIT", resMessage });
      result = resMessage;
    });
  return result;
};

/**-------------------- MACDONALD CODE ADDITIONS------------------------------
 * DATE: mua/
 *
 */
export const getAllCountries = () => {
  // alert("here");
  return (dispatch, getState) => {
    let countries = getState().preferences.countries;
    if (countries) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          country: countries,
        },
      };

      dispatch({ type: "SET_ALL_COUNTRIES", resMessage });
    } else {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/countries`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_COUNTRIES", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_STATES", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        });
    }
  };
};

/**-----------------------------------END---------------------------------- */

export const getAllCamps = () => {
  return (dispatch, getState) => {
    let camps = getState().preferences.camps;
    if (camps) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          camp: camps,
        },
      };

      dispatch({ type: "SET_ALL_CAMPS", resMessage });
    } else {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
      const headers = {
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/camps`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_CAMPS", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_CAMPS", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        });
    }
  };
};
export const getAllHotels = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/hotels`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_HOSTEL", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_CAMPS", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};
export const getAllHotelsAll = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/hotels?all=true`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_HOSTEL", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_CAMPS", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};

export const getAllAreas = () => {
  // alert("here");
  return (dispatch, getState) => {
    let areas = getState().preferences.areas;
    if (areas) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          area: areas,
        },
      };

      dispatch({ type: "SET_ALL_AREAS", resMessage });
    } else {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
      const headers = {
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/areas`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_AREAS", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_AREAS", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        });
    }
  };
};

export const getAllRegions = () => {
  // alert("here");
  return (dispatch, getState) => {
    let regions = getState().preferences.regions;
    if (regions) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          region: regions,
        },
      };

      dispatch({ type: "SET_ALL_REGIONS", resMessage });
    } else {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/regions`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_REGIONS", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_REGIONS", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        });
    }
  };
};

export const getAllStates = () => {
  // alert("here");
  return (dispatch, getState) => {
    let states = getState().preferences.states;
    if (states) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          states: states,
        },
      };

      dispatch({ type: "SET_ALL_STATES", resMessage });
    } else {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
      const headers = {
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/states`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_STATES", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_STATES", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        });
    }
  };
};

export const setAll = () => {
  const user = JSON.parse(dehash(`${storage_type.getItem(user_auth_details)}`));
  let type = false;
  if (user.type === "G" || user.type === "A") {
    type = true;
  }
  return (dispatch, getState) => {
    if (type) {
      dispatch({ type: "SET_USER", payload: user });
    }
    dispatch(getAllCamps());
    dispatch(getAllAreas());
    dispatch(getAllRegions());
    dispatch(getAllStates());
  };
};
export const selectCamp = (id) => {
  return (dispatch, getState) => {
    const camps = getState().preferences.camps;
    if (camps) {
      const selcamp = camps.filter(
        (item) => parseInt(item.area.id) === parseInt(id)
      );
      dispatch({ type: "SEL_CAMP_FROM_AREA", payload: selcamp });
    }
  };
};
export const selectRegion = (id) => {
  return (dispatch, getState) => {
    const regions = getState().preferences.regions;
    if (regions) {
      const selregion = regions.filter(
        (item) => parseInt(item.state.id) === parseInt(id)
      );
      dispatch({ type: "SEL_REGION_FROM_STATE", payload: selregion });
    }
  };
};
export const selectArea = (id) => {
  return (dispatch, getState) => {
    const areas = getState().preferences.areas;
    if (areas) {
      const selarea = areas.filter(
        (item) => parseInt(item.region.id) === parseInt(id)
      );
      dispatch({ type: "SEL_AREA_FROM_REGION", payload: selarea });
    }
  };
};
export const setAllCampFromArea = () => {
  return (dispatch, getState) => {
    dispatch(setAll());
    let campsFromArea = getState().preferences.campsFromArea;

    if (campsFromArea) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          campsFromArea,
        },
      };

      dispatch({ type: "SET_CAMPS_FROM_AREA", resMessage });
    } else {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      axios({
        method: "GET",
        url: `${BASE_URL}/areas/get-camps?area=${user.camp.area.id}`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_CAMPS_FROM_AREA", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_CAMPS_FROM_AREA", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        });
    }
  };
};
export const setAllAreaFromRegion = () => {
  return (dispatch, getState) => {
    dispatch(setAll());
    let areaFromRegion = getState().preferences.areaFromRegion;

    if (areaFromRegion) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          areaFromRegion,
        },
      };

      dispatch({ type: "SET_AREAS_FROM_REGION", resMessage });
    } else {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      axios({
        method: "GET",
        url: `${BASE_URL}/regions/get-areas?region=${user.camp.area.region.id}`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_AREAS_FROM_REGION", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_CAMPS_FROM_AREA", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        });
    }
  };
};
export const setAllRegionFromState = () => {
  return (dispatch, getState) => {
    dispatch(setAll());
    let regionFromState = getState().preferences.regionFromState;

    if (regionFromState) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          regionFromState,
        },
      };

      dispatch({ type: "SET_REGION_FROM_STATE", resMessage });
    } else {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      axios({
        method: "GET",
        url: `${BASE_URL}/states/get-regions?state=${user.camp.area.region.state.id}`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_REGION_FROM_STATE", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_CAMPS_FROM_AREA", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        });
    }
  };
};
export const getAllBibles = () => {
  return (dispatch, getState) => {
    let bibles = getState().preferences.bibles;

    if (bibles) {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      const resMessage = {
        status: "success",
        message: "",
        payload: {
          bibles,
        },
      };

      dispatch({ type: "SET_ALL_BIBLES", resMessage });
    } else {
      dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
      dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/bibles`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_ALL_BIBLES", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_ALL_BIBLES", resMessage });
          dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        });
    }
  };
};
export const getAllScriptureBibles = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/hq/scriptures`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_SCRIPTURE_BIBLES", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_SCRIPTURE_BIBLES", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};
export const getAllMerchandiseBibles = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/hq/merchandise`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_MERCHANDISE_BIBLES", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_MERCHANDISE_BIBLES", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};

//CODE MODIFICATIONS /ADDITION BY MACDONALD
//----------------------------------------------------------------------
export const getAllAssignedDepot = async (dispatch, creds) => {
  //return (dispatch, getState) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: !isEmpty(creds)
      ? `${BASE_URL}/assigned-depots?id=${creds}`
      : `${BASE_URL}/assigned-latest-depots`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      //dispatch({ type: "SET_ALL_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_ALL_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
  // };
};

export const getAllDepotInventoryLimit = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/depot/inventories`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_DEPOT_INVENTORY", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_DEPOT_INVENTORY", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};

export const getLatestAssignedDepots = async (dispatch) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/assigned-latest-depots`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });

  return result;
};

export const getAllDepotInventory = async (dispatch, creds) => {
  //return (dispatch, getState) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  axios({
    method: "GET",
    url: `${BASE_URL}/depots-inventories?id=${creds}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      //dispatch({ type: "SET_ALL_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_SET_ALL_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
  // };
};

//--------------------------------END------------------------------------------

export const getAllDepot = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/depots`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_DEPOT", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_D`EPOT", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};
export const getAllInventory = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/hq/inventories`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_HQ_INVENTORY", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_HQ_INVENTORY", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};
// export const getAllDepotInventory = (creds) => {
//   return (dispatch, getState) => {
//     dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
//     dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${storage_type.getItem(token)}`,
//     };
//     axios({
//       method: "GET",
//       url: `${BASE_URL}/depots-inventories?id=${creds}`,
//       headers: headers,
//     })
//       .then((res) => {
//         const resMessage = res.data;
//         dispatch({ type: "SET_ALL_DEPOT_INVENTORY", resMessage });
//         dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
//       })
//       .catch((err) => {
//         const resMessage = err.response.data;
//         dispatch({ type: "ERRORS_SET_ALL_DEPOT_INVENTORY", resMessage });
//         dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
//       });
//   };
// };
export const getOneDepoInventory = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/depots/${id}/inventories`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ONE_DEPOT_INVENTORY", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_DEPOT_INVENTORY", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};
export const getCampInventory = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/camp/inventories`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_CAMP_INVENTORY", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_CAMP_INVENTORY", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};

export const getOneCampActivityCon = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      // dispatch({ type: "ERRORS_GET_ONE_CAMP_ACTIVITY", resMessage });
      // dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_SET_ALL_CAMP_INVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });

  return result;
};
export const getCampActivityConReg = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/conventions/${id}/registrations`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_USERS_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      // dispatch({ type: "ERRORS_GET_ONE_CAMP_ACTIVITY", resMessage });
      // dispatch({ type: "UNLOAD_USERS_SPINNER" });
      dispatch({ type: "ERRORS_SET_ALL_CAMP_INVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });

  return result;
};

export const getHQInventory = async (dispatch, id, page = "") => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/hq/inventories/${id}${page}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_HQINVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getOneDepotInventory = async (dispatch, id, page = "") => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depot/inventories/${id}${page}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_DEPOTINVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};
export const getOneCampInventory = async (dispatch, id, page = "") => {
  // alert("here");
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camp/inventories/${id}${page}`,
    headers: headers,
  })
    .then((res) => {
      // console.log(res);
      const resMessage = res.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_CAMPINVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    });
  return result;
};

export const deleteCampAction = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "DELETE",
      url: `${BASE_URL}/camps/${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "DELETE_CAMPS", resMessage, id });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        // getAllRoles();
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        dispatch({ type: "ERRORS_DELETE_CAMPS", resMessage });
      });
  };
};
export const assignMemberRole = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/roles/${creds.roleId}/assign`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "ASSIGN_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_ASSIGN_ROLES", resMessage });
      result = resMessage;
    });
  return result;
};
export const transferRequestToDepot = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/scripture/requests/${creds.id}/transfer`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "TRANSFER_REQUEST_TO_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_TRANSFER_REQUEST_TO_DEPOT", resMessage });
      result = resMessage;
    });
  return result;
};
export const transferMerchandiseRequestToDepot = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/merchandise/requests/${creds.id}/transfer`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "TRANSFER_REQUEST_TO_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_TRANSFER_REQUEST_TO_DEPOT", resMessage });
      result = resMessage;
    });
  return result;
};
export const sendTransferRequest = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/transfers`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "TRANSFER_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_TRANSFER_REQUEST", resMessage });
      result = resMessage;
    });
  return result;
};
export const AuthorizeTransferRequest = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/transfers/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "AUTHORIZE_TRANSFER_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_AUTHORIZE_TRANSFER_REQUEST", resMessage });
      result = resMessage;
    });
  return result;
};
export const showSingleRequest = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/transfers/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "TRANSFER_REQUEST", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_TRANSFER_REQUEST", resMessage });
      result = resMessage;
    });
  return result;
};
export const assignMemberRole2 = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/roles/${creds.roleId}/assign`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      let resMessage = res.data;
      resMessage = {
        ...resMessage,
        message: "Member(s) removed successfully",
      };
      dispatch({ type: "ASSIGN_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_ASSIGN_ROLES", resMessage });
      result = resMessage;
    });
  return result;
};

//Code added by Macdonald
//--------------------------------------------------------
export const getCampAreasAndRegions = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/get-cars?regId=${creds.regionId}&aId=${creds.areaId}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_ROLE", resMessage });

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};
//----------------------------------END-------------------

export const oneRole = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/roles/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_ROLE", resMessage });

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};

export const getAllArea = async (dispatch) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/areas`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};
export const getAllRegion = async (dispatch) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/regions`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};
export const getAllState = async (dispatch) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/states`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};
export const getAllSettings2 = async (dispatch) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/settings`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_SET_ALL_SETTINGS", resMessage });
      result = resMessage;
    });

  return result;
};
export const getAllAreaCamp = async (dispatch, id) => {
  let result = {};
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/areas`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["areas"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["areas"] = resMessage;
    });
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["camp"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["camp"] = resMessage;
    });
  dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });

  return result;
};
export const createState = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/states`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_STATES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_STATES", resMessage });
      result = resMessage;
    });
  return result;
};
export const uploadVerses = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
    // console.log(key, value)
  });

  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/bible/upload/one-time-bible-verses`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_UPLOAD_BIBLE", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      console.log(err);
      console.log(err.response);
      console.log(resMessage);
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_UPLOAD_BIBLE", resMessage });
      result = resMessage;
    });
  return result;
};
export const uploadAdminFundFile = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
    // console.log(key, value)
  });

  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/goals/set-goal/excel`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_UPLOAD_BIBLE", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      console.log(err);
      console.log(err.response);
      console.log(resMessage);
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_UPLOAD_BIBLE", resMessage });
      result = resMessage;
    });
  return result;
};
export const uploadCalender = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
    // console.log(key, value)
  });

  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/bible/upload/daily-prayer-calendar`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_UPLOAD_BIBLE", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_UPLOAD_BIBLE", resMessage });
      result = resMessage;
    });
  return result;
};
export const uploadGideonFile = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
    // console.log(key, value)
  });

  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/gideons/excel`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_UPLOAD_BIBLE", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_UPLOAD_BIBLE", resMessage });
      result = resMessage;
    });
  return result;
};
export const uploadAuxiliaryFile = async (dispatch, creds) => {
  const fd = new FormData();
  // console.log(creds);
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
    // console.log(key, value)
  });

  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/auxilliaries/excel`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_UPLOAD_BIBLE", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_UPLOAD_BIBLE", resMessage });
      result = resMessage;
    });
  return result;
};

export const deleteStateAction = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "DELETE",
      url: `${BASE_URL}/states/${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "DELETE_STATES", resMessage, id });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        dispatch({ type: "ERRORS_DELETE_STATES", resMessage });
      });
  };
};
export const getOneState = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/states/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_STATE", resMessage });

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};
export const getOneBible = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/bibles/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_BIBLE", resMessage });

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};
export const getOneDepot = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/depots/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};

export const updateState = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/states/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UPDATE_STATE", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_STATE", resMessage });
      result = resMessage;
    });
  return result;
};

export const createRegion = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/regions`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_REGIONS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_REGIONS", resMessage });
      result = resMessage;
    });
  return result;
};

/**----------------------------------------------------------------
 * CODE ADDED BY MACDONALD
 *
 * DATE:
 *
 *--------------------------------------------------------------------*/
export const assignDepot = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depots-assign?id=${creds.depo_list}&userId=${creds.user_id}`, //?${[creds.depo_list].map((n, index) => `depo_list[${index}]=${n}`).join('&')}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "ASSIGN_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_ASSIGN_DEPOT", resMessage });
      result = resMessage;
    });
  return result;
};
/**----------------------------END--------------------------------- */

// export const assignDepot = async (dispatch, creds) => {
//   let result;
//   dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
//   dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${storage_type.getItem(token)}`,
//   };
//   await axios({
//     method: "POST",
//     url: `${BASE_URL}/depots/${creds.depot_id}/assign`,
//     headers: headers,
//     data: creds,
//   })
//     .then((res) => {
//       const resMessage = res.data;
//       dispatch({ type: "ASSIGN_DEPOT", resMessage });
//       dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
//       result = resMessage;
//     })
//     .catch((err) => {
//       const resMessage = err.response.data;
//       dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
//       dispatch({ type: "ERRORS_ASSIGN_DEPOT", resMessage });
//       result = resMessage;
//     });
//   return result;
// };

export const deallocateDepot = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "DELETE",
    url: `${BASE_URL}/depots/${creds.depot_id}/assign`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "ASSIGN_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_ASSIGN_DEPOT", resMessage });
      result = resMessage;
    });
  return result;
};
export const createHQInventory = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/inventories`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_HQ_INVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_HQ_INVENTORY", resMessage });
      result = resMessage;
    });
  return result;
};
export const createDepotInventory = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depot/inventories`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_DEPOT_INVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_DEPOT_INVENTORY", resMessage });
      result = resMessage;
    });
  return result;
};
export const createCampInventory = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camp/inventories`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_CAMP_INVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_CAMP_INVENTORY", resMessage });
      result = resMessage;
    });
  return result;
};
export const removeHQInventory = async (dispatch, creds) => {
  const fd = new FormData();
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });

  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/hq/inventories/delete`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "REMOVE_HQ_INVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_REMOVE_HQ_INVENTORY", resMessage });
      result = resMessage;
    });
  return result;
};
export const removeDepotInventory = async (dispatch, creds) => {
  const fd = new FormData();
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depot/inventories/delete`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "REMOVE_DEPOT_INVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_REMOVE_DEPOT_INVENTORY", resMessage });
      result = resMessage;
    });
  return result;
};
export const removeCampInventory = async (dispatch, creds) => {
  const fd = new FormData();
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camp/inventories/delete`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "REMOVE_CAMP_INVENTORY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_REMOVE_CAMP_INVENTORY", resMessage });
      result = resMessage;
    });
  return result;
};
export const createBible = async (dispatch, creds) => {
  const fd = new FormData();
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/bibles`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_BIBLE", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_BIBLE", resMessage });
      result = resMessage;
    });
  return result;
};
export const createDepots = async (dispatch, creds) => {
  const fd = new FormData();
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/depots`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_DEPOT", resMessage });
      result = resMessage;
    });
  return result;
};
export const updateDepots = async (dispatch, creds) => {
  // console.log(creds);
  // const fd = new FormData();
  // Object.entries(creds).forEach(([key, value]) => {
  //   fd.append(key, value);
  // });
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/depots/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UPDATE_DEPOT", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_DEPOT", resMessage });
      result = resMessage;
    });
  return result;
};

export const getStaffDepot = async (dispatch) => {
  let result = {};
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/staffs`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["staffs"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["staffs"] = resMessage;
    });
  await axios({
    method: "GET",
    url: `${BASE_URL}/depots`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["depots"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["depots"] = resMessage;
    });
  dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });

  return result;
};

//Code modification by Macdonald
//-----------------------------------------------------------
export const getAreasAndRegions = async (dispatch) => {
  let result = {};
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/regions`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["regions"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["regions"] = resMessage;
    });
  await axios({
    method: "GET",
    url: `${BASE_URL}/areas`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["areas"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["areas"] = resMessage;
    });
  dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });

  return result;
};

export const MoveSelectedCamps = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/camps-move?users=${creds}`, //?${[creds.depo_list].map((n, index) => `depo_list[${index}]=${n}`).join('&')}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      let resMessage = res.data;
      resMessage = {
        ...resMessage,
        message: "Camp(s) moved successfully",
      };
      dispatch({ type: "MOVE_CAMPS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_MOVE_CAMPS", resMessage });
      result = resMessage;
    });
  return result;
};

export const getLoggedInStaffDepot = async (dispatch) => {
  let result = {};
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/staffs`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["staffs"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["staffs"] = resMessage;
    });
  await axios({
    method: "GET",
    url: `${BASE_URL}/loggedin-staff-depots`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["depots"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["depots"] = resMessage;
    });
  dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });

  return result;
};
//--------------------------END------------------------------

export const updateBible = async (dispatch, creds) => {
  const fd = new FormData();
  Object.entries(creds).forEach(([key, value]) => {
    fd.append(key, value);
  });
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/bibles/${creds.id}`,
    headers: headers,
    data: fd,
  })
    .then((res) => {
      const resMessage = res.data;
      // resMessage.edited_id = creds.id;
      // console.log(resMessage);
      dispatch({ type: "UPDATE_BIBLE", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_BIBLE", resMessage });
      result = resMessage;
    });
  return result;
};

export const getAllSettings = () => {
  // alert("here");
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "GET",
      url: `${BASE_URL}/settings`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "SET_ALL_SETTINGS", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_SET_ALL_SETTINGS", resMessage });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      });
  };
};
export const deleteRegionAction = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "DELETE",
      url: `${BASE_URL}/regions/${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "DELETE_REGION", resMessage, id });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        dispatch({ type: "ERRORS_DELETE_REGION", resMessage });
      });
  };
};
export const updateRegion = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/regions/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UPDATE_REGION", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_REGION", resMessage });
      result = resMessage;
    });
  return result;
};
export const updateSettings = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/settings`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UPDATE_SETTINGS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_SETTINGS", resMessage });
      result = resMessage;
    });
  return result;
};
export const getOneRegion = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/regions/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_REGION", resMessage });

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};

export const getAllStatesRegion = async (dispatch, id) => {
  let result = {};
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/states`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["states"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["states"] = resMessage;
    });
  await axios({
    method: "GET",
    url: `${BASE_URL}/regions/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["region"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["region"] = resMessage;
    });
  dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });

  return result;
};

export const createArea = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/areas`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_AREAS", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_AREAS", resMessage });
      result = resMessage;
    });
  return result;
};

export const deleteAreaAction = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "DELETE",
      url: `${BASE_URL}/areas/${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "DELETE_AREA", resMessage, id });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        dispatch({ type: "ERRORS_DELETE_AREA", resMessage });
      });
  };
};

export const getAllRegionsArea = async (dispatch, id) => {
  let result = {};
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/regions`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["regions"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["regions"] = resMessage;
    });
  await axios({
    method: "GET",
    url: `${BASE_URL}/areas/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      result["area"] = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      result["area"] = resMessage;
    });
  dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });

  return result;
};
export const updateArea = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/areas/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UPDATE_AREA", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_AREA", resMessage });
      result = resMessage;
    });
  return result;
};
export const getOneCamp = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/camps/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_CAMP", resMessage });

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};
//--------------------START------------------------------
/** Modifications by Macdonald(mua/251220206)
 *  In response to customer request
 *  Ability to select from list of countries, states, areas etc when making relocation request/movement
 */

export const createCountry = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/countries`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "CREATE_COUNTRY", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_CREATE_COUNTRY", resMessage });
      result = resMessage;
    });
  return result;
};

export const getOneCountry = async (dispatch, id) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "GET",
    url: `${BASE_URL}/countries/${id}`,
    headers: headers,
  })
    .then((res) => {
      const resMessage = res.data;
      // dispatch({ type: "CREATE_ROLES", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      // console.log(err.response);
      const resMessage = err.response.data;
      dispatch({ type: "ERRORS_GET_ONE_COUNTRY", resMessage });

      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      // dispatch({ type: "ERRORS_CREATE_ROLES", resMessage });
      result = resMessage;
    });

  return result;
};

export const updateCountry = async (dispatch, creds) => {
  let result;
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
  dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
  const headers = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  };
  await axios({
    method: "PUT",
    url: `${BASE_URL}/countries/${creds.id}`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "UPDATE_STATE", resMessage });
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      dispatch({ type: "ERRORS_UPDATE_STATE", resMessage });
      result = resMessage;
    });
  return result;
};

export const deleteCountryAction = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
    dispatch({ type: "LOAD_PREFRENCES_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "DELETE",
      url: `${BASE_URL}/countries/${id}`,
      headers: headers,
    })
      .then((res) => {
        const resMessage = res.data;
        dispatch({ type: "DELETE_COUNTRIES", resMessage, id });
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "UNLOAD_PREFRENCES_SPINNER" });
        dispatch({ type: "ERRORS_DELETE_COUNTRIES", resMessage });
      });
  };
};

export const resetPreferencesCountry = (dispatch) => {
  dispatch({ type: "CLEAR_PREFRENCES_ERRORS" });
};

//--------------------END--------------------------------
