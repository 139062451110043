import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../hooks";
import MiniSpinner from "../../helpers/MiniSpinner";
import Message from "../../helpers/Message";
import Spinner from "../../helpers/Spinner";
import { resetNotificationState } from "../../../store/actions/notificationActions";
import { useDispatch, connect } from "react-redux";
import { hasPermission } from "../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../store/actions/authActions";
import $ from "jquery";

import {
  useGetCampSetGoal,
  useGetSystemCamps,
} from "../../../store/hookactions/notificationsActionhooks";

function TrackSetGoals(props) {
  const { spinner_pref } = props;
  const [enabled, setEnabled] = useState(true);
  const [enabled_goals, setEnabledGoals] = useState(false);
  const [from_date, setFromDate] = useState("");
  const [camp_id, setCampId] = useState("");

  useGetCampSetGoal(enabled_goals, setEnabledGoals, from_date, camp_id);
  useGetSystemCamps(enabled, setEnabled);

  const dispatch = useDispatch();
  const history = useHistory();

  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const errors = useShallowEqualSelector((state) => state.notifications.errors);

  const status = useShallowEqualSelector((state) => state.notifications.status);

  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );
  const goals = useShallowEqualSelector((state) => state.notifications.goals);
  const camps = useShallowEqualSelector((state) => state.preferences.camps);

  console.log({ goals, camps });
  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  useLayoutEffect(() => {
    initDependency();
  }, []);

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Goals",
        action: "TRACK_GOAL_REPORT",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();

    return () => {
      resetNotificationState(dispatch);
    };
  }, [dispatch, history]);

  function handleSubmit(e) {
    e.preventDefault();
    const from_date = $("#from_date").val();
    const id = $(".memberselect22").val();

    setCampId(id);
    setFromDate(from_date);
    setEnabledGoals(true);

    console.log({ id, from_date });
  }
  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>GOALS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Track Set Goals </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">Track Set Goals </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}

            <form onSubmit={handleSubmit}>
              <div className="row  ">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="from_date">
                      Date <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": hasErrorFor("from_date"),
                        "form-control": true,
                      })}
                      id="from_date"
                      name="from_date"
                      readOnly
                      placeholder="Select date"
                    />
                    {renderErrorFor("from_date")}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="camp_id">Camp</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": hasErrorFor("camp_id"),
                          "form-control": true,
                          memberselect22: true,
                        })}
                        placeholder="Select a Camp"
                        id="camp_id"
                        name="camp_id"
                        style={{
                          width: "100%",
                        }}
                      >
                        <option value="">--Select a camp--</option>
                        {camps &&
                          camps.map((camp, index) => (
                            <option key={camp.id} value={camp.id}>
                              {camp.name} -- {camp.area.name} --{" "}
                              {camp.area.region.name} --{" "}
                              {camp.area.region.state.name}
                            </option>
                          ))}
                      </select>
                      {spinner_pref ? <MiniSpinner /> : null}
                    </div>
                    {renderErrorFor("camp_id")}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  Generate
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
            </form>

            {Object.keys(goals).length ? (
              <div key="uniqueKey">
                <table
                  id="fund_table1"
                  className="table table-bordered"
                  style={{
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>CAMP</th>
                      <th>CAMP NO.</th>
                      <th>GOAL CATEGORY</th>
                      <th>STATUS</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ backgroundColor: "#eee" }}>
                      <th>SCRIPTURE FUND GOAL</th>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>{goals?.goal?.scripture_fund_goal?.camp || "--"}</th>
                      <th>
                        {goals?.goal?.scripture_fund_goal?.camp_no || "--"}
                      </th>
                      <td>CHURCH RECEIPTS</td>
                      <td>
                        {goals?.goal?.scripture_fund_goal.goal_category
                          .church_receipt || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>GIDEON CARD</td>
                      <td>
                        {goals?.goal?.scripture_fund_goal.goal_category
                          .gideon_receipt || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>AUXILIARY</td>
                      <td>
                        {goals?.goal?.scripture_fund_goal.goal_category
                          .auxiliary_receipt || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>OTHERS</td>
                      <td>
                        {goals?.goal?.scripture_fund_goal.goal_category
                          .other_scripture_fund_goals || "--"}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#eee" }}>
                      <th>ADMIN FUND GOAL</th>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>{goals?.goal?.admin_fund_goal.camp || "--"}</th>
                      <th>{goals?.goal?.admin_fund_goal.camp_no || "--"}</th>
                      <td>GIDEON DUES</td>
                      <td>
                        {goals?.goal?.admin_fund_goal.goal_category
                          .gideon_dues || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>AUXILIARY DUES</td>
                      <td>
                        {goals?.goal?.admin_fund_goal.goal_category
                          .auxiliary_dues || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>CONVENTION ADMIN</td>
                      <td>
                        {goals?.goal?.admin_fund_goal.goal_category
                          .convention_admin || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>OTHERS</td>
                      <td>
                        {goals?.goal?.admin_fund_goal.goal_category
                          .other_admin_fund_goals || "--"}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#eee" }}>
                      <th>SCRIPTURE DISTRIBUTION GOAL</th>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>
                        {goals?.goal?.scripture_distribution_goal.camp || "--"}
                      </th>
                      <th>
                        {goals?.goal?.scripture_distribution_goal.camp_no ||
                          "--"}
                      </th>
                      <td>Full Bible</td>
                      <td>
                        {goals?.goal?.scripture_distribution_goal.goal_category
                          .full_bible || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>College Testaments</td>
                      <td>
                        {goals?.goal?.scripture_distribution_goal.goal_category
                          .college_testaments || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>Medical Testaments</td>
                      <td>
                        {goals?.goal?.scripture_distribution_goal.goal_category
                          .medical_testaments || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>Hospital</td>
                      <td>
                        {goals?.goal?.scripture_distribution_goal.goal_category
                          .hospital || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>PWT</td>
                      <td>
                        {goals?.goal?.scripture_distribution_goal.goal_category
                          .pwt || "--"}
                      </td>
                    </tr>
                    <tr style={{ backgroundColor: "#eee" }}>
                      <th>MEMBERSHIP GOAL</th>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <th>{goals?.goal?.membership_goal.camp || "--"}</th>
                      <th>{goals?.goal?.membership_goal.camp_no || "--"}</th>
                      <td>Life Member</td>
                      <td>
                        {goals?.goal?.membership_goal.goal_category
                          .life_member || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>Annual Member</td>
                      <td>
                        {goals?.goal?.membership_goal.goal_category
                          .annual_member || "--"}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td>Deliquent</td>
                      <td>
                        {goals?.goal?.membership_goal.goal_category.deliquent ||
                          "--"}
                      </td>
                    </tr>
                  </tbody>
                </table>
                {initDependency()}
              </div>
            ) : null}
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    spinner_pref: state.preferences.spinner,
    message_pref: state.preferences.message,
    status_pref: state.preferences.status,
    errors_pref: state.preferences.errors,
  };
};

export default connect(mapStateToProps)(TrackSetGoals);
