import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  resetUsersState,
  getDetailReport,
  generateReportOne,
} from "../../../../store/actions/usersActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import MiniSpinner from "../../../helpers/MiniSpinner";
import $ from "jquery";
import uuid from "uuid";

// 10.10.1.227

class GenerateReport extends Component {
  state = {
    camp_inventory: [],
    bibles: [],
    new_gideon: "",
    new_aux: "",
    prospect_gideon: "",
    prospect_aux: " ",
  };
  componentDidMount() {
    const { setPermissionsErrors, getDetailReport } = this.props;
    if (
      !hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD",
      }) ||
      !hasPermission({
        mod: "Personal Management",
        action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    const id = this.props.match.params.id;
    getDetailReport(id).then((res) => {
      if (res.status === "success") {
        // console.log("dfdt", res);
        this.setState(
          {
            details: res.payload.details,
            ...res.payload.report,
          },
          () => {
            const bibles = [];
            this.state.details.forEach((item) => {
              let id = uuid.v1();
              bibles.push({
                id: id,
                key: id,
                bible_id: item.bible_id,
                name: item.name,
                supplied: item.supplied,
                distributed: 0,
                target_area: "",
                address: "",
                type: "addition",
              });
            });
            bibles.sort((a, b) =>
              a.bible_id > b.bible_id ? 1 : b.bible_id > a.bible_id ? -1 : 0
            );
            this.setState({ bibles }, () => {
              const Sum_bibles = {};
              this.state.bibles.forEach((item) => {
                //   let id = uuid.v1();
                let name = item.name;
                let distributed = item.distributed;
                let requested = item.requested;
                let supplied = item.supplied;
                Sum_bibles[name] = {
                  name: name,
                  requested,
                  supplied: parseInt(supplied),
                  distributed: Sum_bibles[name]
                    ? [
                        ...Sum_bibles[name]["distributed"],
                        parseInt(distributed),
                      ]
                    : [parseInt(distributed)],
                };
              });
              this.setState({ Sum_bibles });
            });
          }
        );
      }
    });
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    e.preventDefault();
    this.setState({ [e.target.id]: e.target.value });
  };
  handleChangeArea = (e) => {
    const id = e.target.dataset.id;
    // console.log(id);
    const bibles = this.state.bibles;
    // eslint-disable-next-line
    const bible = bibles.find((item) => item.id == id);
    // console.log(id, bible);
    bible.target_area = e.target.value;
    this.setState({ bibles });
  };
  handleChangeDistrubted = (e) => {
    e.preventDefault();
    const id = e.target.dataset.id;
    const bibles = this.state.bibles;
    // eslint-disable-next-line
    const bible = bibles.find((item) => item.id == id);
    // console.log(id, bible);
    bible.distributed = e.target.value;
    this.setState({ bibles }, () => {
      const Sum_bibles = {};
      this.state.bibles.forEach((item) => {
        //   let id = uuid.v1();
        let name = item.name;
        let distributed = item.distributed;
        let requested = item.requested;
        let supplied = item.supplied;
        Sum_bibles[name] = {
          name: name,
          requested,
          supplied: parseInt(supplied),
          distributed: Sum_bibles[name]
            ? [...Sum_bibles[name]["distributed"], parseInt(distributed)]
            : [parseInt(distributed)],
        };
      });
      this.setState({ Sum_bibles });
    });
  };
  handleChangeAddress = (e) => {
    e.preventDefault();
    const id = e.target.dataset.id;
    const bibles = this.state.bibles;
    // eslint-disable-next-line
    const bible = bibles.find((item) => item.id == id);
    // console.log(id, bible);
    bible.address = e.target.value;
    this.setState({ bibles });
  };
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const creds = {
      bibles: this.state.bibles,
      report_id: this.props.match.params.id,
      new_gideon: this.state.new_gideon,
      new_aux: this.state.new_aux,
      prospect_gideon: this.state.prospect_gideon,
      prospect_aux: this.state.prospect_aux,
    };

    this.props.generateReportOne(creds).then((res) => {
      if (res.status === "success") {
        this.setState({
          bibles: [],
        });
        $("#request-form")[0].reset();
        setTimeout(() => {
          this.props.history.push("/personal/activities/report");
        }, 1500);
      }
    });
  };
  getStatus = (status) => {
    if (status === "P") {
      return "PENDING";
    } else if (status === "U") {
      return "PENDING";
    } else if (status === "R") {
      return "REJECTED";
    } else if (status === "A") {
      return "AUTHORIZED";
    }
  };
  getBible = (id) => {
    // eslint-disable-next-line
    return this.state.bibles.find((item) => item.id == id);
  };
  addRowTest = (e) => {
    e.preventDefault();
    const bible = this.getBible(e.target.id);
    // console.log(e.target.tagName);
    // const bibles = this.state.bibles;
    if (bible.type === "addition") {
      const bible = this.getBible(e.target.id);
      const sum_arry = this.state.Sum_bibles[`${bible.name}`].distributed;
      const supply = this.state.Sum_bibles[`${bible.name}`].supplied;
      const sum = sum_arry.reduce((a, b) => a + b, 0);
      const diff = supply - sum;
      // console.log(supply, sum, diff, sum_arry);

      const bibles = this.state.bibles;
      const key = uuid.v1();
      bibles.push({
        id: key,
        key: key,
        bible_id: bible.bible_id,
        name: bible.name,
        supplied: bible.supplied,
        distributed: diff > 0 ? diff : 0,
        target_area: "",
        address: "",
        type: "subtration",
      });
      bibles.sort((a, b) =>
        a.bible_id > b.bible_id ? 1 : b.bible_id > a.bible_id ? -1 : 0
      );
      this.setState({ bibles }, () => {
        const Sum_bibles = {};
        this.state.bibles.forEach((item) => {
          //   let id = uuid.v1();
          let name = item.name;
          let distributed = item.distributed;
          let requested = item.requested;
          let supplied = item.supplied;
          Sum_bibles[name] = {
            name: name,
            requested,
            supplied: parseInt(supplied),
            distributed: Sum_bibles[name]
              ? [...Sum_bibles[name]["distributed"], parseInt(distributed)]
              : [parseInt(distributed)],
          };
        });
        this.setState({ Sum_bibles });
      });
    } else if (bible.type === "subtration") {
      const bible = this.getBible(e.target.id);
      // console.log(bible);
      const bibles = this.state.bibles;
      // eslint-disable-next-line
      const rem_bibles = bibles.find((item) => item.id == bible.id);

      rem_bibles.distributed = "";
      rem_bibles.target_area = "";
      rem_bibles.address = "";
      this.setState({ bibles }, () => {
        const new_bibles = bibles.filter((item) => item.id !== bible.id);
        this.setState({ bibles: new_bibles }, () => {
          const Sum_bibles = {};
          this.state.bibles.forEach((item) => {
            //   let id = uuid.v1();
            let name = item.name;
            let distributed = item.distributed;
            let requested = item.requested;
            let supplied = item.supplied;
            Sum_bibles[name] = {
              name: name,
              requested,
              supplied: parseInt(supplied),
              distributed: Sum_bibles[name]
                ? [...Sum_bibles[name]["distributed"], parseInt(distributed)]
                : [parseInt(distributed)],
            };
          });
          this.setState({ Sum_bibles });
        });
      });
    }
  };

  render() {
    const { spinner, status, message } = this.props;

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Personal</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Scripture Requisition Report
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Scripture Requisition Report (Scripture Distribution)
              </h3>
              <div className="card-tools">
                <Link to="/personal/activities/report">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-arrow-circle-left "></i> Back
                  </button>
                </Link>
              </div>
            </div>
            <form id="request-form">
              <div className="card-body">
                {/* {spinner ? <Spinner position="right" /> : null} */}
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}

                {spinner ? (
                  <div>
                    <Spinner />
                  </div>
                ) : null}
                <div
                  className="card"
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <div className="card-body ">
                    <div
                      className="row d-flex justify-content-between"
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <div>
                        <p className="font-weight-bold">
                          Activity Status:{" "}
                          <span className="font-italic">
                            {this.state.activity
                              ? this.state.activity.status === "P"
                                ? "PENDING"
                                : "AUTHORIZED"
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Activity Type:{" "}
                          <span>
                            {this.state.activity
                              ? this.state.activity.type
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Activity Description:{" "}
                          <span>
                            {this.state.activity
                              ? this.state.activity.description
                              : null}
                          </span>{" "}
                        </p>
                      </div>
                      <div>
                        <p className="font-weight-bold">
                          Report Status:{" "}
                          <span className="font-italic">
                            {this.state.status
                              ? this.getStatus(this.state.status)
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Authorized By:{" "}
                          <span className="font-italic">
                            {this.state.authorised_by
                              ? `${this.state.authorised_by.first_name} ${
                                  this.state.authorised_by.last_name
                                } ${
                                  this.state.authorised_by.other_name
                                    ? this.state.authorised_by.other_name
                                    : ""
                                }`
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Created Date:{" "}
                          <span className="font-italic">
                            {/* {this.moneyConv(this.state.stock)} */}
                            {this.state.created_at
                              ? this.dateConv(this.state.created_at)
                              : null}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4">
                        <p className="text-center font-weight-bold">
                          {" "}
                          Members Participation
                        </p>
                      </div>
                      {/* <div className="col-md-6 mx-auto d-flex"> */}
                      <div className="col-md-4">
                        Gideons:
                        <input
                          type="number"
                          min="0"
                          id="new_gideon"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-4">
                        Auxilliary:
                        <input
                          type="number"
                          min="0"
                          id="new_aux"
                          onChange={this.handleChange}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                    <div className="row">
                      <div className="col-md-4 ">
                        <p className="text-center font-weight-bold">
                          {" "}
                          Prospects/Observers
                        </p>
                      </div>
                      {/* <div className="col-md-6 mx-auto d-flex"> */}
                      <div className="col-md-4">
                        Gideons:
                        <input
                          type="number"
                          min="0"
                          id="prospect_gideon"
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md-4">
                        Auxilliary:
                        <input
                          type="number"
                          min="0"
                          id="prospect_aux"
                          onChange={this.handleChange}
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div key="uniqueKey" className="row t45">
                  <table
                    className="table table-hover table-bordered table-striped"
                    id="dynamic_field"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Scriptures</th>
                        <th>Supplied Quantity</th>
                        <th>Distributed Quantity</th>
                        <th>Target Area</th>
                        <th>Recipient's Address</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody id="try">
                      {this.state.bibles &&
                        this.state.bibles.map((item, index) => {
                          return (
                            <tr key={index} id={`row-${item.bible_id}`}>
                              <td>{item.name}</td>
                              <td>{item.supplied}</td>
                              <td>
                                <input
                                  onChange={this.handleChangeDistrubted}
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      `bibles.${index}.distributed`
                                    ),
                                  })}
                                  type="number"
                                  min="0"
                                  data-id={item.id}
                                  value={item.distributed}
                                />
                              </td>
                              <td>
                                <select
                                  onChange={this.handleChangeArea}
                                  data-id={item.id}
                                  value={item.target_area}
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      `bibles.${index}.target_area`
                                    ),
                                  })}
                                >
                                  <option>--Select--</option>
                                  <option value="School">School</option>
                                  <option value="Hospital">Hospital</option>
                                  <option value="Hotels">Hotels</option>
                                  <option value="Others">Others</option>
                                </select>
                              </td>
                              <td>
                                <textarea
                                  value={item.address}
                                  data-id={item.id}
                                  rows="2"
                                  onChange={this.handleChangeAddress}
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      `bibles.${index}.address`
                                    ),
                                  })}
                                ></textarea>
                              </td>
                              <td>
                                {" "}
                                <button
                                  type="button"
                                  className="btn btn-sm btn-default"
                                  data-type={item.type}
                                  data-id={item.bible_id}
                                  id={item.id}
                                  onClick={this.addRowTest}
                                >
                                  <i
                                    className={`fa fa-user-${
                                      item.type === "addition"
                                        ? "plus"
                                        : "minus"
                                    } mr-1 mt-1`}
                                    data-type={item.type}
                                    data-id={item.bible_id}
                                    id={item.id}
                                  ></i>
                                  {item.type === "addition" ? "Add" : "Remove"}
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <button
                    onClick={this.handleSubmit}
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                    disabled={spinner ? true : false}
                  >
                    Submit
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>
                </div>
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch),
    getDetailReport: (id) => getDetailReport(dispatch, id),
    generateReportOne: (creds) => generateReportOne(dispatch, creds),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GenerateReport);
