import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  getAllAreas,
  createCamp,
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import $ from "jquery";

class CreateCamps extends Component {
  state = {
    name: "",
    address: "",
    area_id: "",
    camp_no: "",
    areas: null,
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_CAMP" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      this.props.getAllAreas();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.areas !== prevState.areas) {
      return {
        areas: nextProps.areas,
      };
    }
    return null;
  }

  handleSelectArea = (e) => {
    this.setState({ area_id: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { createCamp } = this.props;
    const creds = {
      name: this.state.name,
      area_id: this.state.area_id,
      address: this.state.address,
      camp_no: this.state.camp_no,
    };
    createCamp(creds).then((res) => {
      if (res.status === "success") {
        this.setState({
          name: "",
          address: "",
          area_id: "",
        });
        $(".selected_area").val("");
      }
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Create Camp</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">Create Camp</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("name"),
                        "form-control": true,
                      })}
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("name")}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Area <span className="text-danger">*</span>
                    </label>
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("area_id"),
                        "form-control selected_area": true,
                      })}
                      placeholder="Select a Role"
                      onChange={this.handleSelectArea}
                      id="area_id"
                      defaultValue={this.state.area_id}
                    >
                      <option value="">--Select an Area--</option>
                      {this.state.areas &&
                        this.state.areas.map((area, index) => (
                          <option key={index} value={area.id}>
                            {area.name}--{area.region.name}--
                            {area.region.state.name}
                          </option>
                        ))}
                    </select>
                    {this.renderErrorFor("area_id")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="address">Camp Address</label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("address"),
                        "form-control": true,
                      })}
                      id="address"
                      name="address"
                      value={this.state.address}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("address")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="camp_no">
                      Camp Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("camp_no"),
                        "form-control": true,
                      })}
                      id="camp_no"
                      name="camp_no"
                      value={this.state.camp_no}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("camp_no")}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Add
                </button>
                {hasPermission({ mod: "Preferences", action: "VIEW_CAMP" }) ? (
                  <Link
                    to="/preferences/camps"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  >
                    View Camp
                  </Link>
                ) : null}
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    errors: state.preferences.errors,
    areas: state.preferences.areas,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllAreas: () => dispatch(getAllAreas()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    createCamp: (creds) => createCamp(dispatch, creds),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateCamps);
