import { BASE_URL, token, storage_type } from "../../config";
import axios from "axios";

export const addToCart = (id) => {
  const resMessage = { id };
  return (dispatch, getstate) => {
    dispatch({ type: "ADD_TO_CART", resMessage });
    dispatch({ type: "ADD_TOTALS" });
  };
  // return { type: "ADD_TO_CART", resMessage };
  // console.log("hello from add to cart.");
};
export const modalOpen = (id) => {
  const resMessage = { id };
  return { type: "OPEN_MODAL", resMessage };
  // console.log("hello from add to cart.");
};
export const modalClose = () => {
  return { type: "CLOSE_MODAL" };
};

export const increment = (id) => {
  const resMessage = { id };

  return (dispatch, getstate) => {
    dispatch({ type: "INCREMENT", resMessage });
    dispatch({ type: "ADD_TOTALS" });
  };
  // console.log("hello from add to cart.");
};
export const decrement = (id) => {
  const resMessage = { id };
  return (dispatch, getstate) => {
    const cart = getstate().tstore.cart;
    const tItem = cart.find((item) => item.id === id);
    if (tItem.count === 1) {
      dispatch({ type: "REMOVE_ITEM", resMessage });
      dispatch({ type: "ADD_TOTALS" });
    } else {
      dispatch({ type: "DECREMENT", resMessage });
      dispatch({ type: "ADD_TOTALS" });
    }
  };
  // console.log("hello from add to cart.");
};
export const removeItem = (id) => {
  const resMessage = { id };
  return (dispatch) => {
    dispatch({ type: "REMOVE_ITEM", resMessage });
    dispatch({ type: "ADD_TOTALS" });
  };

  // console.log("hello from add to cart.");
};
export const clearCart = () => {
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_CART" });
    dispatch({ type: "CLEAR_TSTORE_ERRORS" });
  };
};
export const showSuccessMessage = () => {
  const resMessage = {
    status: "success",
    message: "Transaction completed successfully",
  };
  return (dispatch, getState) => {
    dispatch({ type: "CLEAR_CART" });
    dispatch({ type: "CLEAR_TSTORE_ERRORS" });
    dispatch({ type: "SET_SUCCESS_MESSAGE_REMITA_CART", resMessage });
  };
};
export const checkOut = () => {
  return (dispatch, getState) => {
    const cart = getState().tstore.cart;
    const total = getState().tstore.cartTotal;
    const items = cart.map((item) => {
      return {
        bible_id: item.id,
        count: item.count,
        total: item.total,
        price: item.price,
      };
    });
    const creds = { items, total };
    dispatch({ type: "CLEAR_TSTORE_ERRORS" });
    dispatch({ type: "LOAD_TSTORE_SPINNER" });
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    axios({
      method: "POST",
      url: `${BASE_URL}/orders`,
      headers: headers,
      data: creds,
    })
      .then((res) => {
        let resMessage = res.data;
        dispatch({ type: "CHECKOUT_CART", resMessage });
        dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
        // dispatch({ type: "CLEAR_CART" });
      })
      .catch((err) => {
        const resMessage = err.response.data;
        dispatch({ type: "ERRORS_CHECKOUT_CART", resMessage });
        dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
      });
  };
};
// export const cancelCheckOut = () => {
//   return (dispatch, getState) => {
//     const rrr = getState().tstore.rrr;
//     dispatch({ type: "CLEAR_TSTORE_ERRORS" });
//     dispatch({ type: "LOAD_TSTORE_SPINNER" });
//     const headers = {
//       "Content-Type": "application/json",
//       Authorization: `Bearer ${storage_type.getItem(token)}`
//     };
//     axios({
//       method: "POST",
//       url: `${BASE_URL}/orders`,
//       headers: headers,
//       data: creds
//     })
//       .then(res => {
//         let resMessage = res.data;
//         dispatch({ type: "CHECKOUT_CART", resMessage });
//         dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
//         // dispatch({ type: "CLEAR_CART" });
//       })
//       .catch(err => {
//         const resMessage = err.response.data;
//         dispatch({ type: "ERRORS_CHECKOUT_CART", resMessage });
//         dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
//       });
//   };
// };

export const setDetailProduct = (id) => {
  return (dispatch, getState) => {
    const products = getState().tstore.products;
    if (products.length) {
      const resMessage = { id };
      dispatch({ type: "SET_DETAIL_PRODUCT", resMessage });
    } else {
      dispatch({ type: "CLEAR_TSTORE_ERRORS" });
      dispatch({ type: "LOAD_TSTORE_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/hq/merchandise`,
        headers: headers,
      })
        .then((res) => {
          let resMessage = res.data;
          dispatch({ type: "SET_PRODUCTS", resMessage });
          dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
          resMessage = { id };
          dispatch({ type: "SET_DETAIL_PRODUCT", resMessage });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_PRODUCTS", resMessage });
          dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
        });
    }
  };
};
export const force_cart_total = () => {
  return (dispatch, getState) => {
    const cart = getState().tstore.products;
    if (cart.length) {
      dispatch({ type: "ADD_TOTALS" });
    } else {
      dispatch({ type: "CLEAR_TSTORE_ERRORS" });
      dispatch({ type: "LOAD_TSTORE_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/hq/merchandise`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_PRODUCTS", resMessage });
          dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
          dispatch({ type: "ADD_TOTALS" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_PRODUCTS", resMessage });
          dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
        });
    }
  };
};
export const setProducts = () => {
  return (dispatch, getState) => {
    const cart = getState().tstore.products;
    if (cart.length) {
      const resMessage = { payload: { merchandise: cart } };
      dispatch({ type: "SET_PRODUCTS", resMessage });
    } else {
      dispatch({ type: "CLEAR_TSTORE_ERRORS" });
      dispatch({ type: "LOAD_TSTORE_SPINNER" });
      const headers = {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      };
      axios({
        method: "GET",
        url: `${BASE_URL}/hq/merchandise`,
        headers: headers,
      })
        .then((res) => {
          const resMessage = res.data;
          dispatch({ type: "SET_PRODUCTS", resMessage });
          dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
        })
        .catch((err) => {
          const resMessage = err.response.data;
          dispatch({ type: "ERRORS_SET_PRODUCTS", resMessage });
          dispatch({ type: "UNLOAD_TSTORE_SPINNER" });
        });
    }
  };

  // return { type: "SET_PRODUCTS" };
};
