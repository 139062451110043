import React, { Component } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import {
  resetPassword,
  resetAuthState
} from "../../../store/actions/authActions";
import Message from "../../helpers/Message";
import Spinner from "../../helpers/Spinner";
import classnames from "classnames";
import { Link } from "react-router-dom";
import "./../public.css";

class ResetPassword extends Component {
  state = {
    token: "",
    email: "",
    password: "",
    password_confirmation: ""
  };
  componentDidMount() {
    let params = queryString.parse(this.props.location.search);
    this.setState({
      token: params.token,
      email: params.email,
      login_id: params.login_id
    });
  }
  componentWillUnmount() {
    const { resetAuthState } = this.props;
    resetAuthState();
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    const { resetPassword } = this.props;
    resetPassword(this.state).then(result => {
      if (result.status === "success") {
        this.setState({
          token: "",
          email: "",
          password: "",
          password_confirmation: ""
        });
        setTimeout(() => {
          this.props.history.push("/login");
        }, 3000);
      }
    });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }

  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, message, status } = this.props;
    return (
      <div className="hold-transition login-page">
        <div className="login-box">
          <img
            src="/dist/img/logo-101.png"
            alt="logo"
            className="mx-auto d-block"
            width="100"
            height="100"
          />
          <div className="login-logo">
            <Link to="#!">
              {/* <b>The Gideons International Nigeria</b> */}
              <b>TGIN-MEMBERS</b>
            </Link>
            <br />
            <small>
              <i>...Over 100 Years of Changing Lives</i>
            </small>
          </div>
          {/* /.login-logo */}
          <div className="card">
            <div className="card-body login-card-body">
              {message ? <Message message={message} status={status} /> : null}
              {spinner ? <Spinner /> : null}
              <p className="login-box-msg">Reset Password</p>
              <form onSubmit={this.handleSubmit}>
                <div className="input-group mb-3">
                  <input
                    className={classnames({
                      "is-invalid": this.hasErrorFor("email"),
                      "form-control": true
                    })}
                    type="text"
                    placeholder="Email"
                    name="email"
                    id="email"
                    readOnly
                    autoComplete="off"
                    onChange={this.handleChange}
                    value={this.state.email}
                  />

                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-envelope" />
                    </div>
                  </div>
                  {this.renderErrorFor("email")}
                </div>
                <div className="input-group mb-3">
                  <input
                    className={classnames({
                      "is-invalid": this.hasErrorFor("password"),
                      "form-control": true
                    })}
                    type="password"
                    placeholder="New Password"
                    name="password"
                    id="password"
                    onChange={this.handleChange}
                    value={this.state.password}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                  {this.renderErrorFor("password")}
                <span class="text-danger text-right col-12">Minimum of 8 characters</span>
                </div>
                <div className="input-group mb-3">
                  <input
                    className={classnames({
                      "is-invalid": this.hasErrorFor("password_confirmation"),
                      "form-control": true
                    })}
                    type="password"
                    placeholder="New Password Confirmation"
                    name="password_confirmation"
                    id="password_confirmation"
                    onChange={this.handleChange}
                    value={this.state.password_confirmation}
                  />
                  <div className="input-group-append">
                    <div className="input-group-text">
                      <span className="fas fa-lock" />
                    </div>
                  </div>
                  {this.renderErrorFor("password_confirmation")}
                </div>

                <div className="row d-flex justify-content-between">
                  {/* /.col */}
                  <div className="col-4">
                    <button
                      type="submit"
                      className="btn btn-warning btn-block btn-flat"
                      disabled={spinner ? true : false}
                    >
                      Reset
                    </button>
                  </div>
                  {/* /.col */}
                  <p className="mb-1">
                    <Link to="/login">Cancel</Link>
                  </p>
                </div>
              </form>
              <div className="social-auth-links text-center mb-3" />
              {/* /.social-auth-links */}
            </div>
            {/* /.login-card-body */}
          </div>
        </div>
        {/* /.login-box */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    errors: state.auth.errors,
    spinner: state.auth.spinner,
    message: state.auth.message,
    status: state.auth.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    resetPassword: creds => resetPassword(dispatch, creds),
    resetAuthState: () => dispatch(resetAuthState())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);
