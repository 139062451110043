import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getCampInventory } from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";

class ViewCampInventory extends Component {
  state = {
    camp_inventory: null
  };
  componentDidMount() {
    const { setPermissionsErrors, getCampInventory } = this.props;
    if (
      !hasPermission({
        mod: "Camp Administration",
        action: "VIEW_CAMP_ADMINISTRATION"
      }) ||
      !hasPermission({
        mod: "Camp Administration",
        action: "VIEW_CAMP_INVENTORY"
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getCampInventory();
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.camp_inventory !== prevState.camp_inventory) {
      return {
        camp_inventory: nextProps.camp_inventory
      };
    }
    return null;
  }

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>CAMP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    View Camp Inventory
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                View Camp Inventory
              </h3>
              <div className="card-tools">
                <Link to="/camp-inventory/create">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle "></i>
                    ADD INVENTORY
                  </button>
                </Link>{" "}
                <Link to="/camp-inventory/remove">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-minus-circle mr-1"></i>
                    REMOVE INVENTORY
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <table
                    id="hq_inventory_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%"
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Inventory</th>
                        <th>Type</th>
                        <th>Quantity</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.camp_inventory &&
                        this.state.camp_inventory.map((inventory, index) => {
                          return (
                            <tr key={index}>
                              <td>{inventory.bible.name}</td>
                              <td>
                                {" "}
                                {inventory.bible.merchandise === 1
                                  ? "Cash Item"
                                  : "Non Cash Item"}
                              </td>
                              <td>{inventory.stock}</td>

                              <td>
                                <span
                                  style={{
                                    overflow: "visible",
                                    position: "relative",
                                    width: "110px"
                                  }}
                                >
                                  <Link
                                    to={`/camp-inventory/${inventory.id}`}
                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                    title="view inventory"
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Link>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    camp_inventory: state.preferences.camp_inventory,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getCampInventory: () => dispatch(getCampInventory()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewCampInventory);
