import {
  BASE_URL,
  user_auth_details,
  user_permission_details,
  system_all_permission,
  hash,
  token,
  storage_type,
} from "../../config";
import axios from "axios";

import {
  getAllSystemPermissions,
  getUserPermissionsDetails,
  getUserDetails,
} from "./../../components/helpers/functions/functions";

export const resetAuthState = () => {
  return (dispatch, getState) => dispatch({ type: "CLEAR_AUTH_ERRORS" });
};
export const resetAuthPermState = () => {
  return (dispatch, getState) => dispatch({ type: "CLEAR_AUTH_PERM_ERRORS" });
};
export const setPermissionsErrors = () => {
  const resMessage = {
    status: "error",
    message: "Access denied",
  };
  return (dispatch, getState) =>
    dispatch({ type: "SET_PERMISSIONS_ERRORS", resMessage });
};

export const signin = (creds, history) => {
  return (dispatch, getState) => {
    // console.log(getState());
    dispatch({ type: "CLEAR_AUTH_ERRORS" });
    dispatch({ type: "LOAD_SPINNER" });
    // alert("here");
    // return;
    const headers = {
      "Content-Type": "application/json",
    };
    axios({
      method: "POST",
      url: `${BASE_URL}/signin`,
      headers: headers,
      data: creds,
    })
      .then((res) => {
        storage_type.setItem(token, res.data.payload.access_token);
        storage_type.setItem(
          user_auth_details,
          hash(`${JSON.stringify(getUserDetails(res.data.payload.user))}`)
        );
        storage_type.setItem(
          system_all_permission,
          hash(
            `${JSON.stringify(
              getAllSystemPermissions(res.data.payload.permissions)
            )}`
          )
        );
        storage_type.setItem(
          user_permission_details,
          hash(
            `${JSON.stringify(
              getUserPermissionsDetails(res.data.payload.user.roles)
            )}`
          )
        );
        const { status, message } = res.data;
        const resMessage = {
          status,
          message,
        };
        dispatch({ type: "LOGIN_SUCCESS", resMessage });
        // console.log(history);
        // history.push("/");
        // temporary solution:: fix it later to use react router
        window.location = process.env.REACT_APP_LAUNCH_URL;
        // dispatch({ type: "UNLOAD_SPINNER" });
      })
      .catch((err) => {
        const resMessage = err.response.data;

        dispatch({ type: "UNLOAD_SPINNER" });
        dispatch({ type: "LOGIN_ERROR", resMessage });
        // console.log();
      });
  };
};

export const requestPasswordLink = async (dispatch, creds) => {
  // console.log(getState());
  dispatch({ type: "CLEAR_AUTH_ERRORS" });
  dispatch({ type: "LOAD_SPINNER" });
  let result;

  const headers = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/password/email`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "REQUEST_PASSWORD_LINK_SUCCESS", resMessage });
      dispatch({ type: "UNLOAD_SPINNER" });
      // console.log(resMessage);
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_SPINNER" });
      dispatch({ type: "ERROR_REQUEST_PASSWORD_LINK", resMessage });
      result = resMessage;
    });
  return result;
};
export const resetPassword = async (dispatch, creds) => {
  dispatch({ type: "CLEAR_AUTH_ERRORS" });
  dispatch({ type: "LOAD_SPINNER" });
  let result;

  const headers = {
    "Content-Type": "application/json",
  };
  await axios({
    method: "POST",
    url: `${BASE_URL}/password/reset`,
    headers: headers,
    data: creds,
  })
    .then((res) => {
      const resMessage = res.data;
      dispatch({ type: "RESET_PASSWORD_SUCCESS", resMessage });
      dispatch({ type: "UNLOAD_SPINNER" });
      result = resMessage;
    })
    .catch((err) => {
      const resMessage = err.response.data;
      dispatch({ type: "UNLOAD_SPINNER" });
      dispatch({ type: "ERROR_RESET_PASSWORD", resMessage });
      result = resMessage;
    });
  return result;
};
// export const clearError = () => {
//   return { type: "CLEAR_AUTH_ERRORS" };
// };
export const signout = () => {
  return (dispatch, getState) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    };
    dispatch({ type: "LOAD_SPINNER" });
    axios({
      method: "POST",
      url: `${BASE_URL}/signout`,
      headers: headers,
    }).then((res) => {
      storage_type.removeItem(token);
      storage_type.removeItem(user_permission_details);
      storage_type.removeItem(user_auth_details);
      storage_type.removeItem(system_all_permission);
      const { status, message } = res.data;
      const resMessage = {
        status,
        message,
      };
      dispatch({ type: "UNLOAD_SPINNER" });
      dispatch({ type: "LOGOUT_USER", resMessage });
    });
  };
};
