import {
  storage_type,
  dehash,
  user_permission_details,
  system_all_permission,
} from "./../../../config";
export const getAllSystemPermissions = (permissions) => {
  const new_obj = {};
  Object.keys(permissions).forEach((key) => {
    const arry = [];
    new_obj[key] = {};
    if (permissions[key].length > 0) {
      permissions[key].forEach((permission) => {
        const inner_obj = {
          id: permission.id,
          name: permission.name,
        };
        arry[permission.action] = inner_obj;
      });
      new_obj[key] = { ...arry };
    }
  });
  return new_obj;
};
export const getUserPermissionsDetails = (res_roles) => {
  let roles_obj;
  let permission_obj = {};
  const permission_arry = [];

  const arry_role = [];
  res_roles.forEach((role) => {
    arry_role[role.name] = { id: role.id, name: role.name };
    const auth_permissions = role.permissions;
    auth_permissions.forEach((permission) => {
      const inner_obj = {
        id: permission.id,
        name: permission.name,
      };
      permission_arry[permission.action] = inner_obj;
      permission_obj["all"] = { ...permission_arry };
    });
  });
  roles_obj = { ...arry_role };

  return {
    user_roles: roles_obj,
    user_permission: permission_obj,
  };
};
export const getUserDetails = (user) => {
  const newObj = {};
  Object.keys(user).forEach((key) => {
    if (key !== "roles") {
      newObj[key] = user[key];
    }
  });
  return newObj;
};
export const hasPermission = ({ mod, action }) => {
  if (
    !storage_type.getItem(user_permission_details) ||
    !storage_type.getItem(system_all_permission)
  ) {
    return false;
  }
  const user_permissions = JSON.parse(
    dehash(storage_type.getItem(user_permission_details))
  );
  // console.log(user_permissions);
  const system_permissions = JSON.parse(
    dehash(storage_type.getItem(system_all_permission))
  );
  // console.log(user_permissions);
  // console.log(system_permissions);
  if (!user_permissions.user_permission["all"]) {
    return false;
  }
  if (
    system_permissions[mod] &&
    user_permissions.user_permission["all"][action]
  ) {
    return true;
  }
  return false;
};
