import React from "react";
// import { Link } from "react-router-dom";
// import PayPalButton from "./PayPalButton";

export default function CartTotals({
  cartSubTotal,
  cartTax,
  cartTotal,
  clearCart,
  checkOut,
  history
}) {
  // const {cartSubTotal, cartTax, cartTotal, clearCart} = value;
  const moneyConv = price => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  return (
    <React.Fragment>
      <div className="container">
        <div className="row">
          <div className="col-10 mt-2 ml-sm-5 ml-md-auto col-sm-8 text-capitalize text-right">
            <button
              className="btn btn-outline-danger text-uppercase mb-3 px-5"
              type="button"
              onClick={() => clearCart()}
            >
              clear cart
            </button>{" "}
            <button
              className="btn btn-danger text-uppercase mb-3 px-5"
              type="button"
              onClick={() => checkOut()}
            >
              Check Out
            </button>
            <h5>
              <span className="text-title">subtotal:</span>
              <strong> &#8358;{moneyConv(cartSubTotal)}</strong>
            </h5>
            <h5>
              <span className="text-title">Charges:</span>
              <strong> &#8358;{moneyConv(cartTax)}</strong>
            </h5>
            <h5>
              <span className="text-title">total:</span>
              <strong> &#8358;{moneyConv(cartTotal)}</strong>
            </h5>
            {/* <PayPalButton total={cartTotal} clearCart={clearCart} history={history}/> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
