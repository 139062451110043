import React, { useEffect, Component } from "react";
//import { Component } from 'react';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllAssignedDepot } from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import axios from "axios";
import {
  BASE_URL,
  token,
  storage_type,
  user_auth_details,
  dehash,
  UserIdList,
} from "../../../../config";

//import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles,useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
// import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
// import LastPageIcon from '@material-ui/icons/LastPage';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function formatData(UserData,DepotData){
 var UsrData= [];
 var DepoData = [];
 var UserId=0;
  for (let index = 0; index < UserData.length; index++) {
    var user = UserData[index].title + "," + 
               UserData[index].first_name + "," +
               UserData[index].last_name + "," +
               UserData[index].created_at;
    UserId = UserData[index].id;
    UsrData[index]=user;
    for (let index2 = 0; index2 < DepotData.length; index2++) {
      //console.log(jungle.find(el => el.threat == 5)); // returns object - {name: "monkey", threat: 5}
      //var usrID = DepotData[index2].substring(DepotData[index2].lastIndexOf(",", DepotData[index2].lastIndexOf("]")));
      var usrID = DepotData[index2][DepotData[index2].length - 1]
      console.log("User ID: ", usrID);
      var depot = DepotData[index2].lastIndexOf(el => el.Userdepots[index2] == UserId);
      DepoData[index2] = depot;
    }
  }
  return {
    UsrData,
    Depots: [DepoData],
  };
}

function createData(name, calories, fat, carbs, protein, price) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      { date: '2020-01-05', customerId: '11091700', amount: 3 },
      { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
    ],
  };
}

function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.title}
        </TableCell>
        <TableCell align="right">{row.first_name}</TableCell>
        <TableCell align="right">{row.last_name}</TableCell>
        <TableCell align="right">{row.other_name}</TableCell>
        <TableCell align="right">{row.updated_at}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Assigned Depots
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Address</TableCell>
                    <TableCell align="right">Date Created</TableCell>
                    {/* <TableCell align="right">Total price ($)</TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.depots.map((historyRow) => (
                    <TableRow key={historyRow.name}>
                      <TableCell component="th" scope="row">
                        {historyRow.name}
                      </TableCell>
                      <TableCell>{historyRow.address}</TableCell>
                      <TableCell align="right">{historyRow.created_at}</TableCell>
                      {/* <TableCell align="right">
                        {Math.round(historyRow.amount * row.price * 100) / 100}
                      </TableCell> */}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      }),
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

var rows = [];

class ViewAssignedDepot extends Component {
  constructor(props) {  
        super(props)  
        this.state = {  
          Depots: [] ,
          Users: []
        }  
  }  
  componentDidMount() {  
      //axios.get((UserIdList.length > 0)? `${BASE_URL}/assigned-depots?id=${UserIdList}` : `${BASE_URL}/assigned-latest-depots`,
      axios.get(`${BASE_URL}/assigned-latest-depots`,
        {method:'GET',
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage_type.getItem(token)}`},
        }, 
        ).then(response => {
          this.setState({  
            Users: response.data.payload.userDepots,
            Depots: response.data.payload.userDepots.depots
          });  
        });
        //console.log("Users-Depots Assigned: ", response.data.payload.userDepots);  
          //console.log("Depots Assigned: ", response.data.payload.depots.Userdepots); 
          // rows = [
          //   formatData( response.data.payload.userDepots,response.data.payload.userDepots.depots)
          // ];
         
        //console.log("Formatted rows: ", rows);
  }  
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.depots !== prevState.depots) {
      return {
        depots: nextProps.depots,
      };
    }
    return null;
  }

  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  render() {
    console.log(this.state.Users);  
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">View Assigned Depots</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">View Assigned Depots</h3>
              {/* <div className="card-tools">
                <Link to="/preferences/depots/create">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle mr-1"></i>
                    ADD NEW DEPOT
                  </button>
                </Link>
              </div> */}
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) :null }
            {/* </div> */}
            <TableContainer component={Paper}>
              <Table aria-label="collapsible table">
                <TableHead>
                  <TableRow>
                    <TableCell />
                    <TableCell>Title</TableCell>
                    <TableCell align="right">First Name</TableCell>
                    <TableCell align="right">Last Name</TableCell>
                    <TableCell align="right">Other Names</TableCell>
                    <TableCell align="right">Date Assigned</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {this.state.Users.map((row) => (
                    <Row key={row.name} row={row} />
                  ))}

                  {/* {rows.map((row) => (
                    <Row key={row.name} row={row} />
                  ))} */}

                </TableBody>
              </Table>
            </TableContainer>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    depots: state.preferences.depots,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //getLatestAssignedDepots: (dispatch) => dispatch(getLatestAssignedDepots(dispatch)),
    getAllAssignedDepot: (creds) => getAllAssignedDepot(dispatch,creds),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewAssignedDepot);
