import {
  token,
  storage_type,
  user_permission_details,
  user_auth_details,
  system_all_permission,
} from "../../config";

import jwt_decode from "jwt-decode";
let impersonate = {};

const istoken = storage_type.getItem(token);
if (istoken) {
  impersonate = jwt_decode(istoken).impersonator || {};
}

const initState = {
  status: null,
  message: null,
  isAuth:
    storage_type.getItem(token) &&
    storage_type.getItem(user_permission_details) &&
    storage_type.getItem(user_auth_details) &&
    storage_type.getItem(system_all_permission)
      ? true
      : false,
  errors: [],
  spinner: false,
  status_perm: null,
  message_perm: null,
  impersonator: impersonate,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      // const { status, message } = action.error;
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "SET_PERMISSIONS_ERRORS":
      // const { status, message } = action.error;
      return {
        ...state,
        status_perm: action.resMessage.status,
        message_perm: action.resMessage.message,
      };
    case "CLEAR_AUTH_PERM_ERRORS":
      // const { status, message } = action.error;
      return {
        ...state,
        status_perm: null,
        message_perm: null,
      };
    case "LOAD_SPINNER":
      return {
        ...state,
        spinner: true,
      };
    case "UNLOAD_SPINNER":
      return {
        ...state,
        spinner: false,
      };
    case "CLEAR_AUTH_ERRORS":
      return {
        ...state,
        status: null,
        message: null,
        errors: [],
        spinner: false,
      };
    case "LOGOUT_USER":
      return {
        ...state,
        spinner: false,
        isAuth: false,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        isAuth: true,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "REQUEST_PASSWORD_LINK_SUCCESS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "RESET_PASSWORD_SUCCESS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERROR_REQUEST_PASSWORD_LINK":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERROR_RESET_PASSWORD":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    default:
      return state;
  }
};

export default authReducer;
