import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../hooks";
import MiniSpinner from "../../helpers/MiniSpinner";
import Message from "../../helpers/Message";
import Spinner from "../../helpers/Spinner";
import { resetNotificationState } from "../../../store/actions/notificationActions";
import { useDispatch, connect } from "react-redux";
import { hasPermission } from "../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../store/actions/authActions";
import $ from "jquery";

import {
  useGetGoalStatistics,
  useGetSystemAreas,
} from "../../../store/hookactions/notificationsActionhooks";
import { saveAs } from "file-saver";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

function AreaGoalStatistics(props) {
  const { spinner_pref } = props;
  const [enabled, setEnabled] = useState(true);
  const [enabled_goals, setEnabledGoals] = useState(false);
  const [from_date, setFromDate] = useState("");
  const [area_id, setAreaId] = useState("");
  const [bardata, setBarData] = useState({});
  const [filtered_areas, setFilterAreas] = useState([]);

  const { data } = useGetGoalStatistics(
    enabled_goals,
    setEnabledGoals,
    from_date,
    area_id,
    "area"
  );
  useGetSystemAreas(enabled, setEnabled);

  const dispatch = useDispatch();
  const history = useHistory();
  const areas = useShallowEqualSelector((state) => state.preferences.areas);

  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const errors = useShallowEqualSelector((state) => state.notifications.errors);

  const status = useShallowEqualSelector((state) => state.notifications.status);

  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );

  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Goals",
        action: "VIEW_AREA_PERFORMANCE_CHART_HQ",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();

    return () => {
      resetNotificationState(dispatch);
    };
  }, [dispatch, history]);

  useEffect(() => {
    const key = "name";
    const arrayUniqueByKey = [
      ...new Map(areas?.map((item) => [item[key], item])).values(),
    ];
    setFilterAreas(arrayUniqueByKey);
  }, [areas]);

  useEffect(() => {
    if (data) {
      const resMessage = data.payload;
      const actual = [];
      const proposed = [];
      let current_year = "";

      const admin_fund_proposed =
        resMessage?.current?.admin_fund_goal?.proposed?.total || 0;
      const admin_fund_actual =
        resMessage?.current?.admin_fund_goal?.actual?.total || 0;
      const scripture_fund_proposed =
        resMessage?.current?.scripture_fund_goal?.proposed?.total || 0;
      const scripture_fund_actual =
        resMessage?.current?.scripture_fund_goal?.actual?.total || 0;

      const financial_proposed = admin_fund_proposed + scripture_fund_proposed;
      const financial_actual = admin_fund_actual + scripture_fund_actual;
      actual.push(financial_actual);
      proposed.push(financial_proposed);
      const scripture_dist_proposed =
        resMessage?.current?.scripture_distribution_goal?.proposed?.total || 0;
      const scripture_dist_actual =
        resMessage?.current?.scripture_distribution_goal?.actual?.total || 0;
      actual.push(scripture_dist_actual);
      proposed.push(scripture_dist_proposed);

      const member_proposed =
        resMessage?.current?.membership_goal?.proposed?.total || 0;
      const member_actual =
        resMessage?.current?.membership_goal?.actual?.total || 0;
      actual.push(member_actual);
      proposed.push(member_proposed);
      current_year = resMessage.current.year;

      setBarData({ proposed, actual, current_year });
    }
  }, [data]);

  function handleSubmit(e) {
    e.preventDefault();
    const gideon_year = $("#only_year").val();
    const id = $(".memberselect22").val();

    setAreaId(parseInt(id));
    setFromDate(gideon_year);
    setEnabledGoals(true);
  }
  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  function saveCanvas2State() {
    //save to png
    const canvasSave = document.getElementById("stackPstate");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "current-gideon-year-state-goals.png");
    });
  }

  function moneyConv(price) {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>GOALS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  Area Goal Statistics{" "}
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              Area Goal Statistics{" "}
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}

            <form onSubmit={handleSubmit}>
              <div className="row  ">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="from_date">
                      Gideon Year <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": hasErrorFor("gideon_year"),
                        "form-control": true,
                      })}
                      id="only_year"
                      name="gideon_year"
                      readOnly
                      placeholder="Select date"
                    />
                    {renderErrorFor("gideon_year")}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="camp_id">Area</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": hasErrorFor("camp_id"),
                          "form-control": true,
                          memberselect22: true,
                        })}
                        placeholder="Select a Camp"
                        id="camp_id"
                        name="camp_id"
                        style={{
                          width: "100%",
                        }}
                      >
                        <option value="">--Select area--</option>
                        {areas &&
                          areas.map((area, index) => (
                            <option key={area.id} value={area.id}>
                              {area.name} -- {area.region.name} --{" "}
                              {area.region.state.name}
                            </option>
                          ))}
                      </select>
                      {spinner_pref ? <MiniSpinner /> : null}
                    </div>
                    {renderErrorFor("camp_id")}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  Generate
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
            </form>

            {Object.keys(bardata).length ? (
              <div class="row">
                {/* <div className="row"> */}
                <div className="col-md-6">
                  <div class="card text-left">
                    <div class="card-body mb-0">
                      <h6>AG (Annual Goal)</h6>
                      <p class="card-text d-flex justify-content-between mb-0">
                        <span>Financial: </span>
                        {bardata?.proposed && moneyConv(bardata?.proposed[0])}
                      </p>
                      <p class="card-text d-flex justify-content-between mb-0">
                        <span>Scripture: </span>
                        {bardata?.proposed && moneyConv(bardata?.proposed[1])}
                      </p>
                      <p class="card-text d-flex justify-content-between mb-0">
                        <span>Membership:</span>
                        {bardata?.proposed && moneyConv(bardata?.proposed[2])}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div class="card ">
                    <div class="card-body mb-0">
                      <h6>YTD (Year to Date)</h6>
                      <p class="card-text d-flex justify-content-between mb-0">
                        <span>Financial: </span>
                        {bardata?.actual && moneyConv(bardata?.actual[0])}
                      </p>
                      <p class="card-text d-flex justify-content-between mb-0">
                        <span>Scripture: </span>
                        {bardata?.actual && moneyConv(bardata?.actual[1])}
                      </p>
                      <p class="card-text d-flex justify-content-between mb-0">
                        <span>Membership:</span>
                        {bardata?.actual && moneyConv(bardata?.actual[2])}
                      </p>
                    </div>
                  </div>
                </div>
                {/* </div> */}
                <Link to="#" onClick={saveCanvas2State}>
                  Download as PNG
                </Link>
                <div className="col-md-12">
                  <h5 className="text-center d-block">
                    {bardata.current_year} Gideon Year
                  </h5>
                </div>
                <Bar
                  id="stackPstate"
                  data={{
                    labels: ["Financial", "Scripture", "Membership"],
                    datasets: [
                      {
                        label: "AG (Annual Goal)",
                        data: bardata?.proposed,
                        backgroundColor: "rgba(0,123,225,0.5)",
                        borderColor: "#007bff",
                        borderWidth: 1,
                      },
                      {
                        label: "YTD (Year to Date)",
                        data: bardata?.actual,
                        backgroundColor: "rgba(220,53,69,0.5)",
                        borderColor: "#dc3545",
                        borderWidth: 1,
                      },
                    ],
                  }}
                  width={600}
                  height={400}
                />
              </div>
            ) : null}
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    spinner_pref: state.preferences.spinner,
    message_pref: state.preferences.message,
    status_pref: state.preferences.status,
    errors_pref: state.preferences.errors,
  };
};

export default connect(mapStateToProps)(AreaGoalStatistics);
