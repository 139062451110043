import React, { Component } from "react";
import {
  storage_type,
  dehash,
  system_all_permission
} from "./../../../../config";
import Permission from "./Permission";
import {
  createRole,
  resetPreferencesState
} from "./../../../../store/actions/preferencesActions";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Message from "./../../../helpers/Message";
import Spinner from "./../../../helpers/Spinner";
import classnames from "classnames";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import MiniSpinner from "../../../helpers/MiniSpinner";

class CreateRolesPermissions extends Component {
  state = {
    all_permissions: null,
    name: "",
    default: false,
    permissions: []
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_ROLE" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      this.props.resetPreferencesState();
      const all_permissions = JSON.parse(
        dehash(storage_type.getItem(system_all_permission))
      );
      this.setState({ all_permissions });
    }
    // console.log(all_permissions);
  }
  handleSubmit = e => {
    e.preventDefault();
    const ids = [];
    document.querySelectorAll(".perm-role").forEach(checkbox => {
      if (checkbox.checked) {
        ids.push(parseInt(checkbox.value));
      }
    });
    this.setState({ permissions: ids }, () => this.getCreateRole());
  };
  getCreateRole = () => {
    const { createRole } = this.props;
    const creds = {
      name: this.state.name,
      permissions: this.state.permissions,
      default: this.state.default
    };
    createRole(creds).then(res => {
      if (res.status === "success") {
        this.setState({ name: "", default: false });
        document.querySelector("#default").checked = false;
        document
          .querySelectorAll('.permission input[type="checkbox"]')
          .forEach(checkbox => (checkbox.checked = false));
      }
    });
  };
  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handleDefaultChange = e => {
    this.setState({
      default: e.target.checked
    });
  };
  handleCheckChange = e => {
    const is_checked = e.target.checked;
    const permissions = document.querySelectorAll(`.${e.target.value}`);
    permissions.forEach(permission => (permission.checked = is_checked));
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { all_permissions } = this.state;
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Create Role</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Create Role</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("name"),
                        "form-control": true
                      })}
                      id="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("name")}
                  </div>
                  <div className="form-group col-md-6 pt-md-3">
                    <div className="custom-control custom-checkbox">
                      <input
                        className="custom-control-input"
                        type="checkbox"
                        name="default"
                        id="default"
                        onChange={this.handleDefaultChange}
                      />
                      <label htmlFor="default" className="custom-control-label">
                        Make Role Default
                      </label>
                    </div>
                    <div>
                      <i className="fa fa-info-circle text-info mr-1"></i>
                      Checking this field makes this role the default.
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <h5>Permissions</h5>
                </div>
                <div className="row">
                  <div className="col-md-12 accord" id="permissions">
                    {all_permissions &&
                      Object.keys(all_permissions).map((permission, index) => {
                        return (
                          <Permission
                            key={index}
                            index={index}
                            permission={permission}
                            all_permissions={all_permissions}
                            handleCheckChange={this.handleCheckChange}
                          />
                        );
                      })}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Add
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
                {hasPermission({ mod: "Preferences", action: "VIEW_ROLE" }) ? (
                  <Link
                    to="/preferences/roles-and-permissions"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  >
                    View Roles
                  </Link>
                ) : null}
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    message: state.preferences.message,
    spinner: state.preferences.spinner,
    errors: state.preferences.errors,
    status: state.preferences.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    createRole: creds => createRole(dispatch, creds),
    resetPreferencesState: () => resetPreferencesState(dispatch),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRolesPermissions);
