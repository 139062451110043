import { local_cart, storage_type, dehash, hash } from "../../config";
const initState = {
  status: null,
  message: null,
  errors: [],
  spinner: false,
  products: [],
  detailProduct: {},
  cart: storage_type.getItem(local_cart)
    ? JSON.parse(dehash(`${storage_type.getItem(local_cart)}`))
    : [],
  modalOpen2: false,
  modalProduct: {},
  cartSubTotal: 0,
  cartTax: 0,
  cartTotal: 0,
  merchantId: "",
  rrr: "",
  response_url: "",
  action_url: "",
  hash: "",
};

const tstoreReducer = (state = initState, action) => {
  const clearProducts = () => {
    return state.products.map((item) => {
      return {
        ...item,
        inCart: false,
        count: 0,
        total: 0,
      };
    });
  };
  const getItem = (id) => {
    // eslint-disable-next-line
    return state.products.find((item) => item.id == id);
  };
  const handleDetail = (id) => {
    return getItem(id);
  };
  // const increment = id => {
  //   console.log("this is increment method.");
  // };
  // const decrement = id => {
  //   console.log("this is decrement method.");
  // };
  // const removeItem = id => {
  //   console.log("item removed.");
  // };
  // const clearCart = id => {
  //   console.log("cart cleared.");
  // };
  // const openModal =id=>{
  //   const product
  // }
  // const addTotals = () => {
  //   let subTotal = 0;
  //   state.cart.map();
  // };

  switch (action.type) {
    case "CLEAR_TSTORE_ERRORS":
      return {
        ...state,
        errors: null,
        spinner: false,
        status: null,
        message: null,
      };
    case "LOAD_TSTORE_SPINNER":
      return {
        ...state,
        spinner: true,
      };
    case "UNLOAD_TSTORE_SPINNER":
      return {
        ...state,
        spinner: false,
      };
    case "SET_DETAIL_PRODUCT":
      let detailProduct = handleDetail(action.resMessage.id);
      // console.log(detailProduct);
      return {
        ...state,
        detailProduct,
      };
    case "INCREMENT":
      let tempCart2 = [...state.cart];
      const selectedProduct2 = tempCart2.find((item) => {
        return item.id === action.resMessage.id;
      });
      const index2 = tempCart2.indexOf(selectedProduct2);
      const product2 = tempCart2[index2];
      product2.count = product2.count + 1;
      product2.total = product2.count * product2.price;
      storage_type.setItem(
        local_cart,
        hash(`${JSON.stringify([...tempCart2])}`)
      );

      return {
        ...state,
        cart: [...tempCart2],
      };
    case "DECREMENT":
      let tempCart3 = [...state.cart];
      const selectedProduct3 = tempCart3.find(
        (item) => item.id === action.resMessage.id
      );

      const index3 = tempCart3.indexOf(selectedProduct3);
      const product3 = tempCart3[index3];
      product3.count = product3.count - 1;
      product3.total = product3.count * product3.price;
      storage_type.setItem(
        local_cart,
        hash(`${JSON.stringify([...tempCart3])}`)
      );
      return {
        ...state,
        cart: [...tempCart3],
      };
    case "REMOVE_ITEM":
      // console.log(`removing item ${action.resMessage.id}`);
      const id1 = action.resMessage.id;
      // console.log(id1);
      let tempProducts1 = [...state.products];
      // console.log(tempProducts1);
      let tempCart = [...state.cart];
      // console.log(tempCart);
      // eslint-disable-next-line
      const tempCart1 = tempCart.filter((item) => item.id !== id1);
      // console.log(tempCart1);

      const index1 = tempProducts1.indexOf(getItem(id1));
      let removedProduct = tempProducts1[index1];
      removedProduct.inCart = false;
      removedProduct.count = 0;
      removedProduct.total = 0;
      storage_type.setItem(
        local_cart,
        hash(`${JSON.stringify([...tempCart1])}`)
      );
      return {
        ...state,
        cart: [...tempCart1],
        products: [...tempProducts1],
      };
    case "CLEAR_CART":
      // console.log(`clear cart`);
      storage_type.setItem(local_cart, hash(`${JSON.stringify([])}`));
      return {
        ...state,
        cart: [],
        products: clearProducts(),
      };
    case "OPEN_MODAL":
      // console.log(detailProduct);
      return {
        ...state,
        modalProduct: getItem(action.resMessage.id),
        modalOpen2: true,
      };
    case "CLOSE_MODAL":
      return {
        ...state,
        modalProduct: {},
        modalOpen2: false,
      };
    case "SET_PRODUCTS":
      const resProduct = action.resMessage.payload.merchandise;
      const resProduct2 = resProduct.map((product) => {
        return (product = {
          ...product,
          price: parseInt(product.retail_price),
          title: product.name,
          img: product.image,
          info: product.description,
          inCart: false,
          count: 0,
          total: 0,
        });
      });
      // console.log("res", resProduct2);
      return {
        ...state,
        products: resProduct2,
        cartSubTotal: 0,
        cartTax: 0,
        cartTotal: 0,
      };
    case "ADD_TO_CART":
      // alert("here");
      let id = action.resMessage.id;
      let temProducts = [...state.products];
      const index = temProducts.indexOf(getItem(id));
      const product = temProducts[index];
      // console.log(state.cart);
      // const check_cart = JSON.parse(
      //   dehash(`${storage_type.getItem(local_cart)}`)
      // );
      const check_cart = state.cart;
      const check_cart_item = check_cart.find((item) => item.id === id);
      // console.log(check_cart_item);

      // if (check_cart_item) {
      //   product.inCart = true;
      //   product.count = 1;
      //   const price = product.price;
      //   product.total = price;
      // }
      if (check_cart_item) {
        product.inCart = true;
        product.count = check_cart_item.count;
        // const price = product.price;
        product.total = check_cart_item.total;
      } else {
        product.inCart = true;
        product.count = 1;
        const price = product.price;
        product.total = price;

        storage_type.setItem(
          local_cart,
          hash(`${JSON.stringify([...state.cart, product])}`)
        );
      }

      return {
        ...state,
        products: temProducts,
        cart: check_cart_item ? [...state.cart] : [...state.cart, product],
        detailProduct: product,
        // cartSubTotal: subTotal,
        // cartTax: tax,
        // cartTotal: total
      };

    // let subTotal = 0;
    // state.cart.map(item => (subTotal += item.total));
    // const tempTax = subTotal * 0.1;
    // const tax = parseFloat(tempTax.toFixed(2));
    // const total = subTotal + tax;

    case "ADD_TOTALS":
      let subTotal = 0;
      state.cart.map((item) => (subTotal += item.total));
      const tempTax = subTotal * 0.3;
      const tax = parseFloat(tempTax.toFixed(2));
      const total = subTotal + tax;
      return {
        ...state,
        cartSubTotal: subTotal,
        cartTax: tax,
        cartTotal: total,
      };
    case "CHECKOUT_CART":
      // console.log(action.resMessage);
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        ...action.resMessage.payload,
      };
    case "CANCEL_STORE_REMITAL":
      // console.log(action.resMessage);
      return {
        ...state,
        merchantId: "",
        rrr: "",
        response_url: "",
        action_url: "",
        hash: "",
      };
    case "SET_SUCCESS_MESSAGE_REMITA_CART":
      // const { status, message } = action.error;
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_CHECKOUT_CART":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_CANCEL_REMITA":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    default:
      return state;
  }
};

export default tstoreReducer;
