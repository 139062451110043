import React from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../../helpers/functions/functions";

function NotificationsAside(props) {
  const { handleActiveLink } = props;
  return (
    <React.Fragment>
      {hasPermission({
        mod: "Goals",
        action: "VIEW_GOALS",
      }) ? (
        <li className="nav-item has-treeview">
          <a href="#!" className="nav-link">
            <i className="nav-icon fas fa-chart-pie" />
            <p>
              GOALS
              <i className="right fas fa-angle-left" />
            </p>
          </a>

          {/* set camps goals */}

          <ul className="nav nav-treeview">
            {hasPermission({
              mod: "Goals",
              action: "TRACK_GOAL_REPORT",
            }) ? (
              <>
                <li className="nav-item">
                  <Link
                    onClick={handleActiveLink}
                    to="/goals/tracks/"
                    className="nav-link"
                  >
                    <i className="fas fa-circle nav-icon" />
                    <p>Track Set Goals</p>
                  </Link>
                </li>

                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Upload Camp Goals
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/upload/scripture-funds"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/upload/admin-funds"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/upload/scripture-distribution"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture Distribution </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/upload/members"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> MemberShip </p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "SET_GOAL",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Set Camp Goals
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/set/scripture-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/set/admin-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/set/scripture-distribution"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture distribution</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/set/membership"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Membership</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_CAMP_GOAL_REPORT",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      View Set Camp Goals
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/view/scripture-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/view/admin-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/view/scripture-distribution"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture distribution</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/view/membership"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Membership</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {/* change permission to VIEW_CAMP_GOAL_REPORT_HQ */}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_CAMP_GOAL_REPORT_HQ",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      View Set Camp Goals HQ
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/view/scripture-fund/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/view/admin-fund/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/view/scripture-distribution/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture distribution</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/camps/view/membership/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Membership</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_AREA_GOAL_REPORT",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      View Set Area Goals
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/areas/view/scripture-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/areas/view/admin-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/areas/view/scripture-distribution"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture distribution</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/areas/view/membership"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Membership</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_AREA_GOAL_REPORT_HQ",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      View Set Area Goals HQ
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/areas/view/scripture-fund/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/areas/view/admin-fund/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/areas/view/scripture-distribution/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture distribution</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/areas/view/membership/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Membership</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_REGION_GOAL_REPORT",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      View Set Region Goals
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/region/view/scripture-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/region/view/admin-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/region/view/scripture-distribution"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture distribution</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/region/view/membership"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Membership</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_REGION_GOAL_REPORT_HQ",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      View Set Region Goals HQ
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/region/view/scripture-fund/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/region/view/admin-fund/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/region/view/scripture-distribution/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture distribution</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/region/view/membership/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Membership</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_STATE_GOAL_REPORT",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      View Set State Goals
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/state/view/scripture-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/state/view/admin-fund"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/state/view/scripture-distribution"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture distribution</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/state/view/membership"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Membership</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_STATE_GOAL_REPORT_HQ",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      View Set State Goals HQ
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/state/view/scripture-fund/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture funds </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/state/view/admin-fund/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Admin funds</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/state/view/scripture-distribution/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Scripture distribution</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/goals/state/view/membership/hq"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Membership</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              </>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_STATE_PERFORMANCE_CHART_HQ",
            }) ||
            hasPermission({
              mod: "Goals",
              action: "VIEW_CAMP_PERFORMANCE_CHART_HQ",
            }) ||
            hasPermission({
              mod: "Goals",
              action: "VIEW_AREA_PERFORMANCE_CHART_HQ",
            }) ||
            hasPermission({
              mod: "Goals",
              action: "VIEW_REGION_PERFORMANCE_CHART_HQ",
            }) ? (
              <>
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Goals Statistics
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Goals",
                      action: "VIEW_CAMP_PERFORMANCE_CHART_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/goals/statistics/camp"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Camp Statistics </p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Goals",
                      action: "VIEW_AREA_PERFORMANCE_CHART_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/goals/statistics/area"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Area Statistics </p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Goals",
                      action: "VIEW_REGION_PERFORMANCE_CHART_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/goals/statistics/region"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Region Statistics </p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Goals",
                      action: "VIEW_STATE_PERFORMANCE_CHART_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/goals/statistics/state"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> State Statistics </p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              </>
            ) : null}
          </ul>

          {/* view camps goals */}
        </li>
      ) : null}
    </React.Fragment>
  );
}

export default NotificationsAside;
