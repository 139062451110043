import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getRegionScriptureReport,
  scrpRegionAreadetails,
  resetReportState,
  scrpRegionAreaCampdetails,
} from "./../../../../store/actions/reportActions";
import {
  setAll,
  getAllCamps,
  selectCamp,
  selectArea,
} from "../../../../store/actions/preferencesActions";

import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import classnames from "classnames";
import MiniSpinner from "./../../../helpers/MiniSpinner";
import $ from "jquery";
// import _ from "underscore";
// import { hasPermission } from "./../../helpers/functions/functions";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import { BASE_URL, token, storage_type } from "../../../../config";

class RegionScriptureReport extends Component {
  state = {
    // user_region: null,
    scrip_region: null,
    region_id: "",
    camp_id: "",
    area_id: "",
    scrip_region_camp: null,
    scrip_region_area: null,
    user_region: null,
    is_region: false,
    is_area: false,
    is_camp: false,
    // arranged_data: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REPORTS",
      }) ||
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REGION_SCRIPTURE_REPORT",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    document.title =
      "The Gideon International In Nigeria (Region Scripture Report)";
    this.initDependency();
    // const { getRegionScriptureReport } = this.props;
    // getRegionScriptureReport(1, "12/12/2020", "15/12/2020");
    // this.props.getAllCamps();
    // this.props.setAll();
    this.props.setAll();
    // this.props.getAllCamps();
    // this.props.getAllAreas();
  }
  initDependency = () => {
    // alert("here");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.scrip_region_camp !== prevState.scrip_region_camp ||
      nextProps.scrip_region_area !== prevState.scrip_region_area ||
      nextProps.user_region !== prevState.user_region ||
      nextProps.scrip_region !== prevState.scrip_region
    ) {
      return {
        scrip_region_camp: nextProps.scrip_region_camp,
        scrip_region_area: nextProps.scrip_region_area,
        user_region: nextProps.user_region,
        scrip_region: nextProps.scrip_region,
      };
    }
    return null;
  }
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  handleDownload = (e) => {
    e.preventDefault();
    const from = $("#from_date").val();
    const to = $("#to_date").val();
    const region = this.state.region_id;
    const tok = storage_type.getItem(token);
    const download = 1;
    const url = `${BASE_URL}/reports/user_region/funds?from=${from}&to=${to}&region=${region}&download=${download}&token=${tok}`;
    window.open(url, "_blank");
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const from_date = $("#from_date").val();
    const to_date = $("#to_date").val();
    // const id = $(".memberselect22").val();
    const region = this.state.region_id;
    const area = this.state.area_id;
    const camp = this.state.camp_id;
    document.title = `The Gideon International In Nigeria (Region Scripture Report ${from_date}---${to_date})`;
    // alert(area);
    this.setState(
      {
        from_date,
        to_date,
        is_region: true,
        is_area: false,
        is_camp: false,
        // id
      },
      () => {
        this.props.getRegionScriptureReport(
          from_date,
          to_date,
          region,
          area,
          camp
        );
      }
    );
  };

  // arrangeReport = () => {};
  // updateState = arry => {
  //   const dd = arry;
  //   setTimeout(() => {
  //     this.setState({ new_arry: dd });
  //   }, 1000);
  // };
  setDownload = (e) => {
    e.preventDefault();
    $(".fund_table").attr("id", "role_table22");
    this.initDependency();
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  scrpRegionAreadetails = (e, bible) => {
    e.preventDefault();
    this.setState({
      is_region: false,
      is_area: true,
      is_camp: false,
      bible: bible,
    });
    const { region_id, from_date, to_date } = this.state;
    this.props.scrpRegionAreadetails(region_id, bible, from_date, to_date);
  };
  scrpRegionAreaCampdetails = (e, area) => {
    e.preventDefault();
    this.setState(
      {
        is_region: false,
        is_area: false,
        is_camp: true,
        area_id: area,
      },
      () => {
        const { region_id, area_id, bible, from_date, to_date } = this.state;
        this.props.scrpRegionAreaCampdetails(
          region_id,
          area_id,
          bible,
          from_date,
          to_date
        );
      }
    );
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  dateConv = (date) => {
    return date ? new Date(date).toDateString() : null;
  };
  componentWillUnmount() {
    this.props.resetReportState();
  }

  render() {
    const { spinner, message, status, preference_spinner } = this.props;

    if (!spinner && status !== "error") {
      this.initDependency();
    }
    // if (this.state.scrip_region) {
    //   this.arrangeReport();
    // }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>REPORT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Scripture Report HQ
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Scripture Report HQ{" "}
              </h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "3000px" }}>
                  <Spinner />
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row  ">
                  <div className="col-12 text-center">
                    <p>
                      Ensure your selected date range falls within one Gideon
                      year.
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="from_date">
                        From Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("from_date"),
                          "form-control": true,
                        })}
                        id="from_date"
                        name="from_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("from_date")}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="to_date">
                        To Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("to_date"),
                          "form-control": true,
                        })}
                        id="to_date"
                        name="to_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("to_date")}
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="camp_id">
                        Camp <span className="text-danger">*</span>{" "}
                      </label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%"
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.camps &&
                            this.state.camps.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                        </select>
                        {preference_spinner ? <MiniSpinner /> : null}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div> */}
                  <div className=" col-sm-4">
                    <label>Region</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("region_id"),
                          "form-control selected_area": true,
                        })}
                        onChange={this.handleChange}
                        id="region_id"
                        // defaultValue={this.state.region_id}
                      >
                        <option value="">--Select a region--</option>
                        {this.state.user_region &&
                          this.state.user_region.map((region, index) => (
                            <option key={index} value={region.id}>
                              {region.name}--{region.state.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor("region_id")}
                  </div>
                </div>
                <div className="row mb-3">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    disabled={spinner ? true : false}
                  >
                    Generate
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>

                  {/* <button
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button> */}
                  {/* {this.state.scrip_region.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}
                </div>
              </form>

              {this.state.scrip_region.length &&
              !spinner &&
              this.state.is_region ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>SCRIPTURE TYPE</th>
                        <th>RECEIVED STOCK </th>
                        <th>MONTHLY DISTRIBUTION </th>
                        <th>OUTSTANDING WAREHOUSE STOCK BALANCE </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.scrip_region &&
                        this.state.scrip_region.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>
                                  <Link
                                    to="#!"
                                    onClick={(e) =>
                                      this.scrpRegionAreadetails(e, item.id)
                                    }
                                  >
                                    {item.name}
                                  </Link>
                                </td>
                                <td>{item.stock ? item.stock : 0}</td>
                                <td>
                                  {item.distributed ? item.distributed : 0}
                                </td>
                                <td>{item.balance ? item.balance : 0}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        <th>Total</th>
                        <th>
                          {" "}
                          {this.state.scrip_region &&
                            this.state.scrip_region.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.stock)
                                  ? parseFloat(obj.stock)
                                  : 0)
                              );
                            }, 0)}
                        </th>

                        <th>
                          {" "}
                          {this.state.scrip_region &&
                            this.state.scrip_region.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.distributed)
                                  ? parseFloat(obj.distributed)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {" "}
                          {this.state.scrip_region &&
                            this.state.scrip_region.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.balance)
                                  ? parseFloat(obj.balance)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {this.state.scrip_region_area &&
              !spinner &&
              this.state.is_area ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Scripture</th>
                        <th>distributed</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.scrip_region_area.areas.length &&
                        this.state.scrip_region_area.areas.map(
                          (item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>
                                    <Link
                                      to="#!"
                                      onClick={(e) =>
                                        this.scrpRegionAreaCampdetails(
                                          e,
                                          item.id
                                        )
                                      }
                                    >
                                      {item.area}
                                    </Link>
                                  </td>
                                  <td>{item.region}</td>
                                  <td>{item.state}</td>
                                  <td>{this.state.scrip_region_area.name}</td>
                                  <td>{item.distributed}</td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                      <tr>
                        <td>
                          {" "}
                          <strong>Total</strong>{" "}
                        </td>

                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          <button
                            type="button"
                            onClick={(e) => this.handleSubmit(e)}
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                            disabled={spinner ? true : false}
                          >
                            Back
                          </button>
                        </td>

                        <td>
                          {" "}
                          {this.state.scrip_region_area.areas.length &&
                            this.state.scrip_region_area.areas.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.distributed)
                                  ? parseFloat(obj.distributed)
                                  : 0)
                              );
                            },
                            0)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {this.state.scrip_region_camp &&
              !spinner &&
              this.state.is_camp ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Scripture:</th>
                        <th>distributed</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.scrip_region_camp.camps &&
                        this.state.scrip_region_camp.camps.map(
                          (item, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr>
                                  <td>{item.camp}</td>
                                  <td>{item.area}</td>
                                  <td>{item.region}</td>
                                  <td>{item.state}</td>
                                  <td>{this.state.scrip_region_camp.name}</td>
                                  <td>{item.distributed}</td>
                                </tr>
                              </React.Fragment>
                            );
                          }
                        )}
                      <tr>
                        <td>
                          {" "}
                          <strong>Total</strong>{" "}
                        </td>

                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          <button
                            type="button"
                            onClick={(e) =>
                              this.scrpRegionAreadetails(
                                e,
                                this.state.scrip_region_camp.id
                              )
                            }
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                            disabled={spinner ? true : false}
                          >
                            Back
                          </button>
                        </td>

                        <td>
                          {" "}
                          {this.state.scrip_region_camp.camps &&
                            this.state.scrip_region_camp.camps.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.distributed)
                                  ? parseFloat(obj.distributed)
                                  : 0)
                              );
                            },
                            0)}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.reports.spinner,
    message: state.reports.message,
    status: state.reports.status,
    errors: state.reports.errors,
    scrip_region: state.reports.scrip_region,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
    user_region: state.preferences.user_region,
    scrip_region_area: state.reports.scrip_region_area,
    scrip_region_camp: state.reports.scrip_region_camp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRegionScriptureReport: (from, to, region, area, camp) =>
      dispatch(getRegionScriptureReport(from, to, region, area, camp)),
    // getAllCamps: () => dispatch(getAllCamps()),
    setAll: () => dispatch(setAll()),
    scrpRegionAreadetails: (region, bible, from, to) =>
      dispatch(scrpRegionAreadetails(region, bible, from, to)),
    scrpRegionAreaCampdetails: (region, area, bible, from, to) =>
      dispatch(scrpRegionAreaCampdetails(region, area, bible, from, to)),
    getAllCamps: () => dispatch(getAllCamps()),
    selectCamp: (id) => dispatch(selectCamp(id)),
    selectArea: (id) => dispatch(selectArea(id)),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetReportState: () => resetReportState(dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionScriptureReport);
