import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getVerifiedContributionNon,
  acknowlegdeContribution,
  reverseCampActivityNonScripture, //added by macdonald
  resetUsersState,
} from "./../../../../store/actions/usersActions";
// import classnames from "classnames";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import Pagination from "react-js-pagination";
import { image_path } from "./../../../../config";
// import { getAllCamps } from "../../../../store/actions/preferencesActions";
// import MiniSpinner from "../../../helpers/MiniSpinner";
// import classnames from "classnames";

class VerifiedReportNon extends Component {
  state = {
    activePage: 1,
    modal: false,
    modal_1: false,
  };
  componentDidMount() {
    const { setPermissionsErrors, getVerifiedContributionNon } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_PAYMENTS" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      let years_arry = [];
      const start_year = 2020;
      const end_year = new Date().getFullYear() + 2;
      for (let i = start_year; i < end_year; i++) {
        years_arry.push(i);
      }
      this.setState({ years_arry });
      getVerifiedContributionNon().then((res) => {
        if (res.status === "success") {
          this.setState({ ...res.payload.reports });
          console.log('non-scripture camp activities: ', res.payload.reports );
        }
      });
      //   this.props.getAllCamps();
    }
  }
  dateConv = (date) => {
    return new Date(date).toDateString();
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    const { getVerifiedContributionNon } = this.props;
    const year = this.state.year_pag;
    const month = this.state.month_pag;
    const day = this.state.day_pag;
    getVerifiedContributionNon(pageNumber, year, month, day).then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.reports });
      }
    });
  };
  acknowlegdeContribution = (e, id) => {
    e.preventDefault();
    // this.setState({ selected_id: id, camp_id: "", modal: true });
    if (
      window.confirm(
        "Are you sure you have verified and want to confirm this contribution?"
      )
    ) {
      this.props.acknowlegdeContribution(id).then((res) => {
        if (res.status === "success") {
          const tempdata = [...this.state.data];
          this.setState({
            data: tempdata.filter((data) => data.id !== id),
          });
        }
      });
    }
  };
  viewImage = (e, id) => {
    e.preventDefault();
    this.setState(
      { selected_member: this.state.data.find((item) => item.id === id) },
      () => this.setState({ modal: true })
    );
  };
  viewMore = (e, id) => {
    e.preventDefault();
    this.setState(
      { selected_report: this.state.data.find((item) => item.id === id) },
      () => this.setState({ modal_1: true })
    );
  };

  reverseCampActivityNonScripture = (e, id) => {
    e.preventDefault();
    // this.setState({ selected_id: id, camp_id: "", modal: true });
    if (
      window.confirm("Are you sure want to revert this contribution to pending")
    ) {
      this.props.reverseCampActivityNonScripture(id).then((res) => {
        if (res.status === "success") {
          const tempdata = [...this.state.data];
          this.setState({
            data: tempdata.filter((data) => data.id !== id),
          });
        }
      });
    }
  };
  // handleSubmit = e => {
  //   e.preventDefault();
  //   const creds = {
  //     id: this.state.selected_id,
  //     camp_id: this.state.camp_id
  //   };

  // };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  closeModal = (e) => {
    this.setState({ modal: false, selected_member: null });
    this.props.resetUsersState();
  };
  closeModal_1 = (e) => {
    this.setState({ modal_1: false, selected_report: null });
    this.props.resetUsersState();
  };
  render() {
    const { spinner, message, status } = this.props;

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>MEMBERSHIP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Verified Camp Activities
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Verified Camp Activities
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {this.state.modal ? (
                <div
                  //   className="card opp"
                  //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    zIndex: 9999999,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    // padding: "2%",
                    display: "flex",
                    justifyContent: "center",

                    alignItems: "center",

                    // transition: "all 10s ease"
                  }}
                >
                  <div className="card-body">
                    <div
                      className="row col-sm-12"
                      style={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <div className="col-sm-6 offset-sm-3  ">
                        <h5 className="text-center mt-3">
                          {" "}
                          Evidence of Payment
                        </h5>
                        {this.state.selected_member.evidence ? (
                          <img
                            className="img-fluid"
                            src={`${image_path}${this.state.selected_member.evidence}`}
                            alt=""
                          />
                        ) : (
                          <p>No payment evidence provided</p>
                        )}
                        <br />
                        <button
                          className="btn btn-default btn-sm text-uppercase font-weight-bold my-2"
                          onClick={this.closeModal}
                        >
                          Close
                        </button>{" "}
                        {this.state.selected_member.evidence ? (
                          <a
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold my-2"
                            // onClick={this.downloadEvidence}
                            href={`${image_path}${this.state.selected_member.evidence}`}
                            download="evidence_of_payment"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Download
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
              ) : null}
              {this.state.modal_1 ? (
                <div
                  //   className="card opp"
                  //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    zIndex: 9999999,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    // padding: "2%",
                    display: "flex",
                    justifyContent: "center",

                    alignItems: "center",

                    // transition: "all 10s ease"
                  }}
                >
                  <div className="card-body">
                    <div
                      className="row col-sm-12"
                      style={{
                        backgroundColor: "#fff",
                      }}
                    >
                      <div className="col-sm-12  p-5 ">
                        <h5 className="text-center mt-3"> Activity Details</h5>
                        <br />
                        <div>
                          <div
                            className="row d-flex justify-content-between"
                            style={{
                              overflow: "hidden",
                            }}
                          >
                            <div>
                              <p className="font-weight-bold">
                                Activity Type:{" "}
                                <span className="font-italic">
                                  {this.state.selected_report.activity.type
                                    ? this.state.selected_report.activity.type
                                    : null}
                                </span>{" "}
                              </p>
                              <p className="font-weight-bold">
                                Expected Funding:{" "}
                                <span>
                                  {" "}
                                  &#8358;
                                  {this.state.selected_report.activity.funding
                                    ? this.moneyConv(
                                        this.state.selected_report.activity
                                          .funding
                                      )
                                    : 0}
                                </span>{" "}
                              </p>
                              <p className="font-weight-bold">
                                target Audience:{" "}
                                <span>
                                  {this.state.selected_report.activity.target
                                    ? this.state.selected_report.activity.target
                                    : null}
                                </span>{" "}
                              </p>
                              <p className="font-weight-bold">
                                Activity Description:{" "}
                                <span>
                                  {this.state.selected_report.activity
                                    .description
                                    ? this.state.selected_report.activity
                                        .description
                                    : null}
                                </span>{" "}
                              </p>
                            </div>
                            <div>
                              <p className="font-weight-bold">
                                Location:{" "}
                                <span className="font-italic">
                                  {this.state.selected_report.activity.location
                                    ? this.state.selected_report.activity
                                        .location
                                    : null}
                                </span>{" "}
                              </p>
                              <p className="font-weight-bold">
                                Speaker:{" "}
                                <span className="font-italic">
                                  {this.state.selected_report.activity.speaker
                                    ? this.state.selected_report.activity
                                        .speaker
                                    : null}
                                </span>{" "}
                              </p>
                              <p className="font-weight-bold">
                                Created Date:{" "}
                                <span className="font-italic">
                                  {/* {this.moneyConv(this.state.selected_report.activity.stock)} */}
                                  {this.state.selected_report.activity
                                    .created_at
                                    ? this.dateConv(
                                        this.state.selected_report.activity
                                          .created_at
                                      )
                                    : null}
                                </span>{" "}
                              </p>
                              <p className="font-weight-bold">
                                Scheduled Date:{" "}
                                <span className="font-italic">
                                  {/* {this.moneyConv(this.state.selected_report.activity.stock)} */}
                                  {this.state.selected_report.activity
                                    .activity_date
                                    ? this.dateConv(
                                        this.state.selected_report.activity
                                          .activity_date
                                      )
                                    : null}
                                </span>{" "}
                              </p>
                            </div>
                          </div>
                          <hr className="mb-5" />
                          <form onSubmit={this.handleSubmit}>
                            <div className="row">
                              <div className="form-group col-md-4">
                                <label htmlFor="amount">
                                  Realized Funds: &nbsp;{" "}
                                </label>
                                &#8358;{" "}
                                {this.moneyConv(
                                  this.state.selected_report.amount
                                )}
                              </div>
                              <div className="form-group col-md-4">
                                <label htmlFor="presentations">
                                  No of presentations: &nbsp;
                                </label>
                                {this.state.selected_report.presentations}
                              </div>
                              <div className="form-group col-md-4">
                                <label htmlFor="male">
                                  Male Attendance: &nbsp;
                                </label>
                                {this.state.selected_report.male}
                              </div>
                              <div className="form-group col-md-4">
                                <label htmlFor="female">
                                  Female Attendance: &nbsp;
                                </label>
                                {this.state.selected_report.female}
                              </div>
                              <div className="form-group col-md-4">
                                <label htmlFor="guest">
                                  Guest Attendance : &nbsp;
                                </label>
                                {this.state.selected_report.guest}
                              </div>
                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label htmlFor="channel">
                                    Payment Type : &nbsp;
                                  </label>
                                  {this.state.selected_report.channel}
                                </div>
                              </div>

                              <div className="col-sm-4">
                                <div className="form-group">
                                  <label htmlFor="transaction_id">
                                    Transaction ID / Reference ID / Teller No.
                                  </label>
                                  {this.state.selected_report.transaction_id}
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label htmlFor="evidence_date">
                                    Date : &nbsp;
                                  </label>
                                  {this.dateConv(
                                    this.state.selected_report.paid_date
                                  )}
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                        <button
                          className="btn btn-default btn-sm text-uppercase font-weight-bold my-2"
                          onClick={this.closeModal_1}
                        >
                          Close
                        </button>{" "}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
              ) : null}

              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <div className="form-group col-md-3 d-flex">
                    <select
                      id="year_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.year_pag || ""}
                    >
                      <option value="">Year</option>
                      {this.state.years_arry &&
                        this.state.years_arry.map((year) => (
                          <option key={year} value={year}>
                            {" "}
                            {year}
                          </option>
                        ))}
                    </select>
                    <select
                      id="month_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.month_pag || ""}
                    >
                      <option value="">Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <select
                      id="day_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.day_pag || ""}
                    >
                      <option value="">Day</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8"> 8</option>
                      <option value="9"> 9</option>
                      <option value="10"> 10</option>
                      <option value="11"> 11</option>
                      <option value="12"> 12</option>
                      <option value="13"> 13</option>
                      <option value="14"> 14</option>
                      <option value="15"> 15</option>
                      <option value="16"> 16</option>
                      <option value="17"> 17</option>
                      <option value="18"> 18</option>
                      <option value="19"> 19</option>
                      <option value="20"> 20</option>
                      <option value="21"> 21</option>
                      <option value="22"> 22</option>
                      <option value="23"> 23</option>
                      <option value="24"> 24</option>
                      <option value="25"> 25</option>
                      <option value="26"> 26</option>
                      <option value="27"> 27</option>
                      <option value="28"> 28</option>
                      <option value="29"> 29</option>
                      <option value="30"> 30</option>
                      <option value="31"> 31</option>
                    </select>
                    <button onClick={this.handleSearchChange}>
                      <i className="fa fa-search text-primary"></i>
                    </button>
                    <button onClick={this.cancelSearch}>
                      <i className="fa fa-times text-danger"></i>
                    </button>
                  </div>
                  <table
                    id="state_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Amount ( &#8358; )</th>
                        <th>Payment Mode</th>
                        <th>Transaction ID / Teller No.</th>
                        <th>Transaction Date</th>
                        <th>Date Created</th>
                        <th>Date Verified</th>
                        <th>Verified By</th>
                        <th>View Payment Evidence</th>
                        <th>View More</th>
                        <th>Revert Payment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data &&
                        this.state.data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {parseFloat(item["amount"])
                                  .toFixed(2)
                                  .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
                              </td>

                              <td>{item.channel}</td>
                              <td>{item.transaction_id}</td>
                              <td>{this.dateConv(item.paid_date)}</td>
                              <td>{this.dateConv(item.created_at)}</td>
                              <td>{this.dateConv(item.verified_date)}</td>
                              <td>
                                {item.channel === "remita"
                                  ? "REMITA"
                                  : `${
                                      item.verified_by &&
                                      item.verified_by.first_name
                                    } ${
                                      item.verified_by &&
                                      item.verified_by.last_name
                                    } ${
                                      item.verified_by &&
                                      item.verified_by.other_name
                                    }`}
                                {/*  */}
                              </td>
                              <td>
                                <button
                                  className="btn btn-lg btn-clean btn-icon btn-icon-md"
                                  title="View evidence"
                                  onClick={(e) => {
                                    this.viewImage(e, item.id);
                                  }}
                                >
                                  <i className="fa fa-eye"></i>
                                </button>
                              </td>
                              <td>
                                <button
                                  className="btn btn-sm "
                                  title="View More"
                                  onClick={(e) => {
                                    this.viewMore(e, item.id);
                                  }}
                                >
                                  View
                                </button>
                              </td>
                              <td>
                                <button
                                  className="btn btn-lg btn-clean btn-icon btn-icon-md"
                                  title="Revert to pending"
                                  onClick={(e) => {
                                    this.reverseCampActivityNonScripture(e, item.id);
                                  }}
                                >
                                  <i className="fa fa-ban"></i>
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.per_page}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    errors: state.users.errors,
    message: state.users.message,
    status: state.users.status,
    // camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getVerifiedContributionNon: (page, year, month, day) =>
      getVerifiedContributionNon(dispatch, page, year, month, day),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    acknowlegdeContribution: (id) => acknowlegdeContribution(dispatch, id),
    reverseCampActivityNonScripture: (id) => reverseCampActivityNonScripture(dispatch, id),
    // getAllCamps: () => dispatch(getAllCamps()),
    resetUsersState: () => resetUsersState(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(VerifiedReportNon);
