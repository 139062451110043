import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getMemberReport,
  getMemberAllCampReport,
  resetReportState,
} from "./../../../store/actions/reportActions";
import { getAllCamps, setAll } from "../../../store/actions/preferencesActions";

import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import classnames from "classnames";
import MiniSpinner from "./../../helpers/MiniSpinner";
import $ from "jquery";
// import _ from "underscore";
import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../store/actions/authActions";
import { BASE_URL, token, storage_type } from "../../../config";
// var $ = require("jquery");
// var dt = require("datatables.net")();
class MemberReportHQ extends Component {
  state = {
    camps: null,
    mem: null,
    area_id: "",
    checkarea: 0,
    checkregion: 0,
    checkstate: 0,
    user_camp: null,
    select_All: "ALL", //added by Macdonald
    // arranged_data: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REPORTS",
      }) ||
      !hasPermission({
        mod: "Reports",
        action: "VIEW_CAMP_MEMBERSHIP_REPORT_HQ",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    document.title =
      "The Gideon International In Nigeria (Camp Membership Report)";
    this.initDependency();
    // const { getMemberReport } = this.props;
    // getMemberReport(1, "12/12/2020", "15/12/2020");
    this.props.setAll();
    this.props.getAllCamps();
    // this.props.getAllArea().then(res => {
    //   if (res.status === "success") {
    //     this.setState({ areas: res.payload.area });
    //   }
    // });
  }
  initDependency = () => {
    // alert("here");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.camps !== prevState.camps ||
      nextProps.user_camp !== prevState.user_camp ||
      nextProps.mem !== prevState.mem
    ) {
      // const arry = [];
      // const temp_mem = [...nextProps.mem];
      // if (temp_mem.length) {
      //   const camp_group = _.groupBy(temp_mem, "camp");
      //   for (let [key, value] of Object.entries(camp_group)) {
      //     arry.push({
      //       camp: key,
      //       fund: _.groupBy(value, "purpose"),
      //     });
      //   }
      //   // console.log(arry);
      //   // this.updateState(arry);
      // }
      // if (nextProps.mem) {
      //   $("#fund_table1 tbody").empty();
      // }
      // console.log(prevState);
      return {
        camps: nextProps.camps,
        mem: nextProps.mem,
        user_camp: nextProps.user_camp,
      };
    }
    return null;
  }
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  handleDownload = (e) => {
    e.preventDefault();
    const from = $("#from_date").val();
    const to = $("#to_date").val();
    const id = $(".memberselect22").val();
    const area = this.state.checkarea;
    const region = this.state.checkregion;
    const state = this.state.checkstate;
    const tok = storage_type.getItem(token);
    const download = 1;
    const url = `${BASE_URL}/reports/camps/mem?camp=${id}&from=${from}&to=${to}&area=${area}&region=${region}&state=${state}&download=${download}&token=${tok}`;
    window.open(url, "_blank");
  };
  handleSubmit = (e) => {
    e.preventDefault();

    const from_date = $("#from_date").val();
    const to_date = $("#to_date").val();
    const id = $(".memberselect22").val();
    document.title = `The Gideon International In Nigeria (Camp Membership Report ${from_date}---${to_date})`;

    // const area = this.state.checkarea;
    // const region = this.state.checkregion;
    // const state = this.state.checkstate;
    // alert(area);
    this.setState(
      {
        from_date,
        to_date,
        id,
      },
      () => {
        // Code modification/Addition by Macdonald
        if (id == "ALL") {
          this.props.getMemberAllCampReport(from_date, to_date);
        } else {
          this.props.getMemberReport(id, from_date, to_date);
        }
      }
    );
  };

  // arrangeReport = () => {};
  // updateState = arry => {
  //   const dd = arry;
  //   setTimeout(() => {
  //     this.setState({ new_arry: dd });
  //   }, 1000);
  // };
  setDownload = (e) => {
    e.preventDefault();
    // window.__CsvTable__ = 1;
    $(".fund_table").attr("id", "role_table22");
    this.initDependency();
    // window.__CsvTable__ = 0;
  };
  handleSelectArea = (e) => {
    this.setState({ area_id: e.target.value });
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  dateConv = (date) => {
    return date ? new Date(date).toDateString() : null;
  };
  handleCheck = (e) => {
    // e.preventDefault();
    this.setState((prevState, nextProps) => {
      return {
        checkarea: 0,
        checkregion: 0,
        checkstate: 0,
      };
    });
    this.setState({
      [e.target.id]: this.state[e.target.id] === 1 ? 0 : 1,
    });
  };

  componentWillUnmount() {
    this.props.resetReportState();
  }
  render() {
    const { spinner, message, status } = this.props;

    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>REPORT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Membership Report HQ
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Membership Report HQ
              </h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row  ">
                  <div className="col-12 text-center">
                    <p>
                      Ensure your selected date range falls within one Gideon
                      year.
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="from_date">
                        From Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("from_date"),
                          "form-control": true,
                        })}
                        id="from_date"
                        name="from_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("from_date")}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="to_date">
                        To Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("to_date"),
                          "form-control": true,
                        })}
                        id="to_date"
                        name="to_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("to_date")}
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="camp_id">Camp</label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true,
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%",
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.camps &&
                            this.state.camps.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                        </select>
                        {preference_spinner ? <MiniSpinner /> : null}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div> */}
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="camp_id">Camp</label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true,
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%",
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.camps &&
                            this.state.camps.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                          <option value={this.state.select_All}>ALL</option>
                        </select>
                        {/* {preference_spinner ? <MiniSpinner /> : null} */}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div>

                  {/* <div className=" col-sm-1" style={{ marginTop: "25px" }}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkarea"
                        onChange={this.handleCheck}
                        checked={this.state.checkarea === 1}
                      />
                      <label className="form-check-label" htmlFor="checkarea">
                        Area
                      </label>
                    </div>
                  </div>
                  <div className=" col-sm-1" style={{ marginTop: "25px" }}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkregion"
                        onChange={this.handleCheck}
                        checked={this.state.checkregion === 1}
                      />
                      <label className="form-check-label" htmlFor="checkregion">
                        Region
                      </label>
                    </div>
                  </div>
                  <div className=" col-sm-1" style={{ marginTop: "25px" }}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkstate"
                        onChange={this.handleCheck}
                        checked={this.state.checkstate === 1}
                      />
                      <label className="form-check-label" htmlFor="checkstate">
                        State
                      </label>
                    </div>
                  </div> */}
                </div>
                <div className="row mb-4">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    disabled={spinner ? true : false}
                  >
                    Generate
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>

                  {/* {this.state.mem.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}

                  {/* <button
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button> */}
                </div>
              </form>

              {this.state.mem.length && !spinner ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Total Gideons </th>
                        <th>Total Auxilliaries </th>
                        <th>Active Gideons </th>
                        <th>Active Auxilliaries </th>
                        <th>Dropped Gideons </th>
                        <th>Dropped Auxilliaries </th>
                        <th>New Gideons</th>
                        <th>New Auxilliaries </th>
                        <th>Reinstated Gideons </th>
                        <th>Reinstated Auxilliaries </th>
                        <th>Delisted Gideons </th>
                        <th>Delisted Auxilliaries </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.mem &&
                        this.state.mem.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.camp}</td>
                                <td>{item.area}</td>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>
                                  {(parseFloat(item.active.gideon) || 0) +
                                    (parseFloat(item.dropped.gideon) || 0) +
                                    (parseFloat(
                                      item.new_members.total_gideon
                                    ) || 0) +
                                    (parseFloat(item.reinstated.gideon) || 0)}
                                </td>
                                <td>
                                  {(parseFloat(item.active.aux) || 0) +
                                    (parseFloat(item.dropped.aux) || 0) +
                                    (parseFloat(item.new_members.total_aux) ||
                                      0) +
                                    (parseFloat(item.reinstated.aux) || 0)}
                                </td>
                                <td>{item.active.gideon || 0}</td>
                                <td>{item.active.aux || 0}</td>
                                <td>{item.dropped.gideon || 0}</td>
                                <td>{item.dropped.aux || 0}</td>
                                <td>{item.new_members.total_gideon || 0}</td>
                                <td>{item.new_members.total_aux || 0}</td>
                                <td>{item.reinstated.gideon || 0}</td>
                                <td>{item.reinstated.aux || 0}</td>
                                <td>{item.actual_delisted.gideon || 0}</td>
                                <td>{item.actual_delisted.aux || 0}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      {this.state.id === "ALL" ? (
                        <tr>
                          <th>Total</th>
                          <th></th>
                          <th></th>
                          <th></th>
                          <th>
                            {(this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.active.gideon)
                                    ? parseFloat(obj.active.gideon)
                                    : 0)
                                );
                              }, 0)) +
                              (this.state.mem &&
                                this.state.mem.reduce(function (acc, obj) {
                                  return (
                                    acc +
                                    (parseFloat(obj.dropped.gideon)
                                      ? parseFloat(obj.dropped.gideon)
                                      : 0)
                                  );
                                }, 0)) +
                              (this.state.mem &&
                                this.state.mem.reduce(function (acc, obj) {
                                  return (
                                    acc +
                                    (parseFloat(obj.new_members.total_gideon)
                                      ? parseFloat(obj.new_members.total_gideon)
                                      : 0)
                                  );
                                }, 0)) +
                              (this.state.mem &&
                                this.state.mem.reduce(function (acc, obj) {
                                  return (
                                    acc +
                                    (parseFloat(obj.reinstated.gideon)
                                      ? parseFloat(obj.reinstated.gideon)
                                      : 0)
                                  );
                                }, 0))}
                          </th>
                          <th>
                            {(this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.active.aux)
                                    ? parseFloat(obj.active.aux)
                                    : 0)
                                );
                              }, 0)) +
                              (this.state.mem &&
                                this.state.mem.reduce(function (acc, obj) {
                                  return (
                                    acc +
                                    (parseFloat(obj.dropped.aux)
                                      ? parseFloat(obj.dropped.aux)
                                      : 0)
                                  );
                                }, 0)) +
                              (this.state.mem &&
                                this.state.mem.reduce(function (acc, obj) {
                                  return (
                                    acc +
                                    (parseFloat(obj.new_members.total_aux)
                                      ? parseFloat(obj.new_members.total_aux)
                                      : 0)
                                  );
                                }, 0)) +
                              (this.state.mem &&
                                this.state.mem.reduce(function (acc, obj) {
                                  return (
                                    acc +
                                    (parseFloat(obj.reinstated.aux)
                                      ? parseFloat(obj.reinstated.aux)
                                      : 0)
                                  );
                                }, 0))}
                          </th>

                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.active.gideon)
                                    ? parseFloat(obj.active.gideon)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.active.aux)
                                    ? parseFloat(obj.active.aux)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.gideon)
                                    ? parseFloat(obj.dropped.gideon)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.aux)
                                    ? parseFloat(obj.dropped.aux)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_gideon)
                                    ? parseFloat(obj.new_members.total_gideon)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_aux)
                                    ? parseFloat(obj.new_members.total_aux)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.gideon)
                                    ? parseFloat(obj.reinstated.gideon)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.aux)
                                    ? parseFloat(obj.reinstated.aux)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.actual_delisted.gideon)
                                    ? parseFloat(obj.actual_delisted.gideon)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                          <th>
                            {this.state.mem &&
                              this.state.mem.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.actual_delisted.aux)
                                    ? parseFloat(obj.actual_delisted.aux)
                                    : 0)
                                );
                              }, 0)}
                          </th>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.reports.spinner,
    message: state.reports.message,
    status: state.reports.status,
    errors: state.reports.errors,
    mem: state.reports.mem,
    camps: state.preferences.camps,
    user_camp: state.preferences.user_camp,
    preference_spinner: state.preferences.spinner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMemberReport: (id, from, to) => dispatch(getMemberReport(id, from, to)),
    getMemberAllCampReport: (from, to) =>
      dispatch(getMemberAllCampReport(from, to)),
    getAllCamps: () => dispatch(getAllCamps()),
    setAll: () => dispatch(setAll()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetReportState: () => resetReportState(dispatch),
    // getAllArea: () => getAllArea(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MemberReportHQ);
