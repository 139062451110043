import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllDepotInventoryLimit,
  setDepotInventory,
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import MiniSpinner from "./../../../helpers/MiniSpinner";
import { token,storage_type, user_auth_details, dehash,BASE_URL } from "../../../../config";

class SetDepotInventoryLimit extends Component {
  state = {
    depot_inventory: null,
    bibles: [],
    depot_id: '',
    depots: null
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllDepotInventory } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_DEPOT_INVENTORY" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      if (storage_type.getItem(user_auth_details)) {
        const user = JSON.parse(
          dehash(`${storage_type.getItem(user_auth_details)}`)
        );
        this.setState({ depots: user.depots });
      }
      //getAllDepotInventoryLimit();
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  setBibles = () => {
    const bibles = [];
    this.state.hq_inventory.forEach((item) => {
      bibles.push({
        bible_id: item.bible_id,
        limit: item.limit,
      });
    });
    this.setSate({
      bibles,
    });
  };
  handleChange = (e) => {
    const bibles = [...this.state.bibles];
    if (bibles.length) {
      // eslint-disable-next-line
      const bible = bibles.find((item) => item.bible_id == e.target.id);
      if (bible) {
        bible.limit = e.target.value ? e.target.value : "0";
      } else {
        bibles.push({
          bible_id: e.target.id,
          limit: e.target.value ? e.target.value : "0",
        });
      }
    } else {
      bibles.push({ bible_id: e.target.id, limit: e.target.value });
    }
    this.setState({
      bibles,
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    // if (nextProps.depot_inventory !== prevState.depot_inventory) {
    //   const bibles = [];
    //   nextProps.depot_inventory.forEach((item) => {
    //     bibles.push({
    //       bible_id: item.bible_id,
    //       limit: item.limit,
    //     });
    //   });
    //   return {
    //     depot_inventory: nextProps.depot_inventory,
    //     bibles,
    //   };
    // }
    // return null;
  }

   //---------------------CODE ADDED BY MACDONALD------------------------
   handleDepotChange = (e) => {
    this.setState({
        depot_id: e.target.value
    });

    const creds = {
      depot_id: e.target.value
    };
    
    var cars = this.getUserDepotInventories(creds);
    console.log("Credentials: ", creds);

    window.$depotID = e.target.value;


   //console.log("Depot ID: ", window.$depotID);
}

async getUserDepotInventories (invs) {
  const response = await fetch(
    `${BASE_URL}/depot/inventories/depot-inventories?Id=`+invs.depot_id, {
    method:'GET',
    headers: {
      //"Content-Type": "application/json",
      Authorization: `Bearer ${storage_type.getItem(token)}`,
    }, 
  })
  var result = await response.json();
  this.setState({
    depot_inventory: result.payload.inventories
  });
};

//---------------------------------END------------------------------------

  handleSubmit = (e) => {
    e.preventDefault();
    const { setDepotInventory } = this.props;
    setDepotInventory({ bibles: this.state.bibles });
  };

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    // if (this.state.hq_inventory) {
    //   this.setBibles();
    // }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Set Depot Inventory Reorder Limit
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
            <h3 className="card-title font-weight-bold">
              {/* <form onSubmit={this.handleSubmit}> */}
                <div className='row'>
                  <div className="form-group col-md-12">
                    <label>
                      Select From Your Depots <span className="text-danger"> *</span>
                    </label>
                    {'  '}
                    <select
                        // className={classnames({
                        //   "is-invalid": this.hasErrorFor("id"),
                        //   "form-control selected_state": true
                        // })}
                        placeholder="Select"
                        onChange={this.handleDepotChange}
                        id="depot_id"
                        value={this.state.depot_id}
                      >
                      <option value="">--Select--</option>
                        {this.state.depots &&
                          this.state.depots.map((depot, index) => (
                            <option key={index} value={depot.id}>
                              {depot.name} - {depot.address}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                {/* {this.state.depots ? `(${this.state.depots[0].name})` : null} */}
              {/* </form> */}
              </h3>

              {/* <h3 className="card-title font-weight-bold">
                Set Depot Inventory Reorder Limit{" "}
                {this.state.depot ? `(${this.state.depot.name})` : null}
              </h3> */}
              <div className="card-tools">
                <Link to="/preferences/depot-inventory/add">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle "></i>
                    ADD INVENTORY
                  </button>
                </Link>{" "}
                <Link to="/preferences/depot-inventory/remove">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-minus-circle mr-1"></i>
                    REMOVE INVENTORY
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}

              <form onSubmit={this.handleSubmit}>
                <table
                  id=""
                  className="table table-hover table-bordered table-striped"
                  style={{
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Inventory</th>

                      <th>Limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.depot_inventory &&
                      this.state.depot_inventory.map((inventory, index) => {
                        return (
                          <tr key={index}>
                            <td>{inventory.bible.name}</td>
                            <td>
                              <input
                                type="number"
                                name=""
                                id={inventory.bible_id}
                                min={0}
                                defaultValue={inventory.limit}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Submit
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    depot_inventory: state.preferences.depot_inventory,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllDepotInventoryLimit: () => dispatch(getAllDepotInventoryLimit()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    setDepotInventory: (creds) => setDepotInventory(dispatch, creds),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetDepotInventoryLimit);
