import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getOneDepoInventory } from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import queryString from "query-string";

class DetailsOneDepot extends Component {
  state = {
    depot_one_inventory: null,
  };
  componentDidMount() {
    const { setPermissionsErrors, getOneDepoInventory } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_DEPOT" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const id = this.props.match.params.id;
      getOneDepoInventory(id);
      let query = queryString.parse(this.props.location.search);
      this.setState({ depot: query.depot });
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.depot_one_inventory !== prevState.depot_one_inventory) {
      return {
        depot_one_inventory: nextProps.depot_one_inventory,
      };
    }
    return null;
  }

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {this.state.depot} Depot's Inventories
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                {this.state.depot} Depot's Inventories
              </h3>
              <div className="card-tools">
                <Link to="/preferences/depots">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-arrow-circle-left "></i>
                    Back
                  </button>
                </Link>{" "}
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <table
                    id="hq_inventory_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Inventory</th>
                        <th>Type</th>
                        <th>Quantity</th>
                        {/* <th>View</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.depot_one_inventory &&
                        this.state.depot_one_inventory.map(
                          (inventory, index) => {
                            return (
                              <tr key={index}>
                                <td>{inventory.bible.name}</td>
                                <td>
                                  {" "}
                                  {inventory.bible.merchandise === 1
                                    ? "Cash Item"
                                    : "Non Cash Item"}
                                </td>
                                <td>{inventory.stock}</td>

                                {/* <td>
                                  <span
                                    style={{
                                      overflow: "visible",
                                      position: "relative",
                                      width: "110px",
                                    }}
                                  >
                                    <Link
                                      to={`/preferences/depot-inventory/${inventory.id}`}
                                      className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                      title="view inventory"
                                    >
                                      <i className="fa fa-eye"></i>
                                    </Link>
                                  </span>
                                </td> */}
                              </tr>
                            );
                          }
                        )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    depot_one_inventory: state.preferences.depot_one_inventory,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOneDepoInventory: (id) => dispatch(getOneDepoInventory(id)),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailsOneDepot);
