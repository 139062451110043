import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPendingKitsCamp,
  acknowlegdeKits
} from "./../../../../store/actions/usersActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import Pagination from "react-js-pagination";

class AcknowlegdeKit extends Component {
  state = {
    activePage: 1
  };
  componentDidMount() {
    const { setPermissionsErrors, getPendingKitsCamp } = this.props;
    if (
      !hasPermission({
        mod: "Camp Administration",
        action: "VIEW_CAMP_ADMINISTRATION"
      }) ||
      !hasPermission({ mod: "Camp Administration", action: "RECEIVE_KIT" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      let years_arry = [];
      const start_year = 2020;
      const end_year = new Date().getFullYear() + 2;
      for (let i = start_year; i < end_year; i++) {
        years_arry.push(i);
      }
      this.setState({ years_arry });
      getPendingKitsCamp().then(res => {
        if (res.status === "success") {
          this.setState({ ...res.payload.kits });
        }
      });
    }
  }
  dateConv = date => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  cancelSearch = () => {
    this.setState(
      {
        year_pag: "",
        month_pag: "",
        day_pag: ""
      },
      () => {
        this.handleSearchChange();
      }
    );
  };
  handleSearchChange = () => {
    const pageNumber = 1;
    this.handlePageChange(pageNumber);
  };
  handleSearch = e => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value
    });
  };
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    const { getPendingKitsCamp } = this.props;
    const year = this.state.year_pag;
    const month = this.state.month_pag;
    const day = this.state.day_pag;
    getPendingKitsCamp(pageNumber, year, month, day).then(res => {
      if (res.status === "success") {
        this.setState({ ...res.payload.kits });
      }
    });
  };
  acknowlegdeKit = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you have received this kits")) {
      this.props.acknowlegdeKits(id).then(res => {
        if (res.status === "success") {
          const tempdata = [...this.state.data];
          this.setState({ data: tempdata.filter(data => data.id !== id) });
        }
      });
    }
  };
  render() {
    const { spinner, message, status } = this.props;

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>CAMP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Pending Kit(s)</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Pending Kit(s)</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <div className="form-group col-md-3 d-flex">
                    <select
                      id="year_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.year_pag || ""}
                    >
                      <option value="">Year</option>
                      {this.state.years_arry &&
                        this.state.years_arry.map(year => (
                          <option key={year} value={year}>
                            {" "}
                            {year}
                          </option>
                        ))}
                    </select>
                    <select
                      id="month_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.month_pag || ""}
                    >
                      <option value="">Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <select
                      id="day_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.day_pag || ""}
                    >
                      <option value="">Day</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8"> 8</option>
                      <option value="9"> 9</option>
                      <option value="10"> 10</option>
                      <option value="11"> 11</option>
                      <option value="12"> 12</option>
                      <option value="13"> 13</option>
                      <option value="14"> 14</option>
                      <option value="15"> 15</option>
                      <option value="16"> 16</option>
                      <option value="17"> 17</option>
                      <option value="18"> 18</option>
                      <option value="19"> 19</option>
                      <option value="20"> 20</option>
                      <option value="21"> 21</option>
                      <option value="22"> 22</option>
                      <option value="23"> 23</option>
                      <option value="24"> 24</option>
                      <option value="25"> 25</option>
                      <option value="26"> 26</option>
                      <option value="27"> 27</option>
                      <option value="28"> 28</option>
                      <option value="29"> 29</option>
                      <option value="30"> 30</option>
                      <option value="31"> 31</option>
                    </select>
                    <button onClick={this.handleSearchChange}>
                      <i className="fa fa-search text-primary"></i>
                    </button>
                    <button onClick={this.cancelSearch}>
                      <i className="fa fa-times text-danger"></i>
                    </button>
                  </div>
                  <table
                    id="state_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%"
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Members</th>
                        {/* <th>Camp</th> */}
                        <th>Kits</th>
                        <th>Date Created</th>
                        <th>Acknowlegde Kit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.data &&
                        this.state.data.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                {item.user.first_name} {item.user.last_name}{" "}
                                {item.user.other_name}{" "}
                              </td>
                              {/* <td>{item.camp.name}</td> */}
                              <td>
                                {item.kits.map((kit, index) => (
                                  <span
                                    key={index}
                                    className="badge badge-primary mr-1"
                                  >
                                    {kit}
                                  </span>
                                ))}
                              </td>
                              <td>{this.dateConv(item.created_at)}</td>
                              <td>
                                <button
                                  className="btn btn-default btn-sm btn-clean btn-icon btn-icon-md d-flex"
                                  title="acknowlegde kit"
                                  onClick={e => {
                                    this.acknowlegdeKit(e, item.id);
                                  }}
                                >
                                  <i className="fa fa-user-check mr-1 mt-1"></i>
                                  Acknowlegde
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.per_page}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPendingKitsCamp: (page, year, month, day) =>
      getPendingKitsCamp(dispatch, page, year, month, day),
    acknowlegdeKits: id => acknowlegdeKits(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AcknowlegdeKit);
