import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getPendingKitsMember,
  acknowlegdeKitsMember
} from "../../../../store/actions/usersActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
// import Pagination from "react-js-pagination";

class AcknowlegdeKitMember extends Component {
  state = {
    activePage: 1,
    kits: ""
  };
  componentDidMount() {
    const { setPermissionsErrors, getPendingKitsMember } = this.props;
    if (
      !hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD"
      }) ||
      !hasPermission({
        mod: "Personal Management",
        action: "RECEIVE_MY_KIT"
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getPendingKitsMember().then(res => {
        if (res.status === "success") {
          this.setState({ kits: res.payload.kits });
        }
      });
    }
  }
  dateConv = date => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    const { getPendingKitsMember } = this.props;
    getPendingKitsMember(`?page=${pageNumber}`).then(res => {
      if (res.status === "success") {
        this.setState({ ...res.payload.kits });
      }
    });
  };
  acknowlegdeKit = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you have received this kits")) {
      this.props.acknowlegdeKitsMember(id).then(res => {
        if (res.status === "success") {
          const tempdata = [...this.state.kits];
          this.setState({ kits: tempdata.filter(data => data.id !== id) });
        }
      });
    }
  };
  render() {
    const { spinner, message, status } = this.props;

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PERSONAL</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Pending Kit(s)</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Pending Kit(s)</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <table
                    id="state_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%"
                    }}
                  >
                    <thead>
                      <tr>
                        {/* <th>Members</th> */}
                        {/* <th>Camp</th> */}
                        <th>Kits</th>
                        <th>Date Created</th>
                        <th>Acknowledge Kit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.kits &&
                        this.state.kits.map((item, index) => {
                          return (
                            <tr key={index}>
                              {/* <td>
                                {item.user.first_name} {item.user.last_name}{" "}
                                {item.user.other_name}{" "}
                              </td> */}
                              {/* <td>{item.camp.name}</td> */}
                              <td>
                                {item.kits.map((kit, index) => (
                                  <span
                                    key={index}
                                    className="badge badge-primary mr-1"
                                  >
                                    {kit}
                                  </span>
                                ))}
                              </td>
                              <td>{this.dateConv(item.created_at)}</td>
                              <td>
                                <button
                                  className="btn btn-default btn-sm btn-clean btn-icon btn-icon-md d-flex"
                                  title="acknowlegde kit"
                                  onClick={e => {
                                    this.acknowlegdeKit(e, item.id);
                                  }}
                                >
                                  <i className="fa fa-user-check mr-1 mt-1"></i>
                                  Acknowledge
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {/* <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.per_page}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div> */}
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getPendingKitsMember: page => getPendingKitsMember(dispatch, page),
    acknowlegdeKitsMember: id => acknowlegdeKitsMember(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AcknowlegdeKitMember);
