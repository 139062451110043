import React, { Component } from "react";
import { connect } from "react-redux";
import { resetAuthPermState } from "./../../../store/actions/authActions";
import {
  getAllCamps,
  getAllRegions,
  getAllAreas,
  getAllStates,
  getAllBibles,
} from "./../../../store/actions/preferencesActions";
import Message from "./../../helpers/Message";
import { storage_type, user_auth_details, dehash } from "./../../../config";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import {
  getAllMembers_camp,
  getAllCampActitivies,
  getAllCampActitiviesNon,
  getAllCampActitiviesCon,
  getAllCampActitiviesRec,
  getAllCampActitiviesMet,
  getAllCampActitiviesLeg,
  getAllMembers,
  getAllDelisted,
  getAllStaffs,
  getAllUsers,
  getAllGideons,
  getAllAuxilliaries,
  getAllUserCount,
  getAllMemberCount,
  getPrayerBible,
  getGoalsStatistics,
  getGoalsStatisticsArea,
  getGoalsStatisticRegion,
  getGoalsStatisticState,
} from "../../../store/actions/usersActions";
// import "./../../../remita_inline";
// import $ from "jquery";
import { saveAs } from "file-saver";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";

import { hasPermission } from "../../helpers/functions/functions";

Chart.register(...registerables);

class Dashboard extends Component {
  state = {
    user_type: "G",
    user_status: null,
    camps: null,
    camp_members: null,
    members: null,
    delisted: null,
    staffs_1: null,
    users: null,
    usersCount: null,
    bibles: null,
    auxilliaries: null,
    gideons: null,
  };
  getMemberType = (type) => {
    this.setState({ user_type: type });
  };
  getNumberDay = (exp, lpd) => {
    const date1 = new Date(lpd);
    const date2 = new Date(exp);
    const date3 = new Date();
    const obj = {
      init: parseInt(date1.getTime() / (1000 * 3600 * 24)),
      final: parseInt(date2.getTime() / (1000 * 3600 * 24)),
      show: parseInt(date3.getTime() / (1000 * 3600 * 24)),
      per: parseInt(
        (parseInt(date3.getTime()) / parseInt(date2.getTime())) * 100
      ),
    };
    return obj;
  };
  getMemberStatus = (status) => {
    if (status === "GOOD") {
      return "GOOD STANDING";
    } else if (status === "TRANS") {
      return "TRANSFERED";
    } else if (status === "DELI") {
      return "DELIQUENT";
    } else if (status === "DROP") {
      return "DROPPED";
    } else if (status === "ACTI") {
      return "ACTIVE";
    } else if (status === "DELISTED") {
      return "DELISTED";
    } else if (status === "EXIT") {
      return "EXIT";
    }
    return "";
  };
  dateConv = (date) => {
    if (date === null || date === "") {
      return null;
    }
    return new Date(date).toDateString();
  };

  numberWithCommas = (x) => {
    x = x.toString();
    var pattern = /(-?\d+)(\d{3})/;
    while (pattern.test(x)) x = x.replace(pattern, "$1,$2");
    return x;
  };

  saveCanvas() {
    //save to png
    const canvasSave = document.getElementById("stackD");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "last-gideon-year-camp-goals.png");
    });
  }
  saveCanvas2() {
    //save to png
    const canvasSave = document.getElementById("stackP");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "current-gideon-year-camp-goals.png");
    });
  }
  saveCanvas3() {
    //save to png
    const canvasSave = document.getElementById("stackQ");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "next-gideon-year-camp-goals.png");
    });
  }

  saveCanvasArea() {
    //save to png
    const canvasSave = document.getElementById("stackDarea");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "last-gideon-year-area-goals.png");
    });
  }
  saveCanvas2Area() {
    //save to png
    const canvasSave = document.getElementById("stackParea");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "current-gideon-year-area-goals.png");
    });
  }
  saveCanvas3Area() {
    //save to png
    const canvasSave = document.getElementById("stackQarea");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "current-gideon-year-area-goals.png");
    });
  }
  saveCanvasRegion() {
    //save to png
    const canvasSave = document.getElementById("stackDregion");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "last-gideon-year-region-goals.png");
    });
  }
  saveCanvas2Region() {
    //save to png
    const canvasSave = document.getElementById("stackPregion");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "current-gideon-year-region-goals.png");
    });
  }
  saveCanvas3Region() {
    //save to png
    const canvasSave = document.getElementById("stackQregion");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "current-gideon-year-region-goals.png");
    });
  }
  saveCanvasState() {
    //save to png
    const canvasSave = document.getElementById("stackDstate");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "last-gideon-year-state-goals.png");
    });
  }
  saveCanvas2State() {
    //save to png
    const canvasSave = document.getElementById("stackPstate");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "current-gideon-year-state-goals.png");
    });
  }
  saveCanvas3State() {
    //save to png
    const canvasSave = document.getElementById("stackQstate");
    canvasSave.toBlob(function (blob) {
      saveAs(blob, "current-gideon-year-state-goals.png");
    });
  }
  componentDidMount() {
    // console.log(RmPaymentEngine);
    window.$('[data-toggle="popover"]').popover();

    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );

      this.getMemberType(user.type);
      this.setState(
        {
          ...user,
          user_status: this.getMemberStatus(user.status),
        },
        () => {
          if (this.state.type !== "S") {
            this.props.getAllMembers_camp();

            this.props.getAllCampActitivies().then((res) => {
              if (res.status === "success") {
                this.setState({ activity_total: res.payload.activities.total });
              }
            });

            this.props.getAllCampActitiviesNon().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_non: res.payload.activities.total,
                });
              }
            });
            this.props.getAllCampActitiviesCon().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_con: res.payload.conventions.total,
                });
              }
            });
            this.props.getAllCampActitiviesRec().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_rec: res.payload.activities.total,
                });
              }
            });
            this.props.getAllCampActitiviesMet().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_met: res.payload.activities.total,
                });
              }
            });
            this.props.getAllCampActitiviesLeg().then((res) => {
              if (res.status === "success") {
                this.setState({
                  activity_total_leg: res.payload.activities.total,
                });
              }
            });

            this.props.getGoalsStatistics({
              level: "camp",
              level_points: [this.state.camp_id],
            });
            this.props.getGoalsStatisticsArea({
              level: "area",
              level_points: [this.state?.camp?.area?.id],
            });

            this.props.getGoalsStatisticRegion({
              level: "region",
              level_points: [this.state?.camp?.area?.region?.id],
            });
            this.props.getGoalsStatisticState({
              level: "state",
              level_points: [this.state?.camp?.area?.region?.state?.id],
            });
          }
          // this.props.getAllCamps();
          // this.props.getAllAreas();
          // this.props.getAllRegions();
          if (this.state.type === "S") {
            this.props.getAllDelisted();
          }
          this.props.getAllStates();
          // this.props.getAllMembers();
          this.props.getAllUserCount();
          this.props.getAllMemberCount();
          // this.props.getAllUsers();
          // this.props.getAllStaffs();
          // this.props.getAllAuxilliaries();
          // this.props.getAllGideons();
          // this.props.getAllBibles();
          this.props.getPrayerBible();

          this.setState({
            num_days: this.getNumberDay(
              this.state.expiry_date,
              this.state.last_payment_date
            ),
          });
        }
      );
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.camps !== prevState.camps ||
      nextProps.camp_members !== prevState.camp_members ||
      nextProps.members !== prevState.members ||
      nextProps.delisted !== prevState.delisted ||
      nextProps.usersCount !== prevState.usersCount ||
      nextProps.staffs_1 !== prevState.staffs_1
    ) {
      return {
        camps: nextProps.camps,
        camp_members: nextProps.camp_members,
        members: nextProps.members,

        delisted: nextProps.delisted,
        usersCount: nextProps.usersCount,
        staffs_1: nextProps.staffs_1,
      };
    }
    return null;
  }

  componentWillUnmount() {
    window.$('[data-toggle="popover"]').popover("hide");
    const { resetAuthPermState } = this.props;
    resetAuthPermState();
  }
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  render() {
    let progessbar = null;
    const { message_perm, status_perm } = this.props;
    const { user_type } = this.state;
    if (this.state.num_days) {
      if (this.state.status === "GOOD") {
        progessbar = (
          <div className="progress " style={{ height: "5px" }}>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: `${this.state.num_days.per}%` }}
            />
          </div>
        );
      } else if (this.state.status === "DELI") {
        progessbar = (
          <div className="progress " style={{ height: "5px" }}>
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{ width: `${this.state.num_days.per}%` }}
            />
          </div>
        );
      } else if (this.state.status === "DROP") {
        progessbar = (
          <div className="progress " style={{ height: "5px" }}>
            <div
              className="progress-bar bg-danger"
              role="progressbar"
              style={{ width: `${this.state.num_days.per}%` }}
            />
          </div>
        );
      } else {
        progessbar = (
          <div className="progress " style={{ height: "5px" }}>
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{ width: `${this.state.num_days.per}%` }}
            />
          </div>
        );
      }
    }
    if (user_type === "G" || user_type === "A") {
      const { dashboard_goal_stats } = this.props;
      const { dashboard_goal_stats_area } = this.props;
      const { dashboard_goal_stats_region } = this.props;
      const { dashboard_goal_stats_state } = this.props;

      console.log(dashboard_goal_stats);

      return (
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          {message_perm && status_perm ? (
            <Message message={message_perm} status={status_perm} />
          ) : null}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1> My Dashboard</h1>
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* Main content */}
          <section className="content">
            {/* Default box */}

            <div className="row">
              <div className="col-md-12">
                <div className="card ">
                  <div className="card-header">
                    <h3 className="card-title">
                      <b>Camp No</b>
                      {this.state.camp ? (
                        <i>--{this.state.camp.camp_no}</i>
                      ) : (
                        ""
                      )}{" "}
                      &nbsp;
                      <b>Camp </b>
                      {this.state.camp ? (
                        <i>--{this.state.camp.name}</i>
                      ) : (
                        ""
                      )}{" "}
                      &nbsp;
                      <b>Area</b>{" "}
                      {this.state.camp ? (
                        <i>--{this.state.camp.area.name}</i>
                      ) : (
                        ""
                      )}{" "}
                      &nbsp;
                      <b>Region</b>{" "}
                      {this.state.camp ? (
                        <i>--{this.state.camp.area.region.name}</i>
                      ) : (
                        ""
                      )}{" "}
                      &nbsp;<b>State</b>{" "}
                      {this.state.camp ? (
                        <i>--{this.state.camp.area.region.state.name}</i>
                      ) : (
                        ""
                      )}
                    </h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        class="btn btn-tool"
                        data-card-widget="collapse"
                        title="Collapse"
                      >
                        <i class="fas fa-minus"></i>
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row d-flex justify-content-between">
                      <div>
                        <p className="lead">
                          Status:{" "}
                          <span className="font-italic">
                            {this.state.user_status}
                          </span>{" "}
                        </p>
                      </div>
                      <div>
                        <p className="lead">
                          Due Date:{" "}
                          <span className="font-italic">
                            {this.dateConv(this.state.expiry_date)}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                    {progessbar}
                  </div>
                  {/* /.card-body */}
                  <div className="card-footer text-center">
                    <Link
                      to="/personal/payments/renewal"
                      className="btn btn-warning btn-sm text-uppercase font-weight-bold "
                    >
                      Renew
                    </Link>
                  </div>
                  {/* /.card-footer*/}
                </div>
              </div>

              {this.props.prayer_bible && this.props.prayer_bible.length ? (
                <div className="col-md-12">
                  <div className="card collapsed-card">
                    <div className="card-header p-3">
                      <h3 className="card-title ">
                        <span>Daily Bible Verse</span>{" "}
                      </h3>

                      <div className="card-tools">
                        <h3 className="card-title ">
                          <span
                            data-toggle="modal"
                            data-target="#exampleModal"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            {
                              this.props.prayer_bible[0]?.bible_verse?.verse_1
                            }{" "}
                            {this.props.prayer_bible[0]?.bible_verse?.verse_2}
                          </span>
                        </h3>
                      </div>

                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                        data-backdrop="static"
                        data-keyboard="false"
                      >
                        <div className="modal-dialog" role="document">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Prayer Calendar
                              </h5>
                              <button
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">×</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <p style={{ color: "black", fontWeight: "bold" }}>
                                THE GIDEONS INTERNATIONAL IN NIGERIA
                              </p>
                              <p style={{ color: "black" }}>
                                TODAY'S DEVOTION.
                              </p>
                              <p style={{ color: "black", fontWeight: "bold" }}>
                                {" "}
                                {this.dateConv(
                                  this.props.prayer_bible[0]?.prayer_calendar
                                    ?.date ||
                                    this.props.prayer_bible[1]?.prayer_calendar
                                      ?.date
                                )}
                              </p>

                              {this.props.prayer_bible[0] ? (
                                <div>
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.props.prayer_bible[0]?.prayer_calendar?.category.toUpperCase()}{" "}
                                    PRAYER CALENDAR
                                  </p>
                                  <div className="d-flex justify-content-between">
                                    <p style={{ color: "black" }}>
                                      Text:-{" "}
                                      {
                                        this.props.prayer_bible[0]?.bible_verse
                                          ?.verse_1
                                      }
                                      <br />
                                      {
                                        this.props.prayer_bible[0]?.bible_verse
                                          ?.verse_2
                                      }
                                    </p>

                                    <img
                                      src={
                                        this.props.prayer_bible[0]
                                          ?.prayer_calendar?.avatar
                                      }
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </div>
                                  <p
                                    style={{
                                      color: "black",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {
                                      this.props.prayer_bible[0]
                                        ?.prayer_calendar?.location
                                    }
                                  </p>
                                  <p style={{ color: "black" }}>
                                    {
                                      this.props.prayer_bible[0]
                                        ?.prayer_calendar?.content
                                    }
                                  </p>
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {
                                      this.props.prayer_bible[0]
                                        ?.prayer_calendar?.verse
                                    }
                                  </p>
                                </div>
                              ) : null}

                              <br />

                              {this.props.prayer_bible[1] ? (
                                <div>
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {this.props.prayer_bible[1]?.prayer_calendar?.category.toUpperCase()}{" "}
                                    PRAYER CALENDAR
                                  </p>
                                  <div className="d-flex justify-content-between">
                                    <p style={{ color: "black" }}>
                                      Text:-{" "}
                                      {
                                        this.props.prayer_bible[1]?.bible_verse
                                          ?.verse_1
                                      }
                                      <br />
                                      {
                                        this.props.prayer_bible[1]?.bible_verse
                                          ?.verse_2
                                      }
                                    </p>

                                    <img
                                      src={
                                        this.props.prayer_bible[1]
                                          ?.prayer_calendar?.avatar
                                      }
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                      }}
                                    />
                                  </div>
                                  <p
                                    style={{
                                      color: "black",
                                      fontStyle: "italic",
                                    }}
                                  >
                                    {
                                      this.props.prayer_bible[1]
                                        ?.prayer_calendar?.location
                                    }
                                  </p>
                                  <p style={{ color: "black" }}>
                                    {
                                      this.props.prayer_bible[1]
                                        ?.prayer_calendar?.content
                                    }
                                  </p>
                                  <p
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {
                                      this.props.prayer_bible[1]
                                        ?.prayer_calendar?.verse
                                    }
                                  </p>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card-body"></div>
                  </div>
                </div>
              ) : null}
            </div>

            {/* /.card */}

            <div className="row mt-3">
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link
                  to="/personal/activities/pending"
                  className="small-box-footer"
                > */}
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>
                      {(this.state.activity_total
                        ? this.state.activity_total
                        : 0) +
                        (this.state.activity_total_non
                          ? this.state.activity_total_non
                          : 0) +
                        (this.state.activity_total_con
                          ? this.state.activity_total_con
                          : 0) +
                        (this.state.activity_total_rec
                          ? this.state.activity_total_rec
                          : 0) +
                        (this.state.activity_total_met
                          ? this.state.activity_total_met
                          : 0) +
                        (this.state.activity_total_leg
                          ? this.state.activity_total_leg
                          : 0)}
                    </h3>
                    <p> Activities </p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-globe" />
                  </div>

                  <button
                    className="small-box-footer btn-block border-0 stt "
                    data-toggle="popover"
                    data-placement="top"
                    title="Pending Activities"
                    data-content={`Scripture Distribution : ${
                      this.state.activity_total ? this.state.activity_total : 0
                    }, Camp Activities : ${
                      this.state.activity_total_non
                        ? this.state.activity_total_non
                        : 0
                    }, Recruitment Activity : ${
                      this.state.activity_total_rec
                        ? this.state.activity_total_rec
                        : 0
                    }, Camp Meetings : ${
                      this.state.activity_total_met
                        ? this.state.activity_total_met
                        : 0
                    }, Legacy/Bequest : ${
                      this.state.activity_total_leg
                        ? this.state.activity_total_leg
                        : 0
                    },Convention : ${
                      this.state.activity_total_con
                        ? this.state.activity_total_con
                        : 0
                    }  `}
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </button>
                </div>
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/personal/camps" className="small-box-footer"> */}
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{this.state.camps ? this.state.camps.length : 0}</h3>
                    <p>Camps</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-campground" />
                  </div>
                  <Link to="/personal/camps" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link
                  to="/personal/change-password"
                  className="small-box-footer"
                > */}
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>
                      {" "}
                      <i className="fas fa-lock-open" />
                    </h3>
                    <p>Change Password</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-lock" />
                  </div>
                  <Link
                    to="/personal/change-password"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/personal/camp-members" className="small-box-footer"> */}
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>
                      {this.state.camp_members
                        ? this.state.camp_members.length
                        : 0}
                    </h3>
                    <p>Camp Members</p>
                  </div>
                  <div className="icon">
                    {/* <i className="ion ion-contacts" /> */}
                    <i className="fas fa-users" />
                  </div>
                  <Link
                    to="/personal/camp-members"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
            </div>

            {hasPermission({
              mod: "Goals",
              action: "VIEW_CAMP_PERFORMANCE_CHART",
            }) ? (
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card card-outline card-primary">
                    <div className="card-header">
                      <h3 className="card-title"> Camp Goals</h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                      </div>
                      {/* /.card-tools */}
                    </div>
                    {/* /.card-header */}
                    <div className="card-body" style={{ display: "block" }}>
                      <div className="row ">
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {dashboard_goal_stats.previous_year} Gideon Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats?.proposed_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.proposed_prev[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats?.proposed_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.proposed_prev[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats?.proposed_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.proposed_prev[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats?.proposed_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.proposed_prev[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats?.actual_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.actual_prev[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats?.actual_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.actual_prev[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats?.actual_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.actual_prev[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats?.actual_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.actual_prev[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvas}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackD"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats?.proposed_prev,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats?.actual_prev,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>

                        <div
                          className="col-md-4  small-box p-3"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {" "}
                                {dashboard_goal_stats.current_year} Gideon Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats?.proposed &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.proposed[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats?.proposed &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.proposed[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats?.proposed &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.proposed[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats?.proposed &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.proposed[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats?.actual &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.actual[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats?.actual &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.actual[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats?.actual &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.actual[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats?.actual &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.actual[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvas2}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackP"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats?.proposed,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats?.actual,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>

                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {" "}
                                {dashboard_goal_stats.next_year} Gideon Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats?.proposed_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.proposed_next[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats?.proposed_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.proposed_next[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats?.proposed_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.proposed_next[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats?.proposed_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.proposed_next[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats?.actual_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.actual_next[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats?.actual_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats?.actual_next[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats?.actual_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.actual_next[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats?.actual_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats?.actual_next[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvas3}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackQ"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats?.proposed_next,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats?.actual_next,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_AREA_PERFORMANCE_CHART",
            }) ? (
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card card-outline card-primary">
                    <div className="card-header">
                      <h3 className="card-title"> Area Goals</h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                      </div>
                      {/* /.card-tools */}
                    </div>
                    {/* /.card-header */}
                    <div className="card-body" style={{ display: "block" }}>
                      <div className="row">
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          <div className="col-12">
                            <h5 className="text-center">
                              {dashboard_goal_stats_area.previous_year} Gideon
                              Year
                            </h5>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_area?.proposed_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area
                                          ?.proposed_prev[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_area?.proposed_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area
                                          ?.proposed_prev[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_area?.proposed_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area
                                          ?.proposed_prev[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_area?.proposed_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area
                                          ?.proposed_prev[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_area?.actual_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area
                                          ?.actual_prev[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_area?.actual_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area
                                          ?.actual_prev[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_area?.actual_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area
                                          ?.actual_prev[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_area?.actual_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area
                                          ?.actual_prev[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvasArea}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackDarea"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats_area?.proposed_prev,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats_area?.actual_prev,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {dashboard_goal_stats_area.current_year} Gideon
                                Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_area?.proposed &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area?.proposed[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_area?.proposed &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area?.proposed[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_area?.proposed &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area?.proposed[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_area?.proposed &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area?.proposed[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_area?.actual &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area?.actual[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_area?.actual &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area?.actual[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_area?.actual &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area?.actual[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_area?.actual &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area?.actual[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvas2Area}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackParea"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats_area?.proposed,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats_area?.actual,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {dashboard_goal_stats_area.next_year} Gideon
                                Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_area?.proposed_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area
                                          ?.proposed_next[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_area?.proposed_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area
                                          ?.proposed_next[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_area?.proposed_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area
                                          ?.proposed_next[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_area?.proposed_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area
                                          ?.proposed_next[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_area?.actual_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area
                                          ?.actual_next[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_area?.actual_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_area
                                          ?.actual_next[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_area?.actual_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area
                                          ?.actual_next[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_area?.actual_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_area
                                          ?.actual_next[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvas3Area}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackQarea"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats_area?.proposed_next,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats_area?.actual_next,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_REGION_PERFORMANCE_CHART",
            }) ? (
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card card-outline card-primary">
                    <div className="card-header">
                      <h3 className="card-title"> Region Goals</h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                      </div>
                      {/* /.card-tools */}
                    </div>
                    {/* /.card-header */}
                    <div className="card-body" style={{ display: "block" }}>
                      <div className="row">
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {dashboard_goal_stats_region.previous_year}{" "}
                                Gideon Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_region?.proposed_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region
                                          ?.proposed_prev[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_region?.proposed_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region
                                          ?.proposed_prev[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_region?.proposed_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region
                                          ?.proposed_prev[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_region?.proposed_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region
                                          ?.proposed_prev[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_region?.actual_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region
                                          ?.actual_prev[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_region?.actual_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region
                                          ?.actual_prev[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_region?.actual_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region
                                          ?.actual_prev[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_region?.actual_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region
                                          ?.actual_prev[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvasRegion}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackDregion"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats_region?.proposed_prev,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats_region?.actual_prev,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {dashboard_goal_stats_region.current_year}{" "}
                                Gideon Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_region?.proposed &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region?.proposed[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_region?.proposed &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region?.proposed[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_region?.proposed &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region?.proposed[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_region?.proposed &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region?.proposed[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_region?.actual &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region?.actual[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_region?.actual &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region?.actual[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_region?.actual &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region?.actual[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_region?.actual &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region?.actual[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvas2Region}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackPregion"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats_region?.proposed,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats_region?.actual,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {dashboard_goal_stats_region.next_year} Gideon
                                Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_region?.proposed_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region
                                          ?.proposed_next[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_region?.proposed_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region
                                          ?.proposed_next[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_region?.proposed_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region
                                          ?.proposed_next[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_region?.proposed_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region
                                          ?.proposed_next[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_region?.actual_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region
                                          ?.actual_next[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_region?.actual_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_region
                                          ?.actual_next[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_region?.actual_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region
                                          ?.actual_next[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_region?.actual_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_region
                                          ?.actual_next[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvas3Region}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackQregion"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats_region?.proposed_next,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats_region?.actual_next,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            ) : null}

            {hasPermission({
              mod: "Goals",
              action: "VIEW_STATE_PERFORMANCE_CHART",
            }) ? (
              <div className="row mt-3">
                <div className="col-md-12">
                  <div className="card card-outline card-primary">
                    <div className="card-header">
                      <h3 className="card-title"> State Goals</h3>
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool"
                          data-card-widget="collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                      </div>
                      {/* /.card-tools */}
                    </div>
                    {/* /.card-header */}
                    <div className="card-body" style={{ display: "block" }}>
                      <div className="row">
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {dashboard_goal_stats_state.previous_year}{" "}
                                Gideon Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_state?.proposed_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state
                                          ?.proposed_prev[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_state?.proposed_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state
                                          ?.proposed_prev[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_state?.proposed_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state
                                          ?.proposed_prev[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_state?.proposed_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state
                                          ?.proposed_prev[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_state?.actual_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state
                                          ?.actual_prev[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_state?.actual_prev &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state
                                          ?.actual_prev[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_state?.actual_prev &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state
                                          ?.actual_prev[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_state?.actual_prev &&
                                      dashboard_goal_stats_state
                                        ?.actual_prev[3]}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvasState}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackDstate"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats_state?.proposed_prev,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                  options: {
                                    tooltips: {
                                      intersect: false,
                                    },
                                  },
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats_state?.actual_prev,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#fff" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {dashboard_goal_stats_state.current_year} Gideon
                                Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_state?.proposed &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state?.proposed[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_state?.proposed &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state?.proposed[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_state?.proposed &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state?.proposed[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_state?.proposed &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state?.proposed[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_state?.actual &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state?.actual[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_state?.actual &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state?.actual[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_state?.actual &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state?.actual[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_state?.actual &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state?.actual[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvas2State}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackPstate"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats_state?.proposed,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats_state?.actual,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                        <div
                          className="col-md-4 small-box p-3"
                          style={{ backgroundColor: "#eee" }}
                        >
                          <div className="row">
                            <div className="col-12">
                              <h5 className="text-center">
                                {dashboard_goal_stats_state.next_year} Gideon
                                Year
                              </h5>
                            </div>
                            <div className="col-md-6">
                              <div class="card text-left">
                                <div class="card-body mb-0">
                                  <h6>AG (Annual Goal)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_state?.proposed_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state
                                          ?.proposed_next[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_state?.proposed_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state
                                          ?.proposed_next[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_state?.proposed_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state
                                          ?.proposed_next[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_state?.proposed_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state
                                          ?.proposed_next[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div class="card ">
                                <div class="card-body mb-0">
                                  <h6>YTD (Year to Date)</h6>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Admin Funds: </span>
                                    {dashboard_goal_stats_state?.actual_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state
                                          ?.actual_next[0]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture Funds: </span>
                                    {dashboard_goal_stats_state?.actual_next &&
                                      this.moneyConv(
                                        dashboard_goal_stats_state
                                          ?.actual_next[1]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Scripture: </span>
                                    {dashboard_goal_stats_state?.actual_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state
                                          ?.actual_next[2]
                                      )}
                                  </p>
                                  <p class="card-text d-flex justify-content-between mb-0">
                                    <span>Membership:</span>
                                    {dashboard_goal_stats_state?.actual_next &&
                                      this.numberWithCommas(
                                        dashboard_goal_stats_state
                                          ?.actual_next[3]
                                      )}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <Link to="#" onClick={this.saveCanvas3State}>
                            Download as PNG
                          </Link>

                          <Bar
                            id="stackQstate"
                            data={{
                              labels: [
                                "Admin Funds",
                                "Scripture Funds",
                                "Scripture",
                                "Membership",
                              ],
                              datasets: [
                                {
                                  label: "AG (Annual Goal)",
                                  data: dashboard_goal_stats_state?.proposed_next,
                                  backgroundColor: "rgba(0,123,225,0.5)",
                                  borderColor: "#007bff",
                                  borderWidth: 1,
                                },
                                {
                                  label: "YTD (Year to Date)",
                                  data: dashboard_goal_stats_state?.actual_next,
                                  backgroundColor: "rgba(220,53,69,0.5)",
                                  borderColor: "#dc3545",
                                  borderWidth: 1,
                                },
                              ],
                            }}
                            width={600}
                            height={400}
                          />
                        </div>
                      </div>
                    </div>
                    {/* /.card-body */}
                  </div>
                </div>
              </div>
            ) : null}
          </section>
          {/* /.content */}
        </div>
      );
    } else if (user_type === "S") {
      const { delisted, all_member_counts } = this.props;

      return (
        <div className="content-wrapper">
          {/* Content Header (Page header) */}
          {message_perm && status_perm ? (
            <Message message={message_perm} status={status_perm} />
          ) : null}
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1> Staff Dashboard</h1>
                </div>
                <div className="col-sm-6"></div>
              </div>
            </div>
            {/* /.container-fluid */}
          </section>
          {/* Main content */}
          <section className="content">
            <div className="callout callout-info">
              {/* <h5><i class="fas fa-info"></i> Note:</h5> */}
              <p className="lead">
                Welcome back {this.state.last_name || " "}{" "}
                {this.state.first_name}, your last login was{" "}
                <i>
                  {this.state.last_login ? (
                    <Moment format="MMMM Do YYYY, h:mm:ss a">
                      {this.state.last_login}
                    </Moment>
                  ) : null}
                </i>
              </p>
            </div>

            <div className="row mt-5">
              <div className="col-lg-3 col-6">
                {/* <Link to="/staffs" className="small-box-footer"> */}
                {/* small box */}
                <div className="small-box bg-info">
                  <div className="inner">
                    <h3>
                      {this.state.staffs_1 ? this.state.staffs_1.length : 0}
                    </h3>
                    <p> Staffs</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-globe" />
                  </div>
                  <Link to="/staffs" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/personal/camps" className="small-box-footer"> */}
                <div className="small-box bg-success">
                  <div className="inner">
                    <h3>{this.state.camps ? this.state.camps.length : 0}</h3>
                    <p>Camps</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-campground" />
                  </div>
                  <Link to="/personal/camps" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link
                  to="/personal/change-password"
                  className="small-box-footer"
                > */}
                <div className="small-box bg-warning">
                  <div className="inner">
                    <h3>
                      {" "}
                      <i className="fas fa-lock-open" />
                    </h3>
                    <p>Change Password</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-lock" />
                  </div>
                  <Link
                    to="/personal/change-password"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-danger">
                  <div className="inner">
                    <h3>{all_member_counts?.all_members || 0}</h3>

                    <p> All Members</p>
                  </div>
                  <div className="icon">
                    {/* <i className="ion ion-contacts" /> */}
                    <i className="fas fa-users" />
                  </div>
                  <Link to="/members" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-light">
                  <div className="inner">
                    <h3>{all_member_counts?.dropped_members?.delisted || 0}</h3>
                    <p> Delisted</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-trash" />
                  </div>
                  <Link to="/membership/delist" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-secondary">
                  <div className="inner">
                    <h3>{all_member_counts?.dropped_members?.unpaid || 0}</h3>
                    <p> Unpaid</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-money-bill"></i>
                  </div>
                  <Link to="/members/unpaid" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-primary">
                  <div className="inner">
                    <h3>{all_member_counts?.dropped_members?.rip || 0}</h3>
                    <p> With the Lord</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-dove"></i>
                  </div>
                  <Link
                    to="/members/with-the-lord"
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}

              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-maroon">
                  <div className="inner">
                    <h3>{all_member_counts?.active_members?.all || 0}</h3>
                    <p> Active Members</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-user-check"></i>
                  </div>
                  <Link to="members/active" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-fuchsia">
                  <div className="inner">
                    <h3>{all_member_counts?.membership_type?.annual || 0}</h3>
                    <p> Annual Members</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-user-check"></i>
                  </div>
                  <Link to="members/annual" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-indigo">
                  <div className="inner">
                    <h3>{all_member_counts?.membership_type?.life || 0}</h3>
                    <p> Life Members</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-user-check"></i>
                  </div>
                  <Link to="members/life" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
              {/* ./col */}
              <div className="col-lg-3 col-6">
                {/* small box */}
                {/* <Link to="/members" className="small-box-footer"> */}
                <div className="small-box bg-navy disabled">
                  <div className="inner">
                    <h3>{all_member_counts?.membership_type?.veteran || 0}</h3>
                    <p> Veteran Members</p>
                  </div>
                  <div className="icon">
                    <i className="fas fa-user-check"></i>
                  </div>
                  <Link to="members/veteran" className="small-box-footer">
                    More info <i className="fas fa-arrow-circle-right" />
                  </Link>
                </div>
                {/* </Link> */}
              </div>
              {/* ./col */}
            </div>
          </section>
          {/* /.content */}
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    message_perm: state.auth.message_perm,
    status_perm: state.auth.status_perm,
    camps: state.preferences.camps,
    camp_members: state.users.camp_members,
    members: state.users.members,
    all_member_counts: state.users.all_member_counts,
    delisted: state.users.delisted,
    users: state.users.users,
    usersCount: state.users.usersCount,
    staffs_1: state.users.staffs_1,
    bibles: state.preferences.bibles,
    gideons: state.users.gideons,
    prayer_bible: state.users.prayer_bible,
    auxilliaries: state.users.auxilliaries,
    dashboard_goal_stats: state.users.dashboard_goal_stats,
    dashboard_goal_stats_area: state.users.dashboard_goal_stats_area,
    dashboard_goal_stats_region: state.users.dashboard_goal_stats_region,
    dashboard_goal_stats_state: state.users.dashboard_goal_stats_state,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllCamps: () => dispatch(getAllCamps()),
    getAllAreas: () => dispatch(getAllAreas()),
    getAllRegions: () => dispatch(getAllRegions()),
    getAllStates: () => dispatch(getAllStates()),
    getAllMembers: () => dispatch(getAllMembers()),
    getAllMemberCount: () => dispatch(getAllMemberCount()),
    getAllDelisted: () => dispatch(getAllDelisted()),
    getAllMembers_camp: () => dispatch(getAllMembers_camp()),
    resetAuthPermState: () => dispatch(resetAuthPermState()),
    getAllCampActitivies: (page) => getAllCampActitivies(dispatch, page),
    getGoalsStatistics: (creds) => getGoalsStatistics(dispatch, creds),
    getGoalsStatisticsArea: (creds) => getGoalsStatisticsArea(dispatch, creds),
    getGoalsStatisticRegion: (creds) =>
      getGoalsStatisticRegion(dispatch, creds),
    getGoalsStatisticState: (creds) => getGoalsStatisticState(dispatch, creds),
    getAllCampActitiviesNon: (page) => getAllCampActitiviesNon(dispatch, page),
    getAllCampActitiviesCon: (page) => getAllCampActitiviesCon(dispatch, page),
    getAllCampActitiviesRec: (page) => getAllCampActitiviesRec(dispatch, page),
    getAllCampActitiviesMet: (page) => getAllCampActitiviesMet(dispatch, page),
    getAllCampActitiviesLeg: (page) => getAllCampActitiviesLeg(dispatch, page),
    getAllStaffs: () => dispatch(getAllStaffs()),
    getAllUsers: () => dispatch(getAllUsers()),
    getAllUserCount: () => dispatch(getAllUserCount()),
    getPrayerBible: () => dispatch(getPrayerBible()),
    getAllBibles: () => dispatch(getAllBibles()),
    getAllAuxilliaries: () => dispatch(getAllAuxilliaries()),
    getAllGideons: () => dispatch(getAllGideons()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
