import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import {
  getPendingAreaMessages,
  resetUsersState,
} from "./../../../../store/actions/usersActions";

import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import MiniSpinner from "../../../helpers/MiniSpinner";
import classnames from "classnames";
import Pagination from "react-js-pagination";
// import $ from "jquery";
import { storage_type, user_auth_details, dehash } from "./../../../../config";
import { useShallowEqualSelector } from "../../../../hooks";
import {
  useGetSystemRoles,
  useGetSystemCamps,
} from "../../../../store/hookactions/notificationsActionhooks";
// import summernote from "summernote";
import $ from "jquery";
import { updateCampMessage } from "../../../../store/actions/notificationActions";

function PendingAreaMessages(props) {
  const [state, setState] = useState({
    activePage: 1,
    modal_2: false,
    reason: "",
    selected_id: null,
  });

  const dispatch = useDispatch();
  const [enabled, setEnabled] = useState(true);
  const [cars_Info, setCarInfo] = useState({});
  const [title, setTitle] = useState("");
  const [all_chk_rol, setAllRoleChk] = useState(false);
  const [all_chk_camp, setAllCampChk] = useState(false);
  const [filtered_roles, setFilterRoles] = useState([]);
  const [filtered_camps, setFilterCamps] = useState([]);
  useGetSystemRoles(enabled, setEnabled);
  useGetSystemCamps(enabled, setEnabled);
  const roles = useShallowEqualSelector((state) => state.notifications.roles);
  const camps = useShallowEqualSelector((state) => state.preferences.camps);
  const spinner_preferences = useShallowEqualSelector(
    (state) => state.preferences.spinner
  );

  useEffect(() => {
    const { setPermissionsErrors, getPendingAreaMessages } = props;
    if (
      !hasPermission({
        mod: "Notification",
        action: "SEND_NOTIFICATION_TO_AREA",
      })
    ) {
      setPermissionsErrors();
      props.history.push("/");
    } else {
      //   initDependency();
      if (storage_type.getItem(user_auth_details)) {
        const user = JSON.parse(
          dehash(`${storage_type.getItem(user_auth_details)}`)
        );

        setCarInfo(user?.camp);
        var user_ = user?.camp;
      }
      let years_arry = [];
      const start_year = 2020;
      const end_year = new Date().getFullYear() + 2;
      for (let i = start_year; i < end_year; i++) {
        years_arry.push(i);
      }
      setState((prevState) => {
        return { ...prevState, years_arry };
      });
      const pageNumber = "";
      const year = "";
      const month = "";
      const day = "";
      const id = user_?.area?.id;

      getPendingAreaMessages(pageNumber, year, month, day, id).then((res) => {
        // alert("here");
        if (res.status === "success") {
          setState((prevState) => {
            console.log(res.payload);
            return { ...prevState, data: res.payload[0] };
          });
        }
      });
    }
  }, []);

  useEffect(() => {
    const PATTERN = "Area";
    const PATTERN1 = "Camp";
    const filtered = roles?.filter(function (str) {
      return str.name.includes(PATTERN) || str.name.includes(PATTERN1);
    });
    setFilterRoles(filtered);
  }, [roles]);

  useEffect(() => {
    const area_name = cars_Info?.area?.name;
    const filtered = camps?.filter((camp) => {
      return camp?.area?.name === area_name;
    });
    setFilterCamps(filtered);
  }, [camps, cars_Info]);

  function dateConv(date) {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  }

  function cancelSearch() {
    setState((prevState) => {
      return { ...prevState, year_pag: "", month_pag: "", day_pag: "" };
    });
    handleSearchChange("", { year_pag: "", month_pag: "", day_pag: "" });
  }
  function handleSearchChange(e, obj = null) {
    const pageNumber = 1;
    handlePageChange(pageNumber, obj);
  }
  function handleSearch(e) {
    e.preventDefault();
    // console.log({ [e.target?.id]: e.target.value });
    let newSate = { [e.target?.id]: e.target.value };
    setState((prevState) => {
      //   console.log(prevState);
      return { ...prevState, ...newSate };
    });
  }
  function handlePageChange(pageNumber, obj = null) {
    setState((prevState) => {
      return { ...prevState, activePage: pageNumber };
    });
    const { getPendingAreaMessages } = props;
    if (obj) {
      var year = obj.year_pag;
      var month = obj.month_pag;
      var day = obj.day_pag;
    } else {
      var year = state.year_pag;
      var month = state.month_pag;
      var day = state.day_pag;
    }
    const id = cars_Info?.area?.id;
    getPendingAreaMessages(pageNumber, year, month, day, id).then((res) => {
      if (res.status === "success") {
        setState((prevState) => {
          return { ...prevState, data: res.payload[0] };
        });
      }
    });
  }
  function hasErrorFor(field) {
    const { errors_noti, status_noti } = props;
    if (status_noti === "error") {
      return !!errors_noti[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    const { errors_noti, status_noti } = props;
    if (status_noti === "error") {
      if (errors_noti[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors_noti[field][0]}</strong>
          </span>
        );
      }
    }
  }
  function handleCheckRole() {
    setAllRoleChk((prev) => {
      if (!prev) {
        checkAllRoles();
      } else {
        removeAllRoles();
      }
      return !prev;
    });
  }
  function handleCheckCamp() {
    setAllCampChk((prev) => {
      if (!prev) {
        checkAllCamps();
      } else {
        removeAllCamps();
      }
      return !prev;
    });
  }

  function checkAllRoles() {
    const sel_role = [];
    filtered_roles.forEach((role) => {
      sel_role.push(role.id);
    });
    $("#role_id").val([...sel_role]);
    initDependency();
  }
  function removeAllRoles() {
    $("#role_id").val([]);
    initDependency();
  }
  function removeAllCamps() {
    $("#camp_id").val([]);
    initDependency();
  }
  function checkAllCamps() {
    const sel_camp = [];
    filtered_camps.forEach((camp) => {
      sel_camp.push(camp.id);
    });
    // console.log(sel_camp);
    $("#camp_id").val([...sel_camp]);
    initDependency();
  }

  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  function setView(id) {
    const view = state.data.find((meg) => parseInt(meg.id) === parseInt(id));

    setState((prev) => {
      return { ...prev, modal_2: true, view, selected_id: view.id };
    });
    // $("#title").val(view.title);
    setTitle(view.title);
    $("#birth_date").val(view.send_date);
    $("#role_id").val(JSON.parse(view.role_ids));
    $("#camp_id").val(JSON.parse(view.to_camp));
    console.log(JSON.parse(view.role_ids));

    // $("#compose_message").summernote("editor.pasteHTML", "<b>hello world</b>");
    initDependency();
    $("#compose_message").val(view.compose_message);
  }
  function handleSubmit(e) {
    e.preventDefault();
    const send_date = $("#birth_date").val();
    const compose_message = $("#compose_message").val();
    // const all_role = $("#all_role").is(":checked");
    let role_ids = Array.from($("#role_id").val());
    role_ids = role_ids.map((item) => parseInt(item, 10));
    let camp_ids = Array.from($("#camp_id").val());
    camp_ids = camp_ids.map((item) => parseInt(item, 10));
    const to_area = cars_Info.area.id;

    const creds = {
      title,
      role_ids,
      to_camp: camp_ids,
      compose_message,
      send_date,
      to_area: [to_area],
      type: "area",
      id: state.selected_id,
    };

    updateCampMessage(dispatch, creds).then((res) => {
      if (res?.status === "success") {
        const new_data = [...state.data];
        const id = state.selected_id;
        new_data.forEach((data, index) => {
          if (parseInt(data.id) === parseInt(id)) {
            new_data[index] = res.payload[0];
          }
        });

        setState((prev) => {
          return { ...prev, data: new_data };
        });
      }
    });
  }

  function closeModal() {
    setState((prev) => {
      return { ...prev, modal_2: false };
    });
    $("#closeModalDel").trigger("click");
    dispatch({ type: "CLEAR_USERS_ERRORS" });
    dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
  }

  useEffect(() => {
    return () => {
      dispatch({ type: "CLEAR_USERS_ERRORS" });
      dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
    };
  }, []);

  const { spinner, message, status, spinner_noti, message_noti, status_noti } =
    props;

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>NOTIFICATIONS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Pending Area Message</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold">
              {" "}
              Pending Area Messages{" "}
              <span className="lead">
                Area: <i>{cars_Info?.area?.name}</i>
              </span>
            </h3>
            <div className="card-tools"></div>
          </div>
          <div
            className="card-body"
            style={{
              // width: "100%"
              overflowX: "scroll",
            }}
          >
            <div
              className="modal fade"
              id="RemarkModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="RemarkModalLabel"
              aria-hidden="true"
              data-backdrop="static"
              data-keyboard="false"
            >
              <div className="modal-dialog modal-lg" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="RemarkModalLabel">
                      {/* Restore Membership */}
                    </h5>
                    <button onClick={closeModal}>
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <section className="content">
                      {/* Default box */}
                      <div className="card">
                        <div className="card-header">
                          <h3 className="card-title font-weight-bold ">
                            Edit Area Message{" "}
                            <span className="lead">
                              Area: <i>{cars_Info?.area?.name}</i>
                            </span>
                          </h3>
                          <div className="card-tools"></div>
                        </div>
                        <div className="card-body">
                          {spinner_noti ? <Spinner position="right" /> : null}
                          {status_noti && message_noti ? (
                            <Message
                              status={status_noti}
                              message={message_noti}
                            />
                          ) : null}
                          <form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="form-group col-md-6">
                                <label htmlFor="send_date">
                                  Send Date{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": hasErrorFor("send_date"),
                                    "form-control": true,
                                  })}
                                  id="birth_date"
                                  name="name"
                                  readOnly
                                  placeholder="Select date"
                                />
                                {renderErrorFor("send_date")}
                              </div>
                              <div className="form-group col-md-6">
                                <label htmlFor="title">
                                  Title <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  className={classnames({
                                    "is-invalid": hasErrorFor("title"),
                                    "form-control": true,
                                  })}
                                  id="title"
                                  name="title"
                                  value={title}
                                  onChange={(e) => setTitle(e.target.value)}
                                />
                                {renderErrorFor("title")}
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6 ">
                                <div className="row">
                                  <div
                                    className={classnames({
                                      "col-11": spinner === true,
                                      "col-12": spinner === false,
                                    })}
                                  >
                                    <label>
                                      Select Role{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      className={classnames({
                                        "form-control selected_role": true,
                                        memberselect2: true,
                                      })}
                                      placeholder="Select a Role"
                                      id="role_id"
                                      multiple="multiple"
                                    >
                                      <option value="">
                                        --Select a role--
                                      </option>
                                      {filtered_roles &&
                                        filtered_roles.map((role, index) => (
                                          <option key={role.id} value={role.id}>
                                            {role.name}
                                          </option>
                                        ))}
                                    </select>

                                    {renderErrorFor("role_ids")}
                                  </div>
                                  {spinner && (
                                    <div className="col-1 pt-4">
                                      <MiniSpinner />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group col-md-6 pt-4">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    type="checkbox"
                                    name="all_role"
                                    id="all_role"
                                    defaultChecked={all_chk_rol}
                                    onChange={handleCheckRole}
                                  />
                                  <label
                                    htmlFor="all_role"
                                    className="custom-control-label"
                                  >
                                    All Role
                                  </label>
                                </div>
                                <div>
                                  <i className="fa fa-info-circle text-info mr-1"></i>
                                  Check to send to all roles.
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="form-group col-md-6 ">
                                <div className="row">
                                  <div
                                    className={classnames({
                                      "col-11": spinner_preferences === true,
                                      "col-12": spinner_preferences === false,
                                    })}
                                  >
                                    <label>
                                      Select Camp{" "}
                                      <span className="text-danger">*</span>
                                    </label>
                                    <select
                                      className={classnames({
                                        "is-invalid": hasErrorFor("camp_ids"),
                                        "form-control selected_camp": true,
                                        memberselect22: true,
                                      })}
                                      placeholder="Select camp"
                                      id="camp_id"
                                      multiple="multiple"
                                    >
                                      <option value="">--Select camp--</option>
                                      {filtered_camps &&
                                        filtered_camps.map((camp, index) => (
                                          <option key={camp.id} value={camp.id}>
                                            {camp.name}
                                          </option>
                                        ))}
                                    </select>

                                    {renderErrorFor("camp_ids")}
                                  </div>
                                  {spinner && (
                                    <div className="col-1 pt-4">
                                      <MiniSpinner />
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className="form-group col-md-6 pt-4">
                                <div className="custom-control custom-checkbox">
                                  <input
                                    className="custom-control-input"
                                    type="checkbox"
                                    name="all_camps"
                                    id="all_camps"
                                    defaultChecked={all_chk_camp}
                                    onChange={handleCheckCamp}
                                  />
                                  <label
                                    htmlFor="all_camps"
                                    className="custom-control-label"
                                  >
                                    All Camps
                                  </label>
                                </div>
                                <div>
                                  <i className="fa fa-info-circle text-info mr-1"></i>
                                  Check to send to all camps.
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="card card-outline card-info">
                                  <div className="card-header">
                                    <h3 className="card-title">
                                      Compose message
                                    </h3>
                                    {/* tools box */}
                                    <div className="card-tools">
                                      <button
                                        type="button"
                                        className="btn btn-tool btn-sm"
                                        data-card-widget="collapse"
                                        data-toggle="tooltip"
                                        title="Collapse"
                                      >
                                        <i className="fas fa-minus" />
                                      </button>
                                    </div>
                                    {/* /. tools */}
                                  </div>
                                  {/* /.card-header */}
                                  <div className="card-body pad">
                                    <div className="mb-3">
                                      <textarea
                                        className="textarea "
                                        name="compose_message"
                                        id="compose_message"
                                        placeholder="Place some text here"
                                        style={{
                                          width: "100%",
                                          height: 400,
                                          fontSize: 14,
                                          lineHeight: 18,
                                          border: "1px solid #dddddd",
                                          padding: 10,
                                        }}
                                      />
                                      {renderErrorFor("compose_message")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <button
                              type="submit"
                              className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                              disabled={spinner_noti ? true : false}
                            >
                              Update
                              {spinner_noti && <MiniSpinner />}
                            </button>{" "}
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              id="closeModalDel"
                              data-dismiss="modal"
                              onClick={closeModal}
                            >
                              Close
                            </button>
                          </form>
                        </div>

                        {/* /.card-body */}
                      </div>
                      {/* /.card */}
                    </section>
                  </div>
                </div>
              </div>
            </div>

            {message && status ? (
              <Message message={message} status={status} />
            ) : null}
            {spinner ? (
              <div style={{ paddingBottom: "300px" }}>
                <Spinner />
              </div>
            ) : (
              <div key="uniqueKey">
                <div className="form-group col-md-3 d-flex">
                  <select
                    id="year_pag"
                    onChange={handleSearch}
                    defaultValue={state.year_pag || ""}
                  >
                    <option value="">Year</option>
                    {state.years_arry &&
                      state.years_arry.map((year) => (
                        <option key={year} value={year}>
                          {" "}
                          {year}
                        </option>
                      ))}
                  </select>
                  <select
                    id="month_pag"
                    onChange={handleSearch}
                    defaultValue={state.month_pag || ""}
                  >
                    <option value="">Month</option>
                    <option value="1">January</option>
                    <option value="2">February</option>
                    <option value="3">March</option>
                    <option value="4">April</option>
                    <option value="5">May</option>
                    <option value="6">June</option>
                    <option value="7">July</option>
                    <option value="8">August</option>
                    <option value="9">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                  </select>
                  <select
                    id="day_pag"
                    onChange={handleSearch}
                    defaultValue={state.day_pag || ""}
                  >
                    <option value="">Day</option>
                    <option value="1">1</option>
                    <option value="2">2</option>
                    <option value="3">3</option>
                    <option value="4">4</option>
                    <option value="5">5</option>
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8"> 8</option>
                    <option value="9"> 9</option>
                    <option value="10"> 10</option>
                    <option value="11"> 11</option>
                    <option value="12"> 12</option>
                    <option value="13"> 13</option>
                    <option value="14"> 14</option>
                    <option value="15"> 15</option>
                    <option value="16"> 16</option>
                    <option value="17"> 17</option>
                    <option value="18"> 18</option>
                    <option value="19"> 19</option>
                    <option value="20"> 20</option>
                    <option value="21"> 21</option>
                    <option value="22"> 22</option>
                    <option value="23"> 23</option>
                    <option value="24"> 24</option>
                    <option value="25"> 25</option>
                    <option value="26"> 26</option>
                    <option value="27"> 27</option>
                    <option value="28"> 28</option>
                    <option value="29"> 29</option>
                    <option value="30"> 30</option>
                    <option value="31"> 31</option>
                  </select>
                  <button onClick={handleSearchChange}>
                    <i className="fa fa-search text-primary"></i>
                  </button>
                  <button onClick={cancelSearch}>
                    <i className="fa fa-times text-danger"></i>
                  </button>
                </div>
                <div className="table responsive">
                  <table
                    className="table  table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      // overflowX:"scroll"
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Type</th>
                        <th>Created Date</th>
                        <th>Created By</th>

                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.data &&
                        state.data.map((item, index) => {
                          return (
                            <tr key={item.id}>
                              <td>{item.type.toUpperCase()}</td>
                              <td>{dateConv(item.created_at)}</td>
                              <td>
                                {item.created_by?.first_name}&nbsp;
                                {item.created_by?.last_name}&nbsp; [
                                {item.created_by?.login_id}]
                              </td>

                              <td>
                                <button
                                  className="btn btn-primary btn-xs"
                                  data-toggle="modal"
                                  data-target="#RemarkModal"
                                  onClick={() => {
                                    setView(item.id);
                                  }}
                                >
                                  <i className="fa fa-eye mr-1 mt-1 text-white"></i>
                                  View
                                </button>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="d-flex justify-content-center">
                  <Pagination
                    activePage={state.activePage}
                    itemsCountPerPage={state.per_page}
                    totalItemsCount={state.total}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </div>
              </div>
            )}
          </div>
          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    spinner_noti: state.notifications.spinner,
    message_noti: state.notifications.message,
    status_noti: state.notifications.status,
    errors_noti: state.notifications.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getPendingAreaMessages: (page, year, month, day, id) =>
      getPendingAreaMessages(dispatch, page, year, month, day, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingAreaMessages);
