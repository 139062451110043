import React from "react";
import { Link } from "react-router-dom";
// import { hasPermission } from "./../../../helpers/functions/functions";
const RoleRow = props => {
  const { role, deleteRole } = props;
  return (
    <React.Fragment>
      <tr>
        <td>
          {role.name}{" "}
          {role.default === "1" ? (
            <i>
              <span className="badge badge-primary p-1">Default</span>
            </i>
          ) : null}
        </td>
        <td>{role.users_count}</td>
        <td>
          <span
            style={{
              overflow: "visible",
              position: "relative",
              width: "110px"
            }}
          >
            <Link
              to={`/preferences/roles-and-permissions/edit/${role.id}`}
              className="btn btn-sm btn-clean btn-icon btn-icon-md"
              title="Edit Role"
            >
              <i className="fa fa-edit"></i>
            </Link>

            <Link
              to="#!"
              className="btn btn-sm btn-clean btn-icon btn-icon-md"
              title="Delete Role"
              onClick={e => {
                deleteRole(e, role.id);
              }}
            >
              <i className="fa fa-trash"></i>
            </Link>

            <Link
              to={`/preferences/roles-and-permissions/${role.id}`}
              className="btn btn-sm btn-clean btn-icon btn-icon-md"
              title="View More Details"
            >
              <i className="fa fa-eye"></i>
            </Link>
          </span>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default RoleRow;
