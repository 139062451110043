import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getStateMemberReport,
  resetReportState,
  stateRegionMember,
  stateAreaMember,
} from "./../../../../store/actions/reportActions";
import {
  setAll,
  getAllRegions,
  getAllAreas,
  getAllCamps,
  selectCamp,
} from "../../../../store/actions/preferencesActions";

import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import classnames from "classnames";
import MiniSpinner from "./../../../helpers/MiniSpinner";
import $ from "jquery";
// import _ from "underscore";
import { BASE_URL, token, storage_type } from "../../../../config";
// import { hasPermission } from "./../../helpers/functions/functions";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";

class StateMemberReport extends Component {
  state = {
    user_state: null,
    s_campFromArea: null,
    mem_state_area: null,
    mem_state_region: null,
    mem_state: null,
    state_id: "",
    region_id: "",
    area_id: "",
    camp_id: "",
    is_state: false,
    is_region: false,
    is_area: false,
    is_camp: false,
    // arranged_data: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REPORTS",
      }) ||
      !hasPermission({
        mod: "Reports",
        action: "VIEW_STATE_MEMBERSHIP_REPORT",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    document.title =
      "The Gideon International In Nigeria (State Membership Report)";
    this.initDependency();
    // const { getStateMemberReport } = this.props;
    // getStateMemberReport(1, "12/12/2020", "15/12/2020");
    // this.props.getAllCamps();
    this.props.setAll();
    // this.props.getAllCamps();
    // this.props.getAllAreas();
    // this.props.getAllRegions();
  }
  initDependency = () => {
    // alert("here");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.mem_state_area !== prevState.mem_state_area ||
      nextProps.s_campFromArea !== prevState.s_campFromArea ||
      nextProps.mem_state_region !== prevState.mem_state_region ||
      nextProps.user_state !== prevState.user_state ||
      nextProps.mem_state !== prevState.mem_state
    ) {
      // const arry = [];
      // const temp_mem_state = [...nextProps.mem_state];
      // if (temp_mem_state.length) {
      //   const camp_group = _.groupBy(temp_mem_state, "state");
      //   for (let [key, value] of Object.entries(camp_group)) {
      //     arry.push({
      //       state: key,
      //       fund: _.groupBy(value, "purpose"),
      //     });
      //   }
      //   // console.log(arry);
      //   // this.updateState(arry);
      // }

      return {
        s_campFromArea: nextProps.s_campFromArea,
        mem_state_area: nextProps.mem_state_area,
        mem_state_region: nextProps.mem_state_region,
        user_state: nextProps.user_state,
        mem_state: nextProps.mem_state,
      };
    }
    return null;
  }
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  handleDownload = (e) => {
    e.preventDefault();
    const from = $("#from_date").val();
    const to = $("#to_date").val();
    const state = this.state.state_id;
    const tok = storage_type.getItem(token);
    const download = 1;
    const url = `${BASE_URL}/reports/user_state/funds?from=${from}&to=${to}&state=${state}&download=${download}&token=${tok}`;
    window.open(url, "_blank");
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const from_date = $("#from_date").val();
    const to_date = $("#to_date").val();
    // const id = $(".memberselect22").val();
    document.title = `The Gideon International In Nigeria (State Membership Report ${from_date}---${to_date})`;

    const state = this.state.state_id;
    const region = this.state.region_id;
    const area = this.state.area_id;
    const camp = this.state.camp_id;
    // alert(area);
    this.setState(
      {
        from_date,
        to_date,
        is_state: true,
        is_region: false,
        is_area: false,
        is_camp: false,
        // id
      },
      () => {
        this.props.getStateMemberReport(
          from_date,
          to_date,
          state,
          region,
          area,
          camp
        );
      }
    );
  };
  stateRegionMember = (e, region_id) => {
    e.preventDefault();
    this.setState(
      {
        is_state: false,
        is_region: true,
        is_area: false,
        is_camp: false,
        region_id,
      },
      () => {
        const { from_date, to_date, state_id, region_id } = this.state;
        this.props.stateRegionMember(from_date, to_date, state_id, region_id);
      }
    );
  };
  stateAreaMember = (e, area_id) => {
    e.preventDefault();
    this.setState(
      {
        is_state: false,
        is_region: false,
        is_area: true,
        is_camp: false,
        area_id,
      },
      () => {
        const { from_date, to_date, state_id, area_id } = this.state;
        this.props.stateAreaMember(from_date, to_date, state_id, area_id);
      }
    );
  };

  // arrangeReport = () => {};
  // updateState = arry => {
  //   const dd = arry;
  //   setTimeout(() => {
  //     this.setState({ new_arry: dd });
  //   }, 1000);
  // };
  setDownload = (e) => {
    e.preventDefault();
    $(".fund_table").attr("id", "role_table22");
    this.initDependency();
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  dateConv = (date) => {
    return date ? new Date(date).toDateString() : null;
  };
  componentWillUnmount() {
    this.props.resetReportState();
  }
  render() {
    const { spinner, message, status, preference_spinner } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    // if (this.state.mem_state) {
    //   this.arrangeReport();
    // }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>REPORT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Membership Report</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Membership Report</h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "3000px" }}>
                  <Spinner />
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row  ">
                  <div className="col-12 text-center">
                    <p>
                      Ensure your selected date range falls within one Gideon
                      year.
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="from_date">
                        From Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("from_date"),
                          "form-control": true,
                        })}
                        id="from_date"
                        name="from_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("from_date")}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="to_date">
                        To Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("to_date"),
                          "form-control": true,
                        })}
                        id="to_date"
                        name="to_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("to_date")}
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="camp_id">
                        Camp <span className="text-danger">*</span>{" "}
                      </label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%"
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.camps &&
                            this.state.camps.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                        </select>
                        {preference_spinner ? <MiniSpinner /> : null}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div> */}
                  <div className=" col-sm-4">
                    <label>State</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("state_id"),
                          "form-control selected_area": true,
                        })}
                        onChange={this.handleChange}
                        id="state_id"
                        // defaultValue={this.state.state_id}
                      >
                        <option value="">--Select a State--</option>
                        {this.state.user_state &&
                          this.state.user_state.map((state, index) => (
                            <option key={index} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor("state_id")}
                  </div>

                  {/* {this.state.mem_state.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}
                </div>
                <div className="row mb-3">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    disabled={spinner ? true : false}
                  >
                    Generate
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>

                  {/* <button
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button> */}
                  {/* {this.state.mem_state.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}
                </div>
              </form>

              {this.state.mem_state.length &&
              !spinner &&
              this.state.is_state ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        {/* <th>Camp</th>
                        <th>Area</th> */}
                        <th>Region</th>
                        <th>State</th>
                        <th>Total Gideons </th>
                        <th>Total Auxilliaries </th>
                        <th>Active Gideons </th>
                        <th>Active Auxilliaries </th>
                        <th>Dropped Gideons </th>
                        <th>Dropped Auxilliaries </th>
                        <th>New Gideons</th>
                        <th>New Auxilliaries </th>
                        <th>Reinstated Gideons </th>
                        <th>Reinstated Auxilliaries </th>
                        <th>Delisted Gideons </th>
                        <th>Delisted Auxilliaries </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.mem_state &&
                        this.state.mem_state.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                {/* <td>{item.camp}</td>
                                <td>{item.area}</td> */}
                                <td>
                                  <Link
                                    to="#!"
                                    onClick={(e) =>
                                      this.stateRegionMember(e, item.id)
                                    }
                                  >
                                    {item.region}
                                  </Link>
                                </td>
                                <td>{item.state}</td>
                                <td>
                                  {(parseFloat(item.active.gideon) || 0) +
                                    (parseFloat(item.dropped.gideon) || 0) +
                                    (parseFloat(
                                      item.new_members.total_gideon
                                    ) || 0) +
                                    (parseFloat(item.reinstated.gideon) || 0)}
                                </td>
                                <td>
                                  {(parseFloat(item.active.aux) || 0) +
                                    (parseFloat(item.dropped.aux) || 0) +
                                    (parseFloat(item.new_members.total_aux) ||
                                      0) +
                                    (parseFloat(item.reinstated.aux) || 0)}
                                </td>
                                <td>{item.active.gideon || 0}</td>
                                <td>{item.active.aux || 0}</td>
                                <td>{item.dropped.gideon || 0}</td>
                                <td>{item.dropped.aux || 0}</td>
                                <td>{item.new_members.total_gideon || 0}</td>
                                <td>{item.new_members.total_aux || 0}</td>
                                <td>{item.reinstated.gideon || 0}</td>
                                <td>{item.reinstated.aux || 0}</td>
                                <td>{item.actual_delisted.gideon || 0}</td>
                                <td>{item.actual_delisted.aux || 0}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}

                      <tr>
                        <th>Total</th>
                        <th></th>
                        <th>
                          {(this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.active.gideon)
                                  ? parseFloat(obj.active.gideon)
                                  : 0)
                              );
                            }, 0)) +
                            (this.state.mem_state &&
                              this.state.mem_state.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.gideon)
                                    ? parseFloat(obj.dropped.gideon)
                                    : 0)
                                );
                              }, 0)) +
                            (this.state.mem_state &&
                              this.state.mem_state.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_gideon)
                                    ? parseFloat(obj.new_members.total_gideon)
                                    : 0)
                                );
                              }, 0)) +
                            (this.state.mem_state &&
                              this.state.mem_state.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.gideon)
                                    ? parseFloat(obj.reinstated.gideon)
                                    : 0)
                                );
                              }, 0))}
                        </th>
                        <th>
                          {(this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.active.aux)
                                  ? parseFloat(obj.active.aux)
                                  : 0)
                              );
                            }, 0)) +
                            (this.state.mem_state &&
                              this.state.mem_state.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.aux)
                                    ? parseFloat(obj.dropped.aux)
                                    : 0)
                                );
                              }, 0)) +
                            (this.state.mem_state &&
                              this.state.mem_state.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_aux)
                                    ? parseFloat(obj.new_members.total_aux)
                                    : 0)
                                );
                              }, 0)) +
                            (this.state.mem_state &&
                              this.state.mem_state.reduce(function (acc, obj) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.aux)
                                    ? parseFloat(obj.reinstated.aux)
                                    : 0)
                                );
                              }, 0))}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.active.gideon)
                                  ? parseFloat(obj.active.gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.active.aux)
                                  ? parseFloat(obj.active.aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.dropped.gideon)
                                  ? parseFloat(obj.dropped.gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.dropped.aux)
                                  ? parseFloat(obj.dropped.aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.new_members.total_gideon)
                                  ? parseFloat(obj.new_members.total_gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.new_members.total_aux)
                                  ? parseFloat(obj.new_members.total_aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.reinstated.gideon)
                                  ? parseFloat(obj.reinstated.gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.reinstated.aux)
                                  ? parseFloat(obj.reinstated.aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.actual_delisted.gideon)
                                  ? parseFloat(obj.actual_delisted.gideon)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                        <th>
                          {this.state.mem_state &&
                            this.state.mem_state.reduce(function (acc, obj) {
                              return (
                                acc +
                                (parseFloat(obj.actual_delisted.aux)
                                  ? parseFloat(obj.actual_delisted.aux)
                                  : 0)
                              );
                            }, 0)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {this.state.mem_state_region &&
              !spinner &&
              this.state.is_region ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        {/* <th>Camp</th> */}
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Total Gideons </th>
                        <th>Total Auxilliaries </th>
                        <th>Active Gideons </th>
                        <th>Active Auxilliaries </th>
                        <th>Dropped Gideons </th>
                        <th>Dropped Auxilliaries </th>
                        <th>New Gideons</th>
                        <th>New Auxilliaries </th>
                        <th>Reinstated Gideons </th>
                        <th>Reinstated Auxilliaries </th>
                        <th>Delisted Gideons </th>
                        <th>Delisted Auxilliaries </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.mem_state_region &&
                        this.state.mem_state_region.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                {/* <td>{item.camp}</td> */}
                                <td>
                                  <Link
                                    to="#!"
                                    onClick={(e) =>
                                      this.stateAreaMember(e, item.id)
                                    }
                                  >
                                    {item.area}
                                  </Link>
                                </td>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>
                                  {(parseFloat(item.active.gideon) || 0) +
                                    (parseFloat(item.dropped.gideon) || 0) +
                                    (parseFloat(
                                      item.new_members.total_gideon
                                    ) || 0) +
                                    (parseFloat(item.reinstated.gideon) || 0)}
                                </td>
                                <td>
                                  {(parseFloat(item.active.aux) || 0) +
                                    (parseFloat(item.dropped.aux) || 0) +
                                    (parseFloat(item.new_members.total_aux) ||
                                      0) +
                                    (parseFloat(item.reinstated.aux) || 0)}
                                </td>
                                <td>{item.active.gideon || 0}</td>
                                <td>{item.active.aux || 0}</td>
                                <td>{item.dropped.gideon || 0}</td>
                                <td>{item.dropped.aux || 0}</td>
                                <td>{item.new_members.total_gideon || 0}</td>
                                <td>{item.new_members.total_aux || 0}</td>
                                <td>{item.reinstated.gideon || 0}</td>
                                <td>{item.reinstated.aux || 0}</td>
                                <td>{item.actual_delisted.gideon || 0}</td>
                                <td>{item.actual_delisted.aux || 0}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}

                      <tr>
                        <th>Total</th>
                        <th></th>
                        <th>
                          <button
                            type="button"
                            onClick={this.handleSubmit}
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                            disabled={spinner ? true : false}
                          >
                            Back
                          </button>
                        </th>
                        <th>
                          {(this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.active.gideon)
                                  ? parseFloat(obj.active.gideon)
                                  : 0)
                              );
                            },
                            0)) +
                            (this.state.mem_state_region &&
                              this.state.mem_state_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.gideon)
                                    ? parseFloat(obj.dropped.gideon)
                                    : 0)
                                );
                              },
                              0)) +
                            (this.state.mem_state_region &&
                              this.state.mem_state_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_gideon)
                                    ? parseFloat(obj.new_members.total_gideon)
                                    : 0)
                                );
                              },
                              0)) +
                            (this.state.mem_state_region &&
                              this.state.mem_state_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.gideon)
                                    ? parseFloat(obj.reinstated.gideon)
                                    : 0)
                                );
                              },
                              0))}
                        </th>
                        <th>
                          {(this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.active.aux)
                                  ? parseFloat(obj.active.aux)
                                  : 0)
                              );
                            },
                            0)) +
                            (this.state.mem_state_region &&
                              this.state.mem_state_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.aux)
                                    ? parseFloat(obj.dropped.aux)
                                    : 0)
                                );
                              },
                              0)) +
                            (this.state.mem_state_region &&
                              this.state.mem_state_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_aux)
                                    ? parseFloat(obj.new_members.total_aux)
                                    : 0)
                                );
                              },
                              0)) +
                            (this.state.mem_state_region &&
                              this.state.mem_state_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.aux)
                                    ? parseFloat(obj.reinstated.aux)
                                    : 0)
                                );
                              },
                              0))}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.active.gideon)
                                  ? parseFloat(obj.active.gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.active.aux)
                                  ? parseFloat(obj.active.aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.dropped.gideon)
                                  ? parseFloat(obj.dropped.gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.dropped.aux)
                                  ? parseFloat(obj.dropped.aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.new_members.total_gideon)
                                  ? parseFloat(obj.new_members.total_gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.new_members.total_aux)
                                  ? parseFloat(obj.new_members.total_aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.reinstated.gideon)
                                  ? parseFloat(obj.reinstated.gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.reinstated.aux)
                                  ? parseFloat(obj.reinstated.aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.actual_delisted.gideon)
                                  ? parseFloat(obj.actual_delisted.gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_region &&
                            this.state.mem_state_region.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.actual_delisted.aux)
                                  ? parseFloat(obj.actual_delisted.aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {this.state.mem_state_area && !spinner && this.state.is_area ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Total Gideons </th>
                        <th>Total Auxilliaries </th>
                        <th>Active Gideons </th>
                        <th>Active Auxilliaries </th>
                        <th>Dropped Gideons </th>
                        <th>Dropped Auxilliaries </th>
                        <th>New Gideons</th>
                        <th>New Auxilliaries </th>
                        <th>Reinstated Gideons </th>
                        <th>Reinstated Auxilliaries </th>
                        <th>Delisted Gideons </th>
                        <th>Delisted Auxilliaries </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.mem_state_area &&
                        this.state.mem_state_area.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.camp}</td>
                                <td>{item.area}</td>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>
                                  {(parseFloat(item.active.gideon) || 0) +
                                    (parseFloat(item.dropped.gideon) || 0) +
                                    (parseFloat(
                                      item.new_members.total_gideon
                                    ) || 0) +
                                    (parseFloat(item.reinstated.gideon) || 0)}
                                </td>
                                <td>
                                  {(parseFloat(item.active.aux) || 0) +
                                    (parseFloat(item.dropped.aux) || 0) +
                                    (parseFloat(item.new_members.total_aux) ||
                                      0) +
                                    (parseFloat(item.reinstated.aux) || 0)}
                                </td>
                                <td>{item.active.gideon || 0}</td>
                                <td>{item.active.aux || 0}</td>
                                <td>{item.dropped.gideon || 0}</td>
                                <td>{item.dropped.aux || 0}</td>
                                <td>{item.new_members.total_gideon || 0}</td>
                                <td>{item.new_members.total_aux || 0}</td>
                                <td>{item.reinstated.gideon || 0}</td>
                                <td>{item.reinstated.aux || 0}</td>
                                <td>{item.actual_delisted.gideon || 0}</td>
                                <td>{item.actual_delisted.aux || 0}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}

                      <tr>
                        <th>Total</th>
                        <th></th>
                        <th></th>
                        <th>
                          <button
                            type="button"
                            onClick={(e) =>
                              this.stateRegionMember(e, this.state.region_id)
                            }
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                            disabled={spinner ? true : false}
                          >
                            Back
                          </button>
                        </th>
                        <th>
                          {(this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.active.gideon)
                                  ? parseFloat(obj.active.gideon)
                                  : 0)
                              );
                            },
                            0)) +
                            (this.state.mem_state_area &&
                              this.state.mem_state_area.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.gideon)
                                    ? parseFloat(obj.dropped.gideon)
                                    : 0)
                                );
                              },
                              0)) +
                            (this.state.mem_state_area &&
                              this.state.mem_state_area.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_gideon)
                                    ? parseFloat(obj.new_members.total_gideon)
                                    : 0)
                                );
                              },
                              0)) +
                            (this.state.mem_state_area &&
                              this.state.mem_state_area.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.gideon)
                                    ? parseFloat(obj.reinstated.gideon)
                                    : 0)
                                );
                              },
                              0))}
                        </th>
                        <th>
                          {(this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.active.aux)
                                  ? parseFloat(obj.active.aux)
                                  : 0)
                              );
                            },
                            0)) +
                            (this.state.mem_state_area &&
                              this.state.mem_state_area.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.dropped.aux)
                                    ? parseFloat(obj.dropped.aux)
                                    : 0)
                                );
                              },
                              0)) +
                            (this.state.mem_state_area &&
                              this.state.mem_state_area.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.new_members.total_aux)
                                    ? parseFloat(obj.new_members.total_aux)
                                    : 0)
                                );
                              },
                              0)) +
                            (this.state.mem_state_area &&
                              this.state.mem_state_area.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  (parseFloat(obj.reinstated.aux)
                                    ? parseFloat(obj.reinstated.aux)
                                    : 0)
                                );
                              },
                              0))}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.active.gideon)
                                  ? parseFloat(obj.active.gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.active.aux)
                                  ? parseFloat(obj.active.aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.dropped.gideon)
                                  ? parseFloat(obj.dropped.gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.dropped.aux)
                                  ? parseFloat(obj.dropped.aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.new_members.total_gideon)
                                  ? parseFloat(obj.new_members.total_gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.new_members.total_aux)
                                  ? parseFloat(obj.new_members.total_aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.reinstated.gideon)
                                  ? parseFloat(obj.reinstated.gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.reinstated.aux)
                                  ? parseFloat(obj.reinstated.aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.actual_delisted.gideon)
                                  ? parseFloat(obj.actual_delisted.gideon)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {this.state.mem_state_area &&
                            this.state.mem_state_area.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.actual_delisted.aux)
                                  ? parseFloat(obj.actual_delisted.aux)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.reports.spinner,
    message: state.reports.message,
    status: state.reports.status,
    errors: state.reports.errors,
    mem_state: state.reports.mem_state,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
    user_state: state.preferences.user_state,
    s_campFromArea: state.preferences.s_campFromArea,
    mem_state_area: state.reports.mem_state_area,
    mem_state_region: state.reports.mem_state_region,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStateMemberReport: (from, to, state, region, area, camp) =>
      dispatch(getStateMemberReport(from, to, state, region, area, camp)),
    // getAllCamps: () => dispatch(getAllCamps()),
    setAll: () => dispatch(setAll()),
    getAllRegions: () => dispatch(getAllRegions()),
    getAllAreas: () => dispatch(getAllAreas()),
    getAllCamps: () => dispatch(getAllCamps()),
    selectCamp: (id) => dispatch(selectCamp(id)),
    stateAreaMember: (from, to, state, area) =>
      dispatch(stateAreaMember(from, to, state, area)),
    stateRegionMember: (from, to, state, region) =>
      dispatch(stateRegionMember(from, to, state, region)),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetReportState: () => resetReportState(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StateMemberReport);
