import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    getTransferedCampScriptureRequest,
    resetPreferencesState,
    getAllDepot,
    transferRequestToDepot
} from "../../../../store/actions/preferencesActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import Pagination from "react-js-pagination";
import MiniSpinner from "../../../helpers/MiniSpinner";
import classnames from "classnames";

class TransferedCampScripture extends Component {
    state = {
        activePage: 1,
        modal: false,
        depots: null,
        comment: ""
    };
    componentDidMount() {
        const { setPermissionsErrors, getTransferedCampScriptureRequest } = this.props;
        if (
            !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
            !hasPermission({
                mod: "Preferences",
                action: "VIEW_SCRIPTURE_REQUISITION_FROM_CAMP"
            })
        ) {
            setPermissionsErrors();
            this.props.history.push("/");
        } else {
            let years_arry = [];
            const start_year = 2020;
            const end_year = new Date().getFullYear() + 2;
            for (let i = start_year; i < end_year; i++) {
                years_arry.push(i);
            }
            this.setState({ years_arry });

            getTransferedCampScriptureRequest().then(res => {
                if (res.status === "success") {
                    // console.log(res.payload);
                    this.setState({ ...res.payload.requests });
                }
            });
            this.props.getAllDepot();
        }
    }
    dateConv = date => {
        if (!date) {
            return null;
        }
        return new Date(date).toDateString();
    };
    cancelActivity = (e, id) => {
        e.preventDefault();
        if (window.confirm("Are you sure you cancel this camp activity?")) {
            this.setState({ modal_2: true, selected_id: id });
        }
    };
    closeModal_2 = e => {
        this.setState({ modal_2: false, reason: "" });
        this.props.resetUsersState();
    };
    cancelSearch = () => {
        this.setState(
            {
                year_pag: "",
                month_pag: "",
                day_pag: ""
            },
            () => {
                this.handleSearchChange();
            }
        );
    };
    handleSearchChange = () => {
        const pageNumber = 1;
        this.handlePageChange(pageNumber);
    };
    handleSearch = e => {
        e.preventDefault();
        this.setState({
            [e.target.id]: e.target.value
        });
    };
    handlePageChange = pageNumber => {
        this.setState({ activePage: pageNumber });
        const { getTransferedCampScriptureRequest } = this.props;
        const year = this.state.year_pag;
        const month = this.state.month_pag;
        const day = this.state.day_pag;
        getTransferedCampScriptureRequest(pageNumber, year, month, day).then(res => {
            if (res.status === "success") {
                this.setState({ ...res.payload.requests });
            }
        });
    };
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.depots !== prevState.depots) {
            return {
                depots: nextProps.depots
            };
        }
        return null;
    }
    hasErrorFor(field) {
        const { errors, status } = this.props;
        if (status === "error") {
            return !!errors[field];
        }
        return false;
    }
    renderErrorFor(field) {
        const { errors, status } = this.props;
        if (status === "error") {
            if (errors[field]) {
                return (
                    <span className="invalid-feedback">
                        <strong>{errors[field][0]}</strong>
                    </span>
                );
            }
        }
    }
    sendTransferRequest = e => {
        e.preventDefault();
        if (window.confirm("Are you sure you want to transfer this request?")) {
            const creds = {
                id: this.state.selected_id,
                comment: this.state.comment,
                depot_id: this.state.depot_id
            };
            this.props.transferRequestToDepot(creds).then(res => {
                if (res.status === "success") {
                    const tempdata = [...this.state.data];
                    this.setState({
                        data: tempdata.filter(data => data.id !== this.state.selected_id),
                        modal: false,
                        selected_id: "",
                        depot_id: "",
                        selected_request: "",
                        comment: ""
                    });
                }
            });
        }
    };
    // handleSubmit = e => {
    //   e.preventDefault();
    //   const creds = {
    //     id: this.state.selected_id,
    //     reason: this.state.reason
    //   };
    //   this.props.cancelCampActivity(creds).then(res => {
    //     if (res.status === "success") {
    //       const tempdata = [...this.state.data];
    //       this.setState({
    //         data: tempdata.filter(data => data.id !== creds.id),
    //         modal_2: false,
    //         selected_id: null,
    //         reason: ""
    //       });
    //     }
    //   });
    // };
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    transferRequest = (e, id) => {
        e.preventDefault();
        const selected_request = this.state.data.find(req => req.id === id);
        // const proposed_camp = selected_camp.proposed_camp;
        this.setState({ selected_id: id, modal: true, selected_request });
    };
    closeModal = e => {
        this.setState({
            modal: false,
            depot_id: "",
            selected_id: "",
            selected_request: ""
        });
        this.props.resetPreferencesState();
    };
    getStatus = status => {
        if (status === "P") {
            return "PENDING";
        } else if (status === "A") {
            return "AUTHORIZED";
        } else if (status === "R") {
            return "RECEIVED";
        } else if (status === "T") {
            return "TRANSFERRED";
        }
    };
    render() {
        const { spinner, message, status } = this.props;

        return (
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>PREFERENCES</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Transferred Camp Scripture Request
                  </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    {/* Default box */}
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title font-weight-bold">
                                {" "}
                                Transferred Camp Scripture Request
              </h3>
                            <div className="card-tools">
                                {/* {hasPermission({
                  mod: "Camp Administration",
                  action: "CREATE_CAMP_ACTIVITY"
                }) ? (
                  <Link to="/camp/activities/new">
                    <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                      <i className="fas fa-plus-circle mr-1"></i>
                      CREATE CAMP ACTIVITY
                    </button>
                  </Link>
                ) : null} */}
                            </div>
                        </div>
                        <div
                            className="card-body"
                            style={{
                                // width: "100%"
                                overflowX: "scroll"
                            }}
                        >
                            {this.state.modal ? (
                                <div
                                    //   className="card opp"
                                    //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                                    style={{
                                        position: "fixed",
                                        top: "0px",
                                        right: "0px",
                                        bottom: "0px",
                                        left: "0px",
                                        zIndex: 9999999,
                                        backgroundColor: "rgba(0,0,0,0.5)",
                                        padding: "10%",
                                        display: "flex",
                                        justifyContent: "center",

                                        alignItems: "center"

                                        // transition: "all 10s ease"
                                    }}
                                >
                                    <div
                                        className="card-body"
                                        style={{
                                            backgroundColor: "#fff",
                                            height: "400px"
                                        }}
                                    >
                                        <form onSubmit={this.handleSubmit}>
                                            <h4 className="text-center mt-3">Select Depot</h4>
                                            <div className="col-sm-6 offset-sm-3 ">
                                                <div className="form-group">
                                                    <label htmlFor="depot_id">
                                                        Depot <span className="text-danger">*</span>{" "}
                                                    </label>
                                                    <div className="d-flex">
                                                        <select
                                                            className={classnames({
                                                                "is-invalid": this.hasErrorFor("depot_id"),
                                                                "form-control": true
                                                            })}
                                                            placeholder="Select a depot"
                                                            onChange={this.handleChange}
                                                            value={this.state.depot_id}
                                                            id="depot_id"
                                                            name="depot_id"
                                                        >
                                                            <option value="">--Select a depot--</option>

                                                            {this.state.depots &&
                                                                this.state.depots.map((depot, index) => (
                                                                    <option key={index} value={depot.id}>
                                                                        {depot.name}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                        {/* {spinner ? <MiniSpinner /> : null} */}
                                                    </div>
                                                    {this.renderErrorFor("depot_id")}
                                                </div>
                                            </div>
                                            <div className="col-sm-6 offset-sm-3 ">
                                                <div className="form-group">
                                                    <label htmlFor="camp_id">Comment</label>
                                                    <div className="d-flex">
                                                        <textarea
                                                            name="comment"
                                                            className={classnames({
                                                                "is-invalid": this.hasErrorFor("comment"),
                                                                "form-control": true
                                                            })}
                                                            id="comment"
                                                            onChange={this.handleChange}
                                                            value={this.state.comment}
                                                        />
                                                    </div>
                                                    {this.renderErrorFor("comment")}
                                                </div>

                                                <button
                                                    type="button"
                                                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                                                    disabled={spinner ? true : false}
                                                    onClick={this.sendTransferRequest}
                                                >
                                                    Submit
                          {spinner ? <MiniSpinner color="white" /> : null}
                                                </button>
                                                <button
                                                    className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                                                    disabled={spinner ? true : false}
                                                    onClick={this.closeModal}
                                                >
                                                    Cancel
                        </button>
                                            </div>
                                        </form>
                                    </div>

                                    {/* /.card-body */}
                                </div>
                            ) : null}

                            {message && status ? (
                                <Message message={message} status={status} />
                            ) : null}
                            {spinner ? (
                                <div style={{ paddingBottom: "300px" }}>
                                    <Spinner />
                                </div>
                            ) : (
                                    <div key="uniqueKey">
                                        <div className="form-group col-md-3 d-flex">
                                            <select
                                                id="year_pag"
                                                onChange={this.handleSearch}
                                                defaultValue={this.state.year_pag || ""}
                                            >
                                                <option value="">Year</option>
                                                {this.state.years_arry &&
                                                    this.state.years_arry.map(year => (
                                                        <option key={year} value={year}>
                                                            {" "}
                                                            {year}
                                                        </option>
                                                    ))}
                                            </select>
                                            <select
                                                id="month_pag"
                                                onChange={this.handleSearch}
                                                defaultValue={this.state.month_pag || ""}
                                            >
                                                <option value="">Month</option>
                                                <option value="1">January</option>
                                                <option value="2">February</option>
                                                <option value="3">March</option>
                                                <option value="4">April</option>
                                                <option value="5">May</option>
                                                <option value="6">June</option>
                                                <option value="7">July</option>
                                                <option value="8">August</option>
                                                <option value="9">September</option>
                                                <option value="10">October</option>
                                                <option value="11">November</option>
                                                <option value="12">December</option>
                                            </select>
                                            <select
                                                id="day_pag"
                                                onChange={this.handleSearch}
                                                defaultValue={this.state.day_pag || ""}
                                            >
                                                <option value="">Day</option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                                <option value="4">4</option>
                                                <option value="5">5</option>
                                                <option value="6">6</option>
                                                <option value="7">7</option>
                                                <option value="8"> 8</option>
                                                <option value="9"> 9</option>
                                                <option value="10"> 10</option>
                                                <option value="11"> 11</option>
                                                <option value="12"> 12</option>
                                                <option value="13"> 13</option>
                                                <option value="14"> 14</option>
                                                <option value="15"> 15</option>
                                                <option value="16"> 16</option>
                                                <option value="17"> 17</option>
                                                <option value="18"> 18</option>
                                                <option value="19"> 19</option>
                                                <option value="20"> 20</option>
                                                <option value="21"> 21</option>
                                                <option value="22"> 22</option>
                                                <option value="23"> 23</option>
                                                <option value="24"> 24</option>
                                                <option value="25"> 25</option>
                                                <option value="26"> 26</option>
                                                <option value="27"> 27</option>
                                                <option value="28"> 28</option>
                                                <option value="29"> 29</option>
                                                <option value="30"> 30</option>
                                                <option value="31"> 31</option>
                                            </select>
                                            <button onClick={this.handleSearchChange}>
                                                <i className="fa fa-search text-primary"></i>
                                            </button>
                                            <button onClick={this.cancelSearch}>
                                                <i className="fa fa-times text-danger"></i>
                                            </button>
                                        </div>
                                        <div className="table responsive">
                                            <table
                                                id="state_table"
                                                className="table  table-hover table-bordered table-striped"
                                                style={{
                                                    width: "100%"
                                                    // overflowX:"scroll"
                                                }}
                                            >
                                                <thead>
                                                    <tr>
                                                        <th>Camp</th>
                                                        <th>Date Created</th>
                                                        <th> Depot</th>
                                                        <th>Status</th>
                                                        <th>View Details</th>
                                                        
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.data &&
                                                        this.state.data.map((item, index) => {
                                                            return (
                                                                <tr key={index}>
                                                                    <td>{item.camp.name}</td>
                                                                    <td>{this.dateConv(item.created_at)}</td>
                                                            <td>{item.depot.name}</td>
                                                            <td>{this.getStatus(item.status)}</td>
                                                                    <td>
                                                                        {" "}
                                                                        <Link
                                                                            to={`/preferences/camp-scripture-request/transfered/${item.id}`}
                                                                            className="btn btn-default btn-sm btn-clean btn-icon btn-icon-md "
                                                                        >
                                                                            <i className="fa fa-eye mr-1 mt-1"></i>
                                                                            View
                                  </Link>
                                                                    </td>
                                                              
                                                                </tr>
                                                            );
                                                        })}
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <Pagination
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={this.state.per_page}
                                                totalItemsCount={this.state.total}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                                itemClass="page-item"
                                                linkClass="page-link"
                                            />
                                        </div>
                                    </div>
                                )}
                        </div>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </section>
                {/* /.content */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        states: state.preferences.states,
        spinner: state.preferences.spinner,
        message: state.preferences.message,
        status: state.preferences.status,
        errors: state.preferences.errors,
        depots: state.preferences.depots
    };
};
const mapDispatchToProps = dispatch => {
    return {
        getTransferedCampScriptureRequest: (page, year, month, day) =>
            getTransferedCampScriptureRequest(dispatch, page, year, month, day),
        resetPreferencesState: () => resetPreferencesState(dispatch),
        getAllDepot: () => dispatch(getAllDepot()),
        setPermissionsErrors: () => dispatch(setPermissionsErrors()),
        transferRequestToDepot: creds => transferRequestToDepot(dispatch, creds)
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TransferedCampScripture);
