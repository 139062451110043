import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  createRelocationRequest,
  resetUsersState,
} from "../../../store/actions/usersActions";

// import Spinner from "../../helpers/Spinner";
import Message from "../../helpers/Message";
import { hasPermission } from "../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../store/actions/authActions";
import MiniSpinner from "../../helpers/MiniSpinner";
import { getAllCamps } from "../../../store/actions/preferencesActions";
import $ from "jquery";

// 10.10.1.227

class CreateRelocationRequest extends Component {
  state = {
    country: "",
    state: "",
    city: "",
    address: "",
    info: "",
    camps: "",
    proposed_camp: "",
    postal_code: "",
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD",
      }) ||
      !hasPermission({
        mod: "Personal Management",
        action: "CREATE_RELOCATION_REQUEST",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    // this.initDependency();
    this.props.getAllCamps();
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFileChange = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();

    const proposed_camp = $("#proposed_camp").val();
    this.props
      .createRelocationRequest({ ...this.state, proposed_camp })
      .then((res) => {
        if (res.status === "success") {
          this.setState({
            country: "",
            state: "",
            city: "",
            address: "",
            info: "",
            postal_code: "",
            proposed_camp: "",
          });
          $("#proposed_camp").val("");
        }
      });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.camps !== prevState.camps) {
      return {
        camps: nextProps.camps,
      };
    }
    return null;
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };

  render() {
    const { spinner, status, message, preference_spinner } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Personal</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create Relocation Request
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Create Relocation Request
              </h3>
              <div className="card-tools"></div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="card-body">
                {/* {spinner ? <Spinner position="right" /> : null} */}
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label htmlFor="country">
                        Country <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("country"),
                          "form-control": true,
                        })}
                        name="country"
                        id="country"
                        onChange={this.handleChange}
                        value={this.state.country}
                      />
                      {this.renderErrorFor("country")}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="state">
                        State <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("state"),
                          "form-control": true,
                        })}
                        name="state"
                        id="state"
                        onChange={this.handleChange}
                        value={this.state.state}
                      />
                      {this.renderErrorFor("state")}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="city">
                        City <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("city"),
                          "form-control": true,
                        })}
                        name="city"
                        id="city"
                        value={this.state.city}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("city")}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="address">
                          Address <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("address"),
                            "form-control": true,
                          })}
                          id="address"
                          name="address"
                          onChange={this.handleChange}
                          value={this.state.address}
                        />
                        {this.renderErrorFor("address")}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="postal_code">
                          Postal code/Zip code{" "}
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("postal_code"),
                            "form-control": true,
                          })}
                          id="postal_code"
                          name="postal_code"
                          onChange={this.handleChange}
                          value={this.state.postal_code}
                        />
                        {this.renderErrorFor("postal_code")}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="info">
                          Reason for relocation{" "}
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <textarea
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("info"),
                            "form-control": true,
                          })}
                          id="info"
                          name="info"
                          onChange={this.handleChange}
                          value={this.state.info}
                          required
                        />
                        {this.renderErrorFor("info")}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="proposed_camp">Proposed Camp </label>
                        <div className="d-flex">
                          <select
                            className={classnames({
                              "is-invalid": this.hasErrorFor("proposed_camp"),
                              "form-control": true,
                              memberselect2: true,
                            })}
                            placeholder="Select a Camp"
                            // onChange={this.handleChange}
                            // value={this.state.proposed_camp}
                            id="proposed_camp"
                            name="proposed_camp"
                          >
                            <option value="">--Select a camp--</option>
                            {this.state.camps &&
                              this.state.camps.map((camp, index) => (
                                <option key={index} value={camp.id}>
                                  {camp.name} -- {camp.area.name} --{" "}
                                  {camp.area.region.name} --{" "}
                                  {camp.area.region.state.name}
                                </option>
                              ))}
                          </select>
                          {preference_spinner ? <MiniSpinner /> : null}
                        </div>
                        {this.renderErrorFor("proposed_camp")}
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  Create
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createRelocationRequest: (creds) =>
      createRelocationRequest(dispatch, creds),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch),
    getAllCamps: () => dispatch(getAllCamps()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateRelocationRequest);
