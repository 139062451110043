// import { local_cart, storage_type, dehash, hash } from "../../config";
const initState = {
  status: null,
  message: null,
  errors: null,
  spinner: false,
  funds: [],
  funds_area: [],
  funds_area_camp: [],
  funds_region: [],
  funds_region_area: [],
  funds_region_camp: [],
  funds_state: [],
  funds_state_region: [],
  funds_state_area: [],
  funds_state_camp: [],
  performance: [],
  performance_area: [],
  performance_region: [],
  performance_state: [],
  distribution_state: [],
  camp_503: [],
  camp_performance: [],
  mem: [],
  mem_area: [],
  mem_region: [],
  mem_region_area: [],
  mem_state: [],
  mem_state_region: [],
  mem_state_area: [],
  scrip: [],
  scrip_area: [],
  scrip_area_camp: [],
  scrip_region: [],
  scrip_region_area: [],
  scrip_region_camp: [],
  scrip_state: [],
  scrip_state_region: [],
  scrip_state_area: [],
  scrip_state_camp: [],
  state_summary: [],
  all_scripture: [],
  all_fund_nat: [],
};

const reportReducer = (state = initState, action) => {
  switch (action.type) {
    case "CLEAR_REPORT_ERRORS":
      return {
        ...state,
        errors: null,
        spinner: false,
        status: null,
        message: null,
      };
    case "LOAD_REPORT_SPINNER":
      return {
        ...state,
        spinner: true,
      };
    case "UNLOAD_REPORT_SPINNER":
      return {
        ...state,
        spinner: false,
      };
    case "GET_STATE_REGION_SCRIPTURE_REPORT":
      return {
        ...state,
        scrip_state_region: action.resMessage.payload.report,
      };
    case "GET_STATE_REGION_MEMBER_REPORT":
      return {
        ...state,
        mem_state_region: action.resMessage.payload.report,
      };
    case "GET_REGION_AREA_MEMBER_REPORT":
      return {
        ...state,
        mem_region_area: action.resMessage.payload.report,
      };
    case "GET_STATE_AREA_MEMBER_REPORT":
      return {
        ...state,
        mem_state_area: action.resMessage.payload.report,
      };
    case "GET_STATE_CAMP_SCRIPTURE_REPORT":
      return {
        ...state,
        scrip_state_camp: action.resMessage.payload.report,
      };
    case "GET_STATE_AREA_SCRIPTURE_REPORT":
      return {
        ...state,
        scrip_state_area: action.resMessage.payload.report,
      };
    case "GET_ACCOUNT_REPORT":
      return {
        ...state,
        funds: action.resMessage.payload.report,
      };
    case "GET_REGION_CAMP_SCRIPTURE_REPORT":
      return {
        ...state,
        scrip_region_camp: action.resMessage.payload.report,
      };
    case "GET_STATE_SUMMARY_REPORT":
      return {
        ...state,
        state_summary: action.resMessage.payload.report,
      };
    case "GET_STATE_SUMMARY_REPORT_SCRIPTURE":
      return {
        ...state,
        all_scripture: action.resMessage.payload.report,
      };
    case "GET_STATE_SUMMARY_REPORT_FUNDS":
      return {
        ...state,
        all_fund_nat: action.resMessage.payload.report,
      };
    case "GET_AREA_CAMP_SCRIPT_REPORT":
      return {
        ...state,
        scrip_area_camp: action.resMessage.payload.report,
      };
    case "GET_REGION_AREA_SCRIPTURE_REPORT":
      return {
        ...state,
        scrip_region_area: action.resMessage.payload.report,
      };
    case "GET_AREA_CAMP_ACCOUNT_REPORT":
      return {
        ...state,
        funds_area_camp: action.resMessage.payload.report,
      };
    case "GET_REGION_ACCOUNT_AREA_REPORT":
      return {
        ...state,
        funds_region_area: action.resMessage.payload.report,
      };
    case "GET_REGION_ACCOUNT_CAMP_REPORT":
      return {
        ...state,
        funds_region_camp: action.resMessage.payload.report,
      };
    case "GET_MEM_REPORT":
      return {
        ...state,
        mem: action.resMessage.payload.report,
      };
    case "GET_CAMP_SCRIPTURE_REPORT":
      return {
        ...state,
        scrip: action.resMessage.payload.report,
      };
    case "GET_AREA_SCRIPTURE_REPORT":
      return {
        ...state,
        scrip_area: action.resMessage.payload.report,
      };
    case "GET_AREA_MEMBER_REPORT":
      return {
        ...state,
        mem_area: action.resMessage.payload.report,
      };
    case "GET_REGION_MEMBER_REPORT":
      return {
        ...state,
        mem_region: action.resMessage.payload.report,
      };
    case "GET_REGION_SCRIPTURE_REPORT":
      return {
        ...state,
        scrip_region: action.resMessage.payload.report,
      };
    case "GET_STATE_ACCOUNT_AREA_REPORT":
      return {
        ...state,
        funds_state_area: action.resMessage.payload.report,
      };
    case "GET_STATE_ACCOUNT_CAMP_REPORT":
      return {
        ...state,
        funds_state_camp: action.resMessage.payload.report,
      };
    case "GET_STATE_MEMBER_REPORT":
      return {
        ...state,
        mem_state: action.resMessage.payload.report,
      };
    case "GET_STATE_SCRIPTURE_REPORT":
      return {
        ...state,
        scrip_state: action.resMessage.payload.report,
      };
    case "GET_AREA_ACCOUNT_REPORT":
      return {
        ...state,
        funds_area: action.resMessage.payload.report,
      };
    case "GET_STATE_ACCOUNT_REGION_REPORT":
      return {
        ...state,
        funds_state_region: action.resMessage.payload.report,
      };
    case "GET_REGION_ACCOUNT_REPORT":
      return {
        ...state,
        funds_region: action.resMessage.payload.report,
      };
    case "GET_STATE_ACCOUNT_REPORT":
      return {
        ...state,
        funds_state: action.resMessage.payload.report,
      };
    case "GET_STATE_DISTRIBUTION_REPORT":
      return {
        ...state,
        distribution_state: action.resMessage.payload.report,
      };
    case "GET_CAMP_503_REPORT":
      return {
        ...state,
        camp_503: action.resMessage.payload,
      };
    case "GET_CAMP_MONTHLY_PERFORMANCE":
      return {
        ...state,
        camp_performance: action.resMessage.payload,
      };
    case "GET_PERFORMANCE_REPORT":
      return {
        ...state,
        performance: action.resMessage.payload.report,
      };
    case "GET_AREA_PERFORMANCE_REPORT":
      return {
        ...state,
        performance_area: action.resMessage.payload.report,
      };
    case "GET_REGION_PERFORMANCE_REPORT":
      return {
        ...state,
        performance_region: action.resMessage.payload.report,
      };
    case "GET_STATE_PERFORMANCE_REPORT":
      return {
        ...state,
        performance_state: action.resMessage.payload.report,
      };
    case "ERRORS_GET_ACCOUNT_REPORT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_STATE_SUMMARY_REPORT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_PERFORMANCE_REPORT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    default:
      return state;
  }
};

export default reportReducer;
