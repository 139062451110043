import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
// import {
//   getAllCamps,
//   deleteCampAction
// } from "./../../../store/actions/preferencesActions";
import {
  getAllAuxilliaries,
  delistAuxMember,
} from "./../../../store/actions/usersActions";
import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../store/actions/authActions";
import $ from "jquery";
import DelistedAuxTable from "./DelistedAuxTable";

class DelistAuxilliaries extends Component {
  state = {
    auxilliaries: null,
    initdep: false,
    name: "",
    remark: "",
    error: 0,
  };

  constructor(props) {
    super(props);
    this.triggerDelist = this.triggerDelist.bind(this);
    this.setRemarkPropertiesGid = this.setRemarkPropertiesGid.bind(this);
  }

  triggerDelist = () => {
    if (this.state.remark) {
      this.setState({ error: 0 });

      if (window.confirm("Delist Member: " + this.state.name + "?")) {
        const { delistAuxMember } = this.props;
        delistAuxMember(this.state.id, this.state.remark).then((res) => {
          this.setState({
            id: null,
            name: "",
            remark: "",
            error: 0,
          });

          $("#closeModalAux").trigger("click");
        });
      }
    } else {
      this.setState({ error: 1 });
    }
  };

  setRemarkPropertiesGid = (id, name) => {
    this.setState({
      id: id,
      name: name,
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  componentDidMount() {
    this.setState({ initdep: true });
    const { setPermissionsErrors, getAllAuxilliaries } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBER" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllAuxilliaries();
    }
  }
  initDependency = () => {
    if (this.state.initdep) {
      const old_element = document.querySelector(
        "script[src='/js/content.js']"
      );
      const new_element = document.createElement("script");
      new_element.src = `/js/content.js`;
      new_element.async = true;
      if (old_element) {
        old_element.replaceWith(new_element);
      } else {
        document.body.appendChild(new_element);
      }
      this.setState({ initdep: false });
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.auxilliaries !== prevState.auxilliaries) {
      return {
        auxilliaries: nextProps.auxilliaries,
      };
    }
    return null;
  }
  getMemberStatus = (status) => {
    if (status === "GOOD") {
      return "GOOD STANDING";
    } else if (status === "TRANS") {
      return "TRANSFERED";
    } else if (status === "DELI") {
      return "DELIQUENT";
    } else if (status === "DROP") {
      return "DROPPED";
    } else if (status === "ACTI") {
      return "ACTIVE";
    } else if (status === "DELISTED") {
      return "DELISTED";
    } else if (status === "EXIT") {
      return "EXIT";
    }
    return "";
  };
  dateConv = (date) => {
    if (date === null || date === "") {
      return null;
    }
    return new Date(date).toDateString();
  };
  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Membership</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Delist Auxilliaries
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Delist Auxilliaries
              </h3>
              <div className="card-tools">
                <Link to="/membership/auxilliaries/create">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle mr-1"></i>
                    ADD NEW Auxilliary
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}

              <DelistedAuxTable
                auxilliaries={this.props.auxilliaries}
                dateConv={this.dateConv}
                setRemarkPropertiesGid={this.setRemarkPropertiesGid}
              />
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}

        <div
          className="modal fade"
          id="RemarkGidModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="RemarkGidModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="RemarkGidModalLabel">
                  Delist Member
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label htmlFor="member_name">Member</label>
                <input
                  type="text"
                  id="member_name"
                  className="form-control"
                  defaultValue={this.state.name}
                  placeholder="Member Name"
                />

                <br />

                <label htmlFor="remark">Remark</label>
                <textarea
                  rows={3}
                  id="remark"
                  name="remark"
                  value={this.state.remark}
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="Provide reasons for delisting member"
                >
                  {this.state.remark}
                </textarea>

                <span className="text-danger">
                  {this.state.error == 1
                    ? "Error! Kindly fill the remark field"
                    : ""}
                </span>
              </div>

              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-danger"
                  id="closeModalAux"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.triggerDelist()}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auxilliaries: state.users.auxilliaries,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    delistAuxMember: (id, remark) => delistAuxMember(dispatch, id, remark),
    getAllAuxilliaries: () => dispatch(getAllAuxilliaries()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DelistAuxilliaries);
