import React, { Component } from "react";
import { Link } from "react-router-dom";
// import classnames from "classnames";
import { connect } from "react-redux";
import {
  resetUsersState,
  getDetailRequest,
  raiseScriptureRequisition,
} from "../../../../store/actions/usersActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
// import MiniSpinner from "../../../helpers/MiniSpinner";
import $ from "jquery";

// 10.10.1.227

class DetailScriptureRequest extends Component {
  state = {
    camp_inventory: [],
    bibles: [],
  };
  componentDidMount() {
    const { setPermissionsErrors, getDetailRequest } = this.props;
    if (
      !hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD",
      }) ||
      !hasPermission({
        mod: "Personal Management",
        action: "CAMP_ACTIVITY_SCRIPTURE_REQUISITION",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    const id = this.props.match.params.id;
    getDetailRequest(id).then((res) => {
      if (res.status === "success") {
        // console.log("dfdt", res);
        this.setState({
          details: res.payload.details,
          ...res.payload.request,
        });
      }
    });
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleFileChange = (e) => {
    this.setState({
      evidence: e.target.files[0],
    });
  };
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  handleChange = (e) => {
    const bibles = [...this.state.bibles];
    if (bibles.length) {
      // eslint-disable-next-line
      const bible = bibles.find((item) => item.bible_id == e.target.id);
      if (bible) {
        bible.requested = e.target.value ? e.target.value : "0";
      } else {
        bibles.push({
          bible_id: e.target.id,
          requested: e.target.value ? e.target.value : "0",
        });
      }
    } else {
      bibles.push({ bible_id: e.target.id, requested: e.target.value });
    }
    this.setState({
      bibles,
    });
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const creds = {
      id: this.props.match.params.id,
      bibles: this.state.bibles,
    };
    this.props.raiseScriptureRequisition(creds).then((res) => {
      if (res.status === "success") {
        this.setState({
          bibles: [],
        });
        $("#request-form")[0].reset();
      }
    });
  };

  render() {
    const { spinner, status, message } = this.props;

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Personal</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Scripture Requisition Details
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Scripture Requisition Details (Scripture Distribution)
              </h3>
              <div className="card-tools">
                <Link to="/personal/my-scriptures-request">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-arrow-circle-left "></i> Back
                  </button>
                </Link>
              </div>
            </div>
            <form id="request-form">
              <div className="card-body">
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}

                {spinner ? (
                  <div>
                    <Spinner />
                  </div>
                ) : null}
                <div
                  className="card"
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <div className="card-body ">
                    <div
                      className="row d-flex justify-content-between"
                      style={{
                        overflow: "hidden",
                      }}
                    >
                      <div>
                        <p className="font-weight-bold">
                          Activity Status:{" "}
                          <span className="font-italic">
                            {this.state.activity
                              ? this.state.activity.status === "P"
                                ? "PENDING"
                                : "AUTHORIZED"
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Activity Type:{" "}
                          <span>
                            {this.state.activity
                              ? this.state.activity.type
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Activity Description:{" "}
                          <span>
                            {this.state.activity
                              ? this.state.activity.description
                              : null}
                          </span>{" "}
                        </p>
                      </div>
                      <div>
                        <p className="font-weight-bold">
                          Request Status:{" "}
                          <span className="font-italic">
                            {this.state.status
                              ? this.state.status === "P"
                                ? "PENDING"
                                : "AUTHORIZED"
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Authorized By:{" "}
                          <span className="font-italic">
                            {this.state.authorised_by
                              ? `${this.state.authorised_by.first_name} ${
                                  this.state.authorised_by.last_name
                                } ${
                                  this.state.authorised_by.other_name
                                    ? this.state.authorised_by.other_name
                                    : ""
                                }`
                              : null}
                          </span>{" "}
                        </p>
                        <p className="font-weight-bold">
                          Created Date:{" "}
                          <span className="font-italic">
                            {/* {this.moneyConv(this.state.stock)} */}
                            {this.state.created_at
                              ? this.dateConv(this.state.created_at)
                              : null}
                          </span>{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div key="uniqueKey" className="row col-md-8 offset-md-2 ">
                  <table
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Scriptures</th>
                        <th>Requested Quantity</th>
                        <th>Supplied Quantity</th>
                        <th>Remark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.details &&
                        this.state.details.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.requested}</td>
                              <td>{item.supplied}</td>
                              <td>{item.remark}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch),
    getDetailRequest: (id) => getDetailRequest(dispatch, id),
    raiseScriptureRequisition: (creds) =>
      raiseScriptureRequisition(dispatch, creds),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailScriptureRequest);
