import { BASE_URL, token, storage_type } from "./config";
import axios from "axios";

const privateRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  },
});

const privateRequestGet = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${storage_type.getItem(token)}`,
  },
});

export { privateRequest, privateRequestGet };
