import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllSettings } from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";

class ViewRegions extends Component {
  state = {
    settings: null,
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllSettings } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "UPDATE_SETTING" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllSettings();
    }
  }

  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.settings !== prevState.settings) {
      return {
        settings: nextProps.settings,
      };
    }
    return null;
  }

  covMoney = (amount) => {
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  render() {
    const { spinner, message, status } = this.props;
    const { settings } = this.state;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">View Settings</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">View Settings</h3>
              <div className="card-tools">
                <Link to="/preferences/settings/edit">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-edit mr-1"></i>
                    Edit Settings
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <table
                    id="region_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Settings</th>
                        <th>Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Gidieon Renewal Fee</td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? this.covMoney(settings.gideon_amount)
                            : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td>Auxilliary Renewal Fee</td>
                        <td>
                          &#8358;{" "}
                          {settings ? this.covMoney(settings.aux_amount) : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td>Couples Renewal Fee</td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? this.covMoney(settings.couple_amount)
                            : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td>Deliquent Days</td>
                        <td>
                          {" "}
                          {settings
                            ? `${settings.deliquent_days} day(s)`
                            : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td>New Calender Year</td>
                        <td>{settings ? settings.expiry_date : "--"}</td>
                      </tr>
                      <tr>
                        <td>Goal Setting Expiration</td>
                        <td>
                          {settings ? settings.goal_setting_expiry : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> Purge Audit Trail Record</td>
                        <td>
                          {settings && settings.purge === 1
                            ? "Enabled"
                            : "Disabled"}
                        </td>
                      </tr>
                      <tr>
                        <td> Purge Audit Trail Record after</td>
                        <td>
                          {settings
                            ? `${settings.days_older_than} day(s)`
                            : "--"}
                        </td>
                      </tr>

                      <tr>
                        <td> Gideon State Convention Fee </td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? `${settings.gideon_state_convention_fee}`
                            : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> Auxilliary State Convention Fee </td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? `${settings.aux_state_convention_fee}`
                            : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> Couples State Convention Fee </td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? `${settings.both_state_convention_fee}`
                            : "--"}
                        </td>
                      </tr>

                      <tr>
                        <td> Gideon National Convention Fee </td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? `${settings.gideon_national_convention_fee}`
                            : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> Auxilliary National Convention Fee </td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? `${settings.aux_national_convention_fee}`
                            : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> Couples National Convention Fee </td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? `${settings.both_national_convention_fee}`
                            : "--"}
                        </td>
                      </tr>

                      <tr>
                        <td> Gideon International Convention Fee </td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? `${settings.gideon_international_convention_fee}`
                            : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> Auxilliary International Convention Fee </td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? `${settings.aux_international_convention_fee}`
                            : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> Couples International Convention Fee </td>
                        <td>
                          &#8358;{" "}
                          {settings
                            ? `${settings.both_international_convention_fee}`
                            : "--"}
                        </td>
                      </tr>

                      <tr>
                        <td> International Theme </td>
                        <td>
                          {settings ? `${settings.international_theme}` : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> International Number </td>
                        <td>
                          {settings ? `${settings.international_number}` : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> National Theme </td>
                        <td>
                          {settings ? `${settings.national_theme}` : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> National Number </td>
                        <td>
                          {settings ? `${settings.national_number}` : "--"}
                        </td>
                      </tr>
                      <tr>
                        <td> State Theme </td>
                        <td>{settings ? `${settings.state_theme}` : "--"}</td>
                      </tr>
                      <tr>
                        <td> State Number </td>
                        <td>{settings ? `${settings.state_number}` : "--"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    settings: state.preferences.settings,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllSettings: () => dispatch(getAllSettings()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewRegions);
