import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  uploadAuxiliaryFile,
  resetPreferencesState,
} from "../../../../store/actions/preferencesActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import $ from "jquery";

class UploadAuxiliary extends Component {
  state = {
    upload: "",
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_MEMBER" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
  }
  componentWillUnmount() {
    this.props.resetPreferencesState();
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const { uploadAuxiliaryFile } = this.props;
    const creds = {
      upload: this.state.upload,
    };
    uploadAuxiliaryFile(creds).then((res) => {
      if (res.status === "success") {
        $("#upload").val("");
        this.setState({
          upload: "",
        });
      }
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleFileChange = (e) => {
    this.setState({
      upload: e.target.files[0],
    });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>MEMBERSHIP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Upload Auxiliary</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">Upload Auxiliary</h3>
              <div className="card-tools">
                <a
                  href={`/uploads/upload_auxilliaries_members-sample.xlsx`}
                  className="btn btn-warning btn-xs text-uppercase font-weight-bold"
                  download
                >
                  <i className="fas fa-download" /> Download Template
                </a>
              </div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row col-md-6 mx-auto">
                  <div className="form-group col-md-12 ">
                    <label htmlFor="name">
                      Upload Auxiliary File{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <br />
                    <input
                      type="file"
                      id="upload"
                      onChange={this.handleFileChange}
                    />
                    <p>
                      <small>Acceptable file to upload is .xls only.</small>
                    </p>
                    {this.renderErrorFor("upload")}
                  </div>
                  <div className="form-group col-md-6">
                    <button
                      type="submit"
                      className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                      disabled={spinner ? true : false}
                    >
                      Upload
                    </button>
                  </div>
                </div>
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    errors: state.preferences.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    uploadAuxiliaryFile: (creds) => uploadAuxiliaryFile(dispatch, creds),
    resetPreferencesState: () => resetPreferencesState(dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadAuxiliary);
