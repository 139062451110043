import React, { Component } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { image_path } from "../../../config";

class Modal extends Component {
  moneyConv = price => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  render() {
    const { img, title, price } = this.props.modalProduct;
    return (
      <ModalContainer>
        <div className="container">
          <div className="row">
            <div
              id="modal"
              className="col-8 mx-auto col-md-6 col-lg-4 text-center text-capitalize p-5"
            >
              <h5>item added to cart</h5>
              <img
                src={`${image_path}${img}`}
                className="img-fluid"
                alt="product"
              />
              <h5>{title}</h5>
              <h5 className="text-muted">
                price: &#8358; {this.moneyConv(price)}
              </h5>
              <Link to="/store">
                <button
                  onClick={() => this.props.modalClose()}
                  className="btn btn-default btn-sm text-uppercase font-weight-bold mr-1"
                >
                  store
                </button>
              </Link>
              <Link to="/cart">
                <button
                  onClick={() => this.props.modalClose()}
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                >
                  go to cart
                </button>
              </Link>
            </div>
          </div>
        </div>
      </ModalContainer>
      //     <h1>Hello from Modal</h1>
      //     <button onClick={() => this.props.modalClose()}>close</button>
      //   </div>
    );
  }
}
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  #modal {
    background: var(--mainWhite);
  }
`;
export default Modal;
