const initState = {
  status: null,
  message: null,
  errors: [],
  spinner: false,
  roles: null,
  camps: null,
  bibles: null,
  depots: null,
  hq_inventory: null,
  depot_inventory: null,
  camp_inventory: null,
  //** Added by Macdonald(mua/251220206) */
  countries: null,
  states: null,
  regions: null,
  areas: null,
  settings: null,
  scripture_bibles: null,
  merchandise_bibles: null,
  user_camp: null,
  user_area: null,
  user_region: null,
  user_state: null,
  campsFromArea: null,
  areaFromRegion: null,
  regionFromState: null,
  s_campFromArea: null,
  s_areaFromRegion: null,
  s_regionFromState: null,
  hotels: null,
  report: {},
  report_bck: {},
};

const preferencesReducer = (state = initState, action) => {
  switch (action.type) {
    case "SEL_CAMP_FROM_AREA":
      return {
        ...state,
        s_campFromArea: action.payload,
      };
    case "SET_CONVEN_REPORT":
      return {
        ...state,
        report: action.payload,
      };
    case "CLEAR_PREFRENCES_REPORT":
      return {
        ...state,
        report_bck: { ...state.report },
        report: {},
      };
    case "SEL_REGION_FROM_STATE":
      return {
        ...state,
        s_regionFromState: action.payload,
      };
    case "SEL_AREA_FROM_REGION":
      return {
        ...state,
        s_areaFromRegion: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user_camp: [action.payload.camp],
        user_area: [action.payload.camp.area],
        user_region: [action.payload.camp.area.region],
        user_state: [action.payload.camp.area.region.state],
      };
    case "SET_ALL_ROLES":
      return {
        ...state,
        roles: action.resMessage.payload.roles,
      };
    case "SET_CAMPS_FROM_AREA":
      return {
        ...state,
        campsFromArea: action.resMessage.payload,
      };
    case "SET_REGION_FROM_STATE":
      return {
        ...state,
        regionFromState: action.resMessage.payload,
      };
    case "SET_AREAS_FROM_REGION":
      return {
        ...state,
        areaFromRegion: action.resMessage.payload,
      };
    case "SET_ALL_AREAS":
      return {
        ...state,
        areas: action.resMessage.payload.area,
      };
    case "SET_ALL_CAMPS":
      return {
        ...state,
        camps: action.resMessage.payload.camp,
      };
    case "SET_ALL_HOSTEL":
      return {
        ...state,
        hotels: action.resMessage.payload.hotels,
      };
    case "SET_ALL_BIBLES":
      return {
        ...state,
        bibles: action.resMessage.payload.bibles,
      };
    case "SET_ALL_SCRIPTURE_BIBLES":
      return {
        ...state,
        scripture_bibles: action.resMessage.payload.scriptures,
      };
    case "SET_ALL_MERCHANDISE_BIBLES":
      return {
        ...state,
        merchandise_bibles: action.resMessage.payload.merchandise,
      };
    // case "SET_ALL_ASSIGNED_DEPOT": //Added by Macdonald
    //   return {
    //     ...state,
    //     depots: action.resMessage.payload.depots,
    //   };
    case "SET_ALL_DEPOT":
      return {
        ...state,
        depots: action.resMessage.payload.depots,
      };
    case "SET_ALL_HQ_INVENTORY":
      return {
        ...state,
        hq_inventory: action.resMessage.payload.inventories,
      };
    case "SET_ALL_DEPOT_INVENTORY":
      return {
        ...state,
        depot_inventory: action.resMessage.payload.inventories,
      };
    case "SET_ONE_DEPOT_INVENTORY":
      return {
        ...state,
        depot_one_inventory: action.resMessage.payload.inventories,
      };
    case "SET_ALL_CAMP_INVENTORY":
      return {
        ...state,
        camp_inventory: action.resMessage.payload.inventories,
      };
    case "SET_ALL_REGIONS":
      return {
        ...state,
        regions: action.resMessage.payload.region,
      };
    case "SET_ALL_SETTINGS":
      return {
        ...state,
        settings: action.resMessage.payload.settings,
      };
    case "SET_ALL_STATES":
      return {
        ...state,
        states: action.resMessage.payload.states,
      };
    case "ERRORS_SET_ALL_ROLES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_AREAS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_SETTINGS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_DEPOT_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_ROLE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_SINGLE_DEPOT_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_CAMPS_FROM_AREA":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_TRANSFER_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_VERIFY_CAMP_DEPOT_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "VERIFY_CAMP_DEPOT_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_HQ_MERCHANDISE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_CREATE_HQ_MERCHANDISE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_TRANSFERRED_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_SINGLE_TRANSFER_DETAILS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_PENDING_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_AUTHORIZE_TRANSFER_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "AUTHORIZE_TRANSFER_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_GET_TRANSFER_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "TRANSFER_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        // errors: action.resMessage.payload,
      };
    case "RECEIVED_CAMP_HQ_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_RECEIVED_CAMP_HQ_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_AUTHORIZED_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_BIBLE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_TRANSFER_REQUEST_TO_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "TRANSFER_REQUEST_TO_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_GET_ONE_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_STATE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_CAMP":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_REGION":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_CAMPS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_BIBLES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_SCRIPTURE_BIBLES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_MERCHANDISE_BIBLES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_REGIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_ALL_STATES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_HQINVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_DEPOTINVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_ONE_CAMPINVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "CLEAR_PREFRENCES_ERRORS":
      return {
        ...state,
        errors: null,
        spinner: false,
        status: null,
        message: null,
      };
    case "LOAD_PREFRENCES_SPINNER":
      return {
        ...state,
        spinner: true,
      };
    case "UNLOAD_PREFRENCES_SPINNER":
      return {
        ...state,
        spinner: false,
      };
    case "ERRORS_CREATE_ROLES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_DEPOT_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "CREATE_DEPOT_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };

    case "ERRORS_CREATE_REGION":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_HQ_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_DEPOT_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_CAMP_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_BIBLE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_CAMPS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_AREAS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_ASSIGN_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_STATES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_UPLOAD_BIBLE":
      return {
        ...state,
        status: "error",
        message: action.resMessage.message,
        errors: action.resMessage.errors || {},
      };
    case "ERRORS_ASSIGN_ROLES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_MOVE_CAMPS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_ROLE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_REMOVE_HQ_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_REMOVE_DEPOT_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_CAMP_INVENTORY_LIMIT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_HQ_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_GET_SINGLE_PENDING_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "AUTHORIZED_CAMP_HQ_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_SET_ALL_CAMP_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };

    case "ERRORS_SET_HQ_INVENTORY_LIMIT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_SET_DEPOT_INVENTORY_LIMIT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_STATE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_REGION":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_SETTINGS":
      return {
        ...state,
        status: "error",
        message: action.resMessage.message,
        errors: action.resMessage.payload || [],
      };
    case "ERRORS_REMOVE_CAMP_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_AREA":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "UPDATE_STATE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "UPDATE_REGION":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_HQ_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_DEPOT_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_CAMP_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "REMOVE_HQ_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "REMOVE_DEPOT_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "REMOVE_CAMP_INVENTORY":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "UPDATE_BIBLE":
      let former_bibles = state.bibles;
      let single_bible = action.resMessage.payload.bible;

      if (former_bibles) {
        let index = former_bibles.findIndex(
          (item) => item.id === single_bible.id
        );
        let former_bible = former_bibles[index];
        let new_bible = { ...former_bible, ...single_bible };
        former_bibles[index] = new_bible;
      }
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        bibles: former_bibles ? [...former_bibles] : null,
      };
    case "UPDATE_CAMP":
      let former_camps = state.camps;
      let single_camp = action.resMessage.payload.camp;

      if (former_camps) {
        let index = former_camps.findIndex(
          (item) => item.id === single_camp.id
        );
        let former_camp = former_camps[index];
        let new_camp = { ...former_camp, ...single_camp };
        former_camps[index] = new_camp;
      }
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        camps: former_camps ? [...former_camps] : null,
      };
    case "UPDATE_SETTINGS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "UPDATE_AREA":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "SET_CAMP_INVENTORY_LIMIT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "SET_HQ_INVENTORY_LIMIT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "SET_DEPOT_INVENTORY_LIMIT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_HQ_SCRIPTURE_REQUEST":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_ROLES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_CAMPS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_AREAS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_REGIONS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ASSIGN_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_BIBLE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "UPDATE_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_STATES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "CREATE_UPLOAD_BIBLE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ASSIGN_ROLES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "UPDATE_ROLE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "DELETE_ROLE":
      const newrole = state.roles.filter((role) => role.id !== action.id);
      return {
        ...state,
        roles: newrole,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "DELETE_AREA":
      const newarea = state.areas.filter((area) => area.id !== action.id);
      return {
        ...state,
        areas: newarea,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "DELETE_CAMPS":
      const newcamps = state.camps.filter((camp) => camp.id !== action.id);
      return {
        ...state,
        camps: newcamps,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "DELETE_REGION":
      const newregions = state.regions.filter(
        (region) => region.id !== action.id
      );
      return {
        ...state,
        regions: newregions,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "DELETE_STATES":
      const newstates = state.states.filter((state) => state.id !== action.id);
      return {
        ...state,
        states: newstates,
        status: action.resMessage.status,
        message: action.resMessage.message,
      };
    case "ERRORS_DELETE_ROLE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_DELETE_AREA":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_DELETE_CAMPS":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_BIBLE":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_CREATE_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_UPDATE_DEPOT":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_DELETE_REGION":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    case "ERRORS_DELETE_STATES":
      return {
        ...state,
        status: action.resMessage.status,
        message: action.resMessage.message,
        errors: action.resMessage.payload,
      };
    default:
      return state;
  }
};

export default preferencesReducer;
