import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getHQInventory
  //   acknowlegdeKits
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import Pagination from "react-js-pagination";

class DetailHQIventory extends Component {
  state = {
    activePage: 1
  };
  componentDidMount() {
    const { setPermissionsErrors, getHQInventory } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_HQ_INVENTORY" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const id = this.props.match.params.id;
      getHQInventory(id).then(res => {
        // console.log("here", res);
        if (res.status === "success") {
          this.setState({
            ...res.payload.details,
            ...res.payload.inventory,
            ...res.payload.inventory.bible
          });
        }
      });
    }
  }
  dateConv = date => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  handlePageChange = pageNumber => {
    this.setState({ activePage: pageNumber });
    const { getHQInventory } = this.props;
    const id = this.props.match.params.id;
    getHQInventory(id, `?page=${pageNumber}`).then(res => {
      if (res.status === "success") {
        this.setState({
          ...res.payload.details,
          ...res.payload.inventory
          //   ...res.payload.inventory.bible
        });
      }
    });
  };

  moneyConv = price => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  render() {
    const { spinner, message, status } = this.props;

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Inventory Activities Record
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Inventory Activities Record
              </h3>
              <div className="card-tools">
                <Link to="/preferences/hq-inventory">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-arrow-circle-left "></i> Back
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <React.Fragment>
                  <div className="card">
                    <div className="card-body ">
                      <div className="row d-flex justify-content-between">
                        <div>
                          <p className="lead">
                            Inventory:{" "}
                            <span className="font-italic">
                              {this.state.name}
                            </span>{" "}
                          </p>
                        </div>
                        <div>
                          <p className="lead">
                            Total Quantity:{" "}
                            <span className="font-italic">
                              {this.moneyConv(this.state.stock)}
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div key="uniqueKey">
                    <table
                      id="state_table"
                      className="table table-hover table-bordered table-striped"
                      style={{
                        width: "100%"
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Activity Type</th>
                          <th>Quantity</th>
                          <th>Comment</th>
                          <th> by</th>
                          <th>Activity Date</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data &&
                          this.state.data.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {item.type === "A" ? "ADDITION" : "REMOVAL"}
                                </td>
                                <td>{this.moneyConv(item.value)}</td>

                                <td>{item.comment}</td>
                                <td>
                                  {item.first_name} {item.last_name}{" "}
                                  {item.other_name}
                                </td>
                                <td>{this.dateConv(item.created_at)}</td>
                                <td>{this.moneyConv(item.balance)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-center">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.per_page}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    states: state.preferences.states,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getHQInventory: (id, page) => getHQInventory(dispatch, id, page),
    // acknowlegdeKits: id => acknowlegdeKits(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(DetailHQIventory);
