import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  payContribution,
  resetUsersState,
  cancelRemitaContribution,
  showSuccessMessage,
} from "../../../../store/actions/usersActions";

// import Spinner from "../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import MiniSpinner from "../../../helpers/MiniSpinner";
import $ from "jquery";
import { user_auth_details, dehash, storage_type } from "../../../../config";
import queryString from "query-string";

// 10.10.1.227

class FinancialContribution extends Component {
  state = {
    purpose: "",
    others: "",
    channel: "",
    amount: "",
    transaction_id: "",
    evidence: "",
    paid_date: "",
    type: "",
    modal_2: false,
    modal_1: false,
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD",
      }) ||
      !hasPermission({
        mod: "Personal Management",
        action: "PAY_CONTRIBUTIONS",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    let params = queryString.parse(this.props.location.search);
    if (params.status === "success") {
      this.props.showSuccessMessage();
    }
    if (params.message && params.message === "Approved") {
      this.setState({
        ...params,
        modal_1: true,
        info: "Transaction Successful",
      });
    }
    if (params.message && params.message !== "Approved") {
      this.setState({ ...params, modal_1: true, info: "Transaction Failed" });
    }

    this.initDependency();
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );

      this.setState(
        {
          default_type: user.type,
        },
        () => {}
      );
    }
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleFileChange = (e) => {
    this.setState({
      evidence: e.target.files[0],
    });
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  handleChange = (e) => {
    if (e.target.value === "Membership Due") {
      this.props.history.push("/personal/payments/renewal");
    }
    let ee = e.target.name;

    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (ee === "purpose") {
          this.setState({ type: "" });
        }
      }
    );
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  closeModal_2 = (e) => {
    e.preventDefault();
    const rrr = this.state.rrr;
    this.props.cancelRemitaContribution(rrr).then((res) => {
      if (res.status === "success") {
        this.setState({
          modal_2: false,
          merchantId: "",
          rrr: "",
          response_url: "",
          action_url: "",
          hash: "",
        });
      }
    });
    // this.props.resetUsersState();
  };
  closeModal_1 = (e) => {
    e.preventDefault();
    this.setState({ modal_1: false });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const pay_channel = this.state.channel;
    if (pay_channel !== " " && pay_channel === "remita") {
      const paid_date = $("#evidence_date").val();
      this.setState({ paid_date }, () => {
        this.props.payContribution(this.state).then((res) => {
          if (res.status === "success") {
            this.setState(
              {
                type: "",
                years: "",
                channel: "",
                amount: "",
                transaction_id: "",
                evidence: "",
                paid_date: "",
              },
              (obj = res.payload) => {
                this.setState({ ...obj }, () => {
                  this.setState({ modal_2: true });
                });
              }
            );
            $("#evidence_date").val("");
            $("#evidence").val("");
          }
        });
      });
    } else {
      const paid_date = $("#evidence_date").val();
      this.setState({ paid_date }, () => {
        this.props.payContribution(this.state).then((res) => {
          if (res.status === "success") {
            this.setState({
              purpose: "",
              others: "",
              channel: "",
              amount: "",
              transaction_id: "",
              evidence: "",
              paid_date: "",
            });
            $("#evidence_date").val("");
            $("#evidence").val("");
          }
        });
      });
    }
  };
  covMoney = (amount) => {
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  render() {
    const { spinner, status, message } = this.props;
    const admin_funds = (
      <React.Fragment>
        <option value="Gideon Camp Offering">Gideon Camp Offering</option>
        <option value="Auxilliary Camp Offering">
          Auxilliary Camp Offering
        </option>
        <option value="National Convention Contribution">
          National Convention Contribution
        </option>
        <option value="State Convention Contributions">
          State Convention Contributions
        </option>

        <option value="National Headquarter Building">
          National Headquarter Building(NHQB)
        </option>
        <option value="Machandise">Machandise(MAC)</option>
        <option value="Membership Due">Membership Due</option>
        <option value="Bequest/Legacy">Bequest/Legacy</option>
      </React.Fragment>
    );
    let scripture_funds;
    if (this.state.default_type) {
      if (this.state.default_type === "A") {
        scripture_funds = (
          // <React.Fragment>
          //   <option value="Auxilliary National Convention">
          //     Auxilliary National Convention
          //   </option>
          //   <option value="International Convention">
          //     International Convention
          //   </option>
          //   <option value="Auxilliary State Convention">
          //     Auxilliary State Convention
          //   </option>
          //   <option value="Camp Meeting">Camp Meeting</option>
          //   <option value="Auxillary PW Periwinkle">
          //     Auxillary PW Periwinkle
          //   </option>

          //   <option value="Gifts/Other">Gifts/Other</option>
          // </React.Fragment>
          <React.Fragment>
            <option value="Church Services">Church Services</option>
            <option value="Gideon Card">Gideon Card (GCP)</option>
            <option value="International Convention">
              International Convention
            </option>
            <option value="State Convention">State Convention</option>
            <option value="Camp Meeting">Camp Meeting</option>
            <option value="Gifts/Other">Gifts/Other</option>
            <option value="National Convention ">National Convention</option>
            <option value="Faith Fund ">Faith Fund</option>
            <option value="Personal Workers Testament">
              Personal Workers Testament(PWT)
            </option>
            <option value="Auxilliary National Convention">
              Auxilliary National Convention
            </option>
            <option value="Auxilliary State Convention">
              Auxilliary State Convention
            </option>
            <option value="Bequest/Legacy">Bequest/Legacy</option>
          </React.Fragment>
        );
      } else {
        scripture_funds = (
          <React.Fragment>
            <option value="Church Services">Church Services</option>
            <option value="Gideon Card">Gideon Card (GCP)</option>
            <option value="International Convention">
              International Convention
            </option>
            <option value="State Convention">State Convention</option>
            <option value="Camp Meeting">Camp Meeting</option>
            <option value="Gifts/Other">Gifts/Other</option>
            <option value="National Convention ">National Convention</option>
            <option value="Faith Fund ">Faith Fund</option>
            <option value="Personal Workers Testament">
              Personal Workers Testament(PWT)
            </option>
            <option value="Auxilliary National Convention">
              Auxilliary National Convention
            </option>
            <option value="Auxilliary State Convention">
              Auxilliary State Convention
            </option>
            <option value="Bequest/Legacy">Bequest/Legacy</option>
          </React.Fragment>
        );
      }
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Personal</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Financial Contribution
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Financial Contribution
              </h3>
              <div className="card-tools"></div>
            </div>
            {this.state.modal_2 ? (
              <div
                //   className="card opp"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  // padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center",

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form action={this.state.action_url} method="POST">
                    <h4 className="text-center mt-3">
                      Pay &#8358; {this.covMoney(this.state.amount)} with Remita{" "}
                      <img
                        src="/dist/img/remita.png"
                        alt=""
                        width="50"
                        style={{ position: "relative", top: "-1px" }}
                      />
                    </h4>
                    <p className="text-center text-danger ">
                      <small className="font-weight-bold">
                        Please note that you may be charge proccessing fee of
                        &#8358; {this.covMoney(100)}{" "}
                      </small>
                    </p>
                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <input
                        id="merchantId"
                        name="merchantId"
                        value={this.state.merchantId}
                        type="hidden"
                      />
                      <input
                        id="rrr"
                        name="rrr"
                        value={this.state.rrr}
                        type="hidden"
                      />
                      <input
                        id="responseurl"
                        name="responseurl"
                        value={this.state.response_url}
                        type="hidden"
                      />
                      <input
                        id="hash"
                        name="hash"
                        value={this.state.hash}
                        type="hidden"
                      />

                      <button
                        // type="submit"
                        className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                      >
                        Pay
                      </button>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_2}
                      >
                        Cancel
                        {spinner ? <MiniSpinner /> : null}
                      </button>
                    </div>
                  </form>
                  {/* <hr /> */}
                  {/* <div className="text-center">
                   
                  </div> */}
                </div>

                {/* /.card-body */}
              </div>
            ) : null}

            {this.state.modal_1 ? (
              <div
                //   className="card opp"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "absolute",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  // padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center"

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                      // className="text-center mt-3"
                      >
                        {this.state.info}{" "}
                      </h4>
                      <i
                        style={{
                          fontSize: "1.6rem",
                          color: "#bda230",
                        }}
                        className={classnames({
                          "fas fa-check": this.state.message === "Approved",
                          "fas fa-exclamation-triangle":
                            this.state.message !== "Approved",
                        })}
                      ></i>
                    </div>

                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <p>
                        <strong>
                          {" "}
                          Order ID : {this.state.orderId}{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_1}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                  {/* <hr /> */}
                  {/* <div className="text-center">
                   
                  </div> */}
                </div>

                {/* /.card-body */}
              </div>
            ) : null}
            <form onSubmit={this.handleSubmit}>
              <div className="card-body">
                {/* {spinner ? <Spinner position="right" /> : null} */}
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}

                <div className="box-body">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="purpose">
                          Purpose<span className="text-danger">*</span>
                        </label>
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("purpose"),
                            "form-control": true,
                          })}
                          id="purpose"
                          name="purpose"
                          onChange={this.handleChange}
                          value={this.state.purpose}
                        >
                          <option value="">
                            Select purpose of contribution
                          </option>
                          <option value="Scripture Funds">
                            Scripture Funds
                          </option>
                          <option value="Administrative Funds">
                            Administrative Funds
                          </option>
                        </select>
                        {this.renderErrorFor("purpose")}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="type">
                          Payment Type<span className="text-danger">*</span>
                        </label>
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("type"),
                            "form-control": true,
                          })}
                          id="type"
                          name="type"
                          onChange={this.handleChange}
                          value={this.state.type}
                        >
                          <option value="">--Select--</option>
                          {this.state.purpose === "Administrative Funds"
                            ? admin_funds
                            : null}
                          {this.state.purpose === "Scripture Funds"
                            ? scripture_funds
                            : null}
                        </select>
                        {this.renderErrorFor("type")}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="others">
                          If Others(Please Specify)
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("others"),
                            "form-control": true,
                          })}
                          id="others"
                          name="others"
                          onChange={this.handleChange}
                          value={this.state.others}
                        />
                        {this.renderErrorFor("others")}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="channel">
                          Payment Mode<span className="text-danger">*</span>
                        </label>
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("channel"),
                            "form-control": true,
                          })}
                          id="channel"
                          name="channel"
                          onChange={this.handleChange}
                          value={this.state.channel}
                        >
                          <option value="">--Select--</option>
                          <option value="remita">Remita</option>
                          <option value="transfer">Transfer</option>
                          <option value="deposit">Deposit</option>
                          <option value="cash">Cash</option>
                          <option value="cheque">Cheque</option>
                        </select>
                        {this.renderErrorFor("channel")}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="amount">
                          Amount<span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          min={1}
                          className={classnames({
                            "is-invalid": this.hasErrorFor("amount"),
                            "form-control": true,
                          })}
                          id="amount"
                          name="amount"
                          onChange={this.handleChange}
                          value={this.state.amount}
                        />
                        {this.renderErrorFor("amount")}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="transaction_id">
                          Transaction ID / Reference ID / Teller No.
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("transaction_id"),
                            "form-control": true,
                          })}
                          id="transaction_id"
                          name="transaction_id"
                          onChange={this.handleChange}
                          value={this.state.transaction_id}
                        />
                        {this.renderErrorFor("transaction_id")}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="evidence_date">Date</label>
                        <input
                          type="text"
                          readOnly
                          id="evidence_date"
                          className="form-control"
                        />
                        {this.renderErrorFor("paid_date")}
                      </div>
                    </div>
                    <div className=" col-6 mx-auto">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          {" "}
                          Attach Evidence of payment if any{" "}
                        </label>
                        <input
                          type="file"
                          id="evidence"
                          onChange={this.handleFileChange}
                        />
                        <br />
                        <small>
                          <b>
                            File types expected are png,jpeg, pdf and jpg not greater
                            than 2MB
                          </b>
                        </small>
                        {this.renderErrorFor("evidence")}
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  Submit
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    payContribution: (creds) => payContribution(dispatch, creds),
    cancelRemitaContribution: (creds) =>
      cancelRemitaContribution(dispatch, creds),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    showSuccessMessage: () => dispatch(showSuccessMessage()),
    resetUsersState: () => resetUsersState(dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FinancialContribution);
