import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getStateDistributionReport,
  resetReportState,
} from "./../../../../store/actions/reportActions";
import {
  //   getAllCamps,
  getAllState,
} from "../../../../store/actions/preferencesActions";

import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import classnames from "classnames";
import MiniSpinner from "./../../../helpers/MiniSpinner";
import $ from "jquery";
// import _ from "underscore";
import { BASE_URL, token, storage_type } from "../../../../config";
// import { hasPermission } from "./../../helpers/functions/functions";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";

class StateDistribution extends Component {
  state = {
    // camps: null,
    distribution_state: null,
    state_id: "",
    // arranged_data: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REPORTS",
      }) ||
      !hasPermission({
        mod: "Reports",
        action: "VIEW_STATES_DISTRIBUTION_PERFORMANCE_REPORTS",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    document.title =
      "The Gideon International In Nigeria (State Scripture Distribution Performance)";
    this.initDependency();
    // const { getStateDistributionReport } = this.props;
    // getStateDistributionReport(1, "12/12/2020", "15/12/2020");
    // this.props.getAllCamps();
    // this.props.getAllState().then((res) => {
    //   if (res.status === "success") {
    //     this.setState({ states: res.payload.states });
    //   }
    // });
  }
  initDependency = () => {
    // alert("here");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      //   nextProps.camps !== prevState.camps ||
      nextProps.distribution_state !== prevState.distribution_state
    ) {
      const arry = [];
      const temp_distribution_state = [...nextProps.distribution_state];
      temp_distribution_state.forEach((item) => {
        let obj = { state: item.state };

        let arryObj = item.details.map((itm) => {
          return { [itm.name]: itm.distributed };
        });
        // console.log(arryObj);
        // console.log(item.details);
        let eachState = item.details.reduce(function (acc, obj) {
          return acc + parseFloat(obj.distributed ? obj.distributed : 0);
        }, 0);
        obj = { ...obj, eachState };
        let result = Object.assign({}, obj, ...arryObj);
        arry.push(result);
      });

      // if (temp_distribution_state.length) {
      //   const camp_group = _.groupBy(temp_distribution_state, "state");
      //   for (let [key, value] of Object.entries(camp_group)) {
      //     arry.push({
      //       state: key,
      //       fund: _.groupBy(value, "purpose"),
      //     });
      //   }
      //   // console.log(arry);
      //   // this.updateState(arry);
      // }

      return {
        // camps: nextProps.camps,
        distribution_state: arry,
      };
    }
    return null;
  }
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  handleDownload = (e) => {
    e.preventDefault();
    const from = $("#from_date").val();
    const to = $("#to_date").val();
    const state = this.state.state_id;
    const tok = storage_type.getItem(token);
    const download = 1;
    const url = `${BASE_URL}/reports/states/funds?from=${from}&to=${to}&state=${state}&download=${download}&token=${tok}`;
    window.open(url, "_blank");
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const from_date = $("#from_date").val();
    const to_date = $("#to_date").val();
    // const id = $(".memberselect22").val();

    // const state = this.state.state_id;
    // alert(area);
    this.setState(
      {
        from_date,
        to_date,
        // id
      },
      () => {
        if (window.confirm("Are you sure to want to generate this report?")) {
          this.props.getStateDistributionReport(from_date, to_date);
        }
      }
    );
  };

  // arrangeReport = () => {};
  // updateState = arry => {
  //   const dd = arry;
  //   setTimeout(() => {
  //     this.setState({ new_arry: dd });
  //   }, 1000);
  // };
  setDownload = (e) => {
    e.preventDefault();
    $(".fund_table").attr("id", "role_table22");
    this.initDependency();
  };
  handleSelectArea = (e) => {
    this.setState({ state_id: e.target.value });
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  componentWillUnmount() {
    this.props.resetReportState();
  }
  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    // if (this.state.distribution_state) {
    //   this.arrangeReport();
    // }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>REPORT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {" "}
                    Scripture Distribution Performance
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Scripture Distribution Performance
              </h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "3000px" }}>
                  <Spinner />
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row  ">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="from_date">
                        From Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("from_date"),
                          "form-control": true,
                        })}
                        id="from_date"
                        name="from_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("from_date")}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="to_date">
                        To Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("to_date"),
                          "form-control": true,
                        })}
                        id="to_date"
                        name="to_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("to_date")}
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="camp_id">
                        Camp <span className="text-danger">*</span>{" "}
                      </label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%"
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.camps &&
                            this.state.camps.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                        </select>
                        {preference_spinner ? <MiniSpinner /> : null}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div> */}
                  {/* <div className=" col-sm-3">
                    <label>State</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("state_id"),
                          "form-control selected_area": true,
                        })}
                        onChange={this.handleSelectArea}
                        id="state_id"
                        // defaultValue={this.state.state_id}
                      >
                        <option value="">--Select state--</option>
                        {this.state.states &&
                          this.state.states.map((state, index) => (
                            <option key={index} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor("state_id")}
                  </div> */}

                  {/* {this.state.distribution_state.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}
                </div>
                <div className="row mb-3">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    disabled={spinner ? true : false}
                  >
                    Generate
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>

                  {/* <button
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button> */}
                  {/* {this.state.distribution_state.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}
                </div>
              </form>

              {this.state.distribution_state.length && !spinner ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>State</th>
                        <th>COLL.TEST</th>
                        <th>MED.TEST</th>
                        <th>HOSP.TEST</th>
                        <th>TRIL.TEST</th>
                        <th>ARABIC</th>
                        <th>PWT</th>
                        <th>AUX.PWT</th>
                        <th>SLIMLINE</th>
                        <th>SPECIAL MINISTER TESTAMENT</th>
                        <th>SPECIAL DIGNITARY BIBLE</th>
                        <th>UNMARKED BIBLE PLAIN BROWN</th>
                        <th>FULL BIBLE</th>
                        <th>DIGNITARY BIBLE</th>
                        <th>CHURCH SERVICE PAMPHLET</th>
                        <th>TOTAL DISTRUBUTED</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.distribution_state &&
                        this.state.distribution_state.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.state}</td>
                                <td>
                                  {item["College Testament"]
                                    ? item["College Testament"]
                                    : 0}
                                </td>
                                <td>
                                  {item["Medical Testament"]
                                    ? item["Medical Testament"]
                                    : 0}
                                </td>
                                <td>
                                  {item["Hospital Testament"]
                                    ? item["Hospital Testament"]
                                    : 0}
                                </td>
                                <td>
                                  {item["Triling Testament"]
                                    ? item["Triling Testament"]
                                    : 0}
                                </td>
                                <td>
                                  {item["Others (Arabic PW Testament)"]
                                    ? item["Others (Arabic PW Testament)"]
                                    : 0}
                                </td>
                                <td>
                                  {item["Gideon PWT"] ? item["Gideon PWT"] : 0}
                                </td>
                                <td>
                                  {item["Auxilliary PW Periwinkle"]
                                    ? item["Auxilliary PW Periwinkle"]
                                    : 0}
                                </td>
                                <td>
                                  {item["Slim Line Testament"]
                                    ? item["Slim Line Testament"]
                                    : 0}
                                </td>
                                <td>
                                  {item[
                                    "Special Minister Golden Leather Testament"
                                  ]
                                    ? item[
                                        "Special Minister Golden Leather Testament"
                                      ]
                                    : 0}
                                </td>
                                <td>
                                  {item["Special Dignitary Bible"]
                                    ? item["Special Dignitary Bible"]
                                    : 0}
                                </td>
                                <td>
                                  {item["Unmarked Bible(Plain Brown)"]
                                    ? item["Unmarked Bible(Plain Brown)"]
                                    : 0}
                                </td>
                                <td>
                                  {item["Full Bible"] ? item["Full Bible"] : 0}
                                </td>
                                <td>
                                  {item["Dignitary Bible"]
                                    ? item["Dignitary Bible"]
                                    : 0}
                                </td>
                                <td>
                                  {item["Church Service Pamphlet"]
                                    ? item["Church Service Pamphlet"]
                                    : 0}
                                </td>
                                <td>{item.eachState}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        {" "}
                        <td>
                          {" "}
                          <strong>Total</strong>{" "}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["College Testament"]
                                      ? obj["College Testament"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Medical Testament"]
                                      ? obj["Medical Testament"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Hospital Testament"]
                                      ? obj["Hospital Testament"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Triling Testament"]
                                      ? obj["Triling Testament"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Others(Arabic PW Testament)"]
                                      ? obj["Others(Arabic PW Testament)"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Gideon PWT"] ? obj["Gideon PWT"] : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Auxilliary PW Periwinkle"]
                                      ? obj["Auxilliary PW Periwinkle"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Slim Line Testament"]
                                      ? obj["Slim Line Testament"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj[
                                      "Special Minister Golden Leather Testament"
                                    ]
                                      ? obj[
                                          "Special Minister Golden Leather Testament"
                                        ]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Special Dignitary Bible"]
                                      ? obj["Special Dignitary Bible"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Unmarked Bible(Plain Brown)"]
                                      ? obj["Unmarked Bible(Plain Brown)"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Full Bible"] ? obj["Full Bible"] : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Dignitary Bible"]
                                      ? obj["Dignitary Bible"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj["Church Service Pamphlet"]
                                      ? obj["Church Service Pamphlet"]
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </td>
                        <td>
                          {" "}
                          {this.state.distribution_state &&
                            this.moneyConv(
                              this.state.distribution_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return acc + parseFloat(obj.eachState);
                              },
                              0)
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.reports.spinner,
    message: state.reports.message,
    status: state.reports.status,
    errors: state.reports.errors,
    distribution_state: state.reports.distribution_state,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStateDistributionReport: (from, to) =>
      dispatch(getStateDistributionReport(from, to)),
    // getAllCamps: () => dispatch(getAllCamps()),
    getAllState: () => getAllState(dispatch),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetReportState: () => resetReportState(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(StateDistribution);
