import React from "react";
import { Link } from "react-router-dom";

function DilistedTable({ delisted, setRemarkProperties, dateConv }) {
  return (
    <div key="uniqueKey">
      <table
        id="example"
        className="table table-hover table-bordered table-striped"
        style={{
          width: "100%",
        }}
      >
        <thead>
          <tr>
            <th>S/N</th>
            <th>Last Name</th>
            <th>First Name</th>
            <th>Other Name</th>
            <th>Member Id</th>
            <th>Type</th>
            <th>Renewal type</th>
            <th>Spouse Id</th>
            <th>Spouse Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Recruitment Method</th>
            <th>Camp</th>
            <th>Area</th>
            <th>Region</th>
            <th>State</th>

            <th>Status</th>
            <th>Due Date</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {delisted &&
            delisted.map((delist, index) => {
              return (
                <tr key={delist.id}>
                  <td>{index + 1}</td>

                  <td>{delist.last_name}</td>
                  <td>{delist.first_name}</td>
                  <td>{delist.other_name || "--"}</td>
                  <td>{delist.login_id || "--"}</td>
                  <td>{delist.type === "G" ? "GIDEON" : "AUXILLIARY"}</td>
                  <td>{delist.membership_type || "--"}</td>
                  <td>{delist.aux ? delist.aux.login_id : "--"}</td>
                  <td>
                    {" "}
                    {delist.aux
                      ? `${delist.aux.first_name} ${delist.aux.last_name}`
                      : "--"}
                  </td>

                  {/* <td>{delist.phone.trim() || "--"}</td>
                              <td>{delist.email || "--"}</td>
                              <td>{delist.camp?.name || "--"}</td>
                              <td>{delist.camp.area?.name || "--"}</td>
                              <td>{delist.camp.area.region?.name || "--"}</td>
                              <td>
                                {delist.camp.area.region.state?.name || "--"}
                              </td> */}
                  <td>{delist.phone ? delist.phone.trim() : "--"}</td>
                  <td>{delist.email || "--"}</td>
                  <td>{delist.recruitment || "--"}</td>
                  {/*--------modifications by Macdonald-------------*/}
                  <td>{delist.camp ? delist.camp.name : "--"}</td>
                  <td>{delist.camp ? delist.camp.area.name : "--"}</td>
                  <td>{delist.camp ? delist.camp.area.region.name : "--"}</td>
                  <td>
                    {delist.camp ? delist.camp.area.region.state.name : "--"}
                  </td>
                  {/*--------------End Modifications------------------ */}
                  <td> {delist.status} </td>
                  <td> {dateConv(delist.expiry_date)} </td>

                  <td>
                    <div
                      style={{
                        overflow: "visible",
                        position: "relative",
                        width: "140px",
                      }}
                    >
                      <center>
                        <Link
                          onClick={() =>
                            setRemarkProperties(
                              delist.id,
                              delist.first_name + " " + delist.last_name,
                              delist.type
                            )
                          }
                          to="#"
                          className="btn btn-sm btn-success btn-icon"
                          data-toggle="modal"
                          data-target="#RemarkModal"
                        >
                          <i className="fas fa-trash-restore text-white" />
                        </Link>
                      </center>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default React.memo(DilistedTable);
