import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../../hooks";
import { useGetSystemRoles } from "../../../../store/hookactions/notificationsActionhooks";
import MiniSpinner from "../../../helpers/MiniSpinner";
import Message from "../../../helpers/Message";
import Spinner from "../../../helpers/Spinner";
import $ from "jquery";
import {
  submitCampMessage,
  resetNotificationState,
} from "../../../../store/actions/notificationActions";
import { useDispatch } from "react-redux";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import { storage_type, user_auth_details, dehash } from "./../../../../config";

function ViewSendCampMessages(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [enabled, setEnabled] = useState(true);
  const [cars_Info, setCarInfo] = useState({});
  const [filtered_roles, setFilterRoles] = useState([]);
  const [title, setTitle] = useState("");
  const [all_chk_rol, setAllRoleChk] = useState(false);

  const fake_data = {
    compose_message:
      "<ul><li>fwfwfwfwf</li><li>wgwgwgwg</li><li>wgwgwg</li></ul>",
    role_ids: ["84", "82"],
    send_date: "08/30/2022",
    title: "fhjfjf",
    to_camp: 1,
  };

  useGetSystemRoles(enabled, setEnabled);
  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const status = useShallowEqualSelector((state) => state.notifications.status);
  const errors = useShallowEqualSelector((state) => state.notifications.errors);
  const roles = useShallowEqualSelector((state) => state.notifications.roles);
  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );

  function handleSubmit(e) {
    e.preventDefault();
    const send_date = $("#birth_date").val();
    const compose_message = $("#compose_message").val();
    const all_role = $("#all_role").is(":checked");
    const role_ids = Array.from($(".memberselect2").val());
    const to_camp = cars_Info.id;
    const creds = {
      title,
      role_ids,
      compose_message,
      all_role,
      send_date,
      to_camp,
    };
    submitCampMessage(dispatch, creds);
  }
  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  function handleCheckRole() {
    setAllRoleChk((prev) => {
      if (!prev) {
        checkAllRoles();
      } else {
        removeAllRoles();
      }
      return !prev;
    });
  }

  function checkAllRoles() {
    const sel_role = [];
    filtered_roles.forEach((role) => {
      sel_role.push(role.id);
    });
    $("#role_id").val([...sel_role]);
    initDependency();
  }
  function removeAllRoles() {
    $("#role_id").val([]);
    initDependency();
  }

  useLayoutEffect(() => {
    initDependency();
  }, []);

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Notification",
        action: "SEND_NOTIFICATION_TO_CAMP",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      setCarInfo(user?.camp);
    }

    return () => {
      resetNotificationState(dispatch);
    };
  }, [dispatch, history]);

  useEffect(() => {
    const PATTERN = "Camp",
      filtered = roles?.filter(function (str) {
        return str.name.includes(PATTERN);
      });
    setFilterRoles(filtered);
  }, [roles]);

  useEffect(() => {
    $("#title").val(fake_data.title);
    $("#birth_date").val(fake_data.send_date);
    $("#compose_message").val(fake_data.compose_message);
    $("#role_id").val(fake_data.role_ids);

    initDependency();
  }, [fake_data]);
  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>NOTIFICATIONS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Send Camp Message</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              Send Camp Message{" "}
              <span className="lead">
                Camp: <i>{cars_Info?.name}</i>
              </span>
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="send_date">
                    Send Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames({
                      "is-invalid": hasErrorFor("send_date"),
                      "form-control": true,
                    })}
                    id="birth_date"
                    name="name"
                    readOnly
                    placeholder="Select date"
                  />
                  {renderErrorFor("send_date")}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="title">
                    Title <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames({
                      "is-invalid": hasErrorFor("title"),
                      "form-control": true,
                    })}
                    id="title"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                  {renderErrorFor("title")}
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 ">
                  <div className="row">
                    <div
                      className={classnames({
                        "col-11": spinner === true,
                        "col-12": spinner === false,
                      })}
                    >
                      <label>
                        Select Role <span className="text-danger">*</span>
                      </label>
                      <select
                        className={classnames({
                          "is-invalid": hasErrorFor("role_id"),
                          "form-control selected_role": true,
                          memberselect2: true,
                        })}
                        placeholder="Select a Role"
                        id="role_id"
                        multiple="multiple"
                      >
                        <option value="">--Select a role--</option>
                        {filtered_roles &&
                          filtered_roles.map((role, index) => (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          ))}
                      </select>

                      {renderErrorFor("role_id")}
                    </div>
                    {spinner && (
                      <div className="col-1 pt-4">
                        <MiniSpinner />
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group col-md-6 pt-4">
                  <div className="custom-control custom-checkbox">
                    <input
                      className="custom-control-input"
                      type="checkbox"
                      name="all_role"
                      id="all_role"
                      defaultChecked={all_chk_rol}
                      onChange={handleCheckRole}
                    />
                    <label htmlFor="all_role" className="custom-control-label">
                      All Role
                    </label>
                  </div>
                  <div>
                    <i className="fa fa-info-circle text-info mr-1"></i>
                    Check to send to all roles.
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-outline card-info">
                    <div className="card-header">
                      <h3 className="card-title">Compose message</h3>
                      {/* tools box */}
                      <div className="card-tools">
                        <button
                          type="button"
                          className="btn btn-tool btn-sm"
                          data-card-widget="collapse"
                          data-toggle="tooltip"
                          title="Collapse"
                        >
                          <i className="fas fa-minus" />
                        </button>
                      </div>
                      {/* /. tools */}
                    </div>
                    {/* /.card-header */}
                    <div className="card-body pad">
                      <div className="mb-3">
                        <textarea
                          className="textarea "
                          name="compose_message"
                          id="compose_message"
                          placeholder="Place some text here"
                          style={{
                            width: "100%",
                            height: 400,
                            fontSize: 14,
                            lineHeight: 18,
                            border: "1px solid #dddddd",
                            padding: 10,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                type="submit"
                className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                disabled={spinner ? true : false}
              >
                Send
                {spinner && <MiniSpinner />}
              </button>
            </form>
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default ViewSendCampMessages;
