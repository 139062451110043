import React, { Component } from "react";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { getOneCountry } from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";

class DetailsCountries extends Component {
  state = {
    name: null,
    states: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_ROLE" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const id = this.props.match.params.id;
      // get the role from route param
      this.props.getOneCountry(id).then(res => {
        if (res.status === "success") {
          console.log(res.payload);
          this.setState({
            name: res.payload.country.name,
            states: res.payload.country.states
          });
        }
      });
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };

  render() {
    const { spinner, status, message } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">{this.state.name}</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                <span className="text-capitalize ">{this.state.name}</span>{" "}
                Details
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              {spinner ? (
                <Spinner position="right" />
              ) : (
                <div>
                  <table
                    id="country_detail_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%"
                    }}
                  >
                    <thead>
                      <tr>
                        <th>States</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.states &&
                        this.state.states.map((state, index) => (
                          <tr key={index}>
                            <td>{state.name}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}

            {/* /.card-footer*/}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    message: state.preferences.message,
    spinner: state.preferences.spinner,
    status: state.preferences.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getOneCountry: id => getOneCountry(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailsCountries);
