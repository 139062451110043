import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../../hooks";
// import { useGetSystemRoles } from "../../../../store/hookactions/notificationsActionhooks";
import MiniSpinner from "../../../helpers/MiniSpinner";
import Message from "../../../helpers/Message";
import Spinner from "../../../helpers/Spinner";
import {
  resetNotificationState,
  submitScriptureFund,
} from "../../../../store/actions/notificationActions";
import { useDispatch } from "react-redux";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import { storage_type, user_auth_details, dehash } from "../../../../config";
import { event } from "jquery";

function SetMembership(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [enabled, setEnabled] = useState(true);
  const [cars_Info, setCarInfo] = useState({});
  const [life_member, setLifeMember] = useState("0");
  // const [deliquent, setDeliquent] = useState("0.00");
  const [annual_member, setAnnualMember] = useState("0");

  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const status = useShallowEqualSelector((state) => state.notifications.status);
  const errors = useShallowEqualSelector((state) => state.notifications.errors);
  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );

  function handleSubmit(e) {
    e.preventDefault();
    const creds = {
      camp_id: cars_Info?.id,
      type: "membership_goal",
      gideon: life_member,
      aux: annual_member,
    };

    submitScriptureFund(dispatch, creds).then((res) => {
      if (res?.status === "success") {
        setLifeMember("0.00");
        // setDeliquent("0.00");
        setAnnualMember("0.00");
      }
    });
  }
  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  useLayoutEffect(() => {
    initDependency();
  }, []);

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Goals",
        action: "SET_GOAL",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      setCarInfo(user?.camp);
    }

    return () => {
      resetNotificationState(dispatch);
    };
  }, [dispatch, history]);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>GOALS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">Set membership </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              Set Membership{" "}
              <span className="lead">
                Camp: <i>{cars_Info?.name}</i>
              </span>
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="life_member">
                    Gideon <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    step={1}
                    onKeyPress={(event) => {
                      if (event.key === ".") {
                        event.preventDefault();
                      }
                    }}
                    onInput={(event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]*/g,
                        ""
                      );
                    }}
                    className={classnames({
                      "is-invalid": hasErrorFor("gideon"),
                      "form-control": true,
                    })}
                    value={life_member}
                    id="life_member"
                    name="life_member"
                    onChange={(e) => setLifeMember(e.target.value)}
                  />
                  {renderErrorFor("gideon")}
                </div>
                {/* <div className="form-group col-md-6">
                  <label htmlFor="deliquent">
                    Deliquent <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("deliquent"),
                      "form-control": true,
                    })}
                    id="deliquent"
                    name="deliquent"
                    value={deliquent}
                    onChange={(e) => setDeliquent(e.target.value)}
                  />
                  {renderErrorFor("deliquent")}
                </div> */}
                <div className="form-group col-md-6">
                  <label htmlFor="annual_member">
                    Auxiliary<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    step={1}
                    onKeyPress={(event) => {
                      if (event.key === ".") {
                        event.preventDefault();
                      }
                    }}
                    onInput={(event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9]*/g,
                        ""
                      );
                    }}
                    className={classnames({
                      "is-invalid": hasErrorFor("aux"),
                      "form-control": true,
                    })}
                    id="annual_member"
                    name="annual_member"
                    value={annual_member}
                    onChange={(e) => setAnnualMember(e.target.value)}
                  />
                  {renderErrorFor("aux")}
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                disabled={spinner ? true : false}
              >
                Set goal
                {spinner && <MiniSpinner />}
              </button>
            </form>
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default SetMembership;
