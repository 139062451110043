import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  updateArea,
  getAllRegionsArea
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
// import $ from "jquery";

class UpdateAreas extends Component {
  state = {
    name: "",
    region_id: "",
    regions: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "UPDATE_AREA" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const id = this.props.match.params.id;
      this.props.getAllRegionsArea(id).then(res => {
        // console.log(res);
        if (res.area.status === "success" && res.regions.status === "success") {
          this.setState({
            regions: res.regions.payload.region,
            name: res.area.payload.area.name,
            region_id: res.area.payload.area.region.id
          });
        }
      });
    }
  }

  handleSelectRegion = e => {
    this.setState({ region_id: e.target.value });
  };
  handleSubmit = e => {
    e.preventDefault();
    const { updateArea } = this.props;
    const creds = {
      id: this.props.match.params.id,
      name: this.state.name,
      region_id: this.state.region_id
    };
    updateArea(creds).then(res => {
      if (res.status === "success") {
        // this.setState({
        //   name: "",
        //   region_id: ""
        // });
        // $(".selected_region").val("");
      }
    });
  };
  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Update Area</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">Upadate Area</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("name"),
                        "form-control": true
                      })}
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("name")}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Regions <span className="text-danger">*</span>
                    </label>
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("region_id"),
                        "form-control selected_region": true
                      })}
                      placeholder="Select a Region"
                      onChange={this.handleSelectRegion}
                      id="region_id"
                      value={this.state.region_id}
                    >
                      <option value="">--Select a Region--</option>
                      {this.state.regions &&
                        this.state.regions.map((region, index) => (
                          <option key={index} value={region.id}>
                            {region.name} -- {region.state.name}
                          </option>
                        ))}
                    </select>
                    {this.renderErrorFor("region_id")}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  UPDATE
                </button>
                {hasPermission({
                  mod: "Preferences",
                  action: "VIEW_AREA"
                }) ? (
                  <Link
                    to="/preferences/areas"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  >
                    View Areas
                  </Link>
                ) : null}
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    errors: state.preferences.errors
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllRegionsArea: id => getAllRegionsArea(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    updateArea: creds => updateArea(dispatch, creds)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateAreas);
