import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAllInventory,
  setHQInventory
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import MiniSpinner from "./../../../helpers/MiniSpinner";

class SetHQInventoryLimit extends Component {
  state = {
    hq_inventory: null,
    bibles: []
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllInventory } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "UPDATE_HQ_INVENTORY" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllInventory();
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  setBibles = () => {
    const bibles = [];
    this.state.hq_inventory.forEach(item => {
      bibles.push({
        bible_id: item.bible_id,
        limit: item.limit
      });
    });
    this.setSate({
      bibles
    });
  };
  handleChange = e => {
    const bibles = [...this.state.bibles];
    if (bibles.length) {
      // eslint-disable-next-line
      const bible = bibles.find(item => item.bible_id == e.target.id);
      if (bible) {
        bible.limit = e.target.value ? e.target.value : "0";
      } else {
        bibles.push({
          bible_id: e.target.id,
          limit: e.target.value ? e.target.value : "0"
        });
      }
    } else {
      bibles.push({ bible_id: e.target.id, limit: e.target.value });
    }
    this.setState({
      bibles
    });
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.hq_inventory !== prevState.hq_inventory) {
      const bibles = [];
      nextProps.hq_inventory.forEach(item => {
        bibles.push({
          bible_id: item.bible_id,
          limit: item.limit
        });
      });
      return {
        hq_inventory: nextProps.hq_inventory,
        bibles
      };
    }
    return null;
  }
  handleSubmit = e => {
    e.preventDefault();
    const { setHQInventory } = this.props;
    setHQInventory({ bibles: this.state.bibles });
  };

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    // if (this.state.hq_inventory) {
    //   this.setBibles();
    // }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Set Hq Inventory Reorder Limit
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Set Hq Inventory Reorder Limit
              </h3>
              <div className="card-tools">
                <Link to="/camp-inventory/create">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle "></i>
                    ADD INVENTORY
                  </button>
                </Link>{" "}
                <Link to="/camp-inventory/remove">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-minus-circle mr-1"></i>
                    REMOVE INVENTORY
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}

              <form onSubmit={this.handleSubmit}>
                <table
                  id=""
                  className="table table-hover table-bordered table-striped"
                  style={{
                    width: "100%"
                  }}
                >
                  <thead>
                    <tr>
                      <th>Inventory</th>

                      <th> Reorder Limit</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.hq_inventory &&
                      this.state.hq_inventory.map((inventory, index) => {
                        return (
                          <tr key={index}>
                            <td>{inventory.bible.name}</td>
                            <td>
                              <input
                                type="number"
                                name=""
                                id={inventory.bible_id}
                                min={0}
                                defaultValue={inventory.limit}
                                onChange={this.handleChange}
                              />
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Submit
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    hq_inventory: state.preferences.hq_inventory,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllInventory: () => dispatch(getAllInventory()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    setHQInventory: creds => setHQInventory(dispatch, creds)
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SetHQInventoryLimit);
