import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../../hooks";
// import { useGetSystemRoles } from "../../../../store/hookactions/notificationsActionhooks";
import MiniSpinner from "../../../helpers/MiniSpinner";
import Message from "../../../helpers/Message";
import Spinner from "../../../helpers/Spinner";
import {
  resetNotificationState,
  submitScriptureFund,
} from "../../../../store/actions/notificationActions";
import { useDispatch } from "react-redux";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import { storage_type, user_auth_details, dehash } from "../../../../config";

function SetScriptureDistribution(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  // const [enabled, setEnabled] = useState(true);
  const [cars_Info, setCarInfo] = useState({});
  const [full_bible, setFullBible] = useState("0.00");
  const [hospital, setHospital] = useState("0.00");
  const [college_testaments, setCollegeTestaments] = useState("0.00");
  const [pwt, setPWT] = useState("0.00");
  const [medical_testaments, setMedicalTestaments] = useState("0.00");

  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const status = useShallowEqualSelector((state) => state.notifications.status);
  const errors = useShallowEqualSelector((state) => state.notifications.errors);
  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );

  function handleSubmit(e) {
    e.preventDefault();
    const creds = {
      camp_id: cars_Info?.id,
      type: "scripture_distribution_goal",
      full_bible,
      college_testaments,
      pwt,
      medical_testaments,
      hospital,
    };

    submitScriptureFund(dispatch, creds).then((res) => {
      if (res?.status === "success") {
        setFullBible("0.00");
        setCollegeTestaments("0.00");
        setPWT("0.00");
        setMedicalTestaments("0.00");
        setHospital("0.00");
      }
    });
  }
  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  useLayoutEffect(() => {
    initDependency();
  }, []);

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Goals",
        action: "SET_GOAL",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      setCarInfo(user?.camp);
    }

    return () => {
      resetNotificationState(dispatch);
    };
  }, [dispatch, history]);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>GOALS</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  Set scripture distribution{" "}
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              Set Scripture Distribution{" "}
              <span className="lead">
                Camp: <i>{cars_Info?.name}</i>
              </span>
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="full_bible">
                    Full Bible <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={0}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("full_bible"),
                      "form-control": true,
                    })}
                    value={full_bible}
                    id="full_bible"
                    name="full_bible"
                    onChange={(e) => setFullBible(e.target.value)}
                  />
                  {renderErrorFor("full_bible")}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="hospital">
                    Hospital <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("hospital"),
                      "form-control": true,
                    })}
                    id="hospital"
                    name="hospital"
                    value={hospital}
                    onChange={(e) => setHospital(e.target.value)}
                  />
                  {renderErrorFor("hospital")}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="college_testaments">
                    College Testaments<span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("college_testaments"),
                      "form-control": true,
                    })}
                    id="college_testaments"
                    name="college_testaments"
                    value={college_testaments}
                    onChange={(e) => setCollegeTestaments(e.target.value)}
                  />
                  {renderErrorFor("college_testaments")}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="pwt">
                    PWT <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("pwt"),
                      "form-control": true,
                    })}
                    id="pwt"
                    name="pwt"
                    value={pwt}
                    onChange={(e) => setPWT(e.target.value)}
                  />
                  {renderErrorFor("pwt")}
                </div>
                <div className="form-group col-md-6">
                  <label htmlFor="medical_testaments">
                    Medical Testaments <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    min={1}
                    step={0.01}
                    className={classnames({
                      "is-invalid": hasErrorFor("medical_testaments"),
                      "form-control": true,
                    })}
                    id="medical_testaments"
                    name="medical_testaments"
                    value={medical_testaments}
                    onChange={(e) => setMedicalTestaments(e.target.value)}
                  />
                  {renderErrorFor("medical_testaments")}
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                disabled={spinner ? true : false}
              >
                Set goal
                {spinner && <MiniSpinner />}
              </button>
            </form>
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default SetScriptureDistribution;
