import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import axios from "axios";
import rootReducer from "./store/reducers/rootReducer";
import {
  token,
  user_permission_details,
  user_auth_details,
  system_all_permission,
  storage_type,
} from "./config";
import * as serviceWorker from "./serviceWorker";
const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(thunk))
);
// composeWithDevTools(applyMiddleware(thunk))
// applyMiddleware(thunk)
const client = new QueryClient();
window.$depotID = 0;
window.$Staffs = [];
window.$Depots = [];
window.$msg = null;
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // console.log(error.response.data.message);
    const err = error.response.data.message;
    if (
      err === "Session is invalid. Signin again to continue" ||
      err === "Session has expired. Signin again to continue" ||
      err === "Token has expired"
    ) {
      const resMessage = {
        status: "error",
        // message: err,
        message: "Session has expired. Signin again to continue",
      };
      storage_type.removeItem(token);
      storage_type.removeItem(user_permission_details);
      storage_type.removeItem(user_auth_details);
      storage_type.removeItem(system_all_permission);
      // store.dispatch({ type: "UNLOAD_SPINNER" });
      store.dispatch({ type: "LOGOUT_USER", resMessage });
      // return <Redirect to="/login" />;
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(
  <QueryClientProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
    <ReactQueryDevtools />
  </QueryClientProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
