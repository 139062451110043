import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  updateCampActivityLeg,
  getOneCampActivityLeg,
  resetUsersState,
} from "./../../../../store/actions/usersActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import classnames from "classnames";
import $ from "jquery";

class UpdateActivityLeg extends Component {
  state = {
    type: "",
    activity_date: "",
    location: "",
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Camp Administration",
        action: "VIEW_CAMP_ADMINISTRATION",
      }) ||
      !hasPermission({
        mod: "Camp Administration",
        action: "CREATE_CAMP_ACTIVITY",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    const id = this.props.match.params.id;

    this.props.getOneCampActivityLeg(id).then((res) => {
      if (res.status === "success") {
        this.setState({
          //   type: res.payload.activity.type,
          activity_date: res.payload.activity.activity_date,
          location: res.payload.activity.location,
          description: res.payload.activity.description,
          purpose: res.payload.activity.purpose,
        });
        $("#activity_date").val(res.payload.activity.activity_date);
      }
    });
    this.setState({ id });
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const activity_date = $("#activity_date").val();
    this.setState({ activity_date }, () => {
      this.props.updateCampActivityLeg(this.state).then((res) => {
        if (res.status === "success") {
          //   this.setState({
          //     type: "",
          //     activity_date: "",
          //     activity_time: "",
          //     location: "",
          //     target: "",
          //     description: "",
          //     funding: "",
          //     speaker: "",
          //   });
          //   $("#activity_date").val("");
        }
      });
    });
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>CAMP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Camp Activity</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Edit Activity (Legacy/Bequest)
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    {/* <div className="form-group col-md-6">
                      <label>
                        Type <span className="text-danger">*</span>
                      </label>
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("type"),
                          "form-control ": true,
                        })}
                        placeholder="Select"
                        onChange={this.handleChange}
                        id="type"
                        value={this.state.type}
                      >
                        <option value="">--Select--</option>
                        <option value="Camp Prayer Meeting">
                          Camp Prayer Meeting
                        </option>
                        <option value="Camp Cabinet Meeting">
                          Camp Cabinet Meeting
                        </option>
                        <option value="Camp Business Meeting">
                          Camp Business Meeting
                        </option>
                      </select>
                      {this.renderErrorFor("type")}
                    </div> */}
                    <div className="form-group col-md-6">
                      <label htmlFor="activity_date">
                        Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("activity_date"),
                          "form-control": true,
                        })}
                        id="activity_date"
                        name="activity_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("activity_date")}
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="location">Location</label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("location"),
                          "form-control": true,
                        })}
                        id="location"
                        name="location"
                        value={this.state.location}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("description")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="description">Description</label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("description"),
                          "form-control": true,
                        })}
                        id="description"
                        name="description"
                        value={this.state.description}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("description")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="purpose">Purpose</label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("purpose"),
                          "form-control": true,
                        })}
                        id="purpose"
                        name="purpose"
                        value={this.state.purpose}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("purpose")}
                    </div>

                    {/* <div className="form-group col-md-6">
                      <label htmlFor="location">Location</label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("location"),
                          "form-control": true,
                        })}
                        id="location"
                        name="location"
                        value={this.state.location}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("location")}
                    </div> */}
                    {/* <div className="form-group col-md-6">
                      <label htmlFor="funding">
                        Funding Expected<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="funding"
                        name="funding"
                        value={this.state.funding}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("funding")}
                    </div> */}
                    {/* <div className="form-group col-md-6">
                      <label htmlFor="speaker">
                        Speaker<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="speaker"
                        name="speaker"
                        value={this.state.speaker}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("speaker")}
                    </div> */}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold mr-1"
                    disabled={spinner ? true : false}
                  >
                    UPDATE
                  </button>
                  <Link
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                    to="/camp/activities/legacy/pending"
                  >
                    View Pending Activities
                  </Link>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    updateCampActivityLeg: (creds) => updateCampActivityLeg(dispatch, creds),
    resetUsersState: () => resetUsersState(dispatch),
    getOneCampActivityLeg: (id) => getOneCampActivityLeg(dispatch, id),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateActivityLeg);
