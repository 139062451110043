import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  createReportOneRec,
  getOneCampActivityRec,
  resetUsersState,
  cancelRemitaRenewalRec,
} from "./../../../../store/actions/usersActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import classnames from "classnames";
import $ from "jquery";
import MiniSpinner from "../../../helpers/MiniSpinner";
import queryString from "query-string";

class SubmitActivityReportRec extends Component {
  state = {
    type: "",
    activity_date: "",
    target_area: "",
    description: "",
    // funding: "",
    // speaker: "",
    // location: "",
    modal_2: false,
    modal_1: false,
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD",
      }) ||
      !hasPermission({
        mod: "Personal Management",
        action: "CAMP_ACTIVITY_REPORT_SUBMISSION",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    let params = queryString.parse(this.props.location.search);

    if (params.message && params.message === "Approved") {
      this.setState({
        ...params,
        modal_1: true,
        info: "Transaction Successful",
      });
    }
    if (params.message && params.message !== "Approved") {
      this.setState({ ...params, modal_1: true, info: "Transaction Failed" });
    }

    const id = this.props.match.params.id;

    this.props.getOneCampActivityRec(id).then((res) => {
      if (res.status === "success") {
        this.setState({
          type: res.payload.activity.type,
          activity_date: res.payload.activity.activity_date,
          target_area: res.payload.activity.target_area,
          description: res.payload.activity.description,
          created_at: res.payload.activity.created_at,
          prospect: res.payload.activity.prospect,
          // location: res.payload.activity.location,
          // funding: res.payload.activity.funding,
          // speaker: res.payload.activity.speaker,
        });
        $("#activity_date").val(res.payload.activity.activity_date);
      }
    });
    this.setState({ id });
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const pay_channel = this.state.channel;
    if (pay_channel !== " " && pay_channel === "remita") {
      const paid_date = $("#evidence_date").val();
      this.setState({ paid_date }, () => {
        const activity_date = $("#activity_date").val();
        const id = this.props.match.params.id;
        if (window.confirm("Are you sure you want to submit this report?")) {
          this.setState({ activity_date, id }, () => {
            this.props.createReportOneRec(this.state).then((res) => {
              if (res.status === "success") {
                const obj = res.payload;
                this.setState({ ...obj }, () => {
                  this.setState({ modal_2: true });
                });
                //   this.setState({
                //     type: "",
                //     activity_date: "",
                //     activity_time: "",
                //     location: "",
                //     target: "",
                //     description: "",
                //     funding: "",
                //     speaker: "",
                //   });
                //   $("#activity_date").val("");
              }
            });
          });
        }
      });
    } else {
      const paid_date = $("#evidence_date").val();
      this.setState({ paid_date }, () => {
        const activity_date = $("#activity_date").val();
        const id = this.props.match.params.id;
        if (window.confirm("Are you sure you want to submit this report?")) {
          this.setState({ activity_date, id }, () => {
            this.props.createReportOneRec(this.state).then((res) => {
              if (res.status === "success") {
                //   this.setState({
                //     type: "",
                //     activity_date: "",
                //     activity_time: "",
                //     location: "",
                //     target: "",
                //     description: "",
                //     funding: "",
                //     speaker: "",
                //   });
                //   $("#activity_date").val("");
              }
            });
          });
        }
      });
    }
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  closeModal_2 = (e) => {
    e.preventDefault();
    const rrr = this.state.rrr;
    this.props.cancelRemitaRenewalRec(rrr).then((res) => {
      if (res.status === "success") {
        this.setState({
          modal_2: false,
          merchantId: "",
          rrr: "",
          response_url: "",
          action_url: "",
          hash: "",
        });
      }
    });
    // this.props.resetUsersState();
  };
  closeModal_1 = (e) => {
    e.preventDefault();
    this.setState({ modal_1: false });
  };
  handleFileChange = (e) => {
    this.setState({
      evidence: e.target.files[0],
    });
  };

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PERSONAL</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create Activity Report
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Create Activity Report (Recruitment)
              </h3>
              <div className="card-tools"></div>
            </div>
            {this.state.modal_2 ? (
              <div
                //   className="card opp"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  // padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center",

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form action={this.state.action_url} method="POST">
                    <h4 className="text-center mt-3">
                      Pay &#8358; {this.moneyConv(this.state.amount)} with
                      Remita{" "}
                      <img
                        src="/dist/img/remita.png"
                        alt=""
                        width="50"
                        style={{ position: "relative", top: "-1px" }}
                      />
                    </h4>
                    <p className="text-center text-danger ">
                      <small className="font-weight-bold">
                        Please note that you may be charge proccessing fee of
                        &#8358; {this.moneyConv(100)}{" "}
                      </small>
                    </p>
                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <input
                        id="merchantId"
                        name="merchantId"
                        value={this.state.merchantId}
                        type="hidden"
                      />
                      <input
                        id="rrr"
                        name="rrr"
                        value={this.state.rrr}
                        type="hidden"
                      />
                      <input
                        id="responseurl"
                        name="responseurl"
                        value={this.state.response_url}
                        type="hidden"
                      />
                      <input
                        id="hash"
                        name="hash"
                        value={this.state.hash}
                        type="hidden"
                      />
                      {/* <div className="form-group">
                            <label htmlFor="comment">
                              Comment <span className="text-danger">*</span>{" "}
                            </label>
                            <textarea
                              className={classnames({
                                "is-invalid": this.hasErrorFor("comment"),
                                "form-control": true
                              })}
                              id="comment"
                              name="comment"
                              rows={2}
                              onChange={this.handleChange}
                              value={this.state.comment}
                            />

                            {this.renderErrorFor("comment")}
                          </div> */}

                      <button
                        // type="submit"
                        className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                      >
                        Pay
                      </button>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_2}
                      >
                        Cancel
                        {spinner ? <MiniSpinner /> : null}
                      </button>
                    </div>
                  </form>
                </div>

                {/* /.card-body */}
              </div>
            ) : null}

            {this.state.modal_1 ? (
              <div
                //   className="card opp"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  // padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center",

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                      // className="text-center mt-3"
                      >
                        {this.state.info}{" "}
                      </h4>
                      <i
                        style={{
                          fontSize: "1.6rem",
                          color: "#bda230",
                        }}
                        className={classnames({
                          "fas fa-check": this.state.message === "Approved",
                          "fas fa-exclamation-triangle":
                            this.state.message !== "Approved",
                        })}
                      ></i>
                    </div>

                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <p>
                        <strong>
                          {" "}
                          Order ID : {this.state.orderId}{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_1}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                  {/* <hr /> */}
                  {/* <div className="text-center">
                   
                  </div> */}
                </div>

                {/* /.card-body */}
              </div>
            ) : null}

            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <div>
                <div
                  className="row d-flex justify-content-between"
                  style={{
                    overflow: "hidden",
                  }}
                >
                  <div>
                    <p className="font-weight-bold">
                      Activity Type:{" "}
                      <span className="font-italic">
                        {this.state.type ? this.state.type : null}
                      </span>{" "}
                    </p>
                    {/* <p className="font-weight-bold">
                      Expected Funding:{" "}
                      <span>
                        {" "}
                        &#8358;
                        {this.state.funding
                          ? this.moneyConv(this.state.funding)
                          : 0}
                      </span>{" "}
                    </p> */}
                    <p className="font-weight-bold">
                      target Area:{" "}
                      <span>
                        {this.state.target_area ? this.state.target_area : null}
                      </span>{" "}
                    </p>
                    <p className="font-weight-bold">
                      Activity Description:{" "}
                      <span>
                        {this.state.description ? this.state.description : null}
                      </span>{" "}
                    </p>
                    <p className="font-weight-bold">
                      Prospect:{" "}
                      <span>
                        {this.state.prospect ? this.state.prospect : null}
                      </span>{" "}
                    </p>
                  </div>
                  <div>
                    {/* <p className="font-weight-bold">
                      Location:{" "}
                      <span className="font-italic">
                        {this.state.location ? this.state.location : null}
                      </span>{" "}
                    </p> */}
                    {/* <p className="font-weight-bold">
                      Speaker:{" "}
                      <span className="font-italic">
                        {this.state.speaker ? this.state.speaker : null}
                      </span>{" "}
                    </p> */}
                    <p className="font-weight-bold">
                      Created Date:{" "}
                      <span className="font-italic">
                        {/* {this.moneyConv(this.state.stock)} */}
                        {this.state.created_at
                          ? this.dateConv(this.state.created_at)
                          : null}
                      </span>{" "}
                    </p>
                    <p className="font-weight-bold">
                      Scheduled Date:{" "}
                      <span className="font-italic">
                        {/* {this.moneyConv(this.state.stock)} */}
                        {this.state.activity_date
                          ? this.dateConv(this.state.activity_date)
                          : null}
                      </span>{" "}
                    </p>
                  </div>
                </div>
                <hr className="mb-5" />
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="amount">Realized Funds </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("amount"),
                          "form-control": true,
                        })}
                        id="amount"
                        name="amount"
                        value={this.state.amount}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("amount")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="presentations">No of presentations</label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("presentations"),
                          "form-control": true,
                        })}
                        id="presentations"
                        name="presentations"
                        value={this.state.presentations}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("presentations")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="speaker"> Speaker</label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("speaker"),
                          "form-control": true,
                        })}
                        id="speaker"
                        name="speaker"
                        value={this.state.speaker}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("speaker")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="male">Male Attendance</label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("male"),
                          "form-control": true,
                        })}
                        id="male"
                        name="male"
                        value={this.state.male}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("male")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="female">
                        Female Attendance<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("female"),
                        })}
                        id="female"
                        name="female"
                        value={this.state.female}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("female")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="guest">
                        Guest Attendance<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("guest"),
                        })}
                        id="guest"
                        name="guest"
                        value={this.state.guest}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("guest")}
                    </div>
                    {/* <div className="form-group col-md-6">
                      <label htmlFor="prospect">
                        Prospect<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("prospect"),
                        })}
                        id="prospect"
                        name="prospect"
                        value={this.state.prospect}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("prospect")}
                    </div> */}
                    <div className="form-group col-md-6">
                      <label htmlFor="signup">
                        Sign-Up<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("signup"),
                        })}
                        id="signup"
                        name="signup"
                        value={this.state.signup}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("signup")}
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="channel">
                          Payment Type<span className="text-danger">*</span>
                        </label>
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("channel"),
                            "form-control": true,
                          })}
                          id="channel"
                          name="channel"
                          onChange={this.handleChange}
                          value={this.state.channel}
                        >
                          <option value="">--Select--</option>
                          <option value="remita">Remita</option>
                          <option value="transfer">Transfer</option>
                          <option value="deposit">Deposit</option>
                          <option value="cash">Cash</option>
                          <option value="cheque">Cheque</option>
                        </select>
                        {this.renderErrorFor("channel")}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="transaction_id">
                          Transaction ID / Reference ID / Teller No.
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("transaction_id"),
                            "form-control": true,
                          })}
                          id="transaction_id"
                          name="transaction_id"
                          onChange={this.handleChange}
                          value={this.state.transaction_id}
                        />
                        {this.renderErrorFor("transaction_id")}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="evidence_date">Date</label>
                        <input
                          type="text"
                          readOnly
                          id="evidence_date"
                          className="form-control"
                        />
                        {this.renderErrorFor("paid_date")}
                      </div>
                    </div>

                    <div className=" col-6 mx-auto">
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          {" "}
                          Attach Evidence of payment if any{" "}
                        </label>
                        <input
                          type="file"
                          id="evidence"
                          onChange={this.handleFileChange}
                        />
                        <br />
                        <small>
                          <b>
                            File types expected are png,jpeg and jpg not greater
                            than 2MB
                          </b>
                        </small>
                        {this.renderErrorFor("evidence")}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold mr-1"
                    disabled={spinner ? true : false}
                  >
                    Submit
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>
                  <Link
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                    to="/personal/activities/recruitment/report"
                  >
                    Back
                  </Link>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    createReportOneRec: (creds) => createReportOneRec(dispatch, creds),
    resetUsersState: () => resetUsersState(dispatch),
    cancelRemitaRenewalRec: (creds) => cancelRemitaRenewalRec(dispatch, creds),
    getOneCampActivityRec: (id) => getOneCampActivityRec(dispatch, id),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubmitActivityReportRec);
