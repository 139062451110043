import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getAreaPerformanceReport,
  resetReportState,
} from "./../../../../store/actions/reportActions";
import {
  setAll,
  getAllAreas,
} from "../../../../store/actions/preferencesActions";

import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import classnames from "classnames";
import MiniSpinner from "./../../../helpers/MiniSpinner";
import $ from "jquery";
// import _ from "underscore";
// import { hasPermission } from "./../../helpers/functions/functions";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import { BASE_URL, token, storage_type } from "../../../../config";

class AreaPerformanceReport extends Component {
  state = {
    camps: null,
    performance_area: null,
    area_id: "",
    areas: null,
    user_area: null,
    // arranged_data: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REPORTS",
      }) ||
      !hasPermission({
        mod: "Reports",
        action: "VIEW_AREAS_PERFORMANCE_REPORTS",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    document.title =
      "The Gideon International In Nigeria (Area Performance Report)";
    this.initDependency();
    // const { getAreaPerformanceReport } = this.props;
    // getAreaPerformanceReport(1, "12/12/2020", "15/12/2020");
    // this.props.getAllCamps();
    this.props.setAll();
    this.props.getAllAreas();
  }
  initDependency = () => {
    // alert("here");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.areas !== prevState.areas ||
      nextProps.user_area !== prevState.user_area ||
      nextProps.performance_area !== prevState.performance_area
    ) {
      //   const arry = [];
      //   const temp_performance_area = [...nextProps.performance_area];
      //   if (temp_performance_area.length) {
      //     const camp_group = _.groupBy(temp_performance_area, "camp");
      //     for (let [key, value] of Object.entries(camp_group)) {
      //       arry.push({
      //         camp: key,
      //         fund: _.groupBy(value, "purpose")
      //       });
      //     }
      //     // console.log(arry);
      //     // this.updateState(arry);
      //   }

      return {
        areas: nextProps.areas,
        performance_area: nextProps.performance_area,
        user_area: nextProps.user_area,
      };
    }
    return null;
  }
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  handleDownload = (e) => {
    e.preventDefault();
    const from = $("#from_date").val();
    const to = $("#to_date").val();
    const area = this.state.area_id;
    const tok = storage_type.getItem(token);
    const download = 1;
    const url = `${BASE_URL}/reports/areas/performance?from=${from}&to=${to}&area=${area}&download=${download}&token=${tok}`;
    window.open(url, "_blank");
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const from_date = $("#from_date").val();
    const to_date = $("#to_date").val();
    // const id = $(".memberselect22").val();

    const area = this.state.area_id;
    // alert(area);
    this.setState(
      {
        from_date,
        to_date,
        // id
      },
      () => {
        if (window.confirm("Are you sure to want to generate this report?")) {
          this.props.getAreaPerformanceReport(from_date, to_date, area);
        }
      }
    );
  };

  // arrangeReport = () => {};
  // updateState = arry => {
  //   const dd = arry;
  //   setTimeout(() => {
  //     this.setState({ new_arry: dd });
  //   }, 1000);
  // };
  setDownload = (e) => {
    e.preventDefault();
    $(".fund_table").attr("id", "role_table22");
    this.initDependency();
  };
  handleSelectArea = (e) => {
    this.setState({ area_id: e.target.value });
  };
  componentWillUnmount() {
    this.props.resetReportState();
  }

  render() {
    const { spinner, message, status, preference_spinner } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    // if (this.state.performance_area) {
    //   this.arrangeReport();
    // }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>REPORT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Performance Report</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Performance Report
              </h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "3000px" }}>
                  <Spinner />
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row  ">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="from_date">
                        From Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("from_date"),
                          "form-control": true,
                        })}
                        id="from_date"
                        name="from_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("from_date")}
                    </div>
                  </div>
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="to_date">
                        To Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("to_date"),
                          "form-control": true,
                        })}
                        id="to_date"
                        name="to_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("to_date")}
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="camp_id">
                        Camp <span className="text-danger">*</span>{" "}
                      </label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%"
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.camps &&
                            this.state.camps.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                        </select>
                        {preference_spinner ? <MiniSpinner /> : null}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div> */}
                  <div className=" col-sm-3">
                    <label>Area</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("area_id"),
                          "form-control selected_area": true,
                        })}
                        placeholder="Select a Role"
                        onChange={this.handleSelectArea}
                        id="area_id"
                        // defaultValue={this.state.area_id}
                      >
                        <option value="">--Select an Area--</option>
                        {this.state.user_area &&
                          this.state.user_area.map((area, index) => (
                            <option key={index} value={area.id}>
                              {area.name}--{area.region.name}--
                              {area.region.state.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor("area_id")}
                  </div>
                </div>
                <div className="row mb-3">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    disabled={spinner ? true : false}
                  >
                    Generate
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>
                  {/* <button
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button> */}
                  {/* {this.state.performance_area.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}
                </div>
              </form>

              {this.state.performance_area.length && !spinner ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                      overflow: "scroll",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Gideons </th>
                        <th>Auxilliaries </th>
                        <th>New Gideons </th>
                        <th> New Auxilliaries </th>
                        <th>Gideon Renewals</th>
                        <th>Auxilliary Renewals</th>
                        <th>Scripture Distribution (Demand)</th>
                        <th>Scripture Distribution (Actual)</th>
                        <th> Gideon Scripture Funds</th>
                        <th> Auxilliary Scripture Funds</th>
                        <th> Gideon Contributions</th>
                        <th> Auxilliary Contributions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.performance_area &&
                        this.state.performance_area.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.area}</td>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>{item.members["total_gideon"] || 0}</td>
                                <td>{item.members["total_aux"] || 0}</td>
                                <td>{item.new_members["total_gideon"] || 0}</td>
                                <td>{item.new_members["total_aux"] || 0}</td>
                                <td>{item.renewals["gideon_renewal"] || 0}</td>
                                <td>{item.renewals["aux_renewal"] || 0}</td>
                                <td>{item.hq_scripture["demand"] || 0}</td>
                                <td>{item.hq_scripture["actuals"] || 0}</td>
                                <td>
                                  {item.gideon_scripture_funds["amount"] || 0}
                                </td>
                                <td>
                                  {item.aux_scripture_funds["amount"] || 0}
                                </td>
                                <td>
                                  {item.gideon_contributions.length
                                    ? item.gideon_contributions.map(
                                        (itm, ind) => (
                                          <span
                                            className="badge badge-secondary"
                                            key={ind}
                                          >
                                            {" "}
                                            {`amount: ${itm.amount}, type: ${itm.type}`}
                                          </span>
                                        )
                                      )
                                    : 0}
                                </td>
                                <td>
                                  {item.aux_contributions.length
                                    ? item.aux_contributions.map((itm, ind) => (
                                        <span
                                          className="badge badge-secondary"
                                          key={ind}
                                        >
                                          {" "}
                                          {`amount: ${itm.amount}, type: ${itm.type}`}
                                        </span>
                                      ))
                                    : 0}
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.reports.spinner,
    message: state.reports.message,
    status: state.reports.status,
    errors: state.reports.errors,
    performance_area: state.reports.performance_area,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
    areas: state.preferences.areas,
    user_area: state.preferences.user_area,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAreaPerformanceReport: (from, to, area) =>
      dispatch(getAreaPerformanceReport(from, to, area)),
    // getAllCamps: () => dispatch(getAllCamps()),
    getAllAreas: () => dispatch(getAllAreas()),
    setAll: () => dispatch(setAll()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetReportState: () => resetReportState(dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AreaPerformanceReport);
