import React, { Component,Suspense } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  getStaffDepot,
  assignDepot
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import $ from "jquery";
import axios from "axios";
import {
  BASE_URL,
  token,
  storage_type,
  // user_auth_details,
  // dehash,
  depotnamelist,
  UserIdList
} from '../../../../config';

// const myrow = {
//     display: "flex",
//     flexdirection: "row",
//     flexwrap: "wrap",
//     width: 100%
// };

const msg='';

const mystyle = {
  display: "flex",
  color: "white",
  backgroundColor: "DodgerBlue",
  padding: "10px",
  fontFamily: "Arial"
};



function TableDepotList(props) {
  return(
    <Suspense
      fallback={<h1>Loading depot names...</h1>}
    >
    <table>
      <tr>
        {props.headers.map((item,index) => <th key={index} style= {{backgroundColor: '#d4e6fd',fontFamily: 'helvetica', fontSize: 11,textDecoration: 'underline',border:0}}>{item}</th>)}
      </tr>
      <tbody>
        <div style={{ overflow: 'auto',height:'200px'}}>
          {props.formElements.map((item,index) => <tr key={index}><td>{item.name}</td></tr> )}
        </div>
      </tbody>
    </table>
    </Suspense>
  )
}


class AssignDepot extends Component {
  constructor(props){
    super(props);
    this.state = {
      depots: null,
        staff_id: "",
        staffs: null,
        depot_id: "",
        depo_list:[],
        depotName: "",
        depotNameLst: [],
        selectedDepot: '',
        depotAssignedMsg: '',
    };
    this.handleDepotChange = this.handleDepotChange.bind(this);
  }

  InitDepoArrayLst = () => {
    var DepotArrLst = [];
    var DepotNameArrLst = [];
    const DepotName = {
      name: ''
    };
  };

  // state = {
  //   depots: null,
  //   staff_id: "",
  //   staffs: null,
  //   depot_id: "",
  //   depo_list:[],
  //   depotNameLst: [],
  //   selectedDepot: '',
  // };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "ASSIGN_DEPOT" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      this.props.getStaffDepot().then(res => {
        // console.log(res);
        if (
          res.staffs.status === "success" &&
          res.depots.status === "success"
        ) {
          this.setState({
            depots: res.depots.payload.depots,
            staffs: res.staffs.payload.staffs
          });
        }
      });
    }
  }

  //CODE ADDITION BY MACDONALD
  //------------------------------------------------------------------------------
  isDepotAlreadyAssigned(depotid,stat){
    var initArr = [];
    axios.get(`${BASE_URL}/depot-already-assigned?id=${depotid}`,
      {method:'GET',
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage_type.getItem(token)}`},
        }, 
        ).then(response => {
          let msg = response.data.message;
          this.setState({  
            depotAssignedMsg: response.data.message //payload.DepotAlreadyAssigned
          });  
          if(msg !== "Depot Already Assigned" && stat === "init"){
            var dpName = this.getDepotname(depotid);
            console.log("Initial Depot names: ", depotnamelist);
            initArr.push(depotid.toString());
            this.InitDepoArrayLst.DepotArrLst = initArr;
            console.log("Initial Array: ", initArr);
            this.setState({depo_list: initArr});
            console.log("Init Already Assigned Depot: ", msg);
          }else if(msg !== "Depot Already Assigned" && stat === "cont" ){
            var dpLst = this.InitDepoArrayLst.DepotArrLst.slice(); //this.state.depo_list.slice();
            dpLst.push(depotid.toString());
            this.setState({
              depo_list: dpLst  //depot_id_list
            });
            this.InitDepoArrayLst.DepotArrLst=dpLst;
            dpName = this.getDepotname(depotid);

            console.log("Next Depot names: ", depotnamelist);
            console.log("Selected Depot IDs: ", this.InitDepoArrayLst.DepotArrLst);

            this.setState({
              depo_list:this.InitDepoArrayLst.DepotArrLst
            });
            console.log("Cont. Already Assigned Depot: ", msg);
          }else{
            depotnamelist.push({name:msg});
            var listToDelete = [""];
            for (var i = 0; i < depotnamelist.length; i++) {
              var obj = depotnamelist[i];
        
              if (listToDelete.indexOf(obj.name) !== -1) {
                depotnamelist.splice(i, 1);
              }
              this.setState({
                depotNameLst: depotnamelist
              });
        
            }
          }
        });
  }

  handleDepotChange(event) {
    this.setState({
      depot_id: event.target.value
    });
    if (this.state.depo_list.length === 0){
      const depotId = event.target.value;
      const dp = this.isDepotAlreadyAssigned(depotId,"init"); 
    }else {
      const depotId = event.target.value;
      const dp = this.isDepotAlreadyAssigned(depotId,"cont"); 
    }
  }

 

  async getDepotname (depotId) {
    const response = await fetch(`${BASE_URL}/get-depotname?id=`+depotId, {
      method:'GET',
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      },
    })
    const json = await response.json();
    const result_names = [];
    for (let i = 0, max = json.length; i < max; i += 1) {
        result_names.push(json[i].name);
    }
    this.setState({
      depotName: result_names.toString()
    });

    depotnamelist.push({name:result_names.toString()});
    // if( depotnamelist.some(code => code.name.val === "")) {
    //   depotnamelist.splice(0,1);
    // } // true
    var listToDelete = [""];
    for (var i = 0; i < depotnamelist.length; i++) {
      var obj = depotnamelist[i];

      if (listToDelete.indexOf(obj.name) !== -1) {
        depotnamelist.splice(i, 1);
      }
      this.setState({
        depotNameLst: depotnamelist
      });

  }
    console.log("The value of depot name: ", this.state.depotName)
    //this.InitDepoArrayLst.depotName.name=this.state.depotName;
  };
//--------------------------------------END---------------------------------------
  handleSubmit = e => {
    e.preventDefault();
    const { assignDepot } = this.props;
    const creds = {
      depo_list: this.InitDepoArrayLst.DepotArrLst, /**Added by Macdonald- mua/202101226 */
      user_id: this.state.staff_id
    };
    if(!this.Arraycontains(UserIdList, creds.user_id)){
        UserIdList.push(creds.user_id);
    }
    //console.log("Submitted Depot List: ", creds);
    assignDepot(creds).then(res => {
      if (res.status === "success") {
        this.setState({
          depot_id: "",
          staff_id: "",
          depo_list: [],
          depotNameLst: [],
          TableDepotList: null
        });
        $(".selected_state").val("");
      }
    });
  };

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleAdd = () => {
    var elements = this.state.depo_list.slice();
    elements.push(this.selectedDepot);
    this.setState({ depo_list: elements });
    console.log("VAalue of Depo_list: ", this.state.depo_list);


    // if(this.state.depo_list.length === 0){
    //   var initArr = [];
    //   initArr.push(depotId);
    //   this.setState({depo_list:initArr});
    // }else {
    //   var depot_id_list =  this.state.depo_list.slice();
    //   depot_id_list.push(depotId);
    //   this.setState(
    //     { depo_list: depot_id_list}
    //   );
    // }

  };

  Arraycontains = (a, obj) => {
    for (var i = 0; i < a.length; i++) {
        if (a[i] === obj) {
            return true;
        }
    }
    return false;
  };

  handleBack = e => {
    // console.log(this.props);
    this.props.history.goBack();
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Assign Depot</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">Assign Depot</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                {/* MODIFICATIONS BY MACDONALD
                  DATE: mua/202101226
                  ISSUE RESOLVED: Enabling multiple depot assignments to same staff
                */}
                <div className='container, column:auto'>
                  {/* <div className="card" style={{width:'50%'}}> */}
                <div className='row'>
                  {/* <div className="form-group col-md-6"> */}
                  <div className="col-sm">
                    <div className="card" style={{height:'100%',padding:'5px'}}>
                      <label>
                        Staffs <span className="text-danger">*</span>
                      </label>
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("staff_id"),
                          "form-control selected_state": true
                        })}
                        placeholder="Select"
                        onChange={this.handleChange}
                        id="staff_id"
                        value={this.state.staff_id}
                      >
                        <option value="">--Select--</option>
                        {this.state.staffs &&
                          this.state.staffs.map((staff, index) => (
                            <option key={index} value={staff.id}>
                              {staff.first_name} {staff.last_name}{" "}
                              {staff.other_name}
                            </option>
                          ))}
                      </select>
                      {this.renderErrorFor("staff_id")}
                    {/* </div> */}
                {/* </div> */}
                {/* <div className="row"> */}
                  {/* <div className="form-group col-md-6"> */}
                  {/* <div className="col-sm"> */}
                    <label>
                      Depots <span className="text-danger">*</span>
                    </label>
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("depot_id"),
                        "form-control selected_state": true
                      })}
                      placeholder="Select"
                      required
                      onChange={this.handleDepotChange}
                      id="depot_id"
                      value={this.state.depot_id}
                    >
                      <option value="">--Select--</option>
                      {this.state.depots &&
                        this.state.depots.map((depot, index) => (
                          <option key={index} value={depot.id}>
                            {depot.name}
                          </option>
                        ))}
                    </select>
                    {this.renderErrorFor("depot_id")}
                  </div>
                  {/* <button onClick={this.handleAdd} >Add</button> */}
                </div>
                {/* </div> */}

                <div className="col-sm">
                    <div className="card"  style={{height:'100%',padding:'5px'}}>

                {/* <div style={{ overflow: 'auto',height:'200px'}}> */}
                <TableDepotList
                      headers={["Depot List To Assign"]}
                      formElements={this.state.depotNameLst}
                />

                {/* {this.state.depotNameLst === "Depot Already Assigned"? 
                  <div style= {{backgroundColor: '#FF0000',fontFamily: 'helvetica', fontSize: 16,textDecoration: 'underline',border:0}}><p>{this.state.depotNameLst}</p></div> :
                  <TableDepotList
                      headers={["Depot List To Assign"]}
                      formElements={this.state.depotNameLst}
                    />
                } */}
                  
                  {/* </div> */}

                </div>
                </div>
                </div>
                </div>
                {''}
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Assign
                </button>{" "}
                <Link
                  to={"/preferences/depots"}
                  className="btn btn-default btn-sm text-uppercase font-weight-bold ml-1"
                >
                  View Depot
                </Link>
                <Link
                  to={"/preferences/assigned-depots"}
                  className="btn btn-default btn-sm text-uppercase font-weight-bold ml-1"
                >
                  View Assigned Depots
                </Link>
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    errors: state.preferences.errors
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getStaffDepot: () => getStaffDepot(dispatch),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    assignDepot: creds => assignDepot(dispatch, creds)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AssignDepot);
