import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
    detailPendingMerchandiseRequestTrans,
    authDetailPendingMerchandiseRequest
    // cancelCampActivity
} from "../../../../store/actions/preferencesActions";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import $ from "jquery";
// import MiniSpinner from "../../../helpers/MiniSpinner";

// import Pagination from "react-js-pagination";

class DetailHqMerchandiseRequestTrans extends Component {
    state = {
        request: null,
        details: null
    };
    componentDidMount() {
        const {
            setPermissionsErrors,
            detailPendingMerchandiseRequestTrans
        } = this.props;
        if (
            !hasPermission({
                mod: "Personal Management",
                action: "PERSONAL_DASHBOARD"
            }) ||
            !hasPermission({
                mod: "Personal Management",
                action: "REQUEST_MERCHANDISE"
            })
        ) {
            setPermissionsErrors();
            this.props.history.push("/");
        } else {
            const id = this.props.match.params.id;
            detailPendingMerchandiseRequestTrans(id).then(res => {
                if (res.status === "success") {
                    //   console.log(res.payload);
                    this.setState({ ...res.payload }, () => {
                        const bibles = [];
                        const details = this.state.details;
                        details.forEach(item => {
                            bibles.push({
                                bible_id: item.bible_id,
                                remark: "",
                                supplied: "0"
                            });
                        });
                        this.setState({
                            bibles
                        });
                    });
                }
            });
        }
    }
    dateConv = date => {
        if (!date) {
            return null;
        }
        return new Date(date).toDateString();
    };

    hasErrorFor(field) {
        const { errors, status } = this.props;
        if (status === "error") {
            return !!errors[field];
        }
        return false;
    }
    renderErrorFor(field) {
        const { errors, status } = this.props;
        if (status === "error") {
            if (errors[field]) {
                return (
                    <span className="invalid-feedback">
                        <strong>{errors[field][0]}</strong>
                    </span>
                );
            }
        }
    }
    handleSubmit = e => {
        e.preventDefault();
        if (
            window.confirm("Are you sure want to authorize this scripture request?")
        ) {
            const creds = {
                id: this.props.match.params.id,
                bibles: this.state.bibles
            };
            this.props.authDetailPendingMerchandiseRequest(creds).then(res => {
                if (res.status === "success") {
                    this.setState({
                        bibles: []
                    });
                    $("#request-form")[0].reset();
                    setTimeout(() => {
                        this.props.history.push(
                            "/preferences/camp-scripture-request/pending"
                        );
                    }, 1500);
                }
            });
        }
    };
    handleChange = e => {
        const bibles = [...this.state.bibles];
        if (bibles.length) {
            // eslint-disable-next-line
            const bible = bibles.find(item => item.bible_id == e.target.id);
            if (bible) {
                bible.supplied = e.target.value ? e.target.value : "0";
            } else {
                bibles.push({
                    bible_id: e.target.id,
                    supplied: e.target.value ? e.target.value : "0",
                    remark: ""
                });
            }
        } else {
            bibles.push({
                bible_id: e.target.id,
                supplied: e.target.value,
                remark: ""
            });
        }
        this.setState({
            bibles
        });
    };
    handleChangeRemark = e => {
        const bibles = [...this.state.bibles];
        if (bibles.length) {
            // eslint-disable-next-line
            const bible = bibles.find(item => item.bible_id == e.target.id);
            if (bible) {
                bible.remark = e.target.value;
            } else {
                bibles.push({
                    bible_id: e.target.id,
                    supplied: "0",
                    remark: e.target.value
                });
            }
        } else {
            bibles.push({
                bible_id: e.target.id,
                supplied: "0",
                remark: e.target.value
            });
        }
        this.setState({
            bibles
        });
    };
    getStatus = status => {
        if (status === "P") {
            return "PENDING";
        } else if (status === "A") {
            return "AUTHORIZED";
        } else if (status === "R") {
            return "RETURNED";
        } else if (status === "T") {
            return "TRANSFERRED";
        } else if (status === "C") {
            return "RECEIVED";
        }
    };
    render() {
        const { spinner, message, status } = this.props;

        return (
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>PERSONAL</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item">
                                        <Link to="/">Dashboard</Link>
                                    </li>
                                    <li className="breadcrumb-item active">
                                        Details Transferred Merchandise Request
                  </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* Main content */}
                <section className="content">
                    {/* Default box */}
                    <div className="card">
                        <div className="card-header">
                            <h3 className="card-title font-weight-bold">
                                {" "}
                                Details Transferred Merchandise Request
              </h3>
                            <div className="card-tools">
                                <button
                                    className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                                    onClick={() => {
                                        this.props.history.goBack();
                                    }}
                                >
                                    <i className="fas fa-arrow-circle-left "></i> Back
                </button>
                            </div>
                        </div>
                        <form id="request-form">
                            <div
                                className="card-body"
                                style={{
                                    // width: "100%"
                                    overflowX: "scroll"
                                }}
                            >
                                {message && status ? (
                                    <Message message={message} status={status} />
                                ) : null}
                                {spinner ? (
                                    <div>
                                        <Spinner />
                                    </div>
                                ) : null}
                                <div className="card">
                                    <div className="card-body ">
                                        <div className="row d-flex justify-content-between">
                                            <div>
                                                <p className="font-weight-bold">
                                                    Camp:{" "}
                                                    <span className="font-italic">
                                                        {this.state.request
                                                            ? this.state.request.camp.name
                                                            : null}
                                                    </span>{" "}
                                                </p>
                                                <p className="font-weight-bold">
                                                    Requested by:{" "}
                                                    <span className="font-italic">
                                                        {this.state.request
                                                            ? `${this.state.request.user.first_name}  ${
                                                            this.state.request.user.last_name
                                                            } ${
                                                            this.state.request.user.other_name
                                                                ? this.state.request.user.other_name
                                                                : ""
                                                            } `
                                                            : null}
                                                    </span>{" "}
                                                </p>
                                                <p className="font-weight-bold">
                                                    Authorized by:{" "}
                                                    <span className="font-italic">
                                                        {this.state.request &&
                                                            this.state.request.authorised_by
                                                            ? `${
                                                            this.state.request.authorised_by.first_name
                                                            }  ${
                                                            this.state.request.authorised_by.last_name
                                                            } ${
                                                            this.state.request.authorised_by.other_name
                                                                ? this.state.request.authorised_by
                                                                    .other_name
                                                                : ""
                                                            } `
                                                            : null}
                                                    </span>{" "}
                                                </p>
                                                <p className="font-weight-bold">
                                                    Driver's Name{" "}
                                                    <span className="font-italic">
                                                        {this.state.request &&
                                                            this.state.request.driver_name
                                                            ? this.state.request.driver_name
                                                            : null}
                                                    </span>{" "}
                                                </p>
                                            </div>
                                            <div>
                                                <p className="font-weight-bold">
                                                    Request Status:{" "}
                                                    <span className="font-italic">
                                                        {this.state.request
                                                            ? this.getStatus(this.state.request.status)
                                                            : null}
                                                    </span>{" "}
                                                </p>

                                                <p className="font-weight-bold">
                                                    Created Date:{" "}
                                                    <span className="font-italic">
                                                        {/* {this.moneyConv(this.state.stock)} */}
                                                        {this.state.request
                                                            ? this.dateConv(this.state.request.created_at)
                                                            : null}
                                                    </span>{" "}
                                                </p>
                                                <p className="font-weight-bold">
                                                    Authorized Date:{" "}
                                                    <span className="font-italic">
                                                        {this.state.request &&
                                                            this.state.request.authorised_date
                                                            ? this.dateConv(
                                                                this.state.request.authorised_date
                                                            )
                                                            : null}
                                                    </span>{" "}
                                                </p>
                                                <p className="font-weight-bold">
                                                    Driver's Phone:{" "}
                                                    <span className="font-italic">
                                                        {this.state.request &&
                                                            this.state.request.driver_phone
                                                            ? this.state.request.driver_phone
                                                            : null}
                                                    </span>{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div key="uniqueKey" className="row col-md-8 offset-md-2 ">
                                    <table
                                        className="table table-hover table-bordered table-striped"
                                        style={{
                                            width: "100%"
                                        }}
                                    >
                                        <thead>
                                            <tr>
                                                <th>Scriptures</th>
                                                <th>Requested Quantity</th>
                                                <th>Supplied Quantity</th>
                                                <th>Returned Quantity</th>
                                                <th>Remark</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.details &&
                                                this.state.details.map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            <td>{item.name}</td>
                                                            <td>{item.requested}</td>
                                                            <td>{item.supplied}</td>
                                                            <td>{item.returned}</td>
                                                            <td>{item.remark}</td>
                                                        </tr>
                                                    );
                                                })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </form>
                        {/* /.card-body */}
                    </div>
                    {/* /.card */}
                </section>
                {/* /.content */}
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        states: state.preferences.states,
        spinner: state.preferences.spinner,
        message: state.preferences.message,
        status: state.preferences.status,
        errors: state.preferences.errors
    };
};
const mapDispatchToProps = dispatch => {
    return {
        detailPendingMerchandiseRequestTrans: id =>
            detailPendingMerchandiseRequestTrans(dispatch, id),
        authDetailPendingMerchandiseRequest: creds =>
            authDetailPendingMerchandiseRequest(dispatch, creds),
        setPermissionsErrors: () => dispatch(setPermissionsErrors())
        // cancelCampActivity: creds => cancelCampActivity(dispatch, creds)
    };
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DetailHqMerchandiseRequestTrans);
