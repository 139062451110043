import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../../helpers/functions/functions";

class PrefrencesAside extends Component {
  render() {
    const { handleActiveLink } = this.props;
    return (
      <React.Fragment>
        {hasPermission({
          mod: "Preferences",
          action: "VIEW_PREFERENCES",
        }) ? (
          <li className="nav-item has-treeview">
            <a href="#!" className="nav-link">
              <i className="nav-icon fas fa-chart-pie" />
              <p>
                PREFERENCES
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
              {hasPermission({
                mod: "Preferences",
                action: "IMPERSONATE",
              }) ? (
                <li className="nav-item">
                  <Link
                    onClick={handleActiveLink}
                    to="/preferences/impersonate"
                    className="nav-link"
                  >
                    <i className="far fa-dot-circle nav-icon" />
                    <p> Impersonate members </p>
                  </Link>
                </li>
              ) : null}
              {hasPermission({
                mod: "Preferences",
                action: "UPDATE_MEMBER",
              }) ? (
                <li className="nav-item">
                  <Link
                    onClick={handleActiveLink}
                    to="/preferences/adjust-member-renewal"
                    className="nav-link"
                  >
                    <i className="far fa-dot-circle nav-icon" />
                    <p> Adjust Renewal Date </p>
                  </Link>
                </li>
              ) : null}

              {hasPermission({
                mod: "Preferences",
                action: "CREATE_ROLE",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_ROLE",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "DELETE_ROLE",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_ROLE",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Roles and Permissions
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_ROLE",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/roles-and-permissions/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create Roles </p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_ROLE",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "DELETE_ROLE",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_ROLE",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/roles-and-permissions"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Roles </p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "ASSIGN_ROLE",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/roles-and-permissions/assign-members-roles"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Assign User(s) to Roles </p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}

              {/*daily bible reading sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "BIBLE_VERSE_UPLOAD",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Daily Bible Reading
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <React.Fragment>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/bible-verse/upload"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Upload Daily Reading </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/bible-verses"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Uploaded Readings </p>
                        </Link>
                      </li>
                    </React.Fragment>
                  </ul>
                </li>
              ) : null}
              {/* end daily bible reading sublink */}

              {/*daily bible reading sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "PRAYER_CALENDAR_UPLOAD",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Prayer Calender
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <React.Fragment>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/prayer-calender/upload"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Upload Prayer Calender </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/prayer-calender"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Uploaded Readings </p>
                        </Link>
                      </li>
                    </React.Fragment>
                  </ul>
                </li>
              ) : null}
              {/* end daily bible reading sublink */}

              {/* start state sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "CREATE_STATE",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_STATE",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "DELETE_STATE",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_STATE",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      State
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_STATE",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/states/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create State </p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_STATE",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "DELETE_STATE",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_STATE",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/states"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View State </p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end state sublink */}

              {/* region  sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "CREATE_REGION",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_REGION",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "DELETE_REGION",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_REGION",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Region
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_REGION",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/regions/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create Region </p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_REGION",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "DELETE_REGION",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_REGION",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/regions"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Region </p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end region sublink */}

              {/* area sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "CREATE_AREA",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_AREA",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "DELETE_AREA",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_AREA",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Area
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_AREA",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/areas/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create Area </p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_AREA",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "DELETE_AREA",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_AREA",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/areas"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Area </p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end area sublink */}

              {/* camp sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "CREATE_CAMP",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_CAMP",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "DELETE_CAMP",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_CAMP",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Camp
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_CAMP",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/camps/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create Camp </p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_CAMP",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "DELETE_CAMP",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_CAMP",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/camps"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Camp </p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Preferences",
                      action: "MOVE_CAMP",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/camps/move"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Move Camp </p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end camp sublink */}

              {/**MODIFICATIONS BY MACDONALD(mua/261220207) */}
              {/**------------------------------------------------ */}
              {/* start country sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "CREATE_COUNTRY",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_COUNTRY",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "DELETE_COUNTRY",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_COUNTRY",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Country
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_COUNTRY",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/countries/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create Country </p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_COUNTRY",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "DELETE_COUNTRY",
                    }) ||
                    hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_COUNTRY",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/countries"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Country </p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end country sublink */}
              {/**----------END MODIFICATIONS-------------------------------- */}
              {/* bible  sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "CREATE_BIBLE",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_BIBLE",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Stock Items
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_BIBLE",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/bibles/create"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Items </p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/bibles"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> View Items </p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end bible sublink */}

              {/* transfer  sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_MEMBER_TRANSFER",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "AUTHORISE_MEMBER_TRANSFER",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Membership Transfer
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_MEMBER_TRANSFER",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/member-transfer/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending Request </p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/member-transfer/authorized"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Authorized Request </p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end transfer sublink */}

              {/* settings  sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "UPDATE_SETTING",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Settings
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/preferences/settings"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> View Settings </p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {/* end settings sublink */}

              {/* convention  sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_CONVENTION_HQ",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "CREATE_CONVENTION",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Convention
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/preferences/conventions/new"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Create Convention </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/preferences/conventions/pending"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Pending Convention </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/preferences/conventions/cancelled"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Cancelled Convention </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/preferences/conventions/completed"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Completed Convention </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/personal/activities/convention/report"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Create Activity Report</p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/preferences/conventions/report"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> View Reports </p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {/* end convention sublink */}

              {/* convention hotels  sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_HOTEL",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "CREATE_HOTEL",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Convention Hotels
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/preferences/convention-hotels/create"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Create Hotel </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/preferences/convention-hotels"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> View Hotels </p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}
              {/* end convention he sublink */}

              {/* HQ Inventory sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_HQ_INVENTORY",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "UPDATE_HQ_INVENTORY",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      HQ Inventory
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_HQ_INVENTORY",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/hq-inventory/add"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Add Inventory </p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/hq-inventory/remove"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Remove Inventory</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/hq-inventory/limit"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Set Reorder Limit</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_HQ_INVENTORY",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/hq-inventory"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> View HQ Inventory </p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end HQ Inventory sublink */}

              {/* HQ Inventory sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_DEPOT",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "CREATE_DEPOT",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "ASSIGN_DEPOT",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Depot Management
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "CREATE_DEPOT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depots/create"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Create Depot </p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_DEPOT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depots"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Depot</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_DEPOT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/assigned-depots"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Assigned Depot</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Preferences",
                      action: "ASSIGN_DEPOT",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/depots/assign"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Assign Depot </p>
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/depots/remove"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Deallocate Depot </p>
                          </Link>
                        </li> */}
                        {/* <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/depots/removeassigned"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Remove Assigned Depot </p>
                          </Link>
                        </li> */}
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/depots/removestaffdepot"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Remove Assigned Depots </p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end HQ Inventory sublink */}

              {/* DEPOT Inventory sublink */}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_DEPOT_INVENTORY",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Depot Inventory
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <React.Fragment>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-inventory/add"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Add Inventory </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-inventory/remove"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Remove Inventory</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-inventory/limit"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Set Reorder Limit</p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-inventory"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> View Depot Inventory </p>
                        </Link>
                      </li>
                    </React.Fragment>
                  </ul>
                </li>
              ) : null}
              {/* end Depot Inventory sublink */}

              {/* camp scripture request*/}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_SCRIPTURE_REQUISITION_FROM_CAMP",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Camp Scripture Request
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <React.Fragment>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/camp-scripture-request/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/camp-scripture-request/authorized"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Authorized Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/camp-scripture-request/received"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Received Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/camp-scripture-request/transfered"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Transferred Request </p>
                        </Link>
                      </li>
                    </React.Fragment>
                  </ul>
                </li>
              ) : null}
              {/* end csmp scripture request sublink */}

              {/* merchandise request*/}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_MERCHANDISE_REQUEST",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Excos Merchandise Request
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <React.Fragment>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/merchandise-request/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/merchandise-request/authorized"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Authorized Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/merchandise-requests/received"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Received Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/personal-merchandise-request/transfered"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Transferred Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/personal-merchandise-request/returned"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Returned Request </p>
                        </Link>
                      </li>
                    </React.Fragment>
                  </ul>
                </li>
              ) : null}
              {/* end merchandise request sublink */}

              {hasPermission({
                mod: "Preferences",
                action: "AUTHORISE_SCRIPTURE_REQUISITION_FROM_CAMP_TO_DEPOT",
              }) ||
              hasPermission({
                mod: "Preferences",
                action: "VIEW_SCRIPTURE_REQUISITION_FROM_CAMP_TO_DEPOT",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Verify Request to Depot
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_SCRIPTURE_REQUISITION_FROM_CAMP_TO_DEPOT",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/request-to-depot/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending Request </p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/preferences/request-to-depot/verified"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Verified Request </p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}
                  </ul>
                </li>
              ) : null}
              {/* end transfer sublink */}

              {/* depot scripture request*/}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_DEPOT_INVENTORY",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Depot Stock Request
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <React.Fragment>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-scripture-request/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-scripture-request/authorized"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Authorized Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-scripture-request/received"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Received Request </p>
                        </Link>
                      </li>
                    </React.Fragment>
                  </ul>
                </li>
              ) : null}
              {/* end depo scripture request sublink */}

              {/* depot merchandise request*/}
              {hasPermission({
                mod: "Preferences",
                action: "VIEW_DEPOT_INVENTORY",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Excos Transfered Request
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <React.Fragment>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-merchandise-request/pending"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p> Pending Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-merchandise-request/authorized"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Authorized Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-merchandise-request/received"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Received Request </p>
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/preferences/depot-merchandise-request/returned"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Returned Request </p>
                        </Link>
                      </li>
                    </React.Fragment>
                  </ul>
                </li>
              ) : null}
              {/* end depo merchandise request sublink */}
            </ul>
          </li>
        ) : null}
      </React.Fragment>
    );
  }
}
export default PrefrencesAside;
