import React, { Component, lazy, Suspense } from "react";
import Header from "./layouts/header/Header";
import Aside from "./layouts/aside/Aside";
// import Footer from "./layouts/footer/Footer";
import Dashboard from "./personal/Dashboard";
import CreateRolesPermissions from "./preferences/roles/CreateRolesPermissions";
import EditRolePermissions from "./preferences/roles/EditRolePermissions";
import DetailsRolePermissions from "./preferences/roles/DetailsRolePermissions";
import AssignMembersRoles from "./preferences/roles/AssignMembersRoles";
import ViewRoles from "./preferences/roles/ViewRoles";
import CreateCamps from "./preferences/camps/CreateCamps";
import ViewCamps from "./preferences/camps/ViewCamps";
import MoveCamps from "./preferences/camps/MoveCamps";
import { Switch, Route } from "react-router-dom";
import Spinner from "./../../components/helpers/Spinner";
import UpdateCamps from "./preferences/camps/UpdateCamps";
import CreateStates from "./preferences/states/CreateStates";
import ViewStates from "./preferences/states/ViewStates";
import UpdateStates from "./preferences/states/UpdateStates";
import CreateRegions from "./preferences/regions/CreateRegions";
import ViewRegions from "./preferences/regions/ViewRegions";
import UpdateRegions from "./preferences/regions/UpdateRegions";
import CreateAreas from "./preferences/areas/CreateAreas";
import ViewAreas from "./preferences/areas/ViewAreas";
import UpdateAreas from "./preferences/areas/UpdateAreas";
import DetailsCamps from "./preferences/camps/DetailsCamps";
import CreateGideons from "./membership/CreateGideons";
import ViewGideons from "./membership/ViewGideons";
import UpdateGideons from "./membership/UpdateGideons";
import CreateAuxilary from "./membership/CreateAuxilary";
import ViewAuxilliaries from "./membership/ViewAuxilliaries";
import UpdateAuxilliary from "./membership/UpdateAuxilliary";
import DetailsGideons from "./membership/DetailsGideons";
import DetailsAuxilliaries from "./membership/DetailsAuxilliaries";
import CreateNewKit from "./membership/member_kit/CreateNewKit";
import ViewPendingKit from "./membership/member_kit/ViewPendingKit";
import ViewReceivedKit from "./membership/member_kit/ViewReceivedKit";
import AcknowledgeKit from "./camp/member_kit/AcknowledgeKit";
import CreateStaff from "./membership/CreateStaff";
import ViewStaffs from "./membership/ViewStaffs";
import UpdateStaff from "./membership/UpdateStaff";
import DetailStaff from "./membership/DetailStaff";
import CreateFriends from "./membership/CreateFriends";
import ViewFriends from "./membership/ViewFriends";
import UpdateFriends from "./membership/UpdateFriends";
import DetailsFriends from "./membership/DetailsFriends";
import CreateRelocationRequest from "./personal/CreateRelocationRequest";
import ViewPendingRequest from "./membership/relocation_request/ViewPendingRequest";
import FinancialContribution from "./personal/payments/FinancialContribution";
import PendingContribution from "./membership/contribution/PendingContribution";
import Renewal from "./personal/payments/Renewal";
import PendingRenewal from "./membership/renewal/PendingRenewal";
import VerifyRenewal from "./membership/renewal/VerifyRenewal";
import ViewAuthorizedRequest from "./membership/relocation_request/ViewAuthorizedRequest";
import VerifiedContribution from "./membership/contribution/VerifiedContribution";
import RejectedRenewal from "./membership/renewal/RejectedRenewal";
import CreateActivity from "./camp/activity/CreateActivity";
import RejectedContribution from "./membership/contribution/RejectedContribution";
import AcknowlegdeKitMember from "./personal/member_kit/AcknowlegdeKitMember";
import ChangePassword from "./personal/ChangePassword";
import ViewSettings from "./preferences/settings/ViewSettings";
import UpdateSettings from "./preferences/settings/UpdateSettings";
import ViewActivity from "./camp/activity/ViewActivity";
import PendingActivity from "./personal/activity/PendingActivity";
import ProductList from "./tstore/ProductList";
import ProductDetails from "./tstore/ProductDetails";
import Cart from "./tstore/cart/Cart";
import CreateBibles from "./preferences/bibles/CreateBibles";
import ViewAllBibles from "./preferences/bibles/ViewAllBibles";
import EditBible from "./preferences/bibles/EditBible";
import CreateHQIventory from "./preferences/hq_inventory/CreateHQIventory";
import RemoveHQInventory from "./preferences/hq_inventory/RemoveHQInventory";
import ViewHQInventory from "./preferences/hq_inventory/ViewHQInventory";
import DetailHQIventory from "./preferences/hq_inventory/DetailHQIventory";
import CreateCampInventory from "./camp/manage_inventory/CreateCampInventory";
import RemoveCampInventory from "./camp/manage_inventory/RemoveCampInventory";
import ViewCampInventory from "./camp/manage_inventory/ViewCampInventory";
import DetailCampInventory from "./camp/manage_inventory/DetailCampInventory";
import CreateDepotIventory from "./preferences/depot_inventory/CreateDepotIventory";
import CreateDepotIventoryAdd from "./preferences/depot_inventory/CreateDepotIventoryAdd";
import CreateDepot from "./preferences/depot_management/CreateDepot";
import ViewDepot from "./preferences/depot_management/ViewDepot";
import ViewAssignedDepot from "./preferences/depot_management/ViewAssignedDepot";
import AssignDepot from "./preferences/depot_management/AssignDepot";
import UpdateDepot from "./preferences/depot_management/UpdateDepot";
import RemoveDepotInvetory from "./preferences/depot_inventory/RemoveDepotInvetory";
import ViewDepotInventory from "./preferences/depot_inventory/ViewDepotInventory";
import DetailDepotInventory from "./preferences/depot_inventory/DetailDepotInventory";
import ViewAllCamp from "./personal/ViewAllCamp";
import RemoveDepot from "./preferences/depot_management/RemoveDepot";
import RemoveAssignedDepot from "./preferences/depot_management/RemoveAssignedDepot";
import RemoveStaffDepot from "./preferences/depot_management/RemoveStaffDepot";
import UpdateActivity from "./camp/activity/UpdateActivity";
import CancelledActivity from "./camp/activity/CancelledActivity";
import CompletedActivity from "./camp/activity/CompletedActivity";
import RequestScriptures from "./personal/activity/RequestScriptures";
import MyScriptureRequest from "./personal/activity/MyScriptureRequest";
import DetailScriptureRequest from "./personal/activity/DetailScriptureRequest";
import PendingScriptureRequest from "./camp/activity/PendingScriptureRequest";
import DetailPendingScriptureRequest from "./camp/activity/DetailPendingScriptureRequest";
import AuthorizedScriptureRequest from "./camp/activity/AuthorizedScriptureRequest";
import DetailAuthorizedScriptureRequest from "./camp/activity/DetailAuthorizedScriptureRequest";
import SetStockLimit from "./camp/manage_inventory/SetStockLimit";
import SetHQInventoryLimit from "./preferences/hq_inventory/SetHQInventoryLimit";
import setDepotInventoryLimit from "./preferences/depot_inventory/setDepotInventoryLimit";
import NewScriptureRequest from "./camp/hq_scripture_request/NewScriptureRequest";
import PendingCampScriptureRequest from "./preferences/camp_scripture_request/PendingCampScriptureRequest";
import DetailCampPendingScriptureRequest from "./preferences/camp_scripture_request/DetailCampPendingScriptureRequest";
import AuthorizedCampScriptureRequest from "./preferences/camp_scripture_request/AuthorizedCampScriptureRequest";
import DetailAuthorizedCampScriptureRequest from "./preferences/camp_scripture_request/DetailAuthorizedCampScriptureRequest";
import PendingHqCampScriptureRequest from "./camp/hq_scripture_request/PendingHqCampScriptureRequest";
import DetailHqCampScriptureRequest from "./camp/hq_scripture_request/DetailHqCampScriptureRequest";
import AuthorizedHqCampScriptureRequest from "./camp/hq_scripture_request/AuthorizedHqCampScriptureRequest";
import AllDepot from "./camp/depot_scripture_request/AllDepot";
import NewDepotScriptureRequest from "./camp/depot_scripture_request/NewDepotScriptureRequest";
import ReceiveHqCampScriptureRequest from "./camp/hq_scripture_request/ReceiveHqCampScriptureRequest";
import ReceivedList from "./camp/hq_scripture_request/ReceivedList";
import ReceivedRequestList from "./camp/depot_scripture_request/ReceivedRequestList";
import ReceivedCampScripture from "./preferences/camp_scripture_request/ReceivedCampScripture";
import PendingDepotCampScriptureRequest from "./camp/depot_scripture_request/PendingDepotCampScriptureRequest";
import DetailDepotCampScriptureRequest from "./camp/depot_scripture_request/DetailDepotCampScriptureRequest";
import PendingDepotScriptureRequestp from "./preferences/depot_scripture_request/PendingDepotScriptureRequestp";
import AuthorizedDepotScriptureRequest from "./preferences/depot_scripture_request/AuthorizedDepotScriptureRequest";
import DetailDepotPendingScriptureRequest from "./preferences/depot_scripture_request/DetailDepotPendingScriptureRequest";
import DetailAuthorizedDepotScriptureRequest from "./preferences/depot_scripture_request/DetailAuthorizedDepotScriptureRequest";
import ReceivedDepotScripture from "./preferences/depot_scripture_request/ReceivedDepotScripture";
import AuthorizedDepotCampScriptureRequest from "./camp/depot_scripture_request/AuthorizedDepotCampScriptureRequest";
import ReceiveDepotCampScriptureRequest from "./camp/depot_scripture_request/ReceiveDepotCampScriptureRequest";
import CreateActivityReport from "./personal/activity/CreateActivityReport";
import GenerateReport from "./personal/activity/GenerateReport";
import CreateTransferRequest from "./camp/member_transfer/CreateTransferRequest";
import PendingTransferRequest from "./preferences/member_tranfer/PendingTransferRequest";
import ShowSingleTransferDetails from "./preferences/member_tranfer/ShowSingleTransferDetails";
import AuthorizedTransferRequest from "./preferences/member_tranfer/AuthorizedTransferRequest";
import DetailAuthorizedTransferRequest from "./preferences/member_tranfer/DetailAuthorizedTransferRequest";
import PendingActivityReport from "./camp/activity/PendingActivityReport";
import DetailActivityReport from "./camp/activity/DetailActivityReport";
import RejectedActivityReport from "./personal/activity/RejectedActivityReport";
import DetailScriptureRequestRejected from "./personal/activity/DetailScriptureRequestRejected";
import RejectedCampActivityReport from "./camp/activity/RejectedCampActivityReport";
import DetailedActivityReportRejectedCamp from "./camp/activity/DetailedActivityReportRejectedCamp";
import AuthorizedCampActivityReport from "./camp/activity/AuthorizedCampActivityReport";
import AuthorizedActivityReport from "./personal/activity/AuthorizedActivityReport";
import DetailAuthorizedActReport from "./personal/activity/DetailAuthorizedActReport";
import ViewMyCampMembers from "./personal/ViewMyCampMembers";
import ViewAllMembers from "./personal/ViewAllMembers";
import ViewAllActiveMembers from "./personal/ViewAllActiveMembers";
import ViewAllUnpaidMembers from "./personal/ViewAllUnpaidMembers";
import ViewAllRipMembers from "./personal/ViewAllRipMembers";
import ViewAllStaffs from "./personal/ViewAllStaffs";
import Profile from "./personal/Profile";
import NoMatch from "./personal/NoMatch";
import TransferedCampScriptureRequest from "./preferences/camp_scripture_request/TransferedCampScriptureRequest";
import PendingCampDepotRequest from "./preferences/verify_depot_request/PendingCampDepotRequest";
import VerifiedCampDepotRequest from "./preferences/verify_depot_request/VerifiedCampDepotRequest";
import RequestMerchandiseHq from "./personal/request_merchandise/RequestMerchandiseHq";
import PendingMerchandise from "./personal/request_merchandise/PendingMerchandise";
import DetailHqMerchandiseRequest from "./personal/request_merchandise/DetailHqMerchandiseRequest";
import PendingMerchandiseRequest from "./preferences/excos_merchandise_request/PendingMerchandiseRequest";
import DetailPersonalPendingScriptureRequest from "./preferences/excos_merchandise_request/DetailPersonalPendingScriptureRequest";
import AuthorizedHqMerchandiseRequest from "./personal/request_merchandise/AuthorizedHqMerchandiseRequest";
import AuthorizedPersonalMerchandiseRequest from "./preferences/excos_merchandise_request/AuthorizedPersonalMerchandiseRequest";
import DetailAuthorizedPersonalMerchandiseRequest from "./preferences/excos_merchandise_request/DetailAuthorizedPersonalMerchandiseRequest";
import ReceivedListMerchandise from "./personal/request_merchandise/ReceivedListMerchandise";
import ReceivedHqPersonalMerchandiseRequest from "./preferences/excos_merchandise_request/ReceivedHqPersonalMerchandiseRequest";
import PendingDepotMerchandiseRequestp from "./preferences/depot_merchandise_request/PendingDepotMerchandiseRequestp";
import DetailDepotPendingMerchandiseRequest from "./preferences/depot_merchandise_request/DetailDepotPendingMerchandiseRequest";
import AuthorizedDepotMerchandiseRequest from "./preferences/depot_merchandise_request/AuthorizedDepotMerchandiseRequest";
import DetailedTransferedRequest from "./preferences/camp_scripture_request/DetailedTransferedRequest";
import TransferedPersonalMerchandiseRequest from "./preferences/excos_merchandise_request/TransferedPersonalMerchandiseRequest";
import DetailTransferedMerchandise from "./preferences/excos_merchandise_request/DetailTransferedMerchandise";
import PendingMerchandiseTrans from "./personal/request_merchandise_transferred/PendingMerchandiseTrans";
import DetailHqMerchandiseRequestTrans from "./personal/request_merchandise_transferred/DetailHqMerchandiseRequestTrans";
import AuthorizedHqMerchandiseRequestTrans from "./personal/request_merchandise_transferred/AuthorizedHqMerchandiseRequestTrans";
import DetailAuthorizedDepotMerchandise from "./preferences/depot_merchandise_request/DetailAuthorizedDepotMerchandise";
import ReceivedListMerchandiseTrans from "./personal/request_merchandise_transferred/ReceivedListMerchandiseTrans";
import ReceivedDepotMerchandise from "./preferences/depot_merchandise_request/ReceivedDepotMerchandise";
import ReturnMerchandiseDepot from "./personal/request_merchandise_transferred/ReturnMerchandiseDepot";
import ReturnedListDepot from "./personal/request_merchandise_transferred/ReturnedListDepot";
import ReturnMerchandiseHq from "./personal/request_merchandise/ReturnMerchandiseHq";
import ReturnedListHq from "./personal/request_merchandise/ReturnedListHq";
import ReturnedDepotList from "./preferences/depot_merchandise_request/ReturnedDepotList";
import ReturnedHqPersonal from "./preferences/excos_merchandise_request/ReturnedHqPersonal";
import AccountReport from "./report/AccountReport";
import PerformanceReport from "./report/PerformanceReport";
import AreaPerformanceReport from "./report/area/AreaPerformanceReport";
import AreaAccountReport from "./report/area/AreaAccountReport";
import RegionAccountReport from "./report/region/RegionAccountReport";
import StateAccountReport from "./report/state/StateAccountReport";
import RegionPeformanceReport from "./report/region/RegionPeformanceReport";
import StatePeformanceReport from "./report/state/StatePeformanceReport";
import VerifyRemita from "./membership/remita/VerifyRemita";
import MembershipReport from "./report/MembershipReport";
import ScriptureReport from "./report/ScriptureReport";
import MyPaymentHistory from "./personal/payments/MyPaymentHistory";
import StateDistribution from "./report/state/StateDistribution";
import Form503 from "./report/Form503";
import MonthlyPerm from "./report/MonthlyPerm";
import AccountReportHQ from "./report/AccountReportHQ";
import AreaAccountReportHQ from "./report/area/AreaAccountReportHQ";
import RegionAccountReportHQ from "./report/region/RegionAccountReportHQ";
import StateAccountReportHQ from "./report/state/StateAccountReportHQ";
import MemberReport from "./report/MemberReport";
import MemberReportHQ from "./report/MemberReportHQ";
import AreaMemberReport from "./report/area/AreaMemberReport";
import AreaMemberReportHQ from "./report/area/AreaMemberReportHQ";
import RegionMemberReport from "./report/region/RegionMemberReport";
import RegionMemberReportHQ from "./report/region/RegionMemberReportHQ";
import StateMemberReport from "./report/state/StateMemberReport";
import StateMemberReportHQ from "./report/state/StateMemberReportHQ";
import CampScriptureReport from "./report/CampScriptureReport";
import CampScriptureReportHQ from "./report/CampScriptureReportHQ";
import AreaScriptureReport from "./report/area/AreaScriptureReport";
// import AreaScriptureRequestHQ from "./report/area/AreaScriptureRequestHQ";
import RegionScriptureReport from "./report/region/RegionScriptureReport";
import RegionScriptureReportHQ from "./report/region/RegionScriptureReportHQ";
import StateScriptureReport from "./report/state/StateScriptureReport";
import StateScriptureReportHQ from "./report/state/StateScriptureReportHQ";
import AllStateReport from "./report/state/AllStateReport";
import MonthlyPermHQ from "./report/MonthlyPermHQ";
import Form503HQ from "./report/Form503HQ";
import ConfirmedRenewal from "./membership/renewal/ConfirmedRenewal";
import DetailsOneDepot from "./preferences/depot_management/DetailsOneDepot";
import AreaScriptureReportHQ from "./report/area/AreaScriptureReportHQ";
import CreateActivityNon from "./camp/non_scripture_activity/CreateActivityNon";
import ViewActivityNon from "./camp/non_scripture_activity/ViewActivityNon";
import UpdateActivityNon from "./camp/non_scripture_activity/UpdateActivityNon";
import CancelledActivityNon from "./camp/non_scripture_activity/CancelledActivityNon";
import CompletedActivityNon from "./camp/non_scripture_activity/CompletedActivityNon";
import PendingActivityNon from "./personal/non_scripture_activity/PendingActivityNon";
import CreateActivityReportNon from "./personal/non_scripture_activity/CreateActivityReportNon";
import SubmitActivityReportNon from "./personal/non_scripture_activity/SubmitActivityReportNon";
import ListCompletedNon from "./camp/non_scripture_activity/ListCompletedNon";
import ViewReportNon from "./camp/non_scripture_activity/ViewReportNon";
import CreateActivityRec from "./camp/recruitment_activity/CreateActivityRec";
import ViewActivityRec from "./camp/recruitment_activity/ViewActivityRec";
import UpdateActivityRec from "./camp/recruitment_activity/UpdateActivityRec";
import CancelledActivityRec from "./camp/recruitment_activity/CancelledActivityRec";
import CompletedActivityRec from "./camp/recruitment_activity/CompletedActivityRec";
import PendingActivityRec from "./personal/recruitment_activity/PendingActivityRec";
import CreateActivityReportRec from "./personal/recruitment_activity/CreateActivityReportRec";
import SubmitActivityReportRec from "./personal/recruitment_activity/SubmitActivityReportRec";
import ListCompletedRec from "./camp/recruitment_activity/ListCompletedRec";
import ViewReportRec from "./camp/recruitment_activity/ViewReportRec";
import CreateActivityMet from "./camp/camp_meetings/CreateActivityMet";
import ViewActivityMet from "./camp/camp_meetings/ViewActivityMet";
import UpdateActivityMet from "./camp/camp_meetings/UpdateActivityMet";
import CancelledActivityMet from "./camp/camp_meetings/CancelledActivityMet";
import CompletedActivityMet from "./camp/camp_meetings/CompletedActivityMet";
import PendingActivityMet from "./personal/camp_meetings/PendingActivityMet";
import CreateActivityReportMet from "./personal/camp_meetings/CreateActivityReportMet";
import SubmitActivityReportMet from "./personal/camp_meetings/SubmitActivityReportMet";
import ListCompletedMet from "./camp/camp_meetings/ListCompletedMet";
import ViewReportMet from "./camp/camp_meetings/ViewReportMet";
import CreateActivityLeg from "./camp/legacy/CreateActivityLeg";
import ViewActivityLeg from "./camp/legacy/ViewActivityLeg";
import PendingActivityLeg from "./personal/legacy/PendingActivityLeg";
import UpdateActivityLeg from "./camp/legacy/UpdateActivityLeg";
import CancelledActivityLeg from "./camp/legacy/CancelledActivityLeg";
import CompletedActivityLeg from "./camp/legacy/CompletedActivityLeg";
import CreateActivityReportLeg from "./personal/legacy/CreateActivityReportLeg";
import SubmitActivityReportLeg from "./personal/legacy/SubmitActivityReportLeg";
import ListCompletedLeg from "./camp/legacy/ListCompletedLeg";
import ViewReportLeg from "./camp/legacy/ViewReportLeg";
import CreateActivityCon from "./preferences/convention/CreateActivityCon";
import RegisterCon from "./personal/convention/RegisterCon";
import ListCompleted from "./personal/convention/ListCompleted";
import ViewActivityCon from "./preferences/convention/ViewActivityCon";
import UpdateActivityCon from "./preferences/convention/UpdateActivityCon";
import DetailsActivityCon from "./preferences/convention/DetailsActivityCon";
import CancelledActivityCon from "./preferences/convention/CancelledActivityCon";
import ViewDetailsCon from "./personal/convention/ViewDetailsCon";
import SubmitRegCon from "./personal/convention/SubmitRegCon";
import CompletedActivityCon from "./preferences/convention/CompletedActivityCon";
import CreateReportCon from "./personal/convention/CreateReportCon";
import CreateConHotel from "./preferences/hotels/CreateConHotel";
import ViewConHotel from "./preferences/hotels/ViewConHotel";
import UpdateConHotel from "./preferences/hotels/UpdateConHotel";
import AddRoomHotel from "./preferences/hotels/AddRoomHotel";
import UpdateRoomHotel from "./preferences/hotels/UpdateRoomHotel";
import MoreDetails from "./preferences/convention/MoreDetails";
import ViewReportCon from "./preferences/convention/ViewReportCon";
import ViewStateReportCon from "./personal/convention/ViewStateReportCon";
import BackCon from "./preferences/convention/BackCon";
import PendingReportNon from "./membership/camp_activity/PendingReportNon";
import VerifiedReportNon from "./membership/camp_activity/VerifiedReportNon";
import RejectedReportNon from "./membership/camp_activity/RejectedReportNon";
import PendingReportRec from "./membership/recruitment/PendingReportRec";
import VerifiedReportRec from "./membership/recruitment/VerifiedReportRec";
import RejectedReportRec from "./membership/recruitment/RejectedReportRec";
import PendingReportMet from "./membership/meetings/PendingReportMet";
import VerifiedReportMet from "./membership/meetings/VerifiedReportMet";
import RejectedReportMet from "./membership/meetings/RejectedReportMet";
import ViewDelisted from "./membership/ViewDelisted";
import ViewDelistedHistory from "./membership/ViewDelistedHistory";
import DelistAuxilliaries from "./membership/DelistAuxilliaries";
import DelistGideons from "./membership/DelistGideons";
import PendingReportLeg from "./membership/bequest/PendingReportLeg";
import VerifiedReportLeg from "./membership/bequest/VerifiedReportLeg";
import RejectedReportLeg from "./membership/bequest/RejectedReportLeg";
import NationalScripture from "./report/state/NationalScripture";
import NationalFund from "./report/state/NationalFund";
import PendingCon from "./membership/convention/PendingCon";
import VerifiedCon from "./membership/convention/VerifiedCon";
import RejectedCon from "./membership/convention/RejectedCon";
import ViewRejectedRequest from "./membership/relocation_request/ViewRejectedRequest";
import MemberReportHQAll from "./report/MemberReportHQAll";
//---------------------MACDONALD MODIFICATIONS------------------------------------------------------------
import CreateCountries from "./preferences/countries/CreateCountries"; //Added by Macdonald(mua/261220207)
import ViewCountries from "./preferences/countries/ViewCountries"; //Added by Macdonald(mua/261220207)
import UpdateCountries from "./preferences/countries/UpdateCountries"; //Added by Macdonald(mua/261220207)
import DetailsCountries from "./preferences/countries/DetailsCountries"; //Added by Macdonald(mua/261220207)
//---------------------END MODIFICATIONS-------------------------------------------------------------------

// phase 2 routes

import SendCampMessages from "./notifications/camp/SendCampMessages";
import SendAreaMessages from "./notifications/area/SendAreaMessages";
import SentCampMessages from "./notifications/camp/SentCampMessages";
import ViewSendCampMessages from "./notifications/camp/ViewSendCampMessages";
import SendRegionMessages from "./notifications/region/SendRegionMessages";
import SendStateMessages from "./notifications/state/SendStateMessages";
import SendNationalMessages from "./notifications/national/SendNationalMessages";
import SetScriptureFund from "./goals/camp/SetScriptureFund";
import SetAdminFund from "./goals/camp/SetAdminFund";
import TrackSetGoals from "./goals/TrackSetGoals";
import SetScriptureDistribution from "./goals/camp/SetScriptureDistribution";
import SetMembership from "./goals/camp/SetMembership";
import PendingCampMessages from "./notifications/camp/PendingCampMessages";
import PendingAreaMessages from "./notifications/area/PendingAreaMessages";
import SentAreaMessages from "./notifications/area/SentAreaMessages";
import PendingRegionMessages from "./notifications/region/PendingRegionMessages";
import SentRegionMessages from "./notifications/region/SentRegionMessages";
import PendingStateMessages from "./notifications/state/PendingStateMessages";
import SentStateMessages from "./notifications/state/SentStateMessages";
import PendingNationalMessages from "./notifications/national/PendingNationalMessages";
import SentNationalMessages from "./notifications/national/SentNationalMessages";
import UploadBibleVerse from "./preferences/bible-verses/UploadBibleVerse";
import UploadPrayerCalendar from "./preferences/prayer_calender/UploadPrayerCalendar";
import ViewUploadedCalendar from "./preferences/prayer_calender/ViewUploadedCalendar";
import ViewUploadedVerses from "./preferences/bible-verses/ViewUploadedVerses";
import UploadGideon from "./preferences/upload-members/UploadGideon";
import UploadAuxiliary from "./preferences/upload-members/UploadAuxiliary";
import UploadAdminFund from "./goals/camp/UploadAdminFund";
import UploadScriptureFund from "./goals/camp/UploadScriptureFund";
import UploadScriptureDistribution from "./goals/camp/UploadScriptureDistribution";
import UploadMemberShip from "./goals/camp/UploadMemberShip";
import ViewSetScriptureGoal from "./goals/camp/ViewSetScriptureGoal";
import ViewSetAdminGoal from "./goals/camp/ViewSetAdminGoal";
import CampGoalStatistics from "./goals/CampGoalStatistics";
import AreaGoalStatistics from "./goals/AreaGoalStatistics";
import RegionGoalStatistics from "./goals/RegionGoalStatistics";
import StateGoalStatistics from "./goals/StateGoalStatistics";
import ViewSetScriptureGoalArea from "./goals/area/ViewSetScriptureGoalArea";
import ViewSetScriptureGoalRegion from "./goals/region/ViewSetScriptureGoalRegion";
import ViewSetScriptureGoalState from "./goals/state/ViewSetScriptureGoalState";
import ViewSetAdminGoalArea from "./goals/area/ViewSetAdminGoalArea";
import ViewSetAdminGoalRegion from "./goals/region/ViewSetAdminGoalRegion";
import ViewSetAdminGoalState from "./goals/state/ViewSetAdminGoalState";
import ViewSetMemberGoal from "./goals/camp/ViewSetMemberGoal";
import ViewSetDistributionGoal from "./goals/camp/ViewSetDistribution";
import ViewSetDistributionArea from "./goals/area/ViewSetDistributionArea";
import ViewSetDistributionRegion from "./goals/region/ViewSetDistributionRegion";
import ViewSetDistributionState from "./goals/state/ViewSetDistributionState";
import ViewSetMemberGoalArea from "./goals/area/ViewSetMemberGoalArea";
import ViewSetMemberGoalRegion from "./goals/region/ViewSetMemberRegion";
import ViewSetMemberGoalState from "./goals/state/ViewSetMemberGoalState";
import ImpersonateMembers from "./preferences/impersonate/ImpersonateMembers";
import ChangeMemberShipRenewal from "./preferences/change_member_renewal/ChangeMemberShipRenewal";
import ViewSetScriptureGoalHQ from "./goals/camp/ViewSetScriptureGoalHQ";
import ViewSetAdminGoalHQ from "./goals/camp/ViewSetAdminGoalHQ";
import ViewSetDistributionGoalHQ from "./goals/camp/ViewSetDistributionHQ";
import ViewSetMemberGoalHQ from "./goals/camp/ViewSetMemberGoalHQ";
import ViewSetAdminGoalAreaHQ from "./goals/area/ViewSetAdminGoalAreaHQ";
import ViewSetScriptureGoalAreaHQ from "./goals/area/ViewSetScriptureGoalAreaHQ";
import ViewSetDistributionAreaHQ from "./goals/area/ViewSetDistributionAreaHQ";
import ViewSetMemberGoalAreaHQ from "./goals/area/ViewSetMemberGoalAreaHQ";
import ViewSetAdminGoalRegionHQ from "./goals/region/ViewSetAdminGoalRegionHQ";
import ViewSetAdminGoalStateHQ from "./goals/state/ViewSetAdminGoalStateHQ";
import ViewSetScriptureGoalRegionHQ from "./goals/region/ViewSetScriptureGoalRegionHQ";
import ViewSetDistributionRegionHQ from "./goals/region/ViewSetDistributionRegionHQ";
import ViewSetMemberGoalRegionHQ from "./goals/region/ViewSetMemberRegionHQ";
import ViewSetScriptureGoalStateHQ from "./goals/state/ViewSetScriptureGoalStateHQ";
import ViewSetDistributionStateHQ from "./goals/state/ViewSetDistributionStateHQ";
import ViewSetMemberGoalStateHQ from "./goals/state/ViewSetMemberGoalStateHQ";
import SendCampMessagesHQ from "./notifications/camp/SendCampMessagesHQ";
import PendingCampMessagesHQ from "./notifications/camp/PendingCampMessagesHQ";
import SendAreaMessagesHQ from "./notifications/area/SendAreaMessagesHQ";
import SendRegionMessagesHQ from "./notifications/region/SendRegionMessagesHQ";
import SendStateMessagesHQ from "./notifications/state/SendStateMessagesHQ";
import PendingStateMessagesHQ from "./notifications/state/PendingStateMessagesHQ";
import SentStateMessagesHQ from "./notifications/state/SentStateMessagesHQ";
import PendingRegionMessagesHQ from "./notifications/region/PendingRegionMessagesHQ";
import PendingAreaMessagesHQ from "./notifications/area/PendingAreaMessagesHQ";
import SentAreaMessagesHQ from "./notifications/area/SentAreaMessagesHQ";
import SentCampMessagesHQ from "./notifications/camp/SentCampMessagesHQ";
import SentRegionMessagesHQ from "./notifications/region/SentRegionMessagesHQ";
import ViewAnnualMembers from "./personal/ViewAnnualMembers";
import ViewLifeMembers from "./personal/ViewLifeMembers";
import ViewVeteranMembers from "./personal/ViewVeteranMembers";

// import DetailsStates from "./preferences/states/DetailsStates";
const Footer = lazy(() => import("./layouts/footer/Footer"));

class Layout extends Component {
  render() {
    return (
      <Suspense
        fallback={
          <div style={{ padding: "200px" }}>
            <Spinner position="center" size="large" />
          </div>
        }
      >
        <React.Fragment>
          <div className="hold-transition sidebar-mini  layout-navbar-fixed layout-fixed ">
            <div className="wrapper">
              <Header />
              <Aside />
              <Switch>
                <Route
                  exact
                  path="/preferences/impersonate"
                  component={ImpersonateMembers}
                />
                <Route
                  exact
                  path="/preferences/adjust-member-renewal"
                  component={ChangeMemberShipRenewal}
                />
                <Route
                  exact
                  path="/goals/camps/set/scripture-distribution"
                  component={SetScriptureDistribution}
                />
                <Route
                  exact
                  path="/preferences/bible-verse/upload"
                  component={UploadBibleVerse}
                />
                <Route
                  exact
                  path="/preferences/prayer-calender/upload"
                  component={UploadPrayerCalendar}
                />
                <Route
                  exact
                  path="/goals/statistics/camp"
                  component={CampGoalStatistics}
                />
                <Route
                  exact
                  path="/goals/statistics/area"
                  component={AreaGoalStatistics}
                />
                <Route
                  exact
                  path="/goals/areas/view/scripture-fund"
                  component={ViewSetScriptureGoalArea}
                />
                <Route
                  exact
                  path="/goals/areas/view/scripture-fund/hq"
                  component={ViewSetScriptureGoalAreaHQ}
                />
                <Route
                  exact
                  path="/goals/areas/view/admin-fund/hq"
                  component={ViewSetAdminGoalAreaHQ}
                />
                <Route
                  exact
                  path="/goals/region/view/scripture-fund"
                  component={ViewSetScriptureGoalRegion}
                />
                <Route
                  exact
                  path="/goals/region/view/scripture-fund/hq"
                  component={ViewSetScriptureGoalRegionHQ}
                />
                <Route
                  exact
                  path="/goals/state/view/admin-fund"
                  component={ViewSetAdminGoalState}
                />
                <Route
                  exact
                  path="/goals/state/view/admin-fund/hq"
                  component={ViewSetAdminGoalStateHQ}
                />
                <Route
                  exact
                  path="/goals/camps/view/membership"
                  component={ViewSetMemberGoal}
                />
                <Route
                  exact
                  path="/goals/camps/view/membership/hq"
                  component={ViewSetMemberGoalHQ}
                />
                <Route
                  exact
                  path="/goals/camps/view/scripture-distribution"
                  component={ViewSetDistributionGoal}
                />
                <Route
                  exact
                  path="/goals/camps/view/scripture-distribution/hq"
                  component={ViewSetDistributionGoalHQ}
                />
                <Route
                  exact
                  path="/goals/areas/view/scripture-distribution"
                  component={ViewSetDistributionArea}
                />
                <Route
                  exact
                  path="/goals/areas/view/scripture-distribution/hq"
                  component={ViewSetDistributionAreaHQ}
                />
                <Route
                  exact
                  path="/goals/region/view/admin-fund"
                  component={ViewSetAdminGoalRegion}
                />
                <Route
                  exact
                  path="/goals/region/view/admin-fund/hq"
                  component={ViewSetAdminGoalRegionHQ}
                />
                <Route
                  exact
                  path="/goals/region/view/scripture-distribution"
                  component={ViewSetDistributionRegion}
                />

                <Route
                  exact
                  path="/goals/region/view/scripture-distribution/hq"
                  component={ViewSetDistributionRegionHQ}
                />
                <Route
                  exact
                  path="/goals/areas/view/admin-fund"
                  component={ViewSetAdminGoalArea}
                />
                <Route
                  exact
                  path="/goals/state/view/scripture-distribution"
                  component={ViewSetDistributionState}
                />
                <Route
                  exact
                  path="/goals/state/view/scripture-distribution/hq"
                  component={ViewSetDistributionStateHQ}
                />
                <Route
                  exact
                  path="/goals/areas/view/membership"
                  component={ViewSetMemberGoalArea}
                />
                <Route
                  exact
                  path="/goals/areas/view/membership/hq"
                  component={ViewSetMemberGoalAreaHQ}
                />
                <Route
                  exact
                  path="/goals/state/view/membership"
                  component={ViewSetMemberGoalState}
                />
                <Route
                  exact
                  path="/goals/state/view/membership/hq"
                  component={ViewSetMemberGoalStateHQ}
                />
                <Route
                  exact
                  path="/goals/region/view/membership"
                  component={ViewSetMemberGoalRegion}
                />
                <Route
                  exact
                  path="/goals/region/view/membership/hq"
                  component={ViewSetMemberGoalRegionHQ}
                />
                <Route
                  exact
                  path="/goals/state/view/scripture-fund"
                  component={ViewSetScriptureGoalState}
                />
                <Route
                  exact
                  path="/goals/state/view/scripture-fund/hq"
                  component={ViewSetScriptureGoalStateHQ}
                />
                <Route
                  exact
                  path="/goals/statistics/region"
                  component={RegionGoalStatistics}
                />
                <Route
                  exact
                  path="/goals/statistics/state"
                  component={StateGoalStatistics}
                />
                <Route
                  exact
                  path="/goals/upload/admin-funds"
                  component={UploadAdminFund}
                />
                <Route
                  exact
                  path="/goals/upload/scripture-funds"
                  component={UploadScriptureFund}
                />
                <Route
                  exact
                  path="/goals/upload/members"
                  component={UploadMemberShip}
                />
                <Route
                  exact
                  path="/goals/upload/scripture-distribution"
                  component={UploadScriptureDistribution}
                />
                <Route
                  exact
                  path="/preferences/prayer-calender"
                  component={ViewUploadedCalendar}
                />

                <Route
                  exact
                  path="/preferences/bible-verses"
                  component={ViewUploadedVerses}
                />
                <Route
                  exact
                  path="/membership/gideons/upload"
                  component={UploadGideon}
                />
                <Route
                  exact
                  path="/membership/auxilliaries/upload"
                  component={UploadAuxiliary}
                />
                <Route
                  exact
                  path="/goals/camps/set/membership"
                  component={SetMembership}
                />
                <Route
                  exact
                  path="/goals/camps/set/scripture-fund"
                  component={SetScriptureFund}
                />
                <Route exact path="/goals/tracks" component={TrackSetGoals} />
                <Route
                  exact
                  path="/goals/camps/set/admin-fund"
                  component={SetAdminFund}
                />
                <Route
                  exact
                  path="/notifications/camps/send-message"
                  component={SendCampMessages}
                />
                <Route
                  exact
                  path="/notifications/camps/send-message/hq"
                  component={SendCampMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/areas/send-message"
                  component={SendAreaMessages}
                />
                <Route
                  exact
                  path="/notifications/areas/send-message/hq"
                  component={SendAreaMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/regions/sent-message"
                  component={SentRegionMessages}
                />
                <Route
                  exact
                  path="/notifications/regions/sent-message/hq"
                  component={SentRegionMessagesHQ}
                />

                <Route
                  exact
                  path="/notifications/states/sent-message"
                  component={SentStateMessages}
                />
                <Route
                  exact
                  path="/notifications/states/sent-message/hq"
                  component={SentStateMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/national/sent-message"
                  component={SentNationalMessages}
                />
                <Route
                  exact
                  path="/notifications/areas/sent-message"
                  component={SentAreaMessages}
                />
                <Route
                  exact
                  path="/notifications/areas/sent-message/hq"
                  component={SentAreaMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/regions/pending-message"
                  component={PendingRegionMessages}
                />
                <Route
                  exact
                  path="/notifications/regions/pending-message/hq"
                  component={PendingRegionMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/states/pending-message"
                  component={PendingStateMessages}
                />
                <Route
                  exact
                  path="/notifications/states/pending-message/hq"
                  component={PendingStateMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/national/pending-message"
                  component={PendingNationalMessages}
                />
                <Route
                  exact
                  path="/notifications/regions/send-message"
                  component={SendRegionMessages}
                />
                <Route
                  exact
                  path="/notifications/regions/send-message/hq"
                  component={SendRegionMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/states/send-message"
                  component={SendStateMessages}
                />
                <Route
                  exact
                  path="/notifications/states/send-message/hq"
                  component={SendStateMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/national/send-message"
                  component={SendNationalMessages}
                />
                <Route
                  exact
                  path="/goals/camps/view/scripture-fund"
                  component={ViewSetScriptureGoal}
                />
                <Route
                  exact
                  path="/goals/camps/view/scripture-fund/hq"
                  component={ViewSetScriptureGoalHQ}
                />
                <Route
                  exact
                  path="/goals/camps/view/admin-fund"
                  component={ViewSetAdminGoal}
                />
                <Route
                  exact
                  path="/goals/camps/view/admin-fund/hq"
                  component={ViewSetAdminGoalHQ}
                />
                <Route
                  exact
                  path="/notifications/camps/sent-message"
                  component={SentCampMessages}
                />
                <Route
                  exact
                  path="/notifications/camps/sent-message/hq"
                  component={SentCampMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/camps/pending-message"
                  component={PendingCampMessages}
                />
                <Route
                  exact
                  path="/notifications/camps/pending-message/hq"
                  component={PendingCampMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/areas/pending-message"
                  component={PendingAreaMessages}
                />
                <Route
                  exact
                  path="/notifications/areas/pending-message/hq"
                  component={PendingAreaMessagesHQ}
                />
                <Route
                  exact
                  path="/notifications/camps/sent-message/view/:id"
                  component={ViewSendCampMessages}
                />
                <Route
                  exact
                  path="/preferences/roles-and-permissions"
                  component={ViewRoles}
                />
                <Route
                  exact
                  path="/preferences/roles-and-permissions/create"
                  component={CreateRolesPermissions}
                />
                <Route
                  exact
                  path="/preferences/roles-and-permissions/assign-members-roles"
                  component={AssignMembersRoles}
                />
                <Route
                  exact
                  path="/preferences/roles-and-permissions/edit/:id"
                  component={EditRolePermissions}
                />

                <Route
                  exact
                  path="/preferences/roles-and-permissions/:id"
                  component={DetailsRolePermissions}
                />
                <Route
                  exact
                  path="/preferences/camps/move"
                  component={MoveCamps}
                />
                <Route
                  exact
                  path="/preferences/camps/create"
                  component={CreateCamps}
                />
                <Route
                  exact
                  path="/preferences/camps/edit/:id"
                  component={UpdateCamps}
                />

                <Route
                  exact
                  path="/preferences/camps/:id"
                  component={DetailsCamps}
                />
                <Route exact path="/preferences/camps" component={ViewCamps} />

                <Route
                  exact
                  path="/preferences/states/create"
                  component={CreateStates}
                />
                <Route
                  exact
                  path="/preferences/convention-hotels/create"
                  component={CreateConHotel}
                />
                <Route
                  exact
                  path="/preferences/hotels/rooms-type/edit"
                  component={UpdateRoomHotel}
                />
                <Route
                  exact
                  path="/preferences/activities/convention/more-details/:id"
                  component={MoreDetails}
                />
                <Route
                  exact
                  path="/preferences/conventions/report"
                  component={ViewReportCon}
                />
                <Route
                  exact
                  path="/personal/activities/convention/view-report"
                  component={ViewStateReportCon}
                />
                <Route
                  exact
                  path="/preferences/convention-hotels"
                  component={ViewConHotel}
                />
                <Route
                  exact
                  path="/preferences/convention-hotels/edit"
                  component={UpdateConHotel}
                />
                <Route
                  exact
                  path="/preferences/hotels/rooms"
                  component={AddRoomHotel}
                />
                {/**Modifications by Macdonald(mua/261220207) */}
                {/**------------------------------------------- */}
                <Route
                  exact
                  path="/preferences/countries/create"
                  component={CreateCountries}
                />
                <Route
                  exact
                  path="/preferences/countries"
                  component={ViewCountries}
                />
                <Route
                  exact
                  path="/preferences/countries/edit/:id"
                  component={UpdateCountries}
                />
                <Route
                  exact
                  path="/preferences/countries/:id"
                  component={DetailsCountries}
                />
                {/**END MODIFICATIONS */}
                {/**-------------------------------------- */}
                <Route
                  exact
                  path="/preferences/states"
                  component={ViewStates}
                />
                <Route
                  exact
                  path="/preferences/states/edit/:id"
                  component={UpdateStates}
                />
                <Route
                  exact
                  path="/preferences/hq-inventory/add"
                  component={CreateHQIventory}
                />
                <Route
                  exact
                  path="/preferences/hq-inventory/remove"
                  component={RemoveHQInventory}
                />
                <Route
                  exact
                  path="/preferences/hq-inventory/limit"
                  component={SetHQInventoryLimit}
                />
                <Route
                  exact
                  path="/preferences/hq-inventory"
                  component={ViewHQInventory}
                />
                <Route
                  exact
                  path="/preferences/hq-inventory/:id"
                  component={DetailHQIventory}
                />
                <Route
                  exact
                  path="/preferences/depot-inventory/add" //Modified by Macdonald
                  component={CreateDepotIventoryAdd}
                />
                <Route
                  exact
                  path="/preferences/depot-inventory/add/:id" //Modified by Macdonald
                  component={CreateDepotIventory}
                />
                <Route
                  exact
                  path="/preferences/depot-inventory/remove"
                  component={RemoveDepotInvetory}
                />
                <Route
                  exact
                  path="/preferences/depot-inventory/limit"
                  component={setDepotInventoryLimit}
                />
                <Route
                  exact
                  path="/preferences/depot-inventory"
                  component={ViewDepotInventory}
                />
                <Route
                  exact
                  path="/preferences/depot-inventory/:id"
                  component={DetailDepotInventory}
                />

                <Route
                  exact
                  path="/preferences/bibles/create"
                  component={CreateBibles}
                />
                <Route
                  exact
                  path="/preferences/bibles"
                  component={ViewAllBibles}
                />
                <Route
                  exact
                  path="/preferences/bibles/edit/:id"
                  component={EditBible}
                />

                <Route
                  exact
                  path="/preferences/regions/create"
                  component={CreateRegions}
                />

                <Route
                  exact
                  path="/preferences/regions"
                  component={ViewRegions}
                />
                <Route
                  exact
                  path="/preferences/regions/edit/:id"
                  component={UpdateRegions}
                />

                <Route
                  exact
                  path="/preferences/areas/create"
                  component={CreateAreas}
                />
                <Route exact path="/preferences/areas" component={ViewAreas} />
                <Route
                  exact
                  path="/preferences/areas/edit/:id"
                  component={UpdateAreas}
                />
                <Route
                  exact
                  path="/membership/remita/verify"
                  component={VerifyRemita}
                />
                <Route
                  exact
                  path="/preferences/settings"
                  component={ViewSettings}
                />
                <Route
                  exact
                  path="/preferences/settings/edit"
                  component={UpdateSettings}
                />
                <Route
                  exact
                  path="/preferences/depots/create"
                  component={CreateDepot}
                />
                <Route exact path="/preferences/depots" component={ViewDepot} />
                <Route
                  exact
                  path="/preferences/assigned-depots"
                  component={ViewAssignedDepot}
                />
                <Route
                  exact
                  path="/preferences/depots/assign"
                  component={AssignDepot}
                />
                <Route
                  exact
                  path="/preferences/depots/remove"
                  component={RemoveDepot}
                />
                <Route
                  exact
                  path="/preferences/depots/removeassigned"
                  component={RemoveAssignedDepot}
                />
                <Route
                  exact
                  path="/preferences/depots/removestaffdepot"
                  component={RemoveStaffDepot}
                />
                <Route
                  exact
                  path="/preferences/depots/edit/:id"
                  component={UpdateDepot}
                />
                <Route
                  exact
                  path="/preferences/camp-scripture-request/pending"
                  component={PendingCampScriptureRequest}
                />
                <Route
                  exact
                  path="/preferences/merchandise-request/pending"
                  component={PendingMerchandiseRequest}
                />
                <Route
                  exact
                  path="/preferences/camp-scripture-request/transfered"
                  component={TransferedCampScriptureRequest}
                />
                <Route
                  exact
                  path="/preferences/camp-scripture-request/transfered/:id"
                  component={DetailedTransferedRequest}
                />

                <Route
                  exact
                  path="/preferences/personal-merchandise-request/transfered"
                  component={TransferedPersonalMerchandiseRequest}
                />
                <Route
                  exact
                  path="/preferences/personal-merchandise-request/transfered/:id"
                  component={DetailTransferedMerchandise}
                />
                <Route
                  exact
                  path="/preferences/depot-scripture-request/pending"
                  component={PendingDepotScriptureRequestp}
                />
                <Route
                  exact
                  path="/preferences/depot-merchandise-request/pending"
                  component={PendingDepotMerchandiseRequestp}
                />
                <Route
                  exact
                  path="/preferences/camp-scripture-request/authorized"
                  component={AuthorizedCampScriptureRequest}
                />
                <Route
                  exact
                  path="/preferences/depot-scripture-request/authorized"
                  component={AuthorizedDepotScriptureRequest}
                />
                <Route
                  exact
                  path="/preferences/depot-merchandise-request/authorized"
                  component={AuthorizedDepotMerchandiseRequest}
                />
                <Route
                  exact
                  path="/preferences/camp-scripture-request/received"
                  component={ReceivedCampScripture}
                />
                <Route
                  exact
                  path="/preferences/depot-scripture-request/received"
                  component={ReceivedDepotScripture}
                />
                <Route
                  exact
                  path="/preferences/camp-scripture-request/pending/:id"
                  component={DetailCampPendingScriptureRequest}
                />
                <Route
                  exact
                  path="/preferences/merchandise-request/pending/:id"
                  component={DetailPersonalPendingScriptureRequest}
                />
                <Route
                  exact
                  path="/preferences/depot-scripture-request/pending/:id"
                  component={DetailDepotPendingScriptureRequest}
                />
                <Route
                  exact
                  path="/preferences/depot-merchandise-request/pending/:id"
                  component={DetailDepotPendingMerchandiseRequest}
                />
                <Route
                  exact
                  path="/preferences/camp-scripture-request/authorized/:id"
                  component={DetailAuthorizedCampScriptureRequest}
                />
                <Route
                  exact
                  path="/preferences/depot-scripture-request/authorized/:id"
                  component={DetailAuthorizedDepotScriptureRequest}
                />

                <Route
                  exact
                  path="/membership/gideons/create"
                  component={CreateGideons}
                />
                <Route
                  exact
                  path="/membership/gideons"
                  component={ViewGideons}
                />
                <Route
                  exact
                  path="/membership/gideons/edit/:id"
                  component={UpdateGideons}
                />

                <Route
                  exact
                  path="/membership/gideons/:id"
                  component={DetailsGideons}
                />
                <Route
                  exact
                  path="/membership/auxilliaries/create"
                  component={CreateAuxilary}
                />
                <Route
                  exact
                  path="/membership/auxilliaries"
                  component={ViewAuxilliaries}
                />
                <Route
                  exact
                  path="/membership/auxilliaries/edit/:id"
                  component={UpdateAuxilliary}
                />
                <Route
                  exact
                  path="/membership/auxilliaries/:id"
                  component={DetailsAuxilliaries}
                />

                <Route
                  exact
                  path="/membership/staff/create"
                  component={CreateStaff}
                />
                <Route exact path="/membership/staffs" component={ViewStaffs} />
                <Route
                  exact
                  path="/membership/staffs/edit/:id"
                  component={UpdateStaff}
                />
                <Route
                  exact
                  path="/membership/staffs/:id"
                  component={DetailStaff}
                />

                <Route
                  exact
                  path="/membership/friends/create"
                  component={CreateFriends}
                />
                <Route
                  exact
                  path="/membership/friends"
                  component={ViewFriends}
                />
                <Route
                  exact
                  path="/membership/friends/edit/:id"
                  component={UpdateFriends}
                />
                <Route
                  exact
                  path="/membership/friends/:id"
                  component={DetailsFriends}
                />

                <Route
                  exact
                  path="/membership/kit/new"
                  component={CreateNewKit}
                />
                <Route
                  exact
                  path="/membership/kit/pending"
                  component={ViewPendingKit}
                />
                <Route
                  exact
                  path="/membership/kit/received"
                  component={ViewReceivedKit}
                />
                <Route
                  exact
                  path="/personal/receive-my-kit"
                  component={AcknowlegdeKitMember}
                />

                <Route
                  exact
                  path="/personal/create-relocation-request"
                  component={CreateRelocationRequest}
                />
                <Route
                  exact
                  path="/personal/merchandise-request/create"
                  component={RequestMerchandiseHq}
                />
                <Route
                  exact
                  path="/personal/merchandise-request/pending"
                  component={PendingMerchandise}
                />
                <Route
                  exact
                  path="/personal/merchandise-request/transferred/pending"
                  component={PendingMerchandiseTrans}
                />
                <Route
                  exact
                  path="/membership/relocation-request/pending"
                  component={ViewPendingRequest}
                />
                <Route
                  exact
                  path="/membership/relocation-request/authorized"
                  component={ViewAuthorizedRequest}
                />

                <Route
                  exact
                  path="/membership/relocation-request/rejected"
                  component={ViewRejectedRequest}
                />
                <Route
                  exact
                  path="/personal/payments/financial-contribution"
                  component={FinancialContribution}
                />
                <Route
                  exact
                  path="/personal/payments/payment-history"
                  component={MyPaymentHistory}
                />
                <Route
                  exact
                  path="/membership/camp-activities/verified"
                  component={VerifiedReportNon}
                />
                <Route
                  exact
                  path="/membership/recruitment-activities/verified"
                  component={VerifiedReportRec}
                />
                <Route
                  exact
                  path="/membership/meetings-activities/verified"
                  component={VerifiedReportMet}
                />
                <Route
                  exact
                  path="/membership/bequest-activities/verified"
                  component={VerifiedReportLeg}
                />
                <Route
                  exact
                  path="/membership/camp-activities/pending"
                  component={PendingReportNon}
                />
                <Route
                  exact
                  path="/membership/convention-activities/pending"
                  component={PendingCon}
                />
                <Route
                  exact
                  path="/membership/recruitment-activities/pending"
                  component={PendingReportRec}
                />
                <Route
                  exact
                  path="/membership/meetings-activities/pending"
                  component={PendingReportMet}
                />
                <Route
                  exact
                  path="/membership/bequest-activities/pending"
                  component={PendingReportLeg}
                />
                <Route
                  exact
                  path="/membership/camp-activities/rejected"
                  component={RejectedReportNon}
                />
                <Route
                  exact
                  path="/membership/bequest-activities/rejected"
                  component={RejectedReportLeg}
                />
                <Route
                  exact
                  path="/membership/meetings-activities/rejected"
                  component={RejectedReportMet}
                />
                <Route
                  exact
                  path="/membership/delist"
                  component={ViewDelisted}
                />
                <Route
                  exact
                  path="/membership/delist/gideons"
                  component={DelistGideons}
                />
                <Route
                  exact
                  path="/membership/delist/auxilliaries"
                  component={DelistAuxilliaries}
                />
                <Route
                  exact
                  path="/membership/delist/history"
                  component={ViewDelistedHistory}
                />
                <Route
                  exact
                  path="/membership/recruitment-activities/rejected"
                  component={RejectedReportRec}
                />

                <Route
                  exact
                  path="/membership/contribution/pending"
                  component={PendingContribution}
                />
                <Route
                  exact
                  path="/membership/contribution/verified"
                  component={VerifiedContribution}
                />
                <Route
                  exact
                  path="/membership/contribution/rejected"
                  component={RejectedContribution}
                />

                <Route
                  exact
                  path="/personal/payments/renewal"
                  component={Renewal}
                />
                <Route
                  exact
                  path="/membership/renewal/pending"
                  component={PendingRenewal}
                />
                <Route
                  exact
                  path="/membership/renewal/verify"
                  component={VerifyRenewal}
                />
                <Route
                  exact
                  path="/membership/renewal/rejected"
                  component={RejectedRenewal}
                />

                <Route
                  exact
                  path="/camp-inventory/create"
                  component={CreateCampInventory}
                />
                <Route
                  exact
                  path="/camp-inventory/remove"
                  component={RemoveCampInventory}
                />
                <Route
                  exact
                  path="/camp-inventory/limit"
                  component={SetStockLimit}
                />
                <Route
                  exact
                  path="/camp-inventory"
                  component={ViewCampInventory}
                />
                <Route
                  exact
                  path="/camp-inventory/:id"
                  component={DetailCampInventory}
                />

                <Route
                  exact
                  path="/camp/kit/acknowlegde"
                  component={AcknowledgeKit}
                />
                <Route
                  exact
                  path="/camp/member-transfer/create"
                  component={CreateTransferRequest}
                />
                <Route
                  exact
                  path="/camp/activity-report/pending"
                  component={PendingActivityReport}
                />
                <Route
                  exact
                  path="/camp/activity-report/rejected"
                  component={RejectedCampActivityReport}
                />
                <Route
                  exact
                  path="/camp/activity-report/authorized"
                  component={AuthorizedCampActivityReport}
                />
                <Route
                  exact
                  path="/camp/scriptures-request/report/:id"
                  component={DetailActivityReport}
                />
                <Route
                  exact
                  path="/camp/scriptures-request/report/rejected/:id"
                  component={DetailedActivityReportRejectedCamp}
                />
                <Route
                  exact
                  path="/personal/activities/report/rejected"
                  component={RejectedActivityReport}
                />
                <Route
                  exact
                  path="/personal/activities/report/authorized"
                  component={AuthorizedActivityReport}
                />
                <Route
                  exact
                  path="/preferences/member-transfer/pending"
                  component={PendingTransferRequest}
                />
                <Route
                  exact
                  path="/preferences/request-to-depot/pending"
                  component={PendingCampDepotRequest}
                />
                <Route
                  exact
                  path="/preferences/request-to-depot/verified"
                  component={VerifiedCampDepotRequest}
                />
                <Route
                  exact
                  path="/preferences/member-transfer/authorized"
                  component={AuthorizedTransferRequest}
                />
                <Route
                  exact
                  path="/preferences/member-transfer/authorized/:id"
                  component={DetailAuthorizedTransferRequest}
                />
                <Route
                  exact
                  path="/preferences/member-transfer/pending/:id"
                  component={ShowSingleTransferDetails}
                />

                <Route
                  exact
                  path="/camp/activities/new"
                  component={CreateActivity}
                />
                <Route
                  exact
                  path="/camp/activities/recruitment/new"
                  component={CreateActivityRec}
                />
                <Route
                  exact
                  path="/camp/activities/meetings/new"
                  component={CreateActivityMet}
                />
                <Route
                  exact
                  path="/camp/activities/legacy/new"
                  component={CreateActivityLeg}
                />
                <Route
                  exact
                  path="/camp/activities/non-scripture/new"
                  component={CreateActivityNon}
                />
                <Route
                  exact
                  path="/camp/activities/edit/:id"
                  component={UpdateActivity}
                />
                <Route
                  exact
                  path="/camp/activities/recruitment/edit/:id"
                  component={UpdateActivityRec}
                />
                <Route
                  exact
                  path="/camp/activities/meetings/edit/:id"
                  component={UpdateActivityMet}
                />
                <Route
                  exact
                  path="/camp/activities/legacy/edit/:id"
                  component={UpdateActivityLeg}
                />
                <Route
                  exact
                  path="/camp/activities/non-scripture/edit/:id"
                  component={UpdateActivityNon}
                />
                <Route
                  exact
                  path="/personal/hq-merchandise-request/return/:id"
                  component={ReturnMerchandiseHq}
                />
                <Route
                  exact
                  path="/personal/merchandise-request/returned"
                  component={ReturnedListHq}
                />
                <Route
                  exact
                  path="/preferences/depot-merchandise-request/returned"
                  component={ReturnedDepotList}
                />
                <Route
                  exact
                  path="/preferences/personal-merchandise-request/returned"
                  component={ReturnedHqPersonal}
                />
                <Route
                  exact
                  path="/camp/activities/pending"
                  component={ViewActivity}
                />
                <Route
                  exact
                  path="/camp/activities/recruitment/pending"
                  component={ViewActivityRec}
                />
                <Route
                  exact
                  path="/camp/activities/meetings/pending"
                  component={ViewActivityMet}
                />
                <Route
                  exact
                  path="/camp/activities/legacy/pending"
                  component={ViewActivityLeg}
                />
                <Route
                  exact
                  path="/camp/activities/non-scripture/pending"
                  component={ViewActivityNon}
                />
                <Route
                  exact
                  path="/camp/scripture-request/pending"
                  component={PendingScriptureRequest}
                />
                <Route
                  exact
                  path="/camp/hq-scripture-request/create"
                  component={NewScriptureRequest}
                />
                <Route
                  exact
                  path="/camp/hq-scripture-request/pending"
                  component={PendingHqCampScriptureRequest}
                />
                <Route
                  exact
                  path="/camp/hq-scripture-request/authorized"
                  component={AuthorizedHqCampScriptureRequest}
                />
                <Route
                  exact
                  path="/personal/merchandise-request/authorized"
                  component={AuthorizedHqMerchandiseRequest}
                />
                <Route
                  exact
                  path="/personal/merchandise-request/transferred/authorized"
                  component={AuthorizedHqMerchandiseRequestTrans}
                />
                <Route
                  exact
                  path="/preferences/merchandise-request/authorized"
                  component={AuthorizedPersonalMerchandiseRequest}
                />
                <Route
                  exact
                  path="/preferences/personal-merchandise-request/authorized/:id"
                  component={DetailAuthorizedPersonalMerchandiseRequest}
                />
                <Route
                  exact
                  path="/preferences/depot-merchandise-request/authorized/:id"
                  component={DetailAuthorizedDepotMerchandise}
                />
                <Route
                  exact
                  path="/camp/depot-scripture-request/authorized"
                  component={AuthorizedDepotCampScriptureRequest}
                />
                <Route
                  exact
                  path="/personal/merchandise-request/received"
                  component={ReceivedListMerchandise}
                />
                <Route
                  exact
                  path="/personal/merchandise-request/transferred/received"
                  component={ReceivedListMerchandiseTrans}
                />
                <Route
                  exact
                  path="/preferences/merchandise-requests/received"
                  component={ReceivedHqPersonalMerchandiseRequest}
                />
                <Route
                  exact
                  path="/preferences/depot-merchandise-request/received"
                  component={ReceivedDepotMerchandise}
                />
                <Route
                  exact
                  path="/camp/hq-scripture-request/received"
                  component={ReceivedList}
                />
                <Route
                  exact
                  path="/membership/renewal/confirmed"
                  component={ConfirmedRenewal}
                />
                <Route
                  exact
                  path="/camp/depot-scripture-request/received"
                  component={ReceivedRequestList}
                />
                <Route
                  exact
                  path="/camp/hq-scripture-request/authorized/:id"
                  component={ReceiveHqCampScriptureRequest}
                />
                <Route
                  exact
                  path="/camp/depot-scripture-request/authorized/:id"
                  component={ReceiveDepotCampScriptureRequest}
                />
                <Route
                  exact
                  path="/personal/depot-merchandise-request/return/:id"
                  component={ReturnMerchandiseDepot}
                />
                <Route
                  exact
                  path="/personal/merchandise-request/transferred/returned"
                  component={ReturnedListDepot}
                />
                <Route
                  exact
                  path="/camp/hq-scripture-request/:id"
                  component={DetailHqCampScriptureRequest}
                />
                <Route
                  exact
                  path="/personal/hq-merchandise-request/:id"
                  component={DetailHqMerchandiseRequest}
                />
                <Route
                  exact
                  path="/personal/depot-merchandise-request/:id"
                  component={DetailHqMerchandiseRequestTrans}
                />
                {/* <Route
                  exact
                  path="/camp/depot-scripture-request/:id"
                  component={DetailDepotCampScriptureRequest}
                /> */}

                <Route
                  exact
                  path="/camp/scripture-request/authorized"
                  component={AuthorizedScriptureRequest}
                />
                <Route
                  exact
                  path="/camp/scripture-request-details/authorized/:id"
                  component={DetailAuthorizedScriptureRequest}
                />
                <Route
                  exact
                  path="/camp/scripture-request/pending/:id"
                  component={DetailPendingScriptureRequest}
                />
                <Route
                  exact
                  path="/camp/depot-scripture-request/create"
                  component={AllDepot}
                />
                <Route
                  exact
                  path="/camp/depot-scripture-request/create/:id"
                  component={NewDepotScriptureRequest}
                />
                <Route
                  exact
                  path="/camp/depot-scripture-request/pending"
                  component={PendingDepotCampScriptureRequest}
                />
                <Route
                  exact
                  path="/camp/depot-scripture-request/:id"
                  component={DetailDepotCampScriptureRequest}
                />

                <Route
                  exact
                  path="/personal/activities/pending"
                  component={PendingActivity}
                />
                <Route
                  exact
                  path="/personal/activities/non-scripture/pending"
                  component={PendingActivityNon}
                />
                <Route
                  exact
                  path="/personal/activities/recruitment/pending"
                  component={PendingActivityRec}
                />
                <Route
                  exact
                  path="/personal/activities/meetings/pending"
                  component={PendingActivityMet}
                />
                <Route
                  exact
                  path="/personal/activities/legacy/pending"
                  component={PendingActivityLeg}
                />
                <Route
                  exact
                  path="/personal/activities/report"
                  component={CreateActivityReport}
                />
                <Route
                  exact
                  path="/personal/activities/non-scripture/report"
                  component={CreateActivityReportNon}
                />
                <Route
                  exact
                  path="/personal/activities/recruitment/report"
                  component={CreateActivityReportRec}
                />
                <Route
                  exact
                  path="/personal/activities/meetings/report"
                  component={CreateActivityReportMet}
                />
                <Route
                  exact
                  path="/personal/activities/legacy/report"
                  component={CreateActivityReportLeg}
                />
                <Route
                  exact
                  path="/personal/activities/recruitment/report/:id"
                  component={SubmitActivityReportRec}
                />
                <Route
                  exact
                  path="/personal/activities/meetings/report/:id"
                  component={SubmitActivityReportMet}
                />
                <Route
                  exact
                  path="/personal/activities/legacy/report/:id"
                  component={SubmitActivityReportLeg}
                />
                <Route
                  exact
                  path="/personal/activities/non-scripture/report/:id"
                  component={SubmitActivityReportNon}
                />
                <Route
                  exact
                  path="/personal/scriptures-request/report/:id"
                  component={GenerateReport}
                />
                <Route
                  exact
                  path="/personal/scriptures-request/report/rejected/:id"
                  component={DetailScriptureRequestRejected}
                />
                <Route
                  exact
                  path="/personal/scriptures-request/report/authorized/:id"
                  component={DetailAuthorizedActReport}
                />
                <Route
                  exact
                  path="/camp/activities/cancelled"
                  component={CancelledActivity}
                />
                <Route
                  exact
                  path="/camp/activities/recruitment/cancelled"
                  component={CancelledActivityRec}
                />
                <Route
                  exact
                  path="/camp/activities/meetings/cancelled"
                  component={CancelledActivityMet}
                />
                <Route
                  exact
                  path="/camp/activities/legacy/cancelled"
                  component={CancelledActivityLeg}
                />
                <Route
                  exact
                  path="/camp/activities/non-scripture/cancelled"
                  component={CancelledActivityNon}
                />
                <Route
                  exact
                  path="/camp/activities/completed"
                  component={CompletedActivity}
                />
                <Route
                  exact
                  path="/camp/activities/recruitment/completed"
                  component={CompletedActivityRec}
                />
                <Route
                  exact
                  path="/camp/activities/meetings/completed"
                  component={CompletedActivityMet}
                />
                <Route
                  exact
                  path="/camp/activities/legacy/completed"
                  component={CompletedActivityLeg}
                />
                <Route
                  exact
                  path="/camp/activities/non-scripture/completed"
                  component={CompletedActivityNon}
                />
                <Route
                  exact
                  path="/camp/activities/non-scripture/completed/report"
                  component={ListCompletedNon}
                />
                <Route
                  exact
                  path="/camp/activities/recruitment/completed/report"
                  component={ListCompletedRec}
                />
                <Route
                  exact
                  path="/camp/activities/meetings/completed/report"
                  component={ListCompletedMet}
                />
                <Route
                  exact
                  path="/camp/activities/legacy/completed/report"
                  component={ListCompletedLeg}
                />
                <Route
                  exact
                  path="/camp/activities/non-scripture/completed/report/:id"
                  component={ViewReportNon}
                />
                <Route
                  exact
                  path="/camp/activities/recuitment/completed/report/:id"
                  component={ViewReportRec}
                />
                <Route
                  exact
                  path="/camp/activities/meetings/completed/report/:id"
                  component={ViewReportMet}
                />
                <Route
                  exact
                  path="/camp/activities/legacy/completed/report/:id"
                  component={ViewReportLeg}
                />

                <Route
                  exact
                  path="/preferences/conventions/new"
                  component={CreateActivityCon}
                />

                <Route
                  exact
                  path="/preferences/conventions/cancelled"
                  component={CancelledActivityCon}
                />
                <Route
                  exact
                  path="/personal/activities/convention/details/:id"
                  component={ViewDetailsCon}
                />
                <Route
                  exact
                  path="/personal/activities/convention/register/:id"
                  component={SubmitRegCon}
                />
                <Route
                  exact
                  path="/preferences/conventions/completed"
                  component={CompletedActivityCon}
                />

                <Route
                  exact
                  path="/personal/activities/convention/pending"
                  component={RegisterCon}
                />
                <Route
                  exact
                  path="/personal/activities/convention/report"
                  component={ListCompleted}
                />

                <Route
                  exact
                  path="/personal/activities/convention/report/:id"
                  component={CreateReportCon}
                />
                <Route
                  exact
                  path="/preferences/activities/convention/edit/:id"
                  component={UpdateActivityCon}
                />
                <Route
                  exact
                  path="/preferences/activities/convention/details/:id"
                  component={DetailsActivityCon}
                />
                <Route
                  exact
                  path="/preferences/activities/convention/details/back/:id"
                  component={BackCon}
                />
                <Route
                  exact
                  path="/preferences/conventions/pending"
                  component={ViewActivityCon}
                />

                <Route
                  exact
                  path="/personal/change-password"
                  component={ChangePassword}
                />

                <Route exact path="/personal/profile" component={Profile} />
                <Route
                  exact
                  path="/personal/camp-members"
                  component={ViewMyCampMembers}
                />
                <Route
                  exact
                  path="/personal/scriptures-request/:id"
                  component={RequestScriptures}
                />
                <Route
                  exact
                  path="/personal/scriptures-request-details/:id"
                  component={DetailScriptureRequest}
                />
                <Route
                  exact
                  path="/preferences/depots/manage/:id"
                  component={DetailsOneDepot}
                />
                <Route
                  exact
                  path="/personal/my-scriptures-request"
                  component={MyScriptureRequest}
                />
                <Route exact path="/personal/camps" component={ViewAllCamp} />
                <Route exact path="/store" component={ProductList} />
                <Route
                  exact
                  path="/store/details/:id"
                  component={ProductDetails}
                />
                <Route
                  exact
                  path="/report/account-report"
                  component={AccountReport}
                />
                <Route
                  exact
                  path="/report/member-report"
                  component={MemberReport}
                />
                <Route
                  exact
                  path="/report/member-report-hq"
                  component={MemberReportHQ}
                />
                <Route
                  exact
                  path="/report/member-report-hq-all"
                  component={MemberReportHQAll}
                />
                <Route
                  exact
                  path="/report/state/scripture-report"
                  component={StateScriptureReport}
                />
                <Route
                  exact
                  path="/report/state/scripture-report-hq"
                  component={StateScriptureReportHQ}
                />
                <Route
                  exact
                  path="/report/camp/scripture-report"
                  component={CampScriptureReport}
                />
                <Route
                  exact
                  path="/report/form-503-report-hq"
                  component={Form503HQ}
                />
                <Route
                  exact
                  path="/report/monthly-performance-report-hq"
                  component={MonthlyPermHQ}
                />
                <Route
                  exact
                  path="/report/area/scripture-report"
                  component={AreaScriptureReport}
                />
                <Route
                  exact
                  path="/report/area/scripture-report-hq"
                  component={AreaScriptureReportHQ}
                />
                <Route
                  exact
                  path="/report/camp/scripture-report-hq"
                  component={CampScriptureReportHQ}
                />
                <Route
                  exact
                  path="/report/account-report-hq"
                  component={AccountReportHQ}
                />
                <Route
                  exact
                  path="/report/membership-report"
                  component={MembershipReport}
                />
                <Route
                  exact
                  path="/report/scripture-report"
                  component={ScriptureReport}
                />
                <Route
                  exact
                  path="/report/area/account-report"
                  component={AreaAccountReport}
                />
                <Route
                  exact
                  path="/report/area/membership-report"
                  component={AreaMemberReport}
                />
                <Route
                  exact
                  path="/report/area/member-report-hq"
                  component={AreaMemberReportHQ}
                />
                <Route
                  exact
                  path="/report/area/account-report-hq"
                  component={AreaAccountReportHQ}
                />
                <Route
                  exact
                  path="/report/region/account-report"
                  component={RegionAccountReport}
                />
                <Route
                  exact
                  path="/report/region/member-report"
                  component={RegionMemberReport}
                />
                <Route
                  exact
                  path="/report/region/member-report-hq"
                  component={RegionMemberReportHQ}
                />
                <Route
                  exact
                  path="/report/region/account-report-hq"
                  component={RegionAccountReportHQ}
                />
                <Route
                  exact
                  path="/report/state/account-report"
                  component={StateAccountReport}
                />
                <Route
                  exact
                  path="/membership/convention-activities/verified"
                  component={VerifiedCon}
                />
                <Route
                  exact
                  path="/membership/convention-activities/rejected"
                  component={RejectedCon}
                />
                <Route
                  exact
                  path="/report/state/all-state-report"
                  component={AllStateReport}
                />
                <Route
                  exact
                  path="/report/state/all-state-report/scripture"
                  component={NationalScripture}
                />
                <Route
                  exact
                  path="/report/state/all-state-report/fund"
                  component={NationalFund}
                />
                <Route
                  exact
                  path="/report/state/account-report-hq"
                  component={StateAccountReportHQ}
                />
                <Route
                  exact
                  path="/report/performance-report"
                  component={PerformanceReport}
                />
                <Route
                  exact
                  path="/report/region/performance-report"
                  component={RegionPeformanceReport}
                />
                <Route
                  exact
                  path="/report/region/scripture-report"
                  component={RegionScriptureReport}
                />
                <Route
                  exact
                  path="/report/region/scripture-report-hq"
                  component={RegionScriptureReportHQ}
                />
                <Route
                  exact
                  path="/report/state/performance-report"
                  component={StatePeformanceReport}
                />
                <Route
                  exact
                  path="/report/state/member-report"
                  component={StateMemberReport}
                />
                <Route
                  exact
                  path="/report/state/member-report-hq"
                  component={StateMemberReportHQ}
                />
                <Route
                  exact
                  path="/report/state/distribution-report"
                  component={StateDistribution}
                />
                <Route
                  exact
                  path="/report/form-503-report"
                  component={Form503}
                />
                <Route
                  exact
                  path="/report/monthly-performance-report"
                  component={MonthlyPerm}
                />
                <Route
                  exact
                  path="/report/area/performance-report"
                  component={AreaPerformanceReport}
                />

                <Route exact path="/cart" component={Cart} />
                <Route exact path="/members" component={ViewAllMembers} />
                <Route
                  exact
                  path="/members/annual"
                  component={ViewAnnualMembers}
                />
                <Route exact path="/members/life" component={ViewLifeMembers} />
                <Route
                  exact
                  path="/members/veteran"
                  component={ViewVeteranMembers}
                />
                <Route
                  exact
                  path="/members/active"
                  component={ViewAllActiveMembers}
                />
                <Route
                  exact
                  path="/members/unpaid"
                  component={ViewAllUnpaidMembers}
                />
                <Route
                  exact
                  path="/members/with-the-lord"
                  component={ViewAllRipMembers}
                />
                <Route exact path="/staffs" component={ViewAllStaffs} />

                <Route exact path="/" component={Dashboard} />
                <Route path="*" component={NoMatch} />
              </Switch>
              <Footer />
            </div>
          </div>
        </React.Fragment>
      </Suspense>
    );
  }
}
export default React.memo(Layout);
