import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../../helpers/functions/functions";

class CampAside extends Component {
  render() {
    const { handleActiveLink } = this.props;
    return (
      <React.Fragment>
        {hasPermission({
          mod: "Camp Administration",
          action: "VIEW_CAMP_ADMINISTRATION",
        }) ? (
          <li className="nav-item has-treeview">
            <a href="#!" className="nav-link">
              <i className="nav-icon fas fa-chart-pie" />
              <p>
                CAMP
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
              {hasPermission({
                mod: "Camp Administration",
                action: "CREATE_CAMP_ACTIVITY",
              }) ||
              hasPermission({
                mod: "Camp Administration",
                action: "VIEW_CAMP_ACTIVITY_REPORT",
              }) ? (
                <React.Fragment>
                  <li className="nav-item has-treeview">
                    <a href="#!" className="nav-link">
                      <i className="fas fa-circle nav-icon" />
                      <p>
                        Scripture Activities
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {hasPermission({
                        mod: "Camp Administration",
                        action: "CREATE_CAMP_ACTIVITY",
                      }) ? (
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/activities/new"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Record</p>
                          </Link>
                        </li>
                      ) : null}

                      {hasPermission({
                        mod: "Camp Administration",
                        action: "VIEW_CAMP_ACTIVITY",
                      }) ? (
                        <React.Fragment>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/pending"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Pending Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/cancelled"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Cancelled Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/completed"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Completed Activity Record</p>
                            </Link>
                          </li>
                        </React.Fragment>
                      ) : null}
                    </ul>
                  </li>

                  {hasPermission({
                    mod: "Camp Administration",
                    action: "VIEW_CAMP_ACTIVITY_REPORT_SUBMISSION",
                  }) ? (
                    <li className="nav-item has-treeview">
                      <a href="#!" className="nav-link">
                        <i className="fas fa-circle nav-icon" />
                        <p>
                          Scripture Activity Report
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/activity-report/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending Report </p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/activity-report/rejected"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Rejected Report </p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/activity-report/authorized"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Authorized Report </p>
                          </Link>
                        </li>
                        {/* <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/hq-scripture-request/pending"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Pending Request </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/hq-scripture-request/authorized"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Authorized Request </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/hq-scripture-request/received"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Received Request </p>
                      </Link>
                    </li> */}
                      </ul>
                    </li>
                  ) : null}

                  {hasPermission({
                    mod: "Camp Administration",
                    action: "VIEW_CAMP_ACTIVITY_SCRIPTURE_REQUISITION",
                  }) ? (
                    <li className="nav-item has-treeview">
                      <a href="#!" className="nav-link">
                        <i className="fas fa-circle nav-icon" />
                        <p>
                          Camp Scripture Request
                          <i className="right fas fa-angle-left" />
                        </p>
                      </a>
                      <ul className="nav nav-treeview">
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/scripture-request/pending"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Pending Request </p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/scripture-request/authorized"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Authorized Request </p>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  ) : null}

                  <li className="nav-item has-treeview">
                    <a href="#!" className="nav-link">
                      <i className="fas fa-circle nav-icon" />
                      <p>
                        Camp Activities
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {hasPermission({
                        mod: "Camp Administration",
                        action: "CREATE_CAMP_ACTIVITY",
                      }) ? (
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/activities/non-scripture/new"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Record</p>
                          </Link>
                        </li>
                      ) : null}

                      {hasPermission({
                        mod: "Camp Administration",
                        action: "VIEW_CAMP_ACTIVITY",
                      }) ? (
                        <React.Fragment>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/non-scripture/pending"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Pending Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/non-scripture/cancelled"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Cancelled Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/non-scripture/completed"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Completed Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/non-scripture/completed/report"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> View Report Record</p>
                            </Link>
                          </li>
                        </React.Fragment>
                      ) : null}
                    </ul>
                  </li>
                  <li className="nav-item has-treeview">
                    <a href="#!" className="nav-link">
                      <i className="fas fa-circle nav-icon" />
                      <p>
                        Recruitment Activities
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {hasPermission({
                        mod: "Camp Administration",
                        action: "CREATE_CAMP_ACTIVITY",
                      }) ? (
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/activities/recruitment/new"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Record</p>
                          </Link>
                        </li>
                      ) : null}

                      {hasPermission({
                        mod: "Camp Administration",
                        action: "VIEW_CAMP_ACTIVITY",
                      }) ? (
                        <React.Fragment>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/recruitment/pending"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Pending Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/recruitment/cancelled"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Cancelled Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/recruitment/completed"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Completed Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/recruitment/completed/report"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> View Report Record</p>
                            </Link>
                          </li>
                        </React.Fragment>
                      ) : null}
                    </ul>
                  </li>
                  <li className="nav-item has-treeview">
                    <a href="#!" className="nav-link">
                      <i className="fas fa-circle nav-icon" />
                      <p>
                        Camp Meetings
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {hasPermission({
                        mod: "Camp Administration",
                        action: "CREATE_CAMP_ACTIVITY",
                      }) ? (
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/activities/meetings/new"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Record</p>
                          </Link>
                        </li>
                      ) : null}

                      {hasPermission({
                        mod: "Camp Administration",
                        action: "VIEW_CAMP_ACTIVITY",
                      }) ? (
                        <React.Fragment>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/meetings/pending"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Pending Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/meetings/cancelled"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Cancelled Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/meetings/completed"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Completed Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/meetings/completed/report"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> View Report Record</p>
                            </Link>
                          </li>
                        </React.Fragment>
                      ) : null}
                    </ul>
                  </li>
                  <li className="nav-item has-treeview">
                    <a href="#!" className="nav-link">
                      <i className="fas fa-circle nav-icon" />
                      <p>
                        Legacy/Bequest
                        <i className="right fas fa-angle-left" />
                      </p>
                    </a>
                    <ul className="nav nav-treeview">
                      {hasPermission({
                        mod: "Camp Administration",
                        action: "CREATE_CAMP_ACTIVITY",
                      }) ? (
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp/activities/legacy/new"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Create Activity Record</p>
                          </Link>
                        </li>
                      ) : null}

                      {hasPermission({
                        mod: "Camp Administration",
                        action: "VIEW_CAMP_ACTIVITY",
                      }) ? (
                        <React.Fragment>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/legacy/pending"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Pending Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/legacy/cancelled"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Cancelled Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/legacy/completed"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> Completed Activity Record</p>
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link
                              onClick={handleActiveLink}
                              to="/camp/activities/legacy/completed/report"
                              className="nav-link"
                            >
                              <i className="far fa-dot-circle nav-icon" />
                              <p> View Report Record</p>
                            </Link>
                          </li>
                        </React.Fragment>
                      ) : null}
                    </ul>
                  </li>
                </React.Fragment>
              ) : null}

              {hasPermission({
                mod: "Camp Administration",
                action: "SCRIPTURE_REQUISITION_HQ",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      HQ Scripture Request
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/hq-scripture-request/create"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> New Scripture Request </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/hq-scripture-request/pending"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Pending Request </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/hq-scripture-request/authorized"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Authorized Request </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/hq-scripture-request/received"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Received Request </p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Camp Administration",
                action: "SCRIPTURE_REQUISITION_TO_DEPOT",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Depot Stock Request
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/depot-scripture-request/create"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> New Request </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/depot-scripture-request/pending"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Pending Request </p>
                      </Link>
                    </li>

                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/depot-scripture-request/authorized"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Authorized Request </p>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/depot-scripture-request/received"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Received Request </p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Camp Administration",
                action: "RECEIVE_KIT",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Member Kit
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/kit/acknowlegde/"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> Acknowledge kit</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Camp Administration",
                action: "MEMBER_TRANSFER",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Membership Transfer
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/camp/member-transfer/create"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p> New Transfer Request</p>
                      </Link>
                    </li>
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Camp Administration",
                action: "UPDATE_CAMP_INVENTORY",
              }) ||
              hasPermission({
                mod: "Camp Administration",
                action: "VIEW_CAMP_INVENTORY",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Manage Inventory
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Camp Administration",
                      action: "UPDATE_CAMP_INVENTORY",
                    }) ? (
                      <React.Fragment>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp-inventory/create"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Add Inventory</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp-inventory/remove"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Remove Inventory</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/camp-inventory/limit"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p> Set Reorder Limit</p>
                          </Link>
                        </li>
                      </React.Fragment>
                    ) : null}

                    {hasPermission({
                      mod: "Camp Administration",
                      action: "UPDATE_CAMP_INVENTORY",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/camp-inventory"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>View Inventory</p>
                        </Link>
                      </li>
                    ) : null}
                  </ul>
                </li>
              ) : null}
            </ul>
          </li>
        ) : null}
      </React.Fragment>
    );
  }
}
export default CampAside;
