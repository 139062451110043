const initState = {
  status: null,
  message: null,
  spinner: false,
  errors: [],
  roles: [],
  pending_camp_notifications: [],
  goals: {},
  camps: [],
  prayer_calender: [],
  bible_verse: [],
  camp_goals_view: [],
  camp_goals_view_state: [],
  camp_goals_view_region: [],
  camp_goals_view_area: [],
  camp_goals_view_admin: [],
  admin_goals_view_area: [],
  admin_goals_view_region: [],
  admin_goals_view_state: [],
  camp_goals_view_member: [],
  area_goals_view_member: [],
  camp_goals_view_dist: [],
  area_goals_view_dist: [],

  region_goals_view_member: [],
  region_goals_view_dist: [],
  state_goals_view_member: [],
  state_goals_view_dist: [],
};

const notificationReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "CLEAR_NOTIFICATIONS_ERRORS":
      return {
        ...state,
        message: null,
        status: null,
        spinner: false,
        errors: [],
      };

    case "START_SPINNER_NOTIFICATION":
      return {
        ...state,
        spinner: true,
      };
    case "STOP_SPINNER_NOTIFICATION":
      return {
        ...state,
        spinner: false,
      };
    case "SYSTEM_ROLES_SUCCESS":
      return {
        ...state,
        spinner: false,
        roles: payload.payload.roles,
      };
    case "CAMP_MESSAGE_SUBMITTED_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "PENDING_CAMP_NOTIFICATION_SUCCESS":
      // console.log(payload);
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        // pending_camp_notifications: payload.payload.pend
      };
    case "CAMP_MESSAGE_SUBMITTED_ERRORS":
      return {
        ...state,
        message: payload.message,
        status: "error",
        errors: payload.errors || [],
      };
    case "SCRIPTURE_GOALS_ERRORS":
      return {
        ...state,
        message: payload.message,
        status: "error",
        errors: payload.errors || [],
      };
    case "SCRIPTURE_GOALS_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
      };
    case "GET_UPLOADED_PRAYER_CALENDER":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        prayer_calender: [...payload.payload],
      };
    case "GET_UPLOADED_PRAYER_CALENDER_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_UPLOADED_BIBLE_VERSE":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        bible_verse: payload.payload,
      };
    case "GET_SET_CAMP_SCRIPTURE_FUND_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        camp_goals_view: [...payload.payload],
      };
    case "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_CAMP_ADMIN_FUND_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        camp_goals_view_admin: payload.payload,
      };
    case "GET_SET_CAMP_ADMIN_FUND_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_ADMIN_FUND_GOAL_AREA":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        admin_goals_view_area: payload.payload,
      };
    case "GET_SET_ADMIN_FUND_GOAL_AREA_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_ADMIN_FUND_GOAL_REGION":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        admin_goals_view_region: payload.payload,
      };
    case "GET_SET_ADMIN_FUND_GOAL_REGION_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_CAMP_DISTRIBUTION_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        camp_goals_view_dist: payload.payload,
      };
    case "GET_SET_CAMP_DISTRIBUTION_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_ADMIN_FUND_GOAL_STATE":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        admin_goals_view_state: payload.payload,
      };
    case "GET_SET_ADMIN_FUND_GOAL_STATE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_REGION_MEMBER_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        region_goals_view_member: payload.payload,
      };
    case "GET_SET_REGION_MEMBER_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_STATE_MEMBER_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        state_goals_view_member: payload.payload,
      };
    case "GET_SET_STATE_MEMBER_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_REGION_DISTRIBUTION_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        region_goals_view_dist: payload.payload,
      };
    case "GET_SET_REGION_DISTRIBUTION_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_STATE_DISTRIBUTION_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        state_goals_view_dist: payload.payload,
      };
    case "GET_SET_STATE_DISTRIBUTION_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_CAMP_MEMBER_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        camp_goals_view_member: payload.payload,
      };
    case "GET_SET_CAMP_MEMBER_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_AREA_MEMBER_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        area_goals_view_member: payload.payload,
      };
    case "GET_SET_AREA_MEMBER_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_STATE":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        camp_goals_view_state: payload.payload,
      };
    case "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_STATE_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_AREA_DISTRIBUTION_GOAL":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        area_goals_view_dist: payload.payload,
      };
    case "GET_SET_AREA_DISTRIBUTION_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_REGION":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        camp_goals_view_region: payload.payload,
      };
    case "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_REGION_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_AREA":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        camp_goals_view_area: payload.payload,
      };
    case "GET_SET_CAMP_SCRIPTURE_FUND_GOAL_AREA_ERROR":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        errors: payload.errors || {},
      };
    case "CAMP_GOAL_SUCCESS":
      return {
        ...state,
        message: payload.message,
        status: payload.status,
        goals: payload.payload,
      };

    case "CAMP_GOAL_ERROR":
      return {
        ...state,
        message: payload.message,
        status: "error",
      };
    case "SYSTEM_ROLES_ERROR":
      return {
        ...state,
        spinner: false,
        status: payload.status,
        message: payload.message,
        errors: payload.payload,
      };

    default:
      return state;
  }
};

export default notificationReducer;
