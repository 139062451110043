import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getRegionAccountReport,
  regionCampDetails,
  regionAreaDetails,
  resetReportState,
} from "./../../../../store/actions/reportActions";
import {
  setAll,
  getAllAreas,
  getAllCamps,
} from "../../../../store/actions/preferencesActions";

import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import classnames from "classnames";
import MiniSpinner from "./../../../helpers/MiniSpinner";
import $ from "jquery";
// import _ from "underscore";
// import { hasPermission } from "./../../helpers/functions/functions";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import { BASE_URL, token, storage_type } from "../../../../config";

class RegionAccountReport extends Component {
  state = {
    user_region: null,
    funds_region: null,
    region_id: "",
    camp_id: "",
    area_id: "",
    funds_region_camp: null,
    funds_region_area: null,
    is_region: false,
    is_area: false,
    is_camp: false,
    // arranged_data: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REPORTS",
      }) ||
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REGIONS_FUND_REPORTS",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    document.title = "The Gideon International In Nigeria (Region Fund Report)";
    this.initDependency();
    // const { getRegionAccountReport } = this.props;
    // getRegionAccountReport(1, "12/12/2020", "15/12/2020");
    // this.props.getAllCamps();
    // this.props.getAllAreas();
    this.props.setAll();
  }
  initDependency = () => {
    // alert("here");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.user_region !== prevState.user_region ||
      nextProps.funds_region_area !== prevState.funds_region_area ||
      nextProps.funds_region_camp !== prevState.funds_region_camp ||
      nextProps.funds_region !== prevState.funds_region
    ) {
      // const arry = [];
      // const temp_funds_region = [...nextProps.funds_region];
      // if (temp_funds_region.length) {
      //   const camp_group = _.groupBy(temp_funds_region, "region");
      //   for (let [key, value] of Object.entries(camp_group)) {
      //     arry.push({
      //       region: key,
      //       fund: _.groupBy(value, "purpose"),
      //     });
      //   }
      //   // console.log(arry);
      //   // this.updateState(arry);
      // }

      return {
        user_region: nextProps.user_region,
        funds_region_camp: nextProps.funds_region_camp,
        funds_region_area: nextProps.funds_region_area,
        funds_region: nextProps.funds_region,
      };
    }
    return null;
  }
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  handleDownload = (e) => {
    e.preventDefault();
    const from = $("#from_date").val();
    const to = $("#to_date").val();
    const region = this.state.region_id;
    const tok = storage_type.getItem(token);
    const download = 1;
    const url = `${BASE_URL}/reports/user_region/funds?from=${from}&to=${to}&region=${region}&download=${download}&token=${tok}`;
    window.open(url, "_blank");
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const from_date = $("#from_date").val();
    const to_date = $("#to_date").val();
    document.title = `The Gideon International In Nigeria (Region Fund Report ${from_date}---${to_date})`;
    this.setState(
      {
        is_region: true,
        is_area: false,
        is_camp: false,
        from: from_date,
        to: to_date,
      },
      () =>
        this.props.getRegionAccountReport(
          this.state.region_id,
          from_date,
          to_date
        )
    );
  };

  regionAreaDetails = (e, region, type) => {
    e.preventDefault();
    this.setState({
      is_region: false,
      is_area: true,
      is_camp: false,
      type: type,
    });
    const { from, to } = this.state;
    this.props.regionAreaDetails(region, type, from, to);
  };
  regionCampDetails = (e, region, type) => {
    e.preventDefault();
    this.setState({
      is_region: false,
      is_area: false,
      is_camp: true,
      type: type,
    });
    const { from, to } = this.state;
    this.props.regionCampDetails(region, type, from, to);
  };

  setDownload = (e) => {
    e.preventDefault();
    $(".fund_table").attr("id", "role_table22");
    this.initDependency();
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  dateConv = (date) => {
    return date ? new Date(date).toDateString() : null;
  };
  componentWillUnmount() {
    this.props.resetReportState();
  }

  render() {
    const { spinner, message, status, preference_spinner } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    // if (this.state.funds_region) {
    //   this.arrangeReport();
    // }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>REPORT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Fund Report </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Fund Report </h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "3000px" }}>
                  <Spinner />
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row  ">
                  <div className="col-12 text-center">
                    <p>
                      Ensure your selected date range falls within one Gideon
                      year.
                    </p>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="from_date">
                        From Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("from_date"),
                          "form-control": true,
                        })}
                        id="from_date"
                        name="from_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("from_date")}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="to_date">
                        To Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("to_date"),
                          "form-control": true,
                        })}
                        id="to_date"
                        name="to_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("to_date")}
                    </div>
                  </div>
                  <div className=" col-sm-4">
                    <label>Region</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("region_id"),
                          "form-control selected_area": true,
                        })}
                        onChange={this.handleChange}
                        id="region_id"
                        value={this.state.region_id}
                        // defaultValue={this.state.region_id}
                      >
                        <option value="">--Select a region--</option>
                        {this.state.user_region &&
                          this.state.user_region.map((region, index) => (
                            <option key={index} value={region.id}>
                              {region.name}--{region.state.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor("region_id")}
                  </div>
                </div>
                <div className="row mb-3">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                    disabled={spinner ? true : false}
                  >
                    Generate
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>

                  {/* <button
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button> */}
                  {/* {this.state.funds_region.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}
                </div>
              </form>

              {this.state.funds_region.length &&
              !spinner &&
              this.state.is_region ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Scripture Fund Total (&#8358;)</th>
                        <th>
                          {this.state.funds_region &&
                            this.moneyConv(
                              this.state.funds_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj.purpose === "Scripture Funds"
                                      ? obj.amount
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </th>
                        <th></th>
                        <th>Admin Fund Total (&#8358;)</th>
                        <th>
                          {this.state.funds_region &&
                            this.moneyConv(
                              this.state.funds_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj.purpose === "Administrative Funds"
                                      ? obj.amount || 0
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Region</th>
                        <th>State</th>
                        <th>Fund Purpose</th>
                        <th>Fund Type</th>

                        <th>Amount(&#8358;)</th>
                      </tr>
                      {this.state.funds_region &&
                        this.state.funds_region.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>{item.purpose}</td>
                                <td>{item.type}</td>
                                <td>
                                  <Link
                                    to="#!"
                                    onClick={(e) =>
                                      this.regionAreaDetails(
                                        e,
                                        this.state.region_id,
                                        item.type
                                      )
                                    }
                                  >
                                    {item.amount}
                                  </Link>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        {" "}
                        <td>
                          {" "}
                          <strong>Total</strong>{" "}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          {this.state.funds_region &&
                            this.moneyConv(
                              this.state.funds_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc + parseFloat(obj.amount ? obj.amount : 0)
                                );
                              },
                              0)
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {this.state.funds_region_area.length &&
              !spinner &&
              this.state.is_area ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Fund Purpose</th>
                        <th>Fund Type</th>

                        <th>Amount(&#8358;)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.funds_region_area &&
                        this.state.funds_region_area.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.area}</td>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>{item.purpose}</td>
                                <td>{item.type}</td>
                                <td>
                                  <Link
                                    to="#!"
                                    onClick={(e) =>
                                      this.regionCampDetails(
                                        e,
                                        this.state.region_id,
                                        item.type
                                      )
                                    }
                                  >
                                    {item.amount}
                                  </Link>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        {" "}
                        <td>
                          {" "}
                          <strong>Total</strong>{" "}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <button
                            type="button"
                            onClick={(e) => this.handleSubmit(e)}
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                            disabled={spinner ? true : false}
                          >
                            Back
                          </button>
                        </td>
                        <td>
                          {" "}
                          {this.state.funds_region_area &&
                            this.moneyConv(
                              this.state.funds_region_area.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc + parseFloat(obj.amount ? obj.amount : 0)
                                );
                              },
                              0)
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {this.state.funds_region_camp.length &&
              !spinner &&
              this.state.is_camp ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Fund Purpose</th>
                        <th>Fund Type</th>
                        <th>Fund Channel</th>
                        <th> Paid Date</th>
                        <th>Amount(&#8358;)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.funds_region_camp &&
                        this.state.funds_region_camp.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.camp}</td>
                                <td>{item.area}</td>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>{item.purpose}</td>
                                <td>{item.type}</td>
                                <td>{item.channel}</td>
                                <td>{this.dateConv(item.paid_date)}</td>
                                <td>{item.amount}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        {" "}
                        <td>
                          {" "}
                          <strong>Total</strong>{" "}
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          <button
                            type="button"
                            onClick={(e) =>
                              this.regionAreaDetails(
                                e,
                                this.state.region_id,
                                this.state.type
                              )
                            }
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                            disabled={spinner ? true : false}
                          >
                            Back
                          </button>
                        </td>
                        <td>
                          {" "}
                          {this.state.funds_region_camp &&
                            this.moneyConv(
                              this.state.funds_region_camp.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc + parseFloat(obj.amount ? obj.amount : 0)
                                );
                              },
                              0)
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.reports.spinner,
    message: state.reports.message,
    status: state.reports.status,
    errors: state.reports.errors,
    funds_region: state.reports.funds_region,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
    user_region: state.preferences.user_region,
    funds_region_camp: state.reports.funds_region_camp,
    funds_region_area: state.reports.funds_region_area,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getRegionAccountReport: (region, from, to) =>
      dispatch(getRegionAccountReport(region, from, to)),
    // getAllCamps: () => dispatch(getAllCamps()),
    setAll: () => dispatch(setAll()),
    getAllAreas: () => dispatch(getAllAreas()),
    getAllCamps: () => dispatch(getAllCamps()),
    regionCampDetails: (region, type, from, to) =>
      dispatch(regionCampDetails(region, type, from, to)),
    regionAreaDetails: (region, type, from, to) =>
      dispatch(regionAreaDetails(region, type, from, to)),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetReportState: () => resetReportState(dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionAccountReport);
