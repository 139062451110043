import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../../hooks";
import { useGetCampMemberGoalState } from "../../../../store/hookactions/notificationsActionhooks";
// import MiniSpinner from "../../../helpers/MiniSpinner";
import Message from "../../../helpers/Message";
import Spinner from "../../../helpers/Spinner";
// import $ from "jquery";
import {
  // submitCampMessage,
  resetNotificationState,
} from "../../../../store/actions/notificationActions";
import { useDispatch } from "react-redux";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import { storage_type, user_auth_details, dehash } from "./../../../../config";

function ViewSetMemberGoalState(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [enabled, setEnabled] = useState(false);
  const [cars_Info, setCarInfo] = useState({});
  const [previous_year, setPrevious] = useState("");
  const [current_year, setCurrent] = useState("");
  //   console.log(cars_Info);

  useGetCampMemberGoalState(
    enabled,
    setEnabled,
    cars_Info?.area?.region?.state?.id,
    "state"
  );

  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const status = useShallowEqualSelector((state) => state.notifications.status);
  // const errors = useShallowEqualSelector((state) => state.notifications.errors);

  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );
  const state_goals_view_member = useShallowEqualSelector(
    (state) => state.notifications.state_goals_view_member
  );
  console.log(state_goals_view_member);

  // function handleSubmit(e) {
  //   e.preventDefault();
  //   const send_date = $("#birth_date").val();
  //   const to_camp = cars_Info.id;
  //   const creds = {
  //     send_date,
  //     to_camp: [to_camp],
  //     type: "camp",
  //   };
  //   submitCampMessage(dispatch, creds).then((res) => {
  //     if (res?.status === "success") {
  //       $("#birth_date").val("");
  //       // $("#compose_message").s("reset");

  //       initDependency();
  //     }
  //   });
  // }
  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  // function hasErrorFor(field) {
  //   if (status === "error") {
  //     return !!errors[field];
  //   }
  //   return false;
  // }
  // function renderErrorFor(field) {
  //   if (status === "error") {
  //     if (errors[field]) {
  //       return (
  //         <span className="invalid-feedback">
  //           <strong>{errors[field][0]}</strong>
  //         </span>
  //       );
  //     }
  //   }
  // }

  useLayoutEffect(() => {
    initDependency();
  }, []);

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Goals",
        action: "VIEW_REGION_GOAL_REPORT",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      setCarInfo(user?.camp);
    }

    return () => {
      resetNotificationState(dispatch);
      dispatch({ type: "CLEAR_USERS_ERRORS" });
    };
  }, [dispatch, history]);

  useEffect(() => {
    if (Object.keys(cars_Info).length) {
      setEnabled(true);
    }
  }, [cars_Info]);

  useEffect(() => {
    if (state_goals_view_member?.length) {
      const year_previous = state_goals_view_member[0].total?.previous.year;
      const year_current = state_goals_view_member[0].total?.current.year;

      setPrevious(year_previous);
      setCurrent(year_current);

      setEnabled(true);
    }
  }, [state_goals_view_member]);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Goals</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">View Membership Goal</li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              View Membership Goal{" "}
              <span className="lead">
                State: <i>{cars_Info?.area?.region?.state?.name} </i>
              </span>
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}
            {/* <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="form-group col-md-6">
                  <label htmlFor="send_date">
                    Send Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className={classnames({
                      "is-invalid": hasErrorFor("send_date"),
                      "form-control": true,
                    })}
                    id="birth_date"
                    name="name"
                    readOnly
                    placeholder="Select date"
                  />
                  {renderErrorFor("send_date")}
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                disabled={spinner ? true : false}
              >
                Send
                {spinner && <MiniSpinner />}
              </button>
            </form>
            <br /> */}

            {state_goals_view_member.length ? (
              <div key="uniqueKey">
                <table
                  id="fund_table1"
                  className="table table-bordered"
                  style={{
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>STATE</th>
                      <th>REGION</th>
                      <th>AREA</th>
                      <th>CAMP</th>
                      <th>CAMP NO.</th>
                      <th>GIDEON</th>
                      <th>GIDEON</th>
                      <th>AUXILIARY</th>
                      <th>AUXILIARY </th>
                      <th>TOTAL</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>({previous_year})₦</th>
                        <th>({current_year})₦</th>
                        <th>({previous_year})₦</th>

                        <th>({current_year})₦</th>
                        <th>({previous_year})₦</th>
                        <th>({current_year})₦</th>
                      </tr>
                    }

                    {state_goals_view_member.map((item, index) => {
                      return (
                        <>
                          <tr key={item.camp_name}>
                            <th>{item.name}</th>
                            <th>{item.region}</th>
                            <th>{item.area}</th>
                            <th>{item.camp_name}</th>
                            <th>{item.camp_no}</th>
                            <td>{item.gideon.previous.total || "--"}</td>
                            <td>{item.gideon.current.total || "--"}</td>
                            <td>{item.aux.previous.total || "--"}</td>
                            <td>{item.aux.current.total || "--"}</td>

                            <td>{item.total.previous.total || "--"}</td>
                            <td>{item.total.current.total || "--"}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {initDependency()}
              </div>
            ) : null}
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default ViewSetMemberGoalState;
