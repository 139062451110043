import React, { Component } from "react";
import { Link } from "react-router-dom";
// import classnames from "classnames";
import { connect } from "react-redux";
import {
  addToCart,
  setDetailProduct
} from "./../../../store/actions/tstoreActions";
import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
// import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../store/actions/authActions";
// import { ButtonContainer } from "./Button";
import { detailProduct } from "./../../../data";
// import { image_path } from "../../../config";
import {
  storage_type,
  user_auth_details,
  dehash,
  image_path
} from "./../../../config";

class ProductDetails extends Component {
  state = {
    detailProduct: {}
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.detailProduct !== prevState.detailProduct) {
      return {
        detailProduct: nextProps.detailProduct
      };
    }
    return null;
  }
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    const user = JSON.parse(
      dehash(`${storage_type.getItem(user_auth_details)}`)
    );

    if (user.type === "S") {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const id = this.props.match.params.id;
      // alert(id);
      this.props.setDetailProduct(id);
    }
  }
  setDetailProduct = () => {
    this.setState({ detailProduct: { ...detailProduct } });
  };
  addToCart = id => {
    this.props.addToCart(id);
    this.setState({
      detailProduct: {
        ...this.state.detailProduct,
        inCart: true,
        count: 1,
        total: this.state.detailProduct.price
      }
    });
  };

  //   componentDidMount() {
  //     const { setPermissionsErrors } = this.props;
  //     if (
  //       !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
  //       !hasPermission({ mod: "Preferences", action: "UPDATE_STATE" })
  //     ) {
  //       setPermissionsErrors();
  //       this.props.history.push("/");
  //     } else {
  //       //   const id = this.props.match.params.id;
  //       //   this.props.getOneState(id).then(res => {
  //       //     if (res.status === "success") {
  //       //     //   console.log(res);
  //       //       this.setState({
  //       //         name: res.payload.state.name
  //       //       });
  //       //     }
  //       //   });
  //     }
  //   }

  moneyConv = price => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  render() {
    const { spinner, status, message } = this.props;
    // const {
    //   id,
    //   title,
    //   img,
    //   price,
    //   inCart,
    //   company,
    //   info
    // } = this.state.detailproduct;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>STORE</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">My Store</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">My Store</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              {spinner ? (
                <Spinner position="right" />
              ) : (
                <React.Fragment>
                  <div className="row">
                    <div className="col-10 mx-auto text-center">
                      <h2>{this.state.detailProduct.title}</h2>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10 mx-auto col-md-6 my-3 text-capitalize">
                      <img
                        src={`${image_path}${this.state.detailProduct.img}`}
                        className="img-fluid"
                        alt="product"
                      />
                    </div>
                    <div className="col-10 mx-auto col-md-6 my-3 text-capitalize">
                      {/* <h2>model: {this.state.detailProduct.title}</h2> */}
                      {/* <h4 className="text-title text-uppercase text-muted mt-3 mb-2">
                      made by :{" "}
                      <span className="text-uppercase">
                        {this.state.detailProduct.company}
                      </span>
                    </h4> */}
                      <h4 className="text-blue">
                        <strong>
                          price: <span>&#8358;</span>{" "}
                          {this.moneyConv(this.state.detailProduct.price)}
                        </strong>
                      </h4>
                      <p className="text-capitalize font-weight-bold mt-3 mb-0">
                        Description:
                      </p>
                      <p className="text-muted lead">
                        {this.state.detailProduct.info}
                      </p>
                      <div>
                        <Link to="/store">
                          <button className="btn btn-default btn-sm text-uppercase font-weight-bold mr-1">
                            Back to Store
                          </button>
                        </Link>
                        <button
                          disabled={
                            this.state.detailProduct.inCart ? true : false
                          }
                          onClick={() => {
                            this.addToCart(this.state.detailProduct.id);
                            //   value.openModal(this.state.detailProduct.id);
                          }}
                          className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                        >
                          {this.state.detailProduct.inCart
                            ? "in Cart"
                            : "add to cart"}
                        </button>
                        <Link to="/cart">
                          <button className="btn btn-default btn-sm text-uppercase font-weight-bold ml-1">
                            Go to Cart
                          </button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.tstore.spinner,
    message: state.tstore.message,
    status: state.tstore.status,
    errors: state.tstore.errors,
    detailProduct: state.tstore.detailProduct
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    addToCart: id => dispatch(addToCart(id)),
    setDetailProduct: id => dispatch(setDetailProduct(id))
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
