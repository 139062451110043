import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  createAuxillary,
  resetUsersState,
  getAllGideons,
} from "../../../store/actions/usersActions";
import { getAllCamps } from "../../../store/actions/preferencesActions";
// import Spinner from "../../helpers/Spinner";
import Message from "../../helpers/Message";
import { hasPermission } from "../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../store/actions/authActions";
import MiniSpinner from "../../helpers/MiniSpinner";
import $ from "jquery";
// 10.10.1.227

class CreateAuxilary extends Component {
  state = {
    title: "",
    first_name: "",
    last_name: "",
    other_name: "",
    mailing_address: "",
    city: "",
    state: "",
    camps: "",
    camp_id: "",
    phone: "",
    phone_2: "",
    email: "",
    birth_date: "",
    passed_on: "",
    married: "",
    years: "",
    spirit_info1: "yes",
    spirit_info2: "yes",
    spirit_info3: "yes",
    spirit_info4: "yes",
    spirit_info5: "yes",
    spirit_info6: "yes",
    spirit_info7: "yes",
    spirit_info8: "yes",
    church_denomination: "",
    church_address: "",
    church_phone_number: "",
    name_of_pastor: "",
    pastors_phone_number: "",
    pastors_phone_email: "",
    previous_member: "yes",
    previous_where: "",
    previous_date: "",
    previous_number: "",
    accepted_date: "",
    membership_type: "",
    pay_type: "",
    application_source: "individual enlistment",
    sponsor_spiritual_reasons: "",
    sponsor_occupation: "",
    sponsor_card: "",
    endorsement_name: "",
    endorsement_position: "pastor",
    pastors_comments: "",
    endorse_date: "",
    endorse_by: "",
    alive: "1",
    // membership_id: "",
    login_id: "",
    new_member: "",
    last_payment_date: "",
    image: "",
    gideons: [],
    spouse_id: "",
    recruitment: "",
  };

  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_MEMBER" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    this.initDependency();
    // if (this.props.camps) {
    //   this.setState({ camps: this.props.camps });
    // } else {
    this.props.getAllCamps();
    // }
    // if (this.props.gideons.length) {
    //   this.setState({ gideons: this.props.gideons });
    // } else {
    this.props.getAllGideons();
    // }

    // this.props.getAllGideons();
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }

  handleChange = (e) => {
    var camp_id = $("#camp_id").val();
    var spouse_id = $("#spouse_id").val();

    this.setState({
      [e.target.name]: e.target.value,

      //Repeatedly set state for inputs using library
      //If you do console.log, the current updated state here will not be available
      //So do not bother debugging with instant console
      //The current value is re-fetched upon form submit
      camp_id: parseInt(camp_id),
      spouse_id: parseInt(spouse_id),
    });
  };

  handleFileChange = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };

  handleClick = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  handleSubmit = (e) => {
    e.preventDefault();
    let passed_on = $("#passed_on").val();
    if (this.state.alive == "1") {
      passed_on = "";
    }
    const previous_date = $("#previous_date").val();
    const birth_date = $("#birth_date").val();
    const accepted_date = $("#accepted_date").val();
    const endorse_date = $("#endorse_date").val();
    const last_payment_date = $("#last_payment_date").val();
    const camp_id = $("#camp_id").val();
    const spouse_id = $("#spouse_id").val();
    this.setState(
      {
        birth_date,
        previous_date,
        accepted_date,
        endorse_date,
        last_payment_date,
        passed_on,
        camp_id,
        spouse_id,
      },
      () => {
        if (window.confirm("Are you sure want to create this member record?")) {
          const { createAuxillary } = this.props;
          createAuxillary(this.state).then((res) => {
            // console.log(res);
            if (res.status === "error") {
              // window.alert(`${res.status}`);
              $(".opp").each(function () {
                $(this).removeClass("collapsed-card");
                const item = $(this).find(".fa-plus");
                if (item) {
                  item.removeClass("fa-plus").addClass("fa-minus");
                }
              });
            } else {
              // const all= this.state;
              // Object.entries(all).forEach(([key, value]) => {
              //   this.setState({[key]:""});
              // });
              // window.alert(`${res.message}`);
              $("#previous_date").val("");
              $("#birth_date").val("");
              $("#accepted_date").val("");
              $("#endorse_date").val("");
              $("#passed_on").val("");
              $("#last_payment_date").val("");
              $("#image").val("");
              $(".memberselect22").val("");
              this.setState({
                title: "",
                first_name: "",
                last_name: "",
                other_name: "",
                mailing_address: "",
                city: "",
                state: "",
                camps: "",
                camp_id: "",
                phone: "",
                phone_2: "",
                email: "",
                birth_date: "",
                married: "",
                years: "",
                church_denomination: "",
                church_address: "",
                church_phone_number: "",
                name_of_pastor: "",
                pastors_phone_number: "",
                pastors_phone_email: "",
                previous_where: "",
                previous_date: "",
                previous_number: "",
                accepted_date: "",
                pay_type: "",
                sponsor_spiritual_reasons: "",
                sponsor_occupation: "",
                sponsor_card: "",
                endorsement_name: "",
                pastors_comments: "",
                endorse_date: "",
                endorse_by: "",
                login_id: "",
                new_member: "",
                last_payment_date: "",
                image: "",
                spouse_id: "",
              });
            }
          });
        }
      }
    );
  };
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (
  //     nextProps.camps !== prevState.camps ||
  //     nextProps.gideons !== prevState.gideons
  //   ) {
  //     return {
  //       camps: nextProps.camps,
  //       gideons: nextProps.gideons,
  //     };
  //   }
  //   return null;
  // }
  render() {
    const { spinner, status, message, preference_spinner, camps, gideons } =
      this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Membership</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create an Auxilliary Record
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Create an Auxilliary Record
              </h3>
              <div className="card-tools"></div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="card-body">
                {/* {spinner ? <Spinner position="right" /> : null} */}
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}
                {/* start SECTION I: PERSONAL INFORMATION */}

                <div className="card opp">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">
                      SECTION I: PERSONAL INFORMATION
                    </h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="form-group col-md-4">
                        <label htmlFor="last_name">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("last_name"),
                            "form-control": true,
                          })}
                          name="last_name"
                          id="last_name"
                          onChange={this.handleChange}
                          value={this.state.last_name}
                        />
                        {this.renderErrorFor("last_name")}
                      </div>
                      <div className="form-group col-md-4">
                        <label htmlFor="first_name">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("first_name"),
                            "form-control": true,
                          })}
                          name="first_name"
                          id="first_name"
                          onChange={this.handleChange}
                          value={this.state.first_name}
                        />
                        {this.renderErrorFor("first_name")}
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="other_name">Other Name</label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("other_name"),
                            "form-control": true,
                          })}
                          name="other_name"
                          id="other_name"
                          value={this.state.other_name}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("other_name")}
                      </div>
                    </div>

                    <div className="form-group">
                      <label htmlFor="mailing_address">
                        Mailing Address <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("mailing_address"),
                          "form-control": true,
                        })}
                        id="mailing_address"
                        name="mailing_address"
                        value={this.state.mailing_address}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("mailing_address")}
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="city">
                            City <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("city"),
                              "form-control": true,
                            })}
                            name="city"
                            id="city"
                            value={this.state.city}
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("city")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="state">
                            State <span className="text-danger">*</span>
                          </label>
                          <select
                            className={classnames({
                              "is-invalid": this.hasErrorFor("state"),
                              "form-control": true,
                            })}
                            id="state"
                            name="state"
                            value={this.state.state}
                            onChange={this.handleChange}
                          >
                            <option value="">- Select -</option>
                            <option value="Abuja FCT">Abuja FCT</option>
                            <option value="Abia">Abia</option>
                            <option value="Adamawa">Adamawa</option>
                            <option value="Akwa Ibom">Akwa Ibom</option>
                            <option value="Anambra">Anambra</option>
                            <option value="Bauchi">Bauchi</option>
                            <option value="Bayelsa">Bayelsa</option>
                            <option value="Benue">Benue</option>
                            <option value="Borno">Borno</option>
                            <option value="Cross River">Cross River</option>
                            <option value="Delta">Delta</option>
                            <option value="Ebonyi">Ebonyi</option>
                            <option value="Edo">Edo</option>
                            <option value="Ekiti">Ekiti</option>
                            <option value="Enugu">Enugu</option>
                            <option value="Gombe">Gombe</option>
                            <option value="Imo">Imo</option>
                            <option value="Jigawa">Jigawa</option>
                            <option value="Kaduna">Kaduna</option>
                            <option value="Kano">Kano</option>
                            <option value="Katsina">Katsina</option>
                            <option value="Kebbi">Kebbi</option>
                            <option value="Kogi">Kogi</option>
                            <option value="Kwara">Kwara</option>
                            <option value="Lagos">Lagos</option>
                            <option value="Nassarawa">Nassarawa</option>
                            <option value="Niger">Niger</option>
                            <option value="Ogun">Ogun</option>
                            <option value="Ondo">Ondo</option>
                            <option value="Osun">Osun</option>
                            <option value="Oyo">Oyo</option>
                            <option value="Plateau">Plateau</option>
                            <option value="Rivers">Rivers</option>
                            <option value="Sokoto">Sokoto</option>
                            <option value="Taraba">Taraba</option>
                            <option value="Yobe">Yobe</option>
                            <option value="Zamfara">Zamfara</option>
                          </select>
                          {this.renderErrorFor("state")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="camp_id">
                            Camp <span className="text-danger">*</span>{" "}
                          </label>
                          <div className="d-flex">
                            <select
                              className={classnames({
                                "is-invalid": this.hasErrorFor("camp_id"),
                                "form-control": true,
                                memberselect22: true,
                              })}
                              placeholder="Select a Camp"
                              onChange={this.handleChange}
                              value={this.state.camp_id}
                              id="camp_id"
                              name="camp_id"
                              style={{
                                width: "100%",
                              }}
                            >
                              <option value="">--Select a camp--</option>
                              {camps &&
                                camps.map((camp, index) => (
                                  <option key={camp.id} value={camp.id}>
                                    {camp.name} -- {camp.area.name} --{" "}
                                    {camp.area.region.name} --{" "}
                                    {camp.area.region.state.name}
                                  </option>
                                ))}
                            </select>
                            {preference_spinner ? <MiniSpinner /> : null}
                          </div>
                          {this.renderErrorFor("camp_id")}
                          {this.initDependency()}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="phone">
                            Phone <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("phone"),
                              "form-control": true,
                            })}
                            id="phone"
                            name="phone"
                            onChange={this.handleChange}
                            value={this.state.phone}
                          />
                          {this.renderErrorFor("phone")}
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="phone_2">Phone_2</label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("phone_2"),
                              "form-control": true,
                            })}
                            id="phone_2"
                            name="phone_2"
                            onChange={this.handleChange}
                            value={this.state.phone_2}
                          />
                          {this.renderErrorFor("phone_2")}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="email">Email </label>
                        <input
                          type="email"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("email"),
                            "form-control": true,
                          })}
                          id="email"
                          name="email"
                          onChange={this.handleChange}
                          value={this.state.email}
                        />
                        {this.renderErrorFor("email")}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="image">Upload member's image</label>
                        <br />

                        <input
                          type="file"
                          id="image"
                          onChange={this.handleFileChange}
                        />

                        {this.renderErrorFor("image")}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="birth_date">
                            Birth Date <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("birth_date"),
                              "form-control": true,
                            })}
                            id="birth_date"
                            name="birth_date"
                            readOnly
                            placeholder="Select date"
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("birth_date")}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="alive">
                            Is member alive?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className={classnames({
                              "is-invalid": this.hasErrorFor("alive"),
                              "form-control": true,
                            })}
                            id="alive"
                            name="alive"
                            onChange={this.handleChange}
                            value={this.state.alive}
                          >
                            <option value={1} defaultValue>
                              Yes
                            </option>
                            <option value={0}>No</option>
                          </select>
                          {this.renderErrorFor("alive")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="passed_on">Passed on Date</label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("passed_on"),
                              "form-control": true,
                            })}
                            id="passed_on"
                            name="passed_on"
                            readOnly
                            placeholder="Select date"
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("passed_on")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="login_id">
                            Member ID <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("login_id"),
                              "form-control": true,
                            })}
                            id="login_id"
                            name="login_id"
                            onChange={this.handleChange}
                            value={this.state.login_id}
                          />
                          {this.renderErrorFor("login_id")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="new_member">
                            Existing member?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <select
                            className={classnames({
                              "is-invalid": this.hasErrorFor("new_member"),
                              "form-control": true,
                            })}
                            id="new_member"
                            name="new_member"
                            onChange={this.handleChange}
                            value={this.state.new_member}
                          >
                            <option value="">- Select -</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                          {this.renderErrorFor("new_member")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="last_payment_date">
                            Last payment date
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid":
                                this.hasErrorFor("last_payment_date"),
                              "form-control": true,
                            })}
                            id="last_payment_date"
                            name="last_payment_date"
                            readOnly
                            placeholder="Select date"
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("last_payment_date")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="years">For how many years </label>
                          <input
                            type="number"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("years"),
                              "form-control": true,
                            })}
                            id="years"
                            name="years"
                            onChange={this.handleChange}
                            value={this.state.years}
                          />
                          {this.renderErrorFor("years")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="title">
                            Title <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            placeholder=" Mr/Master/Dr ..."
                            className={classnames({
                              "is-invalid": this.hasErrorFor("title"),
                              "form-control": true,
                            })}
                            id="title"
                            name="title"
                            onChange={this.handleChange}
                            value={this.state.title}
                          />
                          {this.renderErrorFor("title")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="married">
                            Married <span className="text-danger">*</span>
                          </label>
                          <select
                            className={classnames({
                              "is-invalid": this.hasErrorFor("married"),
                              "form-control": true,
                            })}
                            id="married"
                            name="married"
                            onChange={this.handleChange}
                            value={this.state.married}
                          >
                            <option value="">- Select -</option>
                            <option value={1}>Yes</option>
                            <option value={0}>No</option>
                          </select>
                          {this.renderErrorFor("married")}
                        </div>
                      </div>
                      <div className="col-sm-4">
                        <div className="form-group">
                          <label htmlFor="spouse_id">
                            Spouse's Name <span className="text-danger">*</span>{" "}
                          </label>
                          <div className="d-flex">
                            <select
                              className={classnames({
                                "is-invalid": this.hasErrorFor("spouse_id"),
                                "form-control": true,
                                memberselect21: true,
                              })}
                              placeholder="Select a Gideon"
                              onChange={this.handleChange}
                              value={this.state.spouse_id}
                              id="spouse_id"
                              name="spouse_id"
                            >
                              <option value="">--Select a Gideon--</option>
                              {gideons &&
                                gideons.map((gideon, index) => (
                                  <option key={gideon.id} value={gideon.id}>
                                    {gideon.first_name} {gideon.last_name}{" "}
                                    {gideon.other_name} [{gideon.login_id}]
                                  </option>
                                ))}
                            </select>
                            {spinner ? <MiniSpinner /> : null}
                          </div>
                          {this.renderErrorFor("spouse_id")}
                          {this.initDependency()}
                        </div>
                      </div>

                      <div className="form-group col-md-4">
                        <label htmlFor="recruitment">Recruitment Method</label>
                        <select
                          name="recruitment"
                          id="recruitment"
                          value={this.state.recruitment}
                          onChange={this.handleChange}
                          className={classnames({
                            "is-invalid": this.hasErrorFor("recruitment"),
                            "form-control": true,
                          })}
                        >
                          <option value="">--Select--</option>
                          <option value="PSU">PSU</option>
                          <option value="NMP">NMP</option>
                          <option value="AMD">AMD</option>
                          <option value="REINSTATEMENT">REINSTATEMENT</option>
                        </select>

                        {this.renderErrorFor("recruitment")}
                      </div>

                      <div className="form-group col-sm-4">
                        <label htmlFor="membership_type">
                          Renewal type <span className="text-danger">*</span>
                        </label>
                        <select
                          id="membership_type"
                          name="membership_type"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("membership_type"),
                            "form-control": true,
                          })}
                          onChange={this.handleChange}
                          value={this.state.membership_type}
                        >
                          <option value="">- Select -</option>
                          <option value="annual">Annual membership</option>
                          <option value="life">Life membership</option>
                          <option value="veteran">Veteran membership</option>
                        </select>
                        {this.renderErrorFor("membership_type")}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>

                {/* end SECTION I: PERSONAL INFORMATION */}

                {/* start SECTION II: SPIRITUAL INFORMATION */}
                <div className="card collapsed-card opp">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">
                      SECTION II: SPIRITUAL INFORMATION
                    </h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-plus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="font-weight-bold pr-4"> A.</div>
                      <div className="row">
                        <div className="form-group col-sm-12">
                          <label htmlFor="spirit_info1">
                            Do you believe the Bible is the inspired (i.e
                            infallible &amp; inerrant) Word of God? (II Timothy
                            3:16) <span className="text-danger">*</span>
                          </label>
                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info1"
                                defaultValue="yes"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info1"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Yes
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info1"
                                defaultValue="no"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info1"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              No
                              <span />
                            </label>

                            {this.renderErrorFor("spirit_info1")}
                          </div>
                        </div>
                        <div className="form-group col-sm-12">
                          <label htmlFor="spirit_info2">
                            Do you believe in the Lord Jesus Christ as the
                            eternal Son of God? (John 3:16){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info2"
                                defaultValue="yes"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info2"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Yes
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info2"
                                defaultValue="no"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info2"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              No
                              <span />
                            </label>

                            {this.renderErrorFor("spirit_info2")}
                          </div>
                        </div>
                        <div className="form-group col-sm-12">
                          <label htmlFor="spirit_info3">
                            Have you received Him as your personal Saviour?
                            (Romans 10:9) <span className="text-danger">*</span>
                          </label>
                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info3"
                                defaultValue="yes"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info3"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Yes
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info3"
                                defaultValue="no"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info3"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              No
                              <span />
                            </label>

                            {this.renderErrorFor("spirit_info3")}
                          </div>
                        </div>
                        <div className="form-group col-sm-12">
                          <label htmlFor="spirit_info4">
                            Do you endeavor to follow Him in your daily life?
                            (Romans 12:1-2){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info4"
                                defaultValue="yes"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info4"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Yes
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info4"
                                defaultValue="no"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info4"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              No
                              <span />
                            </label>

                            {this.renderErrorFor("spirit_info4")}
                          </div>
                        </div>
                        <div className="form-group col-sm-12">
                          <label htmlFor="spirit_info5">
                            Do you believe in the endless lake of fire for the
                            unsaved? (Rev.20:10-15){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info5"
                                defaultValue="yes"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info5"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Yes
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info5"
                                defaultValue="no"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info5"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              No
                              <span />
                            </label>

                            {this.renderErrorFor("spirit_info5")}
                          </div>
                        </div>
                        <div className="form-group col-sm-12">
                          <label htmlFor="spirit_info6">
                            Do you accept the Biblical standard of marriage
                            being between one man and one woman? (Genesis 2:24){" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info6"
                                defaultValue="yes"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info6"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Yes
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info6"
                                defaultValue="no"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info6"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              No
                              <span />
                            </label>

                            {this.renderErrorFor("spirit_info6")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="font-weight-bold pr-4"> B.</div>
                      <div className="row">
                        <div className="form-group col-sm-12">
                          <label htmlFor="spirit_info7">
                            Are you a layman, NOT a clergyman? (Note:
                            <span className="font-italic">
                              clergyman means one who is generally accepted and
                              recognized as a practicing clergyman, a pastor or
                              a minister of a church, an evangelist, or a
                              missionary
                            </span>
                            ) <span className="text-danger">*</span>
                          </label>
                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info7"
                                defaultValue="yes"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info7"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Yes
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info7"
                                defaultValue="no"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info7"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              No
                              <span />
                            </label>

                            {this.renderErrorFor("spirit_info7")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="font-weight-bold pr-4"> C.</div>
                      <div className="row">
                        <div className="form-group col-sm-12">
                          <label htmlFor="spirit_info8">
                            Are you a member in good standing of a church, as
                            your church defines membership?{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info8"
                                defaultValue="yes"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info8"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Yes
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="spirit_info8"
                                defaultValue="no"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("spirit_info8"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              No
                              <span />
                            </label>

                            {this.renderErrorFor("spirit_info8")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="font-weight-bold pr-4"> D.</div>
                      <div className="row fgd">
                        <div className="form-group col-sm-12">
                          <label htmlFor="church_denomination">
                            Name of church and denomination{" "}
                            <span className="text-danger">*</span>
                          </label>
                          <textarea
                            className={classnames({
                              "is-invalid": this.hasErrorFor(
                                "church_denomination"
                              ),
                              "form-control": true,
                            })}
                            rows={2}
                            id="church_denomination"
                            name="church_denomination"
                            onChange={this.handleChange}
                            value={this.state.church_denomination}
                          />
                          {this.renderErrorFor("church_denomination")}
                        </div>
                        {/* <div className="form-group col-sm-12">
                          <label htmlFor="church_address">
                            {" "}
                            Church Address<span className="text-danger">*</span>
                          </label>
                          <textarea
                            className={classnames({
                              "is-invalid": this.hasErrorFor("church_address"),
                              "form-control": true
                            })}
                            id="church_address"
                            name="church_address"
                            rows={2}
                            onChange={this.handleChange}
                            value={this.state.church_address}
                          />
                          {this.renderErrorFor("church_address")}
                        </div>
                        <div className="form-group col-sm-12">
                          <label htmlFor="church_email">
                            {" "}
                            Church email or web address
                          </label>
                          <input
                            className={classnames({
                              "is-invalid": this.hasErrorFor("church_email"),
                              "form-control": true
                            })}
                            id="church_email"
                            name="church_email"
                            onChange={this.handleChange}
                            value={this.state.church_email}
                          />
                          {this.renderErrorFor("church_email")}
                        </div>
                        <div className="form-group col-sm-12">
                          <label htmlFor="church_phone_number">
                            {" "}
                            Church phone number
                          </label>
                          <input
                            className={classnames({
                              "is-invalid": this.hasErrorFor(
                                "church_phone_number"
                              ),
                              "form-control": true
                            })}
                            id="church_phone_number"
                            name="church_phone_number"
                            onChange={this.handleChange}
                            value={this.state.church_phone_number}
                          />
                          {this.renderErrorFor("church_phone_number")}
                        </div> */}
                      </div>
                    </div>
                    {/* <div className="d-flex">
                      <div className="font-weight-bold pr-4"> E.</div>
                      <div className="row fgd">
                        <div className="form-group col-sm-12">
                          <label htmlFor="name_of_pastor">
                            Name of Pastor/minister
                            <span className="text-danger">*</span>
                          </label>
                          <input
                            className={classnames({
                              "is-invalid": this.hasErrorFor("name_of_pastor"),
                              "form-control": true
                            })}
                            id="name_of_pastor"
                            name="name_of_pastor"
                            onChange={this.handleChange}
                            value={this.state.name_of_pastor}
                          />
                          {this.renderErrorFor("name_of_pastor")}
                        </div>
                        <div className="form-group col-sm-6">
                          <label htmlFor="pastors_phone_number">
                            Pastor's/minister's phone number:
                          </label>
                          <input
                            className={classnames({
                              "is-invalid": this.hasErrorFor(
                                "pastors_phone_number"
                              ),
                              "form-control": true
                            })}
                            id="pastors_phone_number"
                            name="pastors_phone_number"
                            onChange={this.handleChange}
                            value={this.state.pastors_phone_number}
                          />
                          {this.renderErrorFor("pastors_phone_number")}
                        </div>
                        <div className="form-group col-sm-6">
                          <label htmlFor="pastors_phone_email">
                            {" "}
                            Pastor's/minister's email address
                          </label>
                          <input
                            className={classnames({
                              "is-invalid": this.hasErrorFor(
                                "pastors_phone_email"
                              ),
                              "form-control": true
                            })}
                            id="pastors_phone_email"
                            name="pastors_phone_email"
                            onChange={this.handleChange}
                            value={this.state.pastors_phone_email}
                          />
                          {this.renderErrorFor("pastors_phone_email")}
                        </div>
                      </div>
                    </div> */}
                    <div className="d-flex">
                      <div className="font-weight-bold pr-4"> F.</div>
                      <div className="row fgd">
                        <div className="form-group col-sm-6">
                          <label htmlFor="previous_member">
                            Have you held previous Gideon membership?
                            <span className="text-danger">*</span>
                          </label>
                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="previous_member"
                                defaultValue="yes"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("previous_member"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Yes
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="previous_member"
                                defaultValue="no"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("previous_member"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              No
                              <span />
                            </label>

                            {this.renderErrorFor("previous_member")}
                          </div>
                        </div>
                        <div className="form-group col-sm-6">
                          <label htmlFor="previous_where">if yes, where:</label>
                          <input
                            className={classnames({
                              "is-invalid": this.hasErrorFor("previous_where"),
                              "form-control": true,
                            })}
                            id="previous_where"
                            name="previous_where"
                            onChange={this.handleChange}
                            value={this.state.previous_where}
                          />
                          {this.renderErrorFor("previous_where")}
                        </div>
                        <div className="form-group col-sm-6">
                          <label htmlFor="previous_date">
                            {" "}
                            if yes, approximate date
                          </label>
                          <input
                            type="text"
                            className={classnames({
                              "is-invalid": this.hasErrorFor("previous_date"),
                              "form-control": true,
                            })}
                            id="previous_date"
                            readOnly
                            placeholder="Select date"
                            name="previous_date"
                            onChange={this.handleChange}
                          />
                          {this.renderErrorFor("previous_date")}
                        </div>
                        <div className="form-group col-sm-6">
                          <label htmlFor="previous_number">
                            {" "}
                            if yes, approximate Gideon number
                          </label>
                          <input
                            className={classnames({
                              "is-invalid": this.hasErrorFor("previous_number"),
                              "form-control": true,
                            })}
                            id="previous_number"
                            name="previous_number"
                            onChange={this.handleChange}
                            value={this.state.previous_number}
                          />
                          {this.renderErrorFor("previous_number")}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* end SECTION II: SPIRITUAL INFORMATION */}

                {/* start SECTION III: DECLARATION */}
                <div className="card collapsed-card opp">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">
                      SECTION III: DECLARATION
                    </h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-plus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <p>
                      I have prayerfully considered all the preceding questions.
                      I now apply for membership in the Gideons International.
                    </p>
                    <p>
                      I also understand that the Membership Committee must
                      examine this application, as it has the responsibility of
                      guarding the Association's membership standards. If
                      accepted, I agree to do the work of the ministry according
                      to the guidelines and polices as determined by the
                      intenational Cabinet of The Gideons International.{" "}
                    </p>
                    <div className="row fgd">
                      <div className="form-group col-sm-6">
                        <label htmlFor="accepted_date">
                          Date <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          name="accepted_date"
                          id="accepted_date"
                          readOnly
                          placeholder="Select date"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("accepted_date"),
                            "form-control": true,
                          })}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("accepted_date")}
                      </div>
                      {/* <div className="form-group col-sm-6">
                        <label htmlFor="membership_type">
                          Membership type <span className="text-danger">*</span>
                        </label>
                        <select
                          id="membership_type"
                          name="membership_type"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("membership_type"),
                            "form-control": true
                          })}
                          onChange={this.handleChange}
                          value={this.state.membership_type}
                        >
                          <option value="">- Select -</option>
                          <option value="annual">Annual membership</option>
                          <option value="life">Life membership</option>
                        </select>
                        {this.renderErrorFor("membership_type")}
                      </div> */}
                      <div className="form-group col-sm-6">
                        <label htmlFor="pay_type">
                          Payment Type <span className="text-danger">*</span>
                        </label>
                        <select
                          id="pay_type"
                          name="pay_type"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("pay_type"),
                            "form-control": true,
                          })}
                          onChange={this.handleChange}
                          value={this.state.pay_type}
                        >
                          <option value="">- Select -</option>
                          <option value="cash">Cash</option>
                          <option value="check">Check</option>
                          <option value="credit_card">Credit Card</option>
                          <option value="ach">ACH</option>
                        </select>
                        {this.renderErrorFor("pay_type")}
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
                {/* end SECTION III: DECLARATION*/}

                {/* start SECTION IV: SPONSORSHIP */}
                <div className="card collapsed-card opp ">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">
                      SECTION IV: SPONSORSHIP
                    </h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-plus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <p>
                      The information in this section is to be completed only by
                      the sponsor of this applicant
                    </p>
                    <div className="d-flex">
                      <div className="font-weight-bold pr-4"> A.</div>
                      <div className="row">
                        <div className="form-group col-sm-12">
                          <label htmlFor="application_source">
                            Source of application for Gideon membership{" "}
                            <span className="text-danger">*</span>
                          </label>

                          <div className="kt-radio-inline radio form-group">
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="application_source"
                                defaultValue="individual enlistment"
                                defaultChecked
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("application_source"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Individual Enlistment
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="application_source"
                                defaultValue="annual membership dinner"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("application_source"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Annual Membership Dinner
                              <span />
                            </label>
                            <label className="radio-inline">
                              <input
                                type="radio"
                                name="application_source"
                                defaultValue="camp development plan"
                                className={classnames({
                                  "is-invalid":
                                    this.hasErrorFor("application_source"),
                                  "form-check-label": true,
                                })}
                                onClick={this.handleClick}
                                // value={this.state.spirit_info1}
                              />{" "}
                              Camp Development Plan
                              <span />
                            </label>

                            {this.renderErrorFor("application_source")}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex">
                      <div className="font-weight-bold pr-4"> B.</div>
                      <div className="row fgd">
                        <div className="form-group col-sm-12">
                          <label htmlFor="church_denomination">
                            I am Sponsoring this applicant for the following
                            reasons:
                          </label>
                          <div className="d-flex">
                            <div className="font-weight-bold pr-4"> 1.</div>
                            <div className="row fgd">
                              <div className="form-group col-sm-12">
                                <label htmlFor="sponsor_spiritual_reasons">
                                  Spiritual
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  name="sponsor_spiritual_reasons"
                                  id="sponsor_spiritual_reasons"
                                  rows={2}
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "sponsor_spiritual_reasons"
                                    ),
                                    "form-control": true,
                                  })}
                                  onChange={this.handleChange}
                                  value={this.state.sponsor_spiritual_reasons}
                                />
                                {this.renderErrorFor(
                                  "sponsor_spiritual_reasons"
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="font-weight-bold pr-4"> 2.</div>
                            <div className="row fgd">
                              <div className="form-group col-sm-12">
                                <label htmlFor="sponsor_occupation">
                                  Occupational (briefly describe type of job,
                                  business operation or profession)
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  name="sponsor_occupation"
                                  id="sponsor_occupation"
                                  rows={2}
                                  className={classnames({
                                    "is-invalid":
                                      this.hasErrorFor("sponsor_occupation"),
                                    "form-control": true,
                                  })}
                                  onChange={this.handleChange}
                                  value={this.state.sponsor_occupation}
                                />
                                {this.renderErrorFor("sponsor_occupation")}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="font-weight-bold pr-4"> 3.</div>
                            <div className="row fgd">
                              <div className="form-group col-sm-12">
                                <label htmlFor="sponsor_card">
                                  Membership Card #
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="sponsor_card"
                                  id="sponsor_card"
                                  className={classnames({
                                    "is-invalid":
                                      this.hasErrorFor("sponsor_card"),
                                    "form-control": true,
                                  })}
                                  onChange={this.handleChange}
                                  value={this.state.sponsor_card}
                                />
                                {this.renderErrorFor("sponsor_card")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-body */}
                </div>
                {/* end SECTION IV: SPONSORSHIP*/}

                {/* start SECTION V: OTHER INFOMATION */}
                <div className="card collapsed-card opp ">
                  <div
                    className="card-header"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                  >
                    <h3 className="card-title cardie ">
                      SECTION V: OTHER INFOMATION
                    </h3>
                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      >
                        <i className="fas fa-plus" />
                      </button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="d-flex">
                      <div className="font-weight-bold pr-4"> A.</div>
                      <div className="row fgd">
                        <div className="form-group col-sm-12">
                          <label
                            htmlFor="church_denomination"
                            className="font-weight-bold"
                          >
                            {" "}
                            Pastor Endorsement:
                          </label>
                          <div className="d-flex">
                            <div className="font-weight-bold pr-4"> 1.</div>
                            <div className="row fgd">
                              <div className="form-group col-sm-12">
                                <label htmlFor="endorsement_name">
                                  Name<span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="endorsement_name"
                                  id="endorsement_name"
                                  className={classnames({
                                    "is-invalid":
                                      this.hasErrorFor("endorsement_name"),
                                    "form-control": true,
                                  })}
                                  onChange={this.handleChange}
                                  value={this.state.endorsement_name}
                                />
                                {this.renderErrorFor("endorsement_name")}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="font-weight-bold pr-4"> 2.</div>
                            <div className="row fgd">
                              <div className="form-group col-sm-12">
                                <label htmlFor="endorsement_position">
                                  Position<span className="text-danger">*</span>
                                </label>

                                <div className="kt-radio-inline radio form-group">
                                  <label className="radio-inline">
                                    <input
                                      type="radio"
                                      name="endorsement_position"
                                      defaultValue="pastor"
                                      defaultChecked
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "endorsement_position"
                                        ),
                                        "form-check-label": true,
                                      })}
                                      onClick={this.handleClick}
                                      // value={this.state.spirit_info1}
                                    />{" "}
                                    Pastor
                                    <span />
                                  </label>
                                  <label className="radio-inline">
                                    <input
                                      type="radio"
                                      name="endorsement_position"
                                      defaultValue="associate pastor"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "endorsement_position"
                                        ),
                                        "form-check-label": true,
                                      })}
                                      onClick={this.handleClick}
                                      // value={this.state.spirit_info1}
                                    />{" "}
                                    Associate Pastor
                                    <span />
                                  </label>
                                  <label className="radio-inline">
                                    <input
                                      type="radio"
                                      name="endorsement_position"
                                      defaultValue="minister of education"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "endorsement_position"
                                        ),
                                        "form-check-label": true,
                                      })}
                                      onClick={this.handleClick}
                                      // value={this.state.spirit_info1}
                                    />{" "}
                                    Minister of Education
                                    <span />
                                  </label>
                                  <label className="radio-inline">
                                    <input
                                      type="radio"
                                      name="endorsement_position"
                                      defaultValue="minister of music"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "endorsement_position"
                                        ),
                                        "form-check-label": true,
                                      })}
                                      onClick={this.handleClick}
                                      // value={this.state.spirit_info1}
                                    />{" "}
                                    Minister of Music
                                    <span />
                                  </label>
                                  <label className="radio-inline">
                                    <input
                                      type="radio"
                                      name="endorsement_position"
                                      defaultValue="others"
                                      className={classnames({
                                        "is-invalid": this.hasErrorFor(
                                          "endorsement_position"
                                        ),
                                        "form-check-label": true,
                                      })}
                                      onClick={this.handleClick}
                                      // value={this.state.spirit_info1}
                                    />{" "}
                                    Others
                                    <span />
                                  </label>

                                  {this.renderErrorFor("endorsement_position")}
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="d-flex">
                            <div className="font-weight-bold pr-4"> 3.</div>
                            <div className="row fgd">
                              <div className="form-group col-sm-12">
                                <label htmlFor="pastors_comments">
                                  Pastor's Comments{" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <textarea
                                  name="pastors_comments"
                                  id="pastors_comments"
                                  rows={2}
                                  className={classnames({
                                    "is-invalid": this.hasErrorFor(
                                      "pastors_comments"
                                    ),
                                    "form-control": true
                                  })}
                                  onChange={this.handleChange}
                                  value={this.state.pastors_comments}
                                />
                                {this.renderErrorFor("pastors_comments")}
                              </div>
                            </div>
                          </div> */}
                          <div className="d-flex">
                            <div className="font-weight-bold pr-4"> 4.</div>
                            <div className="row fgd">
                              <div className="form-group col-sm-12">
                                <label htmlFor="endorse_date">
                                  Date<span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="endorse_date"
                                  id="endorse_date"
                                  readOnly
                                  placeholder="Select date"
                                  className={classnames({
                                    "is-invalid":
                                      this.hasErrorFor("endorse_date"),
                                    "form-control": true,
                                  })}
                                  onChange={this.handleChange}
                                />
                                {this.renderErrorFor("endorse_date")}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex">
                            <div className="font-weight-bold pr-4"> 5.</div>
                            <div className="row fgd">
                              <div className="form-group col-sm-12">
                                <label htmlFor="endorse_by">
                                  By (Headquaters Staff Initials)
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  type="text"
                                  name="endorse_by"
                                  id="endorse_by"
                                  className={classnames({
                                    "is-invalid":
                                      this.hasErrorFor("endorse_by"),
                                    "form-control": true,
                                  })}
                                  onChange={this.handleChange}
                                  value={this.state.endorse_by}
                                />
                                {this.renderErrorFor("endorse_by")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* /.card-body */}
                </div>
                {/* end SECTION V: OTHER INFOMATION*/}
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  Create
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    preference_spinner: state.preferences.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    camps: state.preferences.camps,
    gideons: state.users.gideons,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    createAuxillary: (creds) => createAuxillary(dispatch, creds),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    getAllCamps: () => dispatch(getAllCamps()),
    getAllGideons: () => dispatch(getAllGideons()),
    resetUsersState: () => resetUsersState(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateAuxilary);
