import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  changePasswordRequest,
  resetUsersState
} from "../../../store/actions/usersActions";
import Message from "../../helpers/Message";
import { setPermissionsErrors } from "../../../store/actions/authActions";
import MiniSpinner from "../../helpers/MiniSpinner";

// 10.10.1.227

class ChangePassword extends Component {
  state = {
    old_password: "",
    password: "",
    password_confirmation: ""
  };
  componentDidMount() {
    // this.initDependency();
    // this.props.getAllCamps();
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    });
  };
  // handleFileChange = e => {
  //     this.setState({
  //         image: e.target.files[0]
  //     });
  // };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    // console.log(this.props);

    this.props.changePasswordRequest(this.state).then(res => {
      if (res.status === "success") {
        this.setState({
          old_password: "",
          password: "",
          password_confirmation: ""
        });
      }
    });
  };

  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Personal</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Change my Password</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Change my Password
              </h3>
              <div className="card-tools"></div>
            </div>
            <form onSubmit={this.handleSubmit}>
              <div className="card-body">
                {/* {spinner ? <Spinner position="right" /> : null} */}
                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label htmlFor="old_password">
                        Current Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("old_password"),
                          "form-control": true
                        })}
                        name="old_password"
                        id="old_password"
                        onChange={this.handleChange}
                        value={this.state.old_password}
                      />
                      {this.renderErrorFor("old_password")}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="password">
                        New Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("password"),
                          "form-control": true
                        })}
                        name="password"
                        id="password"
                        onChange={this.handleChange}
                        value={this.state.password}
                      />
                      {this.renderErrorFor("password")}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="password_confirmation">
                        Confirm New Password{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type="password"
                        className={classnames({
                          "is-invalid": this.hasErrorFor(
                            "password_confirmation"
                          ),
                          "form-control": true
                        })}
                        name="password_confirmation"
                        id="password_confirmation"
                        value={this.state.password_confirmation}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("password_confirmation")}
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  Submit
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors
  };
};
const mapDispatchToProps = dispatch => {
  return {
    changePasswordRequest: creds => changePasswordRequest(dispatch, creds),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
