import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import classnames from "classnames";
import { getUserDepotInventories } from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import { token,storage_type, user_auth_details, dehash, BASE_URL } from "../../../../config";
//import DetailDepotInventory from "./DetailDepotInventory";

class ViewDepotInventory extends Component {
  state = {
    depot_inventory: null,
    depot_id: "",
    SelectedDepot: ''
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_DEPOT_INVENTORY" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      if (storage_type.getItem(user_auth_details)) {
        const user = JSON.parse(
          dehash(`${storage_type.getItem(user_auth_details)}`)
        );
        this.setState({ depots: user.depots }); //modified by Macdonald
      }
      //getAllDepotInventory(); //commented by Macdonald
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    // ----------------commented out by Macdonald----------------------
    // if (nextProps.depot_inventory !== prevState.depot_inventory) {
    //   return {
    //     depot_inventory: nextProps.depot_inventory,
    //   };
    // }
    // return null;
    //------------------------------------END--------------------------------
  }

  handleChange = e => {
    this.setState({
      [e.target.id]: e.target.value,
    });
    
    const creds = {
      depot_id: e.target.value
    };
    
    var cars = this.getUserDepotInventories(creds);
    console.log("Credentials: ", creds);

    window.$depotID = e.target.value;

    // getAllDepotInventory(e.target.value);
  };

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  async getUserDepotInventories (invs) {
    const response = await fetch(
      `${BASE_URL}/depot/inventories/depot-inventories?Id=`+invs.depot_id, {
      method:'GET',
      headers: {
        //"Content-Type": "application/json",
        Authorization: `Bearer ${storage_type.getItem(token)}`,
      }, 
    })
    var result = await response.json();
    this.setState({
      depot_inventory: result.payload.inventories
    });
  };

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    console.log("State Depots: ", this.state.depots);
    console.log("State Depot Inventories: ", this.state.depot_inventory);
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    View Depot Inventory
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
              {/* <form onSubmit={this.handleSubmit}> */}
                <div className='row'>
                  <div className="form-group col-md-12">
                    <label>
                      Select From Your Depots <span className="text-danger"> *</span>
                    </label>
                    {'  '}
                    <select
                        // className={classnames({
                        //   "is-invalid": this.hasErrorFor("id"),
                        //   "form-control selected_state": true
                        // })}
                        placeholder="Select"
                        onChange={this.handleChange}
                        id="depot_id"
                        value={this.state.depot_id}
                      >
                      <option value="">--Select--</option>
                        {this.state.depots &&
                          this.state.depots.map((depot, index) => (
                            <option key={index} value={depot.id}>
                              {depot.name} - {depot.address}
                            </option>
                          ))}
                    </select>
                  </div>
                </div>
                {/* {this.state.depots ? `(${this.state.depots[0].name})` : null} */}
              {/* </form> */}
              </h3>
              <div className="card-tools">
                <Link to={`/preferences/depot-inventory/add/${this.state.depot_id}`}>
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-plus-circle "></i>
                    ADD INVENTORY
                  </button>
                </Link>{" "}
                <Link to="/preferences/depot-inventory/remove">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-minus-circle mr-1"></i>
                    REMOVE INVENTORY
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <table
                    id="hq_inventory_table"
                    className="table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Inventory</th>
                        <th>Type</th>
                        <th>Quantity</th>
                        <th>View</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.depot_inventory &&
                        this.state.depot_inventory.map((inventory, index) => {
                          return (
                            <tr key={index}>
                              <td>{inventory.bible.name}</td>
                              <td>
                                {" "}
                                {inventory.bible.merchandise === 1
                                  ? "Cash Item"
                                  : "Non Cash Item"}
                              </td>
                              <td>{inventory.stock}</td>

                              <td>
                                <span
                                  style={{
                                    overflow: "visible",
                                    position: "relative",
                                    width: "110px",
                                  }}
                                >
                                {/* <Link
                                    to={{pathname: "/preferences/depot-inventory/",
                                    data: `${inventory.id, this.state.depot_id}`}}
                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                    title="view inventory"
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Link> */}


                                  <Link
                                    to={`/preferences/depot-inventory/${inventory.id}`}
                                    className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                    title="view inventory"
                                  >
                                    <i className="fa fa-eye"></i>
                                  </Link>
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
          {/* <DetailDepotInventory dataFromParent = {this.state.depot_id} /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    depot_inventory: state.preferences.depot_inventory,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    //getAllDepotInventory: (creds) => getAllDepotInventory(dispatch,creds), //Modified by Macdonald
    //getAllDepotInventory: () => dispatch(getAllDepotInventory()), //Modified by Macdonald
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewDepotInventory);
