import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getStateAccountReport,
  resetReportState,
  regionDetails,
  stateAreaDetails,
  stateCampDetails,
} from "./../../../../store/actions/reportActions";
import {
  getAllRegions,
  getAllAreas,
  getAllCamps,
  getAllStates,
} from "../../../../store/actions/preferencesActions";

import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import classnames from "classnames";
import MiniSpinner from "./../../../helpers/MiniSpinner";
import $ from "jquery";
// import _ from "underscore";
import { BASE_URL, token, storage_type } from "../../../../config";
// import { hasPermission } from "./../../helpers/functions/functions";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";

class StateAccountReportHQ extends Component {
  state = {
    states: null,
    funds_state_camp: null,
    funds_state_area: null,
    funds_state: null,
    funds_state_region: null,
    state_id: "",
    region_id: "",
    area_id: "",
    camp_id: "",
    is_state: false,
    is_region: false,
    is_area: false,
    is_camp: false,
    // arranged_data: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REPORTS",
      }) ||
      !hasPermission({
        mod: "Reports",
        action: "VIEW_STATES_FUND_REPORTS_HQ",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    document.title = "The Gideon International In Nigeria (State Fund Report)";
    this.initDependency();
    // const { getStateAccountReport } = this.props;
    // getStateAccountReport(1, "12/12/2020", "15/12/2020");
    // this.props.getAllCamps();
    // this.props.getAllCamps();
    // this.props.getAllAreas();
    // this.props.getAllRegions();
    this.props.getAllStates();
  }
  initDependency = () => {
    // alert("here");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.funds_state_area !== prevState.funds_state_area ||
      nextProps.funds_state_camp !== prevState.funds_state_camp ||
      nextProps.funds_state_region !== prevState.funds_state_region ||
      nextProps.states !== prevState.states ||
      nextProps.funds_state !== prevState.funds_state
    ) {
      // const arry = [];
      // const temp_funds_state = [...nextProps.funds_state];
      // if (temp_funds_state.length) {
      //   const camp_group = _.groupBy(temp_funds_state, "state");
      //   for (let [key, value] of Object.entries(camp_group)) {
      //     arry.push({
      //       state: key,
      //       fund: _.groupBy(value, "purpose"),
      //     });
      //   }
      //   // console.log(arry);
      //   // this.updateState(arry);
      // }

      return {
        funds_state_camp: nextProps.funds_state_camp,
        funds_state_area: nextProps.funds_state_area,
        funds_state_region: nextProps.funds_state_region,
        states: nextProps.states,
        funds_state: nextProps.funds_state,
      };
    }
    return null;
  }
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  handleDownload = (e) => {
    e.preventDefault();
    const from = $("#from_date").val();
    const to = $("#to_date").val();
    const state = this.state.state_id;
    const tok = storage_type.getItem(token);
    const download = 1;
    const url = `${BASE_URL}/reports/states/funds?from=${from}&to=${to}&state=${state}&download=${download}&token=${tok}`;
    window.open(url, "_blank");
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const from_date = $("#from_date").val();
    const to_date = $("#to_date").val();
    document.title = `The Gideon International In Nigeria (State Fund Report ${from_date}---${to_date})`;
    this.setState({ from: from_date, to: to_date }, () => this.StateDetails());
    // const state = this.state.state_id;
  };

  // arrangeReport = () => {};
  // updateState = arry => {
  //   const dd = arry;
  //   setTimeout(() => {
  //     this.setState({ new_arry: dd });
  //   }, 1000);
  // };
  setDownload = (e) => {
    e.preventDefault();
    $(".fund_table").attr("id", "role_table22");
    this.initDependency();
  };
  regionDetails = (e, state, type) => {
    e.preventDefault();
    // console.log(state, type);
    this.setState({
      is_state: false,
      is_region: true,
      is_area: false,
      is_camp: false,
      type: type,
    });
    const { from, to } = this.state;
    this.props.regionDetails(state, type, from, to);
  };
  StateDetails = (e) => {
    // e !== undefined ? e.preventDefault() : null;
    // console.log(state, type);
    this.setState({
      is_state: true,
      is_region: false,
      is_area: false,
      is_camp: false,
    });
    const { state_id, from, to } = this.state;
    this.props.getStateAccountReport(state_id, from, to);
  };
  StateAreaDetails = (e, state, type) => {
    e.preventDefault();
    // console.log(state, type);
    this.setState({
      is_state: false,
      is_region: false,
      is_area: true,
      is_camp: false,
      type: type,
    });
    const { from, to } = this.state;
    this.props.stateAreaDetails(state, type, from, to);
  };
  StateCampDetails = (e, state, type) => {
    e.preventDefault();
    // console.log(state, type);
    this.setState({
      is_state: false,
      is_region: false,
      is_area: false,
      is_camp: true,
      type: type,
    });
    const { from, to } = this.state;
    this.props.stateCampDetails(state, type, from, to);
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  dateConv = (date) => {
    return date ? new Date(date).toDateString() : null;
  };
  componentWillUnmount() {
    this.props.resetReportState();
  }
  render() {
    const { spinner, message, status, preference_spinner } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    // if (this.state.funds_state) {
    //   this.arrangeReport();
    // }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>REPORT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Fund Report HQ</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Fund Report HQ</h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "3000px" }}>
                  <Spinner />
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="col-12 text-center">
                  <p>
                    Ensure your selected date range falls within one Gideon
                    year.
                  </p>
                </div>
                <div className="row  ">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="from_date">
                        From Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("from_date"),
                          "form-control": true,
                        })}
                        id="from_date"
                        name="from_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("from_date")}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="to_date">
                        To Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("to_date"),
                          "form-control": true,
                        })}
                        id="to_date"
                        name="to_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("to_date")}
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="camp_id">
                        Camp <span className="text-danger">*</span>{" "}
                      </label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%"
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.camps &&
                            this.state.camps.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                        </select>
                        {preference_spinner ? <MiniSpinner /> : null}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div> */}
                  <div className=" col-sm-4">
                    <label>State</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("state_id"),
                          "form-control selected_area": true,
                        })}
                        onChange={this.handleChange}
                        id="state_id"
                        value={this.state.state_id}
                        // defaultValue={this.state.state_id}
                      >
                        <option value="">--Select a State--</option>
                        {this.state.states &&
                          this.state.states.map((state, index) => (
                            <option key={index} value={state.id}>
                              {state.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor("state_id")}
                  </div>
                  {/* <div className=" col-sm-2">
                    <label>Region</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("region_id"),
                          "form-control selected_area": true,
                        })}
                        onChange={this.handleChange}
                        id="region_id"
                        value={this.state.region_id}
                        // defaultValue={this.state.region_id}
                      >
                        <option value="">--Select a region--</option>
                        {this.state.funds_state_region &&
                          this.state.funds_state_region.map((region, index) => (
                            <option key={index} value={region.id}>
                              {region.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor("region_id")}
                  </div>
                  <div className=" col-sm-2">
                    <label>Area</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("area_id"),
                          "form-control selected_area": true,
                        })}
                        onChange={this.handleChange}
                        id="area_id"
                        value={this.state.area_id}
                        // defaultValue={this.state.region_id}
                      >
                        <option value="">--Select an Area--</option>
                        {this.state.funds_state_area &&
                          this.state.funds_state_area.map((area, index) => (
                            <option key={index} value={area.id}>
                              {area.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor("area_id")}
                  </div>
                  <div className=" col-sm-2">
                    <label>Camp</label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("camp_id"),
                          "form-control selected_area": true,
                        })}
                        onChange={this.handleChange}
                        id="camp_id"
                        // defaultValue={this.state.region_id}
                        value={this.state.camp_id}
                      >
                        <option value="">--Select a Camp--</option>
                        {this.state.funds_state_camp &&
                          this.state.funds_state_camp.map((camp, index) => (
                            <option key={index} value={camp.id}>
                              {camp.name}
                            </option>
                          ))}
                      </select>
                      {preference_spinner ? <MiniSpinner /> : null}
                    </div>
                    {this.renderErrorFor("camp_id")}
                  </div> */}

                  {/* {this.state.funds_state.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}
                </div>
                <div className="row mb-3">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                    disabled={spinner ? true : false}
                  >
                    Generate
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>

                  {/* <button
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button> */}
                  {/* {this.state.funds_state.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}
                </div>
              </form>

              {this.state.funds_state.length &&
              !spinner &&
              this.state.is_state ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th>Scripture Fund Total (&#8358;)</th>
                        <th>
                          {this.state.funds_state &&
                            this.moneyConv(
                              this.state.funds_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj.purpose === "Scripture Funds"
                                      ? obj.amount
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </th>
                        <th>Admin Fund Total (&#8358;)</th>
                        <th>
                          {this.state.funds_state &&
                            this.moneyConv(
                              this.state.funds_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj.purpose === "Administrative Funds"
                                      ? obj.amount || 0
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {/* <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th> */}
                        <th>State</th>
                        <th>Fund Purpose</th>
                        <th>Fund Type</th>
                        {/* <th>Fund Channel</th>
                        <th> Paid Date</th> */}
                        <th>Amount(&#8358;)</th>
                      </tr>
                      {this.state.funds_state &&
                        this.state.funds_state.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.state}</td>
                                <td>{item.purpose}</td>
                                <td>{item.type}</td>
                                <td>
                                  <Link
                                    to="#!"
                                    onClick={(e) =>
                                      this.regionDetails(
                                        e,
                                        this.state.state_id,
                                        item.type
                                      )
                                    }
                                  >
                                    {item.amount}
                                  </Link>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          {this.state.funds_state &&
                            this.moneyConv(
                              this.state.funds_state.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc + parseFloat(obj.amount ? obj.amount : 0)
                                );
                              },
                              0)
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {this.state.funds_state_region.length &&
              !spinner &&
              this.state.is_region ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      {/* <tr>
                        <th>Scripture Fund Total (&#8358;) </th>
                        <th>
                          {this.state.funds_state_region &&
                            this.moneyConv(
                              this.state.funds_state_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj.purpose === "Scripture Funds"
                                      ? obj.amount
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </th>
                        <th>Admin Fund Total (&#8358;) </th>
                        <th>
                          {this.state.funds_state_region &&
                            this.moneyConv(
                              this.state.funds_state_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc +
                                  parseFloat(
                                    obj.purpose === "Administrative Funds"
                                      ? obj.amount || 0
                                      : 0
                                  )
                                );
                              },
                              0)
                            )}
                        </th>
                      </tr> */}
                      <tr>
                        {/* <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th> */}
                        <th>Region</th>
                        <th>State</th>
                        <th>Fund Purpose</th>
                        <th>Fund Type</th>
                        {/* <th>Fund Channel</th>
                        <th> Paid Date</th> */}
                        <th>Amount(&#8358;)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.funds_state_region &&
                        this.state.funds_state_region.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>{item.purpose}</td>
                                <td>{item.type}</td>
                                <td>
                                  <Link
                                    to="#!"
                                    onClick={(e) =>
                                      this.StateAreaDetails(
                                        e,
                                        this.state.state_id,
                                        item.type
                                      )
                                    }
                                  >
                                    {item.amount}
                                  </Link>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          <button
                            type="button"
                            onClick={this.StateDetails}
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                            disabled={spinner ? true : false}
                          >
                            Back
                          </button>
                        </td>
                        <td>
                          {this.state.funds_state_region &&
                            this.moneyConv(
                              this.state.funds_state_region.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc + parseFloat(obj.amount ? obj.amount : 0)
                                );
                              },
                              0)
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {this.state.funds_state_area.length &&
              !spinner &&
              this.state.is_area ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        {/* <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th> */}
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Fund Purpose</th>
                        <th>Fund Type</th>
                        {/* <th>Fund Channel</th>
                        <th> Paid Date</th> */}
                        <th>Amount(&#8358;)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.funds_state_area &&
                        this.state.funds_state_area.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.area}</td>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>{item.purpose}</td>
                                <td>{item.type}</td>
                                <td>
                                  <Link
                                    to="#!"
                                    onClick={(e) =>
                                      this.StateCampDetails(
                                        e,
                                        this.state.state_id,
                                        item.type
                                      )
                                    }
                                  >
                                    {item.amount}
                                  </Link>
                                </td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          <button
                            type="button"
                            onClick={(e) =>
                              this.regionDetails(
                                e,
                                this.state.state_id,
                                this.state.type
                              )
                            }
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                            disabled={spinner ? true : false}
                          >
                            Back
                          </button>
                        </td>
                        <td>
                          {this.state.funds_state_area &&
                            this.moneyConv(
                              this.state.funds_state_area.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc + parseFloat(obj.amount ? obj.amount : 0)
                                );
                              },
                              0)
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}

              {this.state.funds_state_camp.length &&
              !spinner &&
              this.state.is_camp ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover table-bordered table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        {/* <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th> */}
                        <th>Camp</th>
                        <th>Area</th>
                        <th>Region</th>
                        <th>State</th>
                        <th>Fund Purpose</th>
                        <th>Fund Type</th>
                        <th>Fund Channel</th>
                        <th> Paid Date</th>
                        <th>Amount(&#8358;)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.funds_state_camp &&
                        this.state.funds_state_camp.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <tr>
                                <td>{item.camp}</td>
                                <td>{item.area}</td>
                                <td>{item.region}</td>
                                <td>{item.state}</td>
                                <td>{item.purpose}</td>
                                <td>{item.type}</td>
                                <td>{item.channel}</td>
                                <td>{this.dateConv(item.paid_date)}</td>
                                <td>{item.amount}</td>
                              </tr>
                            </React.Fragment>
                          );
                        })}
                      <tr>
                        <td>
                          <strong>Total</strong>
                        </td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>
                          {" "}
                          <button
                            type="button"
                            onClick={(e) =>
                              this.StateAreaDetails(
                                e,
                                this.state.state_id,
                                this.state.type
                              )
                            }
                            className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2 mt-2"
                            disabled={spinner ? true : false}
                          >
                            Back
                          </button>
                        </td>
                        <td>
                          {this.state.funds_state_area &&
                            this.moneyConv(
                              this.state.funds_state_area.reduce(function (
                                acc,
                                obj
                              ) {
                                return (
                                  acc + parseFloat(obj.amount ? obj.amount : 0)
                                );
                              },
                              0)
                            )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.reports.spinner,
    message: state.reports.message,
    status: state.reports.status,
    errors: state.reports.errors,
    funds_state: state.reports.funds_state,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
    states: state.preferences.states,
    funds_state_camp: state.reports.funds_state_camp,
    funds_state_area: state.reports.funds_state_area,
    funds_state_region: state.reports.funds_state_region,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getStateAccountReport: (state, from, to) =>
      dispatch(getStateAccountReport(state, from, to)),
    // getAllCamps: () => dispatch(getAllCamps()),
    getAllStates: () => dispatch(getAllStates()),
    getAllRegions: () => dispatch(getAllRegions()),
    getAllAreas: () => dispatch(getAllAreas()),
    getAllCamps: () => dispatch(getAllCamps()),
    stateCampDetails: (state, type, from, to) =>
      dispatch(stateCampDetails(state, type, from, to)),
    stateAreaDetails: (state, type, from, to) =>
      dispatch(stateAreaDetails(state, type, from, to)),
    regionDetails: (state, type, from, to) =>
      dispatch(regionDetails(state, type, from, to)),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetReportState: () => resetReportState(dispatch),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StateAccountReportHQ);
