import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import { cancelRemita } from "./../../../../store/actions/usersActions";
import classnames from "classnames";
import {
  increment,
  decrement,
  removeItem,
  clearCart,
  checkOut,
  force_cart_total,
  showSuccessMessage,
} from "./../../../../store/actions/tstoreActions";
import CartColumns from "./CartColumns";
import EmptyCart from "./EmptyCart";
import CartList from "./CartList";
import CartTotals from "./CartTotals";
import { storage_type, user_auth_details, dehash } from "./../../../../config";
import MiniSpinner from "../../../helpers/MiniSpinner";
import queryString from "query-string";

class Cart extends Component {
  state = {
    cart: [],
    cartSubTotal: 0,
    cartTax: 0,
    cartTotal: 0,
    modal_2: false,
    modal_1: false,
  };
  increment = (id) => {
    this.props.increment(id);
  };
  decrement = (id) => {
    this.props.decrement(id);
  };
  removeItem = (id) => {
    this.props.removeItem(id);
  };
  clearCart = () => {
    this.props.clearCart();
  };
  checkOut = () => {
    this.props.checkOut();
  };
  closeModal_2 = (e) => {
    e.preventDefault();
    this.setState({
      modal_2: false,
      merchantId: "",
      rrr: "",
      response_url: "",
      action_url: "",
      hash: "",
    });
    // this.props.resetUsersState();
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    const user = JSON.parse(
      dehash(`${storage_type.getItem(user_auth_details)}`)
    );

    if (user.type === "S") {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      this.props.force_cart_total();
      let params = queryString.parse(this.props.location.search);
      if (params.status === "success") {
        this.props.showSuccessMessage();
      }
      if (params.message && params.message === "Approved") {
        this.setState({
          ...params,
          modal_1: true,
          info: "Transaction Successful",
        });
      }
      if (params.message && params.message !== "Approved") {
        this.setState({ ...params, modal_1: true, info: "Transaction Failed" });
      }
    }
  }

  // componentDidMount() {
  //   const { setPermissionsErrors } = this.props;
  //   if (
  //     !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
  //     !hasPermission({ mod: "Preferences", action: "UPDATE_STATE" })
  //   ) {
  //     setPermissionsErrors();
  //     this.props.history.push("/");
  //   } else {
  //     //   const id = this.props.match.params.id;
  //     //   this.props.getOneState(id).then(res => {
  //     //     if (res.status === "success") {
  //     //     //   console.log(res);
  //     //       this.setState({
  //     //         name: res.payload.state.name
  //     //       });
  //     //     }
  //     //   });
  //   }
  // }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.cart !== prevState.cart ||
      nextProps.cartSubTotal !== prevState.cartSubTotal ||
      nextProps.cartTax !== prevState.cartTax ||
      nextProps.cartTotal !== prevState.cartTotal ||
      nextProps.merchantId !== prevState.merchantId ||
      nextProps.rrr !== prevState.rrr ||
      nextProps.response_url !== prevState.response_url ||
      nextProps.action_url !== prevState.action_url ||
      nextProps.hash !== prevState.hash
    ) {
      return {
        cart: nextProps.cart,
        cartSubTotal: nextProps.cartSubTotal,
        cartTax: nextProps.cartTax,
        cartTotal: nextProps.cartTotal,
        merchantId: nextProps.merchantId,
        rrr: nextProps.rrr,
        response_url: nextProps.response_url,
        action_url: nextProps.action_url,
        hash: nextProps.hash,
        modal_2: nextProps.hash ? true : false,
      };
    }
    return null;
  }
  covMoney = (amount) => {
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  closeModal_1 = (e) => {
    e.preventDefault();
    this.setState({ modal_1: false });
  };
  render() {
    const { spinner, status, message, spinner2 } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>STORE</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">My Cart</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">My Cart</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {this.state.modal_2 ? (
                <div
                  //   className="card opp"
                  //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    zIndex: 9999999,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    // padding: "2%",
                    display: "flex",
                    justifyContent: "center",
                    overflowX: "hidden",

                    alignItems: "center",

                    // transition: "all 10s ease"
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: "#fff",
                      height: "300px",
                      overflowX: "hidden",
                    }}
                  >
                    <form action={this.state.action_url} method="POST">
                      <h4 className="text-center mt-3">
                        Pay &#8358; {this.covMoney(this.state.cartTotal)} with
                        Remita{" "}
                        <img
                          src="/dist/img/remita.png"
                          alt=""
                          width="50"
                          style={{ position: "relative", top: "-1px" }}
                        />
                      </h4>
                      <p className="text-center text-danger ">
                        <small className="font-weight-bold">
                          Please note that you may be charge proccessing fee of
                          &#8358; {this.covMoney(100)}{" "}
                        </small>
                      </p>
                      <hr />
                      <div className="col-sm-6 offset-sm-3 ">
                        <p>
                          <strong>
                            {" "}
                            Remita Retrieval Reference (rrr): {
                              this.state.rrr
                            }{" "}
                            <small>
                              <em>always keep this number safe.</em>
                            </small>
                          </strong>
                        </p>
                        <input
                          id="merchantId"
                          name="merchantId"
                          value={this.state.merchantId}
                          type="hidden"
                        />
                        <input
                          id="rrr"
                          name="rrr"
                          value={this.state.rrr}
                          type="hidden"
                        />
                        <input
                          id="responseurl"
                          name="responseurl"
                          value={this.state.response_url}
                          type="hidden"
                        />
                        <input
                          id="hash"
                          name="hash"
                          value={this.state.hash}
                          type="hidden"
                        />
                        {/* <div className="form-group">
                            <label htmlFor="comment">
                              Comment <span className="text-danger">*</span>{" "}
                            </label>
                            <textarea
                              className={classnames({
                                "is-invalid": this.hasErrorFor("comment"),
                                "form-control": true
                              })}
                              id="comment"
                              name="comment"
                              rows={2}
                              onChange={this.handleChange}
                              value={this.state.comment}
                            />

                            {this.renderErrorFor("comment")}
                          </div> */}

                        <button
                          // type="submit"
                          className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                        >
                          Pay
                        </button>
                        <button
                          className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                          onClick={(e) => {
                            e.preventDefault();
                            this.props.cancelRemita(this.state.rrr);
                          }}
                          disabled={spinner2 ? true : false}
                        >
                          Cancel
                          {spinner2 ? <MiniSpinner /> : null}
                        </button>
                      </div>
                    </form>
                  </div>

                  {/* /.card-body */}
                </div>
              ) : null}

              {this.state.modal_1 ? (
                <div
                  //   className="card opp"
                  //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    zIndex: 9999999,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    // padding: "2%",
                    display: "flex",
                    justifyContent: "center",
                    overflowX: "hidden",

                    alignItems: "center",

                    // transition: "all 10s ease"
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: "#fff",
                      height: "300px",
                      overflowX: "hidden",
                    }}
                  >
                    <form>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <h4
                        // className="text-center mt-3"
                        >
                          {this.state.info}{" "}
                        </h4>
                        <i
                          style={{
                            fontSize: "1.6rem",
                            color: "#bda230",
                          }}
                          className={classnames({
                            "fas fa-check": this.state.message === "Approved",
                            "fas fa-exclamation-triangle":
                              this.state.message !== "Approved",
                          })}
                        ></i>
                      </div>

                      <hr />
                      <div className="col-sm-6 offset-sm-3 ">
                        <p>
                          <strong>
                            {" "}
                            Remita Retrieval Reference (rrr): {
                              this.state.rrr
                            }{" "}
                            <small>
                              <em>always keep this number safe.</em>
                            </small>
                          </strong>
                        </p>
                        <p>
                          <strong>
                            {" "}
                            Order ID : {this.state.orderId}{" "}
                            <small>
                              <em>always keep this number safe.</em>
                            </small>
                          </strong>
                        </p>
                        <button
                          className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                          disabled={spinner ? true : false}
                          onClick={this.closeModal_1}
                        >
                          Close
                        </button>
                      </div>
                    </form>
                    {/* <hr /> */}
                    {/* <div className="text-center">
                   
                  </div> */}
                  </div>

                  {/* /.card-body */}
                </div>
              ) : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              {spinner ? (
                <Spinner position="right" />
              ) : (
                <div className="row">
                  {this.state.cart.length > 0 ? (
                    <React.Fragment>
                      <CartColumns />
                      <CartList
                        cart={this.state.cart}
                        increment={this.increment}
                        decrement={this.decrement}
                        removeItem={this.removeItem}
                        clearCart={this.clearCart}
                      />
                      <CartTotals
                        cartSubTotal={this.state.cartSubTotal}
                        cartTax={this.state.cartTax}
                        cartTotal={this.state.cartTotal}
                        clearCart={this.clearCart}
                        checkOut={this.checkOut}
                        history={this.props.history}
                      />
                    </React.Fragment>
                  ) : (
                    <EmptyCart />
                  )}
                </div>
              )}
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.tstore.spinner,
    spinner2: state.users.spinner,
    message: state.tstore.message,
    status: state.tstore.status,
    errors: state.tstore.errors,
    cart: state.tstore.cart,
    cartSubTotal: state.tstore.cartSubTotal,
    cartTax: state.tstore.cartTax,
    cartTotal: state.tstore.cartTotal,
    merchantId: state.tstore.merchantId,
    rrr: state.tstore.rrr,
    response_url: state.tstore.response_url,
    action_url: state.tstore.action_url,
    hash: state.tstore.hash,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    increment: (id) => dispatch(increment(id)),
    decrement: (id) => dispatch(decrement(id)),
    removeItem: (id) => dispatch(removeItem(id)),
    clearCart: () => dispatch(clearCart()),
    checkOut: () => dispatch(checkOut()),
    showSuccessMessage: () => dispatch(showSuccessMessage()),
    force_cart_total: () => dispatch(force_cart_total()),
    cancelRemita: (creds) => cancelRemita(dispatch, creds),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
