import React, { useLayoutEffect, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import classnames from "classnames";
import { useShallowEqualSelector } from "../../../../hooks";
import { useGetCampScriptureFund } from "../../../../store/hookactions/notificationsActionhooks";
import MiniSpinner from "../../../helpers/MiniSpinner";
import Message from "../../../helpers/Message";
import Spinner from "../../../helpers/Spinner";
import $ from "jquery";
import {
  // submitCampMessage,
  resetNotificationState,
} from "../../../../store/actions/notificationActions";
import { useDispatch } from "react-redux";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";

function ViewSetScriptureGoalHQ(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [enabled, setEnabled] = useState(false);
  const [cars_Info, setCarInfo] = useState();
  const [gid_year, setGidYear] = useState(null);
  const [previous_year, setPrevious] = useState("");
  const [current_year, setCurrent] = useState("");

  //   console.log(cars_Info);
  const errors = useShallowEqualSelector((state) => state.notifications.errors);

  useGetCampScriptureFund(enabled, setEnabled, cars_Info, "camp", gid_year);

  const message = useShallowEqualSelector(
    (state) => state.notifications.message
  );
  const status = useShallowEqualSelector((state) => state.notifications.status);
  const camps = useShallowEqualSelector((state) => state.preferences.camps);

  const spinner = useShallowEqualSelector(
    (state) => state.notifications.spinner
  );
  const spinner_pref = useShallowEqualSelector(
    (state) => state.preferences.spinner
  );
  const camp_goals_view = useShallowEqualSelector(
    (state) => state.notifications.camp_goals_view
  );

  function handleSubmit(e) {
    e.preventDefault();
    const gideon_year = $("#only_year").val();
    const id = $(".memberselect22").val();

    setCarInfo(id);
    setGidYear(gideon_year);
    setEnabled(true);
  }
  function initDependency() {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  }

  function hasErrorFor(field) {
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  function renderErrorFor(field) {
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  useLayoutEffect(() => {
    initDependency();
  }, [camp_goals_view]);

  useEffect(() => {
    if (
      !hasPermission({
        mod: "Goals",
        action: "VIEW_CAMP_GOAL_REPORT_HQ",
      })
    ) {
      dispatch(setPermissionsErrors());
      history.push("/");
    }
    initDependency();

    return () => {
      resetNotificationState(dispatch);
      dispatch({ type: "CLEAR_USERS_ERRORS" });
    };
  }, [dispatch, history]);

  //   useEffect(() => {
  //     if (cars_Info && Object.keys(cars_Info).length) {
  //       setEnabled(true);
  //     }
  //   }, [cars_Info]);

  useEffect(() => {
    if (camp_goals_view.length) {
      const year_previous = camp_goals_view[0]?.total?.previous.year;
      const year_current = camp_goals_view[0]?.total?.current.year;

      setPrevious(year_previous);
      setCurrent(year_current);

      setEnabled(true);
    }
  }, [camp_goals_view]);

  return (
    <div className="content-wrapper">
      {/* Content Header (Page header) */}
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Goals</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Dashboard</Link>
                </li>
                <li className="breadcrumb-item active">
                  View Scripture Fund HQ
                </li>
              </ol>
            </div>
          </div>
        </div>
        {/* /.container-fluid */}
      </section>
      {/* Main content */}
      <section className="content">
        {/* Default box */}
        <div className="card">
          <div className="card-header">
            <h3 className="card-title font-weight-bold ">
              View Scripture Fund HQ{" "}
              {/* <span className="lead">
                Camp: <i>{cars_Info?.name}</i>
              </span> */}
            </h3>
            <div className="card-tools"></div>
          </div>
          <div className="card-body">
            {spinner ? <Spinner position="right" /> : null}
            {status && message ? (
              <Message status={status} message={message} />
            ) : null}
            <form onSubmit={handleSubmit}>
              <div className="row  ">
                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="from_date">Gideon Year</label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": hasErrorFor("gideon_year"),
                        "form-control": true,
                      })}
                      id="only_year"
                      name="gideon_year"
                      readOnly
                      placeholder="Select date"
                    />
                    {renderErrorFor("gideon_year")}
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group">
                    <label htmlFor="camp_id">
                      Camp <span className="text-danger">*</span>{" "}
                    </label>
                    <div className="d-flex">
                      <select
                        className={classnames({
                          "is-invalid": hasErrorFor("camp_id"),
                          "form-control": true,
                          memberselect22: true,
                        })}
                        placeholder="Select a Camp"
                        id="camp_id"
                        name="camp_id"
                        style={{
                          width: "100%",
                        }}
                      >
                        <option value="">--Select a camp--</option>
                        <option value="all">ALL</option>
                        {camps &&
                          camps.map((camp, index) => (
                            <option key={camp.id} value={camp.id}>
                              {camp.name} -- {camp.area.name} --{" "}
                              {camp.area.region.name} --{" "}
                              {camp.area.region.state.name}
                            </option>
                          ))}
                      </select>
                      {spinner_pref ? <MiniSpinner /> : null}
                    </div>
                    {renderErrorFor("camp_id")}
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  disabled={spinner ? true : false}
                >
                  Submit
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>
              </div>
            </form>
            <br />

            {!spinner && camp_goals_view.length ? (
              <div>
                <table
                  id="example"
                  className="table table-bordered"
                  style={{
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>CAMP</th>
                      <th>CAMP NO.</th>
                      <th>AREA</th>
                      <th>REGION</th>
                      <th>STATE</th>
                      <th>CHURCH RECEIPTS</th>
                      <th>CHURCH RECEIPTS</th>
                      <th>GIDEON CARD</th>
                      <th>GIDEON CARD</th>
                      <th>AUXILIARY</th>
                      <th>AUXILIARY</th>
                      <th>OTHERS</th>
                      <th>OTHERS</th>
                      <th>TOTAL</th>
                      <th>TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th>({previous_year})₦</th>
                        <th>({current_year})₦</th>
                        <th>({previous_year})₦</th>

                        <th>({current_year})₦</th>
                        <th>({previous_year})₦</th>
                        <th>({current_year})₦</th>
                        <th>({previous_year})₦</th>
                        <th>({current_year})₦</th>
                        <th>({previous_year})₦</th>
                        <th>({current_year})₦</th>
                      </tr>
                    }

                    {camp_goals_view?.map((item, index) => {
                      return (
                        <>
                          <tr key={item.camp_name}>
                            <th>{item.camp_name}</th>
                            <th>{item.camp_no}</th>
                            <th>{item?.area_name}</th>
                            <th>{item?.region_name}</th>
                            <th>{item?.state_name}</th>
                            <td>
                              {item.church_receipt.previous.total || "--"}
                            </td>
                            <td>{item.church_receipt.current.total || "--"}</td>
                            <td>
                              {item.gideon_receipt.previous.total || "--"}
                            </td>
                            <td>{item.gideon_receipt.current.total || "--"}</td>
                            <td>
                              {item.auxiliary_receipt.previous.total || "--"}
                            </td>
                            <td>
                              {item.auxiliary_receipt.current.total || "--"}
                            </td>
                            <td>
                              {item.other_scripture_fund_goals.previous.total ||
                                "--"}
                            </td>
                            <td>
                              {item.other_scripture_fund_goals.current.total ||
                                "--"}
                            </td>
                            <td>{item.total.previous.total || "--"}</td>
                            <td>{item.total.current.total || "--"}</td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
                {/* {initDependency()} */}
              </div>
            ) : null}
          </div>

          {/* /.card-body */}
        </div>
        {/* /.card */}
      </section>
      {/* /.content */}
    </div>
  );
}

export default ViewSetScriptureGoalHQ;
