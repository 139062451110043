
import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import PropTypes from 'prop-types';
import {
  getStaffDepot,
  deallocateDepot,
  getLoggedInStaffDepot
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import $ from "jquery";
import axios from "axios";
import {
  BASE_URL,
  token,
  storage_type,
  user_auth_details,
  dehash,
  UserIdList,
  depotrowlist
} from "../../../../config";
import { render } from "react-dom";
import ViewAllStaffs from "../../personal/ViewAllStaffs";
import { lighten,withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import TablePagination from '@material-ui/core/TablePagination';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.gray,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

const columns = [
  { field: "id", headerName: "ID", width: 70 },
  { field: "name", headerName: "depotname", width: 130 },
  { field: "address", headerName: "Address", width: 130 },
  { field: "created_at", headerName: "Date Created", type: "date", width: 90 }
];

var rowsToKeep = [];
var rowsToBeDeleted = [];

// const isSelected = (name) => selected.indexOf(name) !== -1;

// const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          Assigned Depot(s)
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort} = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const headCells = [
  { id: 'name', numeric: false, disablePadding: true, label: 'Depot Name' },
  { id: 'address', numeric: false, disablePadding: false, label: 'Depot Address' },
  { id: 'datecreated', numeric: false, disablePadding: false, label: 'Date Created' },
  { id: 'dateAssigned', numeric: true, disablePadding: false, label: 'Date Assigned' },
  // { id: 'protein', numeric: true, disablePadding: false, label: 'Protein (g)' },
];


//---------------COMPONENT-----------------
function EnhancedTable(rows) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  //populateStaffRecord();

  console.log("Rows: ", rows);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = [rows].map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {(rows.length > 0)? (stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.name)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.name}
                          selected={isItemSelected}
                        >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row" padding="none">
                            {row.name}
                          </TableCell>
                          <TableCell align="right">{row.address}</TableCell>
                          <TableCell align="right">{row.created_at}</TableCell>
                          <TableCell align="right">{row.updated_at}</TableCell>
                          {/* <TableCell align="right">{row.protein}</TableCell> */}
                        </TableRow>
                      );
                     })) : (stableSort(window.$Depots, getComparator(order, orderBy))
                     .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                     .map((row, index) => {
                         const isItemSelected = isSelected(row.name);
                         const labelId = `enhanced-table-checkbox-${index}`;
                         return (
                             <TableRow
                               hover
                               onClick={(event) => handleClick(event, row.name)}
                               role="checkbox"
                               aria-checked={isItemSelected}
                               tabIndex={-1}
                               key={row.name}
                               selected={isItemSelected}
                             >
                             <TableCell padding="checkbox">
                               <Checkbox
                                 checked={isItemSelected}
                                 inputProps={{ 'aria-labelledby': labelId }}
                               />
                               </TableCell>
                               <TableCell component="th" id={labelId} scope="row" padding="none">
                                 {row.name}
                               </TableCell>
                               <TableCell align="right">{row.address}</TableCell>
                               <TableCell align="right">{row.created_at}</TableCell>
                               <TableCell align="right">{row.updated_at}</TableCell>
                               {/* <TableCell align="right">{row.protein}</TableCell> */}
                             </TableRow>
                           );
                          }))}
                    {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                  />
                </Paper>
                <FormControlLabel
                  control={<Switch checked={dense} onChange={handleChangeDense} />}
                  label="Dense padding"
                />
              </div>
  );
}

//------------------END COMPONENT------------------------

class RemoveDepot extends Component {
  //const classes = useStyles();
  state = {
    depots: [],
    staff_id: "",
    staffs: [],
    depot_id: "",
    data: '',
    depotRowLst: [],
    selectedDepots: []
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "ASSIGN_DEPOT" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      //this.props.getStaffDepot().then(res => {
        //Default to the person that logged in

        this.props.getStaffDepot().then(response => {
          //console.log("Result: ", res.depots.payload.LoggedInStaffDepots[0].depots);
          if (
            response.staffs.status === "success" &&
            response.depots.status === "success"
          ) {
            this.setState({
              // depots: res.depots.payload.depots,
               staffs: response.staffs.payload.staffs,
            });
            window.$Depots = response.depots.payload.depots; //res.depots.payload.depots;
          }
        });
        this.props.getLoggedInStaffDepot().then(res => {
        //console.log("Result: ", res.depots.payload.LoggedInStaffDepots[0].depots);
        if (
          res.staffs.status === "success" &&
          res.depots.status === "success"
        ) {
          this.setState({
            // depots: res.depots.payload.depots,
             //staffs: res.staffs.payload.staffs,

            //If we want the depots of loggedin user
            //----------------------------------------------
            depots: res.depots.payload.LoggedInStaffDepots[0].depots
            //staffs: res.staffs.payload.LoggedInStaffDepots
            //---------------------------------------------------
          });
          window.$Depots = res.depots.payload.LoggedInStaffDepots[0].depots; //res.depots.payload.depots;
        }
      });
      let source='FIRST';
      depotrowlist.push({name:source});
      var listToDelete = [""];
      for (var i = 0; i < depotrowlist.length; i++) {
          var obj = depotrowlist[i];
          if (listToDelete.indexOf(obj.name) !== -1) {
              depotrowlist.splice(i, 1);
          }
      }
      console.log("FIRST value: ", depotrowlist);
    }
  }
  
  //const [rows, setRows] = useState(data);
  //const [deletedRows, setDeletedRows] = useState([]);

  // state = {
  //   data: data
  // };

  /*
   * It's assumed that the user will want to delete all the rows,
   * but there will be scenarios when that's not the case.
   */
  setRowsToBeDeleted = () => {
    for (var i = 0; i < this.state.depots.length; i++) {
      rowsToBeDeleted.push(this.state.depots[i].id);
    }
    rowsToBeDeleted = [...new Set(rowsToBeDeleted)]; //Did this because clicking the button twice will make doubles appear for each row
  };

  /*
   * This method fires off when the checkbox is clicked for a given row.
   */
  handleRowSelection = (e) => {
    // remove it if it's already present - this means the user unchecked it
    if (rowsToKeep.includes(e.data.id)) {
      for (var i = 0; i < rowsToKeep.length; i++) {
        if (rowsToKeep[i] === e.data.id) {
          rowsToKeep.splice(i, 1);
        }
      }
    } else {
      // user clicked it - add it to the list of rows to keep.
      rowsToKeep.push(e.data.id);
    }

    this.setRowsToBeDeleted();
    console.log("Rows to Keep: " + rowsToKeep);
    //setDeletedRows([...deletedRows, ...rows.filter((r) => r.id === e.data.id)]);
    //console.log("All rows: " + rows);
  };

  
  /*
   * This method updates the data that's to be displayed.
   */
  handlePurge = () => {
    // Check to see what rows are to be deleted and which ones aren't.
    for (var j = 0; j < rowsToKeep.length; j++) {
      if (rowsToBeDeleted.includes(rowsToKeep[j])) {
        // delete it from 'rows to be deleted' array
        console.log("Found:" + rowsToKeep[j]);
        while (rowsToBeDeleted.indexOf(rowsToKeep[j]) !== -1) {
          rowsToBeDeleted.splice(rowsToBeDeleted.indexOf(rowsToKeep[j]), 1);
        }
      } else {
        // do nothing
      }
    }

    let data_to_be_kept = this.state.depots.filter(function (item) {
      return rowsToBeDeleted.includes(item.id) === false;
    });

    console.log("Rows to Delete: " + rowsToBeDeleted);
    console.log("Rows to be kept", data_to_be_kept);

    this.setState({
      depots: data_to_be_kept
    });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { deallocateDepot } = this.props;
    const creds = {
      depotsLeft: this.state.Depots,
      user_id: this.state.staff_id
    };
    deallocateDepot(creds).then(res => {
      if (res.status === "success") {
        this.setState({
          Depots: "",
          staff_id: ""
        });
        $(".selected_state").val("");
      }
    });
  };
  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleStaffChange = e => {
    this.setState({ 
      staff_id: e.target.value 
    });
    const staffId = e.target.value;
    axios.get(`${BASE_URL}/staff-depots?id=${staffId}`,
        {method:'GET',
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage_type.getItem(token)}`},
        }, 
        ).then(response => {
          console.log("Selected Depots: ", response.data.payload.staffDepots[0].depots);
          let source='SECOND';
          depotrowlist.push({name:source});
          var listToDelete = [""];
          for (var i = 0; i < depotrowlist.length; i++) {
            var obj = depotrowlist[i];
            if (listToDelete.indexOf(obj.name) !== -1) {
              depotrowlist.splice(i, 1);
            }
          }
          this.setState({  
            //Users: response.data.payload.staffDepots,
            selectedDepots: response.data.payload.staffDepots[0].depots
            //rows:response.data.payload.staffDepots[0].depots
          });
          console.log("Depot Row List: ", this.state.depots);
        });
        this.setState({
          depots:this.state.selectedDepots
        });
        console.log("New depots: ", this.state.depots);
  };

  handleBack = e => {
    // console.log(this.props);
    this.props.history.goBack();
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    console.log("Depots: ", this.state.depots);
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Deallocate Depot</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">Deallocate Depot</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                <label>
                      Staffs <span className="text-danger">*</span>
                    </label>
                  <div className="form-group col-md-6">
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("user_id"),
                        "form-control selected_state": true
                      })}
                      placeholder="Select"
                      onChange={this.handleStaffChange}
                      id="staff_id"
                      value={this.state.staff_id}
                    >
                      <option value="">--Select--</option>
                      {this.state.staffs &&
                        this.state.staffs.map((staff, index) => (
                          <option key={index} value={staff.id}>
                            {staff.first_name} {staff.last_name}{" "}
                            {staff.other_name}
                          </option>
                        ))}
                    </select>
                    {this.renderErrorFor("user_id")}
                  </div>
                </div>
                {depotrowlist.name==='FIRST'?<EnhancedTable rows={this.state.depots} /> : <EnhancedTable rows={this.state.selectedDepots} />}
                <div className="text-center">
                {/* <Button
                  variant="contained"
                  color="primary"
                  startIcon={<DeleteIcon />}
                  style={{ textTransform: "none" }}
                  onClick={this.handlePurge}
                >
                  Purge Records
                </Button>{" "} */}
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold  mx-auto"
                    disabled={spinner ? true : false}
                  >
                    Deallocate
                  </button>{" "}
                  <Link
                    to={"/preferences/depots"}
                    className="btn btn-default btn-sm text-uppercase font-weight-bold ml-1"
                  >
                    View Depot
                  </Link>
                </div>
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    errors: state.preferences.errors
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getStaffDepot: () => getStaffDepot(dispatch),
    getLoggedInStaffDepot: () => getLoggedInStaffDepot(dispatch),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    deallocateDepot: creds => deallocateDepot(dispatch, creds)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(RemoveDepot);
