import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import { connect } from "react-redux";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import MiniSpinner from "../../../helpers/MiniSpinner";
import {
  getAllCamps,
  AuthorizeTransferRequest,
  oneRole
} from "../../../../store/actions/preferencesActions";
import { getAllMembers_2 } from "../../../../store/actions/usersActions";
import $ from "jquery";

class ShowSingleTransferDetails extends Component {
  state = {
    camps: null,
    members: null,
    selectedCamp: "",
    membersArry: null,
    formerMembers: null
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllCamps, getAllMembers_2 } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({
        mod: "Preferences",
        action: "AUTHORISE_MEMBER_TRANSFER"
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const id = this.props.match.params.id;
      getAllCamps();
      getAllMembers_2(id).then(res => {
        if (
          res.members.status === "success" &&
          res.details.status === "success"
        ) {
          this.setState(
            {
              members: res.members.payload.members
            },
            () => {
              const transfer = res.details.payload.transfers;
              const val_arry = [];
              transfer.users.forEach(item => {
                val_arry.push(item.id);
              });
              $(".memberselect2").val(val_arry);
              this.initDependency();
              this.setState({
                id: transfer.id,
                current_camp: transfer.old_camp.name,
                selectedCamp: transfer.new_camp.id
              });
            }
          );
        }
        // console.log(res);
      });
    }
  }

  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      // nextProps.members !== prevState.members ||
      nextProps.camps !== prevState.camps
    ) {
      return {
        camps: nextProps.camps
        // members: nextProps.members
      };
    }
    return null;
  }
  handleSubmit = e => {
    e.preventDefault();
    if (window.confirm("Are you sure you want to authorize this request?")) {
      const members = Array.from($(".memberselect2").val());
      const camp_id = this.state.selectedCamp;
      const id = this.props.match.params.id;
      this.props
        .AuthorizeTransferRequest({ members, camp_id, id })
        .then(res => {
          if (res.status === "success") {
            setTimeout(() => {
              this.props.history.push("/preferences/member-transfer/pending");
            }, 1500);
          }
        });
    }
  };

  handleSelectRole = e => {
    this.setState({ selectedCamp: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  render() {
    const { spinner, message, status, memberSpinner } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {" "}
                    Members Transfer Request
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Members Transfer Request
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? <Spinner /> : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>Current Camp</label>
                    <input
                      type="text"
                      className="form-control"
                      defaultValue={this.state.current_camp}
                      readOnly
                    />
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Requested Camp <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control selected_role"
                      placeholder="Select Camp"
                      onChange={this.handleSelectRole}
                      value={this.state.selectedCamp}
                    >
                      <option>--Select Camp--</option>
                      {this.state.camps &&
                        this.state.camps.map((camp, index) => (
                          <option key={index} value={camp.id}>
                            {camp.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6 ">
                    <label>
                      Member(s) <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <select
                        className="memberselect2"
                        multiple="multiple"
                        data-placeholder="Select Members"
                        style={{ width: "100%" }}
                      >
                        {this.state.members &&
                          this.state.members.map((member, index) => (
                            <option
                              key={index}
                              value={member.id}
                              //   selected={true}
                            >
                              {member.first_name} {member.last_name}{" "}
                              {member.other_name}
                            </option>
                          ))}
                      </select>
                      {memberSpinner ? <MiniSpinner /> : null}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold mt-2"
                  disabled={spinner ? true : false}
                >
                  Authorize
                </button>
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    camps: state.preferences.camps,
    message: state.preferences.message,
    spinner: state.preferences.spinner,
    errors: state.preferences.errors,
    status: state.preferences.status,
    memberSpinner: state.users.spinner
    // members: state.users.members
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllMembers_2: id => getAllMembers_2(dispatch, id),
    getAllCamps: () => dispatch(getAllCamps()),
    AuthorizeTransferRequest: creds =>
      AuthorizeTransferRequest(dispatch, creds),
    // showSingleRequest: id => showSingleRequest(dispatch, id),
    oneRole: id => oneRole(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShowSingleTransferDetails);
