import React from "react";
import { image_path } from "../../../../config";

export default function CartItem({ item, increment, decrement, removeItem }) {
  const { id, title, img, price, total, count } = item;

  const moneyConv = price => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  return (
    <div className="row my-2 text-capitalize text-center">
      <div className="col-10 mx-auto col-lg-2">
        <img
          src={`${image_path}${img}`}
          style={{
            width: "4rem",
            height: "4rem",
            position: "relative",
            top: "-15px"
          }}
          className="img-fluid"
          alt="product"
        />
      </div>
      <div className="col-10 mx-auto col-lg-2">
        <span className="d-lg-none">product:</span>
        {title}
      </div>
      <div className="col-10 mx-auto col-lg-2">
        <span className="d-lg-none">price :</span>
        {moneyConv(price)}
      </div>
      <div className="col-10 mx-auto col-lg-2 my-2 my-lg-0">
        <div className="d-flex justify-content-center">
          <div>
            <span
              className="btn btn-default btn-sm mx-1"
              onClick={() => decrement(id)}
            >
              {" "}
              -
            </span>
            <span className="btn btn-black mx-1">{count}</span>
            <span
              className="btn btn-default btn-sm mx-1"
              onClick={() => increment(id)}
            >
              {" "}
              +
            </span>
          </div>
        </div>
      </div>
      <div className="col-10 mx-auto col-lg-2">
        <div className="cart-icon" onClick={() => removeItem(id)}>
          <i className="fas fa-trash" />
        </div>
      </div>
      <div className="col-10 mx-auto col-lg-2">
        <strong>item total: &#8358; {moneyConv(total)}</strong>
      </div>
    </div>
  );
}
