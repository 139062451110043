import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getOneDepotInventory,
  //   acknowlegdeKits
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import Pagination from "react-js-pagination";
import { storage_type, user_auth_details, dehash, SelectedDepot } from "../../../../config";
import axios from "axios";
import {
  BASE_URL,
  token,
} from "../../../../config";


class DetailDepotIventory extends Component {
  state = {
    activePage: 1,
    depot: ''
  };
  componentDidMount() {
    const { setPermissionsErrors, getOneDepotInventory } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_DEPOT_INVENTORY" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      if (storage_type.getItem(user_auth_details)) {
        const user = JSON.parse(
          dehash(`${storage_type.getItem(user_auth_details)}`)
        );
        this.setState({ depot: user.depots });
      }

      const id = this.props.match.params.id;

      //---------------------CODE ADDED BY MACDONALD------------------------

      const DepotId = window.$depotID;

      console.log("Inventory ID: ", id);
      console.log("Depot ID: ", DepotId);
      axios.get(`${BASE_URL}/get-depot4Inventory?id=${DepotId}`,
        {method:'GET',
          headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${storage_type.getItem(token)}`},
        }, 
        ).then(response => {
          this.setState({  
            depot: response.data.payload.Depot
          });  
        });

      //----------------------------------END------------------------------------------
      getOneDepotInventory(id).then((res) => {
        // console.log("here", res);
        if (res.status === "success") {
          this.setState({
            ...res.payload.details,
            ...res.payload.inventory,
            ...res.payload.inventory.bible,
          });
        }
      });
    }
  }
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };

  // async getDepotname (depotId) {
  //   const response = await fetch("http://127.0.0.1:8000/api/get-depotname?id="+depotId, {
  //     method:'GET',
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${storage_type.getItem(token)}`,
  //     },
  //   })
  //   const json = await response.json();
  //   const result_names = [];
  //   for (let i = 0, max = json.length; i < max; i += 1) {
  //       result_names.push(json[i].name);
  //   }
  //   this.setState({
  //     depotName: result_names.toString()
  //   });

  //   depotnamelist.push({name:result_names.toString()});
  //   // if( depotnamelist.some(code => code.name.val === "")) {
  //   //   depotnamelist.splice(0,1);
  //   // } // true
  //   var listToDelete = [""];
  //   for (var i = 0; i < depotnamelist.length; i++) {
  //     var obj = depotnamelist[i];

  //     if (listToDelete.indexOf(obj.name) !== -1) {
  //       depotnamelist.splice(i, 1);
  //     }
  //     this.setState({
  //       depotNameLst: depotnamelist
  //     });

  // }
  // }


  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    const { getOneDepotInventory } = this.props;
    const id = this.props.match.params.id;
    getOneDepotInventory(id, `?page=${pageNumber}`).then((res) => {
      if (res.status === "success") {
        this.setState({
          ...res.payload.details,
          ...res.payload.inventory,
          //   ...res.payload.inventory.bible
        });
      }
    });
  };

  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  render() {
    const { spinner, message, status } = this.props;
    console.log("State Depot: ", this.state.depot);
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Inventory Activities Record
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Inventory Activities Record{" "}
                {this.state.depot ? `(${this.state.depot[0].name + "-" + this.state.depot[0].address})` : null}
              </h3>
              <div className="card-tools">
                <Link to="/preferences/depot-inventory">
                  <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                    <i className="fas fa-arrow-circle-left "></i> Back
                  </button>
                </Link>
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <React.Fragment>
                  <div className="card">
                    <div className="card-body ">
                      <div className="row d-flex justify-content-between">
                        <div>
                          <p className="lead">
                            Inventory:{" "}
                            <span className="font-italic">
                              {this.state.name}
                            </span>{" "}
                          </p>
                        </div>
                        <div>
                          <p className="lead">
                            Total Qunatity:{" "}
                            <span className="font-italic">
                              {this.moneyConv(this.state.stock)}
                            </span>{" "}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div key="uniqueKey">
                    <table
                      id="state_table"
                      className="table table-hover table-bordered table-striped"
                      style={{
                        width: "100%",
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Activity Type</th>
                          <th>Quantity</th>
                          <th>Comment</th>
                          <th> by</th>
                          <th>Activity Date</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data &&
                          this.state.data.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {item.type === "A" ? "ADDITION" : "REMOVAL"}
                                </td>
                                <td>{this.moneyConv(item.value)}</td>

                                <td>{item.comment}</td>
                                <td>
                                  {item.first_name} {item.last_name}{" "}
                                  {item.other_name}
                                </td>
                                <td>{this.dateConv(item.created_at)}</td>
                                <td>{this.moneyConv(item.balance)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                    <div className="d-flex justify-content-center">
                      <Pagination
                        activePage={this.state.activePage}
                        itemsCountPerPage={this.state.per_page}
                        totalItemsCount={this.state.total}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                        itemClass="page-item"
                        linkClass="page-link"
                      />
                    </div>
                  </div>
                </React.Fragment>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
        {/* <div>
            Data from parent is:{this.props.dataFromParent}
        </div> */}
      </div>
      
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.preferences.states,
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getOneDepotInventory: (id, page) =>
      getOneDepotInventory(dispatch, id, page),
    // acknowlegdeKits: id => acknowlegdeKits(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailDepotIventory);
