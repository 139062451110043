import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  updateCountry,
  getOneCountry
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";

class UpdateCountries extends Component {
  state = {
    name: ""
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "UPDATE_COUNTRY" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const id = this.props.match.params.id;
      this.props.getOneCountry(id).then(res => {
        if (res.status === "success") {
          // console.log(res);
          this.setState({
            name: res.payload.country.name
          });
        }
      });
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const { updateCountry } = this.props;
    const creds = {
      name: this.state.name,
      id: this.props.match.params.id
    };
    // console.log(creds);
    updateCountry(creds).then(res => {
      if (res.status === "success") {
        // this.setState({
        //   name: ""
        // });
      }
    });
  };
  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Update Country</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">Update Country</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row col-md-6 mx-auto">
                  <div className="form-group col-md-12">
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("name"),
                        "form-control": true
                      })}
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("name")}
                  </div>
                  <div className="form-group col-md-12">
                    <button
                      type="submit"
                      className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                      disabled={spinner ? true : false}
                    >
                      UPDATE
                    </button>
                    {hasPermission({
                      mod: "Preferences",
                      action: "VIEW_COUNTRY"
                    }) ? (
                      <Link
                        to="/preferences/countries"
                        className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                      >
                        View State
                      </Link>
                    ) : null}
                  </div>
                </div>
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    errors: state.preferences.errors
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    updateCountry: creds => updateCountry(dispatch, creds),
    getOneCountry: id => getOneCountry(dispatch, id)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateCountries);
