import { privateRequest } from "../../requestMethods";

const submitCampMessage = async (dispatch, creds) => {
  console.log(creds);
  try {
    dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
    dispatch({ type: "START_SPINNER_NOTIFICATION" });
    const result = await privateRequest.post(
      "notification-messages/send",
      creds
    );
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    const resMessage = result.data;
    dispatch({ type: "CAMP_MESSAGE_SUBMITTED_SUCCESS", payload: resMessage });
    return resMessage;
  } catch (error) {
    const resMessage = error.response.data;
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    dispatch({
      type: "CAMP_MESSAGE_SUBMITTED_ERRORS",
      payload: resMessage,
    });
  }
};
const updateCampMessage = async (dispatch, creds) => {
  console.log(creds);
  try {
    dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
    dispatch({ type: "START_SPINNER_NOTIFICATION" });
    const result = await privateRequest.put(
      `notification-messages/${creds.id}/update`,
      creds
    );
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    const resMessage = result.data;
    dispatch({ type: "CAMP_MESSAGE_SUBMITTED_SUCCESS", payload: resMessage });
    return resMessage;
  } catch (error) {
    const resMessage = error.response.data;
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    dispatch({
      type: "CAMP_MESSAGE_SUBMITTED_ERRORS",
      payload: resMessage,
    });
  }
};
const submitAreaMessage = async (dispatch, creds) => {
  console.log(creds);
  try {
    dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
    dispatch({ type: "START_SPINNER_NOTIFICATION" });
    const result = await privateRequest.post(
      "notification-messages/send",
      creds
    );
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    const resMessage = result.data;
    dispatch({ type: "CAMP_MESSAGE_SUBMITTED_SUCCESS", payload: resMessage });
    return resMessage;
  } catch (error) {
    const resMessage = error.response.data;
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    dispatch({
      type: "CAMP_MESSAGE_SUBMITTED_ERRORS",
      payload: resMessage,
    });
  }
};
const submitRegionMessage = async (dispatch, creds) => {
  // console.log(creds);
  try {
    dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
    dispatch({ type: "START_SPINNER_NOTIFICATION" });
    const result = await privateRequest.post(
      "notification-messages/send",
      creds
    );
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    const resMessage = result.data;
    dispatch({ type: "CAMP_MESSAGE_SUBMITTED_SUCCESS", payload: resMessage });
    return resMessage;
  } catch (error) {
    const resMessage = error.response.data;
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    dispatch({
      type: "CAMP_MESSAGE_SUBMITTED_ERRORS",
      payload: resMessage,
    });
  }
};
const submitStateMessage = async (dispatch, creds) => {
  // console.log(creds);
  try {
    dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
    dispatch({ type: "START_SPINNER_NOTIFICATION" });
    const result = await privateRequest.post(
      "notification-messages/send",
      creds
    );
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    const resMessage = result.data;
    dispatch({ type: "CAMP_MESSAGE_SUBMITTED_SUCCESS", payload: resMessage });
    return resMessage;
  } catch (error) {
    const resMessage = error.response.data;
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    dispatch({
      type: "CAMP_MESSAGE_SUBMITTED_ERRORS",
      payload: resMessage,
    });
  }
};
const submitNationalMessage = async (dispatch, creds) => {
  // console.log(creds);
  try {
    dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
    dispatch({ type: "START_SPINNER_NOTIFICATION" });
    const result = await privateRequest.post(
      "notification-messages/send",
      creds
    );
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    const resMessage = result.data;
    dispatch({ type: "CAMP_MESSAGE_SUBMITTED_SUCCESS", payload: resMessage });
    return resMessage;
  } catch (error) {
    const resMessage = error.response.data;
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    dispatch({
      type: "CAMP_MESSAGE_SUBMITTED_ERRORS",
      payload: resMessage,
    });
  }
};
const resetNotificationState = (dispatch) => {
  dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
};

//  Goals notifications

const submitScriptureFund = async (dispatch, creds) => {
  console.log(creds);
  try {
    dispatch({ type: "CLEAR_NOTIFICATIONS_ERRORS" });
    dispatch({ type: "START_SPINNER_NOTIFICATION" });
    const result = await privateRequest.post("goals/set-goal", creds);
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    const resMessage = result.data;
    dispatch({ type: "SCRIPTURE_GOALS_SUCCESS", payload: resMessage });
    return resMessage;
  } catch (error) {
    const resMessage = error.response.data;
    dispatch({ type: "STOP_SPINNER_NOTIFICATION" });
    dispatch({
      type: "SCRIPTURE_GOALS_ERRORS",
      payload: resMessage,
    });
  }
};

export {
  submitCampMessage,
  resetNotificationState,
  submitAreaMessage,
  submitRegionMessage,
  submitStateMessage,
  submitNationalMessage,
  updateCampMessage,
  submitScriptureFund,
};
