import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCompletedCampActivities,
  resetUsersState,
  cancelCampActivity,
} from "./../../../../store/actions/usersActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";

import Pagination from "react-js-pagination";

class CompletedActivity extends Component {
  state = {
    activePage: 1,
  };
  componentDidMount() {
    const { setPermissionsErrors, getCompletedCampActivities } = this.props;
    if (
      !hasPermission({
        mod: "Camp Administration",
        action: "VIEW_CAMP_ADMINISTRATION",
      }) ||
      !hasPermission({
        mod: "Camp Administration",
        action: "VIEW_CAMP_ACTIVITY",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      let years_arry = [];
      const start_year = 2020;
      const end_year = new Date().getFullYear() + 2;
      for (let i = start_year; i < end_year; i++) {
        years_arry.push(i);
      }
      this.setState({ years_arry });
      getCompletedCampActivities().then((res) => {
        if (res.status === "success") {
          //   console.log(res.payload);
          this.setState({ ...res.payload.activities });
        }
      });
    }
  }
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };
  cancelActivity = (e, id) => {
    e.preventDefault();
    if (window.confirm("Are you sure you cancel this camp activity?")) {
      this.setState({ modal_2: true, selected_id: id });
    }
  };
  closeModal_2 = (e) => {
    this.setState({ modal_2: false, reason: "" });
    this.props.resetUsersState();
  };
  cancelSearch = () => {
    this.setState(
      {
        year_pag: "",
        month_pag: "",
        day_pag: "",
      },
      () => {
        this.handleSearchChange();
      }
    );
  };
  handleSearchChange = () => {
    const pageNumber = 1;
    this.handlePageChange(pageNumber);
  };
  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    const { getCompletedCampActivities } = this.props;
    const year = this.state.year_pag;
    const month = this.state.month_pag;
    const day = this.state.day_pag;
    getCompletedCampActivities(pageNumber, year, month, day).then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.activities });
      }
    });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const creds = {
      id: this.state.selected_id,
      reason: this.state.reason,
    };
    this.props.cancelCampActivity(creds).then((res) => {
      if (res.status === "success") {
        const tempdata = [...this.state.data];
        this.setState({
          data: tempdata.filter((data) => data.id !== creds.id),
          modal_2: false,
          selected_id: null,
          reason: "",
        });
      }
    });
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleSearch = (e) => {
    e.preventDefault();
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  render() {
    const { spinner, message, status } = this.props;

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>CAMP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Completed Camp Activities
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                {" "}
                Completed Activities (Scripture Distribution)
              </h3>
              <div className="card-tools">
                {hasPermission({
                  mod: "Camp Administration",
                  action: "CREATE_CAMP_ACTIVITY",
                }) ? (
                  <Link to="/camp/activities/new">
                    <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                      <i className="fas fa-plus-circle mr-1"></i>
                      CREATE CAMP ACTIVITY
                    </button>
                  </Link>
                ) : null}
              </div>
            </div>
            <div
              className="card-body"
              style={{
                // width: "100%"
                overflowX: "scroll",
              }}
            >
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <div className="form-group col-md-3 d-flex">
                    <select
                      id="year_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.year_pag || ""}
                    >
                      <option value="">Year</option>
                      {this.state.years_arry &&
                        this.state.years_arry.map((year) => (
                          <option key={year} value={year}>
                            {" "}
                            {year}
                          </option>
                        ))}
                    </select>
                    <select
                      id="month_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.month_pag || ""}
                    >
                      <option value="">Month</option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                    <select
                      id="day_pag"
                      onChange={this.handleSearch}
                      defaultValue={this.state.day_pag || ""}
                    >
                      <option value="">Day</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                      <option value="4">4</option>
                      <option value="5">5</option>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8"> 8</option>
                      <option value="9"> 9</option>
                      <option value="10"> 10</option>
                      <option value="11"> 11</option>
                      <option value="12"> 12</option>
                      <option value="13"> 13</option>
                      <option value="14"> 14</option>
                      <option value="15"> 15</option>
                      <option value="16"> 16</option>
                      <option value="17"> 17</option>
                      <option value="18"> 18</option>
                      <option value="19"> 19</option>
                      <option value="20"> 20</option>
                      <option value="21"> 21</option>
                      <option value="22"> 22</option>
                      <option value="23"> 23</option>
                      <option value="24"> 24</option>
                      <option value="25"> 25</option>
                      <option value="26"> 26</option>
                      <option value="27"> 27</option>
                      <option value="28"> 28</option>
                      <option value="29"> 29</option>
                      <option value="30"> 30</option>
                      <option value="31"> 31</option>
                    </select>
                    <button onClick={this.handleSearchChange}>
                      <i className="fa fa-search text-primary"></i>
                    </button>
                    <button onClick={this.cancelSearch}>
                      <i className="fa fa-times text-danger"></i>
                    </button>
                  </div>
                  <div className="table responsive">
                    <table
                      id="state_table"
                      className="table  table-hover table-bordered table-striped"
                      style={{
                        width: "100%",
                        // overflowX:"scroll"
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Description</th>
                          <th>Target Area</th>
                          <th>Location</th>
                          <th>Created Date</th>
                          <th>Scheduled Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data &&
                          this.state.data.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.type}</td>
                                <td>{item.description}</td>
                                <td>{item.target_area}</td>
                                <td>{item.location}</td>
                                <td>{this.dateConv(item.created_at)}</td>
                                <td>{this.dateConv(item.activity_date)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.per_page}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getCompletedCampActivities: (page, year, month, day) =>
      getCompletedCampActivities(dispatch, page, year, month, day),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch),
    cancelCampActivity: (creds) => cancelCampActivity(dispatch, creds),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CompletedActivity);
