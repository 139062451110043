import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getAllFriends } from "./../../../store/actions/usersActions";
import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../store/actions/authActions";

class ViewFriends extends Component {
  state = {
    friends: null,
    init_dep: false,
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllFriends } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_FRIENDS" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllFriends();
      this.setState({ init_dep: true });
    }
  }
  initDependency = () => {
    if (this.state.init_dep) {
      const old_element = document.querySelector(
        "script[src='/js/content.js']"
      );
      const new_element = document.createElement("script");
      new_element.src = `/js/content.js`;
      new_element.async = true;
      if (old_element) {
        old_element.replaceWith(new_element);
      } else {
        document.body.appendChild(new_element);
      }
      this.setState({ init_dep: false });
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.friends !== prevState.friends) {
      return {
        friends: nextProps.friends,
      };
    }
    return null;
  }

  render() {
    const { spinner, message, status, friends } = this.props;
    if (friends?.length) {
      // this.setState({ init_dep: true });
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Membership</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">View Friends</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">View Friends</h3>
              <div className="card-tools">
                {hasPermission({
                  mod: "Preferences",
                  action: "CREATE_FRIENDS",
                }) ? (
                  <Link to="/membership/friends/create">
                    <button className="btn btn-warning btn-sm text-uppercase font-weight-bold">
                      <i className="fas fa-plus-circle mr-1"></i>
                      ADD NEW FRIEND
                    </button>
                  </Link>
                ) : null}
              </div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}

              <div key="uniqueKey">
                <table
                  id="example"
                  className="table table-hover table-bordered table-striped"
                  style={{
                    width: "100%",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Last Name</th>
                      <th>First Name</th>
                      <th>Other Name</th>
                      <th>Camp</th>
                      <th>Area</th>
                      <th>Region</th>
                      <th>State</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {friends &&
                      friends.map((friend, index) => {
                        return (
                          <tr key={friend.id}>
                            <td>{friend.last_name}</td>
                            <td>{friend.first_name}</td>
                            <td>{friend.other_name}</td>
                            <td>{friend.campname || "--"}</td>
                            <td>{friend.area || "--"}</td>
                            <td>{friend.region || "--"}</td>
                            <td>{friend.statename || "--"}</td>
                            <td>
                              <span
                                style={{
                                  overflow: "visible",
                                  position: "relative",
                                  width: "110px",
                                }}
                              >
                                <Link
                                  to={`/membership/friends/edit/${friend.id}`}
                                  className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                  title="Edit friend"
                                >
                                  <i className="fa fa-edit"></i>
                                </Link>

                                <Link
                                  to={`/membership/friends/${friend.id}`}
                                  className="btn btn-sm btn-clean btn-icon btn-icon-md"
                                  title="View More Details"
                                >
                                  <i className="fa fa-eye"></i>
                                </Link>
                              </span>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    friends: state.users.friends,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllFriends: () => dispatch(getAllFriends()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewFriends);
