import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  memberRenew,
  resetUsersState,
  cancelRemitaRenewal,
  showSuccessMessage,
  getAllMembers,
  resetUsersRenewal,
} from "../../../../store/actions/usersActions";
import { getAllSettings2 } from "./../../../../store/actions/preferencesActions";
import queryString from "query-string";

// import Spinner from "../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import MiniSpinner from "../../../helpers/MiniSpinner";
import $ from "jquery";
import {
  user_auth_details,
  dehash,
  storage_type,
  token,
} from "../../../../config";
// import "../../../../remita_inline";

// 10.10.1.227

class Renewal extends Component {
  state = {
    type: "",
    years: "1",
    channel: "",
    amount: "",
    transaction_id: "",
    evidence: "",
    paid_date: "",
    modal_2: false,
    modal_1: false,
    member_id: "",
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllMembers } = this.props;
    if (
      hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD",
      }) ||
      hasPermission({
        mod: "Personal Management",
        action: "RENEW_MEMBERSHIP",
      }) ||
      hasPermission({
        mod: "Preferences",
        action: "UPDATE_MEMBER_RENEWAL",
      })
    ) {
      /*Continue*/
    } else {
      setPermissionsErrors();
      this.props.history.push("/");
    }

    let params = queryString.parse(this.props.location.search);
    if (params.status === "success") {
      this.props.showSuccessMessage();
    }
    if (params.message && params.message === "Approved") {
      this.setState({
        ...params,
        modal_1: true,
        info: "Transaction Successful",
      });
    }
    if (params.message && params.message !== "Approved") {
      this.setState({ ...params, modal_1: true, info: "Transaction Failed" });
    }
    getAllMembers();
    this.initDependency();
    this.props.getAllSettings2().then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.settings }, () => {
          if (this.props.member_to_renew) {
            this.setState(
              {
                member_id: this.props.member_to_renew.login_id,
                type: this.props.member_to_renew.type,
                default_type: this.props.member_to_renew.type,
                user_dd: this.props.member_to_renew,
              },
              () => {
                if (this.props.member_to_renew.type === "G") {
                  // alert("here");
                  this.setState({ price: this.state.gideon_amount }, () => {
                    this.getAmount();
                  });
                } else if (this.props.member_to_renew.type === "A") {
                  this.setState({ price: this.state.aux_amount }, () => {
                    this.getAmount();
                  });
                }
                this.initDependency();
              }
            );
          } else if (storage_type.getItem(user_auth_details)) {
            const user = JSON.parse(
              dehash(`${storage_type.getItem(user_auth_details)}`)
            );
            const auth_key = storage_type.getItem(token);

            this.setState(
              {
                type: user.type,
                default_type: user.type,
                user_dd: user,
                auth_key,
              },
              () => {
                if (this.state.type === "G") {
                  // alert("here");
                  this.setState({ price: this.state.gideon_amount }, () => {
                    this.getAmount();
                  });
                } else if (this.state.type === "A") {
                  this.setState({ price: this.state.aux_amount }, () => {
                    this.getAmount();
                  });
                }
                // this.getAmount();
              }
            );
          }
        });
        // alert("here");
      }
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.members !== prevState.members) {
      return {
        members: nextProps.members,
      };
    }
    return null;
  }
  componentWillUnmount() {
    this.props.resetUsersState();
    this.props.resetUsersRenewal();
  }
  handleFileChange = (e) => {
    this.setState({
      evidence: e.target.files[0],
    });
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  handleChange = (e) => {
    let ee = e.target.name;
    let ee1 = e.target.value;

    var member_id = $("#member_id").val();
    this.setState(
      {
        [e.target.name]: e.target.value,

        //Repeatedly set state for inputs using library
        //If you do console.log, the current updated state here will not be available
        //So do not bother debugging with instant console
        //The current value is re-fetched upon form submit
        member_id: parseInt(member_id),
      },
      () => {
        if (ee === "type") {
          this.setPrice(ee1);
        }
        if (ee === "years") {
          this.getAmount();
        }
      }
    );
  };
  closeModal_1 = (e) => {
    e.preventDefault();
    this.setState({ modal_1: false });
  };
  setPrice = (type) => {
    if (type === "G") {
      this.setState({ price: this.state.gideon_amount }, () => {
        this.getAmount();
      });
    } else if (type === "A") {
      this.setState({ price: this.state.aux_amount }, () => {
        this.getAmount();
      });
    } else if (type === "C") {
      this.setState({ price: this.state.couple_amount }, () => {
        this.getAmount();
      });
    }
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  getAmount = () => {
    // alert("here2");
    const amount = this.state.price * parseInt(this.state.years);
    this.setState({ amount });
    $("#amount").val(amount);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const member_id = $("#member_id").val();
    const pay_channel = this.state.channel;
    if (pay_channel !== " " && pay_channel === "remita") {
      const paid_date = $("#evidence_date").val();
      this.setState({ paid_date, member_id }, () => {
        this.props.memberRenew(this.state).then((res) => {
          if (res.status === "success") {
            this.setState(
              {
                type: this.state.default_type === "G" ? "G" : "A",
                years: "",
                channel: "",
                amount: "",
                transaction_id: "",
                evidence: "",
                paid_date: "",
              },
              (obj = res.payload) => {
                this.setState({ ...obj }, () => {
                  this.setState({ modal_2: true });
                });
              }
            );
            $("#evidence_date").val("");
            $("#evidence").val("");
          }
        });
      });
    } else {
      if (window.confirm("Are you sure?")) {
        const paid_date = $("#evidence_date").val();
        this.setState({ paid_date, member_id }, () => {
          this.props.memberRenew(this.state).then((res) => {
            if (res.status === "success") {
              this.setState({
                type: this.state.default_type === "G" ? "G" : "A",
                years: "",
                channel: "",
                amount: "",
                transaction_id: "",
                evidence: "",
                paid_date: "",
              });
              $("#evidence_date").val("");
              $("#evidence").val("");
            }
          });
        });
      }
    }
  };
  closeModal_2 = (e) => {
    e.preventDefault();
    const rrr = this.state.rrr;
    this.props.cancelRemitaRenewal(rrr, this.state.member_id).then((res) => {
      if (res.status === "success") {
        alert("here");
        this.setState({
          modal_2: false,
          merchantId: "",
          rrr: "",
          response_url: "",
          action_url: "",
          hash: "",
        });
      }
    });
    // this.props.resetUsersState();
  };
  covMoney = (amount) => {
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };

  render() {
    const { spinner, spinner2, status, message, members, member_to_renew } =
      this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Personal</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Membership Renewal</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Membership Renewal
              </h3>
              <div className="card-tools"></div>
            </div>
            {this.state.modal_2 ? (
              <div
                //   className="card opp"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center",

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form action={this.state.action_url} method="POST">
                    <h4 className="text-center mt-3">
                      Pay &#8358; {this.covMoney(this.state.amount)} with Remita{" "}
                      <img
                        src="/dist/img/remita.png"
                        alt=""
                        width="50"
                        style={{ position: "relative", top: "-1px" }}
                      />
                    </h4>
                    <p className="text-center text-danger ">
                      <small className="font-weight-bold">
                        Please note that you may be charge proccessing fee of
                        &#8358; {this.covMoney(100)}{" "}
                      </small>
                    </p>
                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <input
                        id="merchantId"
                        name="merchantId"
                        value={this.state.merchantId}
                        type="hidden"
                      />
                      <input
                        id="rrr"
                        name="rrr"
                        value={this.state.rrr}
                        type="hidden"
                      />
                      <input
                        id="responseurl"
                        name="responseurl"
                        value={this.state.response_url}
                        type="hidden"
                      />
                      <input
                        id="hash"
                        name="hash"
                        value={this.state.hash}
                        type="hidden"
                      />
                      {/* <div className="form-group">
                            <label htmlFor="comment">
                              Comment <span className="text-danger">*</span>{" "}
                            </label>
                            <textarea
                              className={classnames({
                                "is-invalid": this.hasErrorFor("comment"),
                                "form-control": true
                              })}
                              id="comment"
                              name="comment"
                              rows={2}
                              onChange={this.handleChange}
                              value={this.state.comment}
                            />

                            {this.renderErrorFor("comment")}
                          </div> */}

                      <button
                        // type="submit"
                        className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                      >
                        Pay
                      </button>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_2}
                      >
                        Cancel
                        {spinner || spinner2 ? <MiniSpinner /> : null}
                      </button>
                    </div>
                  </form>
                </div>

                {/* /.card-body */}
              </div>
            ) : null}

            {this.state.modal_1 ? (
              <div
                //   className="card opp"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  // padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center",

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                      // className="text-center mt-3"
                      >
                        {this.state.info}{" "}
                      </h4>
                      <i
                        style={{
                          fontSize: "1.6rem",
                          color: "#bda230",
                        }}
                        className={classnames({
                          "fas fa-check": this.state.message === "Approved",
                          "fas fa-exclamation-triangle":
                            this.state.message !== "Approved",
                        })}
                      ></i>
                    </div>

                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <p>
                        <strong>
                          {" "}
                          Order ID : {this.state.orderId}{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_1}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                  {/* <hr /> */}
                  {/* <div className="text-center">
                   
                  </div> */}
                </div>

                {/* /.card-body */}
              </div>
            ) : null}
            <form onSubmit={this.handleSubmit}>
              <div className="card-body">
                {/* {spinner ? <Spinner position="right" /> : null} */}

                {status && message ? (
                  <Message status={status} message={message} />
                ) : null}

                <div className="box-body">
                  <div className="row">
                    {hasPermission({
                      mod: "Preferences",
                      action: "UPDATE_MEMBER_RENEWAL",
                    }) ? (
                      <div className="col-6">
                        {this.props.member_to_renew ? (
                          <div className="form-group">
                            <label htmlFor="member">
                              Member<span className="text-danger">*</span>
                            </label>

                            <select
                              className={classnames({
                                "is-invalid": this.hasErrorFor("member_id"),
                                "form-control": true,
                                memberselect22: true,
                              })}
                              style={{
                                width: "100%",
                              }}
                              id="member_id"
                              name="member_id"
                              onChange={this.handleChange}
                              value={this.state.member_id}
                            >
                              <option selected value={member_to_renew.login_id}>
                                {member_to_renew.first_name}{" "}
                                {member_to_renew.last_name}{" "}
                                {member_to_renew.other_name} [
                                {member_to_renew.login_id}]
                              </option>
                            </select>

                            {this.renderErrorFor("member")}
                            {this.initDependency()}
                          </div>
                        ) : (
                          <div className="form-group">
                            <label htmlFor="member">
                              Member<span className="text-danger">*</span>
                            </label>

                            <select
                              className={classnames({
                                "is-invalid": this.hasErrorFor("member_id"),
                                "form-control": true,
                                memberselect22: true,
                              })}
                              style={{
                                width: "100%",
                              }}
                              id="member_id"
                              name="member_id"
                              onChange={this.handleChange}
                              value={this.state.member_id}
                            >
                              <option value="0">-- Select Member --</option>
                              {members &&
                                members.map((member, index) => (
                                  <option key={index} value={member.login_id}>
                                    {member.first_name} {member.last_name}{" "}
                                    {member.other_name} [{member.login_id}]
                                  </option>
                                ))}
                            </select>

                            {this.renderErrorFor("member")}
                            {this.initDependency()}
                          </div>
                        )}
                      </div>
                    ) : null}
                    <div
                      className={
                        hasPermission({
                          mod: "Preferences",
                          action: "UPDATE_MEMBER_RENEWAL",
                        })
                          ? "col-sm-3 col-xs-12"
                          : "col-sm-6 col-xs-12"
                      }
                    >
                      <div className="form-group">
                        <label htmlFor="type">
                          Type<span className="text-danger">*</span>
                        </label>
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("type"),
                            "form-control": true,
                          })}
                          id="type"
                          name="type"
                          onChange={this.handleChange}
                          value={this.state.type}
                        >
                          {this.state.default_type === "G" ? (
                            <option value="G">Gideon</option>
                          ) : (
                            <option value="A">Auxilliary</option>
                          )}
                          {hasPermission({
                            mod: "Preferences",
                            action: "UPDATE_MEMBER_RENEWAL",
                          }) ? (
                            <option value="G">Gideon</option>
                          ) : null}
                          <option value="C">Gideon & Auxilliary </option>
                        </select>
                        {this.renderErrorFor("type")}
                      </div>
                    </div>

                    <div
                      className={
                        hasPermission({
                          mod: "Preferences",
                          action: "UPDATE_MEMBER_RENEWAL",
                        })
                          ? "col-sm-3 col-xs-12"
                          : "col-sm-6 col-xs-12"
                      }
                    >
                      <div className="form-group">
                        <label htmlFor="channel">
                          Payment Type<span className="text-danger">*</span>
                        </label>
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("channel"),
                            "form-control": true,
                          })}
                          id="channel"
                          name="channel"
                          onChange={this.handleChange}
                          value={this.state.channel}
                        >
                          <option value="">--Select--</option>
                          <option value="remita">Remita</option>
                          <option value="transfer">Transfer</option>
                          <option value="deposit">Deposit</option>
                          <option value="cash">Cash</option>
                          <option value="cheque">Cheque</option>
                        </select>
                        {this.renderErrorFor("channel")}
                      </div>
                    </div>

                    <div className="col-sm-4 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="transaction_id">
                          Transaction ID / Reference ID / Teller No.
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("transaction_id"),
                            "form-control": true,
                          })}
                          placeholder="Transaction ID | Reference ID | Teller No"
                          id="transaction_id"
                          name="transaction_id"
                          onChange={this.handleChange}
                          value={this.state.transaction_id}
                        />
                        {this.renderErrorFor("transaction_id")}
                      </div>
                    </div>
                    <div className="col-sm-2 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="evidence_date">Date</label>
                        <input
                          type="text"
                          readOnly
                          id="evidence_date"
                          className="form-control"
                        />
                        {this.renderErrorFor("paid_date")}
                      </div>
                    </div>

                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="amount">
                          Amount<span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          min={1}
                          readOnly
                          className={classnames({
                            "is-invalid": this.hasErrorFor("amount"),
                            "form-control": true,
                          })}
                          placeholder="0.0"
                          id="amount"
                          name="amount"

                          // value={this.state.amount}
                        />
                        {this.renderErrorFor("amount")}
                      </div>
                    </div>
                    <div className="col-sm-3 col-xs-12">
                      <div className="form-group">
                        <label htmlFor="years">No of years</label>
                        <input
                          type="number"
                          min={1}
                          className={classnames({
                            "is-invalid": this.hasErrorFor("years"),
                            "form-control": true,
                          })}
                          id="years"
                          name="years"
                          onChange={this.handleChange}
                          value={this.state.years}
                        />
                        {this.renderErrorFor("years")}
                      </div>
                    </div>

                    <div
                      className="col-8 mx-auto"
                      style={{
                        background: "#343a40",
                        borderRadius: "10px",
                        padding: "20px",
                        textAlign: "center",
                        color: "#FFF",
                      }}
                    >
                      <div className="form-group">
                        <label htmlFor="exampleInputFile">
                          {" "}
                          Attach Evidence of payment if any{" "}
                        </label>
                        <br />
                        <input
                          type="file"
                          id="evidence"
                          onChange={this.handleFileChange}
                        />
                        <hr />
                        <small>
                          <b>
                            File types expected are png,jpeg,jpg and pdf not
                            greater than 2MB
                          </b>
                        </small>
                        {this.renderErrorFor("evidence")}
                        <br />
                        <br />
                        <button
                          type="submit"
                          className="pull-right btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                          disabled={spinner || spinner2 ? true : false}
                        >
                          Submit <i className="fa fa-upload"></i>
                          {spinner || spinner2 ? (
                            <MiniSpinner color="white" />
                          ) : null}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </form>
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    spinner2: state.preferences.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    members: state.users.members,
    member_to_renew: state.users.member_to_renew,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllMembers: () => dispatch(getAllMembers()),
    memberRenew: (creds) => memberRenew(dispatch, creds),
    cancelRemitaRenewal: (creds, member_id) =>
      cancelRemitaRenewal(dispatch, creds, member_id),
    getAllSettings2: () => getAllSettings2(dispatch),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch),
    resetUsersRenewal: () => resetUsersRenewal(dispatch),
    showSuccessMessage: () => dispatch(showSuccessMessage()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Renewal);
