import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  registerActivityCon,
  resetUsersState,
  cancelRemitaConvention,
  showSuccessMessage,
} from "../../../../store/actions/usersActions";
import queryString from "query-string";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import classnames from "classnames";
import $ from "jquery";
import MiniSpinner from "../../../helpers/MiniSpinner";
import {
  getAllSettings2,
  getAllStates,
  getOneCampActivityCon,
  getAllHotelsAll,
  getCampActivityConReg,
} from "./../../../../store/actions/preferencesActions";
import { user_auth_details, dehash, storage_type } from "../../../../config";

class MoreDetails extends Component {
  state = {
    creds: {},
    states: null,
    modal_2: false,
    to_send: {},
    modal_1: false,
    modal_3: false,
    hotels: null,
    sel_hotel: null,
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_CONVENTION" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    let params = queryString.parse(this.props.location.search);
    if (params.status === "success") {
      this.props.showSuccessMessage();
    }
    if (params.message && params.message === "Approved") {
      this.setState({
        ...params,
        modal_1: true,
        info: "Transaction Successful",
      });
    }
    if (params.message && params.message !== "Approved") {
      this.setState({ ...params, modal_1: true, info: "Transaction Failed" });
    }
    this.initDependency();
    this.props.getAllStates();
    this.props.getAllHotelsAll();
    this.props.getAllSettings2().then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.settings }, () => {
          this.setState({
            creds: {
              ...this.state.creds,
              // gideon_fee: "",
              // aux_fee: "",
              // both_fee: "",
            },
          });
        });
      }
    });
    const id = this.props.match.params.id;
    this.props.getCampActivityConReg(id).then((res) => {
      if (res.status === "success") {
        // console.log(res.payload);
        this.setState(
          {
            regist: res.payload.registrations,
          },
          () => {
            let params = queryString.parse(this.props.location.search);
            const one_reg = this.state.regist.find(
              (item) => item.id === parseInt(params.id)
            );
            if (one_reg) {
              this.setState({ to_send: { ...this.state.to_send, ...one_reg } });
              //   $("#from_date").val(one_reg.from_date);
              //   this.initDependency();
            }
          }
        );
      }
    });

    this.props.getOneCampActivityCon(id).then((res) => {
      if (res.status === "success") {
        this.setState(
          {
            creds: { ...this.state.creds, ...res.payload.convention },
          },
          () => {
            if (this.state.creds.type === "state") {
              this.setState({
                creds: {
                  ...this.state.creds,
                  gideon_fee: this.state.gideon_state_convention_fee,
                  aux_fee: this.state.aux_state_convention_fee,
                  both_fee: this.state.both_state_convention_fee,
                },
              });
            }
            if (this.state.creds.type === "national") {
              this.setState({
                creds: {
                  ...this.state.creds,
                  gideon_fee: this.state.gideon_national_convention_fee,
                  aux_fee: this.state.aux_national_convention_fee,
                  both_fee: this.state.both_national_convention_fee,
                },
              });
            }

            if (this.state.creds.type === "international") {
              this.setState({
                creds: {
                  ...this.state.creds,
                  gideon_fee: this.state.gideon_international_convention_fee,
                  aux_fee: this.state.aux_international_convention_fee,
                  both_fee: this.state.both_international_convention_fee,
                },
              });
            }
          }
        );
        $("#start_date").val(res.payload.convention.start_date);
        $("#end_date").val(res.payload.convention.end_date);
      }
    });
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      this.setState({ user }, () => {
        this.setState({
          to_send: {
            ...this.state.to_send,
            type: this.state.user.type,
            id: this.props.match.params.id,
          },
        });
      });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.mem_state_area !== prevState.mem_state_area ||
      nextProps.s_campFromArea !== prevState.s_campFromArea ||
      nextProps.mem_state_region !== prevState.mem_state_region ||
      nextProps.states !== prevState.states ||
      nextProps.mem_state !== prevState.mem_state ||
      nextProps.hotels !== prevState.hotels
    ) {
      return {
        s_campFromArea: nextProps.s_campFromArea,
        mem_state_area: nextProps.mem_state_area,
        mem_state_region: nextProps.mem_state_region,
        states: nextProps.states,
        mem_state: nextProps.mem_state,
        hotels: nextProps.hotels,
      };
    }
    return null;
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    // console.log("here");

    const { target } = e;
    this.setState(
      {
        to_send: { ...this.state.to_send, [e.target.id]: e.target.value },
      },
      () => {
        if (target.id === "hostel_name") {
          const hotel = this.state.hotels.find(
            (hotel) => parseInt(target.value) === hotel.id
          );
          if (hotel) {
            this.setState({ sel_hotel: { ...hotel } });
          } else {
            this.setState({ sel_hotel: null });
          }
        }

        if (target.id === "type") {
          if (target.value === "national" || target.value === "international") {
            this.setState({ creds: { ...this.state.creds, state_id: "" } });
          }
        }
        if (target.id === "type" && target.value === "national") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.national_theme,
              number: this.state.national_number,
            },
          });
        }
        if (target.id === "type" && target.value === "international") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.international_theme,
              number: this.state.international_number,
            },
          });
        }
        if (target.id === "type" && target.value === "state") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.state_theme,
              number: this.state.state_number,
            },
          });
        }
        if (target.id === "type" && target.value === "") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: "",
              number: "",
            },
          });
        }
      }
    );

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const start_date = $("#start_date").val();
    const end_date = $("#end_date").val();

    const creds = { ...this.state.creds };
    if (!creds.state_id) {
      delete creds.state_id;
    }
    this.setState({ creds: { ...creds, start_date, end_date } }, () => {
      const from_date = $("#from_date").val();
      const to_date = $("#to_date").val();
      this.setState({ to_send: { ...this.state.to_send, from_date, to_date } });
      this.props.registerActivityCon(this.state.to_send).then((res) => {
        if (res.status === "success") {
          this.setState(
            {
              creds: { ...this.state.creds, service: "", reservation: "" },
            },
            (obj = res.payload) => {
              this.setState({ ...obj }, () => {
                this.setState({ modal_2: true });
              });
            }
          );
        }
      });
    });
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };
  getTypye = (type) => {
    if (type === "A") {
      return "Auxilliary";
    } else if (type === "G") {
      return "Gideon";
    } else if (type === "C") {
      return "Gideon & Auxilliary";
    } else {
      return "Unknown";
    }
  };
  closeModal_2 = (e) => {
    e.preventDefault();
    const rrr = this.state.rrr;
    this.props.cancelRemitaConvention(rrr).then((res) => {
      if (res.status === "success") {
        this.setState({
          modal_2: false,
          merchantId: "",
          rrr: "",
          response_url: "",
          action_url: "",
          hash: "",
        });
      }
    });
    // this.props.resetUsersState();
  };
  covMoney = (amount) => {
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  closeModal_1 = (e) => {
    e.preventDefault();
    this.setState({ modal_1: false });
  };

  render() {
    const {
      spinner,
      message,
      status,
      spinner1,
      message1,
      status1,
    } = this.props;
    if (
      (!spinner && status !== "error") ||
      (!spinner1 && status1 !== "error")
    ) {
      this.initDependency();
    }
    let hotel = null;
    let room = null;
    if (this.state.hotels && this.state.to_send.hostel_name) {
      hotel = this.state.hotels.find(
        (hotel) => parseInt(this.state.to_send.hostel_name) === hotel.id
      );
      room = hotel.rooms.find(
        (roo) => parseInt(this.state.to_send.room_package) === roo.id
      );
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Convention Registeration
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Convention Registeration Details{" "}
                {this.state.to_send.user
                  ? `(${this.state.to_send.user.first_name} ${this.state.to_send.user.last_name} ${this.state.to_send.user.other_name})`
                  : null}
              </h3>

              <div className="card-tools"></div>
            </div>
            {this.state.modal_2 ? (
              <div
                className="opptry"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999999999999999999999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  // padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center",

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form action={this.state.action_url} method="POST">
                    <h4 className="text-center mt-3">
                      Pay &#8358; {this.covMoney(this.state.amount)} with Remita{" "}
                      <img
                        src="/dist/img/remita.png"
                        alt=""
                        width="50"
                        style={{ position: "relative", top: "-1px" }}
                      />
                    </h4>
                    <p className="text-center text-danger ">
                      <small className="font-weight-bold">
                        Please note that you may be charge proccessing fee of
                        &#8358; {this.covMoney(100)}{" "}
                      </small>
                    </p>
                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <input
                        id="merchantId"
                        name="merchantId"
                        value={this.state.merchantId}
                        type="hidden"
                      />
                      <input
                        id="rrr"
                        name="rrr"
                        value={this.state.rrr}
                        type="hidden"
                      />
                      <input
                        id="responseurl"
                        name="responseurl"
                        value={this.state.response_url}
                        type="hidden"
                      />
                      <input
                        id="hash"
                        name="hash"
                        value={this.state.hash}
                        type="hidden"
                      />
                      {/* <div className="form-group">
                            <label htmlFor="comment">
                              Comment <span className="text-danger">*</span>{" "}
                            </label>
                            <textarea
                              className={classnames({
                                "is-invalid": this.hasErrorFor("comment"),
                                "form-control": true
                              })}
                              id="comment"
                              name="comment"
                              rows={2}
                              onChange={this.handleChange}
                              value={this.state.comment}
                            />

                            {this.renderErrorFor("comment")}
                          </div> */}

                      <button
                        // type="submit"
                        className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                      >
                        Pay
                      </button>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_2}
                      >
                        Cancel
                        {spinner || spinner1 ? <MiniSpinner /> : null}
                      </button>
                    </div>
                  </form>
                </div>

                {/* /.card-body */}
              </div>
            ) : null}

            {this.state.modal_1 ? (
              <div
                className="opptry"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  // padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center",

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                      // className="text-center mt-3"
                      >
                        {this.state.info}{" "}
                      </h4>
                      <i
                        style={{
                          fontSize: "1.6rem",
                          color: "#bda230",
                        }}
                        className={classnames({
                          "fas fa-check": this.state.message === "Approved",
                          "fas fa-exclamation-triangle":
                            this.state.message !== "Approved",
                        })}
                      ></i>
                    </div>

                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <p>
                        <strong>
                          {" "}
                          Order ID : {this.state.orderId}{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_1}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                  {/* <hr /> */}
                  {/* <div className="text-center">
                   
                  </div> */}
                </div>

                {/* /.card-body */}
              </div>
            ) : null}
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {message1 && status1 ? (
                <Message message={message1} status={status1} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              {spinner1 ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label>Type : </label>&nbsp;{" "}
                      {this.state.creds.type
                        ? this.state.creds.type.toUpperCase()
                        : ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="theme">Convention Theme : </label>&nbsp;
                      {this.state.creds.theme || ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="number">Convention Number : </label>&nbsp;
                      {this.state.creds.number || ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="gideon_fee">
                        Gideon Convention Fee :
                      </label>
                      &nbsp; &#8358;
                      {this.state.creds.gideon_fee || ""}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="aux_fee">
                        Auxilliary Convention Fee :
                      </label>
                      &nbsp; &#8358;
                      {this.state.creds.aux_fee || ""}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="both_fee">Couples Convention Fee </label>{" "}
                      &nbsp; &#8358;
                      {this.state.creds.both_fee || ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="start_date">State Date :</label> &nbsp;
                      {this.state.creds.start_date || ""}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="end_date">End Date :</label>&nbsp;
                      {this.state.creds.end_date || ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="address">Address</label>
                      &nbsp;
                      {this.state.creds.address || ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="admin_fund">Admin Fund</label>&nbsp;
                      &#8358;
                      {this.state.creds.admin_fund || ""}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="scripture_fund">Scripture Fund :</label>{" "}
                      &nbsp; &#8358;
                      {this.state.creds.scripture_fund || ""}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="city">City :</label>
                      {this.state.creds.city || ""}
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="state">State :</label> &nbsp;
                        {this.state.creds.state || ""}
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="country">Country :</label>&nbsp;
                      {this.state.creds.country || ""}
                    </div>

                    {/* <div className="form-group col-md-4"> */}
                    {/* <label htmlFor="location">
                        No of prospects<span className="text-danger">*</span>
                      </label> */}
                    {/* <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("location"),
                          "form-control": true,
                        })}
                        id="location"
                        name="location"
                        value={this.state.location}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("location")}
                    </div> */}
                    {/* <div className="form-group col-md-4">
                      <label htmlFor="funding">
                        No of Sign-Up<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="funding"
                        name="funding"
                        value={this.state.funding}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("funding")}
                    </div> */}
                    {/* <div className="form-group col-md-4">
                      <label htmlFor="speaker">
                        Amount Collected<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="speaker"
                        name="speaker"
                        value={this.state.speaker}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("speaker")}
                    </div> */}
                  </div>
                  <hr />
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        PLEASE COMPLETE THE FOLLOWING INFORMATION FOR ALL
                        CHILDREN AND YOUTH ATTENDING THE CONVENTION
                      </p>
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>SN</th>
                            <th>First Name</th>
                            <th>Family Name</th>
                            <th>Age</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1.</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "first_name_1"
                                  ),
                                  "form-control": true,
                                })}
                                id="first_name_1"
                                name="first_name_1"
                                value={this.state.to_send.first_name_1 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("first_name_1")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "family_name_1"
                                  ),
                                  "form-control": true,
                                })}
                                id="family_name_1"
                                name="family_name_1"
                                value={this.state.to_send.family_name_1 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("family_name_1")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor("age_1"),
                                  "form-control": true,
                                })}
                                id="age_1"
                                name="age_1"
                                value={this.state.to_send.age_1 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("age_1")}
                            </td>
                          </tr>
                          <tr>
                            <td>2.</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "first_name_2"
                                  ),
                                  "form-control": true,
                                })}
                                id="first_name_2"
                                name="first_name_2"
                                value={this.state.to_send.first_name_2 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("first_name_2")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "family_name_2"
                                  ),
                                  "form-control": true,
                                })}
                                id="family_name_2"
                                name="family_name_2"
                                value={this.state.to_send.family_name_2 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("family_name_2")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor("age_2"),
                                  "form-control": true,
                                })}
                                id="age_2"
                                name="age_2"
                                value={this.state.to_send.age_2 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("age_2")}
                            </td>
                          </tr>
                          <tr>
                            <td>3.</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "first_name_3"
                                  ),
                                  "form-control": true,
                                })}
                                id="first_name_3"
                                name="first_name_3"
                                value={this.state.to_send.first_name_3 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("first_name_3")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "family_name_3"
                                  ),
                                  "form-control": true,
                                })}
                                id="family_name_3"
                                name="family_name_3"
                                value={this.state.to_send.family_name_3 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("family_name_3")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor("age_3"),
                                  "form-control": true,
                                })}
                                id="age_3"
                                name="age_3"
                                value={this.state.to_send.age_3 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("age_3")}
                            </td>
                          </tr>
                          <tr>
                            <td>4.</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "first_name_4"
                                  ),
                                  "form-control": true,
                                })}
                                id="first_name_4"
                                name="first_name_4"
                                value={this.state.to_send.first_name_4 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("first_name_4")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "family_name_4"
                                  ),
                                  "form-control": true,
                                })}
                                id="family_name_4"
                                name="family_name_4"
                                value={this.state.to_send.family_name_4 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("family_name_4")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor("age_4"),
                                  "form-control": true,
                                })}
                                id="age_4"
                                name="age_4"
                                value={this.state.to_send.age_4 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("age_4")}
                            </td>
                          </tr>
                          <tr>
                            <td>5.</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "first_name_5"
                                  ),
                                  "form-control": true,
                                })}
                                id="first_name_5"
                                name="first_name_5"
                                value={this.state.to_send.first_name_5 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("first_name_5")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "family_name_5"
                                  ),
                                  "form-control": true,
                                })}
                                id="family_name_5"
                                name="family_name_5"
                                value={this.state.to_send.family_name_5 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("family_name_5")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor("age_5"),
                                  "form-control": true,
                                })}
                                id="age_5"
                                name="age_5"
                                value={this.state.to_send.age_5 || ""}
                                onChange={this.handleChange}
                                readOnly
                              />

                              {this.renderErrorFor("age_5")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div className="col-md-12">
                      <p>
                        FREE NURSERY WILL BE PROVIDED FOR CHILDREN DURING
                        GIDEONS SESSIONS.{" "}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="type">Convention Fee Type :</label>
                        {this.state.to_send.type
                          ? this.getTypye(this.state.to_send.type)
                          : ""}
                      </div>
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="reservation">
                        Do you need a Hotel Reservation ?{" "}
                      </label>
                      {this.state.to_send.reservation || ""}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="service">
                        Will you take a Church Service?{" "}
                      </label>
                      {this.state.to_send.service || ""}
                    </div>
                  </div>

                  {this.state.to_send.reservation === "Yes" ? (
                    <div className="row">
                      <p className="lead my-3 ">Hostel Reservation</p>
                      <div className="col-12">
                        <div className="card p-3 text-secondary font-weight-bold">
                          Single or Double Room N …… (Includes 15% of Published
                          Hotel Rate) NO CHARGE FOR CHILDREN STAYING IN SAME
                          ROOM WITH PARENT. ALL FIRST NIGHT ACCOMMODATION MUST
                          BE PAID BEFORE ANY RESERVATION BY LOC
                        </div>
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="hostel_name">
                          Preferred Hotel Name :{" "}
                        </label>
                        {hotel ? hotel.name : ""}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="hostel_name">
                          Preferred Room Package :{" "}
                        </label>
                        {room ? room.name : ""}
                      </div>

                      {/* {this.state.sel_hotel ? (
                        <div className="col-12">
                          <div className="card p-3 text-secondary font-weight-bold">
                            <div className="row">
                              <div className="col-md-6">
                                Remark: {this.state.sel_hotel.remarks}
                              </div>
                              <div className="col-md-6">
                                Telephone: {this.state.sel_hotel.tel}
                              </div>
                              <div className="col-md-6">
                                Address: {this.state.sel_hotel.address}
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : null} */}
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="from_date">Arrival Date :</label>

                          {this.state.to_send.from_date || ""}
                        </div>
                      </div>

                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="to_date">Check out Date :</label>
                          {this.state.to_send.to_date || ""}
                        </div>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="room_type">Desired Room Type: </label>

                        {this.state.to_send.room_type || ""}
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="share_room">
                          Please Select If You Would Like To Share A Double Room
                          With Another Convention Delegate. If sharing, this
                          means you will pay half the room rate{" "}
                          <small className="text-danger">
                            {" "}
                            (Note We Cannot guarantee a person to share a room
                            with you).Also you will forfeit your deposit should
                            you not keep the Reservation Conditions.{" "}
                          </small>{" "}
                        </label>
                        {this.state.to_send.share_room || ""}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="stay_gideon_family">
                          Select if you will like to stay with a Gideon family
                          where available. We do not guarantee that there will
                          be rooms for all who want it. If any, rooms will be
                          allocated on *First come First served basis. :
                        </label>
                        {this.state.to_send.stay_gideon_family || ""}
                      </div>
                    </div>
                  ) : null}
                  {this.state.to_send.service === "Yes" ? (
                    <div className="row">
                      <div className="col-12">
                        <p className="lead my-3 "> Church Service Form </p>
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="denonination">
                          Indicate your Denomination:{" "}
                        </label>
                        {this.state.to_send.denomination || ""}
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="language_preference">
                          Indicate your Language Preference :{" "}
                        </label>
                        {this.state.to_send.language_preference || ""}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="language_interpreter">
                          If you will need an interpreter, please indicate the
                          language :{" "}
                        </label>
                        {this.state.to_send.language_interpreter || ""}
                      </div>
                    </div>
                  ) : null}

                  <Link
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                    to={`/preferences/activities/convention/details/back/${this.state.to_send.convention_id}`}
                    // onClick={this.goBack}
                  >
                    Back
                  </Link>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    states1: state.preferences.states,
    spinner1: state.preferences.spinner,
    message1: state.preferences.message,
    status1: state.preferences.status,
    errors1: state.preferences.errors,
    states: state.preferences.states,
    hotels: state.preferences.hotels,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    registerActivityCon: (creds) => registerActivityCon(dispatch, creds),
    resetUsersState: () => resetUsersState(dispatch),
    getAllSettings2: () => getAllSettings2(dispatch),
    getAllHotelsAll: () => dispatch(getAllHotelsAll()),
    getAllStates: () => dispatch(getAllStates()),
    getOneCampActivityCon: (id) => getOneCampActivityCon(dispatch, id),
    cancelRemitaConvention: (creds) => cancelRemitaConvention(dispatch, creds),
    showSuccessMessage: () => dispatch(showSuccessMessage()),
    getCampActivityConReg: (id) => getCampActivityConReg(dispatch, id),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MoreDetails);
