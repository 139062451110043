import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getMyScriptureRequest,
  resetUsersState,
  activityCompleted,
} from "./../../../../store/actions/usersActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import MiniSpinner from "../../../helpers/MiniSpinner";
import classnames from "classnames";
import Pagination from "react-js-pagination";

class MyScriptureRequest extends Component {
  state = {
    activePage: 1,
    modal_2: false,
    reason: "",
  };
  componentDidMount() {
    const { setPermissionsErrors, getMyScriptureRequest } = this.props;
    if (
      !hasPermission({
        mod: "Personal Management",
        action: "PERSONAL_DASHBOARD",
      }) ||
      !hasPermission({
        mod: "Personal Management",
        action: "CAMP_ACTIVITY_SCRIPTURE_REQUISITION",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getMyScriptureRequest().then((res) => {
        if (res.status === "success") {
          //   console.log(res.payload);
          this.setState({ ...res.payload.requests });
        }
      });
    }
  }
  dateConv = (date) => {
    if (!date) {
      return null;
    }
    return new Date(date).toDateString();
  };

  handlePageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber });
    const { getMyScriptureRequest } = this.props;
    getMyScriptureRequest(`?page=${pageNumber}`).then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.requests });
      }
    });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  render() {
    const { spinner, message, status } = this.props;

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PERSONAL</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    My Scripture Request(s)
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                {" "}
                My Scripture Request(s) (Scripture Distribution)
              </h3>
              <div className="card-tools"></div>
            </div>
            <div
              className="card-body"
              style={{
                // width: "100%"
                overflowX: "scroll",
              }}
            >
              {this.state.modal_2 ? (
                <div
                  //   className="card opp"
                  //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                  style={{
                    position: "fixed",
                    top: "0px",
                    right: "0px",
                    bottom: "0px",
                    left: "0px",
                    zIndex: 9999999,
                    backgroundColor: "rgba(0,0,0,0.5)",
                    // padding: "5%",
                    display: "flex",
                    justifyContent: "center",
                    overflowX: "hidden",

                    alignItems: "center",

                    // transition: "all 10s ease"
                  }}
                >
                  <div
                    className="card-body"
                    style={{
                      backgroundColor: "#fff",
                      height: "300px",
                      overflowX: "hidden",
                    }}
                  >
                    <form onSubmit={this.handleSubmit}>
                      <h4 className="text-center mt-3">
                        {" "}
                        Enter Cancellation Reason(s)
                      </h4>
                      <div className="col-sm-6 offset-sm-3 ">
                        <div className="form-group">
                          <label htmlFor="reason">
                            Reason <span className="text-danger">*</span>{" "}
                          </label>
                          <textarea
                            className={classnames({
                              "is-invalid": this.hasErrorFor("reason"),
                              "form-control": true,
                            })}
                            id="reason"
                            name="reason"
                            rows={2}
                            onChange={this.handleChange}
                            value={this.state.reason}
                          />

                          {this.renderErrorFor("reason")}
                        </div>

                        <button
                          type="submit"
                          className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                          disabled={spinner ? true : false}
                        >
                          Reject
                          {spinner ? <MiniSpinner color="white" /> : null}
                        </button>
                        <button
                          className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                          disabled={spinner ? true : false}
                          onClick={this.closeModal_2}
                        >
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>

                  {/* /.card-body */}
                </div>
              ) : null}

              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div style={{ paddingBottom: "300px" }}>
                  <Spinner />
                </div>
              ) : (
                <div key="uniqueKey">
                  <div className="form-group col-md-3">
                    <div className="input-group input-group-sm">
                      <input type="text" className="form-control" />
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-primary btn-flat"
                        >
                          <i className="fas fa-times-circle"></i>
                        </button>
                      </span>
                      <span className="input-group-append">
                        <button
                          type="button"
                          className="btn btn-warning btn-flat"
                        >
                          <i className="fas fa-search"></i>
                        </button>
                      </span>
                    </div>
                  </div>
                  <div className="table responsive">
                    <table
                      id="state_table"
                      className="table  table-hover table-bordered table-striped"
                      style={{
                        width: "100%",
                        // overflowX:"scroll"
                      }}
                    >
                      <thead>
                        <tr>
                          <th>Activity Type</th>
                          <th>Activity Description</th>
                          <th>Activity Status</th>
                          <th>Request Status</th>
                          <th>Authorized By</th>
                          <th>Authorized Date</th>
                          <th>Created Date</th>
                          <th>View Details</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.data &&
                          this.state.data.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.activity.type}</td>
                                <td>{item.activity.description}</td>
                                <td>
                                  {item.activity.status === "P"
                                    ? "PENDING"
                                    : "AUTHORIZED"}
                                </td>
                                <td>
                                  {item.status === "P"
                                    ? "PENDING"
                                    : "AUTHORIZED"}
                                </td>
                                <td>
                                  {item.authorised_by
                                    ? `${item.authorised_by.first_name}  ${
                                        item.authorised_by.last_name
                                      } ${
                                        item.authorised_by.other_name
                                          ? item.authorised_by.other_name
                                          : ""
                                      }`
                                    : null}
                                </td>
                                <td>{this.dateConv(item.authorised_date)}</td>
                                <td>{this.dateConv(item.created_at)}</td>
                                <td>
                                  <Link
                                    to={`/personal/scriptures-request-details/${item.id}`}
                                    className="btn btn-default btn-sm btn-clean btn-icon btn-icon-md d-flex"
                                  >
                                    <i className="fa fa-eye mr-1 mt-1"></i>
                                    View
                                  </Link>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  <div className="d-flex justify-content-center">
                    <Pagination
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.per_page}
                      totalItemsCount={this.state.total}
                      pageRangeDisplayed={5}
                      onChange={this.handlePageChange}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                  </div>
                </div>
              )}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    states: state.users.states,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMyScriptureRequest: (page) => getMyScriptureRequest(dispatch, page),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetUsersState: () => resetUsersState(dispatch),
    activityCompleted: (id) => activityCompleted(dispatch, id),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyScriptureRequest);
