import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../../helpers/functions/functions";
// import { connect } from "react-redux";

class ReportAside extends Component {
  render() {
    const { handleActiveLink } = this.props;
    return (
      <React.Fragment>
        {hasPermission({
          mod: "Reports",
          action: "VIEW_REPORTS",
        }) ? (
          <li className="nav-item has-treeview">
            <a href="#!" className="nav-link">
              <i className="nav-icon fas fa-chart-pie" />
              <p>
                PERFORMANCE REPORT
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
              {/* CAMP */}
              {hasPermission({
                mod: "Reports",
                action: "VIEW_CAMP_SCRIPTURE_REPORT",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_CAMP_SCRIPTURE_REPORT_HQ",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_CAMPS_FUND_REPORTS",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_CAMPS_FUND_REPORTS_HQ",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_CAMP_MEMBERSHIP_REPORT",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_CAMP_MEMBERSHIP_REPORT_HQ",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_CAMPS_MONTHLY_FUND_REMITTANCE_REPORTS",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_CAMPS_MONTHLY_FUND_REMITTANCE_REPORTS_HQ",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Camps
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMPS_MONTHLY_FUND_REMITTANCE_REPORTS_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/form-503-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Form 503 Report HQ</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMPS_MONTHLY_FUND_REMITTANCE_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/form-503-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Form 503 Report</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMPS_MONTHLY_PERFORMANCE_REPORTS_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/monthly-performance-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Monthly Performance Rpt HQ</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMPS_MONTHLY_PERFORMANCE_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/monthly-performance-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Monthly Performance Report</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMP_SCRIPTURE_REPORT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/camp/scripture-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Scripture Report</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMP_SCRIPTURE_REPORT_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/camp/scripture-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Scripture Report HQ</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMPS_FUND_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/account-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Fund Report</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMP_MEMBERSHIP_REPORT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/member-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Membership Report</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMP_MEMBERSHIP_REPORT_HQ",
                    }) ? (
                      <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/report/member-report-hq"
                        className="nav-link"
                      >
                      <i className="far fa-dot-circle nav-icon" />
                      <p>Membership Report HQ</p>
                    </Link>
                    </li>

                      // <li className="nav-item has-treeview">
                      //     <a href="#!" className="nav-link">
                      //       <i className="fas fa-circle nav-icon" />
                      //       <p>
                      //         Membership Report HQ
                      //         <i className="right fas fa-angle-left" />
                      //       </p>
                      //     </a>
                      //     <ul className="nav nav-treeview">
                      //       <li className="nav-item">
                      //       <Link
                      //         onClick={handleActiveLink}
                      //         to="/report/member-report-hq"
                      //         className="nav-link"
                      //       >
                      //       <i className="far fa-dot-circle nav-icon" />
                      //       <p>Individual Camp</p>
                      //     </Link>
                      //     </li>
                      //     <li className="nav-item">
                      //       <Link
                      //         onClick={handleActiveLink}
                      //         to="/report/member-report-hq-all"
                      //         className="nav-link"
                      //       >
                      //       <i className="far fa-dot-circle nav-icon" />
                      //       <p>All Camps</p>
                      //     </Link>
                      //     </li>
                      //   </ul>
                      // </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMPS_FUND_REPORTS_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/account-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Fund Report HQ</p>
                        </Link>
                      </li>
                    ) : null}

                    {/* {hasPermission({
                      mod: "Reports",
                      action: "VIEW_CAMPS_PERFORMANCE_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/performance-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Performance Report</p>
                        </Link>
                      </li>
                    ) : null} */}
                  </ul>
                </li>
              ) : null}
              {/* AREA */}
              {hasPermission({
                mod: "Reports",
                action: "VIEW_AREA_SCRIPTURE_REPORT",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_AREA_SCRIPTURE_REPORT_HQ",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_AREA_MEMBERSHIP_REPORT",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_AREA_MEMBERSHIP_REPORT_HQ",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_AREAS_FUND_REPORTS",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_AREAS_FUND_REPORTS_HQ",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Areas
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {/* {hasPermission({
                    mod: "Reports",
                    action: "VIEW_AREAS_FUND_REPORTS",
                  }) ? (
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/report/area/form-503-report"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p>Form 503 Report</p>
                      </Link>
                    </li>
                  ) : null} */}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_AREA_SCRIPTURE_REPORT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/area/scripture-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Scripture Report</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_AREA_SCRIPTURE_REPORT_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/area/scripture-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Scripture Report HQ</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_AREA_MEMBERSHIP_REPORT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/area/membership-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Membership Report</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_AREA_MEMBERSHIP_REPORT_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/area/member-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Membership Report HQ</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_AREAS_FUND_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/area/account-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Fund Report</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_AREAS_FUND_REPORTS_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/area/account-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Fund Report HQ</p>
                        </Link>
                      </li>
                    ) : null}
                    {/* {hasPermission({
                      mod: "Reports",
                      action: "VIEW_AREAS_PERFORMANCE_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/area/performance-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Performance Report</p>
                        </Link>
                      </li>
                    ) : null} */}
                  </ul>
                </li>
              ) : null}
              {/* REGION */}
              {hasPermission({
                mod: "Reports",
                action: "VIEW_REGION_SCRIPTURE_REPORT",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_REGION_SCRIPTURE_REPORT_HQ",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_REGION_MEMBERSHIP_REPORT",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_REGION_MEMBERSHIP_REPORT_HQ",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_REGIONS_FUND_REPORTS",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_REGIONS_FUND_REPORTS_HQ",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      Regions
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_REGION_SCRIPTURE_REPORT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/region/scripture-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Scripture Report</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_REGION_SCRIPTURE_REPORT_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/region/scripture-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Scripture Report HQ</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_REGION_MEMBERSHIP_REPORT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/region/member-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Membership Report</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_REGION_MEMBERSHIP_REPORT_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/region/member-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Membership Report HQ</p>
                        </Link>
                      </li>
                    ) : null}

                    {/* {hasPermission({
                    mod: "Reports",
                    action: "VIEW_REGIONS_FUND_REPORTS",
                  }) ? (
                    <li className="nav-item">
                      <Link
                        onClick={handleActiveLink}
                        to="/report/region/form-503-report"
                        className="nav-link"
                      >
                        <i className="far fa-dot-circle nav-icon" />
                        <p>Form 503 Report</p>
                      </Link>
                    </li>
                  ) : null} */}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_REGIONS_FUND_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/region/account-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Fund Report</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_REGIONS_FUND_REPORTS_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/region/account-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Fund Report HQ</p>
                        </Link>
                      </li>
                    ) : null}

                    {/* {hasPermission({
                      mod: "Reports",
                      action: "VIEW_REGIONS_PERFORMANCE_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/region/performance-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Performance Report</p>
                        </Link>
                      </li>
                    ) : null} */}
                  </ul>
                </li>
              ) : null}
              {/* STATE */}

              {hasPermission({
                mod: "Reports",
                action: "VIEW_STATE_SCRIPTURE_REPORT",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_STATE_MEMBERSHIP_REPORT",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_STATE_MEMBERSHIP_REPORT_HQ",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_STATES_FUND_REPORTS",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_STATES_FUND_REPORTS_HQ",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_STATES_DISTRIBUTION_PERFORMANCE_REPORTS",
              }) ||
              hasPermission({
                mod: "Reports",
                action: "VIEW_ALL_STATES_MEMBERSHIP_REPORT_HQ",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      States
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_STATE_SCRIPTURE_REPORT_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/state/scripture-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Scripture Report HQ</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_STATE_SCRIPTURE_REPORT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/state/scripture-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Scripture Report</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_STATE_MEMBERSHIP_REPORT",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/state/member-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Membership Report</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_STATE_MEMBERSHIP_REPORT_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/state/member-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Membership Report HQ</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_STATES_FUND_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/state/account-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Fund Report</p>
                        </Link>
                      </li>
                    ) : null}
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_STATES_FUND_REPORTS_HQ",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/state/account-report-hq"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Fund Report HQ</p>
                        </Link>
                      </li>
                    ) : null}

                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_STATES_DISTRIBUTION_PERFORMANCE_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/state/distribution-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Distribution Report</p>
                        </Link>
                      </li>
                    ) : null}

                    {/* {hasPermission({
                      mod: "Reports",
                      action: "VIEW_STATES_PERFORMANCE_REPORTS",
                    }) ? (
                      <li className="nav-item">
                        <Link
                          onClick={handleActiveLink}
                          to="/report/state/performance-report"
                          className="nav-link"
                        >
                          <i className="far fa-dot-circle nav-icon" />
                          <p>Performance Report</p>
                        </Link>
                      </li>
                    ) : null} */}
                  </ul>
                </li>
              ) : null}

              {hasPermission({
                mod: "Reports",
                action: "VIEW_STATE_SCRIPTURE_REPORT_HQ",
              }) ? (
                <li className="nav-item has-treeview">
                  <a href="#!" className="nav-link">
                    <i className="fas fa-circle nav-icon" />
                    <p>
                      National
                      <i className="right fas fa-angle-left" />
                    </p>
                  </a>
                  <ul className="nav nav-treeview">
                    {hasPermission({
                      mod: "Reports",
                      action: "VIEW_ALL_STATES_MEMBERSHIP_REPORT_HQ",
                    }) ||
                    hasPermission({
                      mod: "Reports",
                      action: "VIEW_ALL_STATES_SCRIPTURE_REPORT_HQ",
                    }) ||
                    hasPermission({
                      mod: "Reports",
                      action: "VIEW_ALL_STATES_FUND_REPORT_HQ",
                    }) ? (
                      <>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/report/state/all-state-report"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p>Membership Report</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/report/state/all-state-report/scripture"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p>Scripture Report</p>
                          </Link>
                        </li>
                        <li className="nav-item">
                          <Link
                            onClick={handleActiveLink}
                            to="/report/state/all-state-report/fund"
                            className="nav-link"
                          >
                            <i className="far fa-dot-circle nav-icon" />
                            <p>Fund Report</p>
                          </Link>
                        </li>
                      </>
                    ) : null}
                  </ul>
                </li>
              ) : null}
            </ul>
          </li>
        ) : null}
      </React.Fragment>
    );
  }
}

export default ReportAside;
