import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import { createBible } from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import $ from "jquery";

class CreateBibles extends Component {
  state = {
    name: "",
    cost_price: "",
    retail_price: "",
    merchandise: "",
    image: "",
    description: ""
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    // console.log(this.props);
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_BIBLE" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    //  else {
    //     this.props.getAllStates();
    // }
  }
  // static getDerivedStateFromProps(nextProps, prevState) {
  //     if (nextProps.states !== prevState.states) {
  //         return {
  //             states: nextProps.states
  //         };
  //     }
  //     return null;
  // }

  // handleSelectArea = e => {
  //     this.setState({ state_id: e.target.value });
  // };
  handleSubmit = e => {
    e.preventDefault();
    const { createBible } = this.props;
    const creds = {
      name: this.state.name,
      description: this.state.description,
      cost_price: this.state.cost_price,
      retail_price: this.state.retail_price,
      merchandise: this.state.merchandise,
      image: this.state.image
    };
    createBible(creds).then(res => {
      if (res.status === "success") {
        this.setState({
          name: "",
          cost_price: "",
          retail_price: "",
          merchandise: "",
          image: "",
          description: ""
        });
        $(".selected_state").val("");
        $("#image").val("");
      }
    });
  };
  handleChange = e => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleFileChange = e => {
    this.setState({
      image: e.target.files[0]
    });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Create Stock Item</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Create Stock Item
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("name"),
                        "form-control": true
                      })}
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("name")}
                  </div>
                  <div className="form-group col-md-6">
                    <label>
                      Is Item a Cash Item?{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("merchandise"),
                        "form-control selected_state": true
                      })}
                      placeholder="Select a State"
                      onChange={this.handleChange}
                      id="merchandise"
                      value={this.state.merchandise}
                    >
                      <option value="">--Select--</option>
                      <option value="1"> Yes</option>
                      <option value="0"> No</option>
                    </select>
                    {this.renderErrorFor("merchandise")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="cost_price">
                      Cost Price <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("cost_price"),
                        "form-control": true
                      })}
                      id="cost_price"
                      name="cost_price"
                      value={this.state.cost_price}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("cost_price")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="retail_price">Retail Price</label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("retail_price"),
                        "form-control": true
                      })}
                      id="retail_price"
                      name="retail_price"
                      value={this.state.retail_price}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("retail_price")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="description">
                      Description <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className={classnames({
                        "is-invalid": this.hasErrorFor("description"),
                        "form-control": true
                      })}
                      id="description"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("description")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="image">
                      Upload Item's image <span className="text-danger">*</span>
                    </label>
                    <br />

                    <input
                      type="file"
                      id="image"
                      onChange={this.handleFileChange}
                    />

                    {this.renderErrorFor("image")}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Create
                </button>

                <Link
                  to="/preferences/bibles"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                >
                  View All
                </Link>
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    states: state.preferences.states,
    errors: state.preferences.errors
  };
};
const mapDispatchToProps = dispatch => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    createBible: creds => createBible(dispatch, creds)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateBibles);
