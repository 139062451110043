import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
// import {
//   getAllCamps,
//   deleteCampAction
// } from "./../../../store/actions/preferencesActions";
import {
  getAllDelisted,
  restoreDelisted,
} from "./../../../store/actions/usersActions";
import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../store/actions/authActions";
import $ from "jquery";
import { getAllSettings2 } from "./../../../store/actions/preferencesActions";
import DilistedTable from "./DilistedTable";
class ViewDelisted extends Component {
  state = {
    delisted: null,
    initdep: false,
    id: null,
    name: "",
    remark: "",
    error: 0,
    type: "A",
    aux_amount: "",
    gideon_amount: "",
    years: "1",
    channel: "",
    amount: "0",
    transaction_id: "",
    evidence: "",
    paid_date: "",
  };

  constructor(props) {
    super(props);
    this.triggerRestoreDelist = this.triggerRestoreDelist.bind(this);
    this.setRemarkProperties = this.setRemarkProperties.bind(this);
  }

  triggerRestoreDelist = () => {
    if (this.state.remark) {
      this.setState({ error: 0 });

      if (window.confirm("Restore Member: " + this.state.name + "?")) {
        const { restoreDelisted } = this.props;
        const paid_date = $("#evidence_date").val();
        const creds = {
          member: this.state.id,
          remark: this.state.remark,
          paid_date,
          channel: this.state.channel,
          type: this.state.type,
          amount: this.state.amount,
          transaction_id: this.state.transaction_id,
          evidence: this.state.evidence,
          years: this.state.years,
        };
        restoreDelisted(creds).then((res) => {
          // console.log(res);
          if (res?.status === "success") {
            this.setState({
              id: null,
              name: "",
              remark: "",
              error: 0,
              channel: "",
              type: "A",
              amount: "",
              transaction_id: "",
              evidence: "",
              years: "1",
            });

            $("#closeModalDel").trigger("click");
          }
        });
      }
    } else {
      this.setState({ error: 1 });
    }
  };

  setRemarkProperties = (id, name, type) => {
    this.setState({
      id: id,
      name: name,
      type,
      amount: type === "A" ? this.state.aux_amount : this.state.gideon_amount,
      years: "1",
      transaction_id: "",
      evidence: "",
      channel: "",
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleFileChange = (e) => {
    this.setState({
      evidence: e.target.files[0],
    });
  };

  componentDidMount() {
    this.setState({ initdep: true });
    const { setPermissionsErrors, getAllDelisted } = this.props;
    if (!hasPermission({ mod: "Preferences", action: "DELIST_MEMBER" })) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllDelisted();
      this.props.getAllSettings2().then((res) => {
        if (res.status === "success") {
          this.setState({
            gideon_amount: res.payload.settings.gideon_amount,
            aux_amount: res.payload.settings.aux_amount,
          });
        }
      });
    }
  }
  initDependency = () => {
    if (this.state.initdep) {
      const old_element = document.querySelector(
        "script[src='/js/content.js']"
      );
      const new_element = document.createElement("script");
      new_element.src = `/js/content.js`;
      new_element.async = true;
      if (old_element) {
        old_element.replaceWith(new_element);
      } else {
        document.body.appendChild(new_element);
      }
      this.setState({ initdep: false });
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.delisted !== prevState.delisted) {
      return {
        delisted: nextProps.delisted,
      };
    }
    return null;
  }

  dateConv = (date) => {
    if (date === null || date === "") {
      return null;
    }
    return new Date(date).toDateString();
  };
  renderErrorFor(field) {
    const { errors, status } = this.props;

    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1 className="text-uppercase">Membership</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">View Delisted</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                View Delisted Members
              </h3>

              <Link
                to="/membership/delist/history"
                style={{ float: "right" }}
                className="btn btn-sm btn-primary btn-icon mr-1"
                title="Load deleted history"
              >
                Load History &nbsp;
                <i className="fas fa-arrow-circle-right text-white"></i>
              </Link>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}

              <DilistedTable
                delisted={this.props.delisted}
                setRemarkProperties={this.setRemarkProperties}
                dateConv={this.dateConv}
              />
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}

        <div
          className="modal fade"
          id="RemarkModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="RemarkModalLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="RemarkModalLabel">
                  Restore Membership
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <label htmlFor="member_name">Member</label>
                <input
                  type="text"
                  id="member_name"
                  className="form-control"
                  defaultValue={this.state.name}
                  placeholder="Member Name"
                />

                <br />

                <label htmlFor="member_remark">Remark</label>
                <textarea
                  rows={3}
                  id="member_remark"
                  name="remark"
                  value={this.state.remark}
                  onChange={this.handleChange}
                  className="form-control"
                  placeholder="Provide reasons for restoring member"
                >
                  {this.state.remark}
                </textarea>

                {/* {this.renderErrorFor("remark")} */}
                <hr />
                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="type">Type</label>
                    <input
                      type="text"
                      id="type"
                      className="form-control"
                      defaultValue={
                        this.state.type == "G" ? "Gideon" : "Auxiliary"
                      }
                      value={this.state.type == "G" ? "Gideon" : "Auxiliary"}
                      placeholder="Member type"
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="type">Amount</label>
                    <input
                      type="text"
                      id="amount"
                      className="form-control"
                      defaultValue={
                        this.state.type == "G"
                          ? this.state.gideon_amount
                          : this.state.aux_amount
                      }
                      value={this.state.amount}
                      placeholder="Amount"
                      readOnly
                    />
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-4 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="transaction_id">
                        Transaction ID / Teller No.
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("transaction_id"),
                          "form-control": true,
                        })}
                        placeholder="Transaction ID | Reference ID | Teller No"
                        id="transaction_id"
                        name="transaction_id"
                        onChange={this.handleChange}
                        value={this.state.transaction_id}
                      />
                      {this.renderErrorFor("transaction_id")}
                    </div>
                  </div>
                  <div className="col-sm-2 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="years">No of years</label>
                      <input
                        type="number"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("years"),
                          "form-control": true,
                        })}
                        id="years"
                        name="years"
                        onChange={this.handleChange}
                        value={this.state.years}
                      />
                      {this.renderErrorFor("years")}
                    </div>
                  </div>
                  <div className="col-sm-4 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="channel">
                        Payment Type<span className="text-danger">*</span>
                      </label>
                      <select
                        className={classnames({
                          "is-invalid": this.hasErrorFor("channel"),
                          "form-control": true,
                        })}
                        id="channel"
                        name="channel"
                        onChange={this.handleChange}
                        value={this.state.channel}
                      >
                        <option value="">--Select--</option>
                        <option value="transfer">Transfer</option>
                        <option value="deposit">Deposit</option>
                        <option value="cash">Cash</option>
                        <option value="cheque">Cheque</option>
                      </select>
                      {this.renderErrorFor("channel")}
                    </div>
                  </div>
                  <div className="col-sm-2 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="evidence_date">Date</label>
                      <input
                        type="text"
                        readOnly
                        id="evidence_date"
                        className="form-control"
                      />
                      {this.renderErrorFor("paid_date")}
                    </div>
                  </div>
                  <div className="col-sm-12 col-xs-12">
                    <div className="form-group">
                      <label htmlFor="exampleInputFile">
                        {" "}
                        Attach Evidence of payment if any{" "}
                      </label>
                      <br />
                      <input
                        type="file"
                        id="evidence"
                        onChange={this.handleFileChange}
                      />
                      <hr />
                      <small>
                        <b>
                          File types expected are png,jpeg,jpg and pdf not
                          greater than 2MB
                        </b>
                      </small>
                      {this.renderErrorFor("evidence")}
                    </div>
                  </div>
                </div>

                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-danger"
                    id="closeModalDel"
                    data-dismiss="modal"
                  >
                    Close
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => this.triggerRestoreDelist()}
                  >
                    Approve
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    delisted: state.users.delisted,
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    restoreDelisted: (creds) => restoreDelisted(dispatch, creds),
    getAllDelisted: () => dispatch(getAllDelisted()),
    getAllSettings2: () => getAllSettings2(dispatch),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ViewDelisted));
