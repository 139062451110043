import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  getMonthlyPerm,
  resetReportState,
} from "./../../../store/actions/reportActions";
import { getAllCamps, setAll } from "../../../store/actions/preferencesActions";

import Spinner from "./../../helpers/Spinner";
import Message from "./../../helpers/Message";
import classnames from "classnames";
import MiniSpinner from "./../../helpers/MiniSpinner";
import $ from "jquery";
import _ from "underscore";
import { hasPermission } from "./../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../store/actions/authActions";
import { BASE_URL, token, storage_type } from "../../../config";

// var $ = require("jquery");
// var dt = require("datatables.net")();
class MonthlyPerm extends Component {
  state = {
    camps: null,
    camp_performance: {},
    user_camp: null,
    // checkarea: 0,
    // checkregion: 0,
    // checkstate: 0,

    // arranged_data: null
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({
        mod: "Reports",
        action: "VIEW_REPORTS",
      }) ||
      !hasPermission({
        mod: "Reports",
        action: "VIEW_CAMPS_MONTHLY_PERFORMANCE_REPORTS",
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    document.title =
      "The Gideon International In Nigeria (Monthly Camp Performance)";
    this.initDependency();
    // const { getMonthlyPerm } = this.props;
    // getMonthlyPerm(1, "12/12/2020", "15/12/2020");
    this.props.setAll();
    this.props.getAllCamps();
    // this.props.getAllArea().then(res => {
    //   if (res.status === "success") {
    //     this.setState({ areas: res.payload.area });
    //   }
    // });
  }
  initDependency = () => {
    // alert("here");
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.camps !== prevState.camps ||
      nextProps.user_camp !== prevState.user_camp ||
      nextProps.camp_performance !== prevState.camp_performance
    ) {
      // const arry = [];
      // const temp_camp_performance = [...nextProps.camp_performance];
      // if (temp_camp_performance.length) {
      //   const camp_group = _.groupBy(temp_camp_performance, "camp");
      //   for (let [key, value] of Object.entries(camp_group)) {
      //     arry.push({
      //       camp: key,
      //       fund: _.groupBy(value, "purpose"),
      //     });
      //   }
      //   // console.log(arry);
      //   // this.updateState(arry);
      // }
      // if (nextProps.camp_performance) {
      //   $("#fund_table1 tbody").empty();
      // }
      // console.log(prevState);
      return {
        camps: nextProps.camps,
        camp_performance: nextProps.camp_performance,
        user_camp: nextProps.user_camp,
      };
    }
    return null;
  }
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  handleDownload = (e) => {
    e.preventDefault();
    const from = $("#from_date").val();
    const to = $("#to_date").val();
    const id = $(".memberselect22").val();
    const area = this.state.checkarea;
    const region = this.state.checkregion;
    const state = this.state.checkstate;
    const tok = storage_type.getItem(token);
    const download = 1;
    const url = `${BASE_URL}/reports/camps/camp_performance?camp=${id}&from=${from}&to=${to}&area=${area}&region=${region}&state=${state}&download=${download}&token=${tok}`;
    window.open(url, "_blank");
  };
  handleSubmit = (e) => {
    e.preventDefault();

    const from_date = $("#from_date").val();
    // const to_date = $("#to_date").val();
    const id = $(".memberselect22").val();
    // console.log(id);
    const sel = this.props.camps.filter((item) => item.id === parseInt(id));
    // console.log(sel[0]);
    this.setState(
      {
        from_date,
        id,
        sel: { ...sel[0] },
      },
      () => {
        if (window.confirm("Are you sure to want to generate this report?")) {
          this.props.getMonthlyPerm(id, from_date);
        }
      }
    );
  };

  // arrangeReport = () => {};
  // updateState = arry => {
  //   const dd = arry;
  //   setTimeout(() => {
  //     this.setState({ new_arry: dd });
  //   }, 1000);
  // };
  monthName = function (dt) {
    const mlist = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return mlist[dt.getMonth()];
  };
  setDownload = (e) => {
    e.preventDefault();
    // window.__CsvTable__ = 1;
    $(".fund_table").attr("id", "role_table22");
    this.initDependency();
    // window.__CsvTable__ = 0;
  };
  handleSelectArea = (e) => {
    this.setState({ area_id: e.target.value });
  };
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  handleCheck = (e) => {
    // e.preventDefault();
    this.setState((prevState, nextProps) => {
      return {
        checkarea: 0,
        checkregion: 0,
        checkstate: 0,
      };
    });
    this.setState({
      [e.target.id]: this.state[e.target.id] === 1 ? 0 : 1,
    });
  };
  dateConv = (date) => {
    return new Date(date).toDateString();
  };
  componentWillUnmount() {
    this.props.resetReportState();
  }
  getNum = (num) => {
    return parseInt(num) ? parseInt(num) : 0;
  };
  render() {
    const { spinner, message, status, preference_spinner } = this.props;
    const { camp_performance } = this.state;

    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>REPORT</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Monthly Camp Performance
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Monthly Camp Performance
              </h3>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row  ">
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="from_date">
                        From Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("from_date"),
                          "form-control": true,
                        })}
                        id="from_date"
                        name="from_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("from_date")}
                    </div>
                  </div>
                  {/* <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="to_date">
                        To Date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("to_date"),
                          "form-control": true,
                        })}
                        id="to_date"
                        name="to_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("to_date")}
                    </div>
                  </div> */}
                  <div className="col-sm-3">
                    <div className="form-group">
                      <label htmlFor="camp_id">Camp</label>
                      <div className="d-flex">
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("camp_id"),
                            "form-control": true,
                            memberselect22: true,
                          })}
                          placeholder="Select a Camp"
                          onChange={this.handleChange}
                          value={this.state.camp_id}
                          id="camp_id"
                          name="camp_id"
                          style={{
                            width: "100%",
                          }}
                        >
                          <option value="">--Select a camp--</option>
                          {this.state.user_camp &&
                            this.state.user_camp.map((camp, index) => (
                              <option key={index} value={camp.id}>
                                {camp.name} -- {camp.area.name} --{" "}
                                {camp.area.region.name} --{" "}
                                {camp.area.region.state.name}
                              </option>
                            ))}
                        </select>
                        {preference_spinner ? <MiniSpinner /> : null}
                      </div>
                      {this.renderErrorFor("camp_id")}
                    </div>
                  </div>
                  {/* <div className=" col-sm-1" style={{ marginTop: "25px" }}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkarea"
                        onChange={this.handleCheck}
                        checked={this.state.checkarea === 1}
                      />
                      <label className="form-check-label" htmlFor="checkarea">
                        Area
                      </label>
                    </div>
                  </div>
                  <div className=" col-sm-1" style={{ marginTop: "25px" }}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkregion"
                        onChange={this.handleCheck}
                        checked={this.state.checkregion === 1}
                      />
                      <label className="form-check-label" htmlFor="checkregion">
                        Region
                      </label>
                    </div>
                  </div>
                  <div className=" col-sm-1" style={{ marginTop: "25px" }}>
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="checkstate"
                        onChange={this.handleCheck}
                        checked={this.state.checkstate === 1}
                      />
                      <label className="form-check-label" htmlFor="checkstate">
                        State
                      </label>
                    </div>
                  </div> */}
                </div>
                <div className="row mb-4">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    disabled={spinner ? true : false}
                  >
                    Generate
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>

                  {/* {this.state.camp_performance.length ? (
                    <button
                      type="button"
                      className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                      onClick={this.setDownload}
                    >
                      Download
                    </button>
                  ) : null} */}

                  {/* <button
                    type="button"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                    onClick={this.handleDownload}
                  >
                    Download
                  </button> */}
                </div>
              </form>

              {_.isEmpty(camp_performance) === false && !spinner ? (
                <div key="uniqueKey">
                  <table
                    id="fund_table1"
                    className=" fund_table table table-hover  table-striped"
                    style={{
                      width: "100%",
                      marginTop: "5px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>CAMP: {this.state.sel.name}</td>
                        <td>AREA: {this.state.sel.area.name}</td>
                        <td></td>
                        <td>STATE : {this.state.sel.area.region.state.name}</td>
                      </tr>
                      <tr>
                        <td>CAMP NUMBER: {this.state.sel.camp_no}</td>
                        <td>
                          DATE GENERATED: {this.dateConv(this.state.from_date)}
                        </td>
                        <td></td>
                        <td>
                          REPORT FOR THE MONTH OF:
                          {this.monthName(new Date(this.state.from_date))}
                        </td>
                      </tr>
                      <tr>
                        <th>SECTION 1 - MEMBERSHIP </th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      {_.isEmpty(camp_performance.membership) === false ? (
                        <React.Fragment>
                          <tr>
                            <td>Number In Good Standing:</td>
                            <td></td>
                            <td>
                              Gideon:{" "}
                              {this.getNum(
                                camp_performance.membership.good_gideon
                              )}
                            </td>
                            <td>
                              Auxilliary:{" "}
                              {this.getNum(
                                camp_performance.membership.good_aux
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td>Number of Delinquent Members: </td>
                            <td></td>
                            <td>
                              Gideon:{" "}
                              {this.getNum(
                                camp_performance.membership.deli_gideon
                              )}
                            </td>
                            <td>
                              Auxilliary:{" "}
                              {this.getNum(
                                camp_performance.membership.deli_aux
                              )}{" "}
                            </td>
                          </tr>
                          <tr>
                            <td>New Members Participation: </td>
                            <td></td>
                            <td>
                              Gideon:{" "}
                              {this.getNum(
                                camp_performance.membership.new_gideon
                              )}
                            </td>
                            <td>
                              Auxilliary:{" "}
                              {this.getNum(camp_performance.membership.new_aux)}
                            </td>
                          </tr>
                          <tr>
                            <td>Prospects/Observers: </td>
                            <td></td>
                            <td>
                              Gideon:{" "}
                              {this.getNum(
                                camp_performance.membership.prospect_gideon
                              )}
                            </td>
                            <td>
                              Auxilliary:{" "}
                              {this.getNum(
                                camp_performance.membership.prospect_aux
                              )}
                            </td>
                          </tr>
                          <tr>
                            <th>Total: </th>
                            <th></th>
                            <th>
                              Gideon:{" "}
                              {this.getNum(
                                camp_performance.membership.prospect_gideon
                              ) +
                                this.getNum(
                                  camp_performance.membership.new_gideon
                                ) +
                                this.getNum(
                                  camp_performance.membership.good_gideon
                                ) +
                                this.getNum(
                                  camp_performance.membership.deli_gideon
                                )}
                            </th>
                            <th>
                              Auxilliary:{" "}
                              {this.getNum(
                                camp_performance.membership.prospect_aux
                              ) +
                                this.getNum(
                                  camp_performance.membership.new_aux
                                ) +
                                this.getNum(
                                  camp_performance.membership.good_aux
                                ) +
                                this.getNum(
                                  camp_performance.membership.deli_aux
                                )}
                            </th>
                          </tr>
                        </React.Fragment>
                      ) : null}
                      <tr>
                        <th>SECTION 2 - SCRIPTURE REPORT </th>
                        <th></th>
                        <th></th>
                        <th></th>
                      </tr>
                      <tr>
                        <th>SCRIPTURE TYPE</th>
                        <th>RECEIVED STOCK </th>
                        <th>MONTHLY DISTRIBUTION </th>
                        <th>OUTSTANDING WAREHOUSE STOCK BALANCE </th>
                      </tr>
                      {camp_performance.scriptures.length &&
                        camp_performance.scriptures.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{item.name}</td>
                              <td>{item.stock ? item.stock : 0}</td>
                              <td>{item.distributed ? item.distributed : 0}</td>
                              <td>{item.balance ? item.balance : 0}</td>

                              <td></td>
                            </tr>
                          );
                        })}
                      <tr>
                        <th>Total</th>
                        <th>
                          {" "}
                          {camp_performance.scriptures &&
                            camp_performance.scriptures.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.stock)
                                  ? parseFloat(obj.stock)
                                  : 0)
                              );
                            },
                            0)}
                        </th>

                        <th>
                          {" "}
                          {camp_performance.scriptures &&
                            camp_performance.scriptures.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.distributed)
                                  ? parseFloat(obj.distributed)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                        <th>
                          {" "}
                          {camp_performance.scriptures &&
                            camp_performance.scriptures.reduce(function (
                              acc,
                              obj
                            ) {
                              return (
                                acc +
                                (parseFloat(obj.balance)
                                  ? parseFloat(obj.balance)
                                  : 0)
                              );
                            },
                            0)}
                        </th>
                      </tr>
                    </tbody>
                  </table>
                </div>
              ) : null}
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.reports.spinner,
    message: state.reports.message,
    status: state.reports.status,
    errors: state.reports.errors,
    camp_performance: state.reports.camp_performance,
    camps: state.preferences.camps,
    preference_spinner: state.preferences.spinner,
    user_camp: state.preferences.user_camp,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMonthlyPerm: (id, from) => dispatch(getMonthlyPerm(id, from)),
    getAllCamps: () => dispatch(getAllCamps()),
    setAll: () => dispatch(setAll()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    resetReportState: () => resetReportState(dispatch),
    // getAllArea: () => getAllArea(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MonthlyPerm);
