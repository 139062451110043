import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import { connect } from "react-redux";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import {
  getAllMembers,
  adjustMemberShip,
} from "./../../../../store/actions/usersActions";
import $ from "jquery";
import classnames from "classnames";

class ChangeMemberShipRenewal extends Component {
  state = {
    members: null,
    selectedMember: "",
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllMembers } = this.props;
    if (!hasPermission({ mod: "Preferences", action: "UPDATE_MEMBER" })) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      if (this.props.members.length) {
        this.setState({ members: this.props.members });
      } else {
        getAllMembers();
      }
    }
  }

  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.members !== prevState.members) {
      return {
        members: nextProps.members,
      };
    }
    return null;
  }
  handleSubmit = (e) => {
    e.preventDefault();

    let selectedMember = $(".memberselect22").val();
    let expiry_date = $("#birth_date").val();

    // const regex = /([0-9]{3,})/gm;
    // const str = selectedMember;
    // let m;

    // while ((m = regex.exec(str)) !== null) {
    //   // This is necessary to avoid infinite loops with zero-width matches
    //   if (m.index === regex.lastIndex) {
    //     regex.lastIndex++;
    //   }

    //   // The result can be accessed through the `m`-variable.
    //   selectedMember = m[0];
    // }

    if (selectedMember) {
      const creds = {
        id: selectedMember,
        expiry_date,
      };

      //   console.log(creds);
      this.props.adjustMemberShip(creds);
    }
  };

  handleChange = (e) => {
    // alert("here");
    this.setState({ selectedMember: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Adjust members renewal
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Adjust members renewal
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? <Spinner /> : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6 ">
                    <label>
                      Member <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control selected_role memberselect22"
                      placeholder="Select a Member"
                      onChange={this.handleChange}
                      value={this.state.selectedMember}
                      id="mem"
                    >
                      <option value="">--Select a Member--</option>
                      {this.props.members &&
                        this.props.members.map((member) => (
                          <option key={member.login_id} value={member.login_id}>
                            {member.first_name} {member.last_name}{" "}
                            {member.other_name} [{member.login_id}]
                          </option>
                        ))}
                    </select>
                  </div>

                  <div className="form-group col-md-6 ">
                    <div className="form-group">
                      <label htmlFor="birth_date">
                        New expiry date <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("birth_date"),
                          "form-control": true,
                        })}
                        id="birth_date"
                        name="birth_date"
                        readOnly
                        placeholder="Select date"
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("birth_date")}
                    </div>
                  </div>
                </div>

                <div className="row form-group ">
                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold mt-2"
                    disabled={spinner ? true : false}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    message: state.users.message,
    spinner: state.users.spinner,
    errors: state.users.errors,
    status: state.users.status,
    members: state.users.members,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllMembers: () => dispatch(getAllMembers()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    adjustMemberShip: (creds) => adjustMemberShip(dispatch, creds),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeMemberShipRenewal);
