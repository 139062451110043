import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  updateSettings,
  getAllSettings2,
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import MiniSpinner from "./../../../helpers/MiniSpinner";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import $ from "jquery";

class UpdateSettings extends Component {
  state = {};
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "UPDATE_SETTING" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      this.props.getAllSettings2().then((res) => {
        if (res.status === "success") {
          this.setState({ ...res.payload.settings });
        }
      });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.settings !== prevState.settings) {
      return {
        settings: nextProps.settings,
      };
    }
    return null;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    const expiry_date = $("#expiry_date").val();
    const goal_setting_expiry = $("#notification_message_expiry").val();
    const { updateSettings } = this.props;
    this.setState({ expiry_date, goal_setting_expiry }, () => {
      updateSettings(this.state).then((res) => {
        if (res.status === "success") {
        }
      });
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    // console.log(errors);
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };

  render() {
    const { spinner, status, message } = this.props;
    console.log(message, status);
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">Edit Settings</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">Edit Settings</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-4">
                    <label htmlFor="gideon_amount">
                      Gideon Renewal Fee <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("gideon_amount"),
                        "form-control": true,
                      })}
                      id="gideon_amount"
                      name="gideon_amount"
                      value={this.state.gideon_amount || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("gideon_amount")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="aux_amount">
                      Auxilliary Renewal Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("aux_amount"),
                        "form-control": true,
                      })}
                      id="aux_amount"
                      name="aux_amount"
                      value={this.state.aux_amount || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("aux_amount")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="couple_amount">
                      Couples Renewal Fee <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("couple_amount"),
                        "form-control": true,
                      })}
                      id="couple_amount"
                      name="couple_amount"
                      value={this.state.couple_amount || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("couple_amount")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="deliquent_days">
                      Deliquent Days <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("deliquent_days"),
                        "form-control": true,
                      })}
                      id="deliquent_days"
                      name="deliquent_days"
                      value={this.state.deliquent_days || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("deliquent_days")}
                  </div>
                  <div className="form-group col-md-4">
                    <label>
                      Purge Audit Trail Record{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("purge"),
                        "form-control selected_state": true,
                      })}
                      placeholder="Select a State"
                      onChange={this.handleChange}
                      id="purge"
                      value={this.state.purge}
                    >
                      <option value="">--Select--</option>
                      <option value={1}>Enable</option>
                      <option value={0}>Disable</option>
                    </select>
                    {this.renderErrorFor("purge")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="expiry_date">
                      New Calendar Year <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      readOnly
                      className={classnames({
                        "is-invalid": this.hasErrorFor("expiry_date"),
                        "form-control": true,
                      })}
                      id="expiry_date"
                      name="expiry_date"
                      value={this.state.expiry_date || ""}
                    />
                    {this.renderErrorFor("expiry_date")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="days_older_than">
                      Purge Audit Trail Record after{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <input
                        type="number"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("days_older_than"),
                          "form-control": true,
                        })}
                        id="days_older_than"
                        name="days_older_than"
                        value={this.state.days_older_than || ""}
                        onChange={this.handleChange}
                      />
                      <div className="ml-1 mt-2">day(s)</div>
                    </div>
                    {this.renderErrorFor("days_older_than")}
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="gideon_state_convention_fee">
                      Gideon State Convention Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "gideon_state_convention_fee"
                        ),
                        "form-control": true,
                      })}
                      id="gideon_state_convention_fee"
                      name="gideon_state_convention_fee"
                      value={this.state.gideon_state_convention_fee || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("gideon_state_convention_fee")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="aux_state_convention_fee">
                      Auxilliary State Convention Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "aux_state_convention_fee"
                        ),
                        "form-control": true,
                      })}
                      id="aux_state_convention_fee"
                      name="aux_state_convention_fee"
                      value={this.state.aux_state_convention_fee || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("aux_state_convention_fee")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="both_state_convention_fee">
                      Couples State Convention Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "both_state_convention_fee"
                        ),
                        "form-control": true,
                      })}
                      id="both_state_convention_fee"
                      name="both_state_convention_fee"
                      value={this.state.both_state_convention_fee || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("both_state_convention_fee")}
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="gideon_national_convention_fee">
                      Gideon National Convention Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "gideon_national_convention_fee"
                        ),
                        "form-control": true,
                      })}
                      id="gideon_national_convention_fee"
                      name="gideon_national_convention_fee"
                      value={this.state.gideon_national_convention_fee || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("gideon_national_convention_fee")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="aux_national_convention_fee">
                      Auxilliary National Convention Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "aux_national_convention_fee"
                        ),
                        "form-control": true,
                      })}
                      id="aux_national_convention_fee"
                      name="aux_national_convention_fee"
                      value={this.state.aux_national_convention_fee || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("aux_national_convention_fee")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="both_national_convention_fee">
                      Couples National Convention Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "both_national_convention_fee"
                        ),
                        "form-control": true,
                      })}
                      id="both_national_convention_fee"
                      name="both_national_convention_fee"
                      value={this.state.both_national_convention_fee || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("both_national_convention_fee")}
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="gideon_international_convention_fee">
                      Gideon International Convention Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "gideon_international_convention_fee"
                        ),
                        "form-control": true,
                      })}
                      id="gideon_international_convention_fee"
                      name="gideon_international_convention_fee"
                      value={
                        this.state.gideon_international_convention_fee || ""
                      }
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("gideon_international_convention_fee")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="aux_international_convention_fee">
                      Auxilliary International Convention Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "aux_international_convention_fee"
                        ),
                        "form-control": true,
                      })}
                      id="aux_international_convention_fee"
                      name="aux_international_convention_fee"
                      value={this.state.aux_international_convention_fee || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("aux_international_convention_fee")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="both_international_convention_fee">
                      Couples International Convention Fee{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "both_international_convention_fee"
                        ),
                        "form-control": true,
                      })}
                      id="both_international_convention_fee"
                      name="both_international_convention_fee"
                      value={this.state.both_international_convention_fee || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("both_international_convention_fee")}
                  </div>

                  <div className="form-group col-md-4">
                    <label htmlFor="international_theme">
                      International Theme <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("international_theme"),
                        "form-control": true,
                      })}
                      id="international_theme"
                      name="international_theme"
                      value={this.state.international_theme || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("international_theme")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="international_number">
                      International Number{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("international_number"),
                        "form-control": true,
                      })}
                      id="international_number"
                      name="international_number"
                      value={this.state.international_number || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("international_number")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="national_theme">
                      National Theme <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("national_theme"),
                        "form-control": true,
                      })}
                      id="national_theme"
                      name="national_theme"
                      value={this.state.national_theme || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("national_theme")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="national_number">
                      National Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("national_number"),
                        "form-control": true,
                      })}
                      id="national_number"
                      name="national_number"
                      value={this.state.national_number || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("national_number")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="state_theme">
                      State Theme <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("state_theme"),
                        "form-control": true,
                      })}
                      id="state_theme"
                      name="state_theme"
                      value={this.state.state_theme || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("state_theme")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="state_number">
                      State Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("state_number"),
                        "form-control": true,
                      })}
                      id="state_number"
                      name="state_number"
                      value={this.state.state_number || ""}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("state_number")}
                  </div>
                  <div className="form-group col-md-4">
                    <label htmlFor="state_number">
                      Goal Setting Expiration{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      readOnly
                      className={classnames({
                        "is-invalid": this.hasErrorFor(
                          "notification_message_expiry"
                        ),
                        "form-control": true,
                      })}
                      id="notification_message_expiry"
                      name="notification_message_expiry"
                      value={this.state.goal_setting_expiry || ""}
                    />
                    {this.renderErrorFor("notification_message_expiry")}
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  UPDATE
                  {spinner ? <MiniSpinner color="white" /> : null}
                </button>

                <Link
                  to="/preferences/settings"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                >
                  View Settings
                </Link>
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    errors: state.preferences.errors,
    settings: state.preferences.settings,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllSettings2: () => getAllSettings2(dispatch),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    updateSettings: (creds) => updateSettings(dispatch, creds),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateSettings);
