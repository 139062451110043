import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  getAllBibles,
  removeDepotInventory,
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import $ from "jquery";
import { storage_type, user_auth_details, dehash } from "../../../../config";

class RemoveDepotInventory extends Component {
  state = {
    depot: null, //Added by Macdonald
    stock: "",
    bible_id: "",
    bibles: null,
    comment: "",
    image: "",
    depot_id: '' //Added by Macdonald
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "VIEW_DEPOT_INVENTORY" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      if (storage_type.getItem(user_auth_details)) {
        const user = JSON.parse(
          dehash(`${storage_type.getItem(user_auth_details)}`)
        );
        this.setState({ depot: user.depots });
      }
      this.props.getAllBibles();
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.bibles !== prevState.bibles) {
      return {
        bibles: nextProps.bibles,
      };
    }
    return null;
  }

   //---------------------CODE ADDED BY MACDONALD------------------------
   handleDepotChange = (e) => {
    this.setState({
        depot_id: e.target.value
    });
   window.$depotID = e.target.value;
   //console.log("Depot ID: ", window.$depotID);
}
//---------------------------------END------------------------------------

  handleFileChange = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { removeDepotInventory } = this.props;
    const creds = {
      bible_id: this.state.bible_id,
      stock: this.state.stock,
      comment: this.state.comment,
      image: this.state.image,
      depotId: window.$depotID    //this.state.depot_Id //Added by Macdonald
    };
    console.log("Depot ID from Global Variable: ", creds.depotId);
    removeDepotInventory(creds).then((res) => {
      if (res.status === "success") {
        this.setState({
          stock: "",
          bible_id: "",
          comment: "",
          image: "",
        });
        $(".selected_state").val("");
        $("#image").val("");
      }
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Remove Depot Inventory
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
            <h3 className="card-title font-weight-bold">
                {/* <form onSubmit={this.handleSubmit}> */}
                    <div className='row'>
                        <div className="form-group col-md-12">
                            <label>
                                Select From Your Depots <span className="text-danger"> *</span>
                            </label>
                            {'  '}
                            <select
                                placeholder="Select"
                                onChange={this.handleDepotChange}
                                id="depot_id"
                                value={this.state.depot_id}
                            >
                            <option value="">--Select--</option>
                                {this.state.depot &&
                                this.state.depot.map((depot, index) => (
                                    <option key={index} value={depot.id}>
                                    {depot.name} - {depot.address}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {/* {this.state.depots ? `(${this.state.depots[0].name})` : null} */}
                {/* </form> */}
              </h3>
              {/* <h3 className="card-title font-weight-bold ">
                Remove Depot Inventory{" "}
                {this.state.depot ? `(${this.state.depot.name})` : null}
              </h3> */}
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Stock Items <span className="text-danger">*</span>
                    </label>
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("bible_id"),
                        "form-control selected_state": true,
                      })}
                      placeholder="Select a State"
                      onChange={this.handleChange}
                      id="bible_id"
                      defaultValue={this.state.bible_id}
                    >
                      <option value="">--Select--</option>
                      {this.state.bibles &&
                        this.state.bibles.map((bible, index) => (
                          <option key={index} value={bible.id}>
                            {bible.name}
                          </option>
                        ))}
                    </select>
                    {this.renderErrorFor("bible_id")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="stock">
                      Stock Quantity <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("stock"),
                        "form-control": true,
                      })}
                      id="stock"
                      name="stock"
                      value={this.state.stock}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("stock")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="comment">
                      Comment <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className={classnames({
                        "is-invalid": this.hasErrorFor("comment"),
                        "form-control": true,
                      })}
                      id="comment"
                      name="comment"
                      value={this.state.comment}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("comment")}
                  </div>
                  <div className=" col-6 mx-auto">
                    <div className="form-group">
                      <label htmlFor="exampleInputFile">
                        {" "}
                        Attach image, if any{" "}
                      </label>
                      <input
                        type="file"
                        id="image"
                        onChange={this.handleFileChange}
                      />
                      <br />
                      <small>
                        <b>
                          File types expected are png,jpeg and jpg not greater
                          than 2MB
                        </b>
                      </small>
                      {this.renderErrorFor("image")}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Remove
                </button>
                <Link
                  to="/preferences/depot-inventory"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                >
                  View Inventory
                </Link>
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    bibles: state.preferences.bibles,
    errors: state.preferences.errors,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllBibles: () => dispatch(getAllBibles()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    removeDepotInventory: (creds) => removeDepotInventory(dispatch, creds),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RemoveDepotInventory);
