import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {
  submitReportActivityCon,
  resetUsersState,
  cancelRemitaConvention,
  showSuccessMessage,
} from "../../../../store/actions/usersActions";
import queryString from "query-string";
import Spinner from "../../../helpers/Spinner";
import Message from "../../../helpers/Message";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import classnames from "classnames";
import $ from "jquery";
import MiniSpinner from "../../../helpers/MiniSpinner";
import {
  getAllSettings2,
  getAllStates,
  getOneCampActivityCon,
} from "./../../../../store/actions/preferencesActions";
import { user_auth_details, dehash, storage_type } from "../../../../config";

class CreateReportCon extends Component {
  state = {
    creds: {},
    states: null,
    modal_2: false,
    to_send: {
      recommend: {},
      born: {},
      relations: {},
      members: {},
      meetings: {},
      auxiliary: {},
      gideons: {},
      trustees: {},
    },
    modal_1: false,
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_CONVENTION_REPORT" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    }
    let params = queryString.parse(this.props.location.search);
    if (params.status === "success") {
      this.props.showSuccessMessage();
    }
    if (params.message && params.message === "Approved") {
      this.setState({
        ...params,
        modal_1: true,
        info: "Transaction Successful",
      });
    }
    if (params.message && params.message !== "Approved") {
      this.setState({ ...params, modal_1: true, info: "Transaction Failed" });
    }
    this.initDependency();
    this.props.getAllStates();
    this.props.getAllSettings2().then((res) => {
      if (res.status === "success") {
        this.setState({ ...res.payload.settings }, () => {
          this.setState({
            creds: {
              ...this.state.creds,
              // gideon_fee: "",
              // aux_fee: "",
              // both_fee: "",
            },
          });
        });
      }
    });
    const id = this.props.match.params.id;
    this.props.getOneCampActivityCon(id).then((res) => {
      if (res.status === "success") {
        this.setState(
          {
            creds: { ...this.state.creds, ...res.payload.convention },
          },
          () => {
            $("#start_date").val(res.payload.convention.start_date);
            $("#end_date").val(res.payload.convention.end_date);
            if (this.state.creds.type === "state") {
              this.setState({
                creds: {
                  ...this.state.creds,
                  gideon_fee: this.state.gideon_state_convention_fee,
                  aux_fee: this.state.aux_state_convention_fee,
                  both_fee: this.state.both_state_convention_fee,
                },
              });
            }
            if (this.state.creds.type === "national") {
              this.setState({
                creds: {
                  ...this.state.creds,
                  gideon_fee: this.state.gideon_national_convention_fee,
                  aux_fee: this.state.aux_national_convention_fee,
                  both_fee: this.state.both_national_convention_fee,
                },
              });
            }
            if (this.state.creds.type === "international") {
              this.setState({
                creds: {
                  ...this.state.creds,
                  gideon_fee: this.state.gideon_international_convention_fee,
                  aux_fee: this.state.aux_international_convention_fee,
                  both_fee: this.state.both_international_convention_fee,
                },
              });
            }
          }
        );
      }
    });
    if (storage_type.getItem(user_auth_details)) {
      const user = JSON.parse(
        dehash(`${storage_type.getItem(user_auth_details)}`)
      );
      this.setState({ user }, () => {
        this.setState({
          to_send: {
            ...this.state.to_send,
            type: this.state.user.type,
            id: this.props.match.params.id,
          },
        });
      });
    }
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.mem_state_area !== prevState.mem_state_area ||
      nextProps.s_campFromArea !== prevState.s_campFromArea ||
      nextProps.mem_state_region !== prevState.mem_state_region ||
      nextProps.states !== prevState.states ||
      nextProps.mem_state !== prevState.mem_state
    ) {
      return {
        s_campFromArea: nextProps.s_campFromArea,
        mem_state_area: nextProps.mem_state_area,
        mem_state_region: nextProps.mem_state_region,
        states: nextProps.states,
        mem_state: nextProps.mem_state,
      };
    }
    return null;
  }
  componentWillUnmount() {
    this.props.resetUsersState();
  }
  handleChange = (e) => {
    const { target } = e;
    this.setState(
      {
        to_send: { ...this.state.to_send, [e.target.id]: e.target.value },
      },
      () => {
        if (target.id === "type") {
          if (target.value === "national" || target.value === "international") {
            this.setState({ creds: { ...this.state.creds, state_id: "" } });
          }
        }
        if (target.id === "type" && target.value === "national") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.national_theme,
              number: this.state.national_number,
            },
          });
        }
        if (target.id === "type" && target.value === "international") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.international_theme,
              number: this.state.international_number,
            },
          });
        }
        if (target.id === "type" && target.value === "state") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: this.state.state_theme,
              number: this.state.state_number,
            },
          });
        }
        if (target.id === "type" && target.value === "") {
          this.setState({
            creds: {
              ...this.state.creds,
              theme: "",
              number: "",
            },
          });
        }
      }
    );

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleChange_1 = (e) => {
    // const { target } = e;
    this.setState({
      to_send: {
        ...this.state.to_send,
        recommend: {
          ...this.state.to_send.recommend,
          [e.target.id]: e.target.value,
        },
      },
    });

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleChange_2 = (e) => {
    // const { target } = e;
    this.setState({
      to_send: {
        ...this.state.to_send,
        born: {
          ...this.state.to_send.born,
          [e.target.id]: e.target.value,
        },
      },
    });

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleChange_3 = (e) => {
    // const { target } = e;
    this.setState({
      to_send: {
        ...this.state.to_send,
        relations: {
          ...this.state.to_send.relations,
          [e.target.id]: e.target.value,
        },
      },
    });

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleChange_4 = (e) => {
    // const { target } = e;
    this.setState({
      to_send: {
        ...this.state.to_send,
        members: {
          ...this.state.to_send.members,
          [e.target.id]: e.target.value,
        },
      },
    });

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleChange_5 = (e) => {
    // const { target } = e;
    this.setState({
      to_send: {
        ...this.state.to_send,
        meetings: {
          ...this.state.to_send.meetings,
          [e.target.id]: e.target.value,
        },
      },
    });

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleChange_6 = (e) => {
    // const { target } = e;
    this.setState({
      to_send: {
        ...this.state.to_send,
        auxiliary: {
          ...this.state.to_send.auxiliary,
          [e.target.id]: e.target.value,
        },
      },
    });

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleChange_7 = (e) => {
    // const { target } = e;
    this.setState({
      to_send: {
        ...this.state.to_send,
        gideons: {
          ...this.state.to_send.gideons,
          [e.target.id]: e.target.value,
        },
      },
    });

    // this.setState({ [e.target.id]: e.target.value });
  };
  handleChange_trustees = (e) => {
    // const { target } = e;
    this.setState({
      to_send: {
        ...this.state.to_send,
        trustees: {
          ...this.state.to_send.trustees,
          [e.target.id]: e.target.value,
        },
      },
    });
    // this.setState({ [e.target.id]: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const start_date = $("#start_date").val();
    const end_date = $("#end_date").val();
    const member_plan_date = $("#member_plan_date").val();
    const second_member_plan_date = $("#second_member_plan_date").val();
    const date_mem_1 = $("#date_mem_1").val();
    const date_mem_2 = $("#date_mem_2").val();
    const date_mem_3 = $("#date_mem_3").val();
    const date_mem_4 = $("#date_mem_4").val();
    const date_mem_5 = $("#date_mem_5").val();
    const date_mem_6 = $("#date_mem_6").val();
    const date_scheduled_1 = $("#date_scheduled_1").val();
    const date_scheduled_2 = $("#date_scheduled_2").val();
    const date_scheduled_3 = $("#date_scheduled_3").val();
    const date_scheduled_4 = $("#date_scheduled_4").val();
    const date_scheduled_5 = $("#date_scheduled_5").val();
    const date_scheduled_6 = $("#date_scheduled_6").val();
    const date_scheduled_7 = $("#date_scheduled_7").val();
    const date_scheduled_8 = $("#date_scheduled_8").val();
    const date_scheduled_9 = $("#date_scheduled_9").val();
    const date_scheduled_10 = $("#date_scheduled_10").val();
    const date_first_conv = $("#date_first_conv").val();
    const president_dated = $("#president_dated").val();
    const meeting_date = $("#meeting_date").val();
    const creds = { ...this.state.creds };
    if (!creds.state_id) {
      delete creds.state_id;
    }
    this.setState(
      {
        creds: { ...creds, start_date, end_date },
        to_send: {
          ...this.state.to_send,
          member_plan_date,
          second_member_plan_date,
          date_first_conv,
          president_dated,
          relations: {
            ...this.state.to_send.relations,
            date_scheduled_1,
            date_scheduled_2,
            date_scheduled_3,
            date_scheduled_4,
            date_scheduled_5,
            date_scheduled_6,
            date_scheduled_7,
            date_scheduled_8,
            date_scheduled_9,
            date_scheduled_10,
          },
          members: {
            ...this.state.to_send.members,
            date_mem_1,
            date_mem_2,
            date_mem_3,
            date_mem_4,
            date_mem_5,
            date_mem_6,
          },
          meetings: {
            ...this.state.to_send.meetings,
            meeting_date,
          },
        },
      },
      () => {
        this.props.submitReportActivityCon(this.state.to_send).then((res) => {
          if (res.status === "success") {
            this.setState(
              {
                to_send: { ...this.state.to_send, attendance: "" },
              },
              (obj = res.payload) => {
                this.setState({ ...obj }, () => {
                  this.setState({ modal_2: false });
                });
              }
            );
          }
        });
      }
    );
  };
  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  moneyConv = (price) => {
    return parseFloat(price)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  closeModal_2 = (e) => {
    e.preventDefault();
    const rrr = this.state.rrr;
    this.props.cancelRemitaConvention(rrr).then((res) => {
      if (res.status === "success") {
        this.setState({
          modal_2: false,
          merchantId: "",
          rrr: "",
          response_url: "",
          action_url: "",
          hash: "",
        });
      }
    });
    // this.props.resetUsersState();
  };
  covMoney = (amount) => {
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  closeModal_1 = (e) => {
    e.preventDefault();
    this.setState({ modal_1: false });
  };
  goBack = (e) => {
    e.preventDefault();
    this.props.history.goBack();
  };

  render() {
    const {
      spinner,
      message,
      status,
      spinner1,
      message1,
      status1,
    } = this.props;
    if (
      (!spinner && status !== "error") ||
      (!spinner1 && status1 !== "error")
    ) {
      this.initDependency();
    }

    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Create Convention Report
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Create Convention Report
              </h3>

              <div className="card-tools"></div>
            </div>
            {this.state.modal_2 ? (
              <div
                className="opptry"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999999999999999999999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  // padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center",

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form action={this.state.action_url} method="POST">
                    <h4 className="text-center mt-3">
                      Pay &#8358; {this.covMoney(this.state.amount)} with Remita{" "}
                      <img
                        src="/dist/img/remita.png"
                        alt=""
                        width="50"
                        style={{ position: "relative", top: "-1px" }}
                      />
                    </h4>
                    <p className="text-center text-danger ">
                      <small className="font-weight-bold">
                        Please note that you may be charge proccessing fee of
                        &#8358; {this.covMoney(100)}{" "}
                      </small>
                    </p>
                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <input
                        id="merchantId"
                        name="merchantId"
                        value={this.state.merchantId}
                        type="hidden"
                      />
                      <input
                        id="rrr"
                        name="rrr"
                        value={this.state.rrr}
                        type="hidden"
                      />
                      <input
                        id="responseurl"
                        name="responseurl"
                        value={this.state.response_url}
                        type="hidden"
                      />
                      <input
                        id="hash"
                        name="hash"
                        value={this.state.hash}
                        type="hidden"
                      />
                      {/* <div className="form-group">
                            <label htmlFor="comment">
                              Comment <span className="text-danger">*</span>{" "}
                            </label>
                            <textarea
                              className={classnames({
                                "is-invalid": this.hasErrorFor("comment"),
                                "form-control": true
                              })}
                              id="comment"
                              name="comment"
                              rows={2}
                              onChange={this.handleChange}
                              value={this.state.comment}
                            />

                            {this.renderErrorFor("comment")}
                          </div> */}

                      <button
                        // type="submit"
                        className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                      >
                        Pay
                      </button>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_2}
                      >
                        Cancel
                        {spinner || spinner1 ? <MiniSpinner /> : null}
                      </button>
                    </div>
                  </form>
                </div>

                {/* /.card-body */}
              </div>
            ) : null}

            {this.state.modal_1 ? (
              <div
                className="opptry"
                //   style={{ display: "absolute", top: 0, bottom: 0, right: 0, left: 0 }}
                style={{
                  position: "fixed",
                  top: "0px",
                  right: "0px",
                  bottom: "0px",
                  left: "0px",
                  zIndex: 9999999,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  // padding: "2%",
                  display: "flex",
                  justifyContent: "center",
                  overflowX: "hidden",

                  alignItems: "center",

                  // transition: "all 10s ease"
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#fff",
                    height: "300px",
                    overflowX: "hidden",
                  }}
                >
                  <form>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <h4
                      // className="text-center mt-3"
                      >
                        {this.state.info}{" "}
                      </h4>
                      <i
                        style={{
                          fontSize: "1.6rem",
                          color: "#bda230",
                        }}
                        className={classnames({
                          "fas fa-check": this.state.message === "Approved",
                          "fas fa-exclamation-triangle":
                            this.state.message !== "Approved",
                        })}
                      ></i>
                    </div>

                    <hr />
                    <div className="col-sm-6 offset-sm-3 ">
                      <p>
                        <strong>
                          {" "}
                          Remita Retrieval Reference (rrr): {
                            this.state.rrr
                          }{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <p>
                        <strong>
                          {" "}
                          Order ID : {this.state.orderId}{" "}
                          <small>
                            <em>always keep this number safe.</em>
                          </small>
                        </strong>
                      </p>
                      <button
                        className="btn btn-default btn-sm text-uppercase font-weight-bold ml-2"
                        disabled={spinner ? true : false}
                        onClick={this.closeModal_1}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                  {/* <hr /> */}
                  {/* <div className="text-center">
                   
                  </div> */}
                </div>

                {/* /.card-body */}
              </div>
            ) : null}
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {message1 && status1 ? (
                <Message message={message1} status={status1} />
              ) : null}
              {spinner ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              {spinner1 ? (
                <div>
                  <Spinner />
                </div>
              ) : null}
              <div>
                <form onSubmit={this.handleSubmit}>
                  <div className="row">
                    <div className="form-group col-md-4">
                      <label>Type : </label>&nbsp;{" "}
                      {this.state.creds.type
                        ? this.state.creds.type.toUpperCase()
                        : ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="theme">Convention Theme : </label>&nbsp;
                      {this.state.creds.theme || ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="number">Convention Number : </label>&nbsp;
                      {this.state.creds.number || ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="gideon_fee">
                        Gideon Convention Fee :
                      </label>
                      &#8358;{" "}
                      {this.state.creds.gideon_fee &&
                        this.moneyConv(this.state.creds.gideon_fee)}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="aux_fee">
                        Auxilliary Convention Fee :
                      </label>
                      &nbsp; &#8358;
                      {this.state.creds.aux_fee &&
                        this.moneyConv(this.state.creds.aux_fee)}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="both_fee">Couples Convention Fee </label>{" "}
                      &nbsp; &#8358;
                      {this.state.creds.both_fee &&
                        this.moneyConv(this.state.creds.both_fee)}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="start_date">State Date :</label> &nbsp;
                      {this.state.creds.start_date || ""}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="end_date">End Date :</label>&nbsp;
                      {this.state.creds.end_date || ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="address">Address</label>
                      &nbsp;
                      {this.state.creds.address || ""}
                    </div>

                    <div className="form-group col-md-4">
                      <label htmlFor="admin_fund">Admin Fund</label>&nbsp;
                      &#8358;
                      {this.state.creds.admin_fund &&
                        this.moneyConv(this.state.creds.admin_fund)}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="scripture_fund">Scripture Fund :</label>{" "}
                      &nbsp; &#8358;
                      {this.state.creds.scripture_fund &&
                        this.moneyConv(this.state.creds.scripture_fund)}
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="city">City :</label>
                      {this.state.creds.city || ""}
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="state">State :</label> &nbsp;
                        {this.state.creds.state || ""}
                      </div>
                    </div>
                    <div className="form-group col-md-4">
                      <label htmlFor="country">Country :</label>&nbsp;
                      {this.state.creds.country || ""}
                    </div>

                    {/* <div className="form-group col-md-4"> */}
                    {/* <label htmlFor="location">
                        No of prospects<span className="text-danger">*</span>
                      </label> */}
                    {/* <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("location"),
                          "form-control": true,
                        })}
                        id="location"
                        name="location"
                        value={this.state.location}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("location")}
                    </div> */}
                    {/* <div className="form-group col-md-4">
                      <label htmlFor="funding">
                        No of Sign-Up<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="funding"
                        name="funding"
                        value={this.state.funding}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("funding")}
                    </div> */}
                    {/* <div className="form-group col-md-4">
                      <label htmlFor="speaker">
                        Amount Collected<span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className={classnames("form-control", {
                          "is-invalid": this.hasErrorFor("location"),
                        })}
                        id="speaker"
                        name="speaker"
                        value={this.state.speaker}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("speaker")}
                    </div> */}
                  </div>
                  <hr />
                  <div className="row">
                    {/* <div className="col-sm-6">
                      <div className="form-group">
                        <label htmlFor="type">
                          Type<span className="text-danger">*</span>
                        </label>
                        <select
                          className={classnames({
                            "is-invalid": this.hasErrorFor("type"),
                            "form-control": true,
                          })}
                          id="type"
                          name="type"
                          onChange={this.handleChange}
                          value={this.state.to_send.type || ""}
                        >
                          {this.state.user && this.state.user.type === "G" ? (
                            <option value="G">Gideon</option>
                          ) : (
                            <option value="A">Auxilliary</option>
                          )}
                          <option value="C">Gideon & Auxilliary </option>
                        </select>
                        {this.renderErrorFor("type")}
                      </div>
                    </div> */}
                    <div className="form-group col-md-6">
                      <label htmlFor="facility_adequate">
                        Were facilities adequate?{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("facility_adequate"),
                          "form-control": true,
                        })}
                        id="facility_adequate"
                        name="facility_adequate"
                        value={this.state.to_send.facility_adequate || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("facility_adequate")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="facility_adequate_explain">
                        if No, explain{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor(
                            "facility_adequate_explain"
                          ),
                          "form-control": true,
                        })}
                        id="facility_adequate_explain"
                        name="facility_adequate_explain"
                        value={
                          this.state.to_send.facility_adequate_explain || ""
                        }
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("facility_adequate_explain")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="actual_admin_offering">
                        Actual Admin Fund <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor(
                            "actual_admin_offering"
                          ),
                          "form-control": true,
                        })}
                        id="actual_admin_offering"
                        name="actual_admin_offering"
                        value={this.state.to_send.actual_admin_offering || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("actual_admin_offering")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="actual_scripture_offering">
                        Actual Scripture Fund{" "}
                        <span className="text-danger">*</span>{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor(
                            "actual_scripture_offering"
                          ),
                          "form-control": true,
                        })}
                        id="actual_scripture_offering"
                        name="actual_scripture_offering"
                        value={
                          this.state.to_send.actual_scripture_offering || ""
                        }
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("actual_scripture_offering")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="friday_banquet">Friday Banquet </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("friday_banquet"),
                          "form-control": true,
                        })}
                        id="friday_banquet"
                        name="friday_banquet"
                        value={this.state.to_send.friday_banquet || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("friday_banquet")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="president_breakfast">
                        President Breakfast{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("president_breakfast"),
                          "form-control": true,
                        })}
                        id="president_breakfast"
                        name="president_breakfast"
                        value={this.state.to_send.president_breakfast || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("president_breakfast")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="auxiliary_luncheon">
                        Auxiliary Luncheon{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("auxiliary_luncheon"),
                          "form-control": true,
                        })}
                        id="auxiliary_luncheon"
                        name="auxiliary_luncheon"
                        value={this.state.to_send.auxiliary_luncheon || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("auxiliary_luncheon")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="saturday_banquet">
                        Saturday Banquet{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("saturday_banquet"),
                          "form-control": true,
                        })}
                        id="saturday_banquet"
                        name="saturday_banquet"
                        value={this.state.to_send.saturday_banquet || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("saturday_banquet")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="church_receipts">Church Receipts </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("church_receipts"),
                          "form-control": true,
                        })}
                        id="church_receipts"
                        name="church_receipts"
                        value={this.state.to_send.church_receipts || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("church_receipts")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="total_realized"> Total Realized </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("total_realized"),
                          "form-control": true,
                        })}
                        id="total_realized"
                        name="total_realized"
                        value={this.state.to_send.total_realized || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("total_realized")}
                    </div>

                    <div className="form-group col-md-6">
                      <label htmlFor="service_taken">
                        {" "}
                        No of service taken{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("service_taken"),
                          "form-control": true,
                        })}
                        id="service_taken"
                        name="service_taken"
                        value={this.state.to_send.service_taken || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("service_taken")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="service_booked">
                        {" "}
                        No of Service Booked{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("service_booked"),
                          "form-control": true,
                        })}
                        id="service_booked"
                        name="service_booked"
                        value={this.state.to_send.service_booked || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("service_booked")}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th colspan="2">Attendance</th>
                            <th>Kick-Off</th>
                            <th>Mid-Morning</th>
                            <th>Afternoon</th>
                            <th>Evening</th>
                            <th>Burden Brearers</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <th>Friday</th>
                            <td>Gideon</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_gideon_kick_off"
                                  ),
                                })}
                                id="friday_gideon_kick_off"
                                name="friday_gideon_kick_off"
                                value={
                                  this.state.to_send.friday_gideon_kick_off ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_gideon_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_gideon_mid_morning"
                                  ),
                                })}
                                id="friday_gideon_mid_morning"
                                name="friday_gideon_mid_morning"
                                value={
                                  this.state.to_send
                                    .friday_gideon_mid_morning || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_gideon_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_gideon_afternoon"
                                  ),
                                })}
                                id="friday_gideon_afternoon"
                                name="friday_gideon_afternoon"
                                value={
                                  this.state.to_send.friday_gideon_afternoon ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_gideon_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_gideon_evening"
                                  ),
                                })}
                                id="friday_gideon_evening"
                                name="friday_gideon_evening"
                                value={
                                  this.state.to_send.friday_gideon_evening || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_gideon_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_gideon_burden_bearers"
                                  ),
                                })}
                                id="friday_gideon_burden_bearers"
                                name="friday_gideon_burden_bearers"
                                value={
                                  this.state.to_send
                                    .friday_gideon_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "friday_gideon_burden_bearers"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Auxilliary</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_aux_kick_off"
                                  ),
                                })}
                                id="friday_aux_kick_off"
                                name="friday_aux_kick_off"
                                value={
                                  this.state.to_send.friday_aux_kick_off || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_aux_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_aux_mid_morning"
                                  ),
                                })}
                                id="friday_aux_mid_morning"
                                name="friday_aux_mid_morning"
                                value={
                                  this.state.to_send.friday_aux_mid_morning ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_aux_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_aux_afternoon"
                                  ),
                                })}
                                id="friday_aux_afternoon"
                                name="friday_aux_afternoon"
                                value={
                                  this.state.to_send.friday_aux_afternoon || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_aux_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_aux_evening"
                                  ),
                                })}
                                id="friday_aux_evening"
                                name="friday_aux_evening"
                                value={
                                  this.state.to_send.friday_aux_evening || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_aux_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_aux_burden_bearers"
                                  ),
                                })}
                                id="friday_aux_burden_bearers"
                                name="friday_aux_burden_bearers"
                                value={
                                  this.state.to_send
                                    .friday_aux_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_aux_burden_bearers")}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Guest</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_guest_kick_off"
                                  ),
                                })}
                                id="friday_guest_kick_off"
                                name="friday_guest_kick_off"
                                value={
                                  this.state.to_send.friday_guest_kick_off || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_guest_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_guest_mid_morning"
                                  ),
                                })}
                                id="friday_guest_mid_morning"
                                name="friday_guest_mid_morning"
                                value={
                                  this.state.to_send.friday_guest_mid_morning ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_guest_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_guest_afternoon"
                                  ),
                                })}
                                id="friday_guest_afternoon"
                                name="friday_guest_afternoon"
                                value={
                                  this.state.to_send.friday_guest_afternoon ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_guest_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_guest_evening"
                                  ),
                                })}
                                id="friday_guest_evening"
                                name="friday_guest_evening"
                                value={
                                  this.state.to_send.friday_guest_evening || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_guest_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_guest_burden_bearers"
                                  ),
                                })}
                                id="friday_guest_burden_bearers"
                                name="friday_guest_burden_bearers"
                                value={
                                  this.state.to_send
                                    .friday_guest_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "friday_guest_burden_bearers"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Youth</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_youth_kick_off"
                                  ),
                                })}
                                id="friday_youth_kick_off"
                                name="friday_youth_kick_off"
                                value={
                                  this.state.to_send.friday_youth_kick_off || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_youth_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_youth_mid_morning"
                                  ),
                                })}
                                id="friday_youth_mid_morning"
                                name="friday_youth_mid_morning"
                                value={
                                  this.state.to_send.friday_youth_mid_morning ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_youth_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_youth_afternoon"
                                  ),
                                })}
                                id="friday_youth_afternoon"
                                name="friday_youth_afternoon"
                                value={
                                  this.state.to_send.friday_youth_afternoon ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_youth_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_youth_evening"
                                  ),
                                })}
                                id="friday_youth_evening"
                                name="friday_youth_evening"
                                value={
                                  this.state.to_send.friday_youth_evening || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_youth_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_youth_burden_bearers"
                                  ),
                                })}
                                id="friday_youth_burden_bearers"
                                name="friday_youth_burden_bearers"
                                value={
                                  this.state.to_send
                                    .friday_youth_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "friday_youth_burden_bearers"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Children</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_children_kick_off"
                                  ),
                                })}
                                id="friday_children_kick_off"
                                name="friday_children_kick_off"
                                value={
                                  this.state.to_send.friday_children_kick_off ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_children_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_children_mid_morning"
                                  ),
                                })}
                                id="friday_children_mid_morning"
                                name="friday_children_mid_morning"
                                value={
                                  this.state.to_send
                                    .friday_children_mid_morning || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "friday_children_mid_morning"
                              )}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_children_afternoon"
                                  ),
                                })}
                                id="friday_children_afternoon"
                                name="friday_children_afternoon"
                                value={
                                  this.state.to_send
                                    .friday_children_afternoon || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_children_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_children_evening"
                                  ),
                                })}
                                id="friday_children_evening"
                                name="friday_children_evening"
                                value={
                                  this.state.to_send.friday_children_evening ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("friday_children_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "friday_children_burden_bearers"
                                  ),
                                })}
                                id="friday_children_burden_bearers"
                                name="friday_children_burden_bearers"
                                value={
                                  this.state.to_send
                                    .friday_children_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "friday_children_burden_bearers"
                              )}
                            </td>
                          </tr>

                          <tr className="py-5 ">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr>
                            <th>Saturday</th>
                            <td>Gideon</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_gideon_kick_off"
                                  ),
                                })}
                                id="satur_gideon_kick_off"
                                name="satur_gideon_kick_off"
                                value={
                                  this.state.to_send.satur_gideon_kick_off || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_gideon_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_gideon_mid_morning"
                                  ),
                                })}
                                id="satur_gideon_mid_morning"
                                name="satur_gideon_mid_morning"
                                value={
                                  this.state.to_send.satur_gideon_mid_morning ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_gideon_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_gideon_afternoon"
                                  ),
                                })}
                                id="satur_gideon_afternoon"
                                name="satur_gideon_afternoon"
                                value={
                                  this.state.to_send.satur_gideon_afternoon ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_gideon_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_gideon_evening"
                                  ),
                                })}
                                id="satur_gideon_evening"
                                name="satur_gideon_evening"
                                value={
                                  this.state.to_send.satur_gideon_evening || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_gideon_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_gideon_burden_bearers"
                                  ),
                                })}
                                id="satur_gideon_burden_bearers"
                                name="satur_gideon_burden_bearers"
                                value={
                                  this.state.to_send
                                    .satur_gideon_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "satur_gideon_burden_bearers"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Auxilliary</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_aux_kick_off"
                                  ),
                                })}
                                id="satur_aux_kick_off"
                                name="satur_aux_kick_off"
                                value={
                                  this.state.to_send.satur_aux_kick_off || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_aux_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_aux_mid_morning"
                                  ),
                                })}
                                id="satur_aux_mid_morning"
                                name="satur_aux_mid_morning"
                                value={
                                  this.state.to_send.satur_aux_mid_morning || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_aux_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_aux_afternoon"
                                  ),
                                })}
                                id="satur_aux_afternoon"
                                name="satur_aux_afternoon"
                                value={
                                  this.state.to_send.satur_aux_afternoon || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_aux_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_aux_evening"
                                  ),
                                })}
                                id="satur_aux_evening"
                                name="satur_aux_evening"
                                value={
                                  this.state.to_send.satur_aux_evening || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_aux_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_aux_burden_bearers"
                                  ),
                                })}
                                id="satur_aux_burden_bearers"
                                name="satur_aux_burden_bearers"
                                value={
                                  this.state.to_send.satur_aux_burden_bearers ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_aux_burden_bearers")}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Guest</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_kick_off"
                                  ),
                                })}
                                id="satur_kick_off"
                                name="satur_kick_off"
                                value={this.state.to_send.satur_kick_off || ""}
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_mid_morning"
                                  ),
                                })}
                                id="satur_mid_morning"
                                name="satur_mid_morning"
                                value={
                                  this.state.to_send.satur_mid_morning || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_afternoon"
                                  ),
                                })}
                                id="satur_afternoon"
                                name="satur_afternoon"
                                value={this.state.to_send.satur_afternoon || ""}
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_evening"
                                  ),
                                })}
                                id="satur_evening"
                                name="satur_evening"
                                value={this.state.to_send.satur_evening || ""}
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_burden_bearers"
                                  ),
                                })}
                                id="satur_burden_bearers"
                                name="satur_burden_bearers"
                                value={
                                  this.state.to_send.satur_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_burden_bearers")}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Youth</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_youth_kick_off"
                                  ),
                                })}
                                id="satur_youth_kick_off"
                                name="satur_youth_kick_off"
                                value={
                                  this.state.to_send.satur_youth_kick_off || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_youth_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_youth_mid_morning"
                                  ),
                                })}
                                id="satur_youth_mid_morning"
                                name="satur_youth_mid_morning"
                                value={
                                  this.state.to_send.satur_youth_mid_morning ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_youth_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_youth_afternoon"
                                  ),
                                })}
                                id="satur_youth_afternoon"
                                name="satur_youth_afternoon"
                                value={
                                  this.state.to_send.satur_youth_afternoon || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_youth_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_youth_evening"
                                  ),
                                })}
                                id="satur_youth_evening"
                                name="satur_youth_evening"
                                value={
                                  this.state.to_send.satur_youth_evening || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_youth_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_youth_burden_bearers"
                                  ),
                                })}
                                id="satur_youth_burden_bearers"
                                name="satur_youth_burden_bearers"
                                value={
                                  this.state.to_send
                                    .satur_youth_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "satur_youth_burden_bearers"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Children</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_children_kick_off"
                                  ),
                                })}
                                id="satur_children_kick_off"
                                name="satur_children_kick_off"
                                value={
                                  this.state.to_send.satur_children_kick_off ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_children_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_children_mid_morning"
                                  ),
                                })}
                                id="satur_children_mid_morning"
                                name="satur_children_mid_morning"
                                value={
                                  this.state.to_send
                                    .satur_children_mid_morning || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "satur_children_mid_morning"
                              )}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_children_afternoon"
                                  ),
                                })}
                                id="satur_children_afternoon"
                                name="satur_children_afternoon"
                                value={
                                  this.state.to_send.satur_children_afternoon ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_children_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_children_evening"
                                  ),
                                })}
                                id="satur_children_evening"
                                name="satur_children_evening"
                                value={
                                  this.state.to_send.satur_children_evening ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("satur_children_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "satur_children_burden_bearers"
                                  ),
                                })}
                                id="satur_children_burden_bearers"
                                name="satur_children_burden_bearers"
                                value={
                                  this.state.to_send
                                    .satur_children_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "satur_children_burden_bearers"
                              )}
                            </td>
                          </tr>
                          <tr className="py-5 ">
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr>
                            <th>Sunday</th>
                            <td>Gideon</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_gideon_kick_off"
                                  ),
                                })}
                                id="sunday_gideon_kick_off"
                                name="sunday_gideon_kick_off"
                                value={
                                  this.state.to_send.sunday_gideon_kick_off ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("sunday_gideon_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_gideon_mid_morning"
                                  ),
                                })}
                                id="sunday_gideon_mid_morning"
                                name="sunday_gideon_mid_morning"
                                value={
                                  this.state.to_send
                                    .sunday_gideon_mid_morning || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("sunday_gideon_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_gideon_afternoon"
                                  ),
                                })}
                                id="sunday_gideon_afternoon"
                                name="sunday_gideon_afternoon"
                                value={
                                  this.state.to_send.sunday_gideon_afternoon ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("sunday_gideon_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_gideon_evening"
                                  ),
                                })}
                                id="sunday_gideon_evening"
                                name="sunday_gideon_evening"
                                value={
                                  this.state.to_send.sunday_gideon_evening || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("sunday_gideon_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_gideon_burden_bearers"
                                  ),
                                })}
                                id="sunday_gideon_burden_bearers"
                                name="sunday_gideon_burden_bearers"
                                value={
                                  this.state.to_send
                                    .sunday_gideon_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "sunday_gideon_burden_bearers"
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td></td>
                            <td>Auxilliary</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_aux_kick_off"
                                  ),
                                })}
                                id="sunday_aux_kick_off"
                                name="sunday_aux_kick_off"
                                value={
                                  this.state.to_send.sunday_aux_kick_off || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("sunday_aux_kick_off")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_aux_mid_morning"
                                  ),
                                })}
                                id="sunday_aux_mid_morning"
                                name="sunday_aux_mid_morning"
                                value={
                                  this.state.to_send.sunday_aux_mid_morning ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("sunday_aux_mid_morning")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_aux_afternoon"
                                  ),
                                })}
                                id="sunday_aux_afternoon"
                                name="sunday_aux_afternoon"
                                value={
                                  this.state.to_send.sunday_aux_afternoon || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("sunday_aux_afternoon")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_aux_evening"
                                  ),
                                })}
                                id="sunday_aux_evening"
                                name="sunday_aux_evening"
                                value={
                                  this.state.to_send.sunday_aux_evening || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("sunday_aux_evening")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "sunday_aux_burden_bearers"
                                  ),
                                })}
                                id="sunday_aux_burden_bearers"
                                name="sunday_aux_burden_bearers"
                                value={
                                  this.state.to_send
                                    .sunday_aux_burden_bearers || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("sunday_aux_burden_bearers")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="camp_represented">
                        CAMP REPRESENTED at convention (do not include camps
                        from other states){" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("camp_represented"),
                          "form-control": true,
                        })}
                        id="camp_represented"
                        name="camp_represented"
                        value={this.state.to_send.camp_represented || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("camp_represented")}
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="total_camps">
                        Total camps in State Association{" "}
                      </label>
                      <input
                        type="number"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("total_camps"),
                          "form-control": true,
                        })}
                        id="total_camps"
                        name="total_camps"
                        value={this.state.to_send.total_camps || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("total_camps")}
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <p className="lead">
                        GIDEON OFFICERS elected or appointed for coming year
                      </p>
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Position</th>
                            <th>Name</th>
                            <th>Camp </th>
                            <th>Phone no </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>a. President</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "president_name"
                                  ),
                                })}
                                id="president_name"
                                name="president_name"
                                value={
                                  this.state.to_send.gideons.president_name ||
                                  ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("president_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "president_camp_name"
                                  ),
                                })}
                                id="president_camp_name"
                                name="president_camp_name"
                                value={
                                  this.state.to_send.gideons
                                    .president_camp_name || ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("president_camp_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "president_phone"
                                  ),
                                })}
                                id="president_phone"
                                name="president_phone"
                                value={
                                  this.state.to_send.gideons.president_phone ||
                                  ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("president_phone")}
                            </td>
                          </tr>
                          <tr>
                            <td>b. Vice President</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "vice_president_name"
                                  ),
                                })}
                                id="vice_president_name"
                                name="vice_president_name"
                                value={
                                  this.state.to_send.gideons
                                    .vice_president_name || ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("vice_president_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "vice_president_camp_name"
                                  ),
                                })}
                                id="vice_president_camp_name"
                                name="vice_president_camp_name"
                                value={
                                  this.state.to_send.gideons
                                    .vice_president_camp_name || ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("vice_president_camp_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "vice_president_phone"
                                  ),
                                })}
                                id="vice_president_phone"
                                name="vice_president_phone"
                                value={
                                  this.state.to_send.gideons
                                    .vice_president_phone || ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("vice_president_phone")}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              {this.state.creds.type &&
                              this.state.creds.type.toUpperCase() === "STATE"
                                ? " c. Secretary"
                                : " c. Secretary/ Ex director"}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "secretary_name"
                                  ),
                                })}
                                id="secretary_name"
                                name="secretary_name"
                                value={
                                  this.state.to_send.gideons.secretary_name ||
                                  ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("secretary_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "secretary_camp_name"
                                  ),
                                })}
                                id="secretary_camp_name"
                                name="secretary_camp_name"
                                value={
                                  this.state.to_send.gideons
                                    .secretary_camp_name || ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("secretary_camp_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "secretary_phone"
                                  ),
                                })}
                                id="secretary_phone"
                                name="secretary_phone"
                                value={
                                  this.state.to_send.gideons.secretary_phone ||
                                  ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("secretary_phone")}
                            </td>
                          </tr>
                          <tr>
                            <td>d. Treasurer</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "treasurer_name"
                                  ),
                                })}
                                id="treasurer_name"
                                name="treasurer_name"
                                value={
                                  this.state.to_send.gideons.treasurer_name ||
                                  ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("treasurer_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "treasurer_camp_name"
                                  ),
                                })}
                                id="treasurer_camp_name"
                                name="treasurer_camp_name"
                                value={
                                  this.state.to_send.gideons
                                    .treasurer_camp_name || ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("treasurer_camp_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "treasurer_phone"
                                  ),
                                })}
                                id="treasurer_phone"
                                name="treasurer_phone"
                                value={
                                  this.state.to_send.gideons.treasurer_phone ||
                                  ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("treasurer_phone")}
                            </td>
                          </tr>
                          <tr>
                            <td>e. Chaplain</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "chaplain_name"
                                  ),
                                })}
                                id="chaplain_name"
                                name="chaplain_name"
                                value={
                                  this.state.to_send.gideons.chaplain_name || ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("chaplain_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "chaplain_camp_name"
                                  ),
                                })}
                                id="chaplain_camp_name"
                                name="chaplain_camp_name"
                                value={
                                  this.state.to_send.gideons
                                    .chaplain_camp_name || ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("chaplain_camp_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "chaplain_phone"
                                  ),
                                })}
                                id="chaplain_phone"
                                name="chaplain_phone"
                                value={
                                  this.state.to_send.gideons.chaplain_phone ||
                                  ""
                                }
                                onChange={this.handleChange_7}
                              />
                              {this.renderErrorFor("chaplain_phone")}
                            </td>
                          </tr>

                          {this.state.creds.type &&
                          this.state.creds.type.toUpperCase() === "STATE" ? (
                            <>
                              <tr>
                                <td>f. Church Ministry Coordinator</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "ministry_coordinator_name"
                                      ),
                                    })}
                                    id="ministry_coordinator_name"
                                    name="ministry_coordinator_name"
                                    value={
                                      this.state.to_send.gideons
                                        .ministry_coordinator_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "ministry_coordinator_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "ministry_coordinator_camp_name"
                                      ),
                                    })}
                                    id="ministry_coordinator_camp_name"
                                    name="ministry_coordinator_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .ministry_coordinator_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "ministry_coordinator_camp_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "ministry_coordinator_phone"
                                      ),
                                    })}
                                    id="ministry_coordinator_phone"
                                    name="ministry_coordinator_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .ministry_coordinator_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "ministry_coordinator_phone"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>g. Membership Coordinator</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "membership_coordinator_name"
                                      ),
                                    })}
                                    id="membership_coordinator_name"
                                    name="membership_coordinator_name"
                                    value={
                                      this.state.to_send.gideons
                                        .membership_coordinator_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "membership_coordinator_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "membership_coordinator_camp_name"
                                      ),
                                    })}
                                    id="membership_coordinator_camp_name"
                                    name="membership_coordinator_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .membership_coordinator_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "membership_coordinator_camp_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "membership_coordinator_phone"
                                      ),
                                    })}
                                    id="membership_coordinator_phone"
                                    name="membership_coordinator_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .membership_coordinator_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "membership_coordinator_phone"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>h. GideonCard Coordinator</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "gideonCard_coordinator_name"
                                      ),
                                    })}
                                    id="gideonCard_coordinator_name"
                                    name="gideonCard_coordinator_name"
                                    value={
                                      this.state.to_send.gideons
                                        .gideonCard_coordinator_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "gideonCard_coordinator_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "gideonCard_coordinator_camp_name"
                                      ),
                                    })}
                                    id="gideonCard_coordinator_camp_name"
                                    name="gideonCard_coordinator_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .gideonCard_coordinator_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "gideonCard_coordinator_camp_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "gideonCard_coordinator_phone"
                                      ),
                                    })}
                                    id="gideonCard_coordinator_phone"
                                    name="gideonCard_coordinator_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .gideonCard_coordinator_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "gideonCard_coordinator_phone"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>i. Scripture Coordinator</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "scripture_coordinator_name"
                                      ),
                                    })}
                                    id="scripture_coordinator_name"
                                    name="scripture_coordinator_name"
                                    value={
                                      this.state.to_send.gideons
                                        .scripture_coordinator_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "scripture_coordinator_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "scripture_coordinator_camp_name"
                                      ),
                                    })}
                                    id="scripture_coordinator_camp_name"
                                    name="scripture_coordinator_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .scripture_coordinator_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "scripture_coordinator_camp_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "scripture_coordinator_phone"
                                      ),
                                    })}
                                    id="scripture_coordinator_phone"
                                    name="scripture_coordinator_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .scripture_coordinator_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "scripture_coordinator_phone"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>j. Regional Director 1</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "regional_director1_name"
                                      ),
                                    })}
                                    id="regional_director1_name"
                                    name="regional_director1_name"
                                    value={
                                      this.state.to_send.gideons
                                        .regional_director1_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "regional_director1_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "regional_director1_camp_name"
                                      ),
                                    })}
                                    id="regional_director1_camp_name"
                                    name="regional_director1_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .regional_director1_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "regional_director1_camp_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "regional_director1_phone"
                                      ),
                                    })}
                                    id="regional_director1_phone"
                                    name="regional_director1_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .regional_director1_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "regional_director1_phone"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>k. Regional Director 2</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "regional_director2_name"
                                      ),
                                    })}
                                    id="regional_director2_name"
                                    name="regional_director2_name"
                                    value={
                                      this.state.to_send.gideons
                                        .regional_director2_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "regional_director2_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "regional_director2_camp_name"
                                      ),
                                    })}
                                    id="regional_director2_camp_name"
                                    name="regional_director2_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .regional_director2_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "regional_director2_camp_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "regional_director2_phone"
                                      ),
                                    })}
                                    id="regional_director2_phone"
                                    name="regional_director2_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .regional_director2_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "regional_director2_phone"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>l. Area Directors</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_directors_name"
                                      ),
                                    })}
                                    id="area_directors_name"
                                    name="area_directors_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_directors_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_directors_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_directors_camp_name"
                                      ),
                                    })}
                                    id="area_directors_camp_name"
                                    name="area_directors_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_directors_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor(
                                    "area_directors_camp_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_directors_phone"
                                      ),
                                    })}
                                    id="area_directors_phone"
                                    name="area_directors_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .area_directors_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_directors_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 1</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_1_name"
                                      ),
                                    })}
                                    id="area_1_name"
                                    name="area_1_name"
                                    value={
                                      this.state.to_send.gideons.area_1_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_1_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_1_camp_name"
                                      ),
                                    })}
                                    id="area_1_camp_name"
                                    name="area_1_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_1_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_1_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_1_phone"
                                      ),
                                    })}
                                    id="area_1_phone"
                                    name="area_1_phone"
                                    value={
                                      this.state.to_send.gideons.area_1_phone ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_1_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 2</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_2_name"
                                      ),
                                    })}
                                    id="area_2_name"
                                    name="area_2_name"
                                    value={
                                      this.state.to_send.gideons.area_2_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_2_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_2_camp_name"
                                      ),
                                    })}
                                    id="area_2_camp_name"
                                    name="area_2_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_2_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_2_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_2_phone"
                                      ),
                                    })}
                                    id="area_2_phone"
                                    name="area_2_phone"
                                    value={
                                      this.state.to_send.gideons.area_2_phone ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_2_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 3</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_3_name"
                                      ),
                                    })}
                                    id="area_3_name"
                                    name="area_3_name"
                                    value={
                                      this.state.to_send.gideons.area_3_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_3_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_3_camp_name"
                                      ),
                                    })}
                                    id="area_3_camp_name"
                                    name="area_3_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_3_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_3_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_3_phone"
                                      ),
                                    })}
                                    id="area_3_phone"
                                    name="area_3_phone"
                                    value={
                                      this.state.to_send.gideons.area_3_phone ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_3_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 4</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_4_name"
                                      ),
                                    })}
                                    id="area_4_name"
                                    name="area_4_name"
                                    value={
                                      this.state.to_send.gideons.area_4_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_4_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_4_camp_name"
                                      ),
                                    })}
                                    id="area_4_camp_name"
                                    name="area_4_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_4_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_4_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_4_phone"
                                      ),
                                    })}
                                    id="area_4_phone"
                                    name="area_4_phone"
                                    value={
                                      this.state.to_send.gideons.area_4_phone ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_4_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 5</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_5_name"
                                      ),
                                    })}
                                    id="area_5_name"
                                    name="area_5_name"
                                    value={
                                      this.state.to_send.gideons.area_5_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_5_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_5_camp_name"
                                      ),
                                    })}
                                    id="area_5_camp_name"
                                    name="area_5_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_5_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_5_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_5_phone"
                                      ),
                                    })}
                                    id="area_5_phone"
                                    name="area_5_phone"
                                    value={
                                      this.state.to_send.gideons.area_5_phone ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_5_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 6</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_6_name"
                                      ),
                                    })}
                                    id="area_6_name"
                                    name="area_6_name"
                                    value={
                                      this.state.to_send.gideons.area_6_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_6_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_6_camp_name"
                                      ),
                                    })}
                                    id="area_6_camp_name"
                                    name="area_6_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_6_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_6_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_6_phone"
                                      ),
                                    })}
                                    id="area_6_phone"
                                    name="area_6_phone"
                                    value={
                                      this.state.to_send.gideons.area_6_phone ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_6_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 7</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_7_name"
                                      ),
                                    })}
                                    id="area_7_name"
                                    name="area_7_name"
                                    value={
                                      this.state.to_send.gideons.area_7_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_7_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_7_camp_name"
                                      ),
                                    })}
                                    id="area_7_camp_name"
                                    name="area_7_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_7_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_7_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_7_phone"
                                      ),
                                    })}
                                    id="area_7_phone"
                                    name="area_7_phone"
                                    value={
                                      this.state.to_send.gideons.area_7_phone ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_7_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 8</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_8_name"
                                      ),
                                    })}
                                    id="area_8_name"
                                    name="area_8_name"
                                    value={
                                      this.state.to_send.gideons.area_8_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_8_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_8_camp_name"
                                      ),
                                    })}
                                    id="area_8_camp_name"
                                    name="area_8_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_8_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_8_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_8_phone"
                                      ),
                                    })}
                                    id="area_8_phone"
                                    name="area_8_phone"
                                    value={
                                      this.state.to_send.gideons.area_8_phone ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_8_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 9</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_9_name"
                                      ),
                                    })}
                                    id="area_9_name"
                                    name="area_9_name"
                                    value={
                                      this.state.to_send.gideons.area_9_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_9_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_9_camp_name"
                                      ),
                                    })}
                                    id="area_9_camp_name"
                                    name="area_9_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_9_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_9_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_9_phone"
                                      ),
                                    })}
                                    id="area_9_phone"
                                    name="area_9_phone"
                                    value={
                                      this.state.to_send.gideons.area_9_phone ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_9_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 10</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_10_name"
                                      ),
                                    })}
                                    id="area_10_name"
                                    name="area_10_name"
                                    value={
                                      this.state.to_send.gideons.area_10_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_10_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_10_camp_name"
                                      ),
                                    })}
                                    id="area_10_camp_name"
                                    name="area_10_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_10_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_10_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_10_phone"
                                      ),
                                    })}
                                    id="area_10_phone"
                                    name="area_10_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .area_10_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_10_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 11</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_11_name"
                                      ),
                                    })}
                                    id="area_11_name"
                                    name="area_11_name"
                                    value={
                                      this.state.to_send.gideons.area_11_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_11_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_11_camp_name"
                                      ),
                                    })}
                                    id="area_11_camp_name"
                                    name="area_11_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_11_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_11_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_11_phone"
                                      ),
                                    })}
                                    id="area_11_phone"
                                    name="area_11_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .area_11_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_11_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 12</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_12_name"
                                      ),
                                    })}
                                    id="area_12_name"
                                    name="area_12_name"
                                    value={
                                      this.state.to_send.gideons.area_12_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_12_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_12_camp_name"
                                      ),
                                    })}
                                    id="area_12_camp_name"
                                    name="area_12_camp_name"
                                    value={
                                      this.state.to_send.gideons
                                        .area_12_camp_name || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_12_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_12_phone"
                                      ),
                                    })}
                                    id="area_12_phone"
                                    name="area_12_phone"
                                    value={
                                      this.state.to_send.gideons
                                        .area_12_phone || ""
                                    }
                                    onChange={this.handleChange_7}
                                  />
                                  {this.renderErrorFor("area_12_phone")}
                                </td>
                              </tr>
                            </>
                          ) : (
                            <>
                              <tr>
                                <th colSpan={4}>TRUSTEES AS APPLICABLE</th>
                              </tr>

                              <tr>
                                <th>Position</th>
                                <th>Name</th>
                                <th colSpan={2}>Complete Address</th>
                              </tr>

                              <tr>
                                <td>
                                  f.{" "}
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_1"
                                      ),
                                    })}
                                    id="trustee_position_1"
                                    name="trustee_position_1"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_position_1 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_position_1")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_name_1"
                                      ),
                                    })}
                                    id="trustee_name_1"
                                    name="trustee_name_1"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_name_1 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_name_1")}
                                </td>
                                <td colSpan={2}>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_addrs_1"
                                      ),
                                    })}
                                    id="trustee_addrs_1"
                                    name="trustee_addrs_1"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_addrs_1 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_addrs_1")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  g.{" "}
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_2"
                                      ),
                                    })}
                                    id="trustee_position_2"
                                    name="trustee_position_2"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_position_2 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_position_2")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_2"
                                      ),
                                    })}
                                    id="trustee_name_2"
                                    name="trustee_name_2"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_name_2 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_name_2")}
                                </td>
                                <td colSpan={2}>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_addrs_2"
                                      ),
                                    })}
                                    id="trustee_addrs_2"
                                    name="trustee_addrs_2"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_addrs_2 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_addrs_2")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  h.{" "}
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_3"
                                      ),
                                    })}
                                    id="trustee_position_3"
                                    name="trustee_position_3"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_position_3 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_position_3")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_name_3"
                                      ),
                                    })}
                                    id="trustee_name_3"
                                    name="trustee_name_3"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_name_3 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_name_3")}
                                </td>
                                <td colSpan={2}>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_addrs_3"
                                      ),
                                    })}
                                    id="trustee_addrs_3"
                                    name="trustee_addrs_3"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_addrs_3 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_addrs_3")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  i.{" "}
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_4"
                                      ),
                                    })}
                                    id="trustee_position_4"
                                    name="trustee_position_4"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_position_4 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_position_4")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_name_4"
                                      ),
                                    })}
                                    id="trustee_name_4"
                                    name="trustee_name_4"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_name_4 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_name_4")}
                                </td>
                                <td colSpan={2}>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_addrs_4"
                                      ),
                                    })}
                                    id="trustee_addrs_4"
                                    name="trustee_addrs_4"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_addrs_4 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_addrs_4")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  j.{" "}
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_5"
                                      ),
                                    })}
                                    id="trustee_position_5"
                                    name="trustee_position_5"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_position_5 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_position_5")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_name_5"
                                      ),
                                    })}
                                    id="trustee_name_5"
                                    name="trustee_name_5"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_name_5 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_name_5")}
                                </td>
                                <td colSpan={2}>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_addrs_5"
                                      ),
                                    })}
                                    id="trustee_addrs_5"
                                    name="trustee_addrs_5"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_addrs_5 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_addrs_5")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  k.{" "}
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_6"
                                      ),
                                    })}
                                    id="trustee_position_6"
                                    name="trustee_position_6"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_position_6 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_position_6")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_name_6"
                                      ),
                                    })}
                                    id="trustee_name_6"
                                    name="trustee_name_6"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_name_6 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_name_6")}
                                </td>
                                <td colSpan={2}>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_addrs_6"
                                      ),
                                    })}
                                    id="trustee_addrs_6"
                                    name="trustee_addrs_6"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_addrs_6 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_addrs_6")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  l.{" "}
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_7"
                                      ),
                                    })}
                                    id="trustee_position_7"
                                    name="trustee_position_7"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_position_7 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_position_7")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_name_7"
                                      ),
                                    })}
                                    id="trustee_name_7"
                                    name="trustee_name_7"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_name_7 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_name_7")}
                                </td>
                                <td colSpan={2}>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_addrs_7"
                                      ),
                                    })}
                                    id="trustee_addrs_7"
                                    name="trustee_addrs_7"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_addrs_7 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_addrs_7")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  m.{" "}
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_8"
                                      ),
                                    })}
                                    id="trustee_position_8"
                                    name="trustee_position_8"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_position_8 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_position_8")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_name_8"
                                      ),
                                    })}
                                    id="trustee_name_8"
                                    name="trustee_name_8"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_name_8 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_name_8")}
                                </td>
                                <td colSpan={2}>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_addrs_8"
                                      ),
                                    })}
                                    id="trustee_addrs_8"
                                    name="trustee_addrs_8"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_addrs_8 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_addrs_8")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  n.{" "}
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_position_9"
                                      ),
                                    })}
                                    id="trustee_position_9"
                                    name="trustee_position_9"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_position_9 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_position_9")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_name_9"
                                      ),
                                    })}
                                    id="trustee_name_9"
                                    name="trustee_name_9"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_name_9 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_name_9")}
                                </td>
                                <td colSpan={2}>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "trustee_addrs_9"
                                      ),
                                    })}
                                    id="trustee_addrs_9"
                                    name="trustee_addrs_9"
                                    value={
                                      this.state.to_send.trustees
                                        .trustee_addrs_9 || ""
                                    }
                                    onChange={this.handleChange_trustees}
                                  />
                                  {this.renderErrorFor("trustee_addrs_9")}
                                </td>
                              </tr>
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <p className="lead">
                        AUXILIARY OFFICERS elected or appointed for coming year
                      </p>
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Position</th>
                            <th>Name</th>
                            <th>Camp </th>
                            <th>Phone no </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>a. President</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "president_aux_name"
                                  ),
                                })}
                                id="president_aux_name"
                                name="president_aux_name"
                                value={
                                  this.state.to_send.auxiliary
                                    .president_aux_name || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("president_aux_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "president_aux_camp_name"
                                  ),
                                })}
                                id="president_aux_camp_name"
                                name="president_aux_camp_name"
                                value={
                                  this.state.to_send.auxiliary
                                    .president_aux_camp_name || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("president_aux_camp_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "president_aux_phone"
                                  ),
                                })}
                                id="president_aux_phone"
                                name="president_aux_phone"
                                value={
                                  this.state.to_send.auxiliary
                                    .president_aux_phone || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("president_aux_phone")}
                            </td>
                          </tr>
                          <tr>
                            <td>b. Vice President</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "vice_president_aux_name"
                                  ),
                                })}
                                id="vice_president_aux_name"
                                name="vice_president_aux_name"
                                value={
                                  this.state.to_send.auxiliary
                                    .vice_president_aux_name || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("vice_president_aux_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "vice_president_aux_camp_name"
                                  ),
                                })}
                                id="vice_president_aux_camp_name"
                                name="vice_president_aux_camp_name"
                                value={
                                  this.state.to_send.auxiliary
                                    .vice_president_aux_camp_name || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor(
                                "vice_president_aux_camp_name"
                              )}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "vice_president_aux_phone"
                                  ),
                                })}
                                id="vice_president_aux_phone"
                                name="vice_president_aux_phone"
                                value={
                                  this.state.to_send.auxiliary
                                    .vice_president_aux_phone || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("vice_president_aux_phone")}
                            </td>
                          </tr>
                          <tr>
                            <td>c. Secretary/Treasurer</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "secretary_aux_name"
                                  ),
                                })}
                                id="secretary_aux_name"
                                name="secretary_aux_name"
                                value={
                                  this.state.to_send.auxiliary
                                    .secretary_aux_name || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("secretary_aux_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "secretary_aux_camp_name"
                                  ),
                                })}
                                id="secretary_aux_camp_name"
                                name="secretary_aux_camp_name"
                                value={
                                  this.state.to_send.auxiliary
                                    .secretary_aux_camp_name || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("secretary_aux_camp_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "secretary_aux_phone"
                                  ),
                                })}
                                id="secretary_aux_phone"
                                name="secretary_aux_phone"
                                value={
                                  this.state.to_send.auxiliary
                                    .secretary_aux_phone || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("secretary_aux_phone")}
                            </td>
                          </tr>
                          <tr>
                            <td>d. Chaplain</td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "chaplain_aux_name"
                                  ),
                                })}
                                id="chaplain_aux_name"
                                name="chaplain_aux_name"
                                value={
                                  this.state.to_send.auxiliary
                                    .chaplain_aux_name || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("chaplain_aux_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "chaplain_aux_camp_name"
                                  ),
                                })}
                                id="chaplain_aux_camp_name"
                                name="chaplain_aux_camp_name"
                                value={
                                  this.state.to_send.auxiliary
                                    .chaplain_aux_camp_name || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("chaplain_aux_camp_name")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "chaplain_aux_phone"
                                  ),
                                })}
                                id="chaplain_aux_phone"
                                name="chaplain_aux_phone"
                                value={
                                  this.state.to_send.auxiliary
                                    .chaplain_aux_phone || ""
                                }
                                onChange={this.handleChange_6}
                              />
                              {this.renderErrorFor("chaplain_aux_phone")}
                            </td>
                          </tr>
                          {this.state.creds.type &&
                          this.state.creds.type.toUpperCase() === "STATE" ? (
                            <>
                              <tr>
                                <td>e. Scripture Coordinator</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "scripture_coordinator_aux_name"
                                      ),
                                    })}
                                    id="scripture_coordinator_aux_name"
                                    name="scripture_coordinator_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .scripture_coordinator_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor(
                                    "scripture_coordinator_aux_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "scripture_coordinator_aux_camp_name"
                                      ),
                                    })}
                                    id="scripture_coordinator_aux_camp_name"
                                    name="scripture_coordinator_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .scripture_coordinator_aux_camp_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor(
                                    "scripture_coordinator_aux_camp_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "scripture_coordinator_aux_phone"
                                      ),
                                    })}
                                    id="scripture_coordinator_aux_phone"
                                    name="scripture_coordinator_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .scripture_coordinator_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor(
                                    "scripture_coordinator_aux_phone"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td>f. Auxiliary Area Directors</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "auxiliary_area_directors_name"
                                      ),
                                    })}
                                    id="auxiliary_area_directors_name"
                                    name="auxiliary_area_directors_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .auxiliary_area_directors_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor(
                                    "auxiliary_area_directors_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "auxiliary_area_directors_camp_name"
                                      ),
                                    })}
                                    id="auxiliary_area_directors_camp_name"
                                    name="auxiliary_area_directors_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .auxiliary_area_directors_camp_name ||
                                      ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor(
                                    "auxiliary_area_directors_camp_name"
                                  )}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "auxiliary_area_directors_phone"
                                      ),
                                    })}
                                    id="auxiliary_area_directors_phone"
                                    name="auxiliary_area_directors_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .auxiliary_area_directors_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor(
                                    "auxiliary_area_directors_phone"
                                  )}
                                </td>
                              </tr>
                              <tr>
                                <td> Area 1 </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_1_aux_name"
                                      ),
                                    })}
                                    id="area_1_aux_name"
                                    name="area_1_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_1_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_1_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_1_aux_camp_name"
                                      ),
                                    })}
                                    id="area_1_aux_camp_name"
                                    name="area_1_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_1_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_1_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_1_aux_phone"
                                      ),
                                    })}
                                    id="area_1_aux_phone"
                                    name="area_1_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_1_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_1_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 2</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_2_aux_name"
                                      ),
                                    })}
                                    id="area_2_aux_name"
                                    name="area_2_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_2_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_2_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_2_aux_camp_name"
                                      ),
                                    })}
                                    id="area_2_aux_camp_name"
                                    name="area_2_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_2_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_2_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_2_aux_phone"
                                      ),
                                    })}
                                    id="area_2_aux_phone"
                                    name="area_2_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_2_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_2_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 3</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_3_aux_name"
                                      ),
                                    })}
                                    id="area_3_aux_name"
                                    name="area_3_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_3_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_3_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_3_aux_camp_name"
                                      ),
                                    })}
                                    id="area_3_aux_camp_name"
                                    name="area_3_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_3_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_3_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_3_aux_phone"
                                      ),
                                    })}
                                    id="area_3_aux_phone"
                                    name="area_3_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_3_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_3_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 4</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_4_aux_name"
                                      ),
                                    })}
                                    id="area_4_aux_name"
                                    name="area_4_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_4_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_4_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_4_aux_camp_name"
                                      ),
                                    })}
                                    id="area_4_aux_camp_name"
                                    name="area_4_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_4_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_4_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_4_aux_phone"
                                      ),
                                    })}
                                    id="area_4_aux_phone"
                                    name="area_4_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_4_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_4_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 5</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_5_aux_name"
                                      ),
                                    })}
                                    id="area_5_aux_name"
                                    name="area_5_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_5_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_5_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_5_aux_camp_name"
                                      ),
                                    })}
                                    id="area_5_aux_camp_name"
                                    name="area_5_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_5_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_5_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_5_aux_phone"
                                      ),
                                    })}
                                    id="area_5_aux_phone"
                                    name="area_5_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_5_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_5_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 6</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_6_aux_name"
                                      ),
                                    })}
                                    id="area_6_aux_name"
                                    name="area_6_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_6_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_6_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_6_aux_camp_name"
                                      ),
                                    })}
                                    id="area_6_aux_camp_name"
                                    name="area_6_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_6_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_6_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_6_aux_phone"
                                      ),
                                    })}
                                    id="area_6_aux_phone"
                                    name="area_6_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_6_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_6_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 7</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_7_aux_name"
                                      ),
                                    })}
                                    id="area_7_aux_name"
                                    name="area_7_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_7_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_7_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_7_aux_camp_name"
                                      ),
                                    })}
                                    id="area_7_aux_camp_name"
                                    name="area_7_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_7_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_7_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_7_aux_phone"
                                      ),
                                    })}
                                    id="area_7_aux_phone"
                                    name="area_7_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_7_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_7_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 8</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_8_aux_name"
                                      ),
                                    })}
                                    id="area_8_aux_name"
                                    name="area_8_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_8_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_8_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_8_aux_camp_name"
                                      ),
                                    })}
                                    id="area_8_aux_camp_name"
                                    name="area_8_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_8_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_8_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_8_aux_phone"
                                      ),
                                    })}
                                    id="area_8_aux_phone"
                                    name="area_8_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_8_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_8_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 9</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_9_aux_name"
                                      ),
                                    })}
                                    id="area_9_aux_name"
                                    name="area_9_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_9_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_9_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_9_aux_camp_name"
                                      ),
                                    })}
                                    id="area_9_aux_camp_name"
                                    name="area_9_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_9_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_9_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_9_aux_phone"
                                      ),
                                    })}
                                    id="area_9_aux_phone"
                                    name="area_9_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_9_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_9_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 10</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_10_aux_name"
                                      ),
                                    })}
                                    id="area_10_aux_name"
                                    name="area_10_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_10_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_10_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_10_aux_camp_name"
                                      ),
                                    })}
                                    id="area_10_aux_camp_name"
                                    name="area_10_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_10_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_10_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_10_aux_phone"
                                      ),
                                    })}
                                    id="area_10_aux_phone"
                                    name="area_10_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_10_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_10_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 11</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_11_aux_name"
                                      ),
                                    })}
                                    id="area_11_aux_name"
                                    name="area_11_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_11_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_11_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_11_aux_camp_name"
                                      ),
                                    })}
                                    id="area_11_aux_camp_name"
                                    name="area_11_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_11_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_11_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_11_aux_phone"
                                      ),
                                    })}
                                    id="area_11_aux_phone"
                                    name="area_11_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_11_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_11_aux_phone")}
                                </td>
                              </tr>
                              <tr>
                                <td>Area 12</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_12_aux_name"
                                      ),
                                    })}
                                    id="area_12_aux_name"
                                    name="area_12_aux_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_12_aux_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_12_aux_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_12_aux_camp_name"
                                      ),
                                    })}
                                    id="area_12_aux_camp_name"
                                    name="area_12_aux_camp_name"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_12_aux_camp_name || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_12_aux_camp_name")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "area_12_aux_phone"
                                      ),
                                    })}
                                    id="area_12_aux_phone"
                                    name="area_12_aux_phone"
                                    value={
                                      this.state.to_send.auxiliary
                                        .area_12_aux_phone || ""
                                    }
                                    onChange={this.handleChange_6}
                                  />
                                  {this.renderErrorFor("area_12_aux_phone")}
                                </td>
                              </tr>
                            </>
                          ) : null}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {this.state.creds.type &&
                  this.state.creds.type.toUpperCase() === "STATE" ? (
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="state_editor">
                          Name and Camp of State Editor (if appointed) (not a
                          member of State Cabinet){" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("state_editor"),
                            "form-control": true,
                          })}
                          id="state_editor"
                          name="state_editor"
                          value={this.state.to_send.state_editor || ""}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("state_editor")}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="state_webmaster">
                          Name of State Webmaster (if appointed) (not a member
                          of State Cabinet){" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("state_webmaster"),
                            "form-control": true,
                          })}
                          id="state_webmaster"
                          name="state_webmaster"
                          value={this.state.to_send.state_webmaster || ""}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("state_webmaster")}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="state_merchandise">
                          Name and Camp of State Merchandise Officer (not a
                          member of State Cabinet){" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("state_merchandise"),
                            "form-control": true,
                          })}
                          id="state_merchandise"
                          name="state_merchandise"
                          value={this.state.to_send.state_merchandise || ""}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("state_merchandise")}
                      </div>
                    </div>
                  ) : null}

                  <div className="row">
                    <div className="col-md-12">
                      <p className="lead">MEETING WITH NEWLY ELECTED CABINET</p>
                      <table className="table table-bordered table-striped">
                        <tbody>
                          <tr>
                            <td>Meeting Date</td>
                            <td>
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.hasErrorFor(
                                    "meeting_date"
                                  ),
                                })}
                                id="meeting_date"
                                name="meeting_date"
                                readOnly
                                // value={this.state.to_send.meeting_date || ""}
                                // onChange={this.handleChange}
                              />
                              {this.renderErrorFor("meeting_date")}
                            </td>
                          </tr>
                          <tr>
                            <td>Meeting time</td>
                            <td>
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.hasErrorFor(
                                    "meeting_time"
                                  ),
                                })}
                                id="meeting_time"
                                name="meeting_time"
                                value={
                                  this.state.to_send.meetings.meeting_time || ""
                                }
                                onChange={this.handleChange_5}
                              />
                              {this.renderErrorFor("meeting_time")}
                            </td>
                          </tr>
                          <tr>
                            <td>Officers present</td>
                            <td>
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.hasErrorFor(
                                    "officers_present"
                                  ),
                                })}
                                id="officers_present"
                                name="officers_present"
                                value={
                                  this.state.to_send.meetings
                                    .officers_present || ""
                                }
                                onChange={this.handleChange_5}
                              />
                              {this.renderErrorFor("officers_present")}
                            </td>
                          </tr>
                          <tr>
                            <td>Date of next year’s convention </td>
                            <td>
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.hasErrorFor(
                                    "next_convention"
                                  ),
                                })}
                                id="next_convention"
                                name="next_convention"
                                value={
                                  this.state.to_send.meetings.next_convention ||
                                  ""
                                }
                                onChange={this.handleChange_5}
                              />
                              {this.renderErrorFor("next_convention")}
                            </td>
                          </tr>
                          <tr>
                            <td>Place for next year’s convention (City) </td>
                            <td>
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.hasErrorFor(
                                    "next_convention_place"
                                  ),
                                })}
                                id="next_convention_place"
                                name="next_convention_place"
                                value={
                                  this.state.to_send.meetings
                                    .next_convention_place || ""
                                }
                                onChange={this.handleChange_5}
                              />
                              {this.renderErrorFor("next_convention_place")}
                            </td>
                          </tr>
                          <tr>
                            <td> Hotel/Motel (Proposed)</td>
                            <td>
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.hasErrorFor(
                                    "hotel_proposed"
                                  ),
                                })}
                                id="hotel_proposed"
                                name="hotel_proposed"
                                value={
                                  this.state.to_send.meetings.hotel_proposed ||
                                  ""
                                }
                                onChange={this.handleChange_5}
                              />
                              {this.renderErrorFor("hotel_proposed")}
                            </td>
                          </tr>
                          <tr>
                            <td> Address </td>
                            <td>
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.hasErrorFor(
                                    "hotel_address"
                                  ),
                                })}
                                id="hotel_address"
                                name="hotel_address"
                                value={
                                  this.state.to_send.meetings.hotel_address ||
                                  ""
                                }
                                onChange={this.handleChange_5}
                              />
                              {this.renderErrorFor("hotel_address")}
                            </td>
                          </tr>
                          <tr>
                            <td> City, State and Zip </td>
                            <td>
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.hasErrorFor(
                                    "hotel_city_state_zip"
                                  ),
                                })}
                                id="hotel_city_state_zip"
                                name="hotel_city_state_zip"
                                value={
                                  this.state.to_send.meetings
                                    .hotel_city_state_zip || ""
                                }
                                onChange={this.handleChange_5}
                              />
                              {this.renderErrorFor("hotel_city_state_zip")}
                            </td>
                          </tr>
                          <tr>
                            <td> Phone number for hotel </td>
                            <td>
                              <input
                                type="text"
                                className={classnames("form-control", {
                                  "is-invalid": this.hasErrorFor("hotel_phone"),
                                })}
                                id="hotel_phone"
                                name="hotel_phone"
                                value={
                                  this.state.to_send.meetings.hotel_phone || ""
                                }
                                onChange={this.handleChange_5}
                              />
                              {this.renderErrorFor("hotel_phone")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    {this.state.creds.type &&
                    this.state.creds.type.toUpperCase() === "STATE" ? (
                      <>
                        <div className="col-md-12">
                          <p className="lead">
                            a. New Member Plan Activity (confirm two in detail,
                            list other place and dates below
                          </p>
                          <p>
                            (1) First New Member Plan to be held following State
                            Convention:{" "}
                          </p>
                          <div className="row">
                            <div className="form-group col-md-3">
                              <label htmlFor="member_plan_date">Date </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "member_plan_date"
                                  ),
                                  "form-control": true,
                                })}
                                id="member_plan_date"
                                name="member_plan_date"
                                readOnly
                                // value={this.state.to_send.member_plan_date || ""}
                                // onChange={this.handleChange}
                              />
                              {this.renderErrorFor("member_plan_date")}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="member_plan_place">Place </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "member_plan_place"
                                  ),
                                  "form-control": true,
                                })}
                                id="member_plan_place"
                                name="member_plan_place"
                                value={
                                  this.state.to_send.member_plan_place || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("member_plan_place")}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="member_gideon_in_charge ">
                                Gideon in Charge{" "}
                              </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "member_gideon_in_charge"
                                  ),
                                  "form-control": true,
                                })}
                                id="member_gideon_in_charge"
                                name="member_gideon_in_charge"
                                value={
                                  this.state.to_send.member_gideon_in_charge ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("member_gideon_in_charge ")}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="member_moderator ">
                                Moderator for NMP dinner meeting{" "}
                              </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "member_moderator"
                                  ),
                                  "form-control": true,
                                })}
                                id="member_moderator"
                                name="member_moderator"
                                value={
                                  this.state.to_send.member_moderator || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("member_moderator ")}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="member_training_leader ">
                                State Assistant Training Leader{" "}
                              </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "member_training_leader"
                                  ),
                                  "form-control": true,
                                })}
                                id="member_training_leader"
                                name="member_training_leader"
                                value={
                                  this.state.to_send.member_training_leader ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("member_training_leader ")}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-12">
                          <p className="lead">
                            b. New Member Plan Activity – continued.
                          </p>
                          <p>
                            (2) Second New Member Plan to be held following
                            State Convention:{" "}
                          </p>
                          <div className="row">
                            <div className="form-group col-md-3">
                              <label htmlFor="second_member_plan_date">
                                Date{" "}
                              </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "second_member_plan_date"
                                  ),
                                  "form-control": true,
                                })}
                                id="second_member_plan_date"
                                name="second_member_plan_date"
                                readOnly
                                // value={
                                //   this.state.to_send.second_member_plan_date || ""
                                // }
                                // onChange={this.handleChange}
                              />
                              {this.renderErrorFor("second_member_plan_date")}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="second_member_plan_place">
                                Place{" "}
                              </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "second_member_plan_place"
                                  ),
                                  "form-control": true,
                                })}
                                id="second_member_plan_place"
                                name="second_member_plan_place"
                                value={
                                  this.state.to_send.second_member_plan_place ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("second_member_plan_place")}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="second_member_gideon_in_charge ">
                                Gideon in Charge{" "}
                              </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "second_member_gideon_in_charge"
                                  ),
                                  "form-control": true,
                                })}
                                id="second_member_gideon_in_charge"
                                name="second_member_gideon_in_charge"
                                value={
                                  this.state.to_send
                                    .second_member_gideon_in_charge || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "second_member_gideon_in_charge "
                              )}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="second_member_moderator ">
                                Moderator for NMP dinner meeting{" "}
                              </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "second_member_moderator"
                                  ),
                                  "form-control": true,
                                })}
                                id="second_member_moderator"
                                name="second_member_moderator"
                                value={
                                  this.state.to_send.second_member_moderator ||
                                  ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor("second_member_moderator ")}
                            </div>
                            <div className="form-group col-md-3">
                              <label htmlFor="second_member_training_leader ">
                                State Assistant Training Leader{" "}
                              </label>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "second_member_training_leader"
                                  ),
                                  "form-control": true,
                                })}
                                id="second_member_training_leader"
                                name="second_member_training_leader"
                                value={
                                  this.state.to_send
                                    .second_member_training_leader || ""
                                }
                                onChange={this.handleChange}
                              />
                              {this.renderErrorFor(
                                "second_member_training_leader "
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <p>(3) Other New Member Plans </p>
                          <table className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th>Region</th>
                                <th>Place</th>
                                <th>Date</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "region_mem_1"
                                      ),
                                    })}
                                    id="region_mem_1"
                                    name="region_mem_1"
                                    value={
                                      this.state.to_send.members.region_mem_1 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("region_mem_1")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "place_mem_1"
                                      ),
                                    })}
                                    id="place_mem_1"
                                    name="place_mem_1"
                                    value={
                                      this.state.to_send.members.place_mem_1 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("place_mem_1")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_mem_1"
                                      ),
                                    })}
                                    id="date_mem_1"
                                    name="date_mem_1"
                                    readOnly
                                    // value={this.state.to_send.members.date_mem_1 || ""}
                                    // onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("date_mem_1")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "region_mem_2"
                                      ),
                                    })}
                                    id="region_mem_2"
                                    name="region_mem_2"
                                    value={
                                      this.state.to_send.members.region_mem_2 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("region_mem_2")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "place_mem_2"
                                      ),
                                    })}
                                    id="place_mem_2"
                                    name="place_mem_2"
                                    value={
                                      this.state.to_send.members.place_mem_2 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("place_mem_2")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_mem_2"
                                      ),
                                    })}
                                    id="date_mem_2"
                                    name="date_mem_2"
                                    readOnly
                                    // value={this.state.to_send.members.date_mem_2 || ""}
                                    // onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("date_mem_2")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "region_mem_3"
                                      ),
                                    })}
                                    id="region_mem_3"
                                    name="region_mem_3"
                                    value={
                                      this.state.to_send.members.region_mem_3 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("region_mem_3")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "place_mem_3"
                                      ),
                                    })}
                                    id="place_mem_3"
                                    name="place_mem_3"
                                    value={
                                      this.state.to_send.members.place_mem_3 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("place_mem_3")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_mem_3"
                                      ),
                                    })}
                                    id="date_mem_3"
                                    name="date_mem_3"
                                    readOnly
                                    // value={this.state.to_send.members.date_mem_3 || ""}
                                    // onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("date_mem_3")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "region_mem_4"
                                      ),
                                    })}
                                    id="region_mem_4"
                                    name="region_mem_4"
                                    value={
                                      this.state.to_send.members.region_mem_4 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("region_mem_4")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "place_mem_4"
                                      ),
                                    })}
                                    id="place_mem_4"
                                    name="place_mem_4"
                                    value={
                                      this.state.to_send.members.place_mem_4 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("place_mem_4")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_mem_4"
                                      ),
                                    })}
                                    id="date_mem_4"
                                    name="date_mem_4"
                                    readOnly
                                    // value={this.state.to_send.members.date_mem_4 || ""}
                                    // onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("date_mem_4")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "region_mem_5"
                                      ),
                                    })}
                                    id="region_mem_5"
                                    name="region_mem_5"
                                    value={
                                      this.state.to_send.members.region_mem_5 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("region_mem_5")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "place_mem_5"
                                      ),
                                    })}
                                    id="place_mem_5"
                                    name="place_mem_5"
                                    value={
                                      this.state.to_send.members.place_mem_5 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("place_mem_5")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_mem_5"
                                      ),
                                    })}
                                    id="date_mem_5"
                                    name="date_mem_5"
                                    readOnly
                                    // value={this.state.to_send.members.date_mem_5 || ""}
                                    // onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("date_mem_5")}
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "region_mem_6"
                                      ),
                                    })}
                                    id="region_mem_6"
                                    name="region_mem_6"
                                    value={
                                      this.state.to_send.members.region_mem_6 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("region_mem_6")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "place_mem_6"
                                      ),
                                    })}
                                    id="place_mem_6"
                                    name="place_mem_6"
                                    value={
                                      this.state.to_send.members.place_mem_6 ||
                                      ""
                                    }
                                    onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("place_mem_6")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_mem_6"
                                      ),
                                    })}
                                    id="date_mem_6"
                                    name="date_mem_6"
                                    readOnly
                                    // value={this.state.to_send.members.date_mem_6 || ""}
                                    // onChange={this.handleChange_4}
                                  />
                                  {this.renderErrorFor("date_mem_6")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-md-12">
                          <p>c. Church Relations Days Scheduled </p>
                          <table className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th>S/N</th>
                                <th>Name of Church</th>
                                <th>Date Scheduled</th>
                                <th>Camp Assigned</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_1"
                                      ),
                                    })}
                                    id="name_of_church_1"
                                    name="name_of_church_1"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_1 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_1")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_1"
                                      ),
                                    })}
                                    id="date_scheduled_1"
                                    name="date_scheduled_1"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_1 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_1")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_1"
                                      ),
                                    })}
                                    id="camp_assigned_1"
                                    name="camp_assigned_1"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_1 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_1")}
                                </td>
                              </tr>
                              <tr>
                                <td>2.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_2"
                                      ),
                                    })}
                                    id="name_of_church_2"
                                    name="name_of_church_2"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_2 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_2")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_2"
                                      ),
                                    })}
                                    id="date_scheduled_2"
                                    name="date_scheduled_2"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_2 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_2")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_2"
                                      ),
                                    })}
                                    id="camp_assigned_2"
                                    name="camp_assigned_2"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_2 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_2")}
                                </td>
                              </tr>
                              <tr>
                                <td>3.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_3"
                                      ),
                                    })}
                                    id="name_of_church_3"
                                    name="name_of_church_3"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_3 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_3")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_3"
                                      ),
                                    })}
                                    id="date_scheduled_3"
                                    name="date_scheduled_3"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_3 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_3")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_3"
                                      ),
                                    })}
                                    id="camp_assigned_3"
                                    name="camp_assigned_3"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_3 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_3")}
                                </td>
                              </tr>
                              <tr>
                                <td>4.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_4"
                                      ),
                                    })}
                                    id="name_of_church_4"
                                    name="name_of_church_4"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_4 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_4")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_4"
                                      ),
                                    })}
                                    id="date_scheduled_4"
                                    name="date_scheduled_4"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_4 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_4")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_4"
                                      ),
                                    })}
                                    id="camp_assigned_4"
                                    name="camp_assigned_4"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_4 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_4")}
                                </td>
                              </tr>
                              <tr>
                                <td>5.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_5"
                                      ),
                                    })}
                                    id="name_of_church_5"
                                    name="name_of_church_5"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_5 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_5")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_5"
                                      ),
                                    })}
                                    id="date_scheduled_5"
                                    name="date_scheduled_5"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_5 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_5")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_5"
                                      ),
                                    })}
                                    id="camp_assigned_5"
                                    name="camp_assigned_5"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_5 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_5")}
                                </td>
                              </tr>
                              <tr>
                                <td>6.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_6"
                                      ),
                                    })}
                                    id="name_of_church_6"
                                    name="name_of_church_6"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_6 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_6")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_6"
                                      ),
                                    })}
                                    id="date_scheduled_6"
                                    name="date_scheduled_6"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_6 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_6")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_6"
                                      ),
                                    })}
                                    id="camp_assigned_6"
                                    name="camp_assigned_6"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_6 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_6")}
                                </td>
                              </tr>
                              <tr>
                                <td>7.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_7"
                                      ),
                                    })}
                                    id="name_of_church_7"
                                    name="name_of_church_7"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_7 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_7")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_7"
                                      ),
                                    })}
                                    id="date_scheduled_7"
                                    name="date_scheduled_7"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_7 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_7")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_7"
                                      ),
                                    })}
                                    id="camp_assigned_7"
                                    name="camp_assigned_7"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_7 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_7")}
                                </td>
                              </tr>
                              <tr>
                                <td>8.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_8"
                                      ),
                                    })}
                                    id="name_of_church_8"
                                    name="name_of_church_8"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_8 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_8")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_8"
                                      ),
                                    })}
                                    id="date_scheduled_8"
                                    name="date_scheduled_8"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_8 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_8")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_8"
                                      ),
                                    })}
                                    id="camp_assigned_8"
                                    name="camp_assigned_8"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_8 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_8")}
                                </td>
                              </tr>
                              <tr>
                                <td>9.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_9"
                                      ),
                                    })}
                                    id="name_of_church_9"
                                    name="name_of_church_9"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_9 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_9")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_9"
                                      ),
                                    })}
                                    id="date_scheduled_9"
                                    name="date_scheduled_9"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_9 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_9")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_9"
                                      ),
                                    })}
                                    id="camp_assigned_9"
                                    name="camp_assigned_9"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_9 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_9")}
                                </td>
                              </tr>
                              <tr>
                                <td>10.</td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "name_of_church_10"
                                      ),
                                    })}
                                    id="name_of_church_10"
                                    name="name_of_church_10"
                                    value={
                                      this.state.to_send.relations
                                        .name_of_church_10 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("name_of_church_10")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "date_scheduled_10"
                                      ),
                                    })}
                                    id="date_scheduled_10"
                                    name="date_scheduled_10"
                                    // value={
                                    //   this.state.to_send.relations.date_scheduled_10 || ""
                                    // }
                                    // onChange={this.handleChange_3}
                                    readOnly
                                  />
                                  {this.renderErrorFor("date_scheduled_10")}
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className={classnames({
                                      "is-invalid": this.hasErrorFor(
                                        "camp_assigned_10"
                                      ),
                                    })}
                                    id="camp_assigned_10"
                                    name="camp_assigned_10"
                                    value={
                                      this.state.to_send.relations
                                        .camp_assigned_10 || ""
                                    }
                                    onChange={this.handleChange_3}
                                  />
                                  {this.renderErrorFor("camp_assigned_10")}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </>
                    ) : null}
                  </div>

                  <div className="row">
                    <div className="form-group col-md-6">
                      <label htmlFor="number_of_camps">
                        d. Number of camps with no Scripture Fund Received to
                        date{" "}
                      </label>
                      <input
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("number_of_camps"),
                          "form-control": true,
                        })}
                        id="number_of_camps"
                        name="number_of_camps"
                        value={this.state.to_send.number_of_camps || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("number_of_camps")}
                    </div>
                  </div>

                  {this.state.creds.type &&
                  this.state.creds.type.toUpperCase() === "STATE" ? (
                    <div className="row">
                      <div className="form-group col-md-6">
                        <label htmlFor="date_first_conv">
                          e. Date of first State Cabinet Meeting following State
                          Convention{" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("date_first_conv"),
                            "form-control": true,
                          })}
                          id="date_first_conv"
                          name="date_first_conv"
                          // value={this.state.to_send.date_first_conv || ""}
                          readOnly
                          // onChange={this.handleChange}
                        />
                        {this.renderErrorFor("date_first_conv")}
                      </div>
                      <div className="col-md-12">
                        <p>
                          f. For other results of this meeting with the newly
                          elected State Cabinet, see my letter to State
                        </p>
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="president_dated">
                          President dated{" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("president_dated"),
                            "form-control": true,
                          })}
                          id="president_dated"
                          name="president_dated"
                          // value={this.state.to_send.president_dated || ""}
                          readOnly
                          // onChange={this.handleChange}
                        />
                        {this.renderErrorFor("president_dated")}
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="hotel_place_bible">
                          Hotel Placed Bible{" "}
                        </label>
                        <input
                          type="text"
                          className={classnames({
                            "is-invalid": this.hasErrorFor("hotel_place_bible"),
                            "form-control": true,
                          })}
                          id="hotel_place_bible"
                          name="hotel_place_bible"
                          value={this.state.to_send.hotel_place_bible || ""}
                          onChange={this.handleChange}
                        />
                        {this.renderErrorFor("hotel_place_bible")}
                      </div>
                    </div>
                  ) : null}

                  <div className="row">
                    <div className="col-md-12">
                      <p className="lead">Testimony</p>
                      <p>
                        <strong>
                          Was there some individual who was saved through a
                          Gideon placed or distributed Scripture who gave a
                          testimony at this convention who you would recommend
                          for possible use at a National Convention: If “yes”
                          please complete below. (If a tape recording and/or
                          typed copy of the testimony is available; please have
                          this sent to Nashville Headquarters as soon as
                          possible.
                        </strong>
                      </p>
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Gideon Scripture</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor("born_name_1"),
                                })}
                                id="born_name_1"
                                name="born_name_1"
                                value={
                                  this.state.to_send.born.born_name_1 || ""
                                }
                                onChange={this.handleChange_2}
                              />
                              {this.renderErrorFor("born_name_1")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "born_address_1"
                                  ),
                                })}
                                id="born_address_1"
                                name="born_address_1"
                                value={
                                  this.state.to_send.born.born_address_1 || ""
                                }
                                onChange={this.handleChange_2}
                              />
                              {this.renderErrorFor("born_address_1")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "born_gideon_scripture_1"
                                  ),
                                })}
                                id="born_gideon_scripture_1"
                                name="born_gideon_scripture_1"
                                value={
                                  this.state.to_send.born
                                    .born_gideon_scripture_1 || ""
                                }
                                onChange={this.handleChange_2}
                              />
                              {this.renderErrorFor("born_gideon_scripture_1")}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor("born_name_2"),
                                })}
                                id="born_name_2"
                                name="born_name_2"
                                value={
                                  this.state.to_send.born.born_name_2 || ""
                                }
                                onChange={this.handleChange_2}
                              />
                              {this.renderErrorFor("born_name_2")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "born_address_2"
                                  ),
                                })}
                                id="born_address_2"
                                name="born_address_2"
                                value={
                                  this.state.to_send.born.born_address_2 || ""
                                }
                                onChange={this.handleChange_2}
                              />
                              {this.renderErrorFor("born_address_2")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "born_gideon_scripture_2"
                                  ),
                                })}
                                id="born_gideon_scripture_2"
                                name="born_gideon_scripture_2"
                                value={
                                  this.state.to_send.born
                                    .born_gideon_scripture_2 || ""
                                }
                                onChange={this.handleChange_2}
                              />
                              {this.renderErrorFor("born_gideon_scripture_2")}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor("born_name_3"),
                                })}
                                id="born_name_3"
                                name="born_name_3"
                                value={
                                  this.state.to_send.born.born_name_3 || ""
                                }
                                onChange={this.handleChange_2}
                              />
                              {this.renderErrorFor("born_name_3")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "born_address_3"
                                  ),
                                })}
                                id="born_address_3"
                                name="born_address_3"
                                value={
                                  this.state.to_send.born.born_address_3 || ""
                                }
                                onChange={this.handleChange_2}
                              />
                              {this.renderErrorFor("born_address_3")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "born_gideon_scripture_3"
                                  ),
                                })}
                                id="born_gideon_scripture_3"
                                name="born_gideon_scripture_3"
                                value={
                                  this.state.to_send.born
                                    .born_gideon_scripture_3 || ""
                                }
                                onChange={this.handleChange_2}
                              />
                              {this.renderErrorFor("born_gideon_scripture_3")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <p className="lead">Recommendation</p>
                      <p>
                        <strong>
                          Do you recommend any Gideon appearing on the State
                          Convention Educational Programme to be considered by
                          the National Convention Programme Committee to speak
                          at a future National Convention?
                        </strong>
                      </p>
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Subject</th>
                            <th>Camp</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_name_1"
                                  ),
                                })}
                                id="recommend_name_1"
                                name="recommend_name_1"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_name_1 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_name_1")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_subject_1"
                                  ),
                                })}
                                id="recommend_subject_1"
                                name="recommend_subject_1"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_subject_1 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_subject_1")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_camp_1"
                                  ),
                                })}
                                id="recommend_camp_1"
                                name="recommend_camp_1"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_camp_1 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_camp_1")}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_name_2"
                                  ),
                                })}
                                id="recommend_name_2"
                                name="recommend_name_2"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_name_2 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_name_2")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_subject_2"
                                  ),
                                })}
                                id="recommend_subject_2"
                                name="recommend_subject_2"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_subject_2 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_subject_2")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_camp_2"
                                  ),
                                })}
                                id="recommend_camp_2"
                                name="recommend_camp_2"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_camp_2 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_camp_2")}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_name_3"
                                  ),
                                })}
                                id="recommend_name_3"
                                name="recommend_name_3"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_name_3 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_name_3")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_subject_3"
                                  ),
                                })}
                                id="recommend_subject_3"
                                name="recommend_subject_3"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_subject_3 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_subject_3")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_camp_3"
                                  ),
                                })}
                                id="recommend_camp_3"
                                name="recommend_camp_3"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_camp_3 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_camp_3")}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_name_4"
                                  ),
                                })}
                                id="recommend_name_4"
                                name="recommend_name_4"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_name_4 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_name_4")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_subject_4"
                                  ),
                                })}
                                id="recommend_subject_4"
                                name="recommend_subject_4"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_subject_4 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_subject_4")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_camp_4"
                                  ),
                                })}
                                id="recommend_camp_4"
                                name="recommend_camp_4"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_camp_4 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_camp_4")}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_name_5"
                                  ),
                                })}
                                id="recommend_name_5"
                                name="recommend_name_5"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_name_5 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_name_5")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_subject_5"
                                  ),
                                })}
                                id="recommend_subject_5"
                                name="recommend_subject_5"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_subject_5 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_subject_5")}
                            </td>
                            <td>
                              <input
                                type="text"
                                className={classnames({
                                  "is-invalid": this.hasErrorFor(
                                    "recommend_camp_5"
                                  ),
                                })}
                                id="recommend_camp_5"
                                name="recommend_camp_5"
                                value={
                                  this.state.to_send.recommend
                                    .recommend_camp_5 || ""
                                }
                                onChange={this.handleChange_1}
                              />
                              {this.renderErrorFor("recommend_camp_5")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-md-12">
                      <label htmlFor="additional_info">
                        ADDITIONAL INFORMATION noted at the convention.{" "}
                      </label>
                      <textarea
                        type="text"
                        className={classnames({
                          "is-invalid": this.hasErrorFor("additional_info"),
                          "form-control": true,
                        })}
                        id="additional_info"
                        name="additional_info"
                        value={this.state.to_send.additional_info || ""}
                        onChange={this.handleChange}
                      />
                      {this.renderErrorFor("additional_info")}
                    </div>
                  </div>

                  <button
                    type="submit"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold mr-1"
                    disabled={spinner || spinner1 ? true : false}
                  >
                    Submit
                    {spinner ? <MiniSpinner color="white" /> : null}
                  </button>

                  <Link
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                    to="#!"
                    onClick={this.goBack}
                  >
                    Back
                  </Link>
                </form>
              </div>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    spinner: state.users.spinner,
    message: state.users.message,
    status: state.users.status,
    errors: state.users.errors,
    states1: state.preferences.states,
    spinner1: state.preferences.spinner,
    message1: state.preferences.message,
    status1: state.preferences.status,
    errors1: state.preferences.errors,
    states: state.preferences.states,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    submitReportActivityCon: (creds) =>
      submitReportActivityCon(dispatch, creds),
    resetUsersState: () => resetUsersState(dispatch),
    getAllSettings2: () => getAllSettings2(dispatch),
    getAllStates: () => dispatch(getAllStates()),
    getOneCampActivityCon: (id) => getOneCampActivityCon(dispatch, id),
    cancelRemitaConvention: (creds) => cancelRemitaConvention(dispatch, creds),
    showSuccessMessage: () => dispatch(showSuccessMessage()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateReportCon);
