import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "../../../helpers/functions/functions";
import { setPermissionsErrors } from "../../../../store/actions/authActions";
import { connect } from "react-redux";
import Spinner from "../../../helpers/Spinner";

import Message from "../../../helpers/Message";
import MiniSpinner from "../../../helpers/MiniSpinner";
import {
  getAllCamps,
  sendTransferRequest,
  oneRole
} from "../../../../store/actions/preferencesActions";
import { getAllMembers_camp } from "../../../../store/actions/usersActions";
import $ from "jquery";

class CreateTransferRequest extends Component {
  state = {
    camps: null,
    members: null,
    selectedCamp: "",
    membersArry: null,
    formerMembers: null
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllMembers_camp } = this.props;
    if (
      !hasPermission({
        mod: "Camp Administration",
        action: "VIEW_CAMP_ADMINISTRATION"
      }) ||
      !hasPermission({
        mod: "Camp Administration",
        action: "MEMBER_TRANSFER"
      })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      getAllMembers_camp();
      this.props.getAllCamps();
    }
  }

  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (
      nextProps.members !== prevState.members ||
      nextProps.camps !== prevState.camps
    ) {
      return {
        camps: nextProps.camps,
        members: nextProps.members
      };
    }
    return null;
  }
  handleSubmit = e => {
    e.preventDefault();
    const members = Array.from($(".memberselect2").val());
    const camp_id = $(".memberselect22").val();
    // const selectedMember = $(".memberselect22").val();

    this.props.sendTransferRequest({ members, camp_id }).then(res => {
      if (res.status === "success") {
        this.setState({
          selectedCamp: ""
        });
        $(".memberselect2").val("");
        $(".selected_role").val("");
      }
    });
  };

  handleSelectRole = e => {
    this.setState({ selectedCamp: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }

  render() {
    const { spinner, message, status, memberSpinner } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}

        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>CAMP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {" "}
                    Membership Transfer
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">
                Membership Transfer
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? <Spinner /> : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Camp <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control selected_role memberselect22"
                      placeholder="Select Camp"
                      onChange={this.handleSelectRole}
                      value={this.state.selectedRole}
                    >
                      <option value="">--Select Camp--</option>
                      {this.state.camps &&
                        this.state.camps.map((camp, index) => (
                          <option key={index} value={camp.id}>
                            {camp.name} -- {camp.area.name} --{" "}
                            {camp.area.region.name} --{" "}
                            {camp.area.region.state.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6 ">
                    <label>
                      Member(s) <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <select
                        className="memberselect2"
                        multiple="multiple"
                        data-placeholder="Select Members"
                        style={{ width: "100%" }}
                      >
                        {this.state.members &&
                          this.state.members.map((member, index) => (
                            <option key={index} value={member.id}>
                              {member.first_name} {member.last_name}{" "}
                              {member.other_name}
                            </option>
                          ))}
                      </select>
                      {memberSpinner ? <MiniSpinner /> : null}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold mt-2"
                  disabled={spinner ? true : false}
                >
                  Create
                </button>
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    camps: state.preferences.camps,
    message: state.preferences.message,
    spinner: state.preferences.spinner,
    errors: state.preferences.errors,
    status: state.preferences.status,
    memberSpinner: state.users.spinner,
    members: state.users.camp_members
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllMembers_camp: () => dispatch(getAllMembers_camp()),
    getAllCamps: () => dispatch(getAllCamps()),
    sendTransferRequest: creds => sendTransferRequest(dispatch, creds),
    oneRole: id => oneRole(dispatch, id),
    setPermissionsErrors: () => dispatch(setPermissionsErrors())
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateTransferRequest);
