import React, { Component } from "react";
import { Link } from "react-router-dom";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import { connect } from "react-redux";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import {
  getAllMembers,
  errorCreateKit,
  createKit
} from "./../../../../store/actions/usersActions";
import $ from "jquery";

class CreateNewKit extends Component {
  state = {
    members: null,
    selectedMember: "",
    kits: ["Emblem", "Id Card", "Guidebook"]
  };
  componentDidMount() {
    const { setPermissionsErrors, getAllMembers } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_MEMBERSHIP" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_KIT" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      if (this.props.members.length) {
        this.setState({ members: this.props.members });
      } else {
        getAllMembers();
      }
    }
  }

  initDependency = () => {
    const old_element = document.querySelector("script[src='/js/content.js']");
    const new_element = document.createElement("script");
    new_element.src = `/js/content.js`;
    new_element.async = true;
    if (old_element) {
      old_element.replaceWith(new_element);
    } else {
      document.body.appendChild(new_element);
    }
  };
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.members !== prevState.members) {
      return {
        members: nextProps.members
      };
    }
    return null;
  }
  handleSubmit = e => {
    e.preventDefault();
    const kitArry = Array.from($(".memberselect2").val());
    let selectedMember = $(".memberselect22").val();

    const regex = /([0-9]{3,})/gm;
    const str = selectedMember;
    let m;

    while ((m = regex.exec(str)) !== null) {
      // This is necessary to avoid infinite loops with zero-width matches
      if (m.index === regex.lastIndex) {
        regex.lastIndex++;
      }

      // The result can be accessed through the `m`-variable.
      selectedMember = m[0];
    }

    if (selectedMember && kitArry) {
      const creds = {
        member_id: selectedMember,
        kits: kitArry
      };
      this.props.createKit(creds).then(res => {
        if (res.status === "success") {
          this.setState({
            selectedMember: ""
          });
          $(".memberselect2").val("");
          $(".memberselect22").val("");
          this.initDependency();
        }

        $(".memberselect2").val("");
        $(".memberselect22").val("");
        this.initDependency();
      });
    } else {
      this.props.errorCreateKit();
    }
  };

  handleChange = e => {
    // alert("here");
    this.setState({ selectedMember: e.target.value });
  };

  render() {
    const { spinner, message, status } = this.props;
    if (!spinner && status !== "error") {
      this.initDependency();
    }
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>MEMBERSHIP</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active"> Create New Kit</li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold">Create New Kit</h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {message && status ? (
                <Message message={message} status={status} />
              ) : null}
              {spinner ? <Spinner /> : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label>
                      Member <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control selected_role memberselect22"
                      placeholder="Select a Member"
                      onChange={this.handleChange}
                      value={this.state.selectedMember}
                      id="mem"
                    >
                      <option value="">--Select a Member--</option>
                      {this.state.members &&
                        this.state.members.map((member, index) => (
                          <option key={index} value={member.id}>
                            {member.first_name} {member.last_name}{" "}
                            {member.other_name} [{member.login_id}]
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="form-group col-md-6 ">
                    <label>
                      Kit(s) <span className="text-danger">*</span>
                    </label>
                    <div className="d-flex">
                      <select
                        className="memberselect2"
                        multiple="multiple"
                        data-placeholder="Select a kit"
                        style={{ width: "100%" }}
                      >
                        {this.state.kits &&
                          this.state.kits.map((kit, index) => (
                            <option key={index} value={kit}>
                              {kit}
                            </option>
                          ))}
                      </select>
                      {/* {memberSpinner ? <MiniSpinner /> : null} */}
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold mt-2"
                  disabled={spinner ? true : false}
                >
                  Create Kit
                </button>
              </form>
            </div>
            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    message: state.users.message,
    spinner: state.users.spinner,
    errors: state.users.errors,
    status: state.users.status,
    members: state.users.members
  };
};
const mapDispatchToProps = dispatch => {
  return {
    getAllMembers: () => dispatch(getAllMembers()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    errorCreateKit: () => errorCreateKit(dispatch),
    createKit: creds => createKit(dispatch, creds)
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CreateNewKit);
