import React, { Component } from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { connect } from "react-redux";
import {
  getAllAreas,
  updateHotelRoomType,
} from "./../../../../store/actions/preferencesActions";
import Spinner from "./../../../helpers/Spinner";
import Message from "./../../../helpers/Message";
import { hasPermission } from "./../../../helpers/functions/functions";
import { setPermissionsErrors } from "./../../../../store/actions/authActions";
import queryString from "query-string";

class UpdateRoomHotel extends Component {
  state = {
    name: "",
    price: "",
    available: "",
  };
  componentDidMount() {
    const { setPermissionsErrors } = this.props;
    if (
      !hasPermission({ mod: "Preferences", action: "VIEW_PREFERENCES" }) ||
      !hasPermission({ mod: "Preferences", action: "CREATE_HOTEL" })
    ) {
      setPermissionsErrors();
      this.props.history.push("/");
    } else {
      const data = queryString.parse(this.props.location.search);

      this.setState({
        hotel_name: data.hotel_name,
        hotel_id: data.hotel_id,
        price: data.price,
        name: data.name,
        available: data.available,
        id: data.type_id,
      });
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.areas !== prevState.areas) {
      return {
        areas: nextProps.areas,
      };
    }
    return null;
  }

  handleSelectArea = (e) => {
    this.setState({ area_id: e.target.value });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    const { updateHotelRoomType } = this.props;
    const creds = {
      name: this.state.name,
      price: this.state.price,
      available: this.state.available,
      hotel_id: this.state.hotel_id,
      id: this.state.id,
    };
    updateHotelRoomType(creds).then((res) => {
      if (res.status === "success") {
        // this.setState({
        //   name: "",
        //   price: "",
        //   available: "",
        // });
        // $(".selected_area").val("");
      }
    });
  };
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  handleChange1 = (e) => {
    e.preventDefault();
    console.log(e.target.dataset.uuid);
    this.setState({ [e.target.id]: e.target.value });
  };
  hasErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      return !!errors[field];
    }
    return false;
  }
  renderErrorFor(field) {
    const { errors, status } = this.props;
    if (status === "error") {
      if (errors[field]) {
        return (
          <span className="invalid-feedback">
            <strong>{errors[field][0]}</strong>
          </span>
        );
      }
    }
  }
  render() {
    const { spinner, status, message } = this.props;
    return (
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>PREFERENCES</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Edit Hotel Room Type
                  </li>
                </ol>
              </div>
            </div>
          </div>
          {/* /.container-fluid */}
        </section>
        {/* Main content */}
        <section className="content">
          {/* Default box */}
          <div className="card">
            <div className="card-header">
              <h3 className="card-title font-weight-bold ">
                Edit Hotel Room Type{" "}
                {this.state.hotel_name ? `(${this.state.hotel_name})` : null}
              </h3>
              <div className="card-tools"></div>
            </div>
            <div className="card-body">
              {spinner ? <Spinner position="right" /> : null}
              {status && message ? (
                <Message status={status} message={message} />
              ) : null}
              <form onSubmit={this.handleSubmit}>
                <div className="row">
                  <div className="form-group col-md-6">
                    <label htmlFor="name">
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("name"),
                        "form-control": true,
                      })}
                      id="name"
                      name="name"
                      value={this.state.name}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("name")}
                  </div>
                  {/* <div className="form-group col-md-6">
                    <label>
                      Area <span className="text-danger">*</span>
                    </label>
                    <select
                      className={classnames({
                        "is-invalid": this.hasErrorFor("area_id"),
                        "form-control selected_area": true,
                      })}
                      placeholder="Select a Role"
                      onChange={this.handleSelectArea}
                      id="area_id"
                      defaultValue={this.state.area_id}
                    >
                      <option value="">--Select an Area--</option>
                      {this.state.areas &&
                        this.state.areas.map((area, index) => (
                          <option key={index} value={area.id}>
                            {area.name}--{area.region.name}--
                            {area.region.state.name}
                          </option>
                        ))}
                    </select>
                    {this.renderErrorFor("area_id")}
                  </div> */}
                  <div className="form-group col-md-6">
                    <label htmlFor="price">
                      {" "}
                      Price <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("price"),
                        "form-control": true,
                      })}
                      id="price"
                      name="price"
                      value={this.state.price}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("price")}
                  </div>
                  <div className="form-group col-md-6">
                    <label htmlFor="available">
                      Available <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className={classnames({
                        "is-invalid": this.hasErrorFor("available"),
                        "form-control": true,
                      })}
                      id="available"
                      name="available"
                      value={this.state.available}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("available")}
                  </div>
                  {/* <div className="form-group col-md-6">
                    <label htmlFor="remarks">
                      Remarks <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className={classnames({
                        "is-invalid": this.hasErrorFor("remarks"),
                        "form-control": true,
                      })}
                      id="remarks"
                      name="remarks"
                      value={this.state.remarks}
                      onChange={this.handleChange}
                    />
                    {this.renderErrorFor("remarks")}
                  </div> */}
                </div>
                {/* <div className="row">
                  <div className="col-12">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>Room Type</th>
                          <th>Price</th>
                          <th>Available</th>
                          <th>
                            <button className="btn  btn-sm btn-outline-primary">
                              <i className="fa fa-plus"></i>
                              Add more
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.room_type.length &&
                          this.state.room_type.map((type) => (
                            <tr key={type.uuid}>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "form-control": true,
                                  })}
                                  id="name"
                                  name="name"
                                  data-uuid={type.uuid}
                                  onChange={this.handleChange1}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "form-control": true,
                                  })}
                                  id="price"
                                  name="price"
                                  data-uuid={type.uuid}
                                  onChange={this.handleChange1}
                                />
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className={classnames({
                                    "form-control": true,
                                  })}
                                  id="available"
                                  name="available"
                                  data-uuid={type.uuid}
                                  onChange={this.handleChange1}
                                />
                              </td>
                              <td>
                                <button className="btn btn-sm btn-outline-primary">
                                  <i className="fa fa-trash"></i>
                                  Remove
                                </button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div> */}
                <button
                  type="submit"
                  className="btn btn-warning btn-sm text-uppercase font-weight-bold"
                  disabled={spinner ? true : false}
                >
                  Update
                </button>
                {hasPermission({ mod: "Preferences", action: "VIEW_HOTEL" }) ? (
                  <Link
                    to="/preferences/convention-hotels"
                    className="btn btn-warning btn-sm text-uppercase font-weight-bold ml-2"
                  >
                    View hotels
                  </Link>
                ) : null}
              </form>
            </div>

            {/* /.card-body */}
          </div>
          {/* /.card */}
        </section>
        {/* /.content */}
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    spinner: state.preferences.spinner,
    message: state.preferences.message,
    status: state.preferences.status,
    errors: state.preferences.errors,
    areas: state.preferences.areas,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getAllAreas: () => dispatch(getAllAreas()),
    setPermissionsErrors: () => dispatch(setPermissionsErrors()),
    updateHotelRoomType: (creds) => updateHotelRoomType(dispatch, creds),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateRoomHotel);
